<div class="container flex justify-center">
  <div *ngIf="(isMagicLoggedIn$ | async)===false">
    <div class="text-red-600 my-8">
      Error! Failed to reset password. Please select forget password again.
    </div>
    <div>
      <button
          igxButton="raised"
          size="m"
          routerLink="../forget-password"
          class="authButton"
        >
          Forget Password
        </button>
    </div>
  </div>

  <div class="form-wrapper" *ngIf="(isMagicLoggedIn$ | async)===true">
    <h1 class="mb-8">
      Reset Password
    </h1>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordForm.valid && submit()">
      <div *ngIf="hasSetPasswordError$ | async" class="mt-4 text-red-600">
        Error! Failed to reset password.
      </div>

      <div *ngIf="isPasswordSet$ | async" class="mt-4 text-green-600">
        Password has been reset.
      </div>

      <div *ngIf="isSettingPassword$ | async" class="mt-4 text-gray-600">
        <img src="../../../../assets/spinner.gif" alt="loading" width="40px" />
      </div>

      <div class="my-14">
        <igx-input-group type="border">
          <input igxInput name="password" type="password" formControlName="password" title="password"/>
          <label igxLabel for="password">password</label>
        </igx-input-group>
      </div>
      <div class="my-14">
        <igx-input-group type="border">
          <input igxInput name="passwordConfirm" type="password" formControlName="passwordConfirm" title="passwordConfirm"/>
          <label igxLabel for="passwordConfirm">Confirm Password</label>
        </igx-input-group>
      </div>

      <div>
        <button
          size="m"
          type="submit"
          [disabled]="(!resetPasswordForm.valid || (isSettingPassword$ | async))"
          class="authButton"
        >
          Reset Password
        </button>
      </div>
    </form>
  </div>
</div>
