import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { IgxBarSeriesComponent, IgxCategoryXAxisComponent, IgxCategoryYAxisComponent, IgxColumnSeriesComponent, IgxDataChartComponent, IgxNumericXAxisComponent, IgxNumericYAxisComponent, IgxPointSeriesComponent, IgxStacked100ColumnSeriesComponent, IgxStackedFragmentSeriesComponent, IgxValueOverlayComponent } from 'igniteui-angular-charts';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';

import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { CallAPIModes, UserSelectionIds, ModeIds, Columns, PPTIds, Ids, Dimensions, DemographicSwitchId, BasisId, BarStyleId, DashboardId } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DataTemplateRenderInfo } from 'igniteui-angular-core';
import { formatNumber } from '@angular/common';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-demographics-chart',
  templateUrl: './demographics-chart.component.html',
  styleUrls: ['./demographics-chart.component.scss']
})
export class DemographicsChartComponent extends BaseComponent {

  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_SOC_Data"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.DEMOGRAPHIC
  dashboardId = DashboardId.demographics_chart
  dashboards = this.cs.DEMOGRAPHIC_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // Set initial look
  callAPIMode = CallAPIModes.FilterAPI
  alwaysCallBackground = true
  shouldLoadSelectionInSetToDefault  = false

  hasPeriod2Filter = true

  // Facts used in dashboard
  allowedGridFactIds = ["SF3", "SF6", "SF8", "SF9"]   // actually not needed, since the api delivers all the facts

  getHHDataFromBackend = true


  /** -------------------- Inputs for this dashboard -------------------- */
  @ViewChild('chart')
  public chart!: IgxDataChartComponent;

  public catXAxis!: IgxCategoryXAxisComponent;
  public numYAxis!: IgxNumericYAxisComponent;

  public catYAxis!: IgxCategoryYAxisComponent;
  public numXAxis!: IgxNumericXAxisComponent;

  public catXAxisStack!: IgxCategoryXAxisComponent;
  public numYAxisStack!: IgxNumericYAxisComponent;

  private socData: any[] = []             // data of soc from the dimension table
  private socDataWithGroup: any[] = []    // add column "Group" in socData
  private chartSerieItems: any[] = []
  private IsChartSettingsDataLabel = true;

  public hhOfSelectedPeriod: string = ""

  //switch
  public filterDemographicsChartSwitchTitle: string = this.cs.SWITCH
  public filterDemographicsChartSwitchData = this.cs.SELECT_DEMOGRAPHIC_CHART_SWITCH_DATA
  public selectedDemographicsChartSwitchItem: SelectItem = {Id: "", Name: ""}
  public getSelectedDemographicsChartSwitchItem = (event: any) => {
    // console.log("event: ", event.selectedItem)
    this.selectedDemographicsChartSwitchItem = event.selectedItem

    this.updateSelectionFilter()

    this.feedChartWithData()
  }

  // Basis
  public filterDemographicsChartBasisTitle: string = this.cs.BASIS
  public filterDemographicsChartBasisData = this.cs.SELECT_BASIS_DATA
  public selectedDemographicsChartBasisItem: SelectItem = {Id: "", Name: ""}
  public getSelectedDemographicsChartBasisItem = (event: any) => {
    // console.log("event: ", event.selectedItem)
    this.selectedDemographicsChartBasisItem = event.selectedItem
    this.feedChartWithData()
  }

  // Demographic Group
  public filterSingleDemographicTitle: string = this.cs.DEMOGRAPHIC_GROUP
  public filterSingleDemographicData: any[] = []
  public selectedSingleDemographicGroup: SelectItem = {Id: '', Name: ""}
  private selectedDemographicGroupItems: any[] = []
  public getSelectedSingleDemographicGroup = (event: any) => {
    // console.log("event: ", event.selectedItem.Id)
    this.selectedSingleDemographicGroup = event.selectedItem
    this.selectedDemographicGroupItems = this.socDataWithGroup.filter(i => i[Ids.GroupId] === this.selectedSingleDemographicGroup.Id && i[Ids.isSection] === 0)
    // console.log("selectedDemographicGroupItems: ", this.selectedDemographicGroupItems)
    this.feedChartWithData()
  }

  // Bars
  public showDemographicsChartBarsFilter: boolean = true
  public filterDemographicsChartBarsTitle: string = this.cs.STYLE
  public filterDemographicsChartBarsData = this.cs.SELECT_BAR_STYLE_DATA
  public selectedDemographicsChartBarsItem: SelectItem = {Id: "", Name: ""}
  public getSelectedDemographicsChartBarsItem = (event: any) => {
    // console.log("event: ", event.selectedItem)
    this.selectedDemographicsChartBarsItem = event.selectedItem
    this.feedChartWithData()
  }

  public householdPeriod1 = this.cs.HOUSEHOLD_PERIOD1

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)

    this.catXAxis = new IgxCategoryXAxisComponent();
    this.numYAxis = new IgxNumericYAxisComponent();

    this.catYAxis = new IgxCategoryYAxisComponent();
    this.numXAxis = new IgxNumericXAxisComponent();

    this.catXAxisStack = new IgxCategoryXAxisComponent();
    this.numYAxisStack = new IgxNumericYAxisComponent();
  }

  setToDefaultIndividual() {
  }

  setToDefaultIndividualWhenReadingDim(dim: any[]) {
    this.socData = dim.filter(dim => dim.Dimension === Dimensions.SOC).sort((n1,n2) => {
      let n1IdInt = parseInt(n1.Id.substring(1))
      let n2IdInt = parseInt(n2.Id.substring(1))
      if (n1IdInt > n2IdInt) {
          return 1;
      }
      if (n1IdInt < n2IdInt) {
          return -1;
      }
      return 0;
    })
    // console.log("this.socData: ", this.socData)
    this.socDataWithGroup = this.addGroupInSocData(this.socData)
    // console.log("this.socDataWithGroup: ", this.socDataWithGroup)

    // demographic group
    this.filterSingleDemographicData = this.socDataWithGroup.filter(i=>i[Ids.isSection] === 1)
    // console.log("filterSingleDemographicData: ", this.filterSingleDemographicData)
  }

  private ProAccList: string[] = []

  feedChartWithData(): void {
    this.isFilterDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectFilterSocDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
        this.hasPenetrationWarning = false

        this.hhOfSelectedPeriod = this.utilityService.abbreviateNumber(this.HH[this.selectedPeriod1Item.Id], 1)

        // console.log("hhOfSelectedPeriod: ", this.hhOfSelectedPeriod)

        // console.log("demographics-chart | chart | data: ", data)

        let dataOfSelectedDemographicsGroup = data.filter(i=>this.selectedDemographicGroupItems.map(i=>i.Id).includes(i[Columns.Soz]))
        // console.log("dataOfSelectedDemographicsGroup: ", dataOfSelectedDemographicsGroup)

        this.chartData = []  // clear chartData
        let group = ""    // used as the value of "Group" in the chartData
        let values: number[] = []

        switch(this.selectedDemographicsChartSwitchItem.Id) {
          case DemographicSwitchId.IndexTotalExpenditureMarket:
            let uniqueGroupsProduct = new Set<string>()
            let uniqueGroupsAccount = new Set<string>()
            let factIdTotal = this.selectedDemographicsChartBasisItem.Id === BasisId.expenditures ? "SF8" : "SF9"

            for(let demoItem of this.selectedDemographicGroupItems.map(i=>i.Id)) {
              let row: any = {} // each item of the chartData
              let demoItemData = dataOfSelectedDemographicsGroup.filter(i => i[Columns.Soz] === demoItem)
              row[Ids.Group] = this.replaceIdWithName(Columns.Soz, demoItem)
              for(let d of demoItemData) {
                let value = d[factIdTotal]
                values.push(value)

                if(d[Columns.Account] === this.totalAccount[0] && d[Columns.Product] !== this.totalProduct[0]) {
                  group = this.replaceIdWithName(Columns.Product, d[Columns.Product]) + " " + this.replaceIdWithName(Columns.Period, d[Columns.Period])
                  row[group] = value
                  uniqueGroupsProduct.add(group)

                } else if(d[Columns.Account] !== this.totalAccount[0] && d[Columns.Product] === this.totalProduct[0]) {
                  group = this.replaceIdWithName(Columns.Account, d[Columns.Account]) + " " + this.replaceIdWithName(Columns.Period, d[Columns.Period])
                  row[group] = value
                  uniqueGroupsAccount.add(group)

                } else if(d[Columns.Account] === this.totalAccount[0] && d[Columns.Product] === this.totalProduct[0]) {
                  if(this.selectedAccountNodes.map(n => n.Id).includes(this.totalAccount[0])) {
                    group = this.replaceIdWithName(Columns.Account, d[Columns.Account]) + " " + this.replaceIdWithName(Columns.Period, d[Columns.Period])
                    row[group] = value
                    uniqueGroupsAccount.add(group)
                  }

                  if(this.selectedProductNodes.map(n => n.Id).includes(this.totalProduct[0])) {
                    group = this.replaceIdWithName(Columns.Product, d[Columns.Product]) + " " + this.replaceIdWithName(Columns.Period, d[Columns.Period])
                    row[group] = value
                    uniqueGroupsProduct.add(group)
                  }
                }
              }
              this.chartData.push(row)
            }

            // console.log("uniqueGroups: ", uniqueGroups)
            this.ProAccList = [...[...uniqueGroupsProduct].sort(), ...[...uniqueGroupsAccount].sort()]
            this.chartSerieItems = this.ProAccList.map(i=> ({"Item": i}))
            // console.log("chartSerieItems: ", this.chartSerieItems)
            // console.log("this.chartData: ", this.chartData, this.ProAccList)
            this.createChart_bar_column(Math.round(Math.max(...values)))
            break

          case DemographicSwitchId.PercentDistribution:
            let groupData: any[] = []
            let factIdDistribution = this.selectedDemographicsChartBasisItem.Id === BasisId.expenditures ? "SF6" : "SF3"
            for(let a of this.selectedAccountNodes.map(n => n.Id)) {
              for(let pe of this.getPeriodIds()) {
                let rowA: any = {} // each item of the chartData
                group = this.replaceIdWithName(Columns.Account, a) + " " + this.replaceIdWithName(Columns.Period, pe)
                rowA[Ids.Group] = group

                groupData = dataOfSelectedDemographicsGroup.filter(i=> i[Columns.Account] === a && i[Columns.Period] === pe && i[Columns.Product] === this.totalProduct[0])
                for(let gd of groupData) {
                  rowA[this.replaceIdWithName(Columns.Soz, gd[Columns.Soz])] = this.utilityService.myRoundNumber(gd[factIdDistribution],1)  // used for ppt export
                  rowA[gd[Columns.Soz]] = this.utilityService.myRoundNumber(gd[factIdDistribution],1)
                }
                this.chartData.push(rowA)
              }
            }

            for(let p of this.selectedProductNodes.map(n => n.Id)) {
              for(let pe of this.getPeriodIds()) {
                let rowB: any = {} // each item of the chartData
                group = this.replaceIdWithName(Columns.Product, p) + " " + this.replaceIdWithName(Columns.Period, pe)
                rowB[Ids.Group] = group

                groupData = dataOfSelectedDemographicsGroup.filter(i=> i[Columns.Product] === p && i[Columns.Period] === pe && i[Columns.Account] === this.totalAccount[0])

                for(let gd of groupData) {
                  rowB[this.replaceIdWithName(Columns.Soz, gd[Columns.Soz])] = this.utilityService.myRoundNumber(gd[factIdDistribution], 1) // used for ppt export
                  rowB[gd[Columns.Soz]] = this.utilityService.myRoundNumber(gd[factIdDistribution], 1)
                }
                this.chartData.push(rowB)
              }
            }

            // add marker value to the chartData, so that the point series (used for data labels) will appear at the right place
            let selectedDemographicGroupItemsIds: any[] = this.selectedDemographicGroupItems.map(i=>i.Id)
            for(let i = 0; i < this.chartData.length; i ++) {
              let row = this.chartData[i];
              for(let j = 0 ; j < selectedDemographicGroupItemsIds.length; j ++) {
                let sum_of_lower_stacked_sozs = 0
                for(let k = 0; k < j; k ++) {
                  sum_of_lower_stacked_sozs = sum_of_lower_stacked_sozs + row[selectedDemographicGroupItemsIds[k]]
                }
                row[selectedDemographicGroupItemsIds[j] + "_marker"] = sum_of_lower_stacked_sozs + row[selectedDemographicGroupItemsIds[j]] / 2
              }
            }
            // console.log("this.chartData: ",  this.chartData, selectedDemographicGroupItemsIds)
            this.createChart_stackedcolumn()
            break

          case DemographicSwitchId.IndexProductAccount:
            // console.log("this.selectedModeItem.Id: ", this.selectedModeItem.Id)
            let uniqueGroupsPA = new Set<string>()
            let factIdPA = this.selectedDemographicsChartBasisItem.Id === BasisId.expenditures ? "SF8" : "SF9"

            if(this.selectedModeItem.Id === ModeIds.OneAccountMultiProducts) {
              let dataProduct: any[] = []
              for(let demoItem of this.selectedDemographicGroupItems.map(i=>i.Id)) {
                let row: any = {} // each item of the chartData
                let demoItemProductData = dataOfSelectedDemographicsGroup.filter(i => i[Columns.Soz] === demoItem)
                // console.log("demoItemProductData: ", demoItemProductData)
                row[Ids.Group] = this.replaceIdWithName(Columns.Soz, demoItem)

                for(let pId of this.selectedProductIds) {
                  for(let peId of this.getPeriodIds()) {
                    let valueOfProductInTotalAccount = demoItemProductData.filter(i=> i[Columns.Account] === this.totalAccount[0] && i[Columns.Product] === pId && i[Columns.Period] === peId)[0][factIdPA]
                    let valueOfAccountInTotalProduct = demoItemProductData.filter(i=> i[Columns.Account] === this.selectedAccountIds[0] && i[Columns.Product] === this.totalProduct[0] && i[Columns.Period] === peId)[0][factIdPA]
                    group = this.replaceIdWithName(Columns.Product, pId) + " " + this.replaceIdWithName(Columns.Period, peId)
                    row[group] = valueOfProductInTotalAccount / valueOfAccountInTotalProduct * 100
                    uniqueGroupsPA.add(group)
                    values.push(row[group])
                  }
                }

                dataProduct.push(row)
              }
              // console.log("dataProduct: ", dataProduct)
              this.chartData = dataProduct
            } else {
              let dataAccount: any[] = []

              for(let demoItem of this.selectedDemographicGroupItems.map(i=>i.Id)) {
                let row: any = {} // each item of the chartData
                let demoItemProductData = dataOfSelectedDemographicsGroup.filter(i => i[Columns.Soz] === demoItem)
                // console.log("demoItemProductData: ", demoItemProductData)
                row[Ids.Group] = this.replaceIdWithName(Columns.Soz, demoItem)

                for(let aId of this.selectedAccountIds) {
                  for(let peId of this.getPeriodIds()) {
                    let valueOfProductInTotalAccount = demoItemProductData.filter(i=> i[Columns.Account] === this.totalAccount[0] && i[Columns.Product] === this.selectedProductIds[0] && i[Columns.Period] === peId)[0][factIdPA]
                    let valueOfAccountInTotalProduct = demoItemProductData.filter(i=> i[Columns.Account] === aId && i[Columns.Product] === this.totalProduct[0] && i[Columns.Period] === peId)[0][factIdPA]
                    group = this.replaceIdWithName(Columns.Account, aId) + " " + this.replaceIdWithName(Columns.Period, peId)
                    row[group] = valueOfAccountInTotalProduct / valueOfProductInTotalAccount * 100
                    uniqueGroupsPA.add(group)
                    values.push(row[group])
                  }
                }
                dataAccount.push(row)
              }
              // console.log("dataAccount: ", dataAccount)
              this.chartData = dataAccount
            }

            this.ProAccList = [...uniqueGroupsPA].sort()
            this.chartSerieItems = this.ProAccList.map(i=> ({"Item": i}))
            // console.log("this.chartSerieItems: ", this.chartSerieItems)
            // console.log("this.chartData: ", this.chartData, values)
            this.createChart_bar_column(Math.round(Math.max(...values)))
            break
        }

        // console.log("this.chartData: ", this.chartData)
      })
    })
  }

  private replaceIdWithName(category: string, id: string) {
    switch(category) {
      case Columns.Account:
        return this.selectedAccountNodes.filter(i=> i.Id === id)[0][Ids.Name]
        break
      case Columns.Product:
        return this.selectedProductNodes.filter(i=> i.Id === id)[0][Ids.Name]
        break
      case Columns.Period:
        return this.filterPeriod1Data.filter(i=> i.Id === id)[0][Ids.Name]
        break
      case Columns.Soz:
        return this.selectedDemographicGroupItems.filter(i=> i.Id === id)[0][Ids.Name]
        break
    }

    return id
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD1] = this.selectedPeriod1Item.Name
    header[this.cs.PERIOD2] = this.selectedPeriod2Item.Name
    header[this.cs.CHART] = this.selectedDemographicsChartSwitchItem.Name
    header[this.cs.BASIS] = this.selectedDemographicsChartBasisItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name
    header[this.cs.DEMOGRAPHIC_GROUP] = this.selectedSingleDemographicGroup.Name

    let columnValueMap: any = {}
    let excelData: any[] = []

    if(this.selectedDemographicsChartSwitchItem.Id === DemographicSwitchId.PercentDistribution) {
      columnValueMap[this.cs.PRODUCT_ACCOUNT] = Ids.Group
      for (let gi of this.selectedDemographicGroupItems) {
        columnValueMap[gi.Name] = gi.Id
      }
      excelData = this.chartData

    } else {
      columnValueMap[this.cs.PRODUCT_ACCOUNT] = Ids.Produkt_Account
      for (let gi of this.selectedDemographicGroupItems.map(n=>n.Name)) {
        columnValueMap[gi] = gi
      }

      for(let pa of this.ProAccList) {
        let row: any = {}
        row[Ids.Produkt_Account] = pa
        for (let gi of this.selectedDemographicGroupItems.map(n=>n.Name)) {
          let d = this.chartData.filter(c=>c[Ids.Group] === gi)[0]
          row[gi] = this.utilityService.myRoundNumber(d[pa],0)
        }
        excelData.push(row)
      }
    }

    // console.log("excelData: ", excelData, columnValueMap)

    this.exportService.exportExcel("Demographics_Chart", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, excelData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  chartMasterNextPage = (event:any) => {
    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.getPeriodNames()
    header[this.cs.CHART] = this.selectedDemographicsChartSwitchItem.Name
    header[this.cs.BASIS] = this.selectedDemographicsChartBasisItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name
    header[this.cs.DEMOGRAPHIC_GROUP] = this.selectedSingleDemographicGroup.Name

    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster

    if(this.selectedDemographicsChartSwitchItem.Id === DemographicSwitchId.PercentDistribution) {
      this.exportService.createPPTDemographicsChart(this.chartMasterPPT, PPTIds.StackedColumns, this.dashboardTitle, Ids.Group, this.selectedDemographicGroupItems.map(i=>i.Name), this.chartData, header, options)
    } else {
      let type = ""
      if(this.selectedDemographicsChartBarsItem.Id === BarStyleId.horizontal) {
        type = PPTIds.MultiBars
      } else {
        type = PPTIds.MultiColumns
      }
      this.exportService.createPPTDemographicsChart(this.chartMasterPPT, type, this.dashboardTitle, Ids.Group, this.ProAccList, this.chartData, header, options)
    }
    this.chartMasterService.next()
  }

  handleExportPPT(singleTreeName: string): void {
    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.getPeriodNames()
    header[this.cs.CHART] = this.selectedDemographicsChartSwitchItem.Name
    header[this.cs.BASIS] = this.selectedDemographicsChartBasisItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name
    header[this.cs.DEMOGRAPHIC_GROUP] = this.selectedSingleDemographicGroup.Name

    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    // console.log("options: ", options)

    if(this.selectedDemographicsChartSwitchItem.Id === DemographicSwitchId.PercentDistribution) {
      this.exportService.exportPPTDemographicsChart(PPTIds.StackedColumns, this.dashboardTitle, Ids.Group, this.selectedDemographicGroupItems.map(i=>i.Name), this.chartData, header, options)
                        .then(fileName => {
                          console.log(`Created Single PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
    } else {
      let type = ""
      if(this.selectedDemographicsChartBarsItem.Id === BarStyleId.horizontal) {
        type = PPTIds.MultiBars
      } else {
        type = PPTIds.MultiColumns
      }
      this.exportService.exportPPTDemographicsChart(type, this.dashboardTitle, Ids.Group, this.ProAccList, this.chartData, header, options)
                        .then(fileName => {
                          console.log(`Created Single PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
    }
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.Switch] = this.selectedDemographicsChartSwitchItem
    selection[UserSelectionIds.Basis] = this.selectedDemographicsChartBasisItem
    selection[UserSelectionIds.DemographicGroups] = this.selectedSingleDemographicGroup
    selection[UserSelectionIds.Mode] = this.selectedModeItem
    selection[UserSelectionIds.Style] = this.selectedDemographicsChartBarsItem
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Chart, it must be done before Swith Mode, because in case of Index (Product / Account), the treeProductIsMulti and so on will be set to a default value by updateSelectionFilter. In Switch Mode, their real value will be read from the backend. If the chart runs after the switch, the real value will be overwritten by the default value, which is rather a bug.
    this.selectedDemographicsChartSwitchItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Switch, this.cs.SELECT_DEMOGRAPHIC_CHART_SWITCH_DATA[0])
    // have to update it according to the User Language
    this.selectedDemographicsChartSwitchItem = {...this.selectedDemographicsChartSwitchItem, Name: this.cs.DEMOGRAPHIC_CHART_SWITCH_OBJECT[this.selectedDemographicsChartSwitchItem.Id]}
    // console.log("this.selectedDemographicsChartSwitchItem: ", this.selectedDemographicsChartSwitchItem)
    this.updateSelectionFilter()

    // Switch (Mode), it must be done after Chart, because in case of Index (Product / Account), the treeProductIsMulti and so on will be set to a default value by updateSelectionFilter. In Switch Mode, their real value will be read from the backend. If the switch runs before chart, the real value will be overwritten by the default value, which is rather a bug.
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    if(this.selectedDemographicsChartSwitchItem.Id === DemographicSwitchId.IndexProductAccount) {
      switch (this.selectedModeItem.Id) {
        case ModeIds.OneAccountMultiProducts:
          this.treeProductIsMulti = true
          this.callBackendToGetProductAndAllAccounts = true

          this.treeAccountIsMulti = false
          this.callBackendToGetAccountAndAllProducts = true
          break

        case ModeIds.OneProductMultiAccounts:
          this.treeProductIsMulti = false
          this.callBackendToGetProductAndAllAccounts = true

          this.treeAccountIsMulti = true
          this.callBackendToGetAccountAndAllProducts = true
          break
      }
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // period1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // period2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)
    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]

    // Basis
    this.selectedDemographicsChartBasisItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Basis, this.cs.SELECT_BASIS_DATA[0])
    // have to update it according to the User Language
    this.selectedDemographicsChartBasisItem = {...this.selectedDemographicsChartBasisItem, Name: this.cs.BASIS_OBJECT[this.selectedDemographicsChartBasisItem.Id]}
    // console.log("this.selectedDemographicsChartBasisItem: ", this.selectedDemographicsChartBasisItem)

    // demographic group
    this.selectedSingleDemographicGroup = this.utilityService.getSelectionItem(selection, UserSelectionIds.DemographicGroups, this.filterSingleDemographicData[0])
    this.selectedDemographicGroupItems = this.socDataWithGroup.filter(i => i[Ids.GroupId] === this.selectedSingleDemographicGroup.Id && i[Ids.isSection] === 0)
    // console.log("load section this.selectedSingleDemographicGroup: ", this.selectedSingleDemographicGroup, this.selectedDemographicGroupItems)

    // Style
    this.selectedDemographicsChartBarsItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Style, this.cs.SELECT_BAR_STYLE_DATA[0])
    // have to update it according to the User Language
    this.selectedDemographicsChartBarsItem = {...this.selectedDemographicsChartBarsItem, Name: this.cs.BAR_STYLE_OBJECT[this.selectedDemographicsChartBarsItem.Id]}
    // console.log("this.selectedDemographicsChartBarsItem: ", this.selectedDemographicsChartBarsItem)

    // shopper group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })
  }

  /**---------------------------------------------------------------------Private methods --------------------------------------------------------------------- */

  private getMarkerStackedBar(ChartSettingsDataLabel: boolean): any {
    return {
        render: function (renderInfo: DataTemplateRenderInfo) {
            let ctx = renderInfo.context;
            var x = renderInfo.xPosition;
            var y = renderInfo.yPosition;

            const dataItem = renderInfo.data.item;
            if (dataItem === null) return;
            const series = renderInfo.data.series;
            let dataPathMarker = series.valueColumn.propertyName;     //such as S901_marker
            let dataPath = dataPathMarker.split("_")[0]
            let dataValue = 0;

            dataValue = dataItem[dataPath];

            let xOffset = 18;
            let yOffset = 10;

            ctx.font = '12px Montserrat';
            ctx.textBaseline = 'top';
            ctx.fillStyle = "black";

            if (dataValue < 10) {
              xOffset = 12;
            }

            const dataFormat = formatNumber(Number(dataValue), 'de-DE', '1.1-1');

            if(ChartSettingsDataLabel === true) {
              ctx.fillText(dataFormat + "", x - (xOffset / 2), y - (yOffset / 2));
            }
        }
    }
  }

  private createChart_stackedcolumn() {
    // Clear series in case of Reload
    this.chart.series.clear();
    this.chart.axes.clear();

    const stack = new IgxStacked100ColumnSeriesComponent();

    // X Axis
    this.catXAxis.label = Ids.Group;
    stack.xAxis = this.catXAxis;
    this.chart.axes.add(this.catXAxis);

    // Y Axis
    this.numYAxis.minimumValue = 0;
    this.numYAxis.maximumValue = 100;
    this.chart.axes.add(this.numYAxis);
    stack.yAxis = this.numYAxis;

    let fragement_values: any[] = this.selectedDemographicGroupItems.map(i=>i.Id)
    // console.log("chartData: ", this.chartData)
    // console.log("fragement_values: ", fragement_values)

    // Add Series
    for (let i = 0; i < fragement_values.length; i++) {
      let value = fragement_values[i]
      var fragmentX = new IgxStackedFragmentSeriesComponent();
      fragmentX.valueMemberPath = value;
      fragmentX.title = this.replaceIdWithName(Columns.Soz, value);
      fragmentX.brush = this.configService.CHART_COLORS[i]
      fragmentX.outline = "Transparent"
      stack.series.add(fragmentX);
      // console.log("value: ", value, fragmentX)
    }

    this.chart.series.add(stack);

    for (let i = 0; i < fragement_values.length; i++) {
      let value = fragement_values[i] + "_marker"
      let pointSeries = new IgxPointSeriesComponent()
      pointSeries.xAxis = this.catXAxis
      pointSeries.yAxis = this.numYAxis
      pointSeries.dataSource = this.chartData
      pointSeries.valueMemberPath = value
      pointSeries.markerTemplate = this.getMarkerStackedBar(this.IsChartSettingsDataLabel);
      pointSeries.legendItemVisibility = 1  //Collapsed

      this.chart.series.add(pointSeries)
    }

  }

  private createChart_bar_column(maxValue: number) {
    // console.log("this.chartData bar_column: ", this.chartData)
    // Clear series in case of Reload
    this.chart.series.clear();
    this.chart.axes.clear();

    if(this.selectedDemographicsChartBarsItem.Id === BarStyleId.vertical) {
      // Set X Axis
      this.catXAxis.gap                  = 0.5;
      this.catXAxis.majorStroke          = this.configService.STROKE_COLOR_CATEGORY;
      this.catXAxis.majorStrokeThickness = 1;
      this.catXAxis.label                = Ids.Group;
      this.chart.axes.add(this.catXAxis);
      // Set Y Axis
      this.numYAxis.minimumValue = 0;
      this.numYAxis.maximumValue = maxValue + 15;
      this.numYAxis.majorStrokeThickness = 0;
      this.chart.axes.add(this.numYAxis);
    } else {
      // Set X Axis
      this.catYAxis.gap                  = 0.5;
      this.catYAxis.majorStroke          = this.configService.STROKE_COLOR_CATEGORY;
      this.catYAxis.majorStrokeThickness = 1;
      this.catYAxis.label                = Ids.Group;
      this.chart.axes.add(this.catYAxis);
      // Set Y Axis
      this.numXAxis.minimumValue = 0;
      this.numXAxis.maximumValue = maxValue + 15;
      this.numXAxis.majorStrokeThickness = 0;
      this.chart.axes.add(this.numXAxis);
    }

    this.chart.brushes = this.configService.CHART_COLORS

    // Add Series
    // console.log("chartSerieItems bar_column: ", this.chartSerieItems)
    for (let key in this.chartSerieItems) {
      // console.log("CHART_ITEMS_Index_Total");
      this.chart.series.add(this.addSeriesChart(this.chartSerieItems[key][Ids.Item]));
    }

    // Add Overlay for Benchmark
    var IgxValueOverlay = new IgxValueOverlayComponent();
    IgxValueOverlay.value = 100.0;
    if(this.selectedDemographicsChartBarsItem.Id === BarStyleId.vertical) {
      IgxValueOverlay.axis = this.numYAxis;
    } else {
      IgxValueOverlay.axis = this.numXAxis;
    }

    IgxValueOverlay.thickness = 3;
    IgxValueOverlay.brush="rgba(0, 0, 0, 0.25)";
    IgxValueOverlay.legendItemVisibility = 1;
    this.chart.series.add(IgxValueOverlay);
  }

  private addSeriesChart(item: any){
    if(this.selectedDemographicsChartBarsItem.Id === BarStyleId.vertical) {
      var seriesVertical = new IgxColumnSeriesComponent();
      seriesVertical.xAxis = this.catXAxis;
      seriesVertical.yAxis = this.numYAxis;
      seriesVertical.title = item;
      seriesVertical.valueMemberPath = item;
      seriesVertical.markerTemplate = this.utilityService.getMarkerComplex(this.IsChartSettingsDataLabel, '1.0-0', true, "v", true);
      return seriesVertical;
    } else {
      var seriesHorizontal = new IgxBarSeriesComponent();
      seriesHorizontal.xAxis = this.numXAxis;
      seriesHorizontal.yAxis = this.catYAxis;
      seriesHorizontal.title = item;
      seriesHorizontal.valueMemberPath = item;
      seriesHorizontal.markerTemplate = this.utilityService.getMarkerComplex(this.IsChartSettingsDataLabel, '1.0-0', true, "h", true);
      return seriesHorizontal;
    }
  }

  private isSocSection(id: string): boolean {
    if(id.slice(-2) === "00") { // last 2 characters are 00, such as 100, 1100
      return true
    } else {
      return false
    }
  }

  private addGroupInSocData(sData: any[]) {
    let results: any[] = []
    for(let d of sData) {
      let result: any = {}
      if(this.isSocSection(d.Id)) {
        result[Ids.GroupId] = d[Ids.Id]
        result[Ids.Id] = d[Ids.Id]
        result[Ids.Name] = d[Ids.Name]
        result[Ids.isSection] = 1
      } else {
        result[Ids.GroupId] = this.getSocSection(d[Ids.Id])
        result[Ids.Id] = d[Ids.Id]
        result[Ids.Name] = d[Ids.Name]
        result[Ids.isSection] = 0
      }
      results.push(result)
    }
    return results
  }

  private getSocSection(id: string) {
    let idLen = id.length
    let sectionId = ""
    if(idLen === 4) { // such as S101, S109, S111 -> it belongs to section S100
      sectionId = id.substring(0,2) + "00"
    } else if (idLen === 5) {   // such as S1101, S1109, S1110 -> it belongs to section S1100
      sectionId = id.substring(0,3) + "00"
    }
    return sectionId
  }

  private updateSelectionFilter() {
    switch(this.selectedDemographicsChartSwitchItem.Id) {
      case DemographicSwitchId.IndexTotalExpenditureMarket:
        this.showFilterMode = false
        this.showDemographicsChartBarsFilter = true

        /** Tree Account */
        this.treeAccountIsMulti = true     // make the tree without checkbox
        this.callBackendToGetAccountAndAllProducts = true // get account from the store
        /** Tree Product */
        this.treeProductIsMulti = true     // make the tree without checkbox
        this.callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

        break

      case DemographicSwitchId.PercentDistribution:
        this.showFilterMode = false
        this.showDemographicsChartBarsFilter = false

        /** Tree Account */
        this.treeAccountIsMulti = true     // make the tree without checkbox
        this.callBackendToGetAccountAndAllProducts = true // get account from the store
        /** Tree Product */
        this.treeProductIsMulti = true     // make the tree without checkbox
        this.callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

        break

      case DemographicSwitchId.IndexProductAccount:
        this.showFilterMode = true
        this.showDemographicsChartBarsFilter = true

        /** Tree Account */
        this.treeAccountIsMulti = false     // make the tree without checkbox
        this.callBackendToGetAccountAndAllProducts = true // get account from the store
        /** Tree Product */
        this.treeProductIsMulti = true     // make the tree without checkbox
        this.callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

        this.selectedModeItem = this.filterModeData[0]
        break
    }
  }
}

