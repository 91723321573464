<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"
    [showStaticFilter]="true" [selectStaticFilterTitle]="selectSwitchTitle" [selectStaticFilterData]="selectSwitchData" [selectedStaticFilterItem]="selectedSwitchItem" [getSelectedStaticFilterItem]="getSelectedSwitchItem"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBasisTitle]="filterBasisTitle"                [selectedBasis]="selectedSwitchItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti" [showInfoBasis]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
        [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
        [hideSwitch]="true"
      ></app-penetration-warning>

      <div [ngSwitch]="selectedSwitchItem.Name">
        <div *ngSwitchCase="switch.Buyers">
          <div class="definition-of-key-facts" id="Buyers">
              <p>{{buyers_text_0}}: {{utilityService.abbreviateNumber(hh, 1)}}</p>
              <div class="box-wrapper">
                  <div class="box-left">
                      <div class="total-market">
                          <div class="total-market-box-text-1" [innerHTML]="buyers_html_1"></div>

                          <div class="total-market-box-text-2">{{utilityService.formatNumberAsPercent(buyer_fact2_percent, 1)}} ({{utilityService.abbreviateNumber(buyer_fact2, 1)}})</div>

                          <div class="channel">
                              <div class="channel-box-text-1" [innerHTML]="buyers_html_2"></div>
                              <div class="channel-box-text-2">{{utilityService.formatNumberAsPercent(buyer_fact3_percent, 1)}} ({{utilityService.abbreviateNumber(buyer_fact3, 1)}})</div>
                              <div class="channel-2">
                                  <div class="channel-2-box-text-1" [innerHTML]="buyers_html_3"></div>
                                  <div class="channel-2-box-text-2">{{utilityService.formatNumberAsPercent(buyer_fact4_percent, 1)}} ({{utilityService.abbreviateNumber(buyer_fact4, 1)}})</div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="box-right">
                      <div class="sub-info-area-4">
                          <div class="sub-info-area-4-1 border-l-8 border-slate-300">
                              <div class="h-0 w-0 border-y-8 border-y-transparent border-l-8 border-slate-300 sub-info-arrow-area-4-arrow"></div>
                          </div>
                          <div class="sub-info-area-4-2">
                            {{buyers_text_4}} <b>{{selectedAccountName}}</b><br/> {{utilityService.abbreviateNumber(buyer_fact5, 1)}} ({{utilityService.formatNumberAsPercent(buyer_fact5_percent, 1)}})
                          </div>
                      </div>
                  </div>
              </div>

              <div class="sub-info-arrow-area-1">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-1-arrow">
                      <div class=" h-5 w-5 bg-slate-300	-rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-arrow-area-2">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-2-arrow">
                      <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-area-1">
                  <div class="sub-info-area-1-1">
                    {{buyers_text_5}} <b>{{selectedAccountName}}</b><br/>
                      {{utilityService.formatNumberAsPercent(buyer_fact3_percent, 1)}} / {{utilityService.formatNumberAsPercent(buyer_fact2_percent, 1)}} = {{utilityService.formatNumberAsPercent(buyer_fact6_percent, 1)}}
                  </div>
                  <div class="sub-info-area-1-2">
                    {{buyers_text_6}} <b>{{selectedAccountName}}</b><br/>
                    {{utilityService.formatNumberAsPercent(buyer_fact4_percent, 1)}} / {{utilityService.formatNumberAsPercent(buyer_fact3_percent, 1)}} = {{utilityService.formatNumberAsPercent(buyer_fact7_percent, 1)}}
                  </div>
              </div>

              <div class="sub-info-arrow-area-3">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-3-arrow">
                      <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-area-2">
                  {{buyers_text_7}} <b>{{selectedAccountName}}</b> in <b>{{selectedProductName}}</b><br/>
                  {{utilityService.formatNumberAsPercent(buyer_fact4_percent, 1)}} / {{utilityService.formatNumberAsPercent(buyer_fact2_percent, 1)}} = {{utilityService.formatNumberAsPercent(buyer_fact8_percent, 1)}}
              </div>
            </div>
        </div>


        <div *ngSwitchCase="switch.Expenditures">
          <div class="definition-of-key-facts" id="Expenditures">
              <div class="box-wrapper">
                  <div class="box-left">
                      <div class="total-market">
                          <div class="total-market-box-text-1" [innerHTML]="expenditures_html_1"></div>
                          <div class="total-market-box-text-2">{{utilityService.abbreviateNumber(expenditure_fact1, 1)}}</div>

                          <div class="channel">
                              <div class="channel-box-text-1" [innerHTML]="expenditures_html_2"></div>
                              <div class="channel-box-text-2">{{utilityService.abbreviateNumber(expenditure_fact2, 1)}}</div>
                              <div class="channel-2">
                                  <div class="channel-2-box-text-1" [innerHTML]="expenditures_html_3"></div>
                                  <div class="channel-2-box-text-2">{{utilityService.abbreviateNumber(expenditure_fact3, 1)}}</div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="box-right">
                      <div class="sub-info-area-4">
                          <div class="sub-info-area-4-1 border-l-8 border-slate-300">
                              <div class="h-0 w-0 border-y-8 border-y-transparent border-l-8 border-slate-300 sub-info-arrow-area-4-arrow"></div>
                          </div>
                          <div class="sub-info-area-4-2">
                              {{expenditures_text_4}} <b>{{selectedAccountName}}</b> {{utilityService.abbreviateNumber(expenditure_fact4, 1)}}
                          </div>
                      </div>
                  </div>
              </div>

              <div class="sub-info-arrow-area-1">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-1-arrow">
                      <div class=" h-5 w-5 bg-slate-300	-rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-arrow-area-2">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-2-arrow">
                      <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-area-1">
                  <div class="sub-info-area-1-1">
                    {{expenditures_text_5}} <b>{{selectedAccountName}}</b><br/>
                      {{utilityService.abbreviateNumber(expenditure_fact2, 1)}} / {{utilityService.abbreviateNumber(expenditure_fact1, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_fact5_percent, 1)}}
                  </div>
                  <div class="sub-info-area-1-2">
                    {{expenditures_text_6}} <b>{{selectedAccountName}}</b><br/>
                    {{utilityService.abbreviateNumber(expenditure_fact3, 1)}} / {{utilityService.abbreviateNumber(expenditure_fact2, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_fact6_percent, 1)}}
                  </div>
              </div>

              <div class="sub-info-arrow-area-3">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-3-arrow">
                      <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-area-2">
                {{expenditures_text_7}} <b>{{selectedAccountName}}</b><br/>
                  {{utilityService.abbreviateNumber(expenditure_fact3, 1)}} / {{utilityService.abbreviateNumber(expenditure_fact1, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_fact7_percent, 1)}}
              </div>
          </div>
        </div>


        <div *ngSwitchCase="switch.Expenditures_incl_RR">
          <div class="definition-of-key-facts" id="Expenditures_incl_RR">
              <div class="box-wrapper">
                  <div class="box-left">
                      <div class="total-market">
                          <div class="total-market-box-text-1" [innerHTML]="expenditures_incl_rr_html_1"></div>
                          <div class="total-market-box-text-2">{{utilityService.abbreviateNumber(expenditure_incl_RR_fact1, 1)}}</div>

                          <div class="channel-exp_req">
                              <div class="channel-box-text-1" [innerHTML]="expenditures_incl_rr_html_2"></div>
                              <div class="channel-exp_req-box-text-2">{{utilityService.abbreviateNumber(expenditure_incl_RR_fact2, 1)}}</div>
                              <div class="channel-exp_req-2">
                                  <div class="channel-exp_req-2-box-text-1" [innerHTML]="expenditures_incl_rr_html_3"></div>
                                  <div class="channel-exp_req-2-box-text-2">{{utilityService.abbreviateNumber(expenditure_incl_RR_fact3, 1)}}</div>
                                  <div class="channel-exp_req-3">
                                      <div class="channel-exp_req-3-box-text-1" [innerHTML]="expenditures_incl_rr_html_4"></div>
                                      <div class="channel-exp_req-3-box-text-2">{{utilityService.abbreviateNumber(expenditure_incl_RR_fact4, 1)}}</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="box-right">
                      <div class="sub-info-area-4">
                          <div class="sub-info-area-4-1 border-l-8 border-slate-300">
                              <div class="h-0 w-0 border-y-8 border-y-transparent border-l-8 border-slate-300 sub-info-arrow-area-4-arrow"></div>
                          </div>
                          <div class="sub-info-area-4-2">
                              {{expenditures_incl_rr_text_5}} <b>{{selectedAccountName}}</b> {{utilityService.abbreviateNumber(expenditure_incl_RR_fact5, 1)}}
                          </div>
                      </div>
                  </div>
              </div>

              <div class="sub-info-area-top-1">
                  <div class="sub-info-area-top-1-1">
                      <div class="sub-info-arrow-area-exp_req-1">
                          <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                          <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-exprest-1-arrow">
                              <div class=" h-5 w-5 bg-slate-300	-rotate-45 transform origin-top-left"></div>
                          </div>
                      </div>
                  </div>
                  <div class="sub-info-area-top-1-2">
                      <div class="sub-info-arrow-area-exp_req-2">
                          <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                          <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-exprest-2-arrow">
                              <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="sub-info-area-1">
                  <div class="sub-info-area-1-1">
                    {{expenditures_incl_rr_text_6}} <b>{{selectedAccountName}}</b><br/>
                     {{utilityService.abbreviateNumber(expenditure_incl_RR_fact2, 1)}} / {{utilityService.abbreviateNumber(expenditure_incl_RR_fact1, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_incl_RR_fact6_percent, 1)}}
                  </div>
                  <div class="sub-info-area-1-2">
                    {{expenditures_incl_rr_text_7}} <b>{{selectedAccountName}}</b><br/>
                    {{utilityService.abbreviateNumber(expenditure_incl_RR_fact4, 1)}} / {{utilityService.abbreviateNumber(expenditure_incl_RR_fact3, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_incl_RR_fact7_percent, 1)}}
                  </div>
              </div>

              <div class="sub-info-arrow-area-4">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-exprest-4-arrow">
                      <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-area-2">
                {{expenditures_incl_rr_text_8}} <b>{{selectedAccountName}}</b><br/>
                  {{utilityService.abbreviateNumber(expenditure_incl_RR_fact4, 1)}} / {{utilityService.abbreviateNumber(expenditure_incl_RR_fact2, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_incl_RR_fact8_percent, 1)}}
              </div>

              <div class="sub-info-arrow-area-3">
                  <div class="border-solid border-slate-300 border-t-8 border-x-transparent border-x-8 border-b-0"></div>
                  <div class="w-16 overflow-hidden inline-block sub-info-arrow-area-3-arrow">
                      <div class=" h-5 w-5 bg-slate-300 -rotate-45 transform origin-top-left"></div>
                  </div>
              </div>

              <div class="sub-info-area-exprest-3">
                {{expenditures_incl_rr_text_9}} <b>{{selectedAccountName}}</b><br/>
                  {{utilityService.abbreviateNumber(expenditure_incl_RR_fact4, 1)}} / {{utilityService.abbreviateNumber(expenditure_incl_RR_fact1, 1)}} = {{utilityService.formatNumberAsPercent(expenditure_incl_RR_fact9_percent, 1)}}
              </div>
          </div>
       </div>


        <div *ngSwitchDefault></div>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
  [multiSelectedNodeLevels]="multiSelectedNodeLevels"
  [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
  [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
  [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
