import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import pptxgen from 'pptxgenjs';

import { ChartMasterService } from '../../shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../shared/services/constant.service';
import { IgxToastComponent } from 'igniteui-angular';
import { AuthAppState } from 'src/app/auth/state/auth.reducer';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/auth/state/auth.actions';
import { fromAuth } from 'src/app/auth/state/auth.selectors';
import { AuthService } from '../../../auth/services/auth.service';
import { first } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit{
  @ViewChild('toast_presentation', { read: IgxToastComponent })
  public toast_presentation!: IgxToastComponent;

  public json = JSON
  public obj = Object

  // titles
  public chartMasterTitle = this.cs.CHART_MASTER
  public powerpointTitle = this.cs.POWERPOINT
  public selectChartMasterTitle = this.cs.SELECT_CHART_MASTER
  public createChartMasterTitle = this.cs.CREATE_CHART_MASTER
  public availableDashboardsTitle = this.cs.AVAILABLE_DASHBOARDS
  public selectedDashboardsTitle = this.cs.SELECTED_DASHBOARDS
  public saveTitle = this.cs.SAVE
  public createPPTTitle = this.cs.CREATE_PPT
  public pptSettingTitle = this.cs.POWERPOINT_SETTING
  public createPPTTemplateTitle = this.cs.CREATE_PPT_TEMPLATE


  // chart master
  private dashboardsList :any[] = this.cs.CHART_MASTER_DASHBOARDS
  public dashboardsAvailable: any[] = [];
  public dashboardsSelected: any[] = [];
  public chartMasterTemplates: {[key: string]: string[]} = {};
  public chartMasterSavedLatest: string = ''

  public selectedChartMasterTemplateName: string = '';  // the name of the selected chart master template
  public newChartMasterTemplateName: string = ''; // the name of the new chart master template

  public chartMasterIsLoading: boolean = false
  public isChartMasterSaving$     = this.store.select(fromAuth.isChartMasterSaving)
  public isChartMasterSaved$      = this.store.select(fromAuth.isChartMasterSaved)

  public isPowerpointMasterLoading$    = this.store.select(fromAuth.isPowerpointMasterLoading)
  public isPowerpointMasterLoaded$     = this.store.select(fromAuth.isPowerpointMasterLoaded)
  public isPowerpointMasterSaving$     = this.store.select(fromAuth.isPowerpointMasterSaving)
  public isPowerpointMasterSaved$      = this.store.select(fromAuth.isPowerpointMasterSaved)

  public addChartMasterTemplateButtonIsDisabled: boolean = true

  // powerpoint
  public pptMaster: string = ""
  public latestPptMaster: string = ""

  constructor(
    public store: Store<AuthAppState>,
    private chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public changeDetector: ChangeDetectorRef,
    public authService: AuthService,
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges()  // to get ride of the error "Expression has changed after it was checked"
  }

  ngOnInit(): void {
    this.store.dispatch(AuthActions.powerpointMasterGet())

    // Todo get chartMasterTemplates from XANO
    this.getChartMaster()
    this.getPowerpoint()
  }



  /** ======================== chart master ======================== */

  private getChartMaster() {
    this.chartMasterIsLoading = true
    this.authService.getChartMaster()
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      if(data.length > 0 && "chart_master" in data[0]) {
        this.chartMasterTemplates = data[0]["chart_master"]
      }

      this.chartMasterSavedLatest = JSON.stringify(this.chartMasterTemplates)

      this.chartMasterIsLoading = false

      this.selectedChartMasterTemplateName = Object.keys(this.chartMasterTemplates)[0] || ""

      // console.log("init | data: ", data, this.chartMasterTemplates, Object.keys(this.chartMasterTemplates)[0], this.selectedChartMasterTemplateName)
      this.createAvailableAndSelectedDashboards();
    })
  }

  public onDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    if(this.selectedChartMasterTemplateName !== '') {
      let convertedDashboardsSelected: string[] = this.dashboardsSelected.map(i => i.id)
      this.chartMasterTemplates = {...this.chartMasterTemplates, [this.selectedChartMasterTemplateName]:convertedDashboardsSelected}
    }
    // console.log("onDrop: ", this.chartMasterTemplates)
  }

  public deleteChartMasterTemplate(event: any) {
    let tempChartMasterTemplates: {[key: string]: string[]} = {}
    for(let key of Object.keys(this.chartMasterTemplates)) {
      if(key !== this.selectedChartMasterTemplateName) {
        tempChartMasterTemplates[key] = this.chartMasterTemplates[key]
      }
    }
    this.chartMasterTemplates = tempChartMasterTemplates
    // console.log("after delete | chartMasterTemplates: ", this.chartMasterTemplates, this.chartMasterTemplates[this.selectedChartMasterTemplateName])

    this.selectedChartMasterTemplateName = Object.keys(this.chartMasterTemplates)[0] || ""
    this.createAvailableAndSelectedDashboards();
    event.dialog.close();
  }

  public addChartMasterTemplate(){
    const found = Object.keys(this.chartMasterTemplates).find(el => el === this.newChartMasterTemplateName.trim());

    if (!found) {
      this.chartMasterTemplates = {...this.chartMasterTemplates, [this.newChartMasterTemplateName.trim()]: []}

      this.toast_presentation.open(this.cs.CHART_MASTER_ADDED);
      this.selectedChartMasterTemplateName = this.newChartMasterTemplateName.trim();
      this.newChartMasterTemplateName = '';
      this.createAvailableAndSelectedDashboards();
    }
    else{
      this.toast_presentation.open(this.cs.CHART_MASTER_EXIST);
      this.newChartMasterTemplateName = '';
    }
  }

  public chartMasterOnchange() {
    this.createAvailableAndSelectedDashboards();
  }

  public saveChartMasterTemplate() {
    let chartMaster = this.chartMasterTemplates
    this.store.dispatch(AuthActions.updateChartMasterRequest({chartMaster}))
    this.isChartMasterSaved$.subscribe((saved) => {
      if(saved) {
        this.chartMasterSavedLatest = this.json.stringify(this.chartMasterTemplates)
        this.toast_presentation.open(this.cs.CHART_MASTER_SAVED);
      }
    })
  }

  public startChartMasterProcess(){
    // console.log("startChartMasterProcess : ", this.pptMaster)
    // console.log("start, dashboardsSelected: ", this.dashboardsSelected)
    this.chartMasterService.setDashboards(this.dashboardsSelected)
    this.chartMasterService.start(this.pptMaster);
  }

  private createAvailableAndSelectedDashboards(){
    this.dashboardsAvailable = [];
    this.dashboardsSelected = []

    // console.log("createAvailableAndSelectedDashboards | this.chartMasterTemplates: ", this.selectedChartMasterTemplateName, this.chartMasterTemplates)

    let dashboardIdsOfSelectedChartMasterTemplate: string[] = this.chartMasterTemplates[this.selectedChartMasterTemplateName] || []
    // console.log("dashboardIdsOfSelectedChartMasterTemplate: ", dashboardIdsOfSelectedChartMasterTemplate)

    Object.entries(this.dashboardsList).forEach(([key, value], index) => {
      if(dashboardIdsOfSelectedChartMasterTemplate.includes(value.id)) {
        this.dashboardsSelected.push(value)
      } else {
        this.dashboardsAvailable.push(value)
      }
    });
    // console.log("createAvailableAndSelectedDashboards | dashboardsAvailable: ", this.dashboardsAvailable, this.dashboardsSelected)
  }


  /** ======================== powerpoint ======================== */

  private getPowerpoint() {
    this.isPowerpointMasterLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
      this.store.select<any>(fromAuth.selectPowerpointMaster)      // select the dim from state
      .pipe(first(), untilDestroyed(this))
      .subscribe(data  => {
            // console.log("powerpointMaster: ", data)
            this.pptMaster = data
            this.latestPptMaster = this.pptMaster
      })
    })
  }


  public savePPT(){
    let powerpointMaster = this.pptMaster
    this.store.dispatch(AuthActions.updatePowerpointMasterRequest({powerpointMaster}))
    this.isPowerpointMasterSaved$.subscribe((saved) => {
      if(saved) {
        this.latestPptMaster = this.pptMaster
        this.toast_presentation.open(this.cs.CHANGE_SAVED);
      }
    })
  }

  public createPPT(){
    let pptx = new pptxgen();
    pptx.layout = "LAYOUT_WIDE";
    // Add brackets to string
    let master = "[" + this.pptMaster + "]";


    // convert string to object
    let x = eval('(' + master + ')');

    pptx.defineSlideMaster({
      title: "MASTER_SLIDE",
      background: { color: "FFFFFF" },
      objects: x,
      slideNumber: { x: 0.3, y: "90%" },
    });

    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });
    pptx.writeFile({fileName: "presentation-template"});
  }


}

/*
<igx-tab-item>
<igx-tab-header>
    <span igxTabHeaderLabel>{{powerpointTitle}}</span>
</igx-tab-header>
<igx-tab-content>
  <div class="chart-master-heading">{{pptSettingTitle}}</div>

  <textarea title="ppt code" rows="4" cols="100" [(ngModel)]="pptMaster">
  </textarea>
  <div>
    <button igxButton="raised" class="button-green" [disabled]="pptMaster === latestPptMaster" (click)="savePPT()">{{saveTitle}}</button>
    <button igxButton="raised" class="button-green create-powerpoint" (click)="createPPT()">{{createPPTTemplateTitle}}</button>
  </div>

</igx-tab-content>
</igx-tab-item>
*/