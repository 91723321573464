import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, AUTH_FEATURE_KEY } from './auth.reducer';


const selectAuth = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const selectAuthUser = createSelector(selectAuth, state => state.user);
const selectLoginError = createSelector(selectAuth, state => state.hasLoginError);
const selectIsLoggedIn = createSelector(selectAuth, state => state.isLoggedIn);
const selectIsLoadingLogin = createSelector(selectAuth, state => state.isLoadingLogin);

const selectIsForgettingPassword = createSelector(selectAuth, state => state.isForgettingPassword);
const selectForgetPasswordError = createSelector(selectAuth, state => state.hasForgetPasswordError);
const selectIsForgetPasswordEmailSent = createSelector(selectAuth, state => state.isForgetPasswordEmailSent);

const selectIsLoadingMagicLogin = createSelector(selectAuth, state => state.isLoadingMagicLogin);
const selectMagicLoginError = createSelector(selectAuth, state => state.hasMagicLoginError);
const selectIsMagicLoggedIn = createSelector(selectAuth, state => state.isMagicLoggedIn);

const selectIsSettingPassword = createSelector(selectAuth, state => state.isSettingPassword);
const selectSetPasswordError = createSelector(selectAuth, state => state.hasSetPasswordError);
const selectIsPasswordSet = createSelector(selectAuth, state => state.isPasswordSet);

const savePersonalDataStatus = createSelector(
  selectAuth,
  (state) => state.savePersonalDataStatus
);
const isPersonalDataSaved = createSelector(
  savePersonalDataStatus,
  (status) => status === 'SAVED'
);
const isPersonalDataSaving = createSelector(
  savePersonalDataStatus,
  (status) => status === 'SAVING'
);

const saveBasicColorStatus = createSelector(
  selectAuth,
  (state) => state.saveBasicColorStatus
);
const isBasicColorSaved = createSelector(
  saveBasicColorStatus,
  (status) => status === 'SAVED'
);
const isBasicColorSaving = createSelector(
  saveBasicColorStatus,
  (status) => status === 'SAVING'
);

const saveChartMasterStatus = createSelector(
  selectAuth,
  (state) => state.saveChartMasterStatus
);
const isChartMasterSaved = createSelector(
  saveChartMasterStatus,
  (status) => status === 'SAVED'
);
const isChartMasterSaving = createSelector(
  saveChartMasterStatus,
  (status) => status === 'SAVING'
);

const saveAccountProductColorStatus = createSelector(
  selectAuth,
  (state) => state.saveAccountProductColorStatus
);
const isAccountProductColorSaved = createSelector(
  saveAccountProductColorStatus,
  (status) => status === 'SAVED'
);
const isAccountProductColorSaving = createSelector(
  saveAccountProductColorStatus,
  (status) => status === 'SAVING'
);

const savePowerpointMasterStatus = createSelector(
  selectAuth,
  (state) => state.savePowerpointMasterStatus
);
const isPowerpointMasterSaved = createSelector(
  savePowerpointMasterStatus,
  (status) => status === 'SAVED'
);
const isPowerpointMasterSaving = createSelector(
  savePowerpointMasterStatus,
  (status) => status === 'SAVING'
);

const selectPowerpointMaster = createSelector(selectAuth, state => state.powerpointMaster);
const selectPowerpointMasterLoadStatus = createSelector(
  selectAuth,
  (state) => state.powerpointMasterLoadStatus
);
const isPowerpointMasterLoaded = createSelector(
  selectPowerpointMasterLoadStatus,
  (powerpointMasterLoadStatus) => powerpointMasterLoadStatus === 'LOADED'
);
const isPowerpointMasterLoading = createSelector(
  selectPowerpointMasterLoadStatus,
  (powerpointMasterLoadStatus) => powerpointMasterLoadStatus === 'LOADING'
);

export const fromAuth = {
  selectAuthUser,
  selectLoginError,
  selectIsLoggedIn,
  selectIsLoadingLogin,
  selectIsForgettingPassword,
  selectForgetPasswordError,
  selectIsForgetPasswordEmailSent,
  selectIsLoadingMagicLogin,
  selectMagicLoginError,
  selectIsMagicLoggedIn,
  selectIsSettingPassword,
  selectSetPasswordError,
  selectIsPasswordSet,
  isPersonalDataSaved,
  isPersonalDataSaving,
  isBasicColorSaved,
  isBasicColorSaving,
  isChartMasterSaved,
  isChartMasterSaving,
  isAccountProductColorSaved,
  isAccountProductColorSaving,

  isPowerpointMasterSaved,
  isPowerpointMasterSaving,
  selectPowerpointMaster,
  selectPowerpointMasterLoadStatus,
  isPowerpointMasterLoaded,
  isPowerpointMasterLoading,
};
