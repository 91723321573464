<div class="sample-wrapper">
  <div class="tab-heading">{{name}}</div>
  
  <div class="flex flex-row justify-between mx-4 my-2">
    <igx-checkbox #checkboxSelectAll (click)="selectAll()">
    {{selectAllTitle}}
    </igx-checkbox>
  </div>

  <igx-tree #tree selection="Cascading" class="tree-root" (nodeSelection)="handleNodeSelection($event)">  
      <igx-tree-node *ngFor="let level1 of data" [expanded]="topLevelIsExpanded" [active]="false" [selected]="topLevelItemsAreSelected" [data]="level1" >
          <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level1 }"></ng-template>
          <igx-tree-node *ngFor="let level2 of level1.Children" [data]="level2" [(selected)]="level2.Selected!">
              <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level2 }"></ng-template>
              <igx-tree-node *ngFor="let level3 of level2.Children" [data]="level3" [(selected)]="level3.Selected!">
                  <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level3 }"></ng-template>
                  <igx-tree-node *ngFor="let level4 of level3.Children" [data]="level4" [(selected)]="level4.Selected!">
                    <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level4 }"></ng-template>
                    <igx-tree-node *ngFor="let level5 of level4.Children" [data]="level5" [(selected)]="level5.Selected!">
                      <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level5 }"></ng-template>
                      <igx-tree-node *ngFor="let level6 of level5.Children" [data]="level6" [(selected)]="level6.Selected!">
                        <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level6 }"></ng-template>
                        <igx-tree-node *ngFor="let level7 of level6.Children" [data]="level7" [(selected)]="level7.Selected!">
                          <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level7 }"></ng-template>
                          <igx-tree-node *ngFor="let level8 of level7.Children" [data]="level8" [(selected)]="level8.Selected!">
                            <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level8 }"></ng-template>
                            <igx-tree-node *ngFor="let level9 of level8.Children" [data]="level9" [(selected)]="level9.Selected!">
                              <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level9 }"></ng-template>
                              <igx-tree-node *ngFor="let level10 of level9.Children" [data]="level10" [(selected)]="level10.Selected!">
                                <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level10 }"></ng-template>
                              </igx-tree-node>
                            </igx-tree-node>
                          </igx-tree-node>
                        </igx-tree-node>
                      </igx-tree-node>
                    </igx-tree-node>
                  </igx-tree-node>
              </igx-tree-node>
          </igx-tree-node>
      </igx-tree-node>
  </igx-tree>
  <ng-template #nodeTemplate let-data>
      <div class="node">
          <span class="node-title">{{ data.Name }}</span>
      </div>
  </ng-template>
</div>
