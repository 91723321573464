import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistributionTopXComponent } from './distribution-top-x/distribution-top-x.component';
import { RestRequirementRoutingModule } from './rest-requirement.routing.module';
import { DistributionTableComponent } from './distribution-table/distribution-table.component';
import { ActualVsExpectedComponent } from './actual-vs-expected/actual-vs-expected.component';
import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';
import { AccountBalanceComponent } from './account-balance/account-balance.component';



@NgModule({
  declarations: [
    DistributionTopXComponent,
    DistributionTableComponent,
    ActualVsExpectedComponent,
    AccountBalanceComponent
  ],
  imports: [
    CommonModule,
    RestRequirementRoutingModule,
    SharedDashboardComponentModule,
  ]
})
export class RestRequirementModule { }
