import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { LayoutComponent } from '../../layout/layout.component';
import { DashboardId } from '../../shared/model/constants';
import { BasketAnalysisComponent } from './basket-analysis/basket-analysis.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.basket_analysis, pathMatch: 'full' },
      { path: DashboardId.basket_analysis, component: BasketAnalysisComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BasketRoutingModule { }
