import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, DASHBOARD_FEATURE_KEY } from './dashboard.reducer';



const selectDashboardState = createFeatureSelector<DashboardState>(DASHBOARD_FEATURE_KEY);

const selectAllData = createSelector(selectDashboardState, state => state.data);

const selectDataWithFilters = (selectedAccounts: string[], selectedProducts: string[], selectedFacts: string[], selectedPeriods: string[], selectedShopperGroup: string[]) => createSelector(selectAllData,
  (data) => data.filter(item => selectedAccounts.includes(item.BGNum)
                             && selectedProducts.includes(item.ProdNum)
                             && selectedFacts.includes(item.Fact)
                             && selectedPeriods.includes(item.PerNum)
                             && selectedShopperGroup.includes(item.MBDNum)
                       ))

const selectDataWithFiltersWithoutFacts = (selectedAccounts: string[], selectedProducts: string[], selectedPeriods: string[], selectedShopperGroup: string[]) => createSelector(selectAllData,
(data) => data.filter(item => selectedAccounts.includes(item.BGNum)
                            && selectedProducts.includes(item.ProdNum)
                            && selectedPeriods.includes(item.PerNum)
                            && selectedShopperGroup.includes(item.MBDNum)
                      )
                    )

const selectDataLoadStatus = createSelector(
  selectDashboardState,
  (state) => state.dataLoadStatus
);

const isDataLoaded = createSelector(
  selectDataLoadStatus,
  (dataLoadStatus) => dataLoadStatus === 'LOADED'
);

const isDataLoading = createSelector(
  selectDataLoadStatus,
  (dataLoadStatus) => dataLoadStatus === 'LOADING'
);

const selectAllDim = createSelector(selectDashboardState, state => state.dim);

const selectDimLoadStatus = createSelector(
  selectDashboardState,
  (state) => state.dimLoadStatus
);
const isDimLoaded = createSelector(
  selectDimLoadStatus,
  (dimLoadStatus) => dimLoadStatus === 'LOADED'
);
const isDimLoading = createSelector(
  selectDimLoadStatus,
  (dimLoadStatus) => dimLoadStatus === 'LOADING'
);

const selectAllFilterData = createSelector(selectDashboardState, state => state.filterData);

const selectFilterDataWithFilters = (selectedAccounts: string[], selectedProducts: string[], selectedFacts: string[], selectedPeriods: string[], selectedShopperGroup: string[]) => createSelector(selectAllFilterData,
  (data) => data.filter(item => selectedAccounts.includes(item.BGNum)
                             && selectedProducts.includes(item.ProdNum)
                             && selectedFacts.includes(item.Fact)
                             && selectedPeriods.includes(item.PerNum)
                             && selectedShopperGroup.includes(item.MBDNum)
                       ))

const selectFilterDataWithFiltersWithoutFacts = (selectedAccounts: string[], selectedProducts: string[], selectedPeriods: string[], selectedShopperGroup: string[]) => createSelector(selectAllFilterData,
(data) => data.filter(item => selectedAccounts.includes(item.BGNum)
                            && selectedProducts.includes(item.ProdNum)
                            && selectedPeriods.includes(item.PerNum)
                            && selectedShopperGroup.includes(item.MBDNum)
                      ))

const selectFilterSocDataWithFilters = (selectedAccounts: string[], selectedProducts: string[], selectedPeriods: string[], selectedShopperGroup: string[]) => createSelector(selectAllFilterData,
(data) => data.filter(item => ( selectedAccounts.includes(item.BGNum) && selectedPeriods.includes(item.PerNum) && selectedShopperGroup.includes(item.MBDNum))
                            || ( selectedProducts.includes(item.ProdNum) && selectedPeriods.includes(item.PerNum) && selectedShopperGroup.includes(item.MBDNum))
                      ))
const selectFilterDataWithAccount = (account: string) => createSelector(selectAllFilterData,
  (data) => data.filter(item => account === item.BGNum))

const selectFilterDataWithProduct = (product: string) => createSelector(selectAllFilterData,
  (data) => data.filter(item => product === item.BGNum))

const selectFilterDataLoadStatus = createSelector(
  selectDashboardState,
  (state) => state.filterDataloadStatus
);
const isFilterDataLoaded = createSelector(
  selectFilterDataLoadStatus,
  (filterDataloadStatus) => filterDataloadStatus === 'LOADED'
);
const isFilterDataLoading = createSelector(
  selectFilterDataLoadStatus,
  (dataLoadStatus) => dataLoadStatus === 'LOADING'
);

const selectAllHH = createSelector(selectDashboardState, state => state.hh);

const selectHHLoadStatus = createSelector(
  selectDashboardState,
  (state) => state.hhLoadStatus
);

const isHHLoaded = createSelector(
  selectHHLoadStatus,
  (hhLoadStatus) => hhLoadStatus === 'LOADED'
);


const selectAllSelection = createSelector(selectDashboardState, state => state.selection);
const selectSelectionLoadStatus = createSelector(
  selectDashboardState,
  (state) => state.selectionLoadStatus
);
const isSelectionLoaded = createSelector(
  selectSelectionLoadStatus,
  (selectionLoadStatus) => selectionLoadStatus === 'LOADED'
);
const isSelectionLoading = createSelector(
  selectSelectionLoadStatus,
  (selectionLoadStatus) => selectionLoadStatus === 'LOADING'
);

const selectSelectionSaveStatus = createSelector(
  selectDashboardState,
  (state) => state.selectionSaveStatus
);
const isSelectionSaved = createSelector(
  selectSelectionSaveStatus,
  (selectionSaveStatus) => selectionSaveStatus === 'SAVED'
);
const isSelectionSaving = createSelector(
  selectSelectionSaveStatus,
  (selectionSaveStatus) => selectionSaveStatus === 'SAVING'
);


export const fromDashboard = {
  selectAllData,
  selectDataWithFilters,
  selectDataWithFiltersWithoutFacts,
  selectDataLoadStatus,
  isDataLoaded,
  isDataLoading,

  selectAllDim,
  selectDimLoadStatus,
  isDimLoaded,
  isDimLoading,

  selectAllFilterData,
  selectFilterDataWithFilters,
  selectFilterDataWithFiltersWithoutFacts,
  selectFilterSocDataWithFilters,
  selectFilterDataWithAccount,
  selectFilterDataWithProduct,
  selectFilterDataLoadStatus,
  isFilterDataLoaded,
  isFilterDataLoading,

  selectAllHH,
  selectHHLoadStatus,
  isHHLoaded,

  selectAllSelection,
  selectSelectionLoadStatus,
  isSelectionLoaded,
  isSelectionLoading,
  selectSelectionSaveStatus,
  isSelectionSaved,
  isSelectionSaving,
};
