import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { OrganizationChartModule } from 'primeng/organizationchart';

import { MarketSharesComponent } from './market-shares/market-shares.component';
import { MarketShareDevelopmentComponent } from './market-share-development/market-share-development.component';
import { MarketShareRoutingModule } from './market-share.routing.module';
import { MarketShareComponentsComponent } from './market-share-components/market-share-components.component';
import { LoyaltySimulationComponent } from './loyalty-simulation/loyalty-simulation.component';

import { DecompositionTreeComponent } from './decomposition-tree/decomposition-tree.component';
import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';


@NgModule({
  declarations: [
    MarketSharesComponent,
    MarketShareDevelopmentComponent,
    MarketShareComponentsComponent,
    LoyaltySimulationComponent,
    DecompositionTreeComponent
  ],
  providers: [IgxExcelExporterService],
  imports: [
    CommonModule,
    MarketShareRoutingModule,
    SharedDashboardComponentModule,
    OrganizationChartModule,
  ]
})
export class MarketShareModule { }
