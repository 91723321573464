import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConstantService } from '../../../services/constant.service';

@Component({
  selector: 'app-chart-master-navigation',
  templateUrl: './chart-master-navigation.component.html',
  styleUrls: ['./chart-master-navigation.component.scss']
})
export class ChartMasterNavigationComponent implements OnInit {

  @Input() public chartMasterActive: boolean = false;
  @Input() public chartMasterCurrentElement: number = 0;
  @Input() public chartMasterTotalElements: number = 0;

  @Output() chartMasterNextPageEvent = new EventEmitter<any>();
  @Output() cancelChartMasterEvent = new EventEmitter<any>();

  public slide: string = this.cs.SLIDE
  public next: string = this.cs.NEXT
  public createPPT: string = this.cs.CREATE_PPT
  public cancel: string = this.cs.CANCEL

  constructor(public cs: ConstantService) { }

  ngOnInit(): void {
  }

  chartMasterNextPage() {
    this.chartMasterNextPageEvent.emit({});
  }

  cancelChartMaster() {
    this.cancelChartMasterEvent.emit({});
  }
}
