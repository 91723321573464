import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { LayoutComponent } from '../layout/layout.component';
import { AccountComponent } from './account/account.component';
import { PresentationComponent } from './presentation/presentation.component';
import { ColorsComponent } from './colors/colors.component';
import { ProjectComponent } from './project/project.component';
import { StandardSelectionComponent } from './standard-selection/standard-selection.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'account', pathMatch: 'full' },
      { path: 'account', component: AccountComponent },
      { path: 'colors', component: ColorsComponent },
      { path: 'standard-selection', component: StandardSelectionComponent },
      { path: 'presentation', component: PresentationComponent },
   //   { path: 'project', component: ProjectComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
