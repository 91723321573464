<section class="container_content">

  <div class="setting-content">
    <igx-tabs tabAlignment="justify">

      <igx-tab-item>
          <igx-tab-header>
              <span igxTabHeaderLabel>{{cs.ACCOUNTS}}</span>
          </igx-tab-header>
          <igx-tab-content>
              <button class="standard-selection-button" (click)="saveAccountStandardSelection()" [disabled]="saveButtonAccountIsDisabled">{{cs.SAVE}}</button>
              <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodes" [showSelectAll]="false" ></app-tree-with-checkbox-with-ten-levels>
          </igx-tab-content>
      </igx-tab-item>

      <igx-tab-item>
          <igx-tab-header>
              <span igxTabHeaderLabel>{{cs.PRODUCTS}}</span>
          </igx-tab-header>
          <igx-tab-content>
            <button class="standard-selection-button" (click)="saveProductStandardSelection()" [disabled]="saveButtonProductIsDisabled">{{cs.SAVE}}</button>
            <app-tree-with-checkbox-with-ten-levels #productTreeWithCheckbox [data]="filterProductData" [name]="filterProductTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedProductNodeLevels" [selectedNodes]="selectedProductNodes" [showSelectAll]="false" ></app-tree-with-checkbox-with-ten-levels>
          </igx-tab-content>
      </igx-tab-item>

    </igx-tabs>
  </div>
  <igx-toast #toast_standard_selection></igx-toast>
  <div class="loading" *ngIf="(isSelectionLoading$ | async) === true || (isSelectionSaving$ | async) === true"></div>
</section>
