import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'
import { first } from 'rxjs'
import pptxgen from 'pptxgenjs';

import { Columns, ModeIds, CallAPIModes, NoFilterSelected, Ids, Dimensions } from '../shared/model/constants'
import { SelectItemChecked } from '../shared/model/interfaces';
import { SelectBenchmakrItem, SelectItem } from '../shared/model/interfaces'
import { SelectedTreeNode } from '../shared/model/interfaces'
import { ChartService } from '../shared/services/chart.service'
import { ExportService } from '../shared/services/export.service'
import { RESTService } from '../shared/services/rest.service'
import { UtilityService } from '../shared/services/utility.service'
import { DashboardActions } from '../shared/state/dashboard.actions'
import { DashboardAppState } from '../shared/state/dashboard.reducer'
import { fromDashboard } from '../shared/state/dashboard.selector'
import { ExportMultiPptDialogComponent } from '../shared/ui/components/export-multi-ppt-dialog/export-multi-ppt-dialog.component'
import { FilterTabsComponent } from '../shared/ui/components/filter-tabs/filter-tabs.component'
import { ChartMasterService } from '../shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../shared/services/constant.service';
import { fromAuth } from 'src/app/auth/state/auth.selectors';
import { ConfigService } from '../shared/services/config.service';
import { AuthService } from '../../auth/services/auth.service';
import { AuthActions } from 'src/app/auth/state/auth.actions';


@UntilDestroy()
@Component({
  selector: 'app-base',
  template: `
    <p>
      the base template is not used
    </p>
  `,
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  /** -------------------------- Input from real Dashboards ----------------------------*/
  sourceTable: string = ''

  dashboardCategoryTitle: string = ''
  dashboardId: string    = ''
  dashboards: any[]  = []
  public  dashboardTitle: string = ''

  /** look and behavior of trees & how to call api of backend */
  callAPIMode: string = CallAPIModes.GetAPI            // default way to call api, the get api. The selected accounts with all products, or the selected products with all accounts
  public alwaysCallBackground: boolean = false               // in Demographics Chart, this value should be true. So even in the case of tree with/without checkbox, it calls the backend always when click either tree.
  /** Tree Account */
  public treeAccountIsMulti: boolean = false          // if the tree is multi select
  public callBackendToGetAccountAndAllProducts: boolean = true    // when call api mode is getapi, then one of (callBackendToGetAccountAndAllProducts and callBackendToGetProductAndAllAccounts) must be true and the other must be false.  The trees could be both single select or one single one multi
  /** Tree Product */
  public treeProductIsMulti: boolean = true
  public callBackendToGetProductAndAllAccounts: boolean = false


  /** some boolean variables, have to be kept*/
  public hasPeriod2Filter: boolean = false            // if it has period 2 filters ?
  public hasFactTypeFilter: boolean = false           // static filter: fact in percent or as index, used in "Analysis of Strengths and Weaknesses"

  /** FactIds */
  axisXFactId: string = ''
  axisYFactId: string = ''
  axisY2FactId: string = ''       // the second Y axis, used in "price performance"
  selectBubbleSizeFactIds: string[] = []    // the ids of the facts, which are used as bubble size
  public axisYMinValue: any
  public axisYMaxValue: any
  public axisYInterval: any
  public factsMaxValueIsHundred: string[] = []
  allowedChartFactIds: string[] = []
  allowedGridFactIds: string[] = []

  /** constent */
  totalProduct = ['P0']
  totalAccount = ['BG1']
  allPeriodIds = ["PER1", "PER2", "PER3", "PER4"]     // Todo, remove periods in the backend api to set all pierods at default, then we don't need to send all period ids to the api
  //allPeriodIds = ["PER1", "PER2", "PER3"]     // Todo, remove periods in the backend api to set all pierods at default, then we don't need to send all period ids to the api

  /** -------------------------- Variables used in base -------------------------- */

  public ids = Ids

  /** HH data */
  public HH: any = {}
  public getHHDataFromBackend: boolean = false

  /** Filter Three Tabs */
  @ViewChild(FilterTabsComponent)
  filterTabsComponent!: FilterTabsComponent

  // tab Account
  public tabAccountTitle = this.cs.ACCOUNT
  public filterAccountTitle = this.cs.ACCOUNT      // used to identify which tree is clicked and also in the column header of info table
  public filterAccountData: any[] = []             // pass the data to tree
  public selectedAccountNodes: SelectedTreeNode[] = []
  public selectedAccountIds: string[] = []

  // tab Product
  public tabProductTitle = this.cs.PRODUCT
  public filterProductTitle = this.cs.PRODUCT     // used to identify which tree is clicked and also in the column header of info table
  public filterProductData: any[] = []
  public selectedProductNodes: SelectedTreeNode[] = []
  public selectedProductIds: string[] = []

  // tab Period & Fact
  public tabPeriodFactTitle: string = this.cs.PERIOD_AND_FACT
         // period 1 filter
  public filterPeriod1Title: string = this.cs.PERIOD1            // set as the label and the name of the component, used to get which select is clicked
  public filterPeriod1Data: any[] = []                            // pass data to select
  public selectedPeriod1Item: SelectItem      = {Id: '', Name: ''}
  public selectedPeriod1ItemNames: string[] = []                  // used for chip bar table. in table-of-facts, you can select multi periods in one select. so to use a list to store the selected periods
  public selectedPeriod1ItemIds: string[] = []                    // used in table-of-facts
         // period 2 filter
  public filterPeriod2Title: string = this.cs.PERIOD2
  public filterPeriod2Data: any[] = []
  public selectedPeriod2Item: SelectItem      = {Id: '', Name: ''}
  public selectedPeriod2ItemNames: string[] = []                  // used for chip bar table
  public period2IsSelected: boolean = true
         // single fact for chart
  public filterChartFactTitle: string = this.cs.FACT_CHART
  public filterChartFactData: any[] = []
  public filterChartFactDataWithoutPenetration: any[] = []
  public selectedChartFactItem: SelectItem    = {Id: '', Name: ''}
         // multi facts for chart
  public filterChartFactsTitle: string = this.cs.FACT_CHART   // the multi select with facts for chart
  public filterChartFactsData: any[] = []
  public selectedChartFactItemNames: string[] = []        // not able to use SelectItem[], so have to use 2 variables, 1 for names and another for ids
  public selectedChartFactItemIds: string[] = []
         // multi facts for grid
  public filterGridFactsTitle: string = this.cs.FACT_TABLE
  public filterGridFactsData: any[] = []
  selectedGridFactItemNames: string[] = []      // not able to use SelectItem[], so have to use 2 variables, 1 for names and another for ids
  public selectedGridFactItemIds: string[] = []
         //bubble size facts
  public filterBubbleSizeFactData: any[] = []                         // used in both base and analysis-of-strengths-and-weaknesses
  public selectedBubbleSizeFactItem: SelectItem = {Id: '', Name: ''}  // used in both base and analysis-of-strengths-and-weaknesses
  public bubbleSizeTitle = this.cs.BUBBLE_SIZES                        // used in info table
         // fact title used in info table
  public factTitle: string = this.cs.FACT

  // tab Selection
  public tabSelectionTitle: string = this.cs.SELECTION
         //benchmark
  public filterBenchmarkTitle: string = this.cs.BENCHMARK
  public filterBenchmarkData: any[] = []
  public filterProductBenchmarkData: any[] = []   // the bechmark filter contains only the products, no matter which Mode it is, and it doesn't have no-benchmark-selected
  public selectedBenchmarkItem: SelectBenchmakrItem  = this.cs.NO_BENCHMARK_SELECTED      // the init value is -1, so that it is not shown in chart
  public selectedProductBenchmarkItem: SelectBenchmakrItem = this.cs.NO_BENCHMARK_SELECTED
  public hasNoBenchmarkSelectedError: boolean = false // when selecting "fact as index", but there has been no benchmark selected. it get an no benchmarkselecterror. and show the error message in ui.
        // benchmark account - used in demographics table
  public benchmarkAccountTitle = this.cs.BENCHMARK_FOR_PRODUCT
  public benchmarkAccountData: any[] = []
  public selectedBenchmarkAccountItem: SelectBenchmakrItem  = this.cs.NO_BENCHMARK_SELECTED
        // benchmark product - used in demographics table
  public benchmarkProductTitle = this.cs.BENCHMARK_FOR_ACCOUNT
  public benchmarkProductData: any[] = []
  public selectedBenchmarkProductItem: SelectBenchmakrItem  = this.cs.NO_BENCHMARK_SELECTED
         //shopper group
  public filterShopperGroupTitle: string = this.cs.SHOPPER_GROUP
  public filterShopperGroupData: any[] = []
  public selectedShopperGroupItem: SelectItem = {Id: '', Name: ''}
         //Mode
  public showFilterMode: boolean = true
  public filterModeTitle: string = this.cs.MODE
  public filterModeData: any[] = this.convertToFilterData(this.cs.SELECT_FILTER_MODE_DATA, true)
  public selectedModeItem: SelectItem = {Id: "", Name: ""}
         //fact type
  public selectedFactTypeItem: SelectItem = {Id: '',  Name: ''}       // used in both base and analysis-of-strengths-and-weaknesses, fact in % or fact as index

        // filter values used in kpi comparision
  public selectFilterAccountTitle = this.cs.ACCOUNT
  public filterValuesAccountData: any[] = [NoFilterSelected]
  public selectedFilterAccountItem: any = NoFilterSelected

  public selectFilterProductTitle = this.cs.PRODUCT
  public filterValuesProductData: any[] = [NoFilterSelected]
  public selectedFilterProductItem: any = NoFilterSelected

  public selectFilterFactTitle = this.cs.FACT
  public filterValuesFactData: any[] = [NoFilterSelected]
  public selectedFilterFactItem: any = NoFilterSelected

  public selectFilterShopperGroupTitle = this.cs.SHOPPER_GROUP
  public filterValuesShopperGroupData: any[] = [NoFilterSelected]
  public selectedFilterShopperGroupItem: any = NoFilterSelected

  // tab Focus  --- used in rest requirement
  public tabFocusTitle = this.cs.FOCUS
  public filterAccountTitleFocus = this.cs.FOCUS          // Used in info table
  public selectedAccountNodesFocus: SelectedTreeNode[] = []
  public selectedFocusAccountName: string = ''

  // tab Competitor  --- used in rest requirement
  public tabCompetitorTitle = this.cs.COMPETITOR
  public filterAccountTitleCompetitor = this.cs.COMPETITOR  // Actually it is not used
  public selectedAccountNodesCompetitor: SelectedTreeNode[] = []

  // others
  public selectedAccountNodeLevels: number[] = [0] // used to save the levels of all the selected nodes in a tree, so that the "select all in hierarchy" works. by default, the root item of tree is selected. so the level is by default 0.
  public selectedAccountNodeLevelsCompetitor: number[] = [0]
  public selectedProductNodeLevels: number[] = [0]
  public multiSelectedNodeLevels: number[] = []    // for the tree in export multi ppt dialog
  public shouldLoadSelectionInSetToDefault: boolean = true   // only in Demographics Chart, its value should be false.

  /** Spinner & Status */
  public  isDataLoading$            = this.store.select(fromDashboard.isDataLoading)
  public  isFilterDataLoading$      = this.store.select(fromDashboard.isFilterDataLoading)
  isDataLoaded$       = this.store.select(fromDashboard.isDataLoaded)
  isDimLoaded$        = this.store.select(fromDashboard.isDimLoaded)
  isFilterDataLoaded$ = this.store.select(fromDashboard.isFilterDataLoaded)
  isHHLoaded$         = this.store.select(fromDashboard.isHHLoaded)
  isSelectionLoaded$  = this.store.select(fromDashboard.isSelectionLoaded)

  /** export ppt */
  public isPowerpointMasterLoaded$      = this.store.select(fromAuth.isPowerpointMasterLoaded)
  public userDefinedPptMaster: string   = ""

  /** Export Multi PPT - Dialog*/
  @ViewChild(ExportMultiPptDialogComponent)
  exportMultiPptDialogComponent!: ExportMultiPptDialogComponent

  public treeMultiAccountName = Ids.MAccount              // used to identify which tree is clicked
  public treeMultiProductName = Ids.MPRODUCT              // used to identify which tree is clicked
  selectedMultiAccountNodes: SelectedTreeNode[] = []    // selected Account Nodes in export multi ppt dialog
  selectedMultiProductNodes: SelectedTreeNode[] = []    // selected Product Nodes in export multi ppt dialog

  /** Replace Id with Name */
  dictAccountDataWithIdName: any[] = []               // such as [{Id: BG1, Name: BG1(Name)}], used to replace Id with Name
  dictProductDataWithIdName: any[] = []
  dictFactDataWithIdName: any[] = []                  // there is also a tree fact. in dashboard "table-of-facts"

  /** Switch Penetration Warning */
  public hidePenetrationWarning = false
  public hasPenetrationWarning = false // use this variable to know if there is any penetration warning in the shown chart / table
  public penetrationFactId = "F2"
  public penetrationFactName = ""

  /** Chart */
  public chartData: any[] = []
  public changeChartData: any = []        // used to create a chart of change
  public showDataLabel: boolean = true
  public showLegend: boolean = true

  public modeCategory = ''                    // igx-category-x/y-axis label, either ProdNum or BGNum, telling if the chart is about account or product
  fieldsWithoutPeriodAndValue: string[] = []  // used to convert xano data to column chart data
  fieldsWithoutFactAndValue: string[] = []    // used to convert xano data to bubble chart data

  /** axis Y */
  public axisYFactName: string = ''
  public axisY2FactName: string = ''    // für axis Y 2

  /** chart master */
  chartMasterPPT: pptxgen = new pptxgen()
  public chartMasterActive: boolean = false;
  public chartMasterTotalElements: number = 0;
  public chartMasterCurrentElement: number = 0;
  public showExportExcel: boolean = true
  public showExportPPT: boolean = true
  public showExportMultiPPT: boolean = true

  /**Colors */
  public chartColors: string[] = []
  private firstChartColor = this.configService.DEFAULT_CHART_COLOR_FIRST
  private secondChartColor = this.configService.DEFAULT_CHART_COLOR_SECOND

  public getUserDefinedAccountProductColor: boolean = false
  public userDefinedAccountColor: any = {}  // the user defined account ids and their colors, got from the backend
  public userDefinedProductColor: any = {}  // the user defined product ids and their colors, got from the backend
  public accountColorMapping: any = {}  // the selected account ids and their colors, used in "marketshare development" and "analysis of strengths and weakness"
  public productColorMapping: any = {}  // the selected product ids and their colors, used in "marketshare development" and "analysis of strengths and weakness"


  /** Constructor*/
  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
  this.forChartMaster()

    this.store
    .select<any | null>(fromAuth.selectAuthUser)
    .pipe(first(), untilDestroyed(this))
    .subscribe( user => {
        this.firstChartColor  = user.chart_color_first
        this.secondChartColor = user.chart_color_second

        this.configService.setBasicColors(this.firstChartColor, this.secondChartColor)
        this.chartColors = this.configService.CHART_COLORS
      }
    )

    if(this.getUserDefinedAccountProductColor) {
      this.authService.getAccountProductColor().subscribe(data => {
        this.userDefinedAccountColor = data[0]["account_color"]
        this.userDefinedProductColor = data[0]["product_color"]
        // console.log("base | this.userDefinedAccountColor: ", this.userDefinedAccountColor)
        // console.log("base | this.userDefinedProductColor: ", this.userDefinedProductColor)
      })
    }

    //console.log("CALL API To LOAD CONFIG: ", this.dashboardId)
    this.store.dispatch(DashboardActions.selectionGet({dashboard: this.dashboardId}))

    // Selection Template
    this.store.dispatch(DashboardActions.selectionTemplatesGet({dashboard: this.dashboardId}))


    this.store.dispatch(AuthActions.powerpointMasterGet())

    if(this.getHHDataFromBackend) {
      this.store.dispatch(DashboardActions.hhGet())           // get the dim from xano and do not change it anymore until the page is reloaded
    }

    this.store.dispatch(DashboardActions.dimGet())           // get the dim from xano and do not change it anymore until the page is reloaded
    // this.store.dispatch(UserConfigActions.get({dashboard: this.dashboardId}))
    this.setToDefault(true, true, true, true)
  }

  ngOnDestroy() {
    // console.log("CALL API To SAVE CONFIG: ", this.dashboardId)
    if(true) {  //TODO: need to check if the selection has changes
      this.saveSelectionToBackend()
    }

    this.resetData(true)        //clean the data when destroying the component
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges()  // to get ride of the error "Expression has changed after it was checked" due to resetting filterBenchmarkData
  }

  setToDefault(readDim: boolean, readHH: boolean, loadSelectionFromBackend: boolean, loadSelection: boolean = true) {
    if(this.getHHDataFromBackend) {
      this.isHHLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
        this.store.select<any[]>(fromDashboard.selectAllHH)
        .pipe(first(), untilDestroyed(this))
        .subscribe(hh => {
          if(readHH) {
            for(let h of hh) {
              this.HH[h[Columns.Period]] = h[Ids.HH]
            }

            // console.log("this.HH: ", this.HH)
          }
        })
      })
    }

    this.isPowerpointMasterLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
      this.store.select<any>(fromAuth.selectPowerpointMaster)
      .pipe(first(), untilDestroyed(this))
      .subscribe(data  => {
        this.userDefinedPptMaster = data
       //  console.log("base | userDefinedPptMaster: ", this.userDefinedPptMaster)
      })
    })

    //only get the dim when there is dim data. at the very beginning, the store is empty, so the dim is empty. Once the store is filled, the dim will be emitted again.
    this.isDimLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
      this.store.select<any[]>(fromDashboard.selectAllDim)      // select the dim from state
      .pipe(first(), untilDestroyed(this))
      .subscribe(dim => {
            if(readDim) {
              /** get data of the ui component --- should be done only once when the dimension data is loaded into state, since they will not be changed if the dim doesn't change */
              this.filterAccountData = dim.filter(dim => dim.Dimension === Dimensions.AccountTree)[0][Dimensions.Tree]
              this.filterProductData = dim.filter(dim => dim.Dimension === Dimensions.ProductTree)[0][Dimensions.Tree]

              this.filterPeriod1Data = this.convertToFilterData(dim.filter(dim => dim.Dimension === Dimensions.Period), false).sort((n1,n2) => {
                if (n1.Id > n2.Id) {
                    return -1;
                }
                if (n1.Id < n2.Id) {
                    return 1;
                }
                return 0;
              });
              this.filterPeriod2Data = this.getPeriod2Data(Object.assign([], this.filterPeriod1Data))
              this.filterShopperGroupData = this.convertToFilterData(dim.filter(dim => dim.Dimension === Dimensions.ShopperGroup), true)

              this.filterChartFactData = this.convertToFilterData(this.utilityService.getFactDim(this.cs.FACT_DIM, this.getFactsToLoad()), true)

              // filterChartFactsData is only used in Basket Analysis, to show the check list of all facts. we don't want to show the penetration fact in the list, so we have to remove the penetrationFactId here
              let factsWithoutPenetration = this.getFactsToLoad()
              let indexOfPenetrationFact = factsWithoutPenetration.indexOf(this.penetrationFactId)
              factsWithoutPenetration.splice(indexOfPenetrationFact, 1);
              this.filterChartFactDataWithoutPenetration = this.convertToFilterData(this.utilityService.getFactDim(this.cs.FACT_DIM, factsWithoutPenetration), true)
              // console.log("this.filterChartFactData: ", this.filterChartFactData, this.filterChartFactDataWithoutPenetration)

              this.filterChartFactsData = this.convertToFilterData(this.utilityService.getFactDim(this.cs.FACT_DIM, factsWithoutPenetration), false)
              // console.log("this.filterChartFactsData: ", this.filterChartFactsData)

              this.filterGridFactsData = this.convertToFilterData(this.utilityService.getFactDim(this.cs.FACT_DIM, this.getFactsToLoad()), true)
              let penetrationFact: any[] = this.filterChartFactData.filter(i=>i["Id"] === this.penetrationFactId)
              this.penetrationFactName = penetrationFact.length === 1 ? penetrationFact[0]["Name"] : ""
              // console.log("this.penetrationFactName: ", this.getFactsToLoad(), this.penetrationFactName, penetrationFact, this.filterChartFactData)

              this.dictAccountDataWithIdName = dim.filter(dim => dim.Dimension === Dimensions.Account)
              this.dictProductDataWithIdName = dim.filter(dim => dim.Dimension === Dimensions.Product)
              this.dictFactDataWithIdName = dim.filter(dim => dim.Dimension === Dimensions.Fact)

              this.setToDefaultIndividualWhenReadingDim(dim)
            }

            this.isSelectionLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
              this.store.select<any[]>(fromDashboard.selectAllSelection)      // select the dim from state
              .pipe(first(), untilDestroyed(this))
              .subscribe(selection => {
             //    console.log("selection: ", this.dashboardId, selection, dim)

                if(loadSelection) {
                  this.loadSelection(selection, loadSelectionFromBackend)
                }

         //       console.log("this.callAPIMode", this.callAPIMode)
                /** call backend to get data */
                switch(this.callAPIMode) {
                  case CallAPIModes.FilterAPI:
                    this.store.dispatch(DashboardActions.filterDataGet({sourceTable: this.sourceTable, accounts: this.selectedAccountIds, products: this.selectedProductIds, periods: this.allPeriodIds, facts: this.getFactsToLoad()}))
                    break

                  case CallAPIModes.FilterAPIWithTotal:
                    this.store.dispatch(DashboardActions.filterDataGet({sourceTable: this.sourceTable, accounts: [... new Set(this.totalAccount.concat(this.selectedAccountIds))], products: [... new Set(this.totalProduct.concat(this.selectedProductIds))], periods: this.allPeriodIds, facts: this.getFactsToLoad()}))
                    break

                  case CallAPIModes.GetAPI:                    
                    // when using GetAPI, one of (callBackendToGetAccountAndAllProducts and callBackendToGetProductAndAllAccounts) must be true, and the other must be false. The trees could be both single select or one single one multi
                    if(this.callBackendToGetAccountAndAllProducts) {
                      this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: [this.selectedAccountIds[0]], selectedProducts: ["ALL"], facts: this.getFactsToLoad()}))
                      this.filterBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
                      this.modeCategory = Columns.Product
                      this.fieldsWithoutPeriodAndValue = [Columns.Product, Columns.Account, Columns.Fact, Columns.ShopperGroup] // make sure Product at the first place
                      this.fieldsWithoutFactAndValue = [Columns.Product, Columns.Account, Columns.Period, Columns.ShopperGroup]
                    } else {
                      this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: ["ALL"], selectedProducts: [this.selectedProductIds[0]], facts: this.getFactsToLoad()}))
                      this.filterBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedAccountNodes)
                      this.modeCategory = Columns.Account
                      this.fieldsWithoutPeriodAndValue = [Columns.Account, Columns.Product, Columns.Fact, Columns.ShopperGroup] // make sure Account at the first place
                      this.fieldsWithoutFactAndValue = [Columns.Account, Columns.Product, Columns.Period, Columns.ShopperGroup]
                    }
                    break
                  case CallAPIModes.GetAPIWithTotal: // similar to GetAPI, just add the total product/market in the call
                    // when using GetAPI, one of (callBackendToGetAccountAndAllProducts and callBackendToGetProductAndAllAccounts) must be true, and the other must be false. The trees could be both single select or one single one multi
                    if(this.callBackendToGetAccountAndAllProducts) {
                      this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: [... new Set(this.totalAccount.concat([this.selectedAccountIds[0]]))], selectedProducts: ["ALL"], facts: this.getFactsToLoad()}))
                      this.filterBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
                      this.modeCategory = Columns.Product
                      this.fieldsWithoutPeriodAndValue = [Columns.Product, Columns.Account, Columns.Fact, Columns.ShopperGroup] // make sure Product at the first place
                      this.fieldsWithoutFactAndValue = [Columns.Product, Columns.Account, Columns.Period, Columns.ShopperGroup]
                    } else {
                      this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: ["ALL"], selectedProducts: [... new Set(this.totalProduct.concat([this.selectedProductIds[0]]))], facts: this.getFactsToLoad()}))
                      this.filterBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedAccountNodes)
                      this.modeCategory = Columns.Account
                      this.fieldsWithoutPeriodAndValue = [Columns.Account, Columns.Product, Columns.Fact, Columns.ShopperGroup] // make sure Account at the first place
                      this.fieldsWithoutFactAndValue = [Columns.Account, Columns.Product, Columns.Period, Columns.ShopperGroup]
                    }
                    break
                }

                this.setToDefaultIndividual()

                this.feedChartWithData()
                this.feedGridWithData()
              })
            })
      })
    })
  }

  public getAccountColorMapping() {
    for(let i = 0; i < this.selectedAccountIds.length; i ++) {
      let _id = this.selectedAccountIds[i]
      if(Object.keys(this.userDefinedAccountColor).includes(_id)) {
        this.accountColorMapping[_id] = this.userDefinedAccountColor[_id]
      } else {
        this.accountColorMapping[_id] = this.chartColors[i]
      }
    }
    // console.log("getAccountColorMapping | accountColorMapping: ", this.accountColorMapping)
  }

  public getProductColorMapping() {
    for(let i = 0; i < this.selectedProductIds.length; i ++) {
      let _id = this.selectedProductIds[i]
      if(Object.keys(this.userDefinedProductColor).includes(_id)) {
        this.productColorMapping[_id] = this.userDefinedProductColor[_id]
      } else {
        this.productColorMapping[_id] = this.chartColors[i]
      }
    }
    // console.log("getProductColorMapping | productColorMapping: ", this.productColorMapping)
  }

  /** Tree Account, Tree Product | get selected nodes */
  public getSelectedNodes = (event:any) => {
    // console.log("getSelectedNodes: ", event.selectedNodes)

    if(event.name === this.filterAccountTitle) {
      // the Account tree is clicked
      this.selectedAccountNodes = event.selectedNodes
      this.selectedAccountIds = this.selectedAccountNodes.map(n => n.Id)
      this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];
      this.getAccountColorMapping()

      this.filterValuesAccountData = this.selectedAccountNodes
      if(this.filterValuesAccountData.length === 0) {
        this.filterValuesAccountData = [NoFilterSelected]
        this.selectedFilterAccountItem = NoFilterSelected
      } else {
        if(!this.filterValuesAccountData.map(i=>i.Id).includes(this.selectedFilterAccountItem.Id)) {
          this.selectedFilterAccountItem = this.filterValuesAccountData[0]
        }
      }

      this.benchmarkAccountData = this.utilityService.getSelectBenchmarkData(this.selectedAccountNodes)     // only happen when clicking the muli tree, which get data from store
      if(this.benchmarkAccountData.filter(i => i.Id === this.selectedBenchmarkAccountItem.Id).length === 0) { // once the selectedBenchmarkItem is not existing in the new filterBenchmarkData (because user has deselected the item from the tree), the selectedBenchmarkItem should be reset to default
        this.selectedBenchmarkAccountItem = this.cs.NO_BENCHMARK_SELECTED
      }

      if(!this.callBackendToGetAccountAndAllProducts) {
        this.filterBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedAccountNodes)     // only happen when clicking the muli tree, which get data from store
        if(this.filterBenchmarkData.filter(i => i.Id === this.selectedBenchmarkItem.Id).length === 0) { // once the selectedBenchmarkItem is not existing in the new filterBenchmarkData (because user has deselected the item from the tree), the selectedBenchmarkItem should be reset to default
          this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        this.resetData()
        switch(this.callAPIMode) {
          case CallAPIModes.FilterAPI:
            this.store.dispatch(DashboardActions.filterDataGet({sourceTable: this.sourceTable, accounts: this.selectedAccountIds, products: this.selectedProductIds, periods: this.allPeriodIds, facts: this.getFactsToLoad()}))
            break

          case CallAPIModes.FilterAPIWithTotal:
            this.store.dispatch(DashboardActions.filterDataGet({sourceTable: this.sourceTable, accounts: [... new Set(this.totalAccount.concat(this.selectedAccountIds))], products: [... new Set(this.totalProduct.concat(this.selectedProductIds))], periods: this.allPeriodIds, facts: this.getFactsToLoad()}))
            break

          case CallAPIModes.GetAPI:
            this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: [this.selectedAccountIds[0]], selectedProducts: ["ALL"], facts: this.getFactsToLoad()}))
            break
          case CallAPIModes.GetAPIWithTotal:
            this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: [... new Set(this.totalAccount.concat([this.selectedAccountIds[0]]))], selectedProducts: ["ALL"], facts: this.getFactsToLoad()})) // make sure the data of total market BG1 is always fetched
            break
        }
      }
    }
    else {
      // the product tree is clicked
      this.selectedProductNodes = event.selectedNodes
      this.selectedProductIds = this.selectedProductNodes.map(n => n.Id)
      this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
      this.getProductColorMapping()

      this.filterValuesProductData = this.selectedProductNodes
      if(this.filterValuesProductData.length === 0) {
        this.filterValuesProductData = [NoFilterSelected]
        this.selectedFilterProductItem = NoFilterSelected
      } else {
        if(!this.filterValuesProductData.map(i=>i.Id).includes(this.selectedFilterProductItem.Id)) {
          this.selectedFilterProductItem = this.filterValuesProductData[0]
        }
      }

      this.benchmarkProductData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)     // only happen when clicking the muli tree, which get data from store
      if(this.benchmarkProductData.filter(i => i.Id === this.selectedBenchmarkProductItem.Id).length === 0) { // once the selectedBenchmarkItem is not existing in the new filterBenchmarkData (because user has deselected the item from the tree), the selectedBenchmarkItem should be reset to default
        this.selectedBenchmarkProductItem = this.cs.NO_BENCHMARK_SELECTED
      }

      this.filterProductBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
      if(this.filterProductBenchmarkData.filter(i => i.Id === this.selectedProductBenchmarkItem.Id).length === 0) {
        this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
      }

      if(!this.callBackendToGetProductAndAllAccounts) {
        /** handle Benchmark */
        this.filterBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
        if(this.filterBenchmarkData.filter(i => i.Id === this.selectedBenchmarkItem.Id).length === 0) {
          this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        this.resetData()
        switch(this.callAPIMode) {
          case CallAPIModes.FilterAPI:
            this.store.dispatch(DashboardActions.filterDataGet({sourceTable: this.sourceTable, accounts: this.selectedAccountIds, products: this.selectedProductIds, periods: this.allPeriodIds, facts: this.getFactsToLoad()}))
            break

          case CallAPIModes.FilterAPIWithTotal:
            this.store.dispatch(DashboardActions.filterDataGet({sourceTable: this.sourceTable, accounts: [... new Set(this.totalAccount.concat(this.selectedAccountIds))], products: [... new Set(this.totalProduct.concat(this.selectedProductIds))], periods: this.allPeriodIds, facts: this.getFactsToLoad()}))
            break

          case CallAPIModes.GetAPI:
            this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: ["ALL"], selectedProducts: [this.selectedProductIds[0]], facts: this.getFactsToLoad()}))
            break
          case CallAPIModes.GetAPIWithTotal:
            this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: ["ALL"], selectedProducts: [... new Set(this.totalProduct.concat([this.selectedProductIds[0]]))], facts: this.getFactsToLoad()}))  // make sure the data of total product P0 is always fetched
            break
        }
      }
    }

    // console.log("getSelectedNodes Account: ", this.selectedAccountNodes, this.selectedAccountIds)
    // console.log("getSelectedNodes Product: ", this.selectedProductNodes, this.selectedProductIds)

    this.feedChartWithData()
    this.feedGridWithData()
  }

  /** Tree Focus*/
  public getSelectedNodesFocus = (event:any) => {
    this.selectedAccountNodesFocus = event.selectedNodes
    this.selectedFocusAccountName = this.selectedAccountNodesFocus[0].Name

    // console.log("this.selectedAccountNodesFocus: ", this.selectedAccountNodesFocus)

    this.feedChartWithData()
  }

  /** Tree Competitor*/
  public getSelectedNodesCompetitor = (event:any) => {
    this.selectedAccountNodesCompetitor = event.selectedNodes

    this.selectedAccountNodeLevelsCompetitor = [... new Set(this.selectedAccountNodesCompetitor.map(n => n.Level))]

    // console.log("this.selectedAccountNodesCompetitor: ", this.selectedAccountNodesCompetitor, this.selectedAccountNodeLevelsCompetitor)

    this.feedChartWithData()
  }

  /** Tree Product - used in request requirement*/
  public getSelectedNodesProduct = (event:any) => {
    this.selectedProductNodes = event.selectedNodes
    this.selectedProductIds = this.selectedProductNodes.map(n => n.Id)
    // console.log("this.selectedProductNodes: ", this.selectedProductNodes)
    this.resetData()
    this.store.dispatch(DashboardActions.dataGet({sourceTable: this.sourceTable, selectedAccounts: ["ALL"], selectedProducts: [this.selectedProductIds[0]], facts: this.getFactsToLoad()}))

    this.feedChartWithData()
  }

  /** Select Period 1, Period 2 */
  public getSelectedPeriodItem = (event: any) => {
    if(event.name === this.filterPeriod1Title) {
      // selection Period 1 is changed
      this.selectedPeriod1Item = event.selectedItem
      this.selectedPeriod1ItemNames = [event.selectedItem.Name]
    }
    else if(event.name === this.filterPeriod2Title) {
      // selection Period 2 is changed
      this.selectedPeriod2Item = event.selectedItem
      this.selectedPeriod2ItemNames = [event.selectedItem.Name]
      // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)

      this.individualLogicForPeriod2()
    }
    this.feedGridWithData()
    this.feedChartWithData()
  }

  /** Select Shopper Group */
  public getSelectedShopperGroupItem = (event: any) => {
    this.selectedShopperGroupItem = event.selectedItem
    // console.log("this.selectedShopperGroupItem: ", this.selectedShopperGroupItem)
    this.filterValuesShopperGroupData = [this.selectedShopperGroupItem]
    if(this.filterValuesShopperGroupData.length === 0) {
      this.filterValuesShopperGroupData = [NoFilterSelected]
      this.selectedFilterShopperGroupItem = NoFilterSelected
    } else {
      if(!this.filterValuesShopperGroupData.map(i=>i.Id).includes(this.selectedFilterShopperGroupItem.Id)) {
        this.selectedFilterShopperGroupItem = this.filterValuesShopperGroupData[0]
      }
    }

    this.feedGridWithData()
    this.feedChartWithData()
  }

  /** Select Benchmark */
  public getSelectedBenchmarkItem = (event: any) => {
    // selection Benchmark is changed
    this.selectedBenchmarkItem = event.selectedItem

    this.getSelectedBenchmarkItemIndividual()

    // console.log("this.selectedBenchmarkItem: ", this.selectedBenchmarkItem)
    this.feedChartWithData()
  }

  // used in Reports (Table of facts & KPI comparison)
  public getSelectedProductBenchmarkItem = (event: any) => {
    // selection Benchmark is changed
    this.selectedProductBenchmarkItem = event.selectedItem
    this.feedGridWithData()   // for table of facts
    this.feedChartWithData()  // for KPI Comparison
  }

  /** Switch Penetration Warning */
  public getSwitchPenetrationWarningEvent = (event: any) => {
    this.hidePenetrationWarning = event["Checked"]
    // console.log("getSwitchPenetrationWarningEvent: ", event, this.hidePenetrationWarning, typeof this.hidePenetrationWarning)
    this.handlePenetrationWarning()
  }

  /** Select Single Chart Fact | get selected item | select one fact */
  public getSelectedChartFact = (event: any) => {
    this.selectedChartFactItem = event.selectedItem

    this.feedChartWithData()
  }

  /** Multi Grid Facts | get selected items */
  public getSelectedGridFacts = (event: any) => {
    this.selectedGridFactItemNames = event.itemNames
    this.selectedGridFactItemIds = event.itemIds
    // console.log("######this.selectedGridFactItemIds: ", this.selectedGridFactItemIds, this.selectedGridFactItemNames)
    this.feedGridWithData()
  }

  /** Select Mode | one product with many Accounts, one Account with many products*/
  public getSelectedMode = (event:any) => {
    // console.log(event)
    // console.log(event.selectedItem.Id)
    this.selectedModeItem = event.selectedItem
    // console.log("this.selectedModeItem: ", this.selectedModeItem)

    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.selectOneAccountMultiProducts(this.alwaysCallBackground)
        break

      case ModeIds.OneProductMultiAccounts:
        this.selectOneProductMultiAccounts(this.alwaysCallBackground)
        break
    }
  }

  private selectOneAccountMultiProducts = (alwaysCallBackground: boolean) => {
    // console.log("selectOneAccountMultiProducts")
    this.treeProductIsMulti = true
    this.callBackendToGetProductAndAllAccounts = alwaysCallBackground? true: false

    this.treeAccountIsMulti = false
    this.callBackendToGetAccountAndAllProducts = alwaysCallBackground? true: true

    this.selectedAccountNodes = this.selectedAccountNodes.length > 0 ? [this.selectedAccountNodes[0]] : []

    this.resetData()
    this.setToDefault(false, false, false, this.shouldLoadSelectionInSetToDefault)
    // console.log("Account: ", this.selectedAccountNodes, this.selectedAccountIds)
    // console.log("Product: ", this.selectedProductNodes, this.selectedProductIds)

  }

  private selectOneProductMultiAccounts = (alwaysCallBackground: boolean) => {
    // console.log("selectOneProductMultiAccounts")
    this.treeProductIsMulti = false
    this.callBackendToGetProductAndAllAccounts = alwaysCallBackground? true: true

    this.treeAccountIsMulti = true
    this.callBackendToGetAccountAndAllProducts = alwaysCallBackground? true: false

    this.selectedProductNodes = this.selectedProductNodes.length > 0 ? [this.selectedProductNodes[0]] : []

    this.resetData()
    this.setToDefault(false, false, false, this.shouldLoadSelectionInSetToDefault)

    // console.log("Account: ", this.selectedAccountNodes, this.selectedAccountIds)
    // console.log("Product: ", this.selectedProductNodes, this.selectedProductIds)

  }


  /** show Legend */
  public displayLegend = (event:any) => {
    if(event.legend.show === true) {
      this.showLegend = true
    } else {
      this.showLegend = false
    }
  }

  /** show DataLabel */
  // this method can be deleted when it is implemented in all individual dashboards
  public displayDataLabel = (event:any) => {
    // if(event.dataLabel.show === true) {
    //   this.showDataLabel = true
    // } else {
    //   this.showDataLabel = false
    // }

    // have to override in indivitual dashboard
  }

  /** Export */
  public isCreatingExport:boolean = false

  public getSelectedExportEvent = (event:any) => {
    // console.log(event)
    // console.log(event.selectedItem.Id)

    switch(event.selectedItem.Id) {
      case "excel": {
        this.isCreatingExport = true
        this.handleExportExcel()
        break
      }
      case "ppt": {
        this.isPowerpointMasterLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
          this.store.select<any>(fromAuth.selectPowerpointMaster)
          .pipe(first(), untilDestroyed(this))
          .subscribe(data  => {
            this.userDefinedPptMaster = data
            // console.log("base | export ppt |  this.userDefinedPptMaster: ", this.userDefinedPptMaster)
            let _singleTreeName = ''
            if(this.treeAccountIsMulti) {
              _singleTreeName = Ids.Product
            } else {
              _singleTreeName = Ids.Account
            }
            this.isCreatingExport = true
            this.handleExportPPT(_singleTreeName)
          })
        })
        break
      }
      case "multippt": {
        this.isPowerpointMasterLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
          this.store.select<any>(fromAuth.selectPowerpointMaster)
          .pipe(first(), untilDestroyed(this))
          .subscribe(data  => {
            this.userDefinedPptMaster = data
            if(this.treeAccountIsMulti) {
              this.exportMultiPptDialogComponent.deselectAllMultiProductTreeWithCheckbox()
            } else {
              this.exportMultiPptDialogComponent.deselectAllMultiAccountTreeWithCheckbox()
            }

            this.selectedMultiAccountNodes = []
            this.selectedMultiProductNodes = []

            this.exportMultiPptDialogComponent.open()
          })
        })
        break
      }
    }
  }

  /** Export Multi PPT */
  public getMultiSelectedNodes = (event: any) => {
    if(event.name === this.treeMultiAccountName) {
      this.selectedMultiAccountNodes = event.selectedNodes
      this.multiSelectedNodeLevels = [... new Set(this.selectedMultiAccountNodes.map(n => n.Level))];
    }
    else {
      this.selectedMultiProductNodes = event.selectedNodes
      this.multiSelectedNodeLevels = [... new Set(this.selectedMultiProductNodes.map(n => n.Level))];
    }
  }

  public exportMultiPPT = () => {
    let _singleTreeName = ''
    let _accounts:any [] = []
    let _products:any[] = []

    if(!this.treeAccountIsMulti) {
      _singleTreeName = Ids.Account
      _accounts = this.selectedMultiAccountNodes
      _products = this.selectedProductNodes

    } else {
      _singleTreeName = Ids.Product
      _accounts = this.selectedAccountNodes
      _products = this.selectedMultiProductNodes
    }

    if(this.multiSelectedNodeLevels.length == 0) {
      alert("Please select at least one " + _singleTreeName + " to start export")
      return
    }

    this.isCreatingExport = true
    this.handleExportMultiPPT(_singleTreeName, _accounts, _products)

    this.exportMultiPptDialogComponent.close()
  }

  /** Chart master */
  public chartMasterNextPage = (event:any) => {
    // should be overriden in dashboard
  }

  public cancelChartMaster = (event:any) => {
    this.chartMasterService.cancel();
  }

  private forChartMaster() {
    this.chartMasterTotalElements = this.chartMasterService.getTotalPagesNumber();
    this.chartMasterCurrentElement = this.chartMasterService.getCurrentPageNumber();

    this.chartMasterService.getStatus().subscribe((d) => {
      this.chartMasterActive = d;
    });

    this.chartMasterService.getPPT().subscribe((d) => {
      this.chartMasterPPT = d;
    });

    if(this.chartMasterActive === true){
      this.showExportExcel = false
      this.showExportPPT = false
      this.showExportMultiPPT = false
    }
    else{
      this.showExportExcel = true
      this.showExportPPT = true
      this.showExportMultiPPT = true
    }
  }


  /** ------------------------- Functions to Override --------------------------------------- */
  /** Load|Save Selection */
  loadSelection(selection: any[], loadSelectionFromBackend: boolean) {
    // must be override
    throw new Error('Method not implemented.');
  }

  saveSelectionToBackend() {
    // must be override
    throw new Error('Method not implemented.');
  }

  /** Chart */
  feedChartWithData(): void {}

  /** Grid */
  feedGridWithData(): void {}

  /** Benchmark */
  feedBenchmarkWithData(data: any[], accountIds: string[] = [], productIds: string[]=[]): void {}

  /** Export */
  handleExportPPT(singleTreeName: string) {}

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {}

  handleExportExcel() {}

  setToDefaultIndividualWhenReadingDim(dim: any[]) {
    // should be overriden in dashboard ts file, if necessary
  }

  setToDefaultIndividual() {
    // should be overriden in dashboard ts file, if necessary
  }

  /** when selecting period 2 */
  // in case of Period 2 not selected, then it should execute some speical logic
  individualLogicForPeriod2() {
    // have to be overridden in individual dashboard
  }

  getSelectedBenchmarkItemIndividual(){
    // have to be overridden in individual dashboard
  }

  /** Penetration warning */
  handlePenetrationWarning() {}

  /** ------------------------- Utils --------------------------------------- */
  resetData(cleanSelection: boolean = false) {
    switch(this.callAPIMode) {
      case CallAPIModes.FilterAPI:
        this.store.dispatch(DashboardActions.filterDataReset())
        break

      case CallAPIModes.FilterAPIWithTotal:
        this.store.dispatch(DashboardActions.filterDataReset())
        break

      case CallAPIModes.GetAPI:
        this.store.dispatch(DashboardActions.dataReset())              // to set the state "dataLoadStatus" to "NOT_LOADED"
        break
      case CallAPIModes.GetAPIWithTotal:
        this.store.dispatch(DashboardActions.dataReset())              // to set the state "dataLoadStatus" to "NOT_LOADED"
        break
    }

    if(cleanSelection) {
      this.store.dispatch(DashboardActions.selectionReset())
    }
  }

  // only "purchase behavior" has allowedGridFactIds, and it is same with the allowedChartFactIds. So we can take getFactsToLoad() for all the chart facts
  public getFactsToLoad() {
    return [... new Set(this.allowedChartFactIds.concat(this.allowedGridFactIds))]
  }

  getPeriodIds() {
    let periodIds = []
    if(this.hasPeriod2Filter) {
      if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
        periodIds = [this.selectedPeriod1Item.Id]
      } else {
        periodIds = [this.selectedPeriod1Item.Id, this.selectedPeriod2Item.Id]
      }
    } else {
      periodIds = [this.selectedPeriod1Item.Id]
    }
    return periodIds
  }

  getPeriodNames() {
    let periodNames = []
    if(this.hasPeriod2Filter) {
      if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
        periodNames = [this.selectedPeriod1Item.Name]
      } else {
        if(this.selectedPeriod1Item.Id === this.selectedPeriod2Item.Id) {
          periodNames = [this.selectedPeriod1Item.Name]
        } else {
          periodNames = [this.selectedPeriod1Item.Name, this.selectedPeriod2Item.Name]
        }
      }
    } else {
      periodNames = [this.selectedPeriod1Item.Name]
    }
    return periodNames
  }

  private getPeriod2Data(nodes: any[]) {
    let result : any[] = []
    result.push(this.cs.PERIOD2_NOT_SELECTED)
    for(let n of nodes) {
      result.push(n)
    }
    return result
  }

  setYaxisMinMaxInterval() {
    if(this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id)) {
      this.axisYInterval = 10
      this.axisYMaxValue = 100
      this.axisYMinValue = 0
    } else {
      this.axisYInterval = undefined
      this.axisYMaxValue = undefined
      this.axisYMinValue = 0
    }
  }

  /**
   * @param data
   * @param isFirstItemChecked
   * @returns a list of data, each has Id, Name and Checked. (Field "Checked" is used for checkbox-filter. For radio-filter, it is not used )
   */
  private convertToFilterData(data: any[], isFirstItemChecked: boolean = false) {
    let results = []
    for(let i = 0; i < data.length; i++) {
      let d = data[i]
      let result: SelectItemChecked = {
        Id: '',
        Name: '',
        Checked: false
      }
      result["Id"] = d.Id
      result["Name"] = d.Name
      if(isFirstItemChecked && i == 0) {
        result["Checked"] = true
      } else {
        result["Checked"] = false
      }
      results.push(result)
    }
    return results
  }
}
