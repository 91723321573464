<div class="sample-wrapper">
  <div class="flex flex-row justify-between mx-4 my-2">
    <ng-container *ngIf="showSelectAll">
      <igx-checkbox #checkboxSelectAll (click)="selectAll()">
        {{selectAllTitle}}
        </igx-checkbox>
    </ng-container>
    <igx-checkbox #checkboxSelectAllInHierarchy (click)="selectAllInHierarchy()">
      {{selectAllInHierarchyTitle}}
    </igx-checkbox>
  </div>

  <igx-tree #tree selection="BiState" class="tree-root" (nodeSelection)="handleNodeSelection($event)">
      <igx-tree-node *ngFor="let level1 of data" [expanded]="topLevelIsExpanded" [active]="false" [selected]="topLevelItemsAreSelected" [data]="level1" >
          <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level1 }"></ng-template>
          <igx-tree-node *ngFor="let level2 of level1.Children" [data]="level2" >
              <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level2 }"></ng-template>
              <igx-tree-node *ngFor="let level3 of level2.Children" [data]="level3">
                  <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level3 }"></ng-template>
                  <igx-tree-node *ngFor="let level4 of level3.Children" [data]="level4">
                    <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level4 }"></ng-template>
                    <igx-tree-node *ngFor="let level5 of level4.Children" [data]="level5">
                      <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level5 }"></ng-template>
                      <igx-tree-node *ngFor="let level6 of level5.Children" [data]="level6">
                        <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level6 }"></ng-template>
                        <igx-tree-node *ngFor="let level7 of level6.Children" [data]="level7">
                          <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level7 }"></ng-template>
                          <igx-tree-node *ngFor="let level8 of level7.Children" [data]="level8">
                            <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level8 }"></ng-template>
                            <igx-tree-node *ngFor="let level9 of level8.Children" [data]="level9">
                              <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level9 }"></ng-template>
                              <igx-tree-node *ngFor="let level10 of level9.Children" [data]="level10">
                                <ng-template *ngTemplateOutlet="nodeTemplate; context: { $implicit: level10 }"></ng-template>
                              </igx-tree-node>
                            </igx-tree-node>
                          </igx-tree-node>
                        </igx-tree-node>
                      </igx-tree-node>
                    </igx-tree-node>
                  </igx-tree-node>
              </igx-tree-node>
          </igx-tree-node>
      </igx-tree-node>
  </igx-tree>
  <ng-template #nodeTemplate let-data>
      <div class="node">
          <span class="node-title">{{ data.Name }}</span>
      </div>
  </ng-template>
</div>
