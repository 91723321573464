<div class="outer">
  <div *ngIf="isLoggedId$ | async; else login" class="inner_fixed">
    <app-header></app-header>
  </div>

  <ng-template #login>
    <app-header-login></app-header-login>
  </ng-template>

  <div class="inner_remaining">
    <router-outlet></router-outlet>
  </div>
</div>
