import { createAction, props } from '@ngrx/store';

import { User } from './auth.models';

const LOGIN_REQUEST = '[Auth] Login Request';
const LOGIN_SUCCESS = '[Auth] Login Success';
const LOGIN_FAILURE = '[Auth] Login Failure';

const LOGOUT_ACTION = '[Auth] Logout';

const AUTH_USER_REQUEST = '[Auth] Auth User Request';
const AUTH_USER_SUCCESS = '[Auth] Auth User Success';
const AUTH_USER_FAILURE = '[Auth] Auth User Failure';

const FORGET_PASSWORD_REQUEST = '[Auth] Forget Password Request';
const FORGET_PASSWORD_SUCCESS = '[Auth] Forget Password Success';
const FORGET_PASSWORD_FAILURE = '[Auth] Forget Password Failure';

const MAGIC_LOGIN_REQUEST = '[Auth] Magic Login Request';
const MAGIC_LOGIN_SUCCESS = '[Auth] Magic Login Success';
const MAGIC_LOGIN_FAILURE = '[Auth] Magic Login Failure';

const RESET_PASSWORD_REQUEST = '[Auth] Reset Password Request';
const RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success';
const RESET_PASSWORD_FAILURE = '[Auth] Reset Password Failure';

const UPDATE_DBID_REQUEST = '[Setting] Update DB ID Request';
const UPDATE_DBID_SUCCESS = '[Setting] Update DB ID Success';
const UPDATE_DBID_FAILURE = '[Setting] Update DB ID Failure';

const UPDATE_LANGUAGE_REQUEST = '[Setting] Update Language Request';
const UPDATE_LANGUAGE_SUCCESS = '[Setting] Update Language Success';
const UPDATE_LANGUAGE_FAILURE = '[Setting] Update Language Failure';

const UPDATE_DBID_LANGUAGE_REQUEST = '[Setting] Update Database and Language Request';
const UPDATE_DBID_LANGUAGE_SUCCESS = '[Setting] Update Database and Language Success';
const UPDATE_DBID_LANGUAGE_FAILURE = '[Setting] Update Database and Language Failure';

const UPDATE_PERSONAL_DATA_REQUEST = '[Setting] Update Personal Data Request';
const UPDATE_PERSONAL_DATA_SUCCESS = '[Setting] Update Personal Data Success';
const UPDATE_PERSONAL_DATA_FAILURE = '[Setting] Update Personal Data Failure';

const UPDATE_BASIC_COLORS_REQUEST = '[Setting] Update Basic Colors Request';
const UPDATE_BASIC_COLORS_SUCCESS = '[Setting] Update Basic Colors Success';
const UPDATE_BASIC_COLORS_FAILURE = '[Setting] Update Basic Colors Failure';

const UPDATE_CHART_MASTER_REQUEST = '[Setting] Update Chart Master Request';
const UPDATE_CHART_MASTER_SUCCESS = '[Setting] Update Chart Master Success';
const UPDATE_CHART_MASTER_FAILURE = '[Setting] Update Chart Master Failure';

const UPDATE_ACCOUNT_PRODUCT_COLOR_REQUEST = '[Setting] Update Account Product Color Request';
const UPDATE_ACCOUNT_PRODUCT_COLOR_SUCCESS = '[Setting] Update Account Product Color Success';
const UPDATE_ACCOUNT_PRODUCT_COLOR_FAILURE = '[Setting] Update Account Product Color Failure';

const UPDATE_POWERPOINT_MASTER_REQUEST = '[Setting] Update Powerpoint Master Request';
const UPDATE_POWERPOINT_MASTER_SUCCESS = '[Setting] Update Powerpoint Master Success';
const UPDATE_POWERPOINT_MASTER_FAILURE = '[Setting] Update Powerpoint Master Failure';

const PPT_MASTER_RESET  = '[Setting] PPT Master  Reset';
const PPT_MASTER_GET    = '[Setting] PPT Master  Get';
const PPT_MASTER_LOAD   = '[Setting] PPT Master  Load';
const PPT_MASTER_LOADED = '[Setting] PPT Master  Loaded';

// Login
const loginRequest = createAction(
  LOGIN_REQUEST,
  props<{ email: string; password: string }>()
);
const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{ user: User; redirect: boolean }>()
  );
const loginFailure = createAction(
  LOGIN_FAILURE,
  props<{ error: string }>()
);


// Logout
const logout = createAction(LOGOUT_ACTION);


// Auth User: me
const getAuthUserRequest = createAction(AUTH_USER_REQUEST);
const getAuthUserSuccess = createAction(
  AUTH_USER_SUCCESS,
  props<{ user: User; redirect: boolean }>()
);
const getAuthUserFailure = createAction(AUTH_USER_FAILURE);


// Forget Password => create magic link and send it with email
const forgetPasswordRequest = createAction(
  FORGET_PASSWORD_REQUEST,
  props<{ email: string }>()
);
const forgetPasswordSuccess = createAction(
  FORGET_PASSWORD_SUCCESS
  );
const forgetPasswordFailure = createAction(
  FORGET_PASSWORD_FAILURE,
  props<{ error: string }>()
);

// Magic Login => when clicking the magic link in the email
const magicLoginRequest = createAction(
  MAGIC_LOGIN_REQUEST,
  props<{ token: string }>()
);
const magicLoginSuccess = createAction(
  MAGIC_LOGIN_SUCCESS
  );
const magicLoginFailure = createAction(
  MAGIC_LOGIN_FAILURE,
  props<{ error: Error }>()
);


// Reset Password
const resetPasswordRequest = createAction(
  RESET_PASSWORD_REQUEST,
  props<{ password: string; passwordConfirm: string }>()
);
const resetPasswordSuccess = createAction(
  RESET_PASSWORD_SUCCESS,
  props<{ redirect: boolean }>()
);
const resetPasswordFailure = createAction(
  RESET_PASSWORD_FAILURE,
  props<{ error: string }>()
);


// Update database id
const updateDBIDRequest = createAction(
  UPDATE_DBID_REQUEST,
  props<{ dbid: number }>()
);
const updateDBIDSuccess = createAction(
  UPDATE_DBID_SUCCESS,
);
const updateDBIDFailure = createAction(
  UPDATE_DBID_FAILURE,
  props<{ error: string }>()
);

// Update language
const updateLanguageRequest = createAction(
  UPDATE_LANGUAGE_REQUEST,
  props<{ language: string }>()
);
const updateLanguageSuccess = createAction(
  UPDATE_LANGUAGE_SUCCESS,
);
const updateLanguageFailure = createAction(
  UPDATE_LANGUAGE_FAILURE,
  props<{ error: string }>()
);

// Update database and language
const updateDBIDLanguageRequest = createAction(
  UPDATE_DBID_LANGUAGE_REQUEST,
  props<{ dbid:number, language: string }>()
);
const updateDBIDLanguageSuccess = createAction(
  UPDATE_DBID_LANGUAGE_SUCCESS,
);
const updateDBIDLanguageFailure = createAction(
  UPDATE_DBID_LANGUAGE_FAILURE,
  props<{ error: string }>()
);

// Update Personal Data
const updatePersonalDataRequest = createAction(
  UPDATE_PERSONAL_DATA_REQUEST,
  props<{ firstname:string, lastname: string}>()
);
const updatePersonalDataSuccess = createAction(
  UPDATE_PERSONAL_DATA_SUCCESS,
  props<{ user: User }>()
);
const updatePersonalDataFailure = createAction(
  UPDATE_PERSONAL_DATA_FAILURE,
  props<{ error: string }>()
);

// Update Basic Colors
const updateBasicColorsRequest = createAction(
  UPDATE_BASIC_COLORS_REQUEST,
  props<{ firstColor:string, secondColor: string}>()
);
const updateBasicColorsSuccess = createAction(
  UPDATE_BASIC_COLORS_SUCCESS,
  props<{ user: User }>()
);
const updateBasicColorsFailure = createAction(
  UPDATE_BASIC_COLORS_FAILURE,
  props<{ error: string }>()
);

// Update Chart Master
const updateChartMasterRequest = createAction(
  UPDATE_CHART_MASTER_REQUEST,
  props<{ chartMaster:any}>()
);
const updateChartMasterSuccess = createAction(
  UPDATE_CHART_MASTER_SUCCESS,
);
const updateChartMasterFailure = createAction(
  UPDATE_CHART_MASTER_FAILURE,
  props<{ error: string }>()
);

// update Account Product Color
const updateAccountProductColorRequest = createAction(
  UPDATE_ACCOUNT_PRODUCT_COLOR_REQUEST,
  props<{ accountProduct: string,  accountProductColor:any}>()
);
const updateAccountProductColorSuccess = createAction(
  UPDATE_ACCOUNT_PRODUCT_COLOR_SUCCESS,
);
const updateAccountProductColorFailure = createAction(
  UPDATE_ACCOUNT_PRODUCT_COLOR_FAILURE,
  props<{ error: string }>()
);

// update Powerpoint master
const updatePowerpointMasterRequest = createAction(
  UPDATE_POWERPOINT_MASTER_REQUEST,
  props<{ powerpointMaster: string}>()
);
const updatePowerpointMasterSuccess = createAction(
  UPDATE_POWERPOINT_MASTER_SUCCESS,
  props<{ powerpointMaster: string }>()
);
const updatePowerpointMasterFailure = createAction(
  UPDATE_POWERPOINT_MASTER_FAILURE,
  props<{ error: string }>()
);

// get Powerpoint master
const powerpointMasterReset = createAction(
  PPT_MASTER_RESET
);

const powerpointMasterGet = createAction(
  PPT_MASTER_GET
);
const powerpointMasterLoad = createAction(
  PPT_MASTER_LOAD
);
const powerpointMasterLoaded = createAction(
  PPT_MASTER_LOADED,
  props<{ powerpointMaster: string }>()
);

export const AuthActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  getAuthUserRequest,
  getAuthUserSuccess,
  getAuthUserFailure,
  forgetPasswordRequest,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  magicLoginRequest,
  magicLoginSuccess,
  magicLoginFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateDBIDRequest,
  updateDBIDSuccess,
  updateDBIDFailure,
  updateLanguageRequest,
  updateLanguageSuccess,
  updateLanguageFailure,
  updateDBIDLanguageRequest,
  updateDBIDLanguageSuccess,
  updateDBIDLanguageFailure,
  updatePersonalDataRequest,
  updatePersonalDataSuccess,
  updatePersonalDataFailure,
  updateBasicColorsRequest,
  updateBasicColorsSuccess,
  updateBasicColorsFailure,
  updateChartMasterRequest,
  updateChartMasterSuccess,
  updateChartMasterFailure,
  updateAccountProductColorRequest,
  updateAccountProductColorSuccess,
  updateAccountProductColorFailure,
  updatePowerpointMasterRequest,
  updatePowerpointMasterSuccess,
  updatePowerpointMasterFailure,
  powerpointMasterReset,
  powerpointMasterGet,
  powerpointMasterLoad,
  powerpointMasterLoaded,

}
