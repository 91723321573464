import { NgModule, APP_INITIALIZER, Injector} from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnvService, envServiceFactory } from './shared/services/env.service';
import { environment } from 'src/environments/environment';
import { AuthEffects } from './auth/state/auth.effects';

import { AuthModule } from './auth/auth.module';
import { authInterceptorProviders } from './auth/interceptors';
import { DashboardModule } from './dashboard/dashboard.module';
import { HomeComponent } from './features/home/home.component';
import { SharedUIModule } from './shared/ui/shared-ui.module';
import { IgxPreventDocumentScrollModule } from './shared/directives/prevent-scroll.directive';
import { HashLocationStrategy, LocationStrategy, LOCATION_INITIALIZED } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { metaReducers } from './auth/state';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient)
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.addLangs(['en', 'de'])
      let defaultLanguage = "de"
      translate.setDefaultLang(defaultLanguage);
      resolve(null)
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    IgxPreventDocumentScrollModule,

    //Angular
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HammerModule,
    HttpClientModule,

    // Application
    AppRoutingModule,
    SharedUIModule,

    // Auth
    AuthModule,
    DashboardModule,

    StoreModule.forRoot({}, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }), //The StoreModule.forRoot() method registers the global providers needed to access the Store throughout your application.
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot([AuthEffects]),

    // !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreDevtoolsModule.instrument({ maxAge: 10, logOnly: environment.production }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    EnvService,
    {
      provide: APP_INITIALIZER,
      useFactory: envServiceFactory,
      deps: [EnvService],
      multi: true
    },
    // Interceptors
    ...authInterceptorProviders,

    // to solve the page-not-found-on-refresh problem
    {provide: LocationStrategy, useClass: HashLocationStrategy},

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }

  ],
  bootstrap: [AppComponent]



})
export class AppModule {
}
