import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthFacade } from '../../state/auth.facade';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../../dashboard/shared/services/constant.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  readonly loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', {
      validators: [
        Validators.required,
        Validators.pattern("[^ @]*@[^ @]*")
      ],
    }),
    password: new UntypedFormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(8)
      ],
    })
  });

  isLoading$ = this.authFacade.isLoadingLogin$;
  showLoginError$ = this.authFacade.hasLoginError$;

  constructor(private authFacade: AuthFacade, private router: Router, public translate: TranslateService, public cs: ConstantService) {
  }

  submit() {
    const { email, password } = this.loginForm.value;
    this.authFacade.login(email, password);
    this.router.navigateByUrl('/dashboard/propensity/definition-of-key-facts');
  }

  forgetPassword() {
    this.router.navigateByUrl('/forget-password');
  }
}
