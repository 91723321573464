import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropensityRoutingModule } from './propensity.routing.module';
import { LoyaltyPropensityRankingComponent } from './loyalty-propensity-ranking/loyalty-propensity-ranking.component';
import { PurchaseBehaviorComponent } from './purchase-behavior/purchase-behavior.component';
import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';

import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { DefinitionOfKeyFactsComponent } from './definition-of-key-facts/definition-of-key-facts.component';
import { AnalysisOfStrengthsAndWeaknessesComponent } from './analysis-of-strengths-and-weaknesses/analysis-of-strengths-and-weaknesses.component';
import { StructureOfAccountClientsComponent } from './structure-of-account-clients/structure-of-account-clients.component';


@NgModule({
  declarations: [
    PurchaseBehaviorComponent,
    LoyaltyPropensityRankingComponent,
    DefinitionOfKeyFactsComponent,
    AnalysisOfStrengthsAndWeaknessesComponent,
    StructureOfAccountClientsComponent,
  ],
  providers: [IgxExcelExporterService],
  imports: [
    CommonModule,
    PropensityRoutingModule,
    SharedDashboardComponentModule,
  ]
})
export class PropensityModule { }
