<igx-tabs tabAlignment="start" [disableAnimation]="true">
  <igx-tab-item [selected]="true" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>store_mall_directory</igx-icon>
      <span igxTabHeaderLabel>{{tabAccountTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodes"></app-tree-with-checkbox-with-ten-levels>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>shopping_cart</igx-icon>
      <span igxTabHeaderLabel>{{tabProductTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <app-tree-with-checkbox-with-ten-levels #productTreeWithCheckbox [data]="filterProductData" [name]="filterProductTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedProductNodeLevels" [selectedNodes]="selectedProductNodes"></app-tree-with-checkbox-with-ten-levels>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>euro_symbol</igx-icon>
      <span igxTabHeaderLabel>{{tabPeriodFactTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <div>
            <app-single-select-radio [name]="filterPeriod1Title" [items]="filterPeriod1Data" [selected]="selectedPeriod1Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)" ></app-single-select-radio>
            <app-single-select-radio [name]="filterPeriod2Title" [items]="filterPeriod2Data" [selected]="selectedPeriod2Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)" ></app-single-select-radio>
        </div>
        <app-tree-with-checkbox-cascading [data]="treeFactData" [name]="treeFactName" (getSelectedNodesEvent)="getSelectedFactNodes($event)" [topLevelItemsAreSelected]="false" [topLevelIsExpanded]="false"></app-tree-with-checkbox-cascading>
      </div>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>insert_chart_outlined</igx-icon>
      <span igxTabHeaderLabel>{{tabSelectionTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <app-single-select-radio [name]="filterShopperGroupTitle" [items]="filterShopperGroupData" [selected]="selectedShopperGroupItem.Id" (getSelectedItemEvent)="getSelectedShopperGroupItem($event)" ></app-single-select-radio>

        <app-single-select [name]="filterBenchmarkTitle" [items]="filterBenchmarkData" [selected]="selectedBenchmarkItem.Id" (getSelectedItemEvent)="getSelectedBenchmarkItem($event)" ></app-single-select>

        <app-single-select-radio [name]="selectRowTitle" [items]="selectRowData" [selected]="selectedRowItem.Id" (getSelectedItemEvent)="getSelectedRowItem($event)" ></app-single-select-radio>

        <app-single-select-radio [name]="selectColumnTitle" [items]="selectColumnData" [selected]="selectedColumnItem.Id" (getSelectedItemEvent)="getSelectedColumnItem($event)" ></app-single-select-radio>

        <app-single-select-radio [disabled]="matModeIsDisabled" [name]="selectMatModeTitle" [items]="selectMatModeData" [selected]="selectedMatModeItem.Id" (getSelectedItemEvent)="getSelectedMatModeItem($event)" ></app-single-select-radio>

        <app-single-select [name]="selectFilterNumberOfColumnsTitle" [items]="filterValuesNumberOfColumnsData" [selected]="selectedFilterNumberOfColumnsItem.Id" (getSelectedItemEvent)="getSelectedFilterNumberOfColumnsItem($event)"></app-single-select>

        <div>
          <div class="tab-heading">{{chartSettingSaveValues}}</div>
          <app-single-select #filterAccountSelect [name]="selectFilterAccountTitle" [items]="filterValuesAccountData" [selected]="selectedFilterAccountItemId" (getSelectedItemEvent)="getSelectedFilterAccountItem($event)" [hidden]="accountHidden"></app-single-select>
          <app-single-select #filterProductSelect [name]="selectFilterProductTitle" [items]="filterValuesProductData" [selected]="selectedFilterProductItemId" (getSelectedItemEvent)="getSelectedFilterProductItem($event)" [hidden]="productHidden" ></app-single-select>
          <app-single-select #filterFactSelect [name]="selectFilterFactTitle" [items]="filterValuesFactData" [selected]="selectedFilterFactItemId" (getSelectedItemEvent)="getSelectedFilterFactItem($event)" [hidden]="factHidden" ></app-single-select>
          <app-single-select #filterShopperGroupSelect [name]="selectFilterShopperGroupTitle" [items]="filterValuesShopperGroupData" [selected]="selectedFilterShopperGroupItem.Id" (getSelectedItemEvent)="getSelectedFilterShopperGroupItem($event)" [hidden]="shopperGroupHidden"></app-single-select>
        </div>

        <div class="tab-heading">{{chartSettingTitle}}</div>
        <div class="flex flex-col">
          <igx-switch labelPosition="after" [checked]="showLegend" (change)="toggleLegend($event)">{{chartSettingLegendTitle}}</igx-switch>
          <igx-switch labelPosition="after" [checked]="showDataLabel" (change)="toggleDataLabel($event)">{{chartSettingDatalabelTitle}}</igx-switch>
        </div>

        <div>
          <div class="tab-heading">{{exportTitle}}</div>
          <igx-buttongroup>
            <button igxButton="outlined" *ngFor="let item of exportItems" (buttonClick)="export(item.Value)">{{item.Name}}</button>
          </igx-buttongroup>
        </div>
      </div>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>
