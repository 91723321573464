<section class="container_content">
  <div class="setting-content">
    <igx-tabs tabAlignment="justify">

      <igx-tab-item>
          <igx-tab-header>
              <span igxTabHeaderLabel>{{chartMasterTitle}}</span>
          </igx-tab-header>

          <igx-tab-content>
            <div class="setting-tab-content">
              <div class="chart-master-heading">{{selectChartMasterTitle}}</div>
              <div class="flex flex-row">
                <div class="basis-1/3">
                  <igx-select [(ngModel)]="selectedChartMasterTemplateName" (ngModelChange)="chartMasterOnchange()" #select>
                      <igx-select-item *ngFor="let item of chartMasterTemplates | keyvalue" [value]="item.key" [text]="item.key">
                            {{item.key}}
                      </igx-select-item>
                  </igx-select>
                </div>
                <div class="basis-1/6">
                  <button igxButton="icon" (click)="dialog.open()" [disabled]="selectedChartMasterTemplateName === ''"><igx-icon fontSet="material">delete</igx-icon> </button>
                </div>
                <div class="basis-1/3">
                  <form>
                    <igx-input-group>
                        <input title="new_name" igxInput name="new_chart_master_name" type="text" [(ngModel)]="newChartMasterTemplateName" [placeholder]="cs.CHART_MASTER_ADD_TIP"/>
                        <label igxLabel for="new_chart_master_name">{{createChartMasterTitle}}</label>
                    </igx-input-group>
                    <igx-dialog #dialog [title]="cs.DELETE_CHART_MASTER"
                    [leftButtonLabel]="cs.CANCEL"
                    (leftButtonSelect)="dialog.close()"
                    rightButtonLabel="OK"
                    (rightButtonSelect)="deleteChartMasterTemplate($event)"
                    [message]="cs.DELETE_CHART_MASTER_CONFIRMATION">
                    </igx-dialog>
                  </form>
                </div>

                <div class="basis-1/6">
                  <button igxButton="icon" (click)="addChartMasterTemplate()" [disabled]="newChartMasterTemplateName.trim() === ''"> <igx-icon fontSet="material">add</igx-icon> </button>
                </div>
              </div>

              <div class="flex flex-row">
                <div class="basis-1/2">
                  <div class="chart-master-heading">{{availableDashboardsTitle}}</div>
                    <div
                    cdkDropList
                    #moviesList="cdkDropList"
                    [cdkDropListData]="dashboardsAvailable"
                    [cdkDropListConnectedTo]="[doneMovieList]"
                    class="movie-list"
                    (cdkDropListDropped)="onDrop($event)">
                      <div class="movie-block" *ngFor="let moviesList of dashboardsAvailable" cdkDrag>
                        <div class="chart-master-item-name">{{moviesList.name}} | <span class="chart-master-item-group">{{moviesList.group}}</span></div>
                      </div>
                    </div>
                </div>
                <div class="basis-1/2">
                  <div class="chart-master-heading">{{selectedDashboardsTitle}}</div>
                  <div
                  cdkDropList
                  #doneMovieList="cdkDropList"
                  [cdkDropListData]="dashboardsSelected"
                  [cdkDropListConnectedTo]="[moviesList]"
                  class="movie-list"
                  (cdkDropListDropped)="onDrop($event)">
                    <div class="movie-block" *ngFor="let dashboard of dashboardsSelected" cdkDrag>
                      <div class="chart-master-item-name">{{dashboard.name}} | <span class="chart-master-item-group">{{dashboard.group}}</span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button igxButton="raised" class="button-green" igxRipple (click)="saveChartMasterTemplate()" [disabled]="chartMasterSavedLatest === json.stringify(chartMasterTemplates)">{{saveTitle}}</button>
                <button igxButton="raised" class="button-green create-powerpoint" igxRipple (click)="startChartMasterProcess()" [disabled]="dashboardsSelected.length === 0">{{createPPTTitle}}</button>
              </div>
            </div>
          </igx-tab-content>
      </igx-tab-item>



    </igx-tabs>
  </div>

  <div class="loading" *ngIf="chartMasterIsLoading === true || (isChartMasterSaving$ | async) === true || (isPowerpointMasterLoading$ | async) === true || (isPowerpointMasterSaving$ | async) === true"></div>
  <igx-toast #toast_presentation></igx-toast>
</section>
