import { Component, ElementRef, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ConnectedPositioningStrategy, HorizontalAlignment, IgxIconService, NoOpScrollStrategy, VerticalAlignment } from '@infragistics/igniteui-angular';

import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { AuthFacade } from '../../../../auth/state/auth.facade';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../../../dashboard/shared/services/constant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // readonly menuPublicItems = [
  //   { link: '/home', label: 'Home', icon: null },
  // ];

  public menuProtectedItems: any[] = this.cs.HEADER_ITEMS


  authUser$ = this.authFacade.user$;
  isLoggedId$ = this.authFacade.isLoggedIn$;

  public chartMasterActive   = false;

  ngOnInit(): void {
    // register custom SVG icons
    this.iconService.addSvgIcon('logout', '/assets/logout.svg', 'filter-icons');
    this.iconService.addSvgIcon('language', '/assets/language.svg', 'filter-icons');

    this.chartMasterService.getStatus().subscribe((d) => {
      this.chartMasterActive = d;
    });
  }

  constructor(
    public elem: ElementRef,
    public translate: TranslateService,
    private authFacade: AuthFacade,
    private iconService: IgxIconService,
    private chartMasterService:ChartMasterService,
    private location: Location,
    public cs: ConstantService,
    ) {}

  logout() {
    // console.log("CALL API When logging out #################")
    this.authFacade.logout();
  }


  public overlaySettings = {
      positionStrategy: new ConnectedPositioningStrategy({
          horizontalDirection: HorizontalAlignment.Left,
          horizontalStartPoint: HorizontalAlignment.Right,
          verticalStartPoint: VerticalAlignment.Bottom
      }),
      scrollStrategy: new NoOpScrollStrategy()
  };

  isSelected(link: string) {
    if(this.location.path().includes(link)) {
      return true
    } else {
      return false
    }
  }
}
