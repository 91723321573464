import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './error-routing/not-found/not-found.component';
import { ErrorRoutingModule } from './error-routing/error-routing.module';
import { AuthGuardService } from './auth/guards';
import { HomeComponent } from './features/home/home.component';

export const routes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '', redirectTo: 'auth/login', pathMatch: 'full'},
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m=> m.AuthModule),
  },

  { path: 'home', component: HomeComponent},

  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m=> m.DashboardModule),
    canActivate: [AuthGuardService],
  },

  { path: '**', component: PageNotFoundComponent } // must always be last
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}), ErrorRoutingModule],
  exports: [RouterModule, ErrorRoutingModule]
})
export class AppRoutingModule {
}
