import { Action, createReducer, on } from "@ngrx/store";
import { DashboardActions } from "./dashboard.actions";


export const DASHBOARD_FEATURE_KEY = 'dashboard';

export interface DashboardState {
  dataLoadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED';
  data: any[];
  dimLoadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED';
  dim: any[];
  filterDataloadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED';
  filterData: any[];
  hhLoadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED';
  hh: any[];
  selectionLoadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED';
  selectionSaveStatus: 'NOT_SAVED' | 'SAVING' | 'SAVED';
  selection: any[];
}

export interface DashboardAppState {
  [DASHBOARD_FEATURE_KEY]: DashboardState;
}

export const initialState: DashboardState = {
  dataLoadStatus: 'NOT_LOADED',
  data: [],
  dimLoadStatus: 'NOT_LOADED',
  dim: [],
  filterDataloadStatus: 'NOT_LOADED',
  filterData: [],
  hhLoadStatus: 'NOT_LOADED',
  hh: [],
  selectionLoadStatus: 'NOT_LOADED',
  selectionSaveStatus: 'NOT_SAVED',
  selection: [],
};

const dashboardReducer = createReducer(
  initialState,

  on(DashboardActions.dataReset, (state) => ({
    ...state,
    dataLoadStatus: 'NOT_LOADED',
    data:[]
  })),
  on(DashboardActions.dataLoad, (state) => ({
    ...state,
    dataLoadStatus: 'LOADING'
  })),
  on(DashboardActions.dataLoaded, (state, { data }) => ({
    ...state,
    data,
    dataLoadStatus: 'LOADED'
  })),


  on(DashboardActions.dimReset, (state) => ({
    ...state,
    dim: [],
    dimLoadStatus: 'NOT_LOADED'
  })),
  on(DashboardActions.dimLoad, (state) => ({
    ...state,
    dimLoadStatus: 'LOADING'
  })),
  on(DashboardActions.dimLoaded, (state, { dim }) => ({
    ...state,
    dim,
    dimLoadStatus: 'LOADED'
  })),

  on(DashboardActions.filterDataReset, (state) => ({
    ...state,
    filterDataloadStatus: 'NOT_LOADED',
    filterData: []
  })),
  on(DashboardActions.filterDataLoad, (state) => ({
    ...state,
    filterDataloadStatus: 'LOADING'
  })),
  on(DashboardActions.filterDataLoaded, (state, { filterData }) => ({
    ...state,
    filterData,
    filterDataloadStatus: 'LOADED'
  })),

  on(DashboardActions.hhReset, (state) => ({
    ...state,
    hh: [],
    hhLoadStatus: 'NOT_LOADED'
  })),
  on(DashboardActions.hhLoad, (state) => ({
    ...state,
    hhLoadStatus: 'LOADING'
  })),
  on(DashboardActions.hhLoaded, (state, { hh }) => ({
    ...state,
    hh,
    hhLoadStatus: 'LOADED'
  })),

  on(DashboardActions.selectionReset, (state) => ({
    ...state,
    selectionLoadStatus: 'NOT_LOADED',
    selectionSaveStatus: 'NOT_SAVED',
    selection:[]
  })),
  on(DashboardActions.selectionLoad, (state) => ({
    ...state,
    selectionLoadStatus: 'LOADING'
  })),
  on(DashboardActions.selectionLoaded, (state, { selection }) => ({
    ...state,
    selection,
    selectionLoadStatus: 'LOADED'
  })),
  on(DashboardActions.selectionSave, (state) => ({
    ...state,
    selectionSaveStatus: 'SAVING'
  })),
  on(DashboardActions.selectionSaved, (state) => ({
    ...state,
    selectionSaveStatus: 'SAVED'
  })),
);

export function reducer(state: DashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}
