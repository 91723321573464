import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstantService } from '../../../services/constant.service';

@Component({
  selector: 'app-penetration-warning',
  templateUrl: './penetration-warning.component.html',
  styleUrls: ['./penetration-warning.component.scss']
})
export class PenetrationWarningComponent {
  @Input() public isChecked: boolean = false;
  @Input() public hideSwitch: boolean = false;
  @Output() getSwitchPenetrationWarningEvent = new EventEmitter<any>();

  public penetrationWarning: string = this.cs.PENETRATION_WARNING
  public penetrationWarningTooltip: string = this.cs.PENETRATION_WARNING_TOOLTIP
  public hidePenetrationWarning: string = this.cs.HIDE_PENETRATION_WARNING

  constructor(public cs: ConstantService) { }

  onChange(event: any): void {
    // console.log(event, event.checked)
    let div = document.getElementById("p-w-div")
    div?.setAttribute("pwIsHidden", event.checked)  // pivot table of "table of facts" will use this attribute to show/hide penetration warning
    this.getSwitchPenetrationWarningEvent.emit({"Checked": event.checked});
  }

}
