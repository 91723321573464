<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
      [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

      [tabAccountTitle]="tabAccountTitle"
      [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

      [tabProductTitle]="tabProductTitle"
      [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

      [tabPeriodFactTitle]="tabPeriodFactTitle"
      [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"

      [tabSelectionTitle]="tabSelectionTitle"
      [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
      [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

      [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard" id="chart">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      [hideSwitch]="true"
      ></app-penetration-warning>

      <div class="card-content-info">
        <div class="card-section-info-a">{{period}}</div>
        <div class="card-section-info-b">
          <div class="card-section-info-b-MAT-1">{{selectedPeriod1ItemNames[0]}}</div>
          <div class="card-section-info-b-MAT-2">{{selectedPeriod2ItemNames[0]}}</div>
        </div>
      </div>

      <p-organizationChart [value]="data" selectionMode="single" styleClass="company">
        <ng-template let-node pTemplate="person">
            <div class="card-content">
            <div class="card-section-a">{{node.label}}</div>
            <div class="card-section-b">
              <div class="card-section-b-MAT-1">{{node.data.MAT1}}</div>
              <div class="card-section-b-MAT-2">{{node.data.MAT2}}</div>
            </div>
          </div>
        </ng-template>
      </p-organizationChart>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [multiSelectedNodeLevels]="multiSelectedNodeLevels"
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>

</section>
