<igx-tabs tabAlignment="start" [disableAnimation]="true" >
  <igx-tab-item [selected]="true" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>store_mall_directory</igx-icon>
      <span igxTabHeaderLabel >{{tabFocusTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <app-tree-without-checkbox-with-ten-levels #accountTreeWithoutCheckbox [data]="filterAccountDataFocus" [name]="filterAccountTitleFocus" (getActivatedNodeEvent)="getSelectedNodesFocus($event)" [selectedNodes]="selectedAccountNodesFocus"></app-tree-without-checkbox-with-ten-levels>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>store_mall_directory</igx-icon>
      <span igxTabHeaderLabel >{{tabCompetitorTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountDataCompetitor" [name]="filterAccountTitleCompetitor" (getSelectedNodesEvent)="getSelectedNodesCompetitor($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodesCompetitor"></app-tree-with-checkbox-with-ten-levels>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>shopping_cart</igx-icon>
      <span igxTabHeaderLabel >{{tabProductTitle}}</span>
    </igx-tab-header>
    <igx-tab-content >
      <app-tree-without-checkbox-with-ten-levels #productTreeWithoutCheckbox [data]="filterProductData" [name]="filterProductTitle" (getActivatedNodeEvent)="getSelectedNodesProduct($event)" [selectedNodes]="selectedProductNodes"></app-tree-without-checkbox-with-ten-levels>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>insert_chart_outlined</igx-icon>
      <span igxTabHeaderLabel>{{tabSelectionTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <app-single-select-radio [name]="filterShopperGroupTitle" [items]="filterShopperGroupData" [selected]="selectedShopperGroupItem.Id" (getSelectedItemEvent)="getSelectedShopperGroupItem($event)" ></app-single-select-radio>

        <ng-container *ngIf="showOneSinglePeriodFilter">
          <app-single-select-radio [name]="filterPeriod1Title" [items]="filterPeriod1Data" [selected]="selectedPeriod1Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)"></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showTwoSinglePeriodFilters">
            <app-single-select-radio [name]="filterPeriod1Title" [items]="filterPeriod1Data" [selected]="selectedPeriod1Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)" ></app-single-select-radio>
            <app-single-select-radio [name]="filterPeriod2Title" [items]="filterPeriod2Data" [selected]="selectedPeriod2Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)" ></app-single-select-radio>
        </ng-container>

        <app-single-select-radio [name]="selectSingleFactTitle" [items]="selectSingleFactData" [selected]="selectedSingleFactItem.Id" (getSelectedItemEvent)="getSelectedSingleFact($event)" ></app-single-select-radio>

        <ng-container *ngIf="showStaticFilter">
          <app-single-select-radio [name]="selectStaticFilterTitle" [items]="selectStaticFilterData" [selected]="selectedStaticFilterItem.Id" (getSelectedItemEvent)="getSelectedStaticFilterItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showRestRequirementIndex">
          <igx-switch labelPosition="after" [checked]="showIndexChart" (change)="toggleRestRequirementIndex($event)">{{showRRIndexTitle}}</igx-switch>
        </ng-container>

        <div class="flex flex-col">
          <ng-container *ngIf="showCheckboxLegend || showCheckboxDataLabel">
            <div class="tab-heading">{{chartSettingTitle}}</div>
          </ng-container>

          <ng-container *ngIf="showCheckboxLegend">
            <igx-switch labelPosition="after" [checked]="showLegend" (change)="toggleLegend($event)">{{chartSettingLegendTitle}}</igx-switch>
          </ng-container>

          <ng-container *ngIf="showCheckboxDataLabel">
            <igx-switch labelPosition="after" [checked]="showDataLabel" (change)="toggleDataLabel($event)">{{chartSettingDatalabelTitle}}</igx-switch>
          </ng-container>
        </div>

        <ng-container *ngIf="showExportExcel || showExportPPT">
          <div class="tab-heading">{{exportTitle}}</div>
          <igx-buttongroup>
            <button igxButton="outlined" *ngFor="let item of exportItems" (buttonClick)="export(item.Value)">{{item.Name}}</button>
          </igx-buttongroup>
        </ng-container>

      </div>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>
