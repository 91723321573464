<section class="container_content">
  <div class="leftmenu">
      <app-filter-tabs-rest-requirement
        [getSelectedPeriodItem]="getSelectedPeriodItem"

        [tabFocusTitle]="tabFocusTitle"
        [filterAccountTitleFocus]="filterAccountTitleFocus" [filterAccountDataFocus]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [getSelectedNodesFocus]="getSelectedNodesFocus" [selectedAccountNodesFocus]="selectedAccountNodesFocus"

        [tabCompetitorTitle]="tabCompetitorTitle"
        [filterAccountTitleCompetitor]="filterAccountTitleCompetitor" [filterAccountDataCompetitor]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevelsCompetitor" [getSelectedNodesCompetitor]="getSelectedNodesCompetitor" [selectedAccountNodesCompetitor]="selectedAccountNodesCompetitor"

        [tabProductTitle]="tabProductTitle"
        [filterProductTitle]="filterProductTitle" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [getSelectedNodesProduct]="getSelectedNodesProduct" [selectedProductNodes]="selectedProductNodes"

        [tabSelectionTitle]="tabSelectionTitle"
        [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
        [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"
        [selectSingleFactTitle]="filterChartFactTitle" [selectSingleFactData]="selectFactRestRequirementData" [selectedSingleFactItem]="selectedFactRestRequirementItem" [getSelectedSingleFact]="getSelectedFactRestRequirement"
        [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="false" (getSelectedExportEvent)="getSelectedExportEvent($event)"
        [showRestRequirementIndex]="false"
        [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
        [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"

        [isDataLoading$]="isDataLoading$"
      ></app-filter-tabs-rest-requirement>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitleFocus"       [selectedAccountNodes]="selectedAccountNodesFocus"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBasisTitle]="factTitle"                       [selectedBasis]="selectedFactRestRequirementItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="true" [showInfoBasis]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item.Account}}</div>
        <div><font [color]="configService.COLOR_RED">{{item.Series}}:</font> {{item[ids.Label_Account_To]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item.Account}}</div>
        <div><font [color]="configService.COLOR_GREEN">{{item.Series}}:</font> {{item[ids.Label_Account_From]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip3>
        <div>{{item.Account}}</div>
        <div><font [color]="configService.COLOR_GREY">{{item.Series}}:</font> {{item[ids.Label_Account_Net]}}</div>
      </ng-template>

      <div class="dashboard-area">
        <div class="accountbalance_bar1">
          <div class="chart-title">{{from}} <span class="focus_account">{{selectedFocusAccountName}}</span> {{cs.TO}} ...</div>

          <igx-data-chart
          name="CHART_ACCOUNTBALANCE_TO_Chart"
          width="300px"
          height="600px"
          [legend]="legendBar1"
          yAxisMinimumValue="0"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          xAxisInverted="true"
          #CHART_ACCOUNTBALANCE_TO_Chart>
              <igx-category-y-axis
              [dataSource]="CHART_ACCOUNTBALANCE"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              axis_maximumValue
              label="Account"
              labelVisibility="collapsed"
              name="CHART_ACCOUNTBALANCE_TO_yAxis"
              #CHART_ACCOUNTBALANCE_TO_yAxis>
              </igx-category-y-axis>
              <igx-numeric-x-axis
              [minimumValue]="axis_minimumValue"
              maximumValue="0"
              labelVisibility="collapsed"
              majorStrokeThickness="0"
              name="CHART_ACCOUNTBALANCE_TO_xAxis"
              #CHART_ACCOUNTBALANCE_TO_xAxis>
              </igx-numeric-x-axis>
              <igx-category-highlight-layer
              name="CHART_ACCOUNTBALANCE_TO_CategoryHighlightLayer"
              #categoryHighlightLayer>
              </igx-category-highlight-layer>

              <igx-bar-series
              [xAxis]="CHART_ACCOUNTBALANCE_TO_xAxis"
              [yAxis]="CHART_ACCOUNTBALANCE_TO_yAxis"
              valueMemberPath="Value_Account_To"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="CHART_ACCOUNTBALANCE"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1ItemNames[0]"
              [brush]="configService.COLOR_RED"
              name="CHART_ACCOUNTBALANCE_TO_BarSeries1"
              #CHART_ACCOUNTBALANCE_TO_barSeries1>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="CHART_ACCOUNTBALANCE" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_ACCOUNTBALANCE_TO_barSeries1"
                [labelMemberPath]="ids.Label_Account_To" [yMemberPath]="ids.index" [xMemberPath]="ids.Value_Account_To"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>

          </igx-data-chart>

          <div class="legend" [hidden]="!showLegend">
            <igx-legend #legendBar1 orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
          </div>
        </div>

        <div class="accountbalance_bar2">
          <div class="chart-title">{{from}} ... {{to}} <span class="focus_account">{{selectedFocusAccountName}}</span></div>

          <igx-data-chart
          name="CHART_ACCOUNTBALANCE_FROM_Chart"
          width="600px"
          height="600px"
          [legend]="legendBar2"
          yAxisMinimumValue="0"
          yAxisMaximumValue="100"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_ACCOUNTBALANCE_FROM_chart>
              <igx-category-y-axis
              [dataSource]="CHART_ACCOUNTBALANCE"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="Account"
              name="CHART_ACCOUNTBALANCE_FROM_yAxis"
              #CHART_ACCOUNTBALANCE_FROM_yAxis>
              </igx-category-y-axis>
              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="axis_maximumValue"
              labelVisibility="collapsed"
              majorStrokeThickness="0"
              name="CHART_ACCOUNTBALANCE_FROM_xAxis"
              #CHART_ACCOUNTBALANCE_FROM_xAxis>
              </igx-numeric-x-axis>
              <igx-category-highlight-layer
              name="CategoryHighlightLayer"
              #CHART_ACCOUNTBALANCE_FROM_categoryHighlightLayer>
              </igx-category-highlight-layer>
              <igx-bar-series
              [xAxis]="CHART_ACCOUNTBALANCE_FROM_xAxis"
              [yAxis]="CHART_ACCOUNTBALANCE_FROM_yAxis"
              valueMemberPath="Value_Account_From"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="CHART_ACCOUNTBALANCE"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod1ItemNames[0]"
              name="CHART_ACCOUNTBALANCE_FROM_BarSeries1"
              [brush]="configService.COLOR_GREEN"
              #CHART_ACCOUNTBALANCE_FROM_BarSeries1>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="CHART_ACCOUNTBALANCE" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_ACCOUNTBALANCE_FROM_BarSeries1"
                [labelMemberPath]="ids.Label_Account_From" [yMemberPath]="ids.index" [xMemberPath]="ids.Value_Account_From"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>

          </igx-data-chart>

          <div class="legend" [hidden]="!showLegend">
            <igx-legend #legendBar2 orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
          </div>
        </div>


        <div class="accountbalance_bar3">
          <div class="chart-title">
              <div class="chart-title-text">{{net_balance}} <span class="focus_account">{{selectedFocusAccountName}}</span></div>
          </div>

          <igx-data-chart
          name="CHART_ACCOUNTBALANCE_NET_Chart"
          width="300px"
          height="600px"
          [legend]="legendBar3"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_ACCOUNTBALANCE_NET_Chart>
              <igx-category-y-axis
              [dataSource]="CHART_ACCOUNTBALANCE"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="Account"
              labelVisibility="collapsed"
              name="CHART_ACCOUNTBALANCE_NET_yAxis"
              #CHART_ACCOUNTBALANCE_NET_yAxis>
              </igx-category-y-axis>
              <igx-numeric-x-axis
              name="CHART_ACCOUNTBALANCE_NET_xAxis"
              [minimumValue]="axis_net_minimumValue"
              [maximumValue]="axis_net_maximumValue"
              labelVisibility="collapsed"
              majorStrokeThickness="1"
              #CHART_ACCOUNTBALANCE_NET_xAxis>
              </igx-numeric-x-axis>
              <igx-category-highlight-layer
              name="CHART_ACCOUNTBALANCE_NET_CategoryHighlightLayer"
              #categoryHighlightLayer>
              </igx-category-highlight-layer>
              <igx-bar-series
              [xAxis]="CHART_ACCOUNTBALANCE_NET_xAxis"
              [yAxis]="CHART_ACCOUNTBALANCE_NET_yAxis"
              valueMemberPath="Value_Account_Net"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="CHART_ACCOUNTBALANCE"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip3"
              [title]="selectedPeriod1ItemNames[0]"
              [brush]="configService.COLOR_GREY"
              name="CHART_ACCOUNTBALANCE_NET_BarSeries1"
              #CHART_ACCOUNTBALANCE_NET_barSeries1>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="CHART_ACCOUNTBALANCE" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_ACCOUNTBALANCE_NET_barSeries1"
                [labelMemberPath]="ids.Label_Account_Net" [yMemberPath]="ids.index" [xMemberPath]="ids.Value_Account_Net"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>

          </igx-data-chart>

          <div class="legend" [hidden]="!showLegend">
            <igx-legend #legendBar3 orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
          </div>
        </div>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [multiSelectedNodeLevels]="multiSelectedNodeLevels"
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
