import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

declare var $:any;
import pptxgen from 'pptxgenjs';
import domtoimage from 'dom-to-image';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { CallAPIModes, Columns, DashboardId, Ids, UserSelectionIds } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-structure-of-account-clients',
  templateUrl: './structure-of-account-clients.component.html',
  styleUrls: ['./structure-of-account-clients.component.scss']
})
export class StructureOfAccountClientsComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.PROPENSITY
  dashboardId = DashboardId.structure_of_account_clients
  dashboards = this.cs.PROPENSITY_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // call backend
  getHHDataFromBackend = true

  // Facts used in dashboard
  aspFactId = "F1"
  ispFactId = "F2"
  allowedChartFactIds = [this.aspFactId, this.ispFactId, this.penetrationFactId]

  // Set initial look and behivor of trees, and there is no swith mode
  callAPIMode = CallAPIModes.GetAPIWithTotal
  /** Tree Account */
  treeAccountIsMulti = false     // make the tree without checkbox
  callBackendToGetAccountAndAllProducts = true
  /** Tree Product */
  treeProductIsMulti = false     // make the tree without checkbox
  callBackendToGetProductAndAllAccounts = false



  /** -------------------- Inputs for this dashboard -------------------- */

  public CHART_ALLHOUSEHOLDS: any[] = [];
  public CHART_CLIENTS: any[] = [];
  public CHART_CLIENTS_CALLOUT: any[] = [];
  public CHART_BUYERS: any[] = [];
  public CHART_BUYERS_CALLOUT: any[] = [];
  public CHART_CLIENT_BUYERS: any[] = [];
  public CHART_CLIENT_BUYERS_CALLOUT: any[] = [];

  filterPeriod1Title = this.cs.PERIOD

  public all_households = this.cs.ALL_HOUSEHOLDS
  public shopper_vs_nonshopper = this.cs.SHOPPER_VS_NON_SHOPPER
  public product_buyer = this.cs.PRODUCT_BUYER
  public shopper_product_buyer = this.cs.SHOPPER_PRODUCT_BUYER

  public hh: number = 0;
  public fact2: number = 0;
  public fact2_percent: number = 0;
  public fact3: number = 0;
  public fact3_percent: number = 0;
  public fact4: number = 0;
  public fact4_percent: number = 0;
  public fact5: number = 0;
  public fact5_percent: number = 0;
  public fact6: number = 0;
  public fact6_percent: number = 0;
  public fact7: number = 0;
  public fact7_percent: number = 0;
  public fact8: number = 0;
  public fact8_percent: number = 0;
  public fact9: number = 0;
  public fact9_percent: number = 0;

  public data_household: any;
  public data_household_clients: any;
  public data_household_nonclients: any;
  public data_household_clients_buyers: any;

  private el: ElementRef;

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(
        [... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(n => n.Id)))],
        [... new Set(this.totalProduct.concat(this.selectedProductNodes.map(n => n.Id)))],  // "P0 Product should always be fethched"
        this.getFactsToLoad(),
        this.getPeriodIds(), [this.selectedShopperGroupItem.Id])
        )
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          let penetrationData = data.filter(i=>i[Columns.Fact] === this.penetrationFactId)
          for(let pd of penetrationData) {
            if(!this.hasPenetrationWarning && this.utilityService.havePenetrationWarning(pd[Columns.Value])) {
              this.hasPenetrationWarning = true
            }
          }

          // console.log("StructureOfAccountClientsComponent | data: ", data, this.selectedShopperGroupItem.Id)

          this.hh = this.HH[this.selectedPeriod1Item.Id] // hh is the buyer_fact2. that's why the following start with buyer_fact2
          // console.log("StructureOfAccountClientsComponent | household: ", this.hh)

          this.fact2_percent = this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.totalProduct[0], this.selectedShopperGroupItem.Id)
          this.fact2 = this.hh * this.fact2_percent / 100

          this.fact3_percent = 100 - this.fact2_percent
          this.fact3 = this.hh * this.fact3_percent / 100

          this.fact5_percent = this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.fact2_percent * 100
          this.fact5 = this.fact2 * this.fact5_percent / 100

          this.fact4_percent = 100 - this.fact5_percent
          this.fact4 = this.fact2 * this.fact4_percent / 100

          this.fact7_percent = (this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod1Item.Id, this.totalAccount[0], this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) - this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)) / this.fact3_percent * 100
          this.fact7 = this.fact3 * this.fact7_percent / 100

          this.fact6_percent = 100 - this.fact7_percent
          this.fact6 = this.fact3 * this.fact6_percent / 100

          this.fact9_percent = this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100
          this.fact9 = this.fact5 * this.fact9_percent / 100

          this.fact8_percent = 100 - this.fact9_percent
          this.fact8 = this.fact5 * this.fact8_percent / 100

          // console.log("this.fact2, fact2_percent: ", this.fact2, this.fact2_percent)
          // console.log("this.fact3, fact3_percent: ", this.fact3, this.fact3_percent)
          // console.log("this.fact4, fact4_percent: ", this.fact4, this.fact4_percent)
          // console.log("this.fact5, fact5_percent: ", this.fact5, this.fact5_percent)
          // console.log("this.fact6, fact6_percent: ", this.fact6, this.fact6_percent)
          // console.log("this.fact7, fact7_percent: ", this.fact7, this.fact7_percent)
          // console.log("this.fact8, fact8_percent: ", this.fact8, this.fact8_percent)
          // console.log("this.fact9, fact9_percent: ", this.fact9, this.fact9_percent)

          this.CHART_ALLHOUSEHOLDS = [
            {
                'x': 0,
                'y': 100 / 2 - 3,
                'AllHouseholds': this.hh,
                'label': this.cs.ALL_HOUSEHOLDS + " (" + this.utilityService.abbreviateNumber(this.hh, 1) + " / 100 %)"
            }
          ];

          this.CHART_CLIENTS = [
            {
                'Clients': this.fact2,
                'Non Clients': this.fact3
            }
          ];

          this.CHART_CLIENTS_CALLOUT = [
            {
                'type': "Non Clients",
                'x': 0,
                'y': this.fact3 / this.hh * 100 / 2 - 3,
                'label': this.cs.NON_SHOPPER + " (" + this.utilityService.abbreviateNumber(this.fact3, 1) + " / " + this.utilityService.myFormatNumber(this.fact3_percent, 1) + "%)"
            },
            {
                'type': "Clients",
                'x': 0,
                'y': (this.fact2 / this.hh * 100 / 2) + this.fact3 / this.hh * 100 - 3,
                'label': this.cs.SHOPPER + " (" + this.utilityService.abbreviateNumber(this.fact2, 1) + " / " + this.utilityService.myFormatNumber(this.fact2_percent, 1) + "%)"
            },
          ];

          // console.log(this.CHART_CLIENTS, this.CHART_CLIENTS_CALLOUT)

          this.CHART_BUYERS = [
            {
                'Clients Buyers': this.fact5,
                'Clients Non-Buyers': this.fact4,
                'Non Clients Buyers': this.fact7,
                'Non-Clients Non Buyers': this.fact6
            }
          ];

          this.CHART_BUYERS_CALLOUT = [
            {
                'type': "Non-Clients Non Buyers",
                'x': 0,
                'y': (this.fact6 / this.hh * 100) / 2 - 3,
                'label': this.cs.NON_PRODUCT_BUYER + " (" + this.utilityService.abbreviateNumber(this.fact6,1)+ " / " + this.utilityService.myFormatNumber(this.fact6_percent,1)+ " %)"
            },
            {
                'type': "Non Clients Buyers",
                'x': 0,
                'y': (this.fact6 / this.hh * 100) + (this.fact7 / this.hh * 100) / 2 - 3,
                'label': this.cs.PRODUCT_BUYER + " (" + this.utilityService.abbreviateNumber(this.fact7,1)+ " / " + this.utilityService.myFormatNumber(this.fact7_percent,1)+ " %)"
            },
            {
                'type': "Non-Buyers",
                'x': 0,
                'y': (this.fact6 / this.hh * 100) + (this.fact7 / this.hh * 100) + (this.fact4 / this.hh * 100) / 2 - 3,
                'label': this.cs.NON_PRODUCT_BUYER + " (" + this.utilityService.abbreviateNumber(this.fact4,1)+ " / " + this.utilityService.myFormatNumber(this.fact4_percent,1)+ " %)"
            },
            {
                'type': "Buyers",
                'x': 0,
                'y': (this.fact6 / this.hh * 100) + (this.fact7 / this.hh * 100) + (this.fact4 / this.hh * 100) + (this.fact5 / this.hh * 100) / 2 - 3,
                'label': this.cs.PRODUCT_BUYER + " (" + this.utilityService.abbreviateNumber(this.fact5,1)+ " / " + this.utilityService.myFormatNumber(this.fact5_percent,1)+ " %)"
            }
          ];

          // console.log(this.CHART_BUYERS, this.CHART_BUYERS_CALLOUT)

          this.CHART_CLIENT_BUYERS = [
            {
                'In shop': this.fact9,
                'Elsewhere': this.fact8,
                'Other': this.hh - this.fact9 - this.fact8
            }
          ];

          this.CHART_CLIENT_BUYERS_CALLOUT = [
            {
                'type': "Elsewhere",
                'x': 0,
                'y': (this.hh - this.fact9 - this.fact8) / this.hh * 100 + (this.fact8 / this.hh * 100) / 2 - 3,
                'label': this.cs.ELSEWHERE + " (" + this.utilityService.abbreviateNumber(this.fact8, 1) + " / " + this.utilityService.myFormatNumber(this.fact8_percent, 1) + " %)"
            },
            {
                'type': "In shop",
                'x': 0,
                'y': (this.hh - this.fact9 - this.fact8) / this.hh * 100 + (this.fact8 / this.hh * 100) + (this.fact9 / this.hh * 100) / 2 - 3,
                'label': this.cs.IN_SHOP + " (" + this.utilityService.abbreviateNumber(this.fact9, 1) + " / " + this.utilityService.myFormatNumber(this.fact9_percent, 1) + " %)"
            }
          ];
        }
      )
    })
  }

  chartMasterNextPage = (event:any) => {
    let pptMaster: string = this.userDefinedPptMaster
    let slide: any
    if(pptMaster) {
      slide = this.chartMasterPPT.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = this.chartMasterPPT.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#chart"

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      slide.addImage({
      data: image,
      x: 1.4,
      y: 2,
      w: 10,
      h: 5
      });

      this.chartMasterService.next();
    })
  }

  handleExportPPT(singleTreeName: string): void {
    this.createPPT()
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  private createPPT(){
    if (!this.el ||
        !this.el.nativeElement ||
        !this.el.nativeElement.children){
          console.log('cant build without element');
        return;
    }

    let pptMaster: string = this.userDefinedPptMaster

    var pptx = new pptxgen();
    this.exportService.createPPTBasics(pptx)

    // create the ppt master
    this.exportService.createPPTMaster(pptx, pptMaster)
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#chart"

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
       slide.addImage({
        data: image,
        x: 1.4,
        y: 2,
        w: 10,
        h: 5
       });
      pptx.writeFile({fileName: fileName})
          .then(fileName => {
            console.log(`Created Single PPT file: ${fileName}`);
            this.isCreatingExport = false
        });
    })
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // not exist
    this.isCreatingExport = false
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let excelData: any[] = [
      {"buyer_group_id": this.cs.ALL_HOUSEHOLDS, "buying_households_m_id": this.utilityService.myRoundNumber(this.hh/1000000, 1), "share_in_group_percent_id": 100.0 },
      {"buyer_group_id": "", "buying_households_m_id": "", "share_in_group_percent_id": "" },

      {"buyer_group_id": this.cs.SHOPPER, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact2/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact2_percent, 1)},
      {"buyer_group_id": this.cs.NON_SHOPPER, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact3/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact3_percent, 1)},
      {"buyer_group_id": "", "buying_households_m_id": "", "share_in_group_percent_id": "" },
      {"buyer_group_id": this.cs.SHOPPER + " + " + this.cs.PRODUCT_BUYER, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact5/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact5_percent, 1)},
      {"buyer_group_id": this.cs.SHOPPER + " + " + this.cs.NON_PRODUCT_BUYER, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact4/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact4_percent, 1)},
      {"buyer_group_id": "", "buying_households_m_id": "", "share_in_group_percent_id": "" },
      {"buyer_group_id": this.cs.NON_SHOPPER + " + " + this.cs.PRODUCT_BUYER, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact7/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact7_percent, 1)},
      {"buyer_group_id": this.cs.NON_SHOPPER + " + " + this.cs.NON_PRODUCT_BUYER, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact6/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact6_percent, 1)},
      {"buyer_group_id": "", "buying_households_m_id": "", "share_in_group_percent_id": "" },
      {"buyer_group_id": this.cs.PRODUCT_BUYER + " " + this.cs.IN_SHOP, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact9/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact9_percent, 1)},
      {"buyer_group_id": this.cs.PRODUCT_BUYER + " " + this.cs.ELSEWHERE, "buying_households_m_id": this.utilityService.myRoundNumber(this.fact8/1000000, 1), "share_in_group_percent_id": this.utilityService.myRoundNumber(this.fact8_percent, 1)},
    ]

    let columnValueMap: any = {}
    columnValueMap[this.cs.BUYER_GROUP] = "buyer_group_id"
    columnValueMap[this.cs.BUYING_HOUSEHOLDS_M] = "buying_households_m_id"
    columnValueMap[this.cs.SHAER_IN_GROUP] = "share_in_group_percent_id"

    let dummy: any = []

    

    this.exportService.exportExcel("Structure_Account_Clients", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, excelData, header, columnValueMap, dummy, dummy, dummy)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

     // Period (Period 1)
     this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Shopper Group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })
  }
}
