import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../../layout/layout.component';
import { TableDemographicsComponent } from './table-demographics/table-demographics.component';
import { DemographicsChartComponent } from './demographics-chart/demographics-chart.component';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { DashboardId } from '../../shared/model/constants';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.demographics_chart, pathMatch: 'full' },
      { path: DashboardId.demographics_chart, component: DemographicsChartComponent },
      { path: DashboardId.table_demographics, component: TableDemographicsComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DemographicRoutingModule { }
