import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { IgxComboComponent } from '@infragistics/igniteui-angular';

import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { Ids } from '../../../model/constants';


@Component({
  selector: 'app-multi-select-combo',
  templateUrl: './multi-select-combo.component.html',
  styleUrls: ['./multi-select-combo.component.scss']
})
export class MultiSelectComboComponent {
  @Input() public name!: string;
  @Input() public items: SelectItem[] = [];
  @Input() public selected: string[] = [];

  @Output() getSelectedItemsEvent = new EventEmitter<any>();

  public ids = Ids

  constructor(public elem: ElementRef) { }

  @ViewChild('combo')
  public comboComponent!: IgxComboComponent;


  onChang(event: any): void {
    const items = this.comboComponent?.value // example of items: "F1(Name), F2(Name), F3(Name)"
    const itemIds = event
    const itemNames = items.split(', ')
    const name = this.name
    this.getSelectedItemsEvent.emit({name, itemNames, itemIds});
  }
}
