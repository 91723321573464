<table class="info-table">
  <tr>
   <td class="info-table-head" [hidden]="!showInfoShopperGroup">{{chipShopperGroupTitle}}</td>
   <td class="info-table-head" [hidden]="!showInfoAccount">{{chipAccountTitle}}</td>
   <td class="info-table-head" [hidden]="!showInfoProduct">{{chipProductTitle}}</td>
   <td class="info-table-head" [hidden]="!showInfoBenchmark">{{chipBenchmarkTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterBubbleSize">{{chipFilterBubbleSizeTitle}}</td>
   <td class="info-table-head" [hidden]="!showInfoFact">{{chipFactTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterPeriod1">{{chipPeriod1Title}}</td>
   <td class="info-table-head" [hidden]="!showFilterPeriod2">{{chipPeriod2Title}}</td>
   <td class="info-table-head" [hidden]="!showInfoChart">{{chipChartTitle}}</td>
   <td class="info-table-head" [hidden]="!showInfoBasis">{{chipBasisTitle}}</td>
   <td class="info-table-head info-table-head-demographics_index_product" [hidden]="!showInfoBenchmarkAccount">{{chipBenchmarkAccountTitle}}</td>
   <td class="info-table-head info-table-head-demographics_index_account" [hidden]="!showInfoBenchmarkProduct">{{chipBenchmarkProductTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterValueAccount">{{chipFilterValueAccountTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterValueProduct">{{chipFilterValueProductTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterValueFact">{{chipFilterValueFactTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterValueShopperGroup">{{chipFilterValueShopperGroupTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterDemographicGroup">{{chipFilterDemographicGroupTitle}}</td>
   <td class="info-table-head" [hidden]="!showFilterHouseholds">{{chipFilterHouseholdsTitle}}</td>

  </tr>
  <tr>
    <td class="info-table-text" [hidden]="!showInfoShopperGroup">{{selectedShopperGroupItem.Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoAccount" *ngIf="selectedAccountNodes.length > 0">{{selectedAccountNodes[0].Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoProduct" *ngIf="selectedProductNodes.length > 0">{{selectedProductNodes[0].Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoBenchmark">{{selectedBenchmark.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterBubbleSize">{{selectedFilterBubbleSize.Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoFact">{{selectedFact.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterPeriod1">{{selectedPeriod1ItemNames[0]}}</td>
    <td class="info-table-text" [hidden]="!showFilterPeriod2">{{selectedPeriod2ItemNames[0]}}</td>
    <td class="info-table-text" [hidden]="!showInfoChart">{{selectedChart.Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoBasis">{{selectedBasis.Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoBenchmarkAccount">{{selectedBenchmarkAccount.Name}}</td>
    <td class="info-table-text" [hidden]="!showInfoBenchmarkProduct">{{selectedBenchmarkProduct.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterValueAccount">{{selectedFilterValueAccount.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterValueProduct">{{selectedFilterValueProduct.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterValueFact">{{selectedFilterValueFact.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterValueShopperGroup">{{selectedFilterValueShopperGroup.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterDemographicGroup">{{selectedFilterDemographicGroup.Name}}</td>
    <td class="info-table-text" [hidden]="!showFilterHouseholds">{{selectedFilterHouseholds}}</td>
  </tr>
</table>
