import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { Color } from 'igniteui-angular-core';

import pptxgen from 'pptxgenjs';
import domtoimage from 'dom-to-image';
declare var $:any;

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { CallAPIModes, Columns, UserSelectionIds, SwitchId, Ids, DashboardId } from '../../../shared/model/constants';
import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from '../../../shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-definition-of-key-facts',
  templateUrl: './definition-of-key-facts.component.html',
  styleUrls: ['./definition-of-key-facts.component.scss']
})
export class DefinitionOfKeyFactsComponent extends BaseComponent implements OnInit{
  /** -------------------- Inputs for Base -------------------- */
  // set source table
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.PROPENSITY
  dashboardId = DashboardId.definition_of_key_facts
  dashboards = this.cs.PROPENSITY_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // call backend
  getHHDataFromBackend = true

  callAPIMode = CallAPIModes.GetAPIWithTotal   // to always get the total market
  // Set initial look and behivor of trees, and there is no swith mode
  /** Tree Account */
  treeAccountIsMulti = false     // make the tree without checkbox
  callBackendToGetAccountAndAllProducts = true // get account from the store
  /** Tree Product */
  treeProductIsMulti = false     // make the tree without checkbox
  callBackendToGetProductAndAllAccounts = false  // still get all the accounts when selecting a product

  // set period title
  filterPeriod1Title = this.cs.PERIOD

  // Facts used in dashboard
  aspFactId = "F1"
  ispFactId = "F2"
  buyerFactIds = [this.aspFactId, this.ispFactId]
  total_exFactId = "F6"
  ret_expFactId = "F7"
  lost_expFactId = "F8"
  expenditureFactIds = [this.total_exFactId, this.ret_expFactId, this.lost_expFactId]
  bedFactId = "F14"
  expenditureInclRRFactIds = [this.total_exFactId, this.ret_expFactId, this.lost_expFactId, this.bedFactId]
  allowedChartFactIds = [... new Set(this.buyerFactIds.concat(this.expenditureFactIds).concat(this.expenditureInclRRFactIds).concat([this.penetrationFactId]))]


  /** -------------------- Inputs for this dashboard -------------------- */
  public selectedProductName = ""
  public selectedAccountName = ""

  public selectSwitchTitle: string = this.cs.SWITCH              // set as the label and the name of the component, used to get which select is clicked
  public selectSwitchData: any[] = this.cs.SELECT_SWITCH_DATA    // pass data to select
  public selectedSwitchItem: SelectItem      = {Id: "", Name: ""}
  public filterBasisTitle: string = this.cs.BASIS
  private el: ElementRef;

  public getSelectedSwitchItem = (event: any) => {
    this.selectedSwitchItem = event.selectedItem
    this.feedChartWithData()
  }

  public switch = this.cs.SWITCH_OBJECT;
  public hh: number = 0;

  public buyer_fact2: number = 0;
  public buyer_fact2_percent: number = 0;
  public buyer_fact3: number = 0;
  public buyer_fact3_percent: number = 0;
  public buyer_fact4: number = 0;
  public buyer_fact4_percent: number = 0;
  public buyer_fact5: number = 0;
  public buyer_fact5_percent: number = 0;
  public buyer_fact6_percent: number = 0;
  public buyer_fact7_percent: number = 0;
  public buyer_fact8_percent: number = 0;

  public expenditure_fact1: number = 0;
  public expenditure_fact2: number = 0;
  public expenditure_fact3: number = 0;
  public expenditure_fact4: number = 0;
  public expenditure_fact5_percent: number = 0;
  public expenditure_fact6_percent: number = 0;
  public expenditure_fact7_percent: number = 0;

  public expenditure_incl_RR_fact1: number = 0;
  public expenditure_incl_RR_fact2: number = 0;
  public expenditure_incl_RR_fact3: number = 0;
  public expenditure_incl_RR_fact4: number = 0;
  public expenditure_incl_RR_fact5: number = 0;
  public expenditure_incl_RR_fact6_percent: number = 0;
  public expenditure_incl_RR_fact7_percent: number = 0;
  public expenditure_incl_RR_fact8_percent: number = 0;
  public expenditure_incl_RR_fact9_percent: number = 0;

  public buyers_text_0: string = ""
  public buyers_html_1: string = ""
  public buyers_html_2: string = ""
  public buyers_html_3: string = ""
  public buyers_text_4: string = ""
  public buyers_text_5: string = ""
  public buyers_text_6: string = ""
  public buyers_text_7: string = ""

  public expenditures_html_1: string = ""
  public expenditures_html_2: string = ""
  public expenditures_html_3: string = ""
  public expenditures_text_4: string = ""
  public expenditures_text_5: string = ""
  public expenditures_text_6: string = ""
  public expenditures_text_7: string = ""

  public expenditures_incl_rr_html_1: string = ""
  public expenditures_incl_rr_html_2: string = ""
  public expenditures_incl_rr_html_3: string = ""
  public expenditures_incl_rr_html_4: string = ""
  public expenditures_incl_rr_text_5: string = ""
  public expenditures_incl_rr_text_6: string = ""
  public expenditures_incl_rr_text_7: string = ""
  public expenditures_incl_rr_text_8: string = ""
  public expenditures_incl_rr_text_9: string = ""

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(
        [... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(n => n.Id)))],  // "BG1 Account should always be fethched"
        this.selectedProductNodes.map(n => n.Id),
        this.allowedChartFactIds,
        this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          let penetrationData = data.filter(i => i[Columns.Fact] === this.penetrationFactId)
          for(let pd of penetrationData) {
            if(!this.hasPenetrationWarning && this.utilityService.havePenetrationWarning(pd[Columns.Value])) {
              this.hasPenetrationWarning = true
            }
          }

          // console.log("DefinitionOfKeyFactsComponent | data: ", data)
          this.selectedProductName = this.selectedProductNodes[0].Name
          this.selectedAccountName = this.selectedAccountNodes[0].Name

          switch (this.selectedSwitchItem.Id) {
            case SwitchId.Buyers:
              this.hh = this.HH[this.selectedPeriod1Item.Id] // hh is the buyer_fact1. that's why the following start with buyer_fact2

              // console.log("DefinitionOfKeyFactsComponent | household: ", this.hh)

              let data_buyers = data.filter(i => this.buyerFactIds.includes(i[Columns.Fact]))

              // console.log("DefinitionOfKeyFactsComponent | data buyers: ", data_buyers)

              if (data_buyers.length >= 1) {
                this.buyer_fact2 = this.hh * this.utilityService.getValue(data_buyers, this.aspFactId, this.selectedPeriod1Item.Id, this.totalAccount[0], this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100
                this.buyer_fact2_percent = this.buyer_fact2 / this.hh * 100

                this.buyer_fact3 = this.hh * this.utilityService.getValue(data_buyers, this.aspFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100
                this.buyer_fact3_percent = this.buyer_fact3 / this.hh * 100

                this.buyer_fact4 = this.hh * this.utilityService.getValue(data_buyers, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100
                this.buyer_fact4_percent = this.buyer_fact4 / this.hh * 100

                this.buyer_fact5 = this.buyer_fact3 - this.buyer_fact4
                this.buyer_fact5_percent = this.buyer_fact5 / this.buyer_fact3 * 100

                this.buyer_fact6_percent = this.buyer_fact3_percent / this.buyer_fact2_percent * 100
                this.buyer_fact7_percent = this.buyer_fact4_percent / this.buyer_fact3_percent * 100
                this.buyer_fact8_percent = this.buyer_fact4_percent / this.buyer_fact2_percent * 100

                this.buyers_text_0 = this.cs.BUYERS_TEXT_0
                this.buyers_html_1 = this.cs.BUYERS_HTML_1.replace("${PRODUCT}", this.selectedProductName)
                this.buyers_html_2 = this.cs.BUYERS_HTML_2.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName)
                this.buyers_html_3 = this.cs.BUYERS_HTML_3.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName)
                this.buyers_text_4 = this.cs.BUYERS_TEXT_4
                this.buyers_text_5 = this.cs.BUYERS_TEXT_5
                this.buyers_text_6 = this.cs.BUYERS_TEXT_6
                this.buyers_text_7 = this.cs.BUYERS_TEXT_7

              }

              break

            case SwitchId.Expenditures:
              let data_expenditures = data.filter(i => this.expenditureFactIds.includes(i[Columns.Fact]))

              // console.log("DefinitionOfKeyFactsComponent | data_expenditures: ", data_expenditures)

              if (data_expenditures.length >= 1) {
                this.expenditure_fact1 = this.utilityService.getValue(data_expenditures, this.ret_expFactId, this.selectedPeriod1Item.Id, this.totalAccount[0], this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_fact2 = this.utilityService.getValue(data_expenditures, this.total_exFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_fact3 = this.utilityService.getValue(data_expenditures, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_fact4 = this.utilityService.getValue(data_expenditures, this.lost_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_fact5_percent = this.expenditure_fact2 / this.expenditure_fact1 * 100
                this.expenditure_fact6_percent = this.expenditure_fact3 / this.expenditure_fact2 * 100
                this.expenditure_fact7_percent = this.expenditure_fact3 / this.expenditure_fact1 * 100

                this.expenditures_html_1 = this.cs.EXPENDITURES_HTML_1.replace("${PRODUCT}", this.selectedProductName)
                this.expenditures_html_2 = this.cs.EXPENDITURES_HTML_2.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName).replace("${ACCOUNT}", this.selectedAccountName)
                this.expenditures_html_3 = this.cs.EXPENDITURES_HTML_3.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName).replace("${ACCOUNT}", this.selectedAccountName)
                this.expenditures_text_4 = this.cs.EXPENDITURES_TEXT_4
                this.expenditures_text_5 = this.cs.EXPENDITURES_TEXT_5
                this.expenditures_text_6 = this.cs.EXPENDITURES_TEXT_6
                this.expenditures_text_7 = this.cs.EXPENDITURES_TEXT_7

              }

              break

            case SwitchId.Expenditures_incl_RR:
              let data_expenditures_incl_RR = data.filter(i => this.expenditureInclRRFactIds.includes(i[Columns.Fact]))

              // console.log("DefinitionOfKeyFactsComponent | data_expenditures_incl_RR: ", data_expenditures_incl_RR)

              if (data_expenditures_incl_RR.length >= 1) {
                this.expenditure_incl_RR_fact1 = this.utilityService.getValue(data_expenditures_incl_RR, this.ret_expFactId, this.selectedPeriod1Item.Id, this.totalAccount[0], this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_incl_RR_fact2 = this.utilityService.getValue(data_expenditures_incl_RR, this.total_exFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_incl_RR_fact4 = this.utilityService.getValue(data_expenditures_incl_RR, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_incl_RR_fact3 = this.expenditure_incl_RR_fact4 / this.utilityService.getValue(data_expenditures_incl_RR, this.bedFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100

                this.expenditure_incl_RR_fact5 = this.utilityService.getValue(data_expenditures_incl_RR, this.lost_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

                this.expenditure_incl_RR_fact6_percent = this.expenditure_incl_RR_fact2 / this.expenditure_incl_RR_fact1 * 100
                this.expenditure_incl_RR_fact7_percent = this.expenditure_incl_RR_fact4 / this.expenditure_incl_RR_fact3 * 100
                this.expenditure_incl_RR_fact8_percent = this.expenditure_incl_RR_fact4 / this.expenditure_incl_RR_fact2 * 100
                this.expenditure_incl_RR_fact9_percent = this.expenditure_incl_RR_fact4 / this.expenditure_incl_RR_fact1 * 100

                this.expenditures_incl_rr_html_1 = this.cs.EXPENDITURES_INCL_RR_HTML_1.replace("${PRODUCT}", this.selectedProductName)
                this.expenditures_incl_rr_html_2 = this.cs.EXPENDITURES_INCL_RR_HTML_2.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName).replace("${ACCOUNT}", this.selectedAccountName)
                this.expenditures_incl_rr_html_3 = this.cs.EXPENDITURES_INCL_RR_HTML_3.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName)
                this.expenditures_incl_rr_html_4 = this.cs.EXPENDITURES_INCL_RR_HTML_4.replace("${PRODUCT}", this.selectedProductName).replace("${ACCOUNT}", this.selectedAccountName).replace("${ACCOUNT}", this.selectedAccountName)
                this.expenditures_incl_rr_text_5 = this.cs.EXPENDITURES_INCL_RR_TEXT_5
                this.expenditures_incl_rr_text_6 = this.cs.EXPENDITURES_INCL_RR_TEXT_6
                this.expenditures_incl_rr_text_7 = this.cs.EXPENDITURES_INCL_RR_TEXT_7
                this.expenditures_incl_rr_text_8 = this.cs.EXPENDITURES_INCL_RR_TEXT_8
                this.expenditures_incl_rr_text_9 = this.cs.EXPENDITURES_INCL_RR_TEXT_9
              }

              break
          }
        }
      )
    })
  }


  chartMasterNextPage = (event:any) => {
    let pptMaster: string = this.userDefinedPptMaster
    let slide: any
    if(pptMaster) {
      slide = this.chartMasterPPT.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = this.chartMasterPPT.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#" + this.selectedSwitchItem.Id;

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
      // console.log("dataUrl: ", dataUrl)
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      // console.log("data image: ", image)
      slide.addImage({
        data: image,
        x: 1.4,
        y: 2.1,
        w: 7,
        h: 5
      });

      this.chartMasterService.next();
    })
  }

  handleExportPPT(singleTreeName: string): void {
    this.createPPT()
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // has no export multi ppt
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    header[this.cs.BASIS] = this.selectedSwitchItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    columnValueMap[this.cs.DESCRIPTION] = this.cs.DESCRIPTION
    columnValueMap[this.cs.VALUE] = this.cs.VALUE

    let excelData: any = []
    let row1: any = {}
    let row2: any = {}
    let row3: any = {}
    let row4: any = {}
    let row5: any = {}
    let row6: any = {}
    let row7: any = {}
    let row8: any = {}
    let row9: any = {}
    let row10: any = {}
    let row11: any = {}
    let row12: any = {}
    let row13: any = {}
    let row14: any = {}
    let row15: any = {}

    //console.log(this.hidePenetrationWarning)

    switch (this.selectedSwitchItem.Id) {
      case SwitchId.Buyers:
        excelData = []

        row1[this.cs.DESCRIPTION] = this.cs.BUYERS_TEXT_0
        row1[this.cs.VALUE] = this.utilityService.myRoundNumber(this.hh / 1000000, 1)
        excelData.push(row1)

        row2[this.cs.DESCRIPTION] = ""
        row2[this.cs.VALUE] = ""
        excelData.push(row2)

        row3[this.cs.DESCRIPTION] = this.cs.BUYERS_EXCEL_1.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name) + " %"
        row3[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact2_percent, 1)
        excelData.push(row3)

        row4[this.cs.DESCRIPTION] = this.cs.BUYERS_EXCEL_2.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name) + " %"
        row4[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact3_percent, 1)
        excelData.push(row4)

        row5[this.cs.DESCRIPTION] = this.cs.BUYERS_EXCEL_3.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name) + " %"
        row5[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact4_percent, 1)
        excelData.push(row5)

        row6[this.cs.DESCRIPTION] = this.cs.BUYERS_TEXT_4 + this.selectedAccountNodes[0]?.Name + " %"
        row6[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact5_percent, 1)
        excelData.push(row6)

        row7[this.cs.DESCRIPTION] = ""
        row7[this.cs.VALUE] = ""
        excelData.push(row7)

        row8[this.cs.DESCRIPTION] = this.cs.BUYERS_EXCEL_1.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name)
        row8[this.cs.VALUE] = this.buyer_fact2
        excelData.push(row8)

        row9[this.cs.DESCRIPTION] = this.cs.BUYERS_EXCEL_2.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row9[this.cs.VALUE] = this.buyer_fact3
        excelData.push(row9)

        row10[this.cs.DESCRIPTION] = this.cs.BUYERS_EXCEL_3.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row10[this.cs.VALUE] = this.buyer_fact4
        excelData.push(row10)

        row11[this.cs.DESCRIPTION] = this.cs.BUYERS_TEXT_4 + this.selectedAccountNodes[0]?.Name
        row11[this.cs.VALUE] = this.buyer_fact5
        excelData.push(row11)

        row12[this.cs.DESCRIPTION] = ""
        row12[this.cs.VALUE] = ""
        excelData.push(row12)

        row13[this.cs.DESCRIPTION] = this.cs.BUYERS_TEXT_5 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row13[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact6_percent, 1)
        excelData.push(row13)

        row14[this.cs.DESCRIPTION] = this.cs.BUYERS_TEXT_6 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row14[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact7_percent, 1)
        excelData.push(row14)

        row15[this.cs.DESCRIPTION] = this.cs.BUYERS_TEXT_7 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row15[this.cs.VALUE] = this.utilityService.myRoundNumber(this.buyer_fact8_percent, 1)
        excelData.push(row15)

        break

      case SwitchId.Expenditures:
        excelData = []


        row1[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_EXCEL_1.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name)
        row1[this.cs.VALUE] = this.expenditure_fact1
        excelData.push(row1)

        row2[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_EXCEL_2.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row2[this.cs.VALUE] = this.expenditure_fact2
        excelData.push(row2)

        row3[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_EXCEL_3.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row3[this.cs.VALUE] = this.expenditure_fact3
        excelData.push(row3)

        row4[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_TEXT_4 + " " + this.selectedAccountNodes[0]?.Name
        row4[this.cs.VALUE] = this.expenditure_fact4
        excelData.push(row4)

        row5[this.cs.DESCRIPTION] = ""
        row5[this.cs.VALUE] = ""
        excelData.push(row5)

        row6[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_TEXT_5 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row6[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_fact5_percent, 1)
        excelData.push(row6)

        row7[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_TEXT_6 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row7[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_fact6_percent, 1)
        excelData.push(row7)

        row8[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_TEXT_7 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row8[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_fact7_percent, 1)
        excelData.push(row8)
        break

      case SwitchId.Expenditures_incl_RR:
        excelData = []

        row1[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_EXCEL_1.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name)
        row1[this.cs.VALUE] = this.expenditure_incl_RR_fact1
        excelData.push(row1)

        row2[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_EXCEL_2.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row2[this.cs.VALUE] = this.expenditure_incl_RR_fact2
        excelData.push(row2)

        row3[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_EXCEL_3.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row3[this.cs.VALUE] = this.expenditure_incl_RR_fact3
        excelData.push(row3)

        row4[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_EXCEL_4.replace('${PRODUCT}', this.selectedProductNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name).replace('${ACCOUNT}', this.selectedAccountNodes[0]?.Name)
        row4[this.cs.VALUE] = this.expenditure_incl_RR_fact4
        excelData.push(row4)

        row5[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_TEXT_5 + " " + this.selectedAccountNodes[0]?.Name
        row5[this.cs.VALUE] = this.expenditure_incl_RR_fact5
        excelData.push(row5)

        row6[this.cs.DESCRIPTION] = ""
        row6[this.cs.VALUE] = ""
        excelData.push(row6)

        row7[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_TEXT_6 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row7[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_incl_RR_fact6_percent, 1)
        excelData.push(row7)

        row8[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_TEXT_7 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row8[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_incl_RR_fact7_percent, 1)
        excelData.push(row8)

        row9[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_TEXT_8 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row9[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_incl_RR_fact8_percent, 1)
        excelData.push(row9)

        row10[this.cs.DESCRIPTION] = this.cs.EXPENDITURES_INCL_RR_TEXT_9 + " " + this.selectedAccountNodes[0]?.Name + " %"
        row10[this.cs.VALUE] = this.utilityService.myRoundNumber(this.expenditure_incl_RR_fact9_percent, 1)
        excelData.push(row10)

        break
    }

    // console.log("export excel: ", excelData, columnValueMap)

    let dummy: any = []

    this.exportService.exportExcel("Definition_Key_Facts", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, excelData, header, columnValueMap, dummy, dummy, dummy)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.BASIS] = this.selectedSwitchItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  private createPPT(){
    if (!this.el ||
        !this.el.nativeElement ||
        !this.el.nativeElement.children){
            console.log('cant build without element');
          return;
    }

    let pptMaster: string = this.userDefinedPptMaster

    var pptx = new pptxgen();
    this.exportService.createPPTBasics(pptx)

    // create the ppt master
    this.exportService.createPPTMaster(pptx, pptMaster)
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#" + this.selectedSwitchItem.Id;

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
      // console.log("dataUrl: ", dataUrl)
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      // console.log("image: ", image)
      slide.addImage({
        data: image,
        x: 1.4,
        y: 2.1,
        w: 7,
        h: 5
      });
      pptx.writeFile({fileName: fileName})
        .then(fileName => {
          console.log(`Created Single PPT file: ${fileName}`);
          this.isCreatingExport = false
      });
    })
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Switch] = this.selectedSwitchItem

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[]): void {
    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];
    // console.log("this.selectedAccountIds: ", this.selectedAccountIds, this.selectedAccountNodeLevels)

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
    // console.log("this.selectedProductIds: ", this.selectedProductIds, this.selectedProductNodeLevels)

    // Period (Period 1)
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // ShopperGroup
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // Switch
    this.selectedSwitchItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Switch, this.cs.SELECT_SWITCH_DATA[0])
    // have to update it according to the User Language
    this.selectedSwitchItem = {...this.selectedSwitchItem, Name: this.cs.SWITCH_OBJECT[this.selectedSwitchItem.Id]}
    // console.log("this.selectedSwitchItem: ", this.selectedSwitchItem, this.cs.SWITCH_OBJECT[this.selectedSwitchItem.Id])
  }
}
