import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { IgxCheckboxComponent, IgxTreeComponent, IgxTreeNode, IgxTreeSearchResolver, ITreeNodeSelectionEvent } from '@infragistics/igniteui-angular';

import { TreeNode } from '../../../../model/interfaces';
import { ConstantService } from '../../../../services/constant.service';

@Component({
  selector: 'app-tree-with-checkbox-with-ten-levels',
  templateUrl: './tree-with-checkbox-with-ten-levels.component.html',
  styleUrls: ['./tree-with-checkbox-with-ten-levels.component.scss']
})
export class TreeWithCheckboxWithTenLevelsComponent implements OnChanges {
  @Input() selectedNodeLevels!: number[]
  @Input() public data: TreeNode[] = []
  @Input() public name!: string
  @Input() public topLevelItemsAreSelected: boolean = false
  @Input() public topLevelIsExpanded: boolean = true
  @Input() public selectedNodes!: any[]
  @Input() public showSelectAll: boolean = true
  @Output() getSelectedNodesEvent = new EventEmitter<any>();

  @ViewChild('tree')
  public tree!: IgxTreeComponent;

  @ViewChild('checkboxSelectAll')
  checkboxSelectAll!: IgxCheckboxComponent

  @ViewChild('checkboxSelectAllInHierarchy')
  checkboxSelectAllInHierarchy!: IgxCheckboxComponent

  public selectAllTitle: string = this.cs.SELECT_ALL
  public selectAllInHierarchyTitle: string = this.cs.SELECT_ALL_IN_HIERARCHY

  constructor(public cs: ConstantService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data.length > 0) {
      setTimeout(() => {
        if(this.selectedNodes) {
          this.select(this.selectedNodes)
        }
      }, 1000);
    }
  }

  public handleNodeSelection(event: ITreeNodeSelectionEvent) {
    // const added: IgxTreeNode<any>[] = event.added; //maybe useful later
    const newSelection: IgxTreeNode<any>[] = event.newSelection;
    const name = this.name

    const selectedNodesName = newSelection.map(n => n.data.Name)
    const selectedNodesId = newSelection.map(n => n.data.Id)
    const selectedNodesLevel = newSelection.map(n => n.level)
    const selectedNodesParent = newSelection.map(n => {
        if(n.parentNode) {
            return n.parentNode.data.Name
        } else {
            return "null"
        }
    })

    const selectedNodes = []
    for (let i = 0; i < selectedNodesName.length; i ++) {
      let node:any = {}
      node['Id'] = selectedNodesId[i]
      node['Name'] = selectedNodesName[i]
      node['Level'] = selectedNodesLevel[i]
      node['Parent'] = selectedNodesParent[i]
      selectedNodes.push(node)
    }

    this.getSelectedNodesEvent.emit({name, selectedNodes});
  }

  // used to load the user config
  select(nodes2Select: any[]) {
    // console.log("tree with checkbox, nodes2Select: ", nodes2Select)
    if(nodes2Select) {  // make sure nodes2Select is not null / undefined / empty string
      if(nodes2Select.length > 0) { // make sure nodes2Select is not empty list
        this.tree.deselectAll()

        const comparer: IgxTreeSearchResolver = (data: any, node: IgxTreeNode<any>) => {
          if(node.parentNode) {
              return nodes2Select.some(r => r.Level === node.level && r.Name === node.data.Name && r.Parent === node.parentNode!.data.Name);
          } else {
              return nodes2Select.some(r => r.Level === node.level && r.Name === node.data.Name);
          }
        };

        const foundNodes: any = this.tree.findNodes(null, comparer);

        if(foundNodes) {
          for(let i of foundNodes ) {
            i.selected = true
            let pn = i.parentNode
            while(pn) {
              pn.expanded = true
              pn = pn.parentNode
            }
          }
        }
      }
    }
  }


  selectAll() {
    const name = this.name
    if(this.checkboxSelectAll.checked) {
        const comparer: IgxTreeSearchResolver = (
            data: any,
            node: IgxTreeNode<any>
          ) => {
          return true;
        };

        const nodes: any =  this.tree.findNodes(null, comparer)

        let selectedNodes = []
        for(let i of nodes ) {
            i.selected = true
            let node:any = {}
            node['Id'] = i.data.Id
            node['Name'] = i.data.Name
            node['Level'] = i.level
            node['Parent'] = i.parentNode ? i.parentNode.data.Name : "null"     // Parent is not used, can be delete later on. so set it just to "nv"
            selectedNodes.push(node)
        }
        this.getSelectedNodesEvent.emit({name, selectedNodes});
    } else {
        this.tree.deselectAll()
        let selectedNodes:any = []
        this.getSelectedNodesEvent.emit({name, selectedNodes});
    }
  }

  selectAllInHierarchy() {
    const name = this.name
    if(this.checkboxSelectAllInHierarchy.checked) {
      if(this.selectedNodeLevels.length === 0) {
          alert(this.cs.SELECT_ALL_IN_HIERARCHY_WARNING)
          this.checkboxSelectAllInHierarchy.checked = false
      } else {
          const comparer: IgxTreeSearchResolver = (
            data: any,
            node: IgxTreeNode<any>
          ) => {
            return this.selectedNodeLevels.includes(node.level) ;
          };

          const nodes: any =  this.tree.findNodes(null, comparer)

          let selectedNodes = []
          for(let i of nodes ) {
              i.selected = true
              let node:any = {}
              node['Id'] = i.data.Id
              node['Name'] = i.data.Name
              node['Level'] = i.level
              node['Parent'] = i.parentNode ? i.parentNode.data.Name : "null"
              selectedNodes.push(node)
          }
          this.getSelectedNodesEvent.emit({name, selectedNodes});      }
    } else {
        this.tree.deselectAll()
        let selectedNodes:any = []
        this.getSelectedNodesEvent.emit({name, selectedNodes});
    }
  }
}
