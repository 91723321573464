import { Injectable   } from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

import pptxgen from 'pptxgenjs';
import { ConfigService } from './config.service';
import { ExportService } from './export.service';



@Injectable({
  providedIn: 'root'
})
export class ChartMasterService {

  private dashboards: any[] = []
  private totalPagesNumber: number = 0;
  private currentPageNumber: number = 0;
  private isActive = new BehaviorSubject<boolean>(false);
  private PPT = new BehaviorSubject<pptxgen>(new pptxgen());

  constructor(private Router: Router, public configService: ConfigService, public exportService: ExportService) {
  }

  public setDashboards(dashboards: any[]) {
    this.dashboards = dashboards
  }

  public getTotalPagesNumber(): number {
    return this.totalPagesNumber
  }

  public getCurrentPageNumber(): number {
    return this.currentPageNumber
  }

  public getPPT(): Observable<pptxgen> {
    return this.PPT.asObservable();
  }

  public getStatus(): Observable<boolean> {
    return this.isActive.asObservable();
  }

  public next(){
    if(this.currentPageNumber < this.totalPagesNumber) {
      this.currentPageNumber = this.currentPageNumber + 1
      this.Router.navigate([this.dashboards[this.currentPageNumber - 1]["path"]]);
    } else if (this.currentPageNumber === this.totalPagesNumber) {
      // navigate back to chart master page
      this.Router.navigate(["dashboard/settings/presentation"]);

      // export ppt
      this.PPT.value.writeFile({ fileName: "chartMaster-ppt.pptx" });

      // end chart master
      this.end()
    }
  }

  public start(pptMaster: string): void {
    this.totalPagesNumber = this.dashboards.length;
    this.currentPageNumber = 1; // the first element of the ChartMasterTotalElements
    this.exportService.createPPTBasics(this.PPT.value)
    this.exportService.createPPTMaster(this.PPT.value, pptMaster)
    this.isActive.next(true)
    this.Router.navigate([this.dashboards[this.currentPageNumber - 1]["path"]]);
  }

  public end(): void {
    this.isActive.next(false)
    this.PPT.next(new pptxgen())
  }

  public cancel(): void {
    this.isActive.next(false)
    this.PPT.next(new pptxgen())
  }
}

