import { createAction, props } from '@ngrx/store';

const DATA_RESET = '[Dashboard] Data Reset';
const DATA_GET = '[Dashboard] Data Get';
const DATA_LOAD = '[Dashboard] Data Load';
const DATA_LOADED = '[Dashboard] Data Loaded';

const DIM_RESET = '[Dashboard] Dim Reset';
const DIM_GET = '[Dashboard] Dim Get';
const DIM_LOAD = '[Dashboard] Dim Load';
const DIM_LOADED = '[Dashboard] Dim Loaded';

const FILTER_DATA_RESET = '[Dashboard] Filter Data Reset';
const FILTER_DATA_GET = '[Dashboard] Filter Data Get';
const FILTER_DATA_LOAD = '[Dashboard] Filter Data Load';
const FILTER_DATA_LOADED = '[Dashboard] Filter Data Loaded';

const HH_RESET = '[Dashboard] HH Reset';
const HH_GET = '[Dashboard] HH Get';
const HH_LOAD = '[Dashboard] HH Load';
const HH_LOADED = '[Dashboard] HH Loaded';

const SELECTION_RESET = '[Dashboard] Selection Reset';
const SELECTION_GET = '[Dashboard] Selection Get';
const SELECTION_LOAD = '[Dashboard] Selection Load';
const SELECTION_LOADED = '[Dashboard] Selection Loaded';
const SELECTION_SAVE = '[Dashboard] Selection Save';
const SELECTION_SAVED = '[Dashboard] Selection Saved';

const SELECTION_TEMPLATES_GET = '[Dashboard] Selection Template Get';
const SELECTION_TEMPLATES_LOAD = '[Dashboard] Selection Template Loaded';

const dataReset = createAction(
  DATA_RESET
);

const dataGet = createAction(
  DATA_GET,
  props<{ sourceTable: string, selectedAccounts: string[]; selectedProducts: string[]; facts: string[] }>()
);
const dataLoad = createAction(
  DATA_LOAD,
  props<{ sourceTable: string, selectedAccounts: string[]; selectedProducts: string[]; facts: string[] }>()
);
const dataLoaded = createAction(
  DATA_LOADED,
  props<{ data: any[] }>()
);

const dimReset = createAction(
  DIM_RESET
);

const dimGet = createAction(
  DIM_GET
);
const dimLoad = createAction(
  DIM_LOAD
);
const dimLoaded = createAction(
  DIM_LOADED,
  props<{ dim: any[] }>()
);

// the multiPPT* actions are not used
const filterDataReset = createAction(
  FILTER_DATA_RESET
);
const filterDataGet = createAction(
  FILTER_DATA_GET,
  props<{sourceTable: string, accounts: string[]; products: string[]; periods: string[]; facts: string[] }>()
);
const filterDataLoad = createAction(
  FILTER_DATA_LOAD,
  props<{sourceTable: string, accounts: string[]; products: string[]; periods: string[]; facts: string[] }>()
);
const filterDataLoaded = createAction(
  FILTER_DATA_LOADED,
  props<{ filterData: any[] }>()
);

const hhReset = createAction(
  HH_RESET
);

const hhGet = createAction(
  HH_GET
);
const hhLoad = createAction(
  HH_LOAD
);
const hhLoaded = createAction(
  HH_LOADED,
  props<{ hh: any[] }>()
);


const selectionReset = createAction(
  SELECTION_RESET
);

const selectionGet = createAction(
  SELECTION_GET,
  props<{ dashboard: string }>()
);
const selectionLoad = createAction(
  SELECTION_LOAD,
  props<{ dashboard: string }>()
);
const selectionLoaded = createAction(
  SELECTION_LOADED,
  props<{ selection: any[] }>()
);

const selectionSave = createAction(
  SELECTION_SAVE,
  props<{ dashboards: string[], selection: any }>()
);
const selectionSaved = createAction(
  SELECTION_SAVED
);

const selectionTemplatesGet = createAction(
  SELECTION_TEMPLATES_GET,
  props<{ dashboard: string }>()
);

const selectionTemplatesLoad = createAction(
  SELECTION_TEMPLATES_LOAD,
  props<{ dashboard: string }>()
);

export const DashboardActions = {
  dataReset,
  dataGet,
  dataLoad,
  dataLoaded,
  dimReset,
  dimGet,
  dimLoad,
  dimLoaded,
  filterDataReset,
  filterDataGet,
  filterDataLoad,
  filterDataLoaded,
  hhReset,
  hhGet,
  hhLoad,
  hhLoaded,
  selectionReset,
  selectionGet,
  selectionLoad,
  selectionLoaded,
  selectionSave,
  selectionSaved,
  selectionTemplatesLoad,
  selectionTemplatesGet



}
