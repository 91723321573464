import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { IgxToastComponent } from '@infragistics/igniteui-angular';
import { first } from 'rxjs';
import { AllDashboardsInStandardSelection, DashboardId, UserSelectionIds } from '../../shared/model/constants';
import { SelectedTreeNode } from '../../shared/model/interfaces';
import { ConstantService } from '../../shared/services/constant.service';
import { UtilityService } from '../../shared/services/utility.service';
import { DashboardActions } from '../../shared/state/dashboard.actions';
import { DashboardAppState } from '../../shared/state/dashboard.reducer';
import { fromDashboard } from '../../shared/state/dashboard.selector';

@UntilDestroy()
@Component({
  selector: 'app-standard-selection',
  templateUrl: './standard-selection.component.html',
  styleUrls: ['./standard-selection.component.scss']
})
export class StandardSelectionComponent implements OnInit {

  public filterAccountTitle = this.cs.ACCOUNT        // used to identify which tree is clicked
  public filterAccountData: any[] = []
  public selectedAccountNodes: SelectedTreeNode[] = []
  private selectedAccountIds: string[] = []
  public selectedAccountNodeLevels: number[] = [0] // used to save the levels of all the selected nodes in a tree, so that the "select all in hierarchy" works. by default, the root item of tree is selected. so the level is by default 0.

  public filterProductTitle = this.cs.PRODUCT
  public filterProductData: any[] = []
  public selectedProductNodes: SelectedTreeNode[] = []
  public selectedProductIds: string[] = []
  public selectedProductNodeLevels: number[] = [0]

  private dashboardId = DashboardId.standard_selection
  public isDimLoaded$        = this.store.select(fromDashboard.isDimLoaded)
  public isSelectionLoading$  = this.store.select(fromDashboard.isSelectionLoading)
  public isSelectionLoaded$  = this.store.select(fromDashboard.isSelectionLoaded)
  public isSelectionSaving$  = this.store.select(fromDashboard.isSelectionSaving)
  public isSelectionSaved$  = this.store.select(fromDashboard.isSelectionSaved)

  public saveButtonAccountIsDisabled = true
  public saveButtonProductIsDisabled = true

  @ViewChild('toast_standard_selection', { read: IgxToastComponent })
  public toast_standard_selection!: IgxToastComponent;

  private allDashboards: string[] = AllDashboardsInStandardSelection

  constructor(
    public store: Store<DashboardAppState>,
    public utilityService: UtilityService,
    public cs: ConstantService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(DashboardActions.selectionGet({dashboard: this.dashboardId})) // get the latest standard selection. the dashboardId could be "standard-selection"
    this.store.dispatch(DashboardActions.dimGet())           // get the dim from xano and do not change it anymore until the page is reloaded
    this.setToDefault()
  }

  ngOnDestroy() {
    this.store.dispatch(DashboardActions.selectionReset())
  }


  public getSelectedNodes = (event:any) => {
    if(event.name === this.filterAccountTitle) {
      // the Account tree is clicked
      this.selectedAccountNodes = event.selectedNodes
      this.selectedAccountIds = this.selectedAccountNodes.map(n => n.Id)
      this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];
      this.saveButtonAccountIsDisabled = false
    }
    else {
      // the product tree is clicked
      this.selectedProductNodes = event.selectedNodes
      this.selectedProductIds = this.selectedProductNodes.map(n => n.Id)
      this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
      this.saveButtonProductIsDisabled = false
    }
  }

  setToDefault() {
    this.isDimLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
      this.store.select<any[]>(fromDashboard.selectAllDim)      // select the dim from state
      .pipe(first(), untilDestroyed(this))
      .subscribe(dim => {
            this.filterAccountData = dim.filter(dim => dim.Dimension === 'AccountTree')[0]['Tree']
            this.filterProductData = dim.filter(dim => dim.Dimension === 'ProductTree')[0]['Tree']

            this.isSelectionLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
              this.store.select<any[]>(fromDashboard.selectAllSelection)      // select the dim from state
              .pipe(first(), untilDestroyed(this))
              .subscribe(selection => {
                // Account Tree
                this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [])
                this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
                this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

                // Product Tree
                this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [])
                this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
                this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
              })
            })
      })
    })
  }

  saveAccountStandardSelection() {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    // selection[UserSelectionIds.Product] = this.selectedProductNodes

    this.store.dispatch(DashboardActions.selectionSave({dashboards: this.allDashboards, selection: selection}))

    this.isSelectionSaved$.subscribe((saved) => {
      if(saved) {
        this.toast_standard_selection.open(this.cs.CHANGE_SAVED)
      }
    })
  }

  saveProductStandardSelection() {
    let selection: any = {}
    // selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes

    this.store.dispatch(DashboardActions.selectionSave({dashboards: this.allDashboards, selection: selection}))

    this.isSelectionSaved$.subscribe((saved) => {
      if(saved) {
        this.toast_standard_selection.open(this.cs.CHANGE_SAVED)
      }
    })
  }

}
