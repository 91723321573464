import { ElementRef, EventEmitter, OnInit, Output, Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectItem } from '../../../model/interfaces';
import { ConstantService } from '../../../services/constant.service';

@Component({
  selector: 'app-filter-tabs',
  templateUrl: './filter-tabs.component.html',
  styleUrls: ['./filter-tabs.component.scss']
})
export class FilterTabsComponent implements OnInit {
  // variables & constent
  public exportItems: any = []


  // tab account
  @Input() public tabAccountTitle: string = this.cs.ACCOUNT

  @Input() public filterAccountTitle!: string;
  @Input() public treeAccountIsMulti!: boolean;
  @Input() public filterAccountData!: any[];
  @Input() public selectedAccountNodes!: any[];
  @Input() getSelectedNodes!: ($event: any) => void


  // tab product
  @Input() public tabProductTitle: string = this.cs.PRODUCT

  @Input() public filterProductTitle!: string;
  @Input() public treeProductIsMulti!: boolean;
  @Input() public filterProductData!: any[];
  @Input() public selectedProductNodes!: any[];


  // tab period & fact
  @Input() public tabPeriodFactTitle!: string;

  @Input() public filterPeriod1Title!: string;
  @Input() public filterPeriod1Data!: any[];
  @Input() public selectedPeriod1Item: SelectItem = {Id: '', Name: ''};
  @Input() public filterPeriod2Title!: string;
  @Input() public filterPeriod2Data!: any[];
  @Input() public selectedPeriod2Item: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedPeriodItem!: ($event: any) => void
  @Input() public showOneSinglePeriodFilter: boolean = false;
  @Input() public showTwoSinglePeriodFilters: boolean = false;

  @Input() public selectSingleFactTitle!: string;
  @Input() public selectSingleFactData!: any[];
  @Input() public selectedSingleFactItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedSingleFact!: ($event: any) => void
  @Input() public showSingleFactFilter: boolean = false;

  @Input() public selectMultiFactsTitle!: string;
  @Input() public selectMultiFactsData!: any[];
  @Input() public selectedMultiFactItemIds: string[] = [];
  @Input() getSelectedMultiFacts!: ($event: any) => void
  @Input() public showMultiFactsFilter: boolean = false;
  @Input() public showMultiFactsCheckboxFilter: boolean = false;


  // tab selection
  @Input() public tabSelectionTitle!: string;

  @Input() public filterShopperGroupTitle!: string;
  @Input() public filterShopperGroupData!: any[];
  @Input() public selectedShopperGroupItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedShopperGroupItem!: ($event: any) => void
  @Input() public showFilterShopperGroup: boolean = false;

  @Input() DemographicGroups!: any[];
  @Input() public filterDemographicGroupTitle!: string;
  @Input() public showMultiDemographicFilter: boolean = false;
  @Output() getChangeDemographicGroupEvent = new EventEmitter<any>();

  @Input() public filterBenchmarkTitle!: string;
  @Input() public filterBenchmarkData!: any[];
  @Input() public selectedBenchmarkItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedBenchmarkItem!: ($event: any) => void
  @Input() public showFilterBenchmark: boolean = false;
  @Input() public showNoBenchmarkSelectedError: boolean = false;

  @Input() public filterBenchmark2Title!: string;
  @Input() public filterBenchmark2Data!: any[];
  @Input() public selectedBenchmark2Item: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedBenchmark2Item!: ($event: any) => void
  @Input() public showFilterBenchmark2: boolean = false;

  @Input() public selectStaticFilterTitle!: string; // static filter is a filter with select-items which are static
  @Input() public selectStaticFilterData!: any[];
  @Input() public selectedStaticFilterItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedStaticFilterItem!: ($event: any) => void
  @Input() public showStaticFilter: boolean = false;

  @Input() public filterDemographicsChartSwitchTitle!: string; // static filter is a filter with select-items which are static
  @Input() public filterDemographicsChartSwitchData!: any[];
  @Input() public selectedDemographicsChartSwitchItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedDemographicsChartSwitchItem!: ($event: any) => void
  @Input() public showDemographicsChartSwitchFilter: boolean = false;

  @Input() public filterDemographicsChartBasisTitle!: string; // static filter is a filter with select-items which are static
  @Input() public filterDemographicsChartBasisData!: any[];
  @Input() public selectedDemographicsChartBasisItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedDemographicsChartBasisItem!: ($event: any) => void
  @Input() public showDemographicsChartBasisFilter: boolean = false;

  @Input() public filterSingleDemographicTitle!: string; // static filter is a filter with select-items which are static
  @Input() public filterSingleDemographicData!: any[];
  @Input() public selectedSingleDemographicItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedSingleDemographicItem!: ($event: any) => void
  @Input() public showSingleDemographicFilter: boolean = false;

  @Input() public filterDemographicsChartBarsTitle!: string; // static filter is a filter with select-items which are static
  @Input() public filterDemographicsChartBarsData!: any[];
  @Input() public selectedDemographicsChartBarsItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedDemographicsChartBarsItem!: ($event: any) => void
  @Input() public showDemographicsChartBarsFilter: boolean = false;

  @Input() showCheckboxLegend: boolean = false;
  @Input() showCheckboxDataLabel: boolean = false;
  @Input() showLegend: boolean = true;
  @Input() showDataLabel: boolean = true;
  @Output() showLegendEvent = new EventEmitter<any>();
  @Output() showDataLabelEvent = new EventEmitter<any>();

  @Input() public filterModeTitle!: string; // static filter is a filter with select-items which are static
  @Input() public filterModeData!: any[];
  @Input() public selectedModeItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedFilterModeItem!: ($event: any) => void
  @Input() public showFilterMode: boolean = false;

  @Input() public showExportExcel: boolean = false;
  @Input() public showExportPPT: boolean = false;
  @Input() public showExportMultiPPT: boolean = false;
  @Output() getSelectedExportEvent = new EventEmitter<any>();


  // others inputs
  @Input() selectedAccountNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes
  @Input() selectedProductNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes


  // maybe used later
  @Input() isDataLoading$!: Observable<boolean>;


  public chartSettingTitle: string = this.cs.CHART_SETTING
  public chartSettingLegendTitle: string = this.cs.LEGEND
  public chartSettingDatalabelTitle: string = this.cs.DATA_LABEL
  public exportTitle: string = this.cs.EXPORT
  public benchmarkWarning: string = this.cs.BENCHMARK_WARNING

  constructor(public elem: ElementRef, public cs: ConstantService) {}

  ngOnInit() {
    this.updateExportItems(this.showExportExcel, this.showExportPPT, this.showExportMultiPPT)
  }


  /** export items */
  private updateExportItems(showExportExcel: boolean, showExportPPT: boolean, showExportMultiPPT: boolean) {
    this.exportItems = []
    if(showExportExcel) {
      this.exportItems.push({Value:"excel", Name:this.cs.EXCEL})  // if there is grid, then export the excel of the grid data. when there is no grid, just export the data of the chart
    }

    if(showExportPPT) {
      this.exportItems.push({Value:"ppt", Name:this.cs.SINGLE_PPT})
    }

    if(showExportMultiPPT) {
      this.exportItems.push({Value:"multippt", Name:this.cs.MULTI_PPT})
    }
  }

  /** export with button group */
  export(id: string){
    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = id

    this.getSelectedExportEvent.emit({selectedItem});
  }

  onChangeDemographicGroup(event: any, group: string) {
    let selectedItem: any = {
      group: '',
      checked: false
    }
    selectedItem['group'] = group
    selectedItem['checked'] = event.checked
    this.getChangeDemographicGroupEvent.emit({selectedItem})
  }

  toggleLegend(event: any) {
    let legend: any = {show: false}
    if(event.checked) {
      legend['show'] = true
    } else {
      legend['show'] = false
    }
    this.showLegendEvent.emit({legend})
  }

  toggleDataLabel(event: any) {
    let dataLabel: any = {show: false}
    if(event.checked) {
      dataLabel['show'] = true
    } else {
      dataLabel['show'] = false
    }
    this.showDataLabelEvent.emit({dataLabel})
  }
}
