<section class="container_content">
  <div class="leftmenu">
      <app-filter-tabs
        [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

        [tabAccountTitle]="tabAccountTitle"
        [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

        [tabProductTitle]="tabProductTitle"
        [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

        [tabPeriodFactTitle]="tabPeriodFactTitle"
        [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"
        [showSingleFactFilter]="true" [selectSingleFactTitle]="filterChartFactTitle" [selectSingleFactData]="filterChartFactData" [selectedSingleFactItem]="selectedChartFactItem" [getSelectedSingleFact]="getSelectedChartFact"
        [showMultiFactsFilter]="true" [selectMultiFactsTitle]="filterGridFactsTitle" [selectMultiFactsData]="filterGridFactsData" [selectedMultiFactItemIds]="selectedGridFactItemIds" [getSelectedMultiFacts]="getSelectedGridFacts"

        [tabSelectionTitle]="tabSelectionTitle"
        [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
        [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="showExportMultiPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"
        [showFilterMode]="true" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
        [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
        [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"

        [isDataLoading$]="isDataLoading$"
      ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item.Category}}</div>
        <div><font [color]="chartColors[0]">{{item.Series1}}:</font> {{item.Label1}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item.Category}}</div>
        <div><font [color]="chartColors[1]">{{item.Series2}}:</font> {{item.Label2}}</div>
      </ng-template>

      <div class="flex flex-row border-0">
        <div class="h-96 w-2/3 ">
            <igx-data-chart #chart height="100%" width="100%" [legend]="legend"
                  isHorizontalZoomEnabled="false"
                  isVerticalZoomEnabled="false">

                <igx-category-x-axis #xAxis [dataSource]="chartData" [label]="modeCategory"></igx-category-x-axis>

                <igx-numeric-y-axis #yAxis abbreviateLargeNumbers="true" [title]="selectedChartFactItem.Name" titleTextStyle="9pt" [majorStroke]="configService.STROKE_COLOR_VALUE"
                  titleLeftMargin="5" titleRightMargin="0" [minimumValue]="axisYMinValue" [maximumValue]="axisYMaxValue" [interval]="axisYInterval">
                </igx-numeric-y-axis>

                <igx-column-series #per1Series [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
                            [valueMemberPath]="selectedPeriod1Item.Name"
                            showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                            isTransitionedInEnabled="true" isHighlightingEnabled="true"
                            [title]="selectedPeriod1Item.Name" markerType="Hidden" [brush]="chartColors[0]" outline="Transparent">
                </igx-column-series>

                <igx-column-series *ngIf="period2IsSelected"
                            #per2Series [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
                            [valueMemberPath]="selectedPeriod2Item.Name"
                            showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                            isTransitionedInEnabled="true" isHighlightingEnabled="true"
                            [title]="selectedPeriod2Item.Name" markerType="Hidden" [brush]="chartColors[1]" outline="Transparent">
                </igx-column-series>

                <ng-container *ngIf="showDataLabel">
                  <igx-callout-layer
                  [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="per1Series"
                  [labelMemberPath]="ids.Label1" [xMemberPath]="ids.index" [yMemberPath]="ids.value1"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>

                  <igx-callout-layer
                  [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="per2Series"
                  [labelMemberPath]="ids.Label2" [xMemberPath]="ids.index" [yMemberPath]="ids.value2"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>
                </ng-container>
            </igx-data-chart>

        </div>

        <div class="legend" [hidden]="!showLegend">
          <igx-legend #legend orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
        </div>
      </div>


      <div class="flex flex-row mt-1">
        <div  class="pivot-container">
          <div>
            <div id="pivot_grid" #pivot></div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [multiSelectedNodeLevels]="multiSelectedNodeLevels"
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
