import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

declare var jQuery:any;
declare var $:any;

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';

import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { Columns, DashboardId, Ids, ModeIds, PPTIds, UserSelectionIds } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';
import { ChartMasterService } from '../../../shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { PENETRATION_COLOR, PENETRATION_HIDDEN_COLOR, PIVOT_AGGREGATOR_NAME } from 'src/app/dashboard/shared/model/config';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-purchase-behavior',
  templateUrl: './purchase-behavior.component.html',
  styleUrls: ['./purchase-behavior.component.scss']
})
export class PurchaseBehaviorComponent extends BaseComponent implements AfterViewInit {
  /** -------------------- Inputs for Base -------------------- */
  // set source table
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.PROPENSITY
  dashboardId = DashboardId.purchase_behavior
  dashboards = this.cs.PROPENSITY_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // If it has 2 period filters
  hasPeriod2Filter = true     // used in base component, have to keep it

  // Facts used in dashboard
  factsMaxValueIsHundred = ["F11", "F10", "F14", "F2"]   // for those facts, the y axis of the chart has max value of 100
  allowedChartFactIds = ["F11", "F10", "F7", "F2", "F5", "F12", "F8", "F14", this.penetrationFactId]
  allowedGridFactIds = ["F11", "F10", "F7", "F2", "F5", "F12", "F8", "F14", this.penetrationFactId]

  /** -------------------- Inputs for this dashboard -------------------- */
  public gridData: any = []

  @ViewChild("per1Series")
  public per1Series!: IgxColumnSeriesComponent;

  @ViewChild("per2Series")
  public per2Series!: IgxColumnSeriesComponent;

  private el: ElementRef;

  @ViewChild('pivot')
  pivot!: ElementRef;

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el;
  }

  public ngAfterViewInit(): void {
    if(this.per1Series !== undefined) this.per1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    if(this.per2Series !== undefined) this.per2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);    
  }

  individualLogicForPeriod2() {
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.period2IsSelected = false

      this.changeDetector.detectChanges();
      if(this.per1Series !== undefined) this.per1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
      if(this.per2Series !== undefined) this.per2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    } else {
      this.period2IsSelected = true

      this.changeDetector.detectChanges();
      if(this.per1Series !== undefined) this.per1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
      if(this.per2Series !== undefined) this.per2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    }
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), [this.selectedChartFactItem.Id, this.penetrationFactId], this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          this.setYaxisMinMaxInterval()
//           console.log("purchase-behavior | chart | data: ", data)

          this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
          // console.log("purchase-behavior | chart | preprocessedData: ", data)
          
          this.chartData = this.utilityService.replaceIdWithName2(this.chartService.covertToChartData1(data, this.fieldsWithoutPeriodAndValue), Columns.Account, Columns.Product, Columns.Fact,
                                                                  this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterChartFactData, this.getPeriodIds(), this.filterPeriod1Data)

          
          this.addPenetrationInChartData(this.chartData)

          this.addCategoryAndDataLabel(this.chartData)
          //console.log("purchase-behavior | chart | chart data: ", this.chartData)
        }
      )
    })
  }

  private addPenetrationInChartData(chartData: any[]) {
    let dataOfChartFact = chartData.filter(d => d[Columns.Fact] === this.selectedChartFactItem.Name)
    let dataOfPenetration = chartData.filter(d => d[Columns.Fact] === this.penetrationFactName)
    for(let i of dataOfChartFact) {
      let iPenetration: any = dataOfPenetration.filter(d => d[Columns.Product] === i[Columns.Product] && d[Columns.Account] === i[Columns.Account] && d[Columns.ShopperGroup] === i[Columns.ShopperGroup])[0]
      i[Ids.Penetration_Prefix + this.selectedPeriod1Item.Name] = this.utilityService.havePenetrationWarning(iPenetration[this.selectedPeriod1Item.Name])
      i[Ids.Penetration_Prefix + this.selectedPeriod2Item.Name] = this.utilityService.havePenetrationWarning(iPenetration[this.selectedPeriod2Item.Name])
      if(!this.hasPenetrationWarning && (i[Ids.Penetration_Prefix + this.selectedPeriod1Item.Name] || i[Ids.Penetration_Prefix + this.selectedPeriod2Item.Name])) {
        this.hasPenetrationWarning = true
      }
    }
    this.chartData = dataOfChartFact
  }

  private addCategoryAndDataLabel(chartData: any[]) {
    let inx = 0
    for(let i of chartData) {
      if(this.treeAccountIsMulti) {
        i[Ids.Category] = i[Columns.Account]
      } else {
        i[Ids.Category] = i[Columns.Product]
      }

      i[Ids.Label1] = this.utilityService.myFormatNumber(i[this.selectedPeriod1Item.Name], 2)
      i[Ids.value1] = i[this.selectedPeriod1Item.Name]
      i[Ids.Series1] = this.selectedPeriod1Item.Name

      i[Ids.Label2] = this.utilityService.myFormatNumber(i[this.selectedPeriod2Item.Name], 2)
      i[Ids.value2] = i[this.selectedPeriod2Item.Name]
      i[Ids.Series2] = this.selectedPeriod2Item.Name

      i[Ids.index] = inx

      inx++
    }
  }

  private addPenetrationInGridData(data: any[]) {
    let dataCopy = data
    let dataOfGridFact = dataCopy.filter(d => this.selectedGridFactItemIds.includes(d[Columns.Fact]))
    let dataOfPenetration = dataCopy.filter(d => d[Columns.Fact] === this.penetrationFactId)
    // console.log("dataOfGridFact: ", dataOfGridFact)
    // console.log("dataOfPenetration: ", dataOfPenetration)
    let dataWithPenetration: any[] = []
    for(let i of dataOfGridFact) {
      let iCopy = Object.assign({}, i)
      let iPenetration: any = dataOfPenetration.filter(d => d[Columns.Product] === iCopy[Columns.Product] && d[Columns.Account] === iCopy[Columns.Account] && d[Columns.Period] === iCopy[Columns.Period] && d[Columns.ShopperGroup] === iCopy[Columns.ShopperGroup])[0]
      // console.log("iPenetration: ", iPenetration)
      iCopy[Ids.Penetration_Row_Value] = iPenetration[Columns.Value]
      iCopy[Ids.Penetration_Row] = this.utilityService.havePenetrationWarning(iPenetration[Columns.Value])
      if(!this.hasPenetrationWarning && iCopy[Ids.Penetration_Row]) {
        this.hasPenetrationWarning = true
      }
      dataWithPenetration.push(iCopy)
    }
    return dataWithPenetration
  }

  feedGridWithData(): void {
    if(this.selectedAccountNodes.length === 0 || this.selectedProductNodes.length === 0 || this.selectedGridFactItemIds.length === 0) {
      this.loadPivotTable([], [], []) // do not show the pivot table
    } else {
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.selectedGridFactItemIds.concat([this.penetrationFactId]), this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( data => {
            this.hasPenetrationWarning = false

            // console.log("purchase-behavior | grid | data: ", data)

            this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
            // console.log("purchase-behavior | grid | preprocessedData: ", data)

            let dataWithPenetration: any[] = this.addPenetrationInGridData(data)
            // console.log("purchase-behavior | grid | data with penetration: ", dataWithPenetration)

            this.gridData = this.utilityService.replaceIdWithName(dataWithPenetration,
              Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
              this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData, [], true
            )
            // console.log("purchase-behavior | grid | grid data: ", this.gridData)


            if(this.treeAccountIsMulti) {
              this.loadPivotTable(this.gridData, [Columns.Account], [Columns.Fact, Columns.Period])
            } else {
              this.loadPivotTable(this.gridData, [Columns.Product], [Columns.Fact, Columns.Period])
            }


          })
      })
    }
  }

  handlePenetrationWarning() {
    if(this.per1Series !== undefined) this.per1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    if(this.per2Series !== undefined) this.per2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);

    if(this.treeAccountIsMulti) {
      this.loadPivotTable(this.gridData, [Columns.Account], [Columns.Fact, Columns.Period])
    } else {
      this.loadPivotTable(this.gridData, [Columns.Product], [Columns.Fact, Columns.Period])
    }
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = this.treeAccountIsMulti ? Ids.Product : Ids.Account

    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields = this.getPeriodNames()
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id)

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.MultiColumns, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields = this.getPeriodNames()
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id)

    this.exportService.exportPPT(PPTIds.MultiColumns, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
                      .then(fileName => {
                          console.log(`Created Single PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNTS] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCTS] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.FACT] = this.selectedChartFactItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    this.restService.filterData (
      this.sourceTable,
      accounts.map(i => i.Id),
      products.map(i => i.Id),
      this.getPeriodIds(),
      [this.selectedChartFactItem.Id]
    )
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})

      // console.log("purchase-behavior | multiPPT | preprocessedData: ", data)

      let multiPptData = this.chartService.covertToChartData1(this.utilityService.replaceIdWithName(data,
      Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
      this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData
      ), this.fieldsWithoutPeriodAndValue)

      let header = this.getPPTHeaderTemplate()
      let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
      let dataseriesFields = this.getPeriodNames()
      let options: any = {}
      options[PPTIds.master] = this.userDefinedPptMaster
      options[PPTIds.MaxValueIsHundred] = this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id)

      this.exportService.exportPPT(PPTIds.MultiColumns, this.dashboardTitle, accounts.map(i=>i.Name), products.map(i=>i.Name), header, options, singleTreeName, catAxisField, dataseriesFields, multiPptData)
                        .then(fileName => {
                          console.log(`Created Multi PPT file: ${fileName}`);
                          this.isCreatingExport = false
                        });
    })
  }

  handleExportExcel() {
    if(this.selectedAccountNodes.length === 0 || this.selectedProductNodes.length === 0 || this.selectedGridFactItemIds.length == 0) {
      alert(this.cs.EXPORT_EXCEL_WARNING)
      this.isCreatingExport = false
      return
    }

     /** header */
    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.selectedPeriod1ItemNames.join(", ")
    header[this.cs.FACTS] = this.selectedGridFactItemNames.join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let div = this.pivot.nativeElement
    let pivotUITable = div.children[0]  // get HTML Table from div
    let htmlTable = pivotUITable.rows[2].cells[1].children[0]

    let row0: any[] = ['']
    let row1: any[] = ['']
    let dataRows: any[][] = []

    let PenetrationRows: any[] = []

    //console.log(pivotUITable)

    /** row0 doesn't have the first and the last element. (which is '' and 'Totals')*/
    let tableRow0 = htmlTable.rows[0]
    for(let j = 2; j < tableRow0.cells.length - 1; j++) {
      let cell = tableRow0.cells[j]      
      for(let c = 0; c < cell.colSpan; c++) {
        row0.push(cell.innerText)
      }
    }

    /** row1 - row3 contains all elements */
    let tableRow1 = htmlTable.rows[1]
    for(let j = 1; j < tableRow1.cells.length; j++) {
      let cell = tableRow1.cells[j]      
      for(let c = 0; c < cell.colSpan; c++) {
        row1.push(cell.innerText)
      }
    }

    // console.log("rowX: ", row0, row1)
    //console.log(PenetrationRows);
    /** dataRows should not contain the last row, which is Totals */
    for (var i = 3; i < htmlTable.rows.length - 1 ; i++) {
      let row = htmlTable.rows[i]
      let rdata: any[] = []
      if(row.cells[1].style.color === 'rgb(255, 110, 8)'){
        PenetrationRows.push(i-2);        
      }
      
      /** each rdata should not contain the last column, since it is Totals */
      for (var j = 0; j < row.cells.length - 1; j++) {
        let cell = row.cells[j]        
        if(j === 0) {
          rdata.push(cell.innerText)
        } else {
          rdata.push(Number(cell.innerText.replaceAll(".", "").replace(",", ".")))  // change the german number format (string) back to the english format
        }
      }
      dataRows.push(rdata)
    }

    //console.log(PenetrationRows);
    // console.log("dataRows: ", dataRows)

    this.exportService.exportPivot(this.hidePenetrationWarning, this.dashboardTitle, header, row0, row1, dataRows, PenetrationRows)
                      .then(fileName => {
                     //   console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
                      
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show
    if(this.per1Series !== undefined) this.per1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    if(this.per2Series !== undefined) this.per2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.Fact] = this.selectedChartFactItem
    selection[UserSelectionIds.FactNames] = this.selectedGridFactItemNames
    selection[UserSelectionIds.FactIds] = this.selectedGridFactItemIds
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Mode] = this.selectedModeItem

    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Switch (Mode)
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.treeProductIsMulti = true
        this.callBackendToGetProductAndAllAccounts = false

        this.treeAccountIsMulti = false
        this.callBackendToGetAccountAndAllProducts = true
        break

      case ModeIds.OneProductMultiAccounts:
        this.treeProductIsMulti = false
        this.callBackendToGetProductAndAllAccounts = true

        this.treeAccountIsMulti = true
        this.callBackendToGetAccountAndAllProducts = false
        break
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // Period1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Period2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)
    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]

    // Fact Chart
    this.selectedChartFactItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, {
      Id: this.filterChartFactData.length === 0 ? '' : this.filterChartFactData[0].Id,
      Name: this.filterChartFactData.length === 0 ? '' : this.filterChartFactData[0].Name
    })
    // have to update it according to the User Language
    this.selectedChartFactItem = {...this.selectedChartFactItem, Name: this.cs.FACT_OBJECT[this.selectedChartFactItem.Id]}
    // console.log("this.selectedChartFactItem: ", this.selectedChartFactItem)

    // Fact Grid
    this.selectedGridFactItemIds = this.utilityService.getSelectionItem(selection, UserSelectionIds.FactIds,this.filterGridFactsData.length === 0 ? [] : [this.filterGridFactsData[0].Id])
    // have to update the selectedGridFactItemNames according to the User Language
    this.selectedGridFactItemNames = []
    for(let id of this.selectedGridFactItemIds) {
      this.selectedGridFactItemNames.push(this.cs.FACT_OBJECT[id])
    }
    // console.log("this.selectedGridFactItemNames: ", this.selectedGridFactItemNames, this.selectedGridFactItemIds)

    // ShopperGroup
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // chart setting (has to be before individualLogicForPeriod2)
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)
    if(this.per1Series !== undefined) this.per1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    if(this.per2Series !== undefined) this.per2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
  }

  private loadPivotTable(data:any,pivot_rows:any,pivot_columns:any) {
    if (!this.el ||
        !this.el.nativeElement ||
        !this.el.nativeElement.children){
            console.log('cant build without element');
            return;
     }

    var container = this.el.nativeElement;
    var inst = jQuery(container);
    var targetElement = inst.find('#pivot_grid');

    if (!targetElement){
      console.log('cant find the pivot element');
      return;
    }

    // clear the table
    targetElement.empty()

    //if there is no data, just return, without creating a pivot
    if(data.length === 0) {
      return
    }

    let selectedAccounts = this.selectedAccountNodes.map(n => n.Name)
    let selectedProducts = this.selectedProductNodes.map(n => n.Name)
    let selectedFacts = this.selectedGridFactItemNames
    let selectedPerids = this.getPeriodNames()

    // console.log("selectedFacts: ", selectedFacts)
    // console.log("selectedPerids: ", selectedPerids)

    let mySorters: any = {}
    mySorters[Columns.Account] = $.pivotUtilities.sortAs(selectedAccounts)
    mySorters[Columns.Product] = $.pivotUtilities.sortAs(selectedProducts)
    mySorters[Columns.Fact] = $.pivotUtilities.sortAs(selectedFacts)
    mySorters[Columns.Period] = $.pivotUtilities.sortAs(selectedPerids)

    $( document ).ready(function() {
      targetElement.pivotUI(
        data,
        {
          rows: pivot_rows,
          cols: pivot_columns,
          vals: [Columns.Value],
          aggregatorName: PIVOT_AGGREGATOR_NAME,
          sorters: mySorters,
          aggregators: $.pivotUtilities.aggregators,

          onRefresh: function() {
            let pwIsHidden = $('#p-w-div').attr('pwIsHidden') === 'true'
            var $values = $('.pvtVal')
            for (var i=0; i<$values.length; i++) {
              if ($values[i].innerHTML.startsWith(" ")) { // " " at beginning indicates of having penetration warning
                if(pwIsHidden) {
                  $values.eq(i).css('color', PENETRATION_HIDDEN_COLOR)
                } else {
                  $values.eq(i).css('color', PENETRATION_COLOR)
                }
              } else {
                $values.eq(i).css('color', PENETRATION_HIDDEN_COLOR)
              }
            }
          },
          showUI: false
        }, true); // true -> allow to override the parameters
    });

  }
}
