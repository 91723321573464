<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
        [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

        [tabAccountTitle]="tabAccountTitle"
        [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

        [tabProductTitle]="tabProductTitle"
        [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

        [tabPeriodFactTitle]="tabPeriodFactTitle"
        [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"

        [tabSelectionTitle]="tabSelectionTitle"
        [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
        [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="false" (getSelectedExportEvent)="getSelectedExportEvent($event)"

        [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      [hideSwitch]="true"
      ></app-penetration-warning>

      <div id="chart">
        <div class="chart-section-1">
          <div class="accountclients-chart-heading">{{all_households}}</div>
          <igx-data-chart
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          width="240px"
          height="700px"
          name="chart"
          [brushes]="['#BFBFBF']"
          toolTipType="Data"
          #chart>
              <igx-category-x-axis
              [dataSource]="CHART_ALLHOUSEHOLDS"
              gap="0"
              name="xAxis"
              labelVisibility="collapsed"
              #xAxis>
              </igx-category-x-axis>
              <igx-numeric-y-axis
              minimumValue="0"
              labelVisibility="collapsed"
              name="yAxis"
              #yAxis>
              </igx-numeric-y-axis>
              <igx-stacked-100-column-series
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [dataSource]="CHART_ALLHOUSEHOLDS"
              areaFillOpacity="1"
              showDefaultTooltip="false"
              name="Stacked100ColumnSeries"
              #stacked100ColumnSeries>
                  <igx-stacked-fragment-series
                  name="s1"
                  #s1
                  title="All Households"
                  valueMemberPath="AllHouseholds">
                  </igx-stacked-fragment-series>
            </igx-stacked-100-column-series>
            <igx-callout-layer
            labelMemberPath="label"
            xMemberPath="x"
            yMemberPath="y"
            contentMemberPath="label"
            calloutTextColor="rgba(0, 0, 0, 1)"
            calloutBackground="rgba(255, 255, 255, 1)"
            calloutLeaderBrush="rgba(0, 0, 0, 0)"
            [dataSource]="CHART_ALLHOUSEHOLDS"
            name="CalloutLayer1"
            #calloutLayer1>
            </igx-callout-layer>
          </igx-data-chart>
        </div>
        <div class="chart-section-2">
          <div class="accountclients-chart-heading">{{shopper_vs_nonshopper}}</div>
          <igx-data-chart
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          width="240px"
          height="700px"
          name="chart2"
          [brushes]="['#BDFFBB','#00A346']"
          #Chart2>
              <igx-category-x-axis
              [dataSource]="CHART_CLIENTS"
              gap="0"
              name="Chart2_xAxis"
              labelVisibility="collapsed"
              #Chart2_xAxis>
              </igx-category-x-axis>
              <igx-numeric-y-axis
              minimumValue="0"
              name="yAxis"
              labelVisibility="collapsed"
              #Chart2_yAxis>
              </igx-numeric-y-axis>
              <igx-stacked-100-column-series
              [xAxis]="Chart2_xAxis"
              [yAxis]="Chart2_yAxis"
              [dataSource]="CHART_CLIENTS"
              areaFillOpacity="1"
              showDefaultTooltip="false"
              name="Stacked100ColumnSeries"
              #Chart2_stacked100ColumnSeries>
                  <igx-stacked-fragment-series
                  name="Chart2_s2"
                  #Chart2_s2
                  title="Non Clients"
                  valueMemberPath="Non Clients">
                  </igx-stacked-fragment-series>
                  <igx-stacked-fragment-series
                  name="Chart2_s1"
                  #Chart2_s1
                  title="Clients"
                  valueMemberPath="Clients">
                  </igx-stacked-fragment-series>
              </igx-stacked-100-column-series>
              <igx-callout-layer
              labelMemberPath="label"
              xMemberPath="x"
              yMemberPath="y"
              contentMemberPath="label"
              calloutTextColor="rgba(0, 0, 0, 1)"
              calloutBackground="rgba(255, 255, 255, 1)"
              calloutLeaderBrush="rgba(0, 0, 0, 0)"
              [dataSource]="CHART_CLIENTS_CALLOUT"
              name="CalloutLayer2"
              #calloutLayer2>
              </igx-callout-layer>
          </igx-data-chart>
        </div>
        <div class="chart-section-3">
          <div class="accountclients-chart-heading">{{product_buyer}}</div>
          <igx-data-chart
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          isCategoryHighlightingEnabled="true"
          width="240px"
          height="700px"
          name="Chart3"
          [brushes]="['#BDFFBB','#BDFFBB','#00A346','#00A346']"
          #Chart3>
              <igx-category-x-axis
              [dataSource]="CHART_BUYERS"
              gap="0"
              name="Chart3_xAxis"
              labelVisibility="collapsed"
              #Chart3_xAxis>
              </igx-category-x-axis>
              <igx-numeric-y-axis
              minimumValue="0"
              name="yAxis"
              labelVisibility="collapsed"
              #Chart3_yAxis>
              </igx-numeric-y-axis>
              <igx-stacked-100-column-series
              [xAxis]="Chart3_xAxis"
              [yAxis]="Chart3_yAxis"
              [dataSource]="CHART_BUYERS"
              areaFillOpacity="1"
              showDefaultTooltip="false"
              name="Stacked100ColumnSeries"
              #Chart3_stacked100ColumnSeries>
                  <igx-stacked-fragment-series
                  name="Chart3_s4"
                  #Chart3_s4
                  title="Non-Clients Non Buyers"
                  valueMemberPath="Non-Clients Non Buyers">
                  </igx-stacked-fragment-series>
                  <igx-stacked-fragment-series
                  name="Chart3_s3"
                  #Chart3_s3
                  title="Non Clients Buyers"
                  valueMemberPath="Non Clients Buyers">
                  </igx-stacked-fragment-series>
                  <igx-stacked-fragment-series
                  name="Chart3_s2"
                  #Chart3_s2
                  title="Clients Non-Buyers"
                  valueMemberPath="Clients Non-Buyers">
                  </igx-stacked-fragment-series>
                  <igx-stacked-fragment-series
                  name="Chart3_s1"
                  #Chart3_s1
                  title="Clients Buyers"
                  valueMemberPath="Clients Buyers">
                  </igx-stacked-fragment-series>
              </igx-stacked-100-column-series>
              <igx-callout-layer
              labelMemberPath="label"
              xMemberPath="x"
              yMemberPath="y"
              contentMemberPath="label"
              calloutTextColor="rgba(0, 0, 0, 1)"
              calloutBackground="rgba(255, 255, 255, 1)"
              calloutLeaderBrush="rgba(0, 0, 0, 0)"
              [dataSource]="CHART_BUYERS_CALLOUT"
              name="CalloutLayer3"
              #calloutLayer3>
              </igx-callout-layer>
          </igx-data-chart>
        </div>
        <div class="chart-section-4">
          <div class="accountclients-chart-heading">{{shopper_product_buyer}}</div>
          <igx-data-chart
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          isCategoryHighlightingEnabled="true"
          width="240px"
          height="700px"
          name="Chart4"
          [brushes]="['#FFFFFF','#00A346','#00A346']"
          #Chart4>
              <igx-category-x-axis
              [dataSource]="CHART_BUYERS"
              gap="0"
              name="Chart4_xAxis"
              labelVisibility="collapsed"
              #Chart4_xAxis>
              </igx-category-x-axis>
              <igx-numeric-y-axis
              minimumValue="0"
              name="yAxis"
              labelVisibility="collapsed"
              #Chart4_yAxis>
              </igx-numeric-y-axis>
              <igx-stacked-100-column-series
              [xAxis]="Chart4_xAxis"
              [yAxis]="Chart4_yAxis"
              [dataSource]="CHART_CLIENT_BUYERS"
              areaFillOpacity="1"
              showDefaultTooltip="false"
              name="Stacked100ColumnSeries"
              #Chart3_stacked100ColumnSeries>
                  <igx-stacked-fragment-series
                  name="Chart4_s1"
                  #Chart4_s1
                  title="Other"
                  valueMemberPath="Other">
                  </igx-stacked-fragment-series>
                  <igx-stacked-fragment-series
                  name="Chart4_s2"
                  #Chart4_s2
                  title="Elsewhere"
                  valueMemberPath="Elsewhere">
                  </igx-stacked-fragment-series>
                  <igx-stacked-fragment-series
                  name="Chart4_s3"
                  #Chart4_s3
                  title="In shop"
                  valueMemberPath="In shop">
                  </igx-stacked-fragment-series>
              </igx-stacked-100-column-series>
              <igx-callout-layer
              labelMemberPath="label"
              xMemberPath="x"
              yMemberPath="y"
              contentMemberPath="label"
              calloutTextColor="rgba(0, 0, 0, 1)"
              calloutBackground="rgba(255, 255, 255, 1)"
              calloutLeaderBrush="rgba(0, 0, 0, 0)"
              [dataSource]="CHART_CLIENT_BUYERS_CALLOUT"
              name="CalloutLayer4"
              #calloutLayer3>
              </igx-callout-layer>
          </igx-data-chart>
        </div>
      </div>

    </div>

  </div>

  <app-export-multi-ppt-dialog
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
