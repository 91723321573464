import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../../../dashboard/shared/services/constant.service';

@Component({
  selector: 'app-report-bar',
  templateUrl: './report-bar.component.html',
  styleUrls: ['./report-bar.component.scss']
})
export class ReportBarComponent implements OnInit{
  public dashboards: any[] = []
  public module: string = ''

  public chartMasterActive   = false;

  constructor(
    private _router: Router,
    private chartMasterService:ChartMasterService,
    private location: Location,
    public translate: TranslateService,
    public cs: ConstantService,
  ) {}

  ngOnInit(): void {
    // console.log(this._router.url)
    const parts = this._router.url.split("/")
    const id = "/" + parts[1] + "/" + parts[2] + "/"
    // console.log(id)
    switch(id) {
      case '/dashboard/propensity/':
        this.dashboards = this.cs.PROPENSITY_DASHBOARDS
        this.module = 'propensity'
        break;
      case '/dashboard/market-share/':
        this.dashboards = this.cs.MARKET_SHARE_DASHBOARDS
        this.module = 'market-share'
        break;
      case '/dashboard/rest-requirement/':
        this.dashboards = this.cs.REST_REQUIREMENT_DASHBOARDS
        this.module = 'rest-requirement'
        break;
      case '/dashboard/tactical/':
        this.dashboards = this.cs.TACTICAL_DASHBOARDS
        this.module = 'tactical'
        break;
      case '/dashboard/demographic/':
        this.dashboards = this.cs.DEMOGRAPHIC_DASHBOARDS
        this.module = 'demographic'
        break;
      case '/dashboard/basket/':
        this.dashboards = this.cs.BASKET_DASHBOARDS
        this.module = 'basket'
        break;
      case '/dashboard/reports/':
        this.dashboards = this.cs.REPORTS_DASHBOARDS
        this.module = 'reports'
        break;
      case '/dashboard/settings/':
        this.dashboards = this.cs.SETTING_PAGES
        this.module = 'settings'
        break;
    }

    this.chartMasterService.getStatus().subscribe((d) => {
      this.chartMasterActive = d;
    });
  }

  isSelected(link: string) {
    if(this.location.path().includes(link)) {
      return true
    } else {
      return false
    }
  }
}
