<header class="header">
  <div class="flex items-center">
    <!-- BEGIN: Logo -->
    <a routerLink="/home" class="flex items-center">
      <img src="/assets/nielsenIQ.png" class="logo-icon" title="logo"/>
    </a>
    <!-- END: Logo -->
  </div>

</header>
