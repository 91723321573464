<div *ngIf="chartMasterActive">
  <div class="ChartMasterNavigation">
      <div class="ChartMasterNavigation_Slide">{{slide}} {{chartMasterCurrentElement}} / {{chartMasterTotalElements}}</div>
      <div class="ChartMasterNavigation_Button">
        <div>
            <button igxButton="raised" class="button-green" igxRipple (click)="chartMasterNextPage()">{{chartMasterCurrentElement < chartMasterTotalElements ? next : createPPT}}</button>
            <button igxButton="raised" class="button-green ChartMasterNavigation_Button_Cancel" igxRipple (click)="cancelChartMaster()">{{cancel}}</button>
        </div>
      </div>
  </div>
</div>
