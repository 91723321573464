<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
        [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

        [tabAccountTitle]="tabAccountTitle"
        [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

        [tabProductTitle]="tabProductTitle"
        [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

        [tabPeriodFactTitle]="tabPeriodFactTitle"
        [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"

        [tabSelectionTitle]="tabSelectionTitle"
        [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
        [showFilterMode]="true" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
        [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
        [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"
        [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"
        [showStaticFilter]="true" [selectStaticFilterTitle]="selectBasisTitle" [selectStaticFilterData]="selectBasisData" [selectedStaticFilterItem]="selectedBasisItem" [getSelectedStaticFilterItem]="getSelectedBasis"

        [isDataLoading$]="isDataLoading$"
      ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBasisTitle]="selectBasisTitle"                [selectedBasis]="selectedBasisItem"
      [showFilterPeriod2]="hasPeriod2Filter"  [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti" [showInfoBasis]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item.Category}}</div>
        <div><font [color]="chartColors[0]">{{axisYFactName}}:</font> {{item.Label1}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item.Category}}</div>
        <div><font [color]="chartColors[1]">{{axisY2FactName}}:</font> {{item.Label2}}</div>
      </ng-template>

      <igx-data-chart #chart height="600px" width="1200px" [legend]="legend"
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false">

          <igx-category-x-axis #xAxis [dataSource]="chartData" [label]="ids.Category"></igx-category-x-axis>
          <igx-numeric-y-axis #yAxis1 abbreviateLargeNumbers="true" [majorStroke]="configService.STROKE_COLOR_VALUE"
                titleLeftMargin="5" titleRightMargin="0" majorStrokeThickness="1" minimumValue="0" maximumValue="100">
          </igx-numeric-y-axis>

          <igx-column-series #columnSeries1 [xAxis]="xAxis" [yAxis]="yAxis1" [dataSource]="chartData" [valueMemberPath]="ids.value1"
                        showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                        isTransitionedInEnabled="true" isHighlightingEnabled="true"
                        [title]="axisYFactName" markerType="Hidden" [brush]="chartColors[0]" outline="Transparent">
          </igx-column-series>

          <igx-column-series #columnSeries2 [xAxis]="xAxis" [yAxis]="yAxis1" [dataSource]="chartData" [valueMemberPath]="ids.value2"
                        showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                        isTransitionedInEnabled="true" isHighlightingEnabled="true"
                        [title]="axisY2FactName" markerType="Hidden" [brush]="chartColors[1]" outline="Transparent">
          </igx-column-series>

          <ng-container *ngIf="showDataLabel">
            <igx-callout-layer
              [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="columnSeries1"
              [labelMemberPath]="ids.Label1" [xMemberPath]="ids.index" [yMemberPath]="ids.value1"
              calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
            </igx-callout-layer>

            <igx-callout-layer
                [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false"  [targetSeries]="columnSeries2"
                [labelMemberPath]="ids.Label2" [xMemberPath]="ids.index" [yMemberPath]="ids.value2"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
            </igx-callout-layer>
          </ng-container>
      </igx-data-chart>

      <div class="legend" [hidden]="!showLegend">
        <igx-legend #legend orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
