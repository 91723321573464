<igx-dialog #dialog [closeOnOutsideSelect]="true">
  <igx-dialog-title>
      <div>
          <div>{{exportMultiPPTDialogTitle}}</div>
      </div>
  </igx-dialog-title>

  <div class="flex w-128 h-128">
    <ng-container *ngIf="!treeAccountIsMulti; else multiProduct">
      <app-tree-with-checkbox-with-ten-levels #multiAccountTreeWithCheckbox [selectedNodeLevels]="multiSelectedNodeLevels" [data]="filterAccountData" [name]="treeMultiAccountName" (getSelectedNodesEvent)="getMultiSelectedNodes($event)"></app-tree-with-checkbox-with-ten-levels>
    </ng-container>
    <ng-template #multiProduct>
      <app-tree-with-checkbox-with-ten-levels #multiProductTreeWithCheckbox [selectedNodeLevels]="multiSelectedNodeLevels" [data]="filterProductData" [name]="treeMultiProductName" (getSelectedNodesEvent)="getMultiSelectedNodes($event)" ></app-tree-with-checkbox-with-ten-levels>
    </ng-template>
  </div>

  <div igxDialogActions>
      <button igxButton="raised" igxRipple="#fdfdfd" class="button" (click)="dialog.close()">{{cancel}}</button>
      <button igxButton="raised" igxRipple="#fdfdfd" class="button" (click)="exportMultiPPT()">{{export}}</button>
  </div>
</igx-dialog>
