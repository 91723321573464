import { ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { CallAPIModes, Columns, Ids, PPTIds, UserSelectionIds, Dimensions, DashboardId } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-table-demographics',
  templateUrl: './table-demographics.component.html',
  styleUrls: ['./table-demographics.component.scss']
})
export class TableDemographicsComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_SOC_Data"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.DEMOGRAPHIC
  dashboardId = DashboardId.table_demographics
  dashboards = this.cs.DEMOGRAPHIC_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // Set initial look and behivor of trees, and THERE IS no Swith Mode
  callAPIMode = CallAPIModes.FilterAPI
  /** Tree Account */
  treeAccountIsMulti = true     // make the tree without checkbox
  callBackendToGetAccountAndAllProducts = true // get account from the store
  /** Tree Product */
  treeProductIsMulti = true     // make the tree without checkbox
  callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

  // call backend
  getHHDataFromBackend = true

  // Facts used in dashboard
  allowedGridFactIds = ["SF2", "SF3", "SF4", "SF5", "SF6", "SF7", "SF8", "SF9"]   // actually not needed, since the api delivers all the facts
  private calculatedFactIds = ["c_BuyerStructureM", "c_BuyerIndexVSProductAccount", "c_ExpenditureIndexVSProductAccount"]

  filterPeriod1Title = this.cs.PERIOD

  /** -------------------- Inputs for this dashboard -------------------- */
  public showTable = false
  public factTitle: string = this.cs.FACT

  // Facts
  public tableDemograhicsFactData = this.cs.TABLE_DEMOGRAPHICS_FACTS
  public selectedTableDemograhicsFactIds: string[] = this.tableDemograhicsFactData.filter(i=>i.Checked === true).map(i=>i.Id)
  public selectedTableDemograhicsFactNames: string[] = this.tableDemograhicsFactData.filter(i=>i.Checked === true).map(i=>i.Name)

  // Daten für die Zeilen in der Tabelle
  public socData: any[] = []            // data of soc from the dimension table
  public filterDemographicGroupTitle = this.cs.DEMOGRAPHIC_GROUP
  public DemographicGroups:any[] = []   // only the sections of soz
  public DATA_TABLE_SOZ:any[] = []      // all the soz data

  public DATA_ACCOUNT:any[] = []
  public DATA_PRODUCT:any[] = []
  public DATA_FACTS:any[] = []
  public facts_items = this.DATA_FACTS.length;

  public gridDataAccount: any = []
  public gridDataProduct: any = []

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  /** Multi Grid Facts | get selected items */
  public getSelectedTableDemograhicsFacts = (event: any) => {
    this.selectedTableDemograhicsFactNames = event.itemNames
    this.selectedTableDemograhicsFactIds = event.itemIds
    // console.log("------------this.selectedTableDemograhicsFactIds: ", this.selectedTableDemograhicsFactIds, this.selectedTableDemograhicsFactNames)
    this.feedGridWithData()
  }

  setToDefaultIndividualWhenReadingDim(dim: any[]) {
    this.socData = dim.filter(dim => dim.Dimension === Dimensions.SOC).sort((n1,n2) => {
      let n1IdInt = parseInt(n1.Id.substring(1))
      let n2IdInt = parseInt(n2.Id.substring(1))
      if (n1IdInt > n2IdInt) {
          return 1;
      }
      if (n1IdInt < n2IdInt) {
          return -1;
      }
      return 0;
    })
    // console.log("this.socData: ", this.socData)
  }

  setToDefaultIndividual() {
    this.benchmarkAccountData = this.utilityService.getSelectBenchmarkData(this.selectedAccountNodes)
    this.benchmarkProductData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
  }

  public getSelectedBenchmarkAccountItem = (event: any) => {
    // selection Benchmark is changed
    this.selectedBenchmarkAccountItem = event.selectedItem
    this.feedGridWithData()
  }

  public getSelectedBenchmarkProductItem = (event: any) => {
    // selection Benchmark is changed
    this.selectedBenchmarkProductItem = event.selectedItem
    this.feedGridWithData()
  }

  private createSocTable(sData: any[]) {
    let results: any[] = []
    for(let d of sData) {
      let result: any = {}
      if(this.isSocSection(d.Id)) {
        result[Ids.Group] = d[Ids.Name]
        result[Ids.Id] = d[Ids.Id]
        result[Ids.Name] = d[Ids.Name]
        result[Ids.isSection] = 1
        result[Ids.isVisible] = 1
      } else {
        result[Ids.Group] = this.getSocSection(d[Ids.Id])
        result[Ids.Id] = d[Ids.Id]
        result[Ids.Name] = d[Ids.Name]
        result[Ids.isSection] = 0
        result[Ids.isVisible] = 1
      }
      results.push(result)
    }
    return results
  }

  private createDemographicGroups(sData: any[]) {
    let results: any[] = []
    for(let d of sData) {
      if(this.isSocSection(d.Id)) {
        let result: any = {}
        result[Ids.Group] = d[Ids.Name]
        result[Ids.state] = true
        result[Ids.Id] = d[Ids.Id]
        results.push(result)
      }
    }
    return results
  }

  private getSocSection(id: string) {
    let idLen = id.length
    let sectionId = ""
    if(idLen === 4) { // such as S101, S109, S111 -> it belongs to section S100
      sectionId = id.substring(0,2) + "00"
    } else if (idLen === 5) {   // such as S1101, S1109, S1110 -> it belongs to section S1100
      sectionId = id.substring(0,3) + "00"
    }
    return this.DemographicGroups.filter(i=>i.Id === sectionId)[0][Ids.Group]
  }

  private isSocSection(id: string): boolean {
    if(id.slice(-2) === "00") { // last 2 characters are 00, such as 100, 1100
      return true
    } else {
      return false
    }
  }

  feedGridWithData(): void {
    this.isFilterDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectFilterSocDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log("table-demographics | grid | data: ", data)

          let dataProduct: any[] = []
          let dataAccount: any[] = []

          for(let d of data) {
            if(d[Columns.Account] === this.totalAccount[0] && d[Columns.Product] !== this.totalProduct[0]) {
              let d_p = Object.assign({}, d)
              d_p[Ids.type] = Ids.Product
              dataProduct.push(d_p)
            } else if(d[Columns.Account] !== this.totalAccount[0] && d[Columns.Product] === this.totalProduct[0]) {
              let d_a = Object.assign({}, d)
              d_a[Ids.type] = Ids.Account
              dataAccount.push(d_a)
            } else if(d[Columns.Account] === this.totalAccount[0] && d[Columns.Product] === this.totalProduct[0]) {
              let d_a = Object.assign({}, d)
              let d_p = Object.assign({}, d)
              d_p[Ids.type] = Ids.Product
              dataProduct.push(d_p)
              d_a[Ids.type] = Ids.Account
              dataAccount.push(d_a)
            }
          }

          // console.log("dataAccount: ", dataAccount)
          // console.log("dataProduct: ", dataProduct)

          let extendedDataProduct = this.utilityService.extendTableDemographicsData(Ids.Product, dataAccount, dataProduct, this.HH, this.calculatedFactIds, this.selectedBenchmarkAccountItem.Id, this.selectedBenchmarkProductItem.Id)
          // console.log("table-demographics | grid | extendedDataProduct: ", extendedDataProduct)

          let extendedDataAccount = this.utilityService.extendTableDemographicsData(Ids.Account, dataAccount, dataProduct, this.HH, this.calculatedFactIds, this.selectedBenchmarkAccountItem.Id, this.selectedBenchmarkProductItem.Id)
          // console.log("table-demographics | grid | extendedDataAccount: ", extendedDataAccount)

          this.DATA_FACTS   = this.get_DATA_FACTS()
          this.facts_items = this.DATA_FACTS.length
          this.DATA_ACCOUNT = this.get_DATA_ACCOUNT()
          this.DATA_PRODUCT = this.get_DATA_PRODUCT()

          // show the table or not
          this.checkShowTable()

          this.gridDataProduct = extendedDataProduct
          this.gridDataAccount = extendedDataAccount

          // console.log("this.DATA_ACCOUNT: ", this.DATA_ACCOUNT)
          // console.log("this.DATA_PRODUCT: ", this.DATA_PRODUCT)
        }
      )
    })
  }

  private checkShowTable() {
    if(this.DATA_FACTS.length === 0) {
      this.showTable = false
    } else if(this.DATA_ACCOUNT.length === 0 && this.DATA_PRODUCT.length === 0) {
      this.showTable = false
    } else if(this.DATA_TABLE_SOZ.filter(i => i.isVisible).length === 0 ) {
      this.showTable = false
    } else {
      this.showTable = true
    }
  }

  get_DATA_FACTS() {
    let result: any[] = []
    for(let i = 0; i < this.selectedTableDemograhicsFactIds.length; i++) {
      let item: any = {}
      item[Ids.FactNum] = this.selectedTableDemograhicsFactIds[i]
      item[Ids.FactName] = this.selectedTableDemograhicsFactNames[i]
      result.push(item)
    }
    return result
  }

  get_DATA_PRODUCT() {
    let result: any[] = []
    for(let pn of this.selectedProductNodes) {
      let item: any = {}
      item[Ids.ProdNum] = pn.Id
      item[Ids.ProdName] = pn.Name
      result.push(item)
    }
    return result
  }

  get_DATA_ACCOUNT() {
    let result: any[] = []
    for(let pn of this.selectedAccountNodes) {
      let item: any = {}
      item[Ids.BGNum] = pn.Id
      item[Ids.BGName] = pn.Name
      result.push(item)
    }
    return result
  }

  handleExportExcel() {
    // header
    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    header[this.cs.FACTS] = this.selectedTableDemograhicsFactNames.join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name
    header[this.cs.BENCHMARK_FOR_PRODUCT] = this.selectedBenchmarkAccountItem.Name
    header[this.cs.BENCHMARK_FOR_ACCOUNT] = this.selectedBenchmarkProductItem.Name

    // columnValueMap
    let columnValueMap: any = {}
    columnValueMap[this.cs.DEMOGRAPHIC_GROUP] = Ids.Group

    for(let product of this.DATA_PRODUCT) {
      for(let fact of this.DATA_FACTS) {
        columnValueMap[product[Ids.ProdName] + "-" + fact[Ids.FactName]] = product[Ids.ProdNum] + "-" + fact[Ids.FactNum]
      }
    }

    for(let account of this.DATA_ACCOUNT) {
      for(let fact of this.DATA_FACTS) {
        columnValueMap[account[Ids.BGName] + "-" + fact[Ids.FactName]] = account[Ids.BGNum] + "-" + fact[Ids.FactNum]
      }
    }

    // excelData
    let excelData: any = []
    for(let soz of this.DATA_TABLE_SOZ) {
      if(soz.isVisible === 1) {
        let row: any = {}
        row[Ids.Group] = soz.Name
        for(let product of this.DATA_PRODUCT) {
          for(let fact of this.DATA_FACTS) {
            row[product[Ids.ProdNum] + "-" + fact[Ids.FactNum]] = this.getValueOfProductWithTotalAccountAsNumber(this.gridDataProduct, product[Ids.ProdNum], this.selectedPeriod1Item.Id, soz.Id, this.selectedShopperGroupItem.Id, fact[Ids.FactNum])
          }
        }

        for(let account of this.DATA_ACCOUNT) {
          for(let fact of this.DATA_FACTS) {
            row[account[Ids.BGNum] + "-" + fact[Ids.FactNum]] = this.getValueOfAccountWithTotalProductAsNumber(this.gridDataAccount, account[Ids.BGNum], this.selectedPeriod1Item.Id, soz.Id, this.selectedShopperGroupItem.Id, fact[Ids.FactNum])
          }
        }
        excelData.push(row)
      }
    }

    // console.log("excelData: ", excelData, columnValueMap, this.DATA_PRODUCT)

    this.exportService.exportExcel("Table_Demographics", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, excelData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  getValueOfProductWithTotalAccount(data: any[], product:String, period:String, soz:String, mbd:String, fact:any):String {
    let result = this.getValueOfProductWithTotalAccountAsNumber(data, product, period, soz, mbd, fact)

    if (typeof result === 'number' && !Number.isNaN(result)) {
      return this.utilityService.myFormatNumber(result, this.getDigits(fact))
    } else {
      return ""
    }
  }

  getValueOfProductWithTotalAccountAsNumber(data: any[], product:String, period:String, soz:String, mbd:String, fact:any) {
    // Diese Funktion liefert Fact für ein Product in Total Market
    let account = this.totalAccount[0]

    let res = data.filter(function (obj) {
      return obj[Columns.Period] === period  && obj[Columns.ShopperGroup] === mbd && obj[Columns.Account] === account && obj[Columns.Product] === product && obj[Columns.Soz] === soz;
    });

    if(res.length > 0) {
      return Math.round(res[0][fact] * 10 * this.getDigits(fact)) / 10 * this.getDigits(fact)
    } else {
      return ""
    }
  }

  private getDigits(fact: string) {
    if(fact === "SF4" || fact === "SF7") {
      return 0
    } else {
      return 1
    }
  }

  getValueOfAccountWithTotalProduct(data: any[], account:String, period:String, soz:String, mbd:String, fact:any):String {
    let result = this.getValueOfAccountWithTotalProductAsNumber(data, account, period, soz, mbd, fact)
    if (typeof result === 'number' && !Number.isNaN(result)) {
      return this.utilityService.myFormatNumber(result, this.getDigits(fact))
    } else {
      return ""
    }
  }

  getValueOfAccountWithTotalProductAsNumber(data: any[], account:String, period:String, soz:String, mbd:String, fact:any) {
    // Diese Funktion liefert Fact für einen Account mit Total Category

    let prod = this.totalProduct[0]

    let res = data.filter(function (obj) {
      return obj[Columns.Period] === period  && obj[Columns.ShopperGroup] === mbd && obj[Columns.Account] === account && obj[Columns.Product] === prod && obj[Columns.Soz] === soz;
    });

    if(res.length > 0) {
      return Math.round(res[0][fact] * 10 * this.getDigits(fact)) / 10 * this.getDigits(fact)
    } else {
      return ""
    }
  }

  // Sobald eine Checkbox geändert wurde, werden die SOC-Zeilen aktualisiert
  public getChangeDemographicGroupEvent(event:any){
    let group = event.selectedItem.group
    let checked = event.selectedItem.checked
    // console.log("getChangeDemographicGroupEvent: ", group, checked)

    /** update this.DemographicGroups */
    let gindex = this.DemographicGroups.findIndex((i)=> i.Group === group)
    let item = this.DemographicGroups[gindex]
    let itemChanged = {Id: item.Id, Group: item.Group, state: checked}
    let copy = []
    for(let j = 0; j < this.DemographicGroups.length; j++) {
      if(j === gindex) {
        copy.push(itemChanged)
      } else {
        copy.push(this.DemographicGroups[j])
      }
    }
    this.DemographicGroups = copy
    // console.log("this.DemographicGroups updated: ", this.DemographicGroups)

    if(checked) {
      this.DATA_TABLE_SOZ.map((u: { isVisible: any; Group: string; }) => u.isVisible = u.Group === group ? 1 : u.isVisible);
    }
    else {
      this.DATA_TABLE_SOZ.map((u: { isVisible: any; Group: string; }) => u.isVisible = u.Group === group ? 0 : u.isVisible);
    }

    this.checkShowTable()
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.FactIds] = this.selectedTableDemograhicsFactIds
    selection[UserSelectionIds.FactNames] = this.selectedTableDemograhicsFactNames
    selection[UserSelectionIds.BenchmarkAccount] = this.selectedBenchmarkAccountItem
    selection[UserSelectionIds.BenchmarkProduct] = this.selectedBenchmarkProductItem
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.DemographicGroups] = this.DemographicGroups

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      // it should never be reached here, since there is no mode switch in this dashboard
      this.selectedBenchmarkAccountItem = this.cs.NO_BENCHMARK_SELECTED
      this.selectedBenchmarkProductItem = this.cs.NO_BENCHMARK_SELECTED
      return
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // period (period1)
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Fact
    this.selectedTableDemograhicsFactIds = this.utilityService.getSelectionItem(selection, UserSelectionIds.FactIds, this.tableDemograhicsFactData.filter(i=>i.Checked === true).map(i=>i.Id))
    this.selectedTableDemograhicsFactNames = this.utilityService.getSelectionItem(selection, UserSelectionIds.FactNames, this.tableDemograhicsFactData.filter(i=>i.Checked === true).map(i=>i.Name))

    for(let id of this.selectedTableDemograhicsFactIds) {
      this.tableDemograhicsFactData.filter(i=>i.Id === id)[0].Checked = true
    }

    // Benchmark for Products
    this.selectedBenchmarkAccountItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.BenchmarkAccount, this.cs.NO_BENCHMARK_SELECTED)
    // if it is not this.cs.NO_BENCHMARK_SELECTED, then we have to check if it is selected in the tree when initializing
    if(this.selectedBenchmarkAccountItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeProductIsMulti) {
        if(!this.selectedProductIds.includes(this.selectedBenchmarkAccountItem.Id)) {
          this.selectedBenchmarkAccountItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        if(!this.selectedAccountIds.includes(this.selectedBenchmarkAccountItem.Id)) {
          this.selectedBenchmarkAccountItem = this.cs.NO_BENCHMARK_SELECTED
        }
      }
    } else {
      this.selectedBenchmarkAccountItem = this.cs.NO_BENCHMARK_SELECTED
    }
    // console.log("this.selectedBenchmarkAccountItem: ", this.selectedBenchmarkAccountItem, this.cs.NO_BENCHMARK_SELECTED)

    // Benchmark for Accounts
    this.selectedBenchmarkProductItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.BenchmarkProduct, this.cs.NO_BENCHMARK_SELECTED)
    // if it is not this.cs.NO_BENCHMARK_SELECTED, then we have to check if it is selected in the tree when initializing
    if(this.selectedBenchmarkProductItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeProductIsMulti) {
        if(!this.selectedProductIds.includes(this.selectedBenchmarkProductItem.Id)) {
          this.selectedBenchmarkProductItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        if(!this.selectedAccountIds.includes(this.selectedBenchmarkProductItem.Id)) {
          this.selectedBenchmarkProductItem = this.cs.NO_BENCHMARK_SELECTED
        }
      }
    } else {
      this.selectedBenchmarkProductItem = this.cs.NO_BENCHMARK_SELECTED
    }
    // console.log("this.selectedBenchmarkProductItem: ", this.selectedBenchmarkProductItem, this.cs.NO_BENCHMARK_SELECTED)

    // shopper group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // demographic group
    this.DemographicGroups = this.utilityService.getSelectionItem(selection, UserSelectionIds.DemographicGroups, this.createDemographicGroups(this.socData))
    // console.log("this.DemographicGroups: ", this.DemographicGroups)
    this.DATA_TABLE_SOZ = this.createSocTable(this.socData)
    // console.log("this.DATA_TABLE_SOZ: ", this.DATA_TABLE_SOZ)

    for(let dg of this.DemographicGroups) {
      let checked = dg.state
      let group = dg.Group
      if(checked) {
        this.DATA_TABLE_SOZ.map((u: { isVisible: any; Group: string; }) => u.isVisible = u.Group === group ? 1 : u.isVisible);
      }
      else {
        this.DATA_TABLE_SOZ.map((u: { isVisible: any; Group: string; }) => u.isVisible = u.Group === group ? 0 : u.isVisible);
      }
    }
    // console.log("this.DATA_TABLE_SOZ: ", this.DATA_TABLE_SOZ)
  }
}
