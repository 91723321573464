import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { EnvService } from '../../shared/services';
import { User, AuthResponseData, ForgetPasswordResponse, MagicLoginResponse, ResetPasswordResponse } from '../state/auth.models';
import { TokenStorageService } from './token-storage.service';


@Injectable()
export class AuthService {
  private authApiUrl: string;

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private tokenStorageService: TokenStorageService,
  ) {
    this.authApiUrl = this.envService.getAuthApiUrl();
  }


  /**
   * Performs a request with user credentials
   * in order to get auth tokens
   *
   * @param {string} email
   * @param {string} password
   * @returns Observable<AuthResponseData>
   */
  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.authApiUrl}/auth/login`, {
      email,
      password,
    });
  }


  /**
   * Convert the Auth Api Response into a User object
   *
   * @param {AuthResponseData} data
   * @returns User Object
   */
  formatUser(email: string, databases_id: number, language: string = "", firstname: string = "", lastname: string = "", chart_color_first: string = "", chart_color_second: string = "") {
    const user = new User(
      email,
      firstname,
      lastname,
      '',
      databases_id,
      language,
      chart_color_first,
      chart_color_second
    );
    return user;
  }

  updateDBID(databases_id: number) {
    return this.http.post<any>(`${this.authApiUrl}/save_database`, {
      databases_id
    });
  }

  updateLanguage(language: string) {
    return this.http.post<any>(`${this.authApiUrl}/save_language`, {
      language
    });
  }

  updateDBIDLanguage(dbid: number, language: string) {
    const data = {
      language,
      dbid
    }
    return this.http.post<any>(`${this.authApiUrl}/save_dbid_language`, data);
  }

  updateBasicColors(firstColor: string, secondColor: string) {
    const data = {
      firstColor,
      secondColor
    }
    return this.http.post<any>(`${this.authApiUrl}/save_basic_colors`, data);
  }

  updatePersonalData(firstname: string, lastname: string) {
    const data = {
      firstname,
      lastname
    }
    return this.http.post<any>(`${this.authApiUrl}/save_pd`, data);
  }

  getDatabaseScope() {
    return this.http.get<any>(`${this.authApiUrl}/getDS`);
  }

  updateChartMaster(chart_master: any) {
    const data = {
      chart_master
    }
    return this.http.post<any>(`${this.authApiUrl}/save_chart_master`, data);
  }

  updateAccountProductColor(accountProduct: string, accountProductColor: any) {
    const url = `${this.authApiUrl}/save_account_product_color`
    let body = new HttpParams();
    body = body.append('accountProduct', accountProduct);
    body = body.append('accountProductColor', JSON.stringify(accountProductColor));
    return this.http.post<any[]>(url, body);
  }

  updatePowerpointMaster(powerpointMaster: string) {
    const url = `${this.authApiUrl}/save_powerpoint_master`
    let body = new HttpParams();
    body = body.append('powerpointMaster', powerpointMaster);
    return this.http.post<any[]>(url, body);
  }

  getAccountProductColor() {
    return this.http.get<any>(`${this.authApiUrl}/get_account_product_color`);
  }

  getChartMaster() {
    return this.http.get<any>(`${this.authApiUrl}/get_chart_master`);
  }

  getPptMaster(): Observable<any> {
    return this.http.get<any>(`${this.authApiUrl}/get_ppt_master`);
  }


  // Selection Template
   getSelectionTemplate(dashboard: string): Observable<any[]> {
    console.log("getSelectionTemplate")
    const url = `${this.authApiUrl}/CurrentDashboardSelectionTemplates`;
    // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxx call user_config_all")
    let queryParams = new HttpParams();
    queryParams = queryParams.append('dashboard', dashboard);
    return this.http.get<any[]>(url, { params: queryParams });
  }


  /**
   * Returns authenticated user
   * based on saved access token
   *
   * @returns {Observable<User>}
   */
   getAuthUser(): Observable<AuthResponseData> {
     if(this.tokenStorageService.getAuthToken()) {
        return this.http.get<AuthResponseData>(`${this.authApiUrl}/auth/me`);
     } else {
        return EMPTY;
     }
  }

  /**
   * Returns authenticated user
   * based on saved access token
   *
   * @returns {Observable<User>}
   */
  getUser(): Observable<User> {
    return this.http.get<User>(`${this.authApiUrl}/auth/me`);
  }

  forgetPassword(email: string): Observable<ForgetPasswordResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    let params = new HttpParams();
    if (email) {
      params = params.set('email', email);
    }
    return this.http.get<ForgetPasswordResponse>(`${this.authApiUrl}/auth/magic-link`, { headers, params })
  }


  magicLogin(magic_token: string):  Observable<MagicLoginResponse> {
    return this.http.post<MagicLoginResponse>(`${this.authApiUrl}/auth/magic-login`, { magic_token })
  }

  resetPassword(password: string, password_confirm: string):  Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(`${this.authApiUrl}/auth/resetpassword`, { password, password_confirm })
  }
}

