<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs-kpi-comparision
    [getSelectedNodes]="getSelectedNodes" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"
    [treeFactName]="treeFactName" [treeFactData]="treeFactData" [getSelectedFactNodes]="getSelectedFactNodes"

    [tabSelectionTitle]="tabSelectionTitle"
    [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem"
    [filterBenchmarkTitle]="filterBenchmarkTitle" [filterBenchmarkData]="filterProductBenchmarkData" [selectedBenchmarkItem]="selectedProductBenchmarkItem" [getSelectedBenchmarkItem]="getSelectedProductBenchmarkItem"
    [selectRowTitle] = "selectRowTitle" [selectRowData] = "select_items" [selectedRowItem] = "selectedRowItem" [getSelectedRowItem]="getSelectedRowItem"
    [selectColumnTitle] = "selectColumnTitle" [selectColumnData] = "select_items" [selectedColumnItem] = "selectedColumnItem" [getSelectedColumnItem]="getSelectedColumnItem"
    [selectFilterNumberOfColumnsTitle]="selectFilterNumberOfColumnsTitle" [filterValuesNumberOfColumnsData]="filterValuesNumberOfColumnsData" [selectedFilterNumberOfColumnsItem]="selectedFilterNumberOfColumnsItem" [getSelectedFilterNumberOfColumnsItem]="getSelectedFilterNumberOfColumnsItem"
    [selectMatModeTitle] = "selectMatModeTitle" [selectMatModeData] = "MatMode_items" [selectedMatModeItem] = "selectedMatModeItem" [getSelectedMatModeItem]="getSelectedMatModeItem" [matModeIsDisabled]="!period2IsSelected"
    [selectFilterAccountTitle]="selectFilterAccountTitle" [filterValuesAccountData]="filterValuesAccountData" [selectedFilterAccountItem]="selectedFilterAccountItem" [getSelectedFilterAccountItem]="getSelectedFilterAccountItem"
    [selectFilterProductTitle]="selectFilterProductTitle" [filterValuesProductData]="filterValuesProductData" [selectedFilterProductItem]="selectedFilterProductItem" [getSelectedFilterProductItem]="getSelectedFilterProductItem"
    [selectFilterFactTitle]="selectFilterFactTitle" [filterValuesFactData]="filterValuesFactData" [selectedFilterFactItem]="selectedFilterFactItem" [getSelectedFilterFactItem]="getSelectedFilterFactItem"
    [selectFilterShopperGroupTitle]="selectFilterShopperGroupTitle" [filterValuesShopperGroupData]="filterValuesShopperGroupData" [selectedFilterShopperGroupItem]="selectedFilterShopperGroupItem" [getSelectedFilterShopperGroupItem]="getSelectedFilterShopperGroupItem"

    (showLegendEvent)="displayLegend($event)"

    [showLegend]="showLegend" (showDataLabelEvent)="displayDataLabel($event)"
    [showDataLabel]="showDataLabel" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isFilterDataLoading$"
    > </app-filter-tabs-kpi-comparision>
  </div>


  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"                       [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"                       [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"                       [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"                       [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"             [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipFilterValueAccountTitle]="filterAccountTitle"            [selectedFilterValueAccount]="selectedFilterAccountItem"
      [chipFilterValueProductTitle]="filterProductTitle"            [selectedFilterValueProduct]="selectedFilterProductItem"
      [chipFilterValueFactTitle]="treeFactName"                     [selectedFilterValueFact]="selectedFilterFactItem"
      [chipFilterValueShopperGroupTitle]="filterShopperGroupTitle"  [selectedFilterValueShopperGroup]="selectedFilterShopperGroupItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoShopperGroup]="false" [showFilterValueAccount]="showFilterValueAccount" [showFilterValueProduct]="showFilterValueProduct"
      [showFilterValueFact]="showFilterValueFact" [showFilterValueShopperGroup]="showFilterValueShopperGroup"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item.content}}</div>
        <div><font [color]="chartColors[0]">{{item[ids.Series_current]}}:</font> {{item[ids.Label_current]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item.content}}</div>
        <div><font [color]="chartColors[1]">{{item[ids.Series_previous]}}:</font> {{item[ids.Label_previous]}}</div>
      </ng-template>

      <div class="kpi-comparison">
        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 1">
          <div class="chart-heading">{{chartHeadings[1]}}</div>
          <igx-data-chart
          name="CHART_KPI_1_Chart"
          width="450px"
          height="600px"
          [legend]="legend"
          yAxisMinimumValue="0"
          yAxisMaximumValue="100"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_1_chart>
              <igx-category-y-axis
              [dataSource]="chartData[1]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              name="CHART_KPI_1_yAxis"
              #CHART_KPI_1_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(1)"
              labelVisibility="Collapsed"
              name="CHART_KPI_1_xAxis"
              majorStrokeThickness="0"
              #CHART_KPI_1_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_1_xAxis"
              [yAxis]="CHART_KPI_1_yAxis"
              [valueMemberPath]="ids.value_current"
              isTransitionInEnabled="true"
              [dataSource]="chartData[1]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_1_BarSeries1"
              #CHART_KPI_1_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_1_xAxis"
              [yAxis]="CHART_KPI_1_yAxis"
              [valueMemberPath]="ids.value_previous"
              isTransitionInEnabled="true"
              [dataSource]="chartData[1]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_1_BarSeries2"
              #CHART_KPI_1_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[1]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_1_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[1]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_1_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
          <div class="legend" [hidden]="!showLegend">
              <igx-legend
              orientation="Horizontal"
              name="Legend"
              #legend>
              </igx-legend>
          </div>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 2">
            <div class="chart-heading-2">{{chartHeadings[2]}}</div>
            <igx-data-chart
            name="CHART_KPI_2_Chart"
            width="250px"
            height="600px"
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false"
            #CHART_KPI_2_chart>
                <igx-category-y-axis
                [dataSource]="chartData[2]"
                gap="0.5"
                overlap="-0.1"
                isInverted="true"
                useEnhancedIntervalManagement="false"
                enhancedIntervalPreferMoreCategoryLabels="false"
                label="content"
                labelVisibility="Collapsed"
                name="CHART_KPI_1_yAxis"
                #CHART_KPI_2_yAxis>
                </igx-category-y-axis>

                <igx-numeric-x-axis
                minimumValue="0"
                [maximumValue]="getAxisMaxValue(2)"
                majorStrokeThickness="0"
                labelVisibility="Collapsed"
                name="CHART_KPI_1_xAxis"
                #CHART_KPI_2_xAxis>
                </igx-numeric-x-axis>

                <igx-bar-series
                [xAxis]="CHART_KPI_2_xAxis"
                [yAxis]="CHART_KPI_2_yAxis"
                [valueMemberPath]="ids.value_current"
                isTransitionInEnabled="true"
                [dataSource]="chartData[2]"
                isHighlightingEnabled="true"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                [title]="selectedPeriod1Item.Name"
                [brush]="chartColors[0]"
                name="CHART_KPI_2_BarSeries1"
                #CHART_KPI_2_BarSeries1>
                </igx-bar-series>

                <igx-bar-series

                [xAxis]="CHART_KPI_2_xAxis"
                [yAxis]="CHART_KPI_2_yAxis"
                [valueMemberPath]="ids.value_previous"
                isTransitionInEnabled="true"
                [dataSource]="chartData[2]"
                isHighlightingEnabled="true"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                [title]="selectedPeriod1Item.Name"
                [brush]="chartColors[1]"
                name="CHART_KPI_2_BarSeries2"
                #CHART_KPI_2_BarSeries2>
                </igx-bar-series>

                <ng-container *ngIf="showDataLabel">
                  <igx-callout-layer
                  [dataSource]="chartData[2]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_2_BarSeries1"
                  [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>

                  <igx-callout-layer
                  [dataSource]="chartData[2]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_2_BarSeries2"
                  [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>
                </ng-container>
            </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 3">
            <div class="chart-heading-2">{{chartHeadings[3]}}</div>
            <igx-data-chart
            name="CHART_KPI_3_Chart"
            width="250px"
            height="600px"
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false"
            #CHART_KPI_3_chart>
                <igx-category-y-axis
                [dataSource]="chartData[3]"
                gap="0.5"
                overlap="-0.1"
                isInverted="true"
                labelVisibility="collapsed"
                useEnhancedIntervalManagement="false"
                enhancedIntervalPreferMoreCategoryLabels="false"
                label="content"
                labelVisibility="Collapsed"
                name="CHART_KPI_3_yAxis"
                #CHART_KPI_3_yAxis>
                </igx-category-y-axis>

                <igx-numeric-x-axis
                minimumValue="0"
                [maximumValue]="getAxisMaxValue(3)"
                name="CHART_KPI_3_xAxis"
                labelVisibility="Collapsed"
                majorStrokeThickness="0"
                #CHART_KPI_3_xAxis>
                </igx-numeric-x-axis>

                <igx-bar-series
                [xAxis]="CHART_KPI_3_xAxis"
                [yAxis]="CHART_KPI_3_yAxis"
                [valueMemberPath]="ids.value_current"
                yAxisMinimumValue="0"
                isTransitionInEnabled="true"
                [dataSource]="chartData[3]"
                isHighlightingEnabled="true"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                [title]="selectedPeriod1Item.Name"
                [brush]="chartColors[0]"
                name="CHART_KPI_3_BarSeries1"
                #CHART_KPI_3_BarSeries1>
                </igx-bar-series>

                <igx-bar-series

                [xAxis]="CHART_KPI_3_xAxis"
                [yAxis]="CHART_KPI_3_yAxis"
                [valueMemberPath]="ids.value_previous"
                yAxisMinimumValue="0"
                isTransitionInEnabled="true"
                [dataSource]="chartData[3]"
                isHighlightingEnabled="true"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                [title]="selectedPeriod2Item.Name"
                [brush]="chartColors[1]"
                name="CHART_KPI_3_BarSeries2"
                #CHART_KPI_3_BarSeries2>
                </igx-bar-series>

                <ng-container *ngIf="showDataLabel">
                  <igx-callout-layer
                  [dataSource]="chartData[3]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_3_BarSeries1"
                  [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>

                  <igx-callout-layer
                  [dataSource]="chartData[3]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_3_BarSeries2"
                  [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>
                </ng-container>
            </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 4">
            <div class="chart-heading-2">{{chartHeadings[4]}}</div>
            <igx-data-chart
            name="CHART_KPI_4_Chart"
            width="250px"
            height="600px"
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false"
            #CHART_KPI_4_chart>
                <igx-category-y-axis
                [dataSource]="chartData[4]"
                gap="0.5"
                overlap="-0.1"
                isInverted="true"
                labelVisibility="collapsed"
                useEnhancedIntervalManagement="false"
                enhancedIntervalPreferMoreCategoryLabels="false"
                label="content"
                labelVisibility="Collapsed"
                name="CHART_KPI_4_yAxis"
                #CHART_KPI_4_yAxis>
                </igx-category-y-axis>

                <igx-numeric-x-axis
                minimumValue="0"
                [maximumValue]="getAxisMaxValue(4)"
                name="CHART_KPI_4_xAxis"
                labelVisibility="Collapsed"
                majorStrokeThickness="0"
                #CHART_KPI_4_xAxis>
                </igx-numeric-x-axis>

                <igx-bar-series
                [xAxis]="CHART_KPI_4_xAxis"
                [yAxis]="CHART_KPI_4_yAxis"
                [valueMemberPath]="ids.value_current"
                yAxisMinimumValue="0"
                isTransitionInEnabled="true"
                [dataSource]="chartData[4]"
                isHighlightingEnabled="true"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                [title]="selectedPeriod1Item.Name"
                [brush]="chartColors[0]"
                name="CHART_KPI_4_BarSeries1"
                #CHART_KPI_4_BarSeries1>
                </igx-bar-series>

                <igx-bar-series

                [xAxis]="CHART_KPI_4_xAxis"
                [yAxis]="CHART_KPI_4_yAxis"
                [valueMemberPath]="ids.value_previous"
                yAxisMinimumValue="0"
                isTransitionInEnabled="true"
                [dataSource]="chartData[4]"
                isHighlightingEnabled="true"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                [title]="selectedPeriod2Item.Name"
                [brush]="chartColors[1]"
                name="CHART_KPI_4_BarSeries2"
                #CHART_KPI_4_BarSeries2>
                </igx-bar-series>

                <ng-container *ngIf="showDataLabel">
                  <igx-callout-layer
                  [dataSource]="chartData[4]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_4_BarSeries1"
                  [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>

                  <igx-callout-layer
                  [dataSource]="chartData[4]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_4_BarSeries2"
                  [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>
                </ng-container>
            </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 5">
          <div class="chart-heading-2">{{chartHeadings[5]}}</div>
          <igx-data-chart
          name="CHART_KPI_5_Chart"
          width="250px"
          height="600px"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_5_chart>
              <igx-category-y-axis
              [dataSource]="chartData[5]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              labelVisibility="collapsed"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              labelVisibility="Collapsed"
              name="CHART_KPI_5_yAxis"
              #CHART_KPI_5_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(5)"
              name="CHART_KPI_5_xAxis"
              labelVisibility="Collapsed"
              majorStrokeThickness="0"
              #CHART_KPI_5_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_5_xAxis"
              [yAxis]="CHART_KPI_5_yAxis"
              [valueMemberPath]="ids.value_current"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[5]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_5_BarSeries1"
              #CHART_KPI_5_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_5_xAxis"
              [yAxis]="CHART_KPI_5_yAxis"
              [valueMemberPath]="ids.value_previous"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[5]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_5_BarSeries2"
              #CHART_KPI_5_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[5]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_5_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[5]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_5_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 6">
          <div class="chart-heading-2">{{chartHeadings[6]}}</div>
          <igx-data-chart
          name="CHART_KPI_6_Chart"
          width="250px"
          height="600px"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_6_chart>
              <igx-category-y-axis
              [dataSource]="chartData[6]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              labelVisibility="collapsed"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              labelVisibility="Collapsed"
              name="CHART_KPI_6_yAxis"
              #CHART_KPI_6_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(6)"
              name="CHART_KPI_6_xAxis"
              labelVisibility="Collapsed"
              majorStrokeThickness="0"
              #CHART_KPI_6_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_6_xAxis"
              [yAxis]="CHART_KPI_6_yAxis"
              [valueMemberPath]="ids.value_current"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[6]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_6_BarSeries1"
              #CHART_KPI_6_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_6_xAxis"
              [yAxis]="CHART_KPI_6_yAxis"
              [valueMemberPath]="ids.value_previous"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[6]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_6_BarSeries2"
              #CHART_KPI_6_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[6]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_6_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[6]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_6_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 7">
          <div class="chart-heading-2">{{chartHeadings[7]}}</div>
          <igx-data-chart
          name="CHART_KPI_7_Chart"
          width="250px"
          height="600px"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_7_chart>
              <igx-category-y-axis
              [dataSource]="chartData[7]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              labelVisibility="collapsed"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              labelVisibility="Collapsed"
              name="CHART_KPI_7_yAxis"
              #CHART_KPI_7_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(7)"
              name="CHART_KPI_7_xAxis"
              labelVisibility="Collapsed"
              majorStrokeThickness="0"
              #CHART_KPI_7_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_7_xAxis"
              [yAxis]="CHART_KPI_7_yAxis"
              [valueMemberPath]="ids.value_current"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[7]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_7_BarSeries1"
              #CHART_KPI_7_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_7_xAxis"
              [yAxis]="CHART_KPI_7_yAxis"
              [valueMemberPath]="ids.value_previous"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[7]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_7_BarSeries2"
              #CHART_KPI_7_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[7]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_7_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[7]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_7_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 8">
          <div class="chart-heading-2">{{chartHeadings[8]}}</div>
          <igx-data-chart
          name="CHART_KPI_8_Chart"
          width="250px"
          height="600px"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_8_chart>
              <igx-category-y-axis
              [dataSource]="chartData[8]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              labelVisibility="collapsed"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              labelVisibility="Collapsed"
              name="CHART_KPI_8_yAxis"
              #CHART_KPI_8_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(8)"
              name="CHART_KPI_8_xAxis"
              labelVisibility="Collapsed"
              majorStrokeThickness="0"
              #CHART_KPI_8_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_8_xAxis"
              [yAxis]="CHART_KPI_8_yAxis"
              [valueMemberPath]="ids.value_current"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[8]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_8_BarSeries1"
              #CHART_KPI_8_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_8_xAxis"
              [yAxis]="CHART_KPI_8_yAxis"
              [valueMemberPath]="ids.value_previous"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[8]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_8_BarSeries2"
              #CHART_KPI_8_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[8]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_8_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[8]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_8_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 9">
          <div class="chart-heading-2">{{chartHeadings[9]}}</div>
          <igx-data-chart
          name="CHART_KPI_9_Chart"
          width="250px"
          height="600px"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_9_chart>
              <igx-category-y-axis
              [dataSource]="chartData[9]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              labelVisibility="collapsed"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              labelVisibility="Collapsed"
              name="CHART_KPI_9_yAxis"
              #CHART_KPI_9_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(9)"
              name="CHART_KPI_9_xAxis"
              labelVisibility="Collapsed"
              majorStrokeThickness="0"
              #CHART_KPI_9_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_9_xAxis"
              [yAxis]="CHART_KPI_9_yAxis"
              [valueMemberPath]="ids.value_current"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[9]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_9_BarSeries1"
              #CHART_KPI_9_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_9_xAxis"
              [yAxis]="CHART_KPI_9_yAxis"
              [valueMemberPath]="ids.value_previous"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[9]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_9_BarSeries2"
              #CHART_KPI_9_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[9]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_9_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[9]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_9_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>

        <div *ngIf="selectedFilterNumberOfColumnsItem.Id >= 10">
          <div class="chart-heading-2">{{chartHeadings[10]}}</div>
          <igx-data-chart
          name="CHART_KPI_10_Chart"
          width="250px"
          height="600px"
          isHorizontalZoomEnabled="false"
          isVerticalZoomEnabled="false"
          #CHART_KPI_10_chart>
              <igx-category-y-axis
              [dataSource]="chartData[10]"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              labelVisibility="collapsed"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              label="content"
              labelVisibility="Collapsed"
              name="CHART_KPI_10_yAxis"
              #CHART_KPI_10_yAxis>
              </igx-category-y-axis>

              <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="getAxisMaxValue(10)"
              name="CHART_KPI_10_xAxis"
              labelVisibility="Collapsed"
              majorStrokeThickness="0"
              #CHART_KPI_10_xAxis>
              </igx-numeric-x-axis>

              <igx-bar-series
              [xAxis]="CHART_KPI_10_xAxis"
              [yAxis]="CHART_KPI_10_yAxis"
              [valueMemberPath]="ids.value_current"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[10]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              [title]="selectedPeriod1Item.Name"
              [brush]="chartColors[0]"
              name="CHART_KPI_10_BarSeries1"
              #CHART_KPI_10_BarSeries1>
              </igx-bar-series>

              <igx-bar-series

              [xAxis]="CHART_KPI_10_xAxis"
              [yAxis]="CHART_KPI_10_yAxis"
              [valueMemberPath]="ids.value_previous"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="chartData[10]"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              [title]="selectedPeriod2Item.Name"
              [brush]="chartColors[1]"
              name="CHART_KPI_10_BarSeries2"
              #CHART_KPI_10_BarSeries2>
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData[10]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_10_BarSeries1"
                [labelMemberPath]="ids.Label_current" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_current"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>

                <igx-callout-layer
                [dataSource]="chartData[10]" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_KPI_10_BarSeries2"
                [labelMemberPath]="ids.Label_previous" [yMemberPath]="ids.index_yaxis" [xMemberPath]="ids.value_previous"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>
      </div>

    </div>
  </div>

  <div class="loading" *ngIf="(isFilterDataLoading$ | async) === true || isCreatingExport === true"></div>

</section>
