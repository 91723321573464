<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"
    [showSingleFactFilter]="true" [selectSingleFactTitle]="filterChartFactTitle" [selectSingleFactData]="filterChartFactDataWithoutPenetration" [selectedSingleFactItem]="selectedChartFactItem" [getSelectedSingleFact]="getSelectedChartFact"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [showFilterBenchmark]="true" [filterBenchmarkTitle]="filterBenchmarkTitle" [filterBenchmarkData]="filterBenchmarkData" [selectedBenchmarkItem]="selectedBenchmarkItem" [getSelectedBenchmarkItem]="getSelectedBenchmarkItem"
    [showFilterMode]="true" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
    [showStaticFilter]="true" [selectStaticFilterTitle]="selectChartSortingTitle" [selectStaticFilterData]="selectChartSortingData" [selectedStaticFilterItem]="selectedChartSortingItem" [getSelectedStaticFilterItem]="getSelectedChartSorting"
    [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
    [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"
    [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBenchmarkTitle]="filterBenchmarkTitle"        [selectedBenchmark]="selectedBenchmarkItem"
      [chipFactTitle]="factTitle"                        [selectedFact]="selectedChartFactItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      [showInfoBenchmark]="true"  [showInfoFact]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip>
        <span>{{item.Series}}: {{item.Label}}</span>
      </ng-template>

      <igx-data-chart
        #chart
        width="900px"
        height="600px"
        [legend]="legend"
        isHorizontalZoomEnabled="false"
        isVerticalZoomEnabled="false"
        isSeriesHighlightingEnabled="true"
        highlightingMode="FadeOthersSpecific"
        highlightingBehavior="NearestItemsAndSeries"
        crosshairsDisplayMode="None"
        [brushes]="chartColors[0]"
        outlines="Transparent"
        [dataSource]="chartData"
        >

        <igx-category-x-axis
          #xAxis
          [majorStroke]="configService.STROKE_COLOR_CATEGORY"
          majorStrokeThickness="1"
          [label]="modeCategory">
        </igx-category-x-axis>

        <igx-numeric-y-axis
          #yAxis
          minimumValue="0"
          maximumValue="110"
          [majorStroke]="configService.STROKE_COLOR_VALUE"
          abbreviateLargeNumbers="true"
          [title]="selectedChartFactItem.Name"
          titleLeftMargin="5"
          titleRightMargin="0"
          interval="20">
        </igx-numeric-y-axis>

        <igx-column-series
          #columnseries
          [xAxis]="xAxis"
          [yAxis]="yAxis"
          [title]="selectedChartFactItem.Name"
          valueMemberPath="Value"
          showDefaultTooltip="false" [tooltipTemplate]="valueTooltip"
          >
        </igx-column-series>

        <ng-container *ngIf="selectedBenchmarkItem.Id !== NoBenchmarkSelectedId">
          <igx-value-overlay
            [axis]="yAxis"
            [title]="benchmarkTitle"
            [value]="selectedBenchmarkItem.Values[0]"
            [brush]="configService.BENCHMARK_COLOR"
            thickness=3>
          </igx-value-overlay>
        </ng-container>

        <ng-container *ngIf="showDataLabel">
          <igx-callout-layer
                [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="columnseries"
                [labelMemberPath]="ids.Label" [xMemberPath]="ids.index" [yMemberPath]="ids.value"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
          </igx-callout-layer>
        </ng-container>
      </igx-data-chart>

      <div class="legend" [hidden]="!showLegend">
        <igx-legend #legend orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
      </div>

    </div>

    <app-export-multi-ppt-dialog
        [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
        [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
        [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
      ></app-export-multi-ppt-dialog>

    <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
  </div>
</section>
