import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EnvService } from 'src/app/shared/services';


@Injectable({
  providedIn: 'root'
})
export class RESTService {
  private dataApiUrl: string;

  constructor(
    private http: HttpClient,
    private envService: EnvService,
  ) {
    this.dataApiUrl = this.envService.getDataAPIUrl();
  }

  getData(table: string, accounts: string[], products: string[], facts: string[]): Observable<any[]> {
    let url = ''
    switch(table) {
      case "NTP_Data":
        url = `${this.dataApiUrl}/get_ntp_data`;
        return this.getDataBase(url, accounts, products, facts)
        break;
      case "NTP_Data_Normalized":
        url = `${this.dataApiUrl}/get_ntp_data_n`;
        return this.getDataBase(url, accounts, products, facts)
        break;
      case "NTP_Data-rest-requirement":
        url = `${this.dataApiUrl}/get_ntp_data_rest_requirement`;
        return this.getDataBase(url, accounts, products, facts)
        break;
      default:
        return of(['should never get here'])
    }
  }

  filterData(table: string, accounts: string[], products: string[], periods: string[], facts: string[]): Observable<any[]> {
    let url = ''
    switch(table) {
      case "NTP_Data":
        url = `${this.dataApiUrl}/filter_ntp_data`;
        return this.filterDataBase(url, accounts, products, periods, facts)   // only accounts and products are used
        break;
      case "NTP_Data_Normalized":
        url = `${this.dataApiUrl}/filter_ntp_data_n`;
        return this.filterDataBase(url, accounts, products, periods, facts)
        break;
      case "NTP_SOC_Data":
        url = `${this.dataApiUrl}/filter_ntp_soc_data`;
        return this.filterDataBase(url, accounts, products, periods, facts) // only accounts and products are used
        break;
      default:
        return of(['should never get here'])
    }
  }


  getDataBase(apiUrl:string, accounts: string[], products: string[], facts: string[]): Observable<any[]> {
     // console.log("xxxxxxxxxxxxxxxxxxxxxx call getDataBase ", apiUrl)
    let queryParams = new HttpParams();
    for (let account of accounts) {
      queryParams = queryParams.append('accounts[]', account);
    }
    for (let product of products) {
      queryParams = queryParams.append('products[]', product);
    }
    for (let fact of facts) {
      queryParams = queryParams.append('facts[]', fact);
    }
    return this.http.get<any[]>(apiUrl, { params: queryParams });
  }

  filterDataBase(apiUrl:string, accounts: string[], products: string[], periods: string[], facts: string[]) : Observable<any[]> {
    // console.log("xxxxxxxxxxxxxxxxxxxxxx call filterDataBase ", apiUrl)
    let queryParams = new HttpParams();
    for (let account of accounts) {
      queryParams = queryParams.append('accounts[]', account);
    }
    for (let product of products) {
      queryParams = queryParams.append('products[]', product);
    }
    for (let period of periods) {
      queryParams = queryParams.append('periods[]', period);
    }
    for (let fact of facts) {
      queryParams = queryParams.append('facts[]', fact);
    }
    return this.http.get<any[]>(apiUrl, { params: queryParams });
  }

  getDimensions(): Observable<any[]> {
    const url = `${this.dataApiUrl}/dimensions2`;
    // console.log("xxxxxxxxxxxxxxxxxxxxxx call dimensinos2")
    return this.http.get<any[]>(url);
  }

  getHH(): Observable<any[]> {
    const url = `${this.dataApiUrl}/ntp_hh_data`;
    // console.log("xxxxxxxxxxxxxxxxxxxxxx call hh data")
    return this.http.get<any[]>(url);
  }

  public getSelection(dashboard: string): Observable<any[]> {
    const url = `${this.dataApiUrl}/CurrentDashboardSelection`;
    // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxx call user_config_all")
    let queryParams = new HttpParams();
    queryParams = queryParams.append('dashboard', dashboard);
    return this.http.get<any[]>(url, { params: queryParams });
  }

  // Selection Template
  public getSelectionTemplate(dashboard: string): Observable<any[]> {
    console.log("getSelectionTemplate")
    const url = `${this.dataApiUrl}/CurrentDashboardSelectionTemplates`;
    // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxx call user_config_all")
    let queryParams = new HttpParams();
    queryParams = queryParams.append('dashboard', dashboard);
    return this.http.get<any[]>(url, { params: queryParams });
  }


  public addOrUpdateSelection(dashboards: string[], selections: any): Observable<any[]> {
    const url = `${this.dataApiUrl}/SaveDashboardSelection`;

    let body = new HttpParams();

    for (let dashboard of dashboards) {
      body = body.append('dashboards[]', dashboard);
    }

    body = body.append('selections', JSON.stringify(selections));
    return this.http.post<any[]>(url, body);
  }
}
