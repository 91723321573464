<header class="header">
  <div class="flex items-center">
    <!-- BEGIN: Logo -->
    <a routerLink="/home" class="flex items-center">
      <img src="/assets/nielsenIQ.png" class="logo-icon" title="logo"/>
    </a>
    <!-- END: Logo -->
  </div>

  <ng-container *ngIf="isLoggedId$ | async">
    <div class="flex items-center">
      <!-- BEGIN: Main menu items -->
      <div class="menu-items space-x-5" >
        <ng-container *ngFor="let item of menuProtectedItems">
          <ng-container *ngIf="chartMasterActive; else link">
            <a
            [class]="isSelected(item.link) ? 'selected': 'unselected'"
            >
            {{ item.label }}
            </a>
          </ng-container>

          <ng-template #link>
            <a
            [routerLink]="item.link"
            routerLinkActive="active"
            #rla="routerLinkActive"
            [class]="rla.isActive ? 'selected' : 'unselected'"
            >
            {{ item.label }}
            </a>
          </ng-template>
        </ng-container>
      </div>
      <!-- END: Main menu items -->
    </div>
  </ng-container>


  <div class="flex items-center">
    <div class="flex items-center">
      <!-- BEGIN: Auth user menu -->
      <div *ngIf="authUser$ | async as user">
        <!-- <igx-avatar class="avatar" [initials]="user.firstname.charAt(0) + user.lastname.charAt(0)"
                    [igxToggleAction]="menu" [igxDropDownItemNavigation]="menu" size="small"  [roundShape]="true"
                    [overlaySettings]="overlaySettings" bgColor="#00f000" color="black"
        ></igx-avatar>
        <igx-drop-down #menu>
            <igx-drop-down-item>
              <button igxButton="flat" (click)="logout()" igxButtonColor="black">
                <igx-icon name="logout" family="filter-icons"></igx-icon>
                <span>Logout</span>
              </button>
            </igx-drop-down-item>
        </igx-drop-down> -->
        <button igxButton="icon" [routerLink]="'/dashboard/settings/'" [disabled]="chartMasterActive">
          <igx-icon>
            settings
          </igx-icon>
        </button>
        <button igxButton="icon" (click)="logout()" [disabled]="chartMasterActive">
          <igx-icon>
            logout
          </igx-icon>
        </button>
      </div>
    </div>
  </div>
</header>
