import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IgxExcelExporterService } from '@infragistics/igniteui-angular';

import { PricePerformanceComponent } from './price-performance/price-performance.component';
import { TacticalRoutingModule } from './tactical.routing.module';
import { PromotionPerformanceComponent } from './promotion-performance/promotion-performance.component';
import { AssortmentPerformanceComponent } from './assortment-performance/assortment-performance.component';

import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';



@NgModule({
  declarations: [
    PricePerformanceComponent,
    PromotionPerformanceComponent,
    AssortmentPerformanceComponent
  ],
  providers: [IgxExcelExporterService],
  imports: [
    CommonModule,
    TacticalRoutingModule,
    SharedDashboardComponentModule,
  ]
})
export class TacticalModule { }
