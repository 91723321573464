import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { IgxCalloutLayerComponent, IgxColumnSeriesComponent, IgxDataChartComponent } from 'igniteui-angular-charts';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { Columns, UserSelectionIds, ChangeRateIds, PPTIds, Ids, DashboardId } from '../../../shared/model/constants';
import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-market-share-development',
  templateUrl: './market-share-development.component.html',
  styleUrls: ['./market-share-development.component.scss']
})
export class MarketShareDevelopmentComponent extends BaseComponent{
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.MARKET_SHARE
  dashboardId = DashboardId.market_share_development
  dashboards = this.cs.MARKET_SHARE_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

   // Set initial look and behivor of trees, and there is no swith mode
  /** Tree Account */
  treeAccountIsMulti = true
  callBackendToGetAccountAndAllProducts = false // get account from the store

  /** Tree Product */
  treeProductIsMulti = false
  callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product


  // It it has 2 period filters
  hasPeriod2Filter = true

  getUserDefinedAccountProductColor = true

  // static filter if existing
  public selectChangeRateTitle: string = this.cs.CHANGE_RATE
  public selectChangeRateData: any[] = this.cs.SELECT_CHANGE_RATE_DATE
  public selectedChangeRateItem: SelectItem = {Id: "", Name: ""}

  // Facts used in dashboard
  factsMaxValueIsHundred = ["F9"]
  allowedChartFactIds = ["F9", "F7", this.penetrationFactId] // F9 is the default selected fact for the chart, so it's at the first position

  /** -------------------- Inputs for this dashboard -------------------- */
  filterPeriod1Title = this.cs.CURRENT_PERIOD
  filterPeriod2Title = this.cs.PERVIOUS_PERIOD
  filterChartFactTitle = this.cs.MARKET_SHARE_FACT

  public factSelectData: any[]  = [] // used to show the Facts to select in tab "Period & Facts"

  @ViewChild("barChart", { static: true })
  public barChart!: IgxDataChartComponent;
  public barChartCalloutLayer!: IgxCalloutLayerComponent;

  @ViewChild("barChangeChart", { static: false })
  public barChangeChart!: IgxDataChartComponent;
  public barChangeChartCalloutLayer!: IgxCalloutLayerComponent;

  @ViewChild("barFactSeries")
  public barseries!: IgxColumnSeriesComponent

  public minChangeValue!: number
  public maxChangeValue!: number
  public interval!: number

  public showChangeChart: boolean = true
  private period2WasNoSelected: boolean = false // flag detecting if the period2 was no-selected last time. if so, then we need to add the calloutlayer in change chart
  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  setToDefaultIndividual() {
    if(this.showDataLabel) {
      this.setBarChartCalloutLayer()
      this.setBarChangeChartCalloutLayer()
    }
    else {
      this.barChart.series.clear()    // as long as barChart exists, you can call clear() as many times as possible
      this.barChangeChart.series.clear()
    }
    this.barseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning);
  }

  individualLogicForPeriod2() {
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.showChangeChart = false
      this.period2WasNoSelected = true
    } else {
      this.showChangeChart = true

      if(this.period2WasNoSelected) {
        this.changeDetector.detectChanges();
        if(this.showDataLabel) {
          this.setBarChangeChartCalloutLayer()
        } else {
          this.barChangeChart.series.clear()
        }
      }

      this.period2WasNoSelected = false
    }
  }

  /** Select Change Rate | as percentage or as value */
  public getSelectedChangeRate = (event: any) => {
    this.selectedChangeRateItem = event.selectedItem

    // console.log("base | this.selectedChangeRateItem: ", this.selectedChangeRateItem)

    this.feedChartWithData()
  }

  feedChartWithData(): void {
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), [this.selectedChartFactItem.Id, this.penetrationFactId], this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( data => {
            this.hasPenetrationWarning = false

            // console.log("market-share-development | data: ", data)

            this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})

            // console.log("market-share-development | preprocessed data: ", data)
            let dataOfPeriod1 = data.filter(i => i[Columns.Period] === this.selectedPeriod1Item.Id)

            // no need to convert, because chartData has the same format as the data
            this.chartData = this.utilityService.replaceIdWithName(dataOfPeriod1,
              Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
              this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData, [], false, true
            )

            this.addPenetration(this.chartData)

            // add data labels in chart
            let idx: number = 0;
            for (const item of this.chartData) {
                item[Ids.index] = idx;
                item[Ids.value] = item.Value;
                item[Ids.Label] = this.utilityService.myFormatNumber(item.Value, 1);
                item[Ids.Color] = this.accountColorMapping[item[Ids.BGNum_Id]].replace("#", "");
                idx++;
            }

            if(this.showChangeChart) {
              // calcuate the data for ChangeChart
              let dataOfPeriod1WithoutPenetration = data.filter(i => i[Columns.Period] === this.selectedPeriod1Item.Id && i[Columns.Fact] !== this.penetrationFactId)
              let dataOfPeriod2WithoutPenetration = data.filter(i => i[Columns.Period] === this.selectedPeriod2Item.Id && i[Columns.Fact] !== this.penetrationFactId)
              let dataOfChange = []

              for(let i of dataOfPeriod1WithoutPenetration) {
                let j = dataOfPeriod2WithoutPenetration.filter(d => d[Columns.Account] === i[Columns.Account] && d[Columns.ShopperGroup] === i[Columns.ShopperGroup] && d[Columns.Product] === i[Columns.Product])[0]
                let item = Object.assign({}, j);
                item[ChangeRateIds.changeInValue] = Math.round((i.Value - j.Value)*100) / 100
                item[ChangeRateIds.changeInPercentage] = Math.round((i.Value - j.Value)/j.Value * 100 * 100) / 100
                dataOfChange.push(item)
              }

              this.changeChartData = this.utilityService.replaceIdWithName(dataOfChange,
                Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
                this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData,
                [ChangeRateIds.changeInValue, ChangeRateIds.changeInPercentage]
              )

              // add data labels in change chart
              let changeValues: number[] = []
              let idx2 = 0;
              for (const item of this.changeChartData) {
                  item[Ids.index] = idx2;
                  item[Ids.value] = this.selectedChangeRateItem.Id === ChangeRateIds.changeInValue ? item[ChangeRateIds.changeInValue] : item[ChangeRateIds.changeInPercentage]
                  item[Ids.Label] = this.selectedChangeRateItem.Id === ChangeRateIds.changeInValue ? (item[ChangeRateIds.changeInValue] ? this.utilityService.myFormatNumber(item[ChangeRateIds.changeInValue], 1) : '0') : (item[ChangeRateIds.changeInPercentage] ? this.utilityService.myFormatNumber(item[ChangeRateIds.changeInPercentage], 1) : '0');
                  item[Ids.Color] = item[Ids.value] >= 0 ? this.configService.COLOR_GREEN.replace("#", "") : this.configService.COLOR_RED.replace("#", "")
                  changeValues.push(item[Ids.value])
                  idx2++;
              }

              // get the min, max and interval for the xAxis of the change chart
              let minValue = Math.min.apply(Math, changeValues)
              let maxValue = Math.max.apply(Math, changeValues)
              if(maxValue <= 0) {
                this.maxChangeValue = 0
                this.minChangeValue = Math.round(minValue - 1)
              } else if(minValue >= 0) {
                this.minChangeValue = 0
                this.maxChangeValue = Math.round(maxValue + 1)
              } else if(maxValue > 0 && minValue < 0) {
                this.minChangeValue = Math.round(minValue - 1)
                this.maxChangeValue = Math.round(maxValue + 1)
              }
              if(Math.abs(this.maxChangeValue) >= Math.abs(this.minChangeValue)) {
                this.interval = Math.abs(this.maxChangeValue)
              } else {
                this.interval = Math.abs(this.minChangeValue)
              }
            }

            // console.log("market-share-development | chart data: ", this.chartData)
            // console.log("market-share-development | chart data2: ", this.changeChartData)
          }
        )
      })

  }

  private addPenetration(chartData: any[]) {
    let dataOfChartFact = chartData.filter(d => d[Columns.Fact] === this.selectedChartFactItem.Name)
    let dataOfPenetration = chartData.filter(d => d[Columns.Fact] === this.penetrationFactName)
    for(let i of dataOfChartFact) {
      let iPenetration: any = dataOfPenetration.filter(d => d[Columns.Product] === i[Columns.Product] && d[Columns.Account] === i[Columns.Account] && d[Columns.Period] === i[Columns.Period] && d[Columns.ShopperGroup] === i[Columns.ShopperGroup])[0]
      i[this.penetrationFactId] = iPenetration[Columns.Value]
      i[Ids.Penetration_SingleSeries] = this.utilityService.havePenetrationWarning(iPenetration[Columns.Value])
      if(!this.hasPenetrationWarning && i[Ids.Penetration_SingleSeries]) {
        this.hasPenetrationWarning = true
      }
    }
    this.chartData = dataOfChartFact
  }

  handlePenetrationWarning() {
    this.barseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning);
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = this.treeAccountIsMulti ? Ids.Product : Ids.Account

    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields: any[] = []
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id) ? true : false,
    options[PPTIds.LeftValAxisTitle] = this.selectedChartFactItem.Name,
    options[PPTIds.RightValAxisTitle] = this.selectedChangeRateItem.Name

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.TwoBarCharts, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData, this.changeChartData)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields: any[] = []
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id) ? true : false,
    options[PPTIds.LeftValAxisTitle] = this.selectedChartFactItem.Name,
    options[PPTIds.RightValAxisTitle] = this.selectedChangeRateItem.Name

    this.exportService.exportPPT(PPTIds.TwoBarCharts, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData, this.changeChartData)
                      .then(fileName => {
                          console.log(`Created Single PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNTS] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCTS] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.FACT] = this.selectedChartFactItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    this.restService.filterData (
      this.sourceTable,
      accounts.map(i => i.Id),
      products.map(i => i.Id),
      this.getPeriodIds(),
      [this.selectedChartFactItem.Id]
    )
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      // console.log("market-share-development | multippt | data: ", data)

      this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})

      // console.log("market-share-development | multippt | preprocessed data: ", data)


      // console.log("market-share-development | preprocessed data: ", data)
      let dataOfPeriod1 = data.filter(i => i[Columns.Period] === this.selectedPeriod1Item.Id)

      // no need to convert, because chartData has the same format as the data
      let leftData = this.utilityService.replaceIdWithName(dataOfPeriod1,
        Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
        this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData, [], false, true
      )

      // add data labels in chart
      let idx: number = 0;
      for (const item of leftData) {
          item[Ids.Color] = this.accountColorMapping[item[Ids.BGNum_Id]].replace("#", "");
          idx++;
      }

      // console.log("market-share-development | multippt | leftData: ", leftData)

      let rightData: any[] = []
      if(this.showChangeChart) {
        // calcuate the data for ChangeChart
        let dataOfPeriod2 = data.filter(i => i[Columns.Period] === this.selectedPeriod2Item.Id)
        let dataOfChange = []

        for(let i of dataOfPeriod1) {
          let j = dataOfPeriod2.filter(d => d[Columns.Account] === i[Columns.Account] && d[Columns.ShopperGroup] === i[Columns.ShopperGroup] && d[Columns.Product] === i[Columns.Product])[0]
          let item = Object.assign({}, j);
          item[ChangeRateIds.changeInValue] = Math.round((i.Value - j.Value)*100) / 100
          item[ChangeRateIds.changeInPercentage] = Math.round((i.Value - j.Value)/j.Value * 100 * 100) / 100
          dataOfChange.push(item)
        }

        rightData = this.utilityService.replaceIdWithName(dataOfChange,
          Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
          this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData,
          [ChangeRateIds.changeInValue, ChangeRateIds.changeInPercentage]
        )

        let idx2 = 0;
        for (const item of rightData) {
            item[Ids.index] = idx2;
            item[Ids.value] = this.selectedChangeRateItem.Id === ChangeRateIds.changeInValue ? item[ChangeRateIds.changeInValue] : item[ChangeRateIds.changeInPercentage]
            item[Ids.Label] = this.selectedChangeRateItem.Id === ChangeRateIds.changeInValue ? (item[ChangeRateIds.changeInValue] ? this.utilityService.myFormatNumber(item[ChangeRateIds.changeInValue], 1) : '0') : (item[ChangeRateIds.changeInPercentage] ? this.utilityService.myFormatNumber(item[ChangeRateIds.changeInPercentage], 1) : '0');
            item[Ids.Color] = item[Ids.value] >= 0 ? this.configService.COLOR_GREEN.replace("#", "") : this.configService.COLOR_RED.replace("#", "")
            idx2++;
        }
      }

      // console.log("market-share-development | multippt | rightData: ", rightData)

      let header = this.getPPTHeaderTemplate()
      let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
      let dataseriesFields: any[] = []
      let options: any = {}
      options[PPTIds.master] = this.userDefinedPptMaster
      options[PPTIds.MaxValueIsHundred] = this.factsMaxValueIsHundred.includes(this.selectedChartFactItem.Id) ? true : false,
      options[PPTIds.LeftValAxisTitle] = this.selectedChartFactItem.Name,
      options[PPTIds.RightValAxisTitle] = this.selectedChangeRateItem.Name

      this.exportService.exportPPT(PPTIds.TwoBarCharts, this.dashboardTitle, accounts.map(i=>i.Name), products.map(i=>i.Name), header, options, singleTreeName, catAxisField, dataseriesFields, leftData, rightData)
                        .then(fileName => {
                            console.log(`Created Multi PPT file: ${fileName}`);
                            this.isCreatingExport = false
                        });
    })
  }

  handleExportExcel() {
    let columnValueMap:any = {}
    columnValueMap[this.cs.ACCOUNT] = Columns.Account
    columnValueMap[this.selectedChartFactItem.Name] = Columns.Value

    let columnValueMap2:any = {}
    columnValueMap2[this.selectedChangeRateItem.Name] = this.selectedChangeRateItem.Id

    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.CURRENT_PERIOD] = this.selectedPeriod1ItemNames.join(", ")
    header[this.cs.PERVIOUS_PERIOD] = this.selectedPeriod2ItemNames.join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

   console.log(this.hidePenetrationWarning)

    this.exportService.exportExcel("MarketShare_Development", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.chartData, header, columnValueMap, this.changeChartData, columnValueMap2)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  private setBarChartCalloutLayer() {
    this.barChartCalloutLayer = new IgxCalloutLayerComponent();
    this.barChartCalloutLayer.xMemberPath = Ids.value;
    this.barChartCalloutLayer.yMemberPath = Ids.index;
    this.barChartCalloutLayer.labelMemberPath = Ids.Label;
    this.barChartCalloutLayer.contentMemberPath = Ids.Label;
    this.barChartCalloutLayer.calloutTextColor = this.configService.CALLOUT_TEXT_COLOR;
    this.barChartCalloutLayer.calloutBackground = this.configService.CALLOUT_BACKGROUND;
    this.barChartCalloutLayer.calloutLeaderBrush = this.configService.CALLOUT_LEDER_BRUSH;

    this.barChart.series.add(this.barChartCalloutLayer);
  }

  private setBarChangeChartCalloutLayer() {
    if(this.showChangeChart) {
      this.barChangeChartCalloutLayer = new IgxCalloutLayerComponent();
      this.barChangeChartCalloutLayer.xMemberPath = Ids.value;
      this.barChangeChartCalloutLayer.yMemberPath = Ids.index;
      this.barChangeChartCalloutLayer.labelMemberPath = Ids.Label;
      this.barChangeChartCalloutLayer.contentMemberPath = Ids.Label;
      this.barChangeChartCalloutLayer.calloutTextColor = this.configService.CALLOUT_TEXT_COLOR;
      this.barChangeChartCalloutLayer.calloutBackground = this.configService.CALLOUT_BACKGROUND;
      this.barChangeChartCalloutLayer.calloutLeaderBrush = this.configService.CALLOUT_LEDER_BRUSH;

      this.barChangeChart.series.add(this.barChangeChartCalloutLayer);
    }
  }

  // override the function to display data label by selecting the checkbox
  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show
    if(this.showDataLabel) {
      this.setBarChartCalloutLayer()
      this.setBarChangeChartCalloutLayer()
    } else {
      this.barChart.series.clear()
      this.barChangeChart.series.clear()
    }
    this.barseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning);
  }

  public assignAccountColors(e: any){
    let args = e.args;
    let item = args.getItems(args.startIndex, args.endIndex)[0];

    let accountId = item[Ids.BGNum_Id]

    let colorCode = this.accountColorMapping[accountId]
    args.fill = colorCode
    args.stroke = colorCode
  }

  public assigningCategoryStyle(e: any){
    let args = e.args;
    let item = args.getItems(args.startIndex, args.endIndex)[0];

    if(item[this.selectedChangeRateItem.Id] < 0){
      args.fill = this.configService.COLOR_RED;
      args.stroke = this.configService.COLOR_RED;
    }
    else if(item[this.selectedChangeRateItem.Id] == 0){
      args.fill = this.configService.COLOR_GREY;
      args.stroke = this.configService.COLOR_GREY;
    }
    else{
      args.fill = this.configService.COLOR_GREEN;
      args.stroke = this.configService.COLOR_GREEN;
    }
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.Fact] = this.selectedChartFactItem
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.ChangeRate] = this.selectedChangeRateItem
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Fact
    this.factSelectData = this.filterChartFactData.filter(i => i["Id"] !== this.penetrationFactId)  // exclude penetration fact

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];
    this.getAccountColorMapping()

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
    this.getProductColorMapping()

    // actual
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // previous
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)

    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.showChangeChart = false
      this.period2WasNoSelected = true
    } else {
      this.showChangeChart = true
      this.period2WasNoSelected = false
    }

    // market share (Fact)
    this.selectedChartFactItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, {
      Id: this.filterChartFactData.length === 0 ? '' : this.filterChartFactData[0].Id,
      Name: this.filterChartFactData.length === 0 ? '' : this.filterChartFactData[0].Name
    })
    // have to update it according to the User Language
    this.selectedChartFactItem = {...this.selectedChartFactItem, Name: this.cs.FACT_OBJECT[this.selectedChartFactItem.Id]}
    // console.log("this.selectedChartFactItem: ", this.selectedChartFactItem)

    // Shopper Group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // Change Rate
    this.selectedChangeRateItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ChangeRate, this.selectChangeRateData[0])
    // have to update it according to the User Language
    this.selectedChangeRateItem = {...this.selectedChangeRateItem, Name: this.cs.CHANGE_RATE_OBJECT[this.selectedChangeRateItem.Id]}
    // console.log("this.selectedChangeRateItem: ", this.selectedChangeRateItem)

    // Chart Setting
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)
  }
}

