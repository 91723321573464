import { Action, createReducer, on } from '@ngrx/store';

import { AuthActions } from './auth.actions';
import { User } from './auth.models';


export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  user: User | null,

  isLoadingLogin: boolean,
  hasLoginError: boolean,
  isLoggedIn: boolean,

  isForgettingPassword: boolean,
  hasForgetPasswordError: boolean,
  isForgetPasswordEmailSent: boolean,

  isLoadingMagicLogin: boolean,
  hasMagicLoginError: boolean,
  isMagicLoggedIn: boolean,

  isSettingPassword: boolean,
  hasSetPasswordError: boolean,
  isPasswordSet: boolean,

  savePersonalDataStatus: 'NOT_SAVED' | 'SAVING' | 'SAVED',
  saveBasicColorStatus: 'NOT_SAVED' | 'SAVING' | 'SAVED',
  saveChartMasterStatus: 'NOT_SAVED' | 'SAVING' | 'SAVED',
  saveAccountProductColorStatus: 'NOT_SAVED' | 'SAVING' | 'SAVED',
  savePowerpointMasterStatus: 'NOT_SAVED' | 'SAVING' | 'SAVED',
  powerpointMasterLoadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED',
  powerpointMaster: string,
}

export interface AuthAppState {
  [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  user: null,

  isLoadingLogin: false,
  hasLoginError: false,
  isLoggedIn: false,

  isForgettingPassword: false,
  hasForgetPasswordError: false,
  isForgetPasswordEmailSent: false,

  isLoadingMagicLogin: false,
  hasMagicLoginError: false,
  isMagicLoggedIn: false,

  isSettingPassword: false,
  hasSetPasswordError: false,
  isPasswordSet: false,

  savePersonalDataStatus: 'NOT_SAVED',
  saveBasicColorStatus: 'NOT_SAVED',
  saveChartMasterStatus: 'NOT_SAVED',
  saveAccountProductColorStatus: 'NOT_SAVED',
  savePowerpointMasterStatus: 'NOT_SAVED',
  powerpointMasterLoadStatus: 'NOT_LOADED',
  powerpointMaster: '',
};

const AuthReducer = createReducer(
  initialState,

  // Login
  on(
    AuthActions.loginRequest,
    (state): AuthState => ({
      ...state,
      isLoadingLogin: true,
      hasLoginError: false,
    })
  ),
  on(
    AuthActions.loginSuccess,
    (state, action): AuthState => ({
      ...state,
      user: action.user,
      isLoggedIn: true,
      isLoadingLogin: false,
    })
  ),
  on(
    AuthActions.loginFailure,
    (state, action): AuthState => ({
      ...state,
      isLoadingLogin: false,
      hasLoginError: true,
    })
  ),

  // Logout
  on(
    AuthActions.logout,
    (): AuthState => ({
      ...initialState,
    })
  ),


  // Auth user for reloading page
  on(
    AuthActions.getAuthUserSuccess,
    (state, action): AuthState => ({
      ...state,
      user: action.user,
      isLoggedIn: true,
    })
  ),
  on(
    AuthActions.getAuthUserFailure,
    (): AuthState => ({
      ...initialState,
    })
  ),


  // Forget Password
  on(
    AuthActions.forgetPasswordRequest,
    (state): AuthState => ({
      ...state,
      isForgettingPassword: true,
      hasForgetPasswordError: false,
      isForgetPasswordEmailSent: false,
    })
  ),
  on(
    AuthActions.forgetPasswordSuccess,
    (state): AuthState => ({
      ...state,
      isForgettingPassword: false,
      hasForgetPasswordError: false,
      isForgetPasswordEmailSent: true,
    })
  ),
  on(
    AuthActions.forgetPasswordFailure,
    (state): AuthState => ({
      ...state,
      isForgettingPassword: false,
      hasForgetPasswordError: true,
      isForgetPasswordEmailSent: false,
    })
  ),


   // Magic Login
   on(
    AuthActions.magicLoginRequest,
    (state): AuthState => ({
      ...state,
      isLoadingMagicLogin: true,
      hasMagicLoginError: false,
      isMagicLoggedIn: false,
    })
  ),
  on(
    AuthActions.magicLoginSuccess,
    (state): AuthState => ({
      ...state,
      isLoadingMagicLogin: false,
      hasMagicLoginError: false,
      isMagicLoggedIn: true,
    })
  ),
  on(
    AuthActions.magicLoginFailure,
    (state): AuthState => ({
      ...state,
      isLoadingMagicLogin: false,
      hasMagicLoginError: true,
      isMagicLoggedIn: false,
    })
  ),



  // Set Password
  on(
    AuthActions.resetPasswordRequest,
    (state): AuthState => ({
      ...state,
      isSettingPassword: true,
      hasSetPasswordError: false,
    })
  ),
  on(
    AuthActions.resetPasswordSuccess,
    (state): AuthState => ({
      ...state,
      isSettingPassword: false,
      isPasswordSet: true,
    })
  ),
  on(
    AuthActions.resetPasswordFailure,
    (state): AuthState => ({
      ...state,
      isSettingPassword: false,
      hasSetPasswordError: true,
    })
  ),

  // personal data
  on(
    AuthActions.updatePersonalDataRequest,
    (state, action): AuthState => ({
      ...state,
      savePersonalDataStatus: 'SAVING'
    })
  ),

  on(
    AuthActions.updatePersonalDataSuccess,
    (state, action): AuthState => ({
      ...state,
      user: action.user,
      savePersonalDataStatus: 'SAVED'
    })
  ),

  // basic color
  on(
    AuthActions.updateBasicColorsRequest,
    (state, action): AuthState => ({
      ...state,
      saveBasicColorStatus: 'SAVING'
    })
  ),
  on(
    AuthActions.updateBasicColorsSuccess,
    (state, action): AuthState => ({
      ...state,
      user: action.user,
      saveBasicColorStatus: 'SAVED'
    })
  ),

  // chart master
  on(
    AuthActions.updateChartMasterRequest,
    (state, action): AuthState => ({
      ...state,
      saveChartMasterStatus: 'SAVING'
    })
  ),
  on(
    AuthActions.updateChartMasterSuccess,
    (state, action): AuthState => ({
      ...state,
      saveChartMasterStatus: 'SAVED'
    })
  ),

  // account product color
  on(
    AuthActions.updateAccountProductColorRequest,
    (state, action): AuthState => ({
      ...state,
      saveAccountProductColorStatus: 'SAVING'
    })
  ),
  on(
    AuthActions.updateAccountProductColorSuccess,
    (state, action): AuthState => ({
      ...state,
      saveAccountProductColorStatus: 'SAVED'
    })
  ),

  // save powerpoint master
  on(
    AuthActions.updatePowerpointMasterRequest,
    (state, action): AuthState => ({
      ...state,
      savePowerpointMasterStatus: 'SAVING'
    })
  ),
  on(
    AuthActions.updatePowerpointMasterSuccess,
    (state, { powerpointMaster }): AuthState => ({
      ...state,
      powerpointMaster,
      savePowerpointMasterStatus: 'SAVED'
    })
  ),

  // load powerpoint master
  on(AuthActions.powerpointMasterReset, (state) => ({
    ...state,
    powerpointMaster: '',
    powerpointMasterLoadStatus: 'NOT_LOADED'
  })),
  on(AuthActions.powerpointMasterLoad, (state) => ({
    ...state,
    powerpointMasterLoadStatus: 'LOADING'
  })),
  on(AuthActions.powerpointMasterLoaded, (state, { powerpointMaster }) => ({
    ...state,
    powerpointMaster,
    powerpointMasterLoadStatus: 'LOADED'
  })),

);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return AuthReducer(state, action);
}
