<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
      [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

      [tabAccountTitle]="tabAccountTitle"
      [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

      [tabProductTitle]="tabProductTitle"
      [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

      [tabPeriodFactTitle]="tabPeriodFactTitle"
      [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"

      [tabSelectionTitle]="tabSelectionTitle"
      [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
      [showFilterBenchmark]="true" [filterBenchmarkTitle]="filterBenchmarkTitle" [filterBenchmarkData]="filterBenchmarkData" [selectedBenchmarkItem]="selectedBenchmarkItem" [getSelectedBenchmarkItem]="getSelectedBenchmarkItem"
      [showStaticFilter]="true" [selectStaticFilterTitle]="selectMarketShareExpenditureTitle" [selectStaticFilterData]="selectMarketShareExpenditureData" [selectedStaticFilterItem]="selectedMarketShareExpenditureItem" [getSelectedStaticFilterItem]="getSelectedMarketShareExpenditure"
      [showFilterMode]="true" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
      [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
      [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"
      [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

      [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBenchmarkTitle]="benchmarkTitle"              [selectedBenchmark]="selectedBenchmarkItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti" [showInfoBenchmark]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item.Category}}</div>
        <div><font [color]="chartColors[0]">{{selectedChartFactItem.Name}}:</font> {{item.Label1}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item.Category}}</div>
        <div><font [color]="chartColors[1]">{{benchmarkTitle}}:</font> {{item.Label2}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip3>
        <div>{{item.Category}}</div>
        <div><font [color]="'black'">{{selectedMarketShareExpenditureItem.Name}}:</font> {{item.Label}}</div>
      </ng-template>

      <div class="flex flex-row">
        <div class="flex flex-row w-1/2">
          <div class="flex flex-col w-full">
            <igx-data-chart #chart height="550px" width="100%" [legend]="legend"
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false">

                <igx-category-x-axis #xAxis [dataSource]="chartData" [label]="modeCategory"></igx-category-x-axis>
                <igx-numeric-y-axis #yAxis abbreviateLargeNumbers="true" [title]="selectedChartFactItem.Name" [majorStroke]="configService.STROKE_COLOR_VALUE"
                              titleLeftMargin="5" titleRightMargin="0" minimumValue="0" maximumValue="110" interval="20">
                </igx-numeric-y-axis>

                <igx-column-series #factSeries [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
                  [valueMemberPath]="selectedChartFactItem.Name"
                  showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                  isTransitionedInEnabled="true" isHighlightingEnabled="true"
                  [title]="selectedChartFactItem.Name" [brush]="chartColors[0]" outline="Transparent" [markerBrush]="chartColors[0]">
                </igx-column-series>

                <ng-container *ngIf="this.selectedBenchmarkItem.Id !== NoBenchmarkSelectedId">
                  <igx-column-series #benchmarkSeries [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
                    [valueMemberPath]="benchmarkTitle"
                    showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                    isTransitionedInEnabled="true" isHighlightingEnabled="true"
                    [title]="benchmarkTitle" [brush]="chartColors[1]" outline="Transparent" [markerBrush]="chartColors[1]">
                  </igx-column-series>
                </ng-container>

                <ng-container *ngIf="showDataLabel">
                  <igx-callout-layer
                  [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="factSeries"
                  [labelMemberPath]="ids.Label1" [xMemberPath]="ids.index" [yMemberPath]="ids.value1"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>

                  <igx-callout-layer
                  [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false"  [targetSeries]="benchmarkSeries"
                  [labelMemberPath]="ids.Label2" [xMemberPath]="ids.index" [yMemberPath]="ids.value2"
                  calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                  </igx-callout-layer>
                </ng-container>
            </igx-data-chart>

            <div class="legend" [hidden]="!showLegend">
              <igx-legend #legend orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
            </div>
          </div>
        </div>

        <div class="flex flex-row w-1/2">
          <igx-data-chart #barChangeChart isHorizontalZoomEnabled="false" isVerticalZoomEnabled="false" width="100%"
              height="550px" [dataSource]="chartData">
              <igx-numeric-x-axis [title]="selectedMarketShareExpenditureItem.Name" #xAxis2
              majorStrokeThickness="0" referenceValue="0" abbreviateLargeNumbers="true"
              [minimumValue]="minChangeValue"
              [maximumValue]="maxChangeValue"
              [interval]="interval"
              ></igx-numeric-x-axis>
              <igx-category-y-axis [label]="modeCategory" [dataSource]="chartData" #yAxis2 isInverted="true" gap="0.5" overlap="-0.1"></igx-category-y-axis>

              <igx-bar-series #barChangeSeries [dataSource]="chartData" [valueMemberPath]="selectedMarketShareExpenditureItem.Id" [xAxis]="xAxis2" [yAxis]="yAxis2" [title]="selectedPeriod1Item.Name"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip3"
                isTransitionedInEnabled="true" isHighlightingEnabled="true"
                isCustomCategoryStyleAllowed="true" (assigningCategoryStyle)="assigningCategoryStyle($event)"
                >
              </igx-bar-series>

              <ng-container *ngIf="showDataLabel">
                <igx-callout-layer
                [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="barChangeSeries"
                [labelMemberPath]="ids.Label" [yMemberPath]="ids.index" [xMemberPath]="ids.value"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
              </ng-container>
          </igx-data-chart>
        </div>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
