<section class="container_content">
  <div class="leftmenu">
      <app-filter-tabs-rest-requirement
        [getSelectedPeriodItem]="getSelectedPeriodItem"

        [tabFocusTitle]="tabFocusTitle"
        [filterAccountTitleFocus]="filterAccountTitleFocus" [filterAccountDataFocus]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [getSelectedNodesFocus]="getSelectedNodesFocus" [selectedAccountNodesFocus]="selectedAccountNodesFocus"

        [tabCompetitorTitle]="tabCompetitorTitle"
        [filterAccountTitleCompetitor]="filterAccountTitleCompetitor" [filterAccountDataCompetitor]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevelsCompetitor" [getSelectedNodesCompetitor]="getSelectedNodesCompetitor" [selectedAccountNodesCompetitor]="selectedAccountNodesCompetitor"


        [tabProductTitle]="tabProductTitle"
        [filterProductTitle]="filterProductTitle" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [getSelectedNodesProduct]="getSelectedNodesProduct" [selectedProductNodes]="selectedProductNodes"

        [tabSelectionTitle]="tabSelectionTitle"
        [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
        [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"
        [selectSingleFactTitle]="filterChartFactTitle" [selectSingleFactData]="selectFactRestRequirementData" [selectedSingleFactItem]="selectedFactRestRequirementItem" [getSelectedSingleFact]="getSelectedFactRestRequirement"
        [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="false" (getSelectedExportEvent)="getSelectedExportEvent($event)"
        [showRestRequirementIndex]="true" [showIndexChart]="showRestRequirementIndexChart" (showRestRequirementIndexEvent)="displayRestRequirementIndex($event)"
        [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
        [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"

        [isDataLoading$]="isDataLoading$"
      ></app-filter-tabs-rest-requirement>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitleFocus"       [selectedAccountNodes]="selectedAccountNodesFocus"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBasisTitle]="factTitle"                       [selectedBasis]="selectedFactRestRequirementItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="true" [showInfoBasis]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item[ids.Account_Competitor]}}</div>
        <div><font [color]="chartColors[0]">{{item[ids.Series1]}}:</font> {{item[ids.Label1]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item[ids.Account_Competitor]}}</div>
        <div><font [color]="chartColors[1]">{{item[ids.Series2]}}:</font> {{item[ids.Label2]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip3>
        <div>{{item[ids.Account_Competitor]}}</div>
        <div><font [color]="chartColors[2]">{{item[ids.Series1]}}:</font> {{item[ids.Label1]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip4>
        <div>{{item[ids.Account_Competitor]}}</div>
        <div><font [color]="chartColors[3]">{{item[ids.Series2]}}:</font> {{item[ids.Label2]}}</div>
      </ng-template>

      <div class="distribution_topX_pie">
        <div class="distribution-topx-heading-period-1">{{selectedPeriod1Item.Name}}</div>
        <div class="chart-subtitle">
          <div class="chart-subtitle-stats-title">{{VerloreneAusgaben}}</div>
          <div class="chart-subtitle-stats-value">{{Value_P1_A_string}} <span class="chart-subtitle-stats-value-total">({{VerloreneAusgabenTotal}} {{Value_P1_B_string}})</span></div>
        </div>

        <igx-pie-chart
        [dataSource]="CHART_DISTRIBUTION_PIE_MAT1"
        valueMemberPath="Value"
        labelMemberPath="Summary"
        legendLabelMemberPath="Summary"
        labelsPosition="BestFit"
        radiusFactor="0.7"
        labelExtent="30"
        width="350px"
        height="225px"
        outlines="white"
        name="CHART_DISTRIBUTION_PIE_MAT1_chart"
        [legend]="legendMAT1"
        legendLabelMemberPath="Type"
        othersCategoryText="Others"
        othersCategoryType="Percent"
        othersCategoryThreshold="0"
        [brushes] = "chartColors[0] + ', #BFBFBF'"
        #CHART_DISTRIBUTION_PIE_MAT1_chart>
        </igx-pie-chart>

        <div class="legend" [hidden]="!showLegend">
          <igx-item-legend #legendMAT1 orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-item-legend>
        </div>

        <div *ngIf="period2IsSelected">
          <div class="distribution-topx-heading-period-2">{{selectedPeriod2Item.Name}}</div>
          <div class="chart-subtitle">
            <div class="chart-subtitle-stats-title">{{VerloreneAusgaben}}</div>
            <div class="chart-subtitle-stats-value">{{Value_P2_A_string}} <span class="chart-subtitle-stats-value-total">({{VerloreneAusgabenTotal}} {{Value_P2_B_string}})</span></div>
          </div>

          <igx-pie-chart
          [dataSource]="CHART_DISTRIBUTION_PIE_MAT2"
          valueMemberPath="Value"
          labelMemberPath="Summary"
          legendLabelMemberPath="Summary"
          labelsPosition="BestFit"
          radiusFactor="0.7"
          labelExtent="30"
          width="350px"
          height="225px"
          outlines="white"
          name="CHART_DISTRIBUTION_PIE_MAT2_chart"
          [legend]="legendMAT2"
          legendLabelMemberPath="Type"
          othersCategoryText="Others"
          othersCategoryType="Percent"
          othersCategoryThreshold="0"
          [brushes] = "chartColors[1] + ', #BFBFBF'"
          #CHART_DISTRIBUTION_PIE_MAT2_chart>
          </igx-pie-chart>
        </div>

        <div class="legend" [hidden]="!showLegend">
          <igx-item-legend #legendMAT2 orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-item-legend>
        </div>
      </div>

      <div class="distribution_topx_bar1">
        <div class="distribution-topx-heading chart-title">{{CHART_HEADING_1}}</div>
        <igx-data-chart
        [legend]="CHART_DISTRIBUTION_BAR_1_legend"
        name="CHART_DISTRIBUTION_BAR_1_Chart"
        width="500px"
        height="643px"
        title=""
        isHorizontalZoomEnabled="false"
        isVerticalZoomEnabled="false"
        #CHART_DISTRIBUTION_BAR_1_chart>
            <igx-category-y-axis
              [dataSource]="CHART_DISTRIBUTION_BAR_1"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              [label]="ids.Account_Competitor"
              name="CHART_DISTRIBUTION_BAR_1_yAxis"
              #CHART_DISTRIBUTION_BAR_1_yAxis>
            </igx-category-y-axis>

            <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="maxXValueBar1"
              labelVisibility="collapsed"
              majorStrokeThickness="0"
              name="CHART_DISTRIBUTION_BAR_1_xAxis"
              #CHART_DISTRIBUTION_BAR_1_xAxis>
            </igx-numeric-x-axis>

            <igx-category-highlight-layer
              name="CategoryHighlightLayer"
              #CHART_DISTRIBUTION_BAR_1_categoryHighlightLayer>
            </igx-category-highlight-layer>

            <igx-bar-series
              [xAxis]="CHART_DISTRIBUTION_BAR_1_xAxis"
              [yAxis]="CHART_DISTRIBUTION_BAR_1_yAxis"
              [title]="selectedPeriod1Item.Name"
              [valueMemberPath]="selectedPeriod1Item.Name"
              [dataSource]="CHART_DISTRIBUTION_BAR_1"
              [brush]="chartColors[0]"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
              name="CHART_DISTRIBUTION_BAR_1_BarSeries1"
              #CHART_DISTRIBUTION_BAR_1_BarSeries1>
            </igx-bar-series>

            <igx-bar-series
              *ngIf="period2IsSelected"
              [xAxis]="CHART_DISTRIBUTION_BAR_1_xAxis"
              [yAxis]="CHART_DISTRIBUTION_BAR_1_yAxis"
              [title]="selectedPeriod2Item.Name"
              [valueMemberPath]="selectedPeriod2Item.Name"
              [dataSource]="CHART_DISTRIBUTION_BAR_1"
              [brush]="chartColors[1]"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
              isTransitionInEnabled="true"
              isHighlightingEnabled="true"
              name="CHART_DISTRIBUTION_BAR_1_BarSeries2"
              #CHART_DISTRIBUTION_BAR_1_BarSeries2>
            </igx-bar-series>

            <ng-container *ngIf="showDataLabel">
              <igx-callout-layer
              [dataSource]="CHART_DISTRIBUTION_BAR_1" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_DISTRIBUTION_BAR_1_BarSeries1"
              [labelMemberPath]="ids.Label1" [yMemberPath]="ids.index" [xMemberPath]="ids.value1"
              calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
              </igx-callout-layer>

              <igx-callout-layer
              [dataSource]="CHART_DISTRIBUTION_BAR_1" isAutoCalloutBehaviorEnabled="false"  [targetSeries]="CHART_DISTRIBUTION_BAR_1_BarSeries2"
              [labelMemberPath]="ids.Label2" [yMemberPath]="ids.index" [xMemberPath]="ids.value2"
              calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
              </igx-callout-layer>
            </ng-container>
        </igx-data-chart>

        <div class="CHART_DISTRIBUTION_BAR_1_legend" [hidden]="!showLegend">
          <igx-legend
          orientation="Horizontal"
          name="Legend"
          #CHART_DISTRIBUTION_BAR_1_legend>
          </igx-legend>
        </div>
      </div>

      <div class="distribution_topx_bar2" *ngIf="showRestRequirementIndexChart">
        <div class="distribution-topx-heading">{{CHART_HEADING_2}}</div>
          <igx-data-chart
            [legend]="CHART_DISTRIBUTION_BAR_2_legend"
            name="CHART_DISTRIBUTION_BAR_2_Chart"
            width="250px"
            height="643px"
            title=""
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false"
            #CHART_DISTRIBUTION_BAR_2_Chart>

            <igx-category-y-axis
              [dataSource]="CHART_DISTRIBUTION_BAR_2"
              gap="0.5"
              overlap="-0.1"
              isInverted="true"
              useEnhancedIntervalManagement="false"
              enhancedIntervalPreferMoreCategoryLabels="false"
              [label]="ids.Account_Competitor"
              labelVisibility="Collapsed"
              name="CHART_DISTRIBUTION_BAR_2_yAxis"
              #CHART_DISTRIBUTION_BAR_2_yAxis>
            </igx-category-y-axis>

            <igx-numeric-x-axis
              minimumValue="0"
              [maximumValue]="maxXValueBar2"
              labelVisibility="collapsed"
              majorStrokeThickness="0"
              name="CHART_DISTRIBUTION_BAR_2_xAxis"
              #CHART_DISTRIBUTION_BAR_2_xAxis>
            </igx-numeric-x-axis>

            <igx-value-overlay
              [axis]="CHART_DISTRIBUTION_BAR_2_xAxis"
              title="Benchmark"
              brush="#BFBFBF"
              legendItemVisibility="Collapsed"
              value=100.0
              thickness=1>
            </igx-value-overlay>

            <igx-category-highlight-layer
              name="CHART_DISTRIBUTION_BAR_2_CategoryHighlightLayer"
              #categoryHighlightLayer>
            </igx-category-highlight-layer>

            <igx-bar-series
              [xAxis]="CHART_DISTRIBUTION_BAR_2_xAxis"
              [yAxis]="CHART_DISTRIBUTION_BAR_2_yAxis"
              [valueMemberPath]="selectedPeriod1Item.Name"
              [brush]="chartColors[2]"
              yAxisMinimumValue="0"
              isTransitionInEnabled="true"
              [dataSource]="CHART_DISTRIBUTION_BAR_2"
              isHighlightingEnabled="true"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip3"
              [title]="selectedPeriod1Item.Name"
              name="CHART_DISTRIBUTION_BAR_2_BarSeries1"
              #CHART_DISTRIBUTION_BAR_2_BarSeries1>
            </igx-bar-series>

            <igx-bar-series
              *ngIf="period2IsSelected"
              [xAxis]="CHART_DISTRIBUTION_BAR_2_xAxis"
              [yAxis]="CHART_DISTRIBUTION_BAR_2_yAxis"
              [title]="selectedPeriod2Item.Name"
              [valueMemberPath]="selectedPeriod2Item.Name"
              [dataSource]="CHART_DISTRIBUTION_BAR_2"
              [brush]="chartColors[3]"
              showDefaultTooltip="false" [tooltipTemplate]="valueTooltip4"
              isTransitionInEnabled="true"
              isHighlightingEnabled="true"
              name="CHART_DISTRIBUTION_BAR_2_BarSeries2"
              #CHART_DISTRIBUTION_BAR_2_BarSeries2>
            </igx-bar-series>

            <ng-container *ngIf="showDataLabel">
              <igx-callout-layer
              [dataSource]="CHART_DISTRIBUTION_BAR_2" isAutoCalloutBehaviorEnabled="false" [targetSeries]="CHART_DISTRIBUTION_BAR_2_BarSeries1"
              [labelMemberPath]="ids.Label1" [yMemberPath]="ids.index" [xMemberPath]="ids.value1"
              calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
              </igx-callout-layer>

              <igx-callout-layer
              [dataSource]="CHART_DISTRIBUTION_BAR_2" isAutoCalloutBehaviorEnabled="false"  [targetSeries]="CHART_DISTRIBUTION_BAR_2_BarSeries2"
              [labelMemberPath]="ids.Label2" [yMemberPath]="ids.index" [xMemberPath]="ids.value2"
              calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
              </igx-callout-layer>
            </ng-container>

          </igx-data-chart>

        <div class="CHART_DISTRIBUTION_BAR_2_legend" [hidden]="!showLegend">
          <igx-legend
          orientation="Horizontal"
          name="CHART_DISTRIBUTION_BAR_2_legend_Legend"
          #CHART_DISTRIBUTION_BAR_2_legend>
          </igx-legend>
        </div>
      </div>

    </div>

  </div>

  <app-export-multi-ppt-dialog
    [multiSelectedNodeLevels]="multiSelectedNodeLevels"
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
