import { Component, Input, ViewChild } from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { TreeWithCheckboxWithTenLevelsComponent } from '../tree/tree-with-checkbox-with-ten-levels/tree-with-checkbox-with-ten-levels.component';
import { ConstantService } from '../../../services/constant.service';

@Component({
  selector: 'app-export-multi-ppt-dialog',
  templateUrl: './export-multi-ppt-dialog.component.html',
  styleUrls: ['./export-multi-ppt-dialog.component.scss']
})
export class ExportMultiPptDialogComponent {
  @Input() multiSelectedNodeLevels!: number[];

  @Input() public treeAccountIsMulti!: boolean;

  @Input() public filterAccountData!: any[];
  @Input() public treeMultiAccountName!: string;

  @Input() public filterProductData!: any[];
  @Input() public treeMultiProductName!: string;

  @Input() getMultiSelectedNodes!: ($event: any) => void

  @Input() exportMultiPPT!: () => void;

  @ViewChild('dialog')
  private exportMultiPPTComponent!: IgxDialogComponent;

  @ViewChild('multiAccountTreeWithCheckbox')
  private multiAccountTreeWithCheckbox!: TreeWithCheckboxWithTenLevelsComponent;

  @ViewChild('multiProductTreeWithCheckbox')
  private multiProductTreeWithCheckbox!: TreeWithCheckboxWithTenLevelsComponent;

  public exportMultiPPTDialogTitle: string = this.cs.EXPORT_MULTI_PPT
  public cancel: string = this.cs.CANCEL
  public export: string = this.cs.EXPORT

  constructor(public cs: ConstantService) { }

  public open() {
    this.exportMultiPPTComponent.open();
  }

  public close() {
    this.exportMultiPPTComponent.close();
  }

  public deselectAllMultiAccountTreeWithCheckbox() {
    this.multiAccountTreeWithCheckbox.tree.deselectAll()
  }

  public deselectAllMultiProductTreeWithCheckbox() {
    this.multiProductTreeWithCheckbox.tree.deselectAll()
  }


}
