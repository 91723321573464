<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"
    [showSingleFactFilter]="true" [selectSingleFactTitle]="selectBubbleSizeFactTitle" [selectSingleFactData]="filterBubbleSizeFactData" [selectedSingleFactItem]="selectedBubbleSizeFactItem" [getSelectedSingleFact]="getSelectedBubbleSizeFact"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [showFilterBenchmark]="true" [filterBenchmarkTitle]="filterBenchmarkTitle" [filterBenchmarkData]="filterBenchmarkData" [selectedBenchmarkItem]="selectedBenchmarkItem" [getSelectedBenchmarkItem]="getSelectedBenchmarkItem"
    [showNoBenchmarkSelectedError]="hasNoBenchmarkSelectedError"
    [showStaticFilter]="true" [selectStaticFilterTitle]="selectFactTypeTitle" [selectStaticFilterData]="selectFactTypeData" [selectedStaticFilterItem]="selectedFactTypeItem" [getSelectedStaticFilterItem]="getSelectedFactType"
    [showFilterMode]="true" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
    [showCheckboxLegend]="true" (showLegendEvent)="displayLegend($event)"
    [showCheckboxDataLabel]="false" (showDataLabelEvent)="displayDataLabel($event)"
    [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="false" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBenchmarkTitle] = "filterBenchmarkTitle"      [selectedBenchmark]="selectedBenchmarkItem"
      [chipFactTitle] = "factTitle"                      [selectedFact]="selectedFactTypeItem"
      [chipFilterBubbleSizeTitle] = "bubbleSizeTitle"    [selectedFilterBubbleSize]="selectedBubbleSizeFactItem"
      [showFilterPeriod2]="hasPeriod2Filter"  [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      [showInfoBenchmark]="true"  [showInfoFact]="true" [showFilterBubbleSize]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <div class="chart-navigation">
        <div class="chart-navigation-inner">
          <button igxButton="fab" class="button-chart-navigation" (click)="onPanUpClick()">
            <igx-icon fontSet="material">arrow_upward</igx-icon>
          </button>
          <button igxButton="fab" class="button-chart-navigation" (click)="onPanDownClick()">
            <igx-icon fontSet="material">arrow_downward</igx-icon>
          </button>
          <button igxButton="fab" class="button-chart-navigation" (click)="onPanLeftClick()">
            <igx-icon fontSet="material">arrow_back</igx-icon>
          </button>
          <button igxButton="fab" class="button-chart-navigation" (click)="onPanRightClick()">
            <igx-icon fontSet="material">arrow_forward</igx-icon>
          </button>
          <button igxButton="fab" class="button-chart-navigation" (click)="resetZoom()">
            <igx-icon fontSet="material">refresh</igx-icon>
          </button>
          <button igxButton="fab" class="button-chart-navigation" (click)="ZoomInClick()">
            <igx-icon fontSet="material">zoom_in</igx-icon>
          </button>
          <button igxButton="fab" class="button-chart-navigation" (click)="ZoomOutClick()">
            <igx-icon fontSet="material">zoom_out</igx-icon>
          </button>
        </div>
      </div>

      <ng-template let-series="series" let-item="item" #valueTooltip>
        <div>{{item.PerNum}}</div>
        <div>{{item[ids.Label]}}</div>
      </ng-template>

      <div id="bubblechart">
          <igx-data-chart #chart
            height="600px" width="900px" [legend]="legend" markerThickness="2" isHorizontalZoomEnabled="true" isVerticalZoomEnabled="true">

            <igx-numeric-x-axis #xAxis_Per1 majorStrokeThickness="0" [title]="axisXFactName" [minimumValue]="minXAxisValue" [maximumValue]="maxXAxisValue"></igx-numeric-x-axis>
            <igx-numeric-y-axis #yAxis_Per1 majorStrokeThickness="0" [title]="axisYFactName" [minimumValue]="minYAxisValue" [maximumValue]="maxYAxisValue"></igx-numeric-y-axis>

            <igx-numeric-x-axis #xAxis_Per2 majorStrokeThickness="0" [title]="axisXFactName" labelVisibility="collapsed" [minimumValue]="minXAxisValue" [maximumValue]="maxXAxisValue"></igx-numeric-x-axis>
            <igx-numeric-y-axis #yAxis_Per2 majorStrokeThickness="0" [title]="axisYFactName" labelVisibility="collapsed" [minimumValue]="minYAxisValue" [maximumValue]="maxYAxisValue"></igx-numeric-y-axis>

            <ng-container *ngIf="selectedBenchmarkItem.Id !== NoBenchmarkSelectedId">
              <igx-value-overlay
                [axis]="yAxis_Per1"
                [value] = "selectedBenchmarkItem.YAxisValues[0]"
                [brush]="configService.BENCHMARK_COLOR"
                thickness=1
                legendItemVisibility="collapsed"
                >
              </igx-value-overlay>

              <igx-value-overlay
                [axis]="xAxis_Per1"
                [value] = "selectedBenchmarkItem.XAxisValues[0]"
                [brush]="configService.BENCHMARK_COLOR"
                thickness=1
                legendItemVisibility="collapsed"
                >
              </igx-value-overlay>
            </ng-container>
          </igx-data-chart>

          <div class="legend" [hidden]="!showLegend">
            <igx-legend #legend orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
          </div>

          <div class="MAT-chart-analysis-strengths-weaknesses">
            <div class="Per1-Bubble">{{filterPeriod1Title}}</div>
            <div class="Per2-Bubble">{{filterPeriod2Title}}</div>
          </div>
      </div>
    </div>
  </div>

  <app-export-multi-ppt-dialog
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
