<igx-tabs tabAlignment="start" [disableAnimation]="true" >
  <igx-tab-item [selected]="true" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>store_mall_directory</igx-icon>
      <span igxTabHeaderLabel >{{tabAccountTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div [ngSwitch]="selectedTableType">
        <div *ngSwitchCase="tableType.Table">
            <ng-container *ngIf="treeAccountIsMulti; else oneAccount">
              <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodes"></app-tree-with-checkbox-with-ten-levels>
            </ng-container>
            <ng-template #oneAccount>
              <app-tree-without-checkbox-with-ten-levels #accountTreeWithoutCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getActivatedNodeEvent)="getSelectedNodes($event)" [selectedNodes]="selectedAccountNodes"></app-tree-without-checkbox-with-ten-levels>
            </ng-template>
        </div>

        <div *ngSwitchCase="tableType.Pivot1">
          <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodes"></app-tree-with-checkbox-with-ten-levels>
        </div>

        <div *ngSwitchCase="tableType.Pivot2">
          <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodes"></app-tree-with-checkbox-with-ten-levels>
        </div>
      </div>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>shopping_cart</igx-icon>
      <span igxTabHeaderLabel >{{tabProductTitle}}</span>
    </igx-tab-header>
    <igx-tab-content >
      <div [ngSwitch]="selectedTableType">
        <div *ngSwitchCase="tableType.Table">
          <div *ngIf="treeProductIsMulti; else oneProduct">
            <app-tree-with-checkbox-with-ten-levels #productTreeWithCheckbox [data]="filterProductData" [name]="filterProductTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedProductNodeLevels" [selectedNodes]="selectedProductNodes"></app-tree-with-checkbox-with-ten-levels>
          </div>
          <ng-template #oneProduct>
            <app-tree-without-checkbox-with-ten-levels #productTreeWithoutCheckbox [data]="filterProductData" [name]="filterProductTitle" (getActivatedNodeEvent)="getSelectedNodes($event)" [selectedNodes]="selectedProductNodes"></app-tree-without-checkbox-with-ten-levels>
          </ng-template>
        </div>

        <div *ngSwitchCase="tableType.Pivot1">
          <app-tree-with-checkbox-with-ten-levels #productTreeWithCheckbox [data]="filterProductData" [name]="filterProductTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedProductNodeLevels" [selectedNodes]="selectedProductNodes"></app-tree-with-checkbox-with-ten-levels>
        </div>

        <div *ngSwitchCase="tableType.Pivot2">
          <app-tree-with-checkbox-with-ten-levels #productTreeWithCheckbox [data]="filterProductData" [name]="filterProductTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedProductNodeLevels" [selectedNodes]="selectedProductNodes"></app-tree-with-checkbox-with-ten-levels>
        </div>
      </div>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>euro_symbol</igx-icon>
      <span igxTabHeaderLabel >{{tabPeriodFactTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <app-multi-select-checkbox [name]="filterPeriod1Title" [items]="filterPeriod1Data" (getCheckedItemsEvent)="getSelectedPeriods($event)"></app-multi-select-checkbox>
        <app-tree-with-checkbox-cascading [data]="treeFactData" [name]="treeFactName" (getSelectedNodesEvent)="getSelectedFactNodes($event)" [topLevelItemsAreSelected]="false" [topLevelIsExpanded]="false"></app-tree-with-checkbox-cascading>
      </div>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>insert_chart_outlined</igx-icon>
      <span igxTabHeaderLabel>{{tabSelectionTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <app-single-select-radio [name]="filterShopperGroupTitle" [items]="filterShopperGroupData" [selected]="selectedShopperGroupItem.Id" (getSelectedItemEvent)="getSelectedShopperGroupItem($event)" ></app-single-select-radio>

        <div>
          <div class="tab-heading" >{{tableTypeTitle}}</div>
          <igx-radio-group alignment="vertical">
            <igx-radio [(ngModel)]="selectedTableType" [value]="tableType.Table" labelPosition="after"  (change)="selectRadioTable()">{{tableTitle}}</igx-radio>
            <igx-radio [(ngModel)]="selectedTableType" [value]="tableType.Pivot1" labelPosition="after" (change)="selectRadioPivot1()">{{pivot1Title}}</igx-radio>
            <igx-radio [(ngModel)]="selectedTableType" [value]="tableType.Pivot2" labelPosition="after" (change)="selectRadioPivot2()">{{pivot2Title}}</igx-radio>
          </igx-radio-group>
        </div>

        <app-single-select [name]="filterBenchmarkTitle" [items]="filterBenchmarkData" [selected]="selectedBenchmarkItem.Id" (getSelectedItemEvent)="getSelectedBenchmarkItem($event)" ></app-single-select>

        <div [ngSwitch]="selectedTableType">
          <div *ngSwitchCase="tableType.Table">
            <div>
              <app-single-select-radio [name]="filterModeTitle" [items]="filterModeData" [selected]="selectedModeItem.Id" (getSelectedItemEvent)="getSelectedFilterModeItem($event)" ></app-single-select-radio>

              <app-single-select-radio [name]="selectMetricsTitle" [items]="selectMetricsData" [selected]="selectedMetricsItem.Id" (getSelectedItemEvent)="getSelectedMetricsItem($event)" ></app-single-select-radio>

              <app-single-select-radio [name]="selectChangeTitle" [items]="selectChangeData" [selected]="selectedChangeItem.Id" (getSelectedItemEvent)="getSelectedChangeItem($event)" ></app-single-select-radio>
            </div>
          </div>

          <div *ngSwitchCase="tableType.Pivot1">
            <div class="tab-heading">{{pivotSettingTitle}}</div>
            <table class="border-separate border-spacing-2 border border-slate-400">
              <tr>
                <td rowspan="4" >
                  <igx-select class="select-table" [(ngModel)]="select_col1_selected" (ngModelChange)="checkUpdatePivotButton()">
                    <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                      {{item.Name}}
                    </igx-select-item>
                </igx-select>
                </td>
                <td class="border border-slate-300">
                  <igx-select class="select-table" [(ngModel)]="select_col2_selected" (ngModelChange)="checkUpdatePivotButton()">
                    <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                      {{item.Name}}
                    </igx-select-item>
                  </igx-select>
                </td>
              </tr>

              <tr>
                <td class="border border-slate-300">
                  <igx-select class="select-table" [(ngModel)]="select_col3_selected" (ngModelChange)="checkUpdatePivotButton()">
                    <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                      {{item.Name}}
                    </igx-select-item>
                  </igx-select>
                </td>
              </tr>

              <tr>
                <td class="border border-slate-300">
                  <igx-select class="select-table" [(ngModel)]="select_col4_selected" (ngModelChange)="checkUpdatePivotButton()">
                    <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                      {{item.Name}}
                    </igx-select-item>
                  </igx-select>
                </td>
              </tr>

              <tr>
                <td class="border border-slate-300">
                  <igx-select class="select-table" [(ngModel)]="select_col5_selected" (ngModelChange)="checkUpdatePivotButton()">
                    <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                      {{item.Name}}
                    </igx-select-item>
                  </igx-select>
                </td>
              </tr>
            </table>

            <button igxButton="raised" class="button-green" [disabled]="updatePivotButtonDisabled" (click)="updatePivot1()">{{updatePivotTitle}}</button>
          </div>

          <div *ngSwitchCase="tableType.Pivot2">
            <div class="font-bold">{{pivotSettingTitle}}</div>
            <table class="border-separate border-spacing-2 border border-slate-400">
              <tbody>
                <tr>
                  <td rowspan="3" class="border border-slate-300">
                    <igx-select class="select-table" [(ngModel)]="select_col1_selected" (ngModelChange)="checkUpdatePivotButton()">
                      <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                        {{item.Name}}
                      </igx-select-item>
                  </igx-select>
                  </td>
                  <td rowspan="3" class="border border-slate-300">
                    <igx-select class="select-table" [(ngModel)]="select_col2_selected" (ngModelChange)="checkUpdatePivotButton()">
                      <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                        {{item.Name}}
                      </igx-select-item>
                  </igx-select>
                  </td>
                  <td class="border border-slate-300">
                    <igx-select class="select-table" [(ngModel)]="select_col3_selected" (ngModelChange)="checkUpdatePivotButton()">
                      <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                        {{item.Name}}
                      </igx-select-item>
                  </igx-select>
                  </td>
                </tr>
                <tr>
                  <td class="border border-slate-300">
                    <igx-select class="select-table" [(ngModel)]="select_col4_selected" (ngModelChange)="checkUpdatePivotButton()">
                      <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                        {{item.Name}}
                      </igx-select-item>
                  </igx-select>
                  </td>
                </tr>
                <tr>
                  <td class="border border-slate-300">
                    <igx-select class="select-table" [(ngModel)]="select_col5_selected" (ngModelChange)="checkUpdatePivotButton()">
                      <igx-select-item *ngFor="let item of select_col_items" [value]="item.Id" [text]="item.Name">
                        {{item.Name}}
                      </igx-select-item>
                  </igx-select>
                  </td>
                </tr>
              </tbody>
            </table>

            <button igxButton="raised" class="button-green" [disabled]="updatePivotButtonDisabled" (click)="updatePivot2()">{{updatePivotTitle}}</button>
          </div>
        </div>

        <div>
          <div class="tab-heading">{{exportTitle}}</div>
          <igx-buttongroup>
            <button igxButton="outlined" *ngFor="let item of exportItems" (buttonClick)="export(item.Value)">{{item.Name}}</button>
          </igx-buttongroup>
        </div>
      </div>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>
