import {TreeNode} from 'primeng/api';
import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import pptxgen from 'pptxgenjs';
import domtoimage from 'dom-to-image';
declare var $:any;

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { UserSelectionIds, Columns, Ids, DashboardId } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-decomposition-tree',
  templateUrl: './decomposition-tree.component.html',
  styleUrls: ['./decomposition-tree.component.scss']
})
export class DecompositionTreeComponent extends BaseComponent {

  private el: ElementRef;

  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.MARKET_SHARE
  dashboardId = DashboardId.decomposition_tree
  dashboards = this.cs.MARKET_SHARE_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // call backend
  getHHDataFromBackend = true

  // Set initial look and behivor of trees, and there is no swith mode
  /** Tree Account */
  treeAccountIsMulti = false     // make the tree without checkbox
  callBackendToGetAccountAndAllProducts = false // get account from the store

  /** Tree Product */
  treeProductIsMulti = false     // make the tree without checkbox
  callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

  // If it has 2 period filters
  hasPeriod2Filter = true     // used in base component, have to keep it

  // Facts used in dashboard
  ret_expFactId = "F7"
  bedFactId = "F14"
  unitsFactId = "F22"
  priceFactId = "F23"
  ispFactId = "F2"
  frequenzFactId = "F5"
  allowedChartFactIds = [this.ret_expFactId, this.bedFactId, this.unitsFactId, this.priceFactId, this.ispFactId, this.frequenzFactId].concat([this.penetrationFactId])

  hh_per1: number = 0;
  hh_per2: number = 0;
  fact1_per1: number = 0;       // per1 is period actual. per2 is period previous
  fact1_per2: number = 0;
  fact1_diff_per: number = 0;   // difference in percent
  fact1_diff_abs: number = 0;   // absolute difference
  fact1_diff_change_per: number = 0

  fact2_per1: number = 0;
  fact2_per2: number = 0;
  fact2_diff_per: number = 0;
  fact2_impact: number = 0;

  fact3_per1: number = 0;
  fact3_per2: number = 0;
  fact3_diff_per: number = 0;
  fact3_impact: number = 0;

  fact4_per1: number = 0;
  fact4_per2: number = 0;
  fact4_diff_per: number = 0;
  fact4_diff_abs: number = 0;
  fact4_impact: number = 0;

  fact5_per1: number = 0;
  fact5_per2: number = 0;
  fact5_diff_per: number = 0;
  fact5_impact: number = 0;

  fact6_per1: number = 0;
  fact6_per2: number = 0;
  fact6_diff_per: number = 0;
  fact6_impact: number = 0;

  fact7_per1: number = 0;
  fact7_per2: number = 0;
  fact7_diff_per: number = 0;
  fact7_impact: number = 0;

  fact8_per1: number = 0;
  fact8_per2: number = 0;
  fact8_diff_per: number = 0;
  fact8_impact: number = 0;

  fact9_per1: number = 0;
  fact9_per2: number = 0;
  fact9_diff_per: number = 0;
  fact9_impact: number = 0;


  /** -------------------- Inputs for this dashboard -------------------- */
  filterPeriod1Title = this.cs.CURRENT_PERIOD
  filterPeriod2Title = this.cs.PERVIOUS_PERIOD
  public data: TreeNode[] = [];

  public showChart: boolean = true

  public ContributionValueChangeY: any[] = [];

  public VALUE_CHANGE = "";

  public decompositionTree = this.cs.DECOMPOSITION_TREE
  public valueChange: string = this.cs.VALUE_CHANGE
  public valueChangeAmoung: string = this.cs.VALUE_CHANGE_AMOUNT
  public changedValue = "";

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el
  }

  individualLogicForPeriod2() {
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.showChart = false
    } else {
      this.showChart = true
    }
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters([... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(n => n.Id)))], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          if(!this.showChart) {
            return
          }
          // console.log("allowedChartFactIds: ", this.allowedChartFactIds, data)

          this.hasPenetrationWarning = false

          let penetrationData = data.filter(i => i[Columns.Fact] === this.penetrationFactId)
          for(let pd of penetrationData) {
            if(!this.hasPenetrationWarning && this.utilityService.havePenetrationWarning(pd[Columns.Value])) {
              this.hasPenetrationWarning = true
            }
          }

          this.hh_per1 = this.HH[this.selectedPeriod1Item.Id]
          this.hh_per2 = this.HH[this.selectedPeriod2Item.Id]

          this.fact1_per1 = this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 1000
          this.fact1_per2 = this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 1000
          this.fact1_diff_abs = this.utilityService.getDiffAbsolute(this.fact1_per1, this.fact1_per2)
          this.fact1_diff_per = this.utilityService.getDiffInPercent(this.fact1_per1, this.fact1_per2)
          this.fact1_diff_change_per = ((this.fact1_diff_per) / 100) / this.fact1_diff_abs

          this.fact2_per1 = this.utilityService.getValue(data, this.unitsFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, 1) / 1000
          this.fact2_per2 = this.utilityService.getValue(data, this.unitsFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, 1) / 1000
          this.fact2_per1 = this.utilityService.myRoundNumber(this.fact2_per1, 2)
          this.fact2_per2 = this.utilityService.myRoundNumber(this.fact2_per2, 2)
          this.fact2_diff_per = this.utilityService.getDiffInPercent(this.fact2_per1, this.fact2_per2)

          this.fact3_per1 = this.utilityService.getValue(data, this.priceFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, -1)
          this.fact3_per2 = this.utilityService.getValue(data, this.priceFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, -1)
          this.fact3_impact = (this.fact3_diff_per / 100) / this.fact1_diff_change_per

          var fact2_log = Math.log(this.fact2_per1 / this.fact2_per2)
          var fact3_log = Math.log(this.fact3_per1 / this.fact3_per2)
          var fact2_3_log_sum = fact2_log + fact3_log
          this.fact2_impact = fact2_log / fact2_3_log_sum * this.fact1_diff_abs
          this.fact3_impact = fact3_log / fact2_3_log_sum * this.fact1_diff_abs
          this.fact3_diff_per = this.utilityService.getDiffInPercent(this.fact3_per1, this.fact3_per2)

          // Packungen je Account-Käufer
          this.fact4_per1 = (this.utilityService.getValue(data, this.unitsFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 1000) / (this.hh_per1 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100000000)
          this.fact4_per2 = (this.utilityService.getValue(data, this.unitsFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 1000) / (this.hh_per2 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100000000)
          this.fact4_diff_abs = this.utilityService.getDiffAbsolute(this.fact4_per1, this.fact4_per2)
          this.fact4_diff_per = this.utilityService.getDiffInPercent(this.fact4_per1, this.fact4_per2)

          // Anzahl der Account-Käufer
          this.fact5_per1 = this.hh_per1 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100000000
          this.fact5_per2 = this.hh_per2 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100000000

          var fact4_log = Math.log(this.fact4_per1 / this.fact4_per2)
          var fact5_log = Math.log(this.fact5_per1 / this.fact5_per2)
          var fact4_5_log_sum = fact4_log + fact5_log

          this.fact4_impact = fact4_log / fact4_5_log_sum * this.fact2_impact
          this.fact5_impact = fact5_log / fact4_5_log_sum * this.fact2_impact

          this.fact5_diff_per = this.utilityService.getDiffInPercent(this.fact5_per1, this.fact5_per2)

          this.fact6_per1 = this.utilityService.getValue(data, this.frequenzFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
          this.fact6_per2 = this.utilityService.getValue(data, this.frequenzFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
          this.fact6_diff_per = this.utilityService.getDiffInPercent(this.fact6_per1, this.fact6_per2)

          this.fact7_per1 = (this.utilityService.getValue(data, this.unitsFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000) / (this.hh_per1 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100) / this.utilityService.getValue(data, this.frequenzFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
          this.fact7_per2 = (this.utilityService.getValue(data, this.unitsFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000) / (this.hh_per2 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100) / this.utilityService.getValue(data, this.frequenzFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

          var fact6_log = Math.log(this.fact6_per1 / this.fact6_per2)
          var fact7_log = Math.log(this.fact7_per1 / this.fact7_per2)
          var fact6_7_log_sum = fact6_log + fact7_log
          this.fact6_impact = fact6_log / fact6_7_log_sum * this.fact4_impact
          this.fact7_impact = fact7_log / fact6_7_log_sum * this.fact4_impact

          this.fact7_diff_per = this.utilityService.getDiffInPercent(this.fact7_per1, this.fact7_per2)

          this.fact8_per1 = this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
          this.fact8_per2 = this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

          this.fact8_diff_per = this.utilityService.getDiffInPercent(this.fact8_per1, this.fact8_per2)

          this.fact9_per1 = this.hh_per1 / 1000000
          this.fact9_per2 = this.hh_per2 / 1000000
          this.fact9_diff_per = this.utilityService.getDiffInPercent(this.fact9_per1, this.fact9_per2)

          var fact8_log = Math.log(this.fact8_per1 / this.fact8_per2)
          var fact9_log = Math.log(this.fact9_per1 / this.fact9_per2)
          var fact8_9_log_sum = fact8_log + fact9_log

          this.fact8_impact = fact8_log / fact8_9_log_sum * this.fact5_impact
          this.fact9_impact = fact9_log / fact8_9_log_sum * this.fact5_impact

          // Format Facts for output
          this.fact1_per1 = this.utilityService.myRoundNumber(this.fact1_per1, 2)
          this.fact1_per2 = this.utilityService.myRoundNumber(this.fact1_per2, 2)
          this.fact1_diff_abs = this.utilityService.myRoundNumber(this.fact1_diff_abs, 1)

          this.fact2_per1 = this.utilityService.myRoundNumber(this.fact2_per1, 2)
          this.fact2_per2 = this.utilityService.myRoundNumber(this.fact2_per2, 2)
          this.fact2_impact = this.utilityService.myRoundNumber(this.fact2_impact, 1)
          this.fact2_diff_per = this.utilityService.myRoundNumber(this.fact2_diff_per, 1)

          this.fact3_per1 = this.utilityService.myRoundNumber(this.fact3_per1, 2)
          this.fact3_per2 = this.utilityService.myRoundNumber(this.fact3_per2, 2)
          this.fact3_impact = this.utilityService.myRoundNumber(this.fact3_impact, 1)

          this.fact4_per1 = this.utilityService.myRoundNumber(this.fact4_per1, 2)
          this.fact4_per2 = this.utilityService.myRoundNumber(this.fact4_per2, 2)

          this.fact5_per1 = this.utilityService.myRoundNumber(this.fact5_per1, 2)
          this.fact5_per2 = this.utilityService.myRoundNumber(this.fact5_per2, 2)
          this.fact5_impact = this.utilityService.myRoundNumber(this.fact5_impact, 1)

          this.fact6_per1 = this.utilityService.myRoundNumber(this.fact6_per1, 2)
          this.fact6_per2 = this.utilityService.myRoundNumber(this.fact6_per2, 2)
          this.fact6_impact = this.utilityService.myRoundNumber(this.fact6_impact, 1)

          this.fact7_per1 = this.utilityService.myRoundNumber(this.fact7_per1, 2)
          this.fact7_per2 = this.utilityService.myRoundNumber(this.fact7_per2, 2)
          this.fact7_impact = this.utilityService.myRoundNumber(this.fact7_impact, 1)

          this.fact8_per1 = this.utilityService.myRoundNumber(this.fact8_per1, 2)
          this.fact8_per2 = this.utilityService.myRoundNumber(this.fact8_per2, 2)
          this.fact8_impact = this.utilityService.myRoundNumber(this.fact8_impact, 1)

          this.fact9_per1 = this.utilityService.myRoundNumber(this.fact9_per1, 2)
          this.fact9_per2 = this.utilityService.myRoundNumber(this.fact9_per2, 2)
          this.fact9_impact = this.utilityService.myRoundNumber(this.fact9_impact, 1)

          this.data = [{
            label: this.cs.EXPENDITURES_ACCOUNT_BUYERS_M,
            type: 'person',
            styleClass: 'expenditures',
            expanded: true,
            data: {val: this.utilityService.myFormatNumber(this.fact1_per1,2,2) ,change:this.utilityService.formatNumberAsPercent(this.fact1_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact1_diff_abs,1,1)},
            children: [
                {
                    label: this.cs.UNITS_M,
                    type: 'person',
                    styleClass: 'units',
                    data: {val: this.utilityService.myFormatNumber(this.fact2_per1,2,2) ,change:this.utilityService.formatNumberAsPercent(this.fact2_diff_per,1), impact: this.fact2_impact},
                    expanded: true,
                    children: [
                      {
                          label: this.cs.UNITS_ACCOUNT_BUYER,
                          type: 'person',
                          styleClass: 'units-account-buyer',
                          data: {val: this.utilityService.myFormatNumber(this.fact4_per1,2,2) ,change:this.utilityService.formatNumberAsPercent(this.fact4_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact4_impact,1,1)},
                          expanded: true,
                          children: [
                            {
                                label: this.cs.PURCHASE_FREQUENCY,
                                type: 'person',
                                styleClass: 'purchase-frequency',
                                data: {val: this.utilityService.myFormatNumber(this.fact6_per1,2,2) ,change:this.utilityService.formatNumberAsPercent(this.fact6_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact6_impact,1,1)},
                                expanded: true,
                            },
                            {
                              label: this.cs.UNITS_OCCASION,
                              type: 'person',
                              styleClass: 'units-occasion',
                              data: {val: this.utilityService.myFormatNumber(this.fact7_per1,2,2), change:this.utilityService.formatNumberAsPercent(this.fact7_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact7_impact,1,1)},
                              expanded: true,
                            }
                        ]
                      },
                      {
                        label: this.cs.NUMBER_ACCOUNT_BUYERS_M,
                        type: 'person',
                        styleClass: 'number-account-buyer',
                        data: {val: this.utilityService.myFormatNumber(this.fact5_per1,2,2), change:this.utilityService.formatNumberAsPercent(this.fact5_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact5_impact,1,1)},
                        expanded: true,
                        children: [
                          {
                              label: this.cs.PENETRATION_ACCOUNT_BUYER,
                              type: 'person',
                              styleClass: 'penetration-account-buyers',
                              data: {val: this.utilityService.myFormatNumber(this.fact8_per1,2,2),change:this.utilityService.formatNumberAsPercent(this.fact8_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact8_impact,1,1)},
                              expanded: true,
                          },
                          {
                            label: this.cs.NUMBER_HOUSEHOLDS_TOTAL_MARKET_M,
                            type: 'person',
                            styleClass: 'number-households',
                            data: {val: this.utilityService.myFormatNumber(this.fact9_per1,2,2), change:this.utilityService.formatNumberAsPercent(this.fact9_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact9_impact,1,1)},
                            expanded: true,
                          }
                      ]
                      }
                  ]
                },
                {
                  label: this.cs.AVERAGE_PRICE_UNIT,
                  styleClass: 'average-price',
                  type: 'person',
                  data: {val: this.fact3_per1, change:this.utilityService.formatNumberAsPercent(this.fact3_diff_per, 1), impact: this.utilityService.myFormatNumber(this.fact3_impact,1,1)},
                  expanded: true
              }
            ]
          }];

          var item1 =  this.fact1_diff_abs;
          //var item1 =  0;
          var item2 =  this.fact3_impact;
          var item3 =  item2 + this.fact6_impact;
          var item4 =  item3 + this.fact7_impact;
          var item5 =  item4 + this.fact8_impact;
          var item6 =  item5 + this.fact9_impact;

          this.ContributionValueChangeY = [
            { [Ids.Category]: this.cs.AVERAGE_PRICE_UNIT,  [Ids.Value]: item2, [Ids.Change]: this.fact3_impact, index: 0, [Ids.Label]: this.fact3_impact >= 0? "+" + this.utilityService.myFormatNumber(this.fact3_impact,1) : this.utilityService.myFormatNumber(this.fact3_impact,1)},
            { [Ids.Category]: this.cs.PURCHASE_FREQUENCY, [Ids.Value]: item3, [Ids.Change]: this.fact6_impact, index: 1, [Ids.Label]: this.fact6_impact >= 0? "+" + this.utilityService.myFormatNumber(this.fact6_impact,1) : this.utilityService.myFormatNumber(this.fact6_impact,1)},
            { [Ids.Category]: this.cs.UNITS_OCCASION, [Ids.Value]: item4, [Ids.Change]: this.fact7_impact, index: 2, [Ids.Label]: this.fact7_impact >= 0? "+" + this.utilityService.myFormatNumber(this.fact7_impact,1) : this.utilityService.myFormatNumber(this.fact7_impact,1)},
            { [Ids.Category]: this.cs.PENETRATION_ACCOUNT_BUYER, [Ids.Value]: item5, [Ids.Change]: this.fact8_impact, index: 3, [Ids.Label]: this.fact8_impact >= 0? "+" + this.utilityService.myFormatNumber(this.fact8_impact,1) : this.utilityService.myFormatNumber(this.fact8_impact,1)},
            { [Ids.Category]: this.cs.NUMBER_HOUSEHOLDS, [Ids.Value]: item6, [Ids.Change]: this.fact9_impact, index: 4, [Ids.Label]: this.fact9_impact >= 0? "+" + this.utilityService.myFormatNumber(this.fact9_impact,1) : this.utilityService.myFormatNumber(this.fact9_impact,1)},
          ]

          this.VALUE_CHANGE = this.fact1_diff_abs >= 0? "+" + this.utilityService.myFormatNumber(this.fact1_diff_abs,1) : this.utilityService.myFormatNumber(this.fact1_diff_abs,1)
        }
      )
    })

  }

  chartMasterNextPage = (event:any) => {
    let pptMaster: string = this.userDefinedPptMaster
    let slide: any
    if(pptMaster) {
      slide = this.chartMasterPPT.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = this.chartMasterPPT.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#main-dashboard";

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
      // console.log("dataUrl: ", dataUrl)
      console.log(image);
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      // console.log("image: ", image)
      slide.addImage({
      data: image,
      x: 1.4,
      y: 2,
      w: 10,
      h: 5
      });
      this.chartMasterService.next();
    })
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  handleExportPPT(singleTreeName: string): void {
    if (!this.el ||
      !this.el.nativeElement ||
      !this.el.nativeElement.children){
        console.log('cant build without element');
      return;
    }

    let pptMaster: string = this.userDefinedPptMaster

    var pptx = new pptxgen();
    this.exportService.createPPTBasics(pptx)

    // create the ppt master
    this.exportService.createPPTMaster(pptx, pptMaster)
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle

    var div_id = "#main-dashboard";

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
      // console.log("dataUrl: ", dataUrl)
      console.log(image);
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      // console.log("image: ", image)
      slide.addImage({
      data: image,
      x: 1.4,
      y: 2,
      w: 10,
      h: 5
      });
      pptx.writeFile({fileName: fileName})
        .then(fileName => {
          console.log(`Created Single PPT file: ${fileName}`);
          this.isCreatingExport = false
      });
    })
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // not used
    this.isCreatingExport = false
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    columnValueMap[this.cs.FACT] = Columns.Fact
    columnValueMap[this.cs.VALUE] = Columns.Value
    columnValueMap[this.cs.CHANGE_RATE] = Ids.Changes
    columnValueMap[this.cs.CONTRIBUTION_TO_CHANGE] = Ids.Contribution

    let excelData: any[] = []
    excelData = [
      {[Columns.Fact]: this.cs.EXPENDITURES_ACCOUNT_BUYERS_M, [Columns.Value]: this.utilityService.myRoundNumber(this.fact1_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact1_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact1_diff_abs, 1)},
      {[Columns.Fact]: this.cs.UNITS_M, [Columns.Value]: this.utilityService.myRoundNumber(this.fact2_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact2_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact2_impact, 1)},
      {[Columns.Fact]: this.cs.AVERAGE_PRICE_UNIT, [Columns.Value]: this.utilityService.myRoundNumber(this.fact3_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact3_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact3_impact, 1)},
      {[Columns.Fact]: this.cs.UNITS_ACCOUNT_BUYER, [Columns.Value]: this.utilityService.myRoundNumber(this.fact4_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact4_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact4_impact, 1)},
      {[Columns.Fact]: this.cs.NUMBER_ACCOUNT_BUYERS_M, [Columns.Value]: this.utilityService.myRoundNumber(this.fact5_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact5_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact5_impact, 1)},
      {[Columns.Fact]: this.cs.PURCHASE_FREQUENCY, [Columns.Value]: this.utilityService.myRoundNumber(this.fact6_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact6_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact6_impact, 1)},
      {[Columns.Fact]: this.cs.UNITS_OCCASION, [Columns.Value]: this.utilityService.myRoundNumber(this.fact7_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact7_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact7_impact, 1)},
      {[Columns.Fact]: this.cs.PENETRATION_ACCOUNT_BUYER, [Columns.Value]: this.utilityService.myRoundNumber(this.fact8_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact8_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact8_impact, 1)},
      {[Columns.Fact]: this.cs.NUMBER_HOUSEHOLDS_TOTAL_MARKET_M, [Columns.Value]: this.utilityService.myRoundNumber(this.fact9_per1, 2),[Ids.Value]: this.utilityService.myRoundNumber(this.fact9_diff_per, 2),[Ids.Contribution]: this.utilityService.myRoundNumber(this.fact9_impact, 1)},
    ]

    let dummy: any = []

    this.exportService.exportExcel("Decomposition_Tree", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, excelData, header, columnValueMap, dummy, dummy, dummy)
                      .then(fileName => {
                     //   console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });


  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // Period1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Period2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)

    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.showChart = false
    } else {
      this.showChart = true
    }

    // ShopperGroup
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

  }
}
