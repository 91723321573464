import {TreeNode} from 'primeng/api';
import { ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import pptxgen from 'pptxgenjs';
import domtoimage from 'dom-to-image';
declare var $:any;

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { Columns, DashboardId, Ids, UserSelectionIds } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-market-share-components',
  templateUrl: './market-share-components.component.html',
  styleUrls: ['./market-share-components.component.scss']
})
export class MarketShareComponentsComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.MARKET_SHARE
  dashboardId = DashboardId.market_share_components
  dashboards = this.cs.MARKET_SHARE_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // call backend
  getHHDataFromBackend = true

  // If it has 2 period filters
  hasPeriod2Filter = true

  // Filter Three Tab Items show or not


  // Facts used in dashboard
  sotFactId = "F9"
  loyaltyFactId = "F10"
  propensFactId = "F11"
  ispFactId = "F2"
  aspFactId = "F1"
  bedFactId = "F14"
  ret_expFactId = "F7"
  frequenzFactId = "F5"
  wjheFactId = "F13"
  intensFactId = "F16"

  allowedChartFactIds = [this.sotFactId, this.loyaltyFactId, this.propensFactId, this.ispFactId, this.aspFactId, this.bedFactId, this.ret_expFactId, this.frequenzFactId, this.wjheFactId, this.intensFactId, this.penetrationFactId]

  hh_per1: number = 0;
  hh_per2: number = 0;
  fact1_per1_percent: number = 0;
  fact1_per2_percent: number = 0;
  fact2_per1_percent: number = 0;
  fact2_per2_percent: number = 0;
  fact3_per1_percent: number = 0;
  fact3_per2_percent: number = 0;
  fact4_per1_percent: number = 0;
  fact4_per2_percent: number = 0;
  fact5_per1: number = 0;
  fact5_per2: number = 0;
  fact6_per1: number = 0;
  fact6_per2: number = 0;
  fact7_per1_percent: number = 0;
  fact7_per2_percent: number = 0;
  fact8_per1: number = 0;
  fact8_per2: number = 0;
  fact9_per1: number = 0;
  fact9_per2: number = 0;
  fact10_per1: number = 0;
  fact10_per2: number = 0;
  fact11_per1: number = 0;
  fact11_per2: number = 0;
  fact12_per1: number = 0;
  fact12_per2: number = 0;
  fact13_per1_percent: number = 0;
  fact13_per2_percent: number = 0;
  fact14_per1: number = 0;
  fact14_per2: number = 0;
  fact15_per1: number = 0;
  fact15_per2: number = 0;



  // Set initial look and behivor of trees, and there is no swith mode
  /** Tree Account */
  treeAccountIsMulti = false     // make the tree without checkbox
  callBackendToGetAccountAndAllProducts = false // get account from the store

  /** Tree Product */
  treeProductIsMulti = false     // make the tree without checkbox
  callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

  /** -------------------- Inputs for this dashboard -------------------- */

  public data: TreeNode[] = [];

  private excelData: any[] = []; // for exporting excel

  private el: ElementRef;

  public period: string = this.cs.PERIOD

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el
  }


  feedChartWithData(): void {
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters([... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(n => n.Id)))], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( data => {
            this.hasPenetrationWarning = false

            let penetrationData = data.filter(i=>i[Columns.Fact] === this.penetrationFactId)
            for(let pd of penetrationData) {
              if(!this.hasPenetrationWarning && this.utilityService.havePenetrationWarning(pd[Columns.Value])) {
                this.hasPenetrationWarning = true
              }
            }

            // console.log("market-share-components | data: ", data)

            this.hh_per1 = this.HH[this.selectedPeriod1Item.Id]
            this.hh_per2 = this.HH[this.selectedPeriod2Item.Id]


            this.fact1_per1_percent = this.utilityService.getValue(data, this.sotFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact1_per2_percent = this.utilityService.getValue(data, this.sotFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact2_per1_percent = this.utilityService.getValue(data, this.loyaltyFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact2_per2_percent = this.utilityService.getValue(data, this.loyaltyFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact3_per1_percent = this.utilityService.getValue(data, this.propensFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact3_per2_percent = this.utilityService.getValue(data, this.propensFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact5_per1 = this.hh_per1 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100
            this.fact5_per2 = this.hh_per2 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100

            this.fact6_per1 = this.hh_per1 * this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100
            this.fact6_per2 = this.hh_per2 * this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100

            this.fact4_per1_percent = this.fact5_per1 / this.fact6_per1 * 100
            this.fact4_per2_percent = this.fact5_per2 / this.fact6_per2 * 100

            this.fact7_per1_percent = this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact7_per2_percent = this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact8_per1 = this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100 * 1000
            this.fact8_per2 = this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100 * 1000

            this.fact9_per1 = this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000
            this.fact9_per2 = this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 1000

            this.fact10_per1 = this.hh_per1 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100
            this.fact10_per2 = this.hh_per2 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100

            this.fact11_per1 = this.utilityService.getValue(data, this.frequenzFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact11_per2 = this.utilityService.getValue(data, this.frequenzFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact12_per1 = this.utilityService.getValue(data, this.wjheFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact12_per2 = this.utilityService.getValue(data, this.wjheFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact13_per1_percent = this.utilityService.getValue(data, this.intensFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)
            this.fact13_per2_percent = this.utilityService.getValue(data, this.intensFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id)

            this.fact14_per1 = (this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100) / (this.hh_per1 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100) * 1000
            this.fact14_per2 = (this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100) / (this.hh_per2 * this.utilityService.getValue(data, this.ispFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100) * 1000

            this.fact15_per1 = (this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100) / (this.hh_per1 * this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod1Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100) * 1000
            this.fact15_per2 = (this.utilityService.getValue(data, this.ret_expFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / this.utilityService.getValue(data, this.bedFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) * 100) / (this.hh_per2 * this.utilityService.getValue(data, this.aspFactId, this.selectedPeriod2Item.Id, this.selectedAccountNodes[0].Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id) / 100) * 1000

            // console.log("this.fact1_per1_percent, this.fact1_per2_percent: ", this.fact1_per1_percent, this.fact1_per2_percent)
            // console.log("this.fact2_per1_percent, this.fact2_per2_percent: ", this.fact2_per1_percent, this.fact2_per2_percent)
            // console.log("this.fact3_per1_percent, this.fact3_per2_percent: ", this.fact3_per1_percent, this.fact3_per2_percent)
            // console.log("this.fact4_per1_percent, this.fact4_per2_percent: ", this.fact4_per1_percent, this.fact4_per2_percent)
            // console.log("this.fact5_per1, this.fact5_per2: ", this.fact5_per1, this.fact5_per2)
            // console.log("this.fact6_per1, this.fact6_per2: ", this.fact6_per1, this.fact6_per2)
            // console.log("this.fact7_per1_percent, this.fact7_per2_percent: ", this.fact7_per1_percent, this.fact7_per2_percent)
            // console.log("this.fact8_per1, this.fact8_per2: ", this.fact8_per1, this.fact8_per2)
            // console.log("this.fact9_per1, this.fact9_per2: ", this.fact9_per1, this.fact9_per2)
            // console.log("this.fact10_per1, this.fact10_per2: ", this.fact10_per1, this.fact10_per2)
             //console.log("this.fact11_per1, this.fact11_per2: ", this.fact11_per1, this.fact11_per2)
            // console.log("this.fact12_per1, this.fact12_per2: ", this.fact12_per1, this.fact12_per2)
            // console.log("this.fact13_per1_percent, this.fact13_per2_percent: ", this.fact13_per1_percent, this.fact13_per2_percent)
            // console.log("this.fact14_per1, this.fact14_per2: ", this.fact14_per1, this.fact14_per2)
            // console.log("this.fact15_per1, this.fact15_per2: ", this.fact15_per1, this.fact15_per2)

            //console.log("Ids.number", Ids.number)

            this.data = [{
              label: this.cs.FACT_OBJECT["F_MSC_FACT1"],
              type: 'person',
              styleClass: 'org-marketshare',
              expanded: true,
              data: { MAT1: this.printFactOfPeriod1(Ids.percent, this.fact1_per1_percent), MAT2: this.printFactOfPeriod2(Ids.percent, this.fact1_per2_percent) },
              children: [
                  {
                      label: this.cs.FACT_OBJECT["F_MSC_FACT2"],
                      type: 'person',
                      styleClass: 'org-loyality',
                      data: { MAT1: this.printFactOfPeriod1(Ids.percent, this.fact2_per1_percent), MAT2: this.printFactOfPeriod2(Ids.percent, this.fact2_per2_percent) },
                      expanded: true,
                      children: [
                        {
                            label: this.cs.FACT_OBJECT["F_MSC_FACT4"],
                            type: 'person',
                            styleClass: 'org-shopperconversion',
                            data: { MAT1: this.printFactOfPeriod1(Ids.percent, this.fact4_per1_percent), MAT2: this.printFactOfPeriod2(Ids.percent, this.fact4_per2_percent) },
                            expanded: true,
                            children: [
                              {
                                  label: this.cs.FACT_OBJECT["F_MSC_FACT5"],
                                  type: 'person',
                                  styleClass: 'org-shopperconversion',
                                  data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact5_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact5_per2) },
                                  expanded: true,
                              },
                              {
                                label: this.cs.FACT_OBJECT["F_MSC_FACT6"],
                                type: 'person',
                                styleClass: 'org-shopperconversion',
                                data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact6_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact6_per2) },
                                expanded: true,
                              }
                          ]
                        },
                        {
                          label: this.cs.FACT_OBJECT["F_MSC_FACT7"],
                          type: 'person',
                          styleClass: 'org-shareofrequirement',
                          data: { MAT1: this.printFactOfPeriod1(Ids.percent, this.fact7_per1_percent), MAT2: this.printFactOfPeriod2(Ids.percent, this.fact7_per2_percent) },
                          expanded: true,
                          children: [
                            {
                                label: this.cs.FACT_OBJECT["F_MSC_FACT9"],
                                type: 'person',
                                styleClass: 'org-shareofrequirement',
                                data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact9_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact9_per2) },
                                expanded: true,
                                children: [
                                  {
                                      label: this.cs.FACT_OBJECT["F_MSC_FACT10"],
                                      type: 'person',
                                      styleClass: 'org-shareofrequirement',
                                      data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact10_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact10_per2) },
                                      expanded: true,
                                  },
                                  {
                                    label: this.cs.FACT_OBJECT["F_MSC_FACT11"],                                    
                                    type: 'person',
                                    styleClass: 'org-shareofrequirement',
                                    data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact11_per1, 1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact11_per2, 1) },
                                    expanded: true,
                                  },
                                  {
                                    label: this.cs.FACT_OBJECT["F_MSC_FACT12"],
                                    type: 'person',
                                    styleClass: 'org-shareofrequirement',
                                    data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact12_per1, 2), MAT2: this.printFactOfPeriod2(Ids.number, this.fact12_per2, 2) },
                                    expanded: true,
                                  }
                              ]
                            },
                            {
                              label: this.cs.FACT_OBJECT["F_MSC_FACT8"],
                              type: 'person',
                              styleClass: 'org-shareofrequirement',
                              data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact8_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact8_per2) },
                              expanded: true,
                            }
                        ]
                        },
                        {
                          label: this.cs.FACT_OBJECT["F_MSC_FACT13"],
                          type: 'person',
                          styleClass: 'org-intenseness',
                          data: { MAT1: this.printFactOfPeriod1(Ids.percent, this.fact13_per1_percent, 2), MAT2: this.printFactOfPeriod2(Ids.percent, this.fact13_per2_percent, 2) },
                          expanded: true,
                          children: [
                            {
                                label: this.cs.FACT_OBJECT["F_MSC_FACT14"],
                                type: 'person',
                                styleClass: 'org-intenseness',
                                data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact14_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact14_per2) },
                                expanded: true,
                            },
                            {
                              label: this.cs.FACT_OBJECT["F_MSC_FACT15"],
                              type: 'person',
                              styleClass: 'org-intenseness',
                              data: { MAT1: this.printFactOfPeriod1(Ids.number, this.fact15_per1), MAT2: this.printFactOfPeriod2(Ids.number, this.fact15_per2) },
                              expanded: true,
                            }
                        ]
                      },
                    ]
                  },
                  {
                    label: this.cs.FACT_OBJECT["F_MSC_FACT3"],
                    styleClass: 'org-intenseness',
                    type: 'person',
                    data: { MAT1: this.printFactOfPeriod1(Ids.percent, this.fact3_per1_percent), MAT2: this.printFactOfPeriod2(Ids.percent, this.fact3_per2_percent) },
                    expanded: true
                }
              ]
            }];
          }
        )
      })

  }

  chartMasterNextPage = (event:any) => {
    let pptMaster: string = this.userDefinedPptMaster
    let slide: any
    if(pptMaster) {
      slide = this.chartMasterPPT.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = this.chartMasterPPT.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#chart";

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      slide.addImage({
      data: image,
      x: 1.4,
      y: 2,
      w: 10,
      h: 5
      });

      this.chartMasterService.next();
    })

  }

  handleExportPPT(singleTreeName: string): void {
    this.createPPT()
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    //has no multi ppt export
  }

  handleExportExcel() {
    this.excelData = []
    let facts: string[] = [
      this.cs.FACT_OBJECT["F_MSC_FACT1"] + " %",
      this.cs.FACT_OBJECT["F_MSC_FACT2"] + " %",
      this.cs.FACT_OBJECT["F_MSC_FACT3"] + " %",
      this.cs.FACT_OBJECT["F_MSC_FACT4"] + " %",
      this.cs.FACT_OBJECT["F_MSC_FACT5"],
      this.cs.FACT_OBJECT["F_MSC_FACT6"],
      this.cs.FACT_OBJECT["F_MSC_FACT7"] + " %",
      this.cs.FACT_OBJECT["F_MSC_FACT9"],
      this.cs.FACT_OBJECT["F_MSC_FACT8"],
      this.cs.FACT_OBJECT["F_MSC_FACT10"],
      this.cs.FACT_OBJECT["F_MSC_FACT11"],
      this.cs.FACT_OBJECT["F_MSC_FACT12"],
      this.cs.FACT_OBJECT["F_MSC_FACT13"] + " %",
      this.cs.FACT_OBJECT["F_MSC_FACT14"],
      this.cs.FACT_OBJECT["F_MSC_FACT15"],
    ]

    let valuesOfPeriod1 = [
      this.roundTo1(this.fact1_per1_percent),
      this.roundTo1(this.fact2_per1_percent),
      this.roundTo1(this.fact3_per1_percent),
      this.roundTo1(this.fact4_per1_percent),
      this.roundMTo1(this.fact5_per1),
      this.roundMTo1(this.fact6_per1),
      this.roundTo1(this.fact7_per1_percent),
      this.roundMTo1(this.fact9_per1),
      this.roundMTo1(this.fact8_per1),
      this.roundMTo1(this.fact10_per1),
      this.roundTo1(this.fact11_per1),
      this.roundTo2(this.fact12_per1),
      this.roundTo1(this.fact13_per1_percent),
      this.roundTo1(this.fact14_per1),
      this.roundTo1(this.fact15_per1)
    ]

    let valuesOfPeriod2 = [
      this.roundTo1(this.fact1_per2_percent),
      this.roundTo1(this.fact2_per2_percent),
      this.roundTo1(this.fact3_per2_percent),
      this.roundTo1(this.fact4_per2_percent),
      this.roundMTo1(this.fact5_per2),
      this.roundMTo1(this.fact6_per2),
      this.roundTo1(this.fact7_per2_percent),
      this.roundMTo1(this.fact9_per2),
      this.roundMTo1(this.fact8_per2),
      this.roundMTo1(this.fact10_per2),
      this.roundTo1(this.fact11_per2),
      this.roundTo2(this.fact12_per2),
      this.roundTo1(this.fact13_per2_percent),
      this.roundTo1(this.fact14_per2),
      this.roundTo1(this.fact15_per2)
    ]
    // console.log(valuesOfPeriod1, valuesOfPeriod2)
    for(let i = 0 ; i < facts.length; i++) {
      let excelDataItem: any = {}
      excelDataItem[Columns.Fact] = facts[i]
      excelDataItem[this.selectedPeriod1Item.Name] = valuesOfPeriod1[i]
      excelDataItem[this.selectedPeriod2Item.Name] = valuesOfPeriod2[i]
      this.excelData.push(excelDataItem)
    }
    // console.log("excelData: ",this.excelData)

    let header: any = {}
    header[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD1] = this.selectedPeriod1ItemNames.join(", ")
    header[this.cs.PERIOD2] = this.selectedPeriod2ItemNames.join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap:any = {}
    columnValueMap[this.cs.FACT] = Columns.Fact
    columnValueMap[this.selectedPeriod1Item.Name] = this.selectedPeriod1Item.Name
    columnValueMap[this.selectedPeriod2Item.Name] = this.selectedPeriod2Item.Name

    let dummy: any = []

    this.exportService.exportExcel("MarketShare_Components", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.excelData, header, columnValueMap, dummy, dummy, dummy)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  private roundTo2(num: number) {
    return Math.round(num * 100) / 100
  }

  private roundTo1(num: number) {
    return Math.round(num * 10) / 10
  }

  private roundMTo1(num: number) {
    let mNum = num / 1000000
    return Math.round(mNum * 10) / 10
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  private createPPT(){
    if (!this.el ||
        !this.el.nativeElement ||
        !this.el.nativeElement.children){
          console.log('cant build without element');
        return;
    }

    let pptMaster: string = this.userDefinedPptMaster

    var pptx = new pptxgen();
    this.exportService.createPPTBasics(pptx)

    // create the ppt master
    this.exportService.createPPTMaster(pptx, pptMaster)
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#chart";

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      slide.addImage({
      data: image,
      x: 1.4,
      y: 2,
      w: 10,
      h: 5
      });
      pptx.writeFile({fileName: fileName})
        .then(fileName => {
          console.log(`Created Single PPT file: ${fileName}`);
          this.isCreatingExport = false
      });
    })
  }

  printFactOfPeriod1(flag: string, factPer1: number, digits: number = 1) : string {
    if(flag === Ids.percent) {
      return this.utilityService.formatNumberAsPercent(factPer1, digits)
    } else {
      return this.utilityService.abbreviateNumber(factPer1, digits, digits)
    }
  }

  printFactOfPeriod2(flag: string, factPer2: number, digits: number = 1): string {
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      return ""
    } else {
      if(flag === Ids.percent) {
        return this.utilityService.formatNumberAsPercent(factPer2, digits)
      } else {
        return this.utilityService.abbreviateNumber(factPer2, digits)
      }
    }
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // Period 1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Period 2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)
    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]

    // Shopper Group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

  }
}
