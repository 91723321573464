import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItemChecked } from '../../../model/interfaces';

@Component({
  selector: 'app-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: ['./multi-select-checkbox.component.scss']
})
export class MultiSelectCheckboxComponent {

  @Input() public name!: string;
  @Input() public items: SelectItemChecked[] = [];

  @Output() getCheckedItemsEvent = new EventEmitter<any>();

  constructor() { }

  public onClick() {
    let itemNames = []
    let itemIds = []

    for(let item of this.items) {
      if(item.Checked) {
        itemNames.push(item.Name)
        itemIds.push(item.Id)
      }
    }
    const name = this.name

    this.getCheckedItemsEvent.emit({name, itemNames, itemIds});
  }
}
