import { ActionReducer, INIT, MetaReducer } from "@ngrx/store";
import { DashboardState } from "src/app/dashboard/shared/state/dashboard.reducer";
import {AuthActions} from './auth.actions';

export function logout(reducer: ActionReducer<DashboardState>): ActionReducer<DashboardState> {
  return (state, action) => {
    if ( action != null && action.type === AuthActions.logout.type) {
      return reducer( undefined, {type: INIT});
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [ logout ];
