import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { Store } from '@ngrx/store';

import { IgxToastComponent } from '@infragistics/igniteui-angular';

import { AuthAppState } from 'src/app/auth/state/auth.reducer';
import { fromAuth } from 'src/app/auth/state/auth.selectors';
import { ConstantService } from '../../shared/services/constant.service';
import { AuthService } from '../../../auth/services/auth.service';
import { AuthActions } from 'src/app/auth/state/auth.actions';
import { DashboardActions } from '../../shared/state/dashboard.actions';
import { fromDashboard } from '../../shared/state/dashboard.selector';
import { UtilityService } from '../../shared/services/utility.service';

@UntilDestroy()
@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit {

  // titles
  public headerBasic = this.cs.BASIC
  public firstColorTitle = this.cs.FIRST_DEFAULT_COLOR
  public secondColorTitle = this.cs.SECOND_DEFAULT_COLOR
  public headerAccounts = this.cs.ACCOUNTS
  public headerProducts = this.cs.PRODUCTS
  public saveTitle = this.cs.SAVE

  // Basic Color
  public latestChartColorFirst: string = ''
  public latestChartColorSecond: string = ''
  public chartColorFirst: string = '';
  public chartColorSecond: string = '';

  public basicColorIsLoading: boolean = true;
  public isBasicColorSaving$     = this.store.select(fromAuth.isBasicColorSaving)
  public isBasicColorSaved$      = this.store.select(fromAuth.isBasicColorSaved)

  // Account & Product
  public ProductTreeData: any[] = [];
  public AccountTreeData: any[] = [];

  public isDimLoaded$        = this.store.select(fromDashboard.isDimLoaded)
  public isDimLoading$        = this.store.select(fromDashboard.isDimLoading)
  public filterAccountData: any[] = []
  public filterProductData: any[] = []

  public latestAccountsTreeString: string = ""
  public latestProductsTreeString: string = ""
  public AccountsTree: any[] = []
  public ProductsTree: any[] = []

  public isAccountProductColorSaving$     = this.store.select(fromAuth.isAccountProductColorSaving)
  public isAccountProductColorSaved$      = this.store.select(fromAuth.isAccountProductColorSaved)

  // Others
  @ViewChild('toast_color', { read: IgxToastComponent })
  public toast_color!: IgxToastComponent;

  public json = JSON

  constructor(
    public store: Store<AuthAppState>,
    public cs: ConstantService,
    public authService: AuthService,
    public utilityService: UtilityService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(DashboardActions.dimGet())

    this.getBasicColor()

    this.getAccountProductDimensions()

//    console.log("xxx");
  }

  // Basic Color functions
  private getBasicColor() {
    this.basicColorIsLoading = true
    this.store
    .select<any | null>(fromAuth.selectAuthUser)
    .pipe(first(), untilDestroyed(this))
    .subscribe( user => {
        this.latestChartColorFirst = user.chart_color_first
        this.latestChartColorSecond = user.chart_color_second
        this.chartColorFirst  = this.latestChartColorFirst
        this.chartColorSecond = this.latestChartColorSecond

        this.basicColorIsLoading = false
      //  console.log("yyy")
        // console.log("this.chartColorFirst: ", this.chartColorFirst, this.chartColorSecond)
      }
    )
  }

  public getChartColorFirst(event: string, data: any): void {
    this.chartColorFirst = data;
    // console.log("chartColorFirst: ", this.chartColorFirst);
  }

  public getChartColorSecond(event: string, data: any): void {
    this.chartColorSecond = data;
    // console.log("chartColorSecond: ", this.chartColorSecond);
  }

  public SaveBasicColor() {
    // console.log("chartColorFirst: ", this.chartColorFirst, this.chartColorSecond);
    let firstColor = this.chartColorFirst
    let secondColor = this.chartColorSecond

    this.store.dispatch(AuthActions.updateBasicColorsRequest({firstColor, secondColor}))

    this.isBasicColorSaved$.subscribe((saved) => {
      if(saved) {
        this.latestChartColorFirst = this.chartColorFirst
        this.latestChartColorSecond = this.chartColorSecond
        this.toast_color.open(this.cs.CHANGE_SAVED)
      }
    })
  }

  // Account/Product Color functions
  private getAccountProductDimensions() {
    console.log("111")
    // TODO, make it just like the dimensions, save the user defined colors in store and get them from  store.
    // So that in the dashboards, where the user defined colors are needed, you could get them from store if they are there.
    this.authService.getAccountProductColor().subscribe(data => {
//      console.log("aaa")
      //console.log(data)
      let userDefinedAccountColor: any = data[0]["account_color"]
      let userDefinedProductColor: any = data[0]["product_color"]
      
      this.isDimLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
        //console.log("222")
        this.store.select<any[]>(fromDashboard.selectAllDim)      // select the dim from state
        .pipe(first(), untilDestroyed(this))
        .subscribe(dim => {
          //console.log("333")
              this.filterAccountData = dim.filter(dim => dim.Dimension === 'AccountTree')[0]['Tree']
              this.filterProductData = dim.filter(dim => dim.Dimension === 'ProductTree')[0]['Tree']

              this.AccountsTree = JSON.parse(JSON.stringify(this.filterAccountData)); // make the convertion here so that you can edit the data
              this.ProductsTree = JSON.parse(JSON.stringify(this.filterProductData));

              this.utilityService.updateIdAndColor(this.AccountsTree, userDefinedAccountColor)
              this.utilityService.updateIdAndColor(this.ProductsTree, userDefinedProductColor)

              this.latestAccountsTreeString = JSON.stringify(this.AccountsTree)
              this.latestProductsTreeString = JSON.stringify(this.ProductsTree)
            //  console.log("zzz")
               //console.log("this.AccountsTree: ", typeof this.AccountsTree, this.AccountsTree)
               //console.log("this.ProductsTree: ", typeof this.ProductsTree, this.ProductsTree)
        })
      })
    })

  }

  public saveAccountsColor() {
    let accountProduct = "account"
    let accountProductColor: any = {}
    this.utilityService.getIdAndColor(this.AccountsTree, accountProductColor)
    console.log("accountProductColor: ", this.AccountsTree, accountProductColor, this.latestAccountsTreeString)

    this.store.dispatch(AuthActions.updateAccountProductColorRequest({accountProductColor, accountProduct}))

    this.isAccountProductColorSaved$.subscribe((saved) => {
      if(saved) {
        this.latestAccountsTreeString = JSON.stringify(this.AccountsTree)
        this.toast_color.open(this.cs.CHANGE_SAVED)
      }
    })
  }

  public saveProductsColor() {
    let accountProduct = "product"
    let accountProductColor: any = {}
    this.utilityService.getIdAndColor(this.ProductsTree, accountProductColor)
    // console.log("accountProductColor: ", this.ProductsTree, accountProductColor)

    this.store.dispatch(AuthActions.updateAccountProductColorRequest({accountProductColor, accountProduct}))

    this.isAccountProductColorSaved$.subscribe((saved) => {
      if(saved) {
        this.latestProductsTreeString = JSON.stringify(this.ProductsTree)
        this.toast_color.open(this.cs.CHANGE_SAVED)
      }
    })
  }
}

