import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';

import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { Columns, DashboardId, Ids, ModeIds, PPTIds, UserSelectionIds } from '../../../shared/model/constants';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { fromAuth } from 'src/app/auth/state/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-basket-analysis',
  templateUrl: './basket-analysis.component.html',
  styleUrls: ['./basket-analysis.component.scss']
})
export class BasketAnalysisComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.BASKET
  dashboardId = DashboardId.basket_analysis
  dashboards = this.cs.BASKET_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // Facts used in dashboard
  allowedChartFactIds = ["F19","F17", "F18","F20", this.penetrationFactId]

  /** -------------------- Inputs for this dashboard -------------------- */
  filterPeriod1Title = this.cs.PERIOD

  public NoBenchmarkSelectedId: string = this.cs.NO_BENCHMARK_SELECTED.Id

  public maxYAxis: number = 0

  @ViewChild("fact1Series")
  public fact1Series!: IgxColumnSeriesComponent;

  @ViewChild("fact2Series")
  public fact2Series!: IgxColumnSeriesComponent;

  @ViewChild("fact3Series")
  public fact3Series!: IgxColumnSeriesComponent;

  @ViewChild("fact4Series")
  public fact4Series!: IgxColumnSeriesComponent;


  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  setToDefaultIndividual() {
    this.changeDetector.detectChanges();
    if(this.fact1Series !== undefined) { this.fact1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact2Series !== undefined) { this.fact2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact3Series !== undefined) { this.fact3Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact4Series !== undefined) { this.fact4Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
  }


  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show
    if(this.fact1Series !== undefined) { this.fact1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact2Series !== undefined) { this.fact2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact3Series !== undefined) { this.fact3Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact4Series !== undefined) { this.fact4Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
  }

  /** Combo Fact Chart | get selected items | can select multiple facts */
  public getSelectedChartFacts = (event: any) => {
    if(event.name === this.filterChartFactsTitle) {
      this.selectedChartFactItemNames = event.itemNames
      this.selectedChartFactItemIds = event.itemIds
    }

    this.changeDetector.detectChanges();
    if(this.fact1Series !== undefined) { this.fact1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact2Series !== undefined) { this.fact2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact3Series !== undefined) { this.fact3Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact4Series !== undefined) { this.fact4Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }

    this.feedChartWithData()
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log("basket-analysis | data: ", data)

          // this.maxYAxis = Math.max(...data.map(d=>d.Value)) * 1.2
          // console.log("this.maxYAxis: ", this.maxYAxis)

          this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
          // console.log("basket-analysis | preprocessed data: ", data)

          this.chartData = this.utilityService.replaceIdWithName3(this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue),
                          Columns.Account, Columns.Product, Columns.Period,
                          this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data,
                          this.selectedChartFactItemIds, this.filterChartFactsData)

          this.addCategoryAndDataLabelAndPenetration(this.chartData)
          // console.log("basket | chart data: ", this.chartData)
        }
      )
    })
  }

  handlePenetrationWarning() {
    this.changeDetector.detectChanges();
    if(this.fact1Series !== undefined) { this.fact1Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact2Series !== undefined) { this.fact2Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact3Series !== undefined) { this.fact3Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.fact4Series !== undefined) { this.fact4Series.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
  }

  private addCategoryAndDataLabelAndPenetration(chartData: any[]) {
    let inx = 0
    for(let i of chartData) {
      if(this.treeAccountIsMulti) {
        i[Ids.Category] = i[Columns.Account]
      } else {
        i[Ids.Category] = i[Columns.Product]
      }

      i[Ids.Series1] = this.selectedChartFactItemNames[0]
      i[Ids.Label1] = this.utilityService.myFormatNumber(i[this.selectedChartFactItemNames[0]], 1, 1)
      i[Ids.value1] = i[this.selectedChartFactItemNames[0]]

      i[Ids.Series2] = this.selectedChartFactItemNames[1]
      i[Ids.Label2] = this.utilityService.myFormatNumber(i[this.selectedChartFactItemNames[1]], 1, 1)
      i[Ids.value2] = i[this.selectedChartFactItemNames[1]]

      i[Ids.Series3] = this.selectedChartFactItemNames[2]
      i[Ids.Label3] = this.utilityService.myFormatNumber(i[this.selectedChartFactItemNames[2]], 1, 1)
      i[Ids.value3] = i[this.selectedChartFactItemNames[2]]

      i[Ids.Series4] = this.selectedChartFactItemNames[3]
      i[Ids.Label4] = this.utilityService.myFormatNumber(i[this.selectedChartFactItemNames[3]], 1, 1)
      i[Ids.value4] = i[this.selectedChartFactItemNames[3]]

      i[Ids.Penetration_SingleSeries] = this.utilityService.havePenetrationWarning(i[this.penetrationFactId])
      if(!this.hasPenetrationWarning && i[Ids.Penetration_SingleSeries]) {
        this.hasPenetrationWarning = true
      }

      i[Ids.index] = inx

      inx++
    }
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = this.treeAccountIsMulti ? Ids.Product : Ids.Account

    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields = this.selectedChartFactItemNames
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.MultiColumns, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    // console.log("single ppt data: ", this.chartData)
    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields = this.selectedChartFactItemNames
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster

    this.exportService.exportPPT(PPTIds.MultiColumns, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
                      .then(fileName => {
                        console.log(`Created Single PPT file: ${fileName}`);
                        this.isCreatingExport = false
                    });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNTS] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCTS] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.FACTS] = this.selectedChartFactItemNames.join(", ")
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    this.restService.filterData (
      this.sourceTable,
      accounts.map(i => i.Id),
      products.map(i => i.Id),
      this.getPeriodIds(),
      this.selectedChartFactItemIds
    )
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      // console.log("basket-analysis: multi ppt | data: ", data)

      this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})

      // console.log("basket-analysis | multippt | preprocessed data: ", data)

      let multiPptData = this.utilityService.replaceIdWithName3(this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue),
                            Columns.Account, Columns.Product, Columns.Period,
                            this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data,
                            this.selectedChartFactItemIds, this.filterChartFactsData)

      // console.log("basket-analysis: multi ppt | multiPptData: ", multiPptData)

      let header = this.getPPTHeaderTemplate()
      let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
      let dataseriesFields = this.selectedChartFactItemNames
      let options: any = {}
      options[PPTIds.master] = this.userDefinedPptMaster

      this.exportService.exportPPT(PPTIds.MultiColumns, this.dashboardTitle, accounts.map(i=>i.Name), products.map(i=>i.Name), header, options, singleTreeName, catAxisField, dataseriesFields, multiPptData)
                        .then(fileName => {
                          console.log(`Created Multi PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
    })
  }

  handleExportExcel() {
    // header
    let header: any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    header[this.cs.FACTS] = this.selectedChartFactItemNames.join(", ")
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    // table
    let columnValueMap: any = {}
    columnValueMap[this.cs.PRODUCT] = Columns.Product
    for(let fn of this.selectedChartFactItemNames) {
      columnValueMap[fn] = fn
    }

    this.exportService.exportExcel("Basket_Analysis", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.chartData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.FactIds] = this.selectedChartFactItemIds
    selection[UserSelectionIds.FactNames] = this.selectedChartFactItemNames
    selection[UserSelectionIds.Mode] = this.selectedModeItem
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
      if(!loadSelectionFromBackend) {
        return
      }

      // Switch (Mode)
      this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
      switch (this.selectedModeItem.Id) {
        case ModeIds.OneAccountMultiProducts:
          this.treeProductIsMulti = true
          this.callBackendToGetProductAndAllAccounts = false

          this.treeAccountIsMulti = false
          this.callBackendToGetAccountAndAllProducts = true
          break

        case ModeIds.OneProductMultiAccounts:
          this.treeProductIsMulti = false
          this.callBackendToGetProductAndAllAccounts = true

          this.treeAccountIsMulti = true
          this.callBackendToGetAccountAndAllProducts = false
          break
      }

      // Account Tree
      this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
        {
          Id: this.filterAccountData[0].Id,
          Name: this.filterAccountData[0].Name,
          Level: 0, // first level is 0
          Parent: "null"
        }
      ])
      if(!this.treeAccountIsMulti) {
        this.selectedAccountNodes = [this.selectedAccountNodes[0]]
      }
      this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
      this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

      // Product Tree
      this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
        {
          Id: this.filterProductData[0].Id,
          Name: this.filterProductData[0].Name,
          Level: 0,
          Parent: "null"
        }
      ])
      if(!this.treeProductIsMulti) {
        this.selectedProductNodes = [this.selectedProductNodes[0]]
      }
      this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
      this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

      // Period (Period 1)
      this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
        Id: this.filterPeriod1Data[0].Id,
        Name: this.filterPeriod1Data[0].Name
      })
      this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

      // Fact
      this.selectedChartFactItemIds = this.utilityService.getSelectionItem(selection, UserSelectionIds.FactIds, this.filterChartFactsData.length === 0 ? [] : [this.filterChartFactsData[0].Id])
      this.selectedChartFactItemNames = []
      for(let id of this.selectedChartFactItemIds) {
        let item = this.filterChartFactsData.filter(i=>i.Id === id)[0]
        item.Checked = true
        this.selectedChartFactItemNames.push(item.Name)
      }
      // console.log("selectedChartFactItemIds load: ", this.selectedChartFactItemIds, this.selectedChartFactItemNames, this.filterChartFactsData)

      // Shopper Group
      this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
        Id: this.filterShopperGroupData[0].Id,
        Name: this.filterShopperGroupData[0].Name
      })

      // Chart Setting
      this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
      this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)

  }

}
