import { Component, EventEmitter, Input, Output} from '@angular/core';

import { SelectItem, SelectItemChecked } from 'src/app/dashboard/shared/model/interfaces';


@Component({
  selector: 'app-single-select-radio',
  templateUrl: './single-select-radio.component.html',
  styleUrls: ['./single-select-radio.component.scss']
})
export class SingleSelectRadioComponent {
  @Input() public name!: string;
  @Input() public items: SelectItemChecked[] = [];
  @Input() public selected!: string;

  @Input() public disabled: boolean = false;

  @Output() getSelectedItemEvent = new EventEmitter<any>();

  public selectedRadio: string = "";
  private init = true

  constructor() { }

  onChang(item: any): void {
    // console.log("item radio: ", item, this.items)

    // the first call will be done when inializing the page, but this call should not take place. so here is a solution to ignore the first call.
    if(this.init) {
      this.init = false
      return
    }

    const selectedItem: SelectItem = {
      Id: '',
      Name: ''
    }
    selectedItem['Id'] = item.Id
    selectedItem['Name'] = item.Name

    const name = this.name
    // console.log("------------------------ radio item changed: ", name, selectedItem)
    this.getSelectedItemEvent.emit({name, selectedItem});
  }
}
