import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableOfFactsComponent } from './table-of-facts/table-of-facts.component';
import { TwoDimensionalComponent } from './two-dimensional/two-dimensional.component';
import { ReportsRoutingModule } from './reports.routing.module';
import { ThreeDimensionalComponent } from './three-dimensional/three-dimensional.component';
import { KpiComparisonComponent } from './kpi-comparison/kpi-comparison.component';
import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';


@NgModule({
  declarations: [
    TableOfFactsComponent,
    TwoDimensionalComponent,
    ThreeDimensionalComponent,
    KpiComparisonComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedDashboardComponentModule,
  ]
})
export class ReportsModule { }
