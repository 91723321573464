import { Injectable } from '@angular/core';
import { CsvFileTypes, IgxCsvExporterOptions, IgxCsvExporterService } from '@infragistics/igniteui-angular';
import { Workbook, WorkbookColorInfo, WorkbookFormat } from 'igniteui-angular-excel';
import pptxgen from 'pptxgenjs';


import { Columns, Ids, PPTIds } from '../model/constants';
import { ExcelUtility } from './excel-utility';
import { ConstantService } from './constant.service';
import { ConfigService } from './config.service';
import { Color } from 'igniteui-angular-core';


@Injectable({
  providedIn: 'root'
})
export class ExportService {
  private commonPPTOpts = {
    // chart position
    x: 1,
    y: 2.1,
    w: "80%",
    h: "60%",

    // legend
    showLegend: true,

    //valAxis
    valAxisTitleFontSize: 8,
    valAxisLabelFontSize: 8,
    // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',

    //catAxis
    catAxisLabelFontSize: 8,
    catAxisOrientation: "minMax",

    //dataLabel
    showValue: true,
    dataLabelFontSize: 8,
    dataLabelFormatCode: '#,##0.0',
    dataLabelColor: "000000",
  }

  private leftChartPPTOpts = {
    // chart position
    x: 1,
    y: 2.1,
    w: "40%",
    h: "60%",

    // legend
    showLegend: true,

    //valAxis
    valAxisTitleFontSize: 8,
    valAxisLabelFontSize: 8,
    // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',

    //catAxis
    catAxisLabelFontSize: 8,
    catAxisOrientation: "minMax",

    //dataLabel
    showValue: true,
    dataLabelFontSize: 8,
    dataLabelFormatCode: '#,##0.0',
    dataLabelColor: "000000",
  }

  private rightChartPPTOpts = {
    // chart position
    x: 6.5,
    y: 2.1,
    w: "40%",
    h: "60%",

    // legend
    showLegend: true,

    //valAxis
    valAxisTitleFontSize: 8,
    valAxisLabelFontSize: 8,
    // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',

    //catAxis
    catAxisLabelFontSize: 8,
    catAxisOrientation: "minMax",

    //dataLabel
    showValue: true,
    dataLabelFontSize: 8,
    dataLabelFormatCode: '#,##0.0',
    dataLabelColor: "000000",
  }

  constructor(
    private configService: ConfigService,
    private csvExportService: IgxCsvExporterService,
    private cs: ConstantService,
  ) { }


  // ------------------------- export ppt "kpi comparision" Start ------------------------------ //
  public createPPTKPIComparison(pptx: any, fileName: string, columnId: string, columnNames: string[], catAxisField: string, data: any[], header: any, options: any) {
    let pptMaster = options[PPTIds.master]
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    this.add_text_in_slide(slide, fileName, header)

    data = this.reverseArray(data)

    let uniqueColumnNames = [...new Set(columnNames)]
    for(let i = 0; i < uniqueColumnNames.length; i ++) {
      let chartData = []
      let chartDataRaw = data.filter(d => d[columnId] === uniqueColumnNames[i])
      let labels = chartDataRaw.map(d => d[catAxisField])

      chartData.push({
        name: this.cs.CURRENT_PERIOD,
        labels: labels,
        values: chartDataRaw.map(i => i[Ids.value_current])
      })

      chartData.push({
        name: this.cs.PERVIOUS_PERIOD,
        labels: labels,
        values: chartDataRaw.map(i => i[Ids.value_previous])
      })

      // console.log("chartData: ", chartData)

      let chartPositionOpts = {}

      if(uniqueColumnNames.length >= 7) {
        chartPositionOpts = {
          // chart position
          x: i <= 1? i * 2 : 2 + (i-1) * 1.2,
          y: 2,
          w: i === 0? "15%": "10%",
          h: i === 0? "65%": "58%",
        }
      } else {
        chartPositionOpts = {
          // chart position
          x: i <= 1? i * 2.7 : 2.7 + (i-1) * 2,
          y: 2,
          w: i === 0? "20%": "15%",
          h: i === 0? "65%": "60%",
        }
      }

      let opts = {
        // legend
        showLegend: i === 0? true: false,

         // title
        showTitle: true,
        title: uniqueColumnNames[i],
        titleFontSize: 6,

        //valAxis
        valAxisTitleFontSize: 6,
        valAxisLabelFontSize: 6,
        // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',

        //catAxis
        catAxisHidden: i === 0? false: true,
        catAxisLabelFontSize: 6,
        catAxisOrientation: "minMax",

        //dataLabel
        showValue: true,
        dataLabelFontSize: 6,
        dataLabelFormatCode: '#,##0.0',
        dataLabelColor: "000000",

        chartColors: this.configService.CHART_COLORS,  //there is always 2 colors needed, one for actual period, the other for previous period
        barDir: "bar",
        barGrouping: "clustered",
        valAxisMinVal: 0,
        valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "none", size: 0.5 },
        legendPos: "b",
      };

      this.create_single_chart_kpi_comparison(pptx, slide, chartData, {...chartPositionOpts, ...opts})
    }
  }

  public exportPPTKPIComparison(fileName: string, columnId: string, columnNames: string[], catAxisField: string, data: any[], header: any, options: any): Promise<string> {
    let pptx = new pptxgen();
    this.createPPTBasics(pptx)

    let pptMaster = options[PPTIds.master]
    this.createPPTMaster(pptx, pptMaster)

    this.createPPTKPIComparison(pptx, fileName, columnId, columnNames, catAxisField, data, header, options)
    return pptx.writeFile({ fileName: fileName });
  }

  private create_single_chart_kpi_comparison(pptx: any, slide: any, data:any[], opts: any) {
    slide.addChart(pptx.charts.BAR, data, opts);
  }
  // ------------------------- export ppt "kpi comparision" End ------------------------------ //


  // ------------------------- export ppt "demographics chart" Start ------------------------------ //
  public createPPTDemographicsChart(pptx: any, type: string, fileName: string, catAxisField: string, dataseriesFields: string[], data: any[], header:any, options: any) {
    let pptMaster = options[PPTIds.master]
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    let slideData = data

    switch (type) {
      case PPTIds.MultiColumns: {
        this.ppt_create_demographics_chart("col", "clustered", '#,##0', pptx, slide, fileName, catAxisField, dataseriesFields, slideData, header)
        break
      }
      case PPTIds.MultiBars: {
        this.ppt_create_demographics_chart("bar", "clustered", '#,##0', pptx, slide, fileName, catAxisField, dataseriesFields, slideData, header)
        break
      }
      case PPTIds.StackedColumns: {
        this.ppt_create_demographics_chart("col", "stacked", '#,##0.0', pptx, slide, fileName, catAxisField, dataseriesFields, slideData, header)
        break
      }
    }
  }

  public exportPPTDemographicsChart(type: string, fileName: string, catAxisField: string, dataseriesFields: string[], data: any[], header:any, options: any): Promise<string> {
    let pptx = new pptxgen();
    this.createPPTBasics(pptx)

    let pptMaster = options[PPTIds.master]
    this.createPPTMaster(pptx, pptMaster)

    this.createPPTDemographicsChart(pptx, type, fileName, catAxisField, dataseriesFields, data, header, options)

    return pptx.writeFile({ fileName: fileName });
  }

  private ppt_create_demographics_chart(barDir: string, barGrouping: string, dataLabelFormatCode:string, pptx:any, slide: any, fileName: string, catAxisField: string, dataseriesFields: string[], data: any[], header: any) {
    let chartData = []

    // not need to reverse array in case of bar chart, do not know why
    // if(barDir === "bar") {
    //   data = this.reverseArray(data)
    // }
    // console.log("ppt data: ", data, uniqueElementsOfDim)

    for(let sf of dataseriesFields) {
      let labels: string[] = []
      let factData: number[] = []

      labels = data.map(i => i[catAxisField])

      factData = data.map(i => i[sf])

      chartData.push({
        name: sf,
        labels: labels,
        values: factData,
      })
    }

    let len = dataseriesFields.length
    let chartColors = this.configService.CHART_COLORS.slice(0, len)  // get first N colors from the default color list. the N equals to the length of series.

    // console.log("ppt chartData: ", chartData)

    let opts = {
      // chart position
      y: 2.2,

      dataLabelFormatCode: dataLabelFormatCode,

      chartColors: chartColors,
      showLegend: true,
      legendFontSize: 8,
			barDir: barDir,
      barGrouping: barGrouping,
      valAxisMinVal: 0,
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.5 },
      legendPos: "b",
		};

    let stackedOpts = {}
    if(barGrouping === "stacked") {
      stackedOpts = {
        valAxisMaxVal: 100
      }
    }

    this.add_text_in_slide_wider(slide, fileName, header)
    slide.addChart(pptx.charts.BAR, chartData, {...this.commonPPTOpts, ...opts, ...stackedOpts});
  }
  // ------------------------- export ppt "demographics chart" End ------------------------------ //


  // ------------------------- export ppt Normal Start ------------------------------ //
  public createPPTBasics(pptx: pptxgen) {
    pptx.author = this.configService.PPT_AUTHOR;
    pptx.company = this.configService.PPT_COMPANY;
    pptx.layout = "LAYOUT_WIDE";
  }

  public createPPTMaster(pptx: pptxgen, pptMaster: string) {
    // create the ppt master
    if(pptMaster) {
      let master = "[" + pptMaster + "]"
      let obj = eval('(' + master + ')')   // convert string to object
      pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        background: { color: "FFFFFF" },
        objects: obj,
        slideNumber: { x: 0.2, y: "95%", fontSize: 10 },
      });
    }
  }

  // just create a slide, used in chart master
  public createPPT(pptx: pptxgen, type: string, fileName: string, accounts: string[], products: string[], headerTemplate: any, options:any, singleTreeName: string, catAxisField: string, dataseriesFields: string[], allSlidesDataOfChart1: any[], allSlidesDataOfChart2: any[]=[], allSlidesDataOfChart3: any[]=[], allSlidesDataOfChart4: any[]=[]) {
    let pptMaster = options[PPTIds.master]
    // console.log("createPPT pptMaster: ", pptMaster)

    let categoryValues: string[] = []
    let categoryKey: string = ""
    if(singleTreeName === 'Account') {
      categoryValues = accounts
      categoryKey = Columns.Account
    }
    else if(singleTreeName === 'Product') {
      categoryValues = products
      categoryKey = Columns.Product
    }

    for(let cv of categoryValues) {
      let slide: any
      if(pptMaster) {
        slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
      } else {
        slide = pptx.addSlide()
      }
      let oneSlideDataOfChart1 = allSlidesDataOfChart1.filter(d => d[categoryKey] === cv)
      let oneSlideDataOfChart2 = allSlidesDataOfChart2.filter(d => d[categoryKey] === cv)
      let oneSlideDataOfChart3 = allSlidesDataOfChart3.filter(d => d[categoryKey] === cv)
      let oneSlideDataOfChart4 = allSlidesDataOfChart4.filter(d => d[categoryKey] === cv)

      switch (type) {
        case PPTIds.MultiColumnsAndBarChart: {
          // Loyalty Simulation
          this.ppt_create_multi_column_and_bar_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
          break
        }

        case PPTIds.MultiColumns: {
          // Purchase Behavior & Basket Analysis & Promotion Performance
          this.ppt_create_multi_column_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
          break
        }
        case PPTIds.ColumnAndDot: {
          // Assortment Performance & Price Performance
          this.ppt_create_column_and_dot_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
          break
        }

        case PPTIds.ColumnAndLine: {
          // Loyalty & Propensity Ranking
          this.ppt_create_column_and_line_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
          break
        }

        // case "SingleColumn": {
        //   // market-shares
        //   this.ppt_create_single_bar_or_column_chart("col", pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
        //   break
        // }

        // case "SingleBar": {
        //   // market-shares
        //   this.ppt_create_single_bar_or_column_chart("bar", pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
        //   break
        // }

        case PPTIds.ThreeBarCharts: {
          // account-balance
          this.ppt_create_three_bar_chart("bar", pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
          break
        }

        // case "SinglePie": {
        // // market-shares
        //   this.ppt_create_single_pie_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1)
        //   break
        // }

        case PPTIds.TwoBarCharts: {
          // market-share-development
          this.ppt_create_two_bar_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1, oneSlideDataOfChart2)
          break
        }

        case PPTIds.TwoPieTwoBarCharts: {
          // distribution-top-x
          this.ppt_create_two_pie_two_bar_chart(pptx, slide, fileName, cv, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, oneSlideDataOfChart1, oneSlideDataOfChart2, oneSlideDataOfChart3, oneSlideDataOfChart4)
          break
        }
      }
    }
  }

  public exportPPT(type: string, fileName: string, accounts: string[], products: string[], headerTemplate: any, options:any, singleTreeName: string, catAxisField: string, dataseriesFields: string[], allSlidesDataOfChart1: any[], allSlidesDataOfChart2: any[]=[], allSlidesDataOfChart3: any[]=[], allSlidesDataOfChart4: any[]=[]): Promise<string> {
    let pptx = new pptxgen();
    this.createPPTBasics(pptx)

    let pptMaster = options[PPTIds.master]
    this.createPPTMaster(pptx, pptMaster)

    this.createPPT(pptx, type, fileName, accounts, products, headerTemplate, options, singleTreeName, catAxisField, dataseriesFields, allSlidesDataOfChart1, allSlidesDataOfChart2, allSlidesDataOfChart3, allSlidesDataOfChart4)

    return pptx.writeFile({ fileName: fileName });
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param leftData          used for chart left | a list of dictionary. it is the data for the chart of the slide
   * @param rightData         used for chart right| a list of dictionary. it is the data for the chart of the slide
   */
  private ppt_create_two_bar_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], leftData: any[], rightData: any[]) {
    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)
    this.add_text_in_slide(slide, fileName, header)

    // left chart - chart data
    let leftLabels: string[] = []
    let leftFactData: number[] = []
    let leftColors: string[] = []

    leftData = this.reverseArray(leftData)
    rightData = this.reverseArray(rightData)

    leftLabels = leftData.map(i => i[catAxisField])
    leftFactData = leftData.map(i => i[Columns.Value])  // could be -"value" or "Value"
    leftColors = leftData.map(i => i[Ids.Color])
    // console.log("create ppt | leftFactData: ", leftData, leftLabels, leftFactData, leftColors)

    // left chart - options
    let leftOpts = {
      showLegend: false,
			barDir: "bar",
      barGrouping: "clustered",
      showValAxisTitle: true,
      valAxisTitle: options[PPTIds.LeftValAxisTitle],
      valAxisMinVal: 0,
      valGridLine: { style: "none" },
      valAxisTitleFontSize: 8,
      valAxisLabelFontSize: 8,
      catAxisTitleFontSize: 8,
      catAxisLabelFontSize: 8,
      chartColors: leftColors,
		};

    let leftChartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.LeftValAxisTitle],
						labels: leftLabels,
						values: leftFactData,
            fill: leftColors
					},
				],
			},
		];

    slide.addChart(leftChartTypes, {...this.leftChartPPTOpts, ...this.getOptsForMaxValueIsHundred(options[PPTIds.MaxValueIsHundred]), ...leftOpts});

    // right chart - chart data
    let rightLabels: string[] = []
    let rightFactData: number[] = []
    let rightColors: string[] = []

    rightLabels = rightData.map(i => i[catAxisField])
    rightFactData = rightData.map(i => i["value"])  // not "Value" !!!
    rightColors = rightData.map(i => i[Ids.Color])

    // right chart - options
    let rightOpts = {
      chartColors: rightColors,
      showLegend: false,
			barDir: "bar",
      barGrouping: "clustered",
      showValAxisTitle: true,
      valAxisTitle: options[PPTIds.RightValAxisTitle],
      valGridLine: { style: "none" },
      catAxisLabelPos: 'low',
      catAxisHidden: false,
      valAxisTitleFontSize: 8,
      valAxisLabelFontSize: 8,
      catAxisTitleFontSize: 8,
      catAxisLabelFontSize: 8,
      // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',
		};

    let rightChartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.RightValAxisTitle],
						labels: rightLabels,
						values: rightFactData,
            fill: rightColors
					},
				],
			},
		];
    slide.addChart(rightChartTypes, {...this.rightChartPPTOpts, ...rightOpts});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   *
   * NOT Used anymore
   */
  private ppt_create_single_bar_or_column_chart(chartType:string, pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // create chart data
    if(chartType === "bar") {
      data = this.reverseArray(data)
    }

    let labels: string[] = []
    let factData: number[] = []
    labels = data.map(i => i[catAxisField])
    factData = data.map(i => i[Columns.Value])
    let chartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.ValAxisTitle],
						labels: labels,
						values: factData,
					},
				],
			},
		];

    // create options
    let opts = {
      chartColors: [this.configService.CHART_COLORS[0]],
      showLegend: false,
			barDir: chartType,
      barGrouping: "clustered",
      showValAxisTitle: true,
      valAxisTitle: options[PPTIds.ValAxisTitle],
      valAxisMinVal: 0,
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.5 },
		};

    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)

    this.add_text_in_slide(slide, fileName, header)
    slide.addChart(chartTypes, {...this.commonPPTOpts, ...this.getOptsForMaxValueIsHundred(options[PPTIds.MaxValueIsHundred]), ...opts});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   */
  private ppt_create_three_bar_chart(chartType:string, pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)
    this.add_text_in_slide_wider(slide, fileName, header)

    slide.addText(this.cs.FROM + " " + options[PPTIds.Account] + " " + this.cs.TO + " ...",       { x: "5%",  y: "26%", w: "25%", h: "3%", align: "center", color: "000000", fontSize: 10,});
    slide.addText(this.cs.FROM + " ... " + this.cs.TO + " " + options[PPTIds.Account],            { x: "30%", y: "26%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 10,});
    slide.addText(this.cs.NET_BALANCE + " " + options[PPTIds.Account],                            { x: "60%", y: "26%", w: "25%", h: "3%", align: "center", color: "000000", fontSize: 10,});

    data = this.reverseArray(data)

    // left chart
    let leftLabels: string[] = []
    let leftFactData: number[] = []
    leftLabels = data.map(i => i[catAxisField])
    leftFactData = data.map(i => i[Ids.Value_Account_To])
    let leftChartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.Dataseries],
						labels: leftLabels,
						values: leftFactData,
					},
				],
			},
		];

    let leftOpts = {
      // chart position
      x: 0.5,
      y: 2.1,
      w: "20%",
      h: "60%",

      // legend
      showLegend: true,
      legendPos: 'b',
      legendFontSize: 8,

      //valAxis
      valAxisHidden: true,
      valGridLine: {style: "none"},

      //catAxis
      catAxisHidden: true,
      catAxisLineShow: true,

      //dataLabel
      showValue: true,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0',
      dataLabelColor: "#000000",

      chartColors:[this.configService.COLOR_RED],
			barDir: chartType,
      barGrouping: "clustered",
		};

    slide.addChart(leftChartTypes, {...leftOpts});

    // middle chart
    let middleLabels: string[] = []
    let middleFactData: number[] = []
    middleLabels = data.map(i => i[catAxisField])
    middleFactData = data.map(i => i[Ids.Value_Account_From])
    let middleChartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.Dataseries],
						labels: middleLabels,
						values: middleFactData,
					},
				],
			},
		];

    let middleOpts = {
      // chart position
      x: 4,
      y: 2.1,
      w: "30%",
      h: "60%",

      // legend
      showLegend: true,
      legendPos: 'b',
      legendFontSize: 8,

      //valAxis
      valAxisHidden: true,
      valGridLine: {style: "none"},

      //catAxis
      catAxisHidden: false,
      catAxisLabelFontSize: 8,
      catAxisOrientation: "minMax",
      catAxisLabelPos: "low",
      catAxisLineShow: true,

      //dataLabel
      showValue: true,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0',
      dataLabelColor: "#000000",

      chartColors:[this.configService.COLOR_GREEN],
			barDir: chartType,
      barGrouping: "clustered",
		};

    slide.addChart(middleChartTypes, {...middleOpts});

    // right chart
    let rightLabels: string[] = []
    let rightFactData: number[] = []
    rightLabels = data.map(i => i[catAxisField])
    rightFactData = data.map(i => i[Ids.Value_Account_Net])
    let rightChartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.Dataseries],
						labels: rightLabels,
						values: rightFactData,
					},
				],
			},
		];

    let rightOpts = {
      // chart position
      x: 8,
      y: 2.1,
      w: "25%",
      h: "60%",

      // legend
      showLegend: true,
      legendPos: 'b',
      legendFontSize: 8,

      //valAxis
      valAxisHidden: true,
      valGridLine: {style: "none"},

      //catAxis
      catAxisHidden: true,
      catAxisLineShow: true,

      //dataLabel
      showValue: true,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0',
      dataLabelColor: "#000000",

      chartColors:[this.configService.COLOR_GREY],
			barDir: chartType,
      barGrouping: "clustered",
		};

    slide.addChart(rightChartTypes, {...rightOpts});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   *
   * Not Used anymore
   */
  private ppt_create_single_pie_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // create chart data
    let labels: string[] = []
    let factData: number[] = []
    labels = data.map(i => i[catAxisField])
    factData = data.map(i => i[Columns.Value])

    let chartData = [
      			{
      				name: options[PPTIds.ValAxisTitle],
      				labels: labels,
      				values: factData,
      			},
      		]

    // create options
    let opts = {
      chartColors: this.configService.CHART_COLORS,  // even there just need 1 color, it is ok to give a list of colors, maybe because it is a pie chart. (it would be a problem if it is a column chart, in case of a column chart, you have to give 1 color if only 1 color is needed)
      legendPos: "r",
      showLegend: true,

      showLeaderLines: false,
      showPercent: false,
      showValue: true,

      dataLabelFontSize: 10,
      dataLabelPosition: "bestFit", // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
		};

    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)

    this.add_text_in_slide(slide, fileName, header)

    slide.addChart(pptx.charts.PIE, chartData, {...this.commonPPTOpts, ...opts});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data1             used for chart1 | a list of dictionary. it is the data for the chart of the slide
   * @param data2             used for chart2 | a list of dictionary. it is the data for the chart of the slide
   * @param data3             used for chart3 | a list of dictionary. it is the data for the chart of the slide
   * @param data4             used for chart4 | a list of dictionary. it is the data for the chart of the slide
   *
   */
  private ppt_create_two_pie_two_bar_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data1: any[], data2: any[], data3: any[], data4: any[]) {
    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)
    this.add_text_in_slide_wider(slide, fileName, header)

    slide.addText(this.cs.EXPENDITURE_FOR + " " + options[PPTIds.period1],    { x: "3%", y: "26%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 10,});
    slide.addText(this.cs.LOST_EXPENSES,                                      { x: "3%", y: "28%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 8,});
    slide.addText(options[PPTIds.period1Value],                               { x: "3%", y: "30%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 8,});

    slide.addText(this.cs.LOST_EXPENDITURE_IN_PERCENT,                        { x: "30%", y: "26%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 10,});
    slide.addText(this.cs.LOST_EXPENDITURE_INDEX,                             { x: "60%", y: "26%", w: "20%", h: "3%", align: "center", color: "000000", fontSize: 10,});

    if(options[PPTIds.period2] !== "none") {
      slide.addText(this.cs.EXPENDITURE_FOR + " " + options[PPTIds.period2],  { x: "3%", y: "60%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 10,});
      slide.addText(this.cs.LOST_EXPENSES,                                    { x: "3%", y: "62%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 8,});
      slide.addText(options[PPTIds.period2Value],                             { x: "3%", y: "64%", w: "30%", h: "3%", align: "center", color: "000000", fontSize: 8,});
    }

    // pie chart period 1
    let labels1 = data1.map(i => i["Type"])
    let factData1 = data1.map(i => i[Columns.Value])
    let chartData1 = [
      {
        name: "Value",
        labels: labels1,
        values: factData1,
      },
    ]
    let opts1 = {
      // chart
      x: 0.5,
      y: 2.4,
      w: "30%",
      h: "30%",
      chartColors: [this.configService.CHART_COLORS[0], "#BFBFBF"],

      // legend
      showLegend: true,
      legendPos: "b",
      legendFontSize: 8,

      //dataLabel
      showValue: true,
      showPercent: false,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0.0',
      dataLabelColor: "000000",
      dataLabelPosition: "bestFit", // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
		};
    slide.addChart(pptx.charts.PIE, chartData1, {...opts1});

    // pie chart period 2
    let labels2 = data2.map(i => i["Type"])
    let factData2 = data2.map(i => i[Columns.Value])
    let chartData2 = [
      {
        name: "Value",
        labels: labels2,
        values: factData2,
      },
    ]
    let opts2 = {
      // chart
      x: 0.7,
      y: 5,
      w: "26%",
      h: "26%",
      chartColors: [this.configService.CHART_COLORS[1], "#BFBFBF"],

      // legend
      showLegend: true,
      legendPos: "b",
      legendFontSize: 8,

      //dataLabel
      showValue: true,
      showPercent: false,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0.0',
      dataLabelColor: "000000",
      dataLabelPosition: "bestFit", // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
		};
    slide.addChart(pptx.charts.PIE, chartData2, {...opts2});

    // bar chart middle
    data3 = this.reverseArray(data3)
    let uniqueElementsOfDim = [... new Set(dataseriesFields)]
    let chartData3 = []
    for(let up of uniqueElementsOfDim) {
      let labels = data3.map(i => i[Ids.Account_Competitor])
      let factData = data3.map(i => i[up])

      chartData3.push({
        name: up,
        labels: labels,
        values: factData,
      })
    }
    let len = uniqueElementsOfDim.length
    let chartColors = this.configService.CHART_COLORS.slice(0, len)  // get first N colors from the default color list. the N equals to the length of series.
    let opts3 = {
      // chart position
      x: 4.5,
      y: 2.1,
      w: "30%",
      h: "65%",

      // legend
      showLegend: true,
      legendPos: "b",
      legendFontSize: 8,

      //valAxis
      valAxisHidden: true,
      valGridLine: {style: "none"},
      // valAxisMinVal: 0,
      // valAxisTitleFontSize: 8,
      // valAxisLabelFontSize: 8,
      // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',

      //catAxis
      catAxisLabelFontSize: 8,
      catAxisOrientation: "minMax",

      //dataLabel
      showValue: true,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0.0',
      dataLabelColor: "000000",

      chartColors: chartColors,
			barDir: "bar",
      barGrouping: "clustered",
		};
    slide.addChart(pptx.charts.BAR, chartData3, {...opts3});

    // bar chart change
    uniqueElementsOfDim = [... new Set(dataseriesFields)]
    let chartData4 = []
    data4 = this.reverseArray(data4)
    for(let up of uniqueElementsOfDim) {
      let labels4 = data4.map(i => i[Ids.Account_Competitor])
      let factData4 = data4.map(i => i[up])

      chartData4.push({
        name: up,
        labels: labels4,
        values: factData4,
      })
    }
    len = uniqueElementsOfDim.length
    chartColors = this.configService.CHART_COLORS.slice(2, 2+len)  // get first N colors from the default color list. the N equals to the length of series.
    let opts4 = {
      // chart position
      x: 8.5,
      y: 2.1,
      w: "25%",
      h: "65%",

      // legend
      showLegend: true,
      legendPos: "b",
      legendFontSize: 8,

      //valAxis
      valAxisHidden: false,
      valAxisLineShow: false,
      // valGridLine: {style: "none"},
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.1 },
      valAxisMinVal: 0,
      valAxisMajorUnit: 100,
      valAxisTitleFontSize: 8,
      valAxisLabelFontSize: 8,
      // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',

      //catAxis
      catAxisHidden: true,
      catAxisLabelFontSize: 8,
      catAxisOrientation: "minMax",

      //dataLabel
      showValue: true,
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0.0',
      dataLabelColor: "000000",

      chartColors: chartColors,
			barDir: "bar",
      barGrouping: "clustered",
		};
    slide.addChart(pptx.charts.BAR, chartData4, {...opts4});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   */
  private ppt_create_column_and_dot_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // create data for chart
    let labels: string[] = []
    let factData: number[] = []
    let benchmarkData: number[] = []
    labels = data.map(i => i[catAxisField])
    factData = data.map(i => i[Ids.value1])
    benchmarkData = data.map(i => i[Ids.value2])

    // console.log(catAxisField, options, labels, factData, benchmarkData)

    // create options of the chart
    let opts = {
      barDir: "col",

      //catAxis
      catAxisLabelFontSize: 8,
      catAxisOrientation: "minMax",

      // legend
      showLegend: true,
      legendPos: "b",

      //dataLabel
      showValue: true,
      dataLabelFontSize: 8,
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.5 },

			valAxes: [
				{
					showValAxisTitle: true,
					valAxisTitle: options[PPTIds.ValAxisTitle],
          valAxisMaxVal: 100,
          valAxisMajorUnit: 10,
          valAxisMinVal: 0,
          valAxisTitleFontSize: 8,
          valAxisLabelFontSize: 8,
          // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',
				},
				{
					showValAxisTitle: true,
					valAxisTitle: options[PPTIds.ValAxisTitle2],
					valGridLine: { style: "none" },
          valAxisMinVal: 0,
          valAxisTitleFontSize: 8,
          valAxisLabelFontSize: 8,
          // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #,##0.0',
				},
			],

			catAxes: [
				{
					catAxisTitle: "Primary Category Axis",
				},
				{
					catAxisHidden: true,
				},
			],
		};

    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)

    let chartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.ValAxisTitle],
						labels: labels,
						values: factData,
					},
				],
				options: {
					chartColors: this.configService.CHART_COLORS.slice(0,1),
          barGrouping: "stacked",
          dataLabelColor: "000000",
          dataLabelFormatCode: '#,##0.0',
				},
			},
			{
				type: pptx.charts.LINE,
				data: [
					{
						name: options[PPTIds.ValAxisTitle2],
						labels: labels,
						values: benchmarkData,
					},
				],
				options: {
					secondaryValAxis: true,
					secondaryCatAxis: true,
          lineSize: 0,
          chartColors: [this.configService.CHART_COLORS[1]],
          showValAxisTitle: true,
          showLabel: true,
          dataLabelColor: this.configService.CHART_COLORS[1],
          lineDataSymbol: "diamond",
          dataLabelFormatCode: options[PPTIds.DataLabelFormatCode2],
				},
			},
		];

    // console.log("chartTypes: ", header, chartTypes, {...this.commonPPTOpts, ...opts})
    this.add_text_in_slide(slide, fileName, header)
    slide.addChart(chartTypes, {...this.commonPPTOpts, ...opts});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   */
  private ppt_create_column_and_line_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // create data for chart
    let labels: string[] = []
    let factData: number[] = []
    let benchmarkData: number[] = []
    labels = data.map(i => i[catAxisField])
    factData = data.map(i => i[Columns.Value])
    benchmarkData = data.map(i => i[this.cs.BENCHMARK])

    // create options of the chart
    let opts = {
			barDir: "col",
      valAxisMaxVal: 100,
			valAxisMajorUnit: 10,
      valAxisMinVal: 0,
      // catAxisLineColor: "#ff0505",
      // valAxisLineColor: "#0ddb55",
      catGridLine: { color: this.configService.STROKE_COLOR_CATEGORY.replace("#", ""), style: "solid", size: 0.5 },
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.5 },
      legendPos: "b",
			valAxes: [
				{
					showValAxisTitle: false,
					valAxisTitle: options[PPTIds.ValAxisTitle],
				},
				{
					showValAxisTitle: false,
					valAxisTitle: "Secondary Value Axis",
					valGridLine: { style: "none" },
				},
			],

			catAxes: [
				{
					catAxisTitle: "Primary Category Axis",
				},
				{
					catAxisHidden: true,
				},
			],
		};

    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)

    let chartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.ValAxisTitle],
						labels: labels,
						values: factData,
					},
				],
				options: {
					chartColors: this.configService.CHART_COLORS.slice(0,1),
					barGrouping: "stacked",
				},
			},
			{
				type: pptx.charts.LINE,
				data: [
					{
						name: this.cs.BENCHMARK,
						labels: labels,
						values: benchmarkData,
					},
				],
				options: {
					barGrouping: "standard",
					secondaryValAxis: !!opts.valAxes,
					secondaryCatAxis: !!opts.catAxes,
          lineDataSymbol: "none",
          chartColors: [this.configService.BENCHMARK_COLOR],
          showValue: false,
				},
			},
		];

    this.add_text_in_slide(slide, fileName, header)
    slide.addChart(chartTypes, {...this.commonPPTOpts, ...opts});
  }

  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   */
  private ppt_create_multi_column_and_bar_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)
    this.add_text_in_slide(slide, fileName, header)

    // left chart
    let leftChartData = []
    for(let ds of dataseriesFields) {
      let leftLabels: string[] = data.map(i => i[catAxisField])
      let leftFactData: number[] = data.map(i => i[ds])
      leftChartData.push({
        name: ds,
        labels: leftLabels,
        values: leftFactData,
      })
    }
    // console.log("leftChartData: ", leftChartData)

    // update options
    let chartColors = this.configService.CHART_COLORS.slice(0, dataseriesFields.length) // get first N colors from the default color list. the N equals to the length of series
    let leftOpts = {
      chartColors: chartColors,
			barDir: "col",
      barGrouping: "clustered",
      valAxisMinVal: 0,
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.5 },
      legendPos: "b",
      valAxisTitle: options[PPTIds.LeftValAxisTitle],
      valAxisTitleFontSize: 8,
      valAxisLabelFontSize: 8,
      catAxisTitleFontSize: 8,
      catAxisLabelFontSize: 8,
		};

    slide.addChart(pptx.charts.BAR, leftChartData, {...this.leftChartPPTOpts, ...leftOpts, ...this.getOptsForMaxValueIsHundred(options[PPTIds.MaxValueIsHundred])});

    // right chart - chart data
    data = this.reverseArray(data)

    let rightLabels: string[] = []
    let rightFactData: number[] = []
    let rightColors: string[] = []

    rightLabels = data.map(i => i[catAxisField])
    rightFactData = data.map(i => i["value"])  // not "Value" !!!
    rightColors = data.map(i => i[Ids.Color])

    // right chart - options
    let rightOpts = {
      chartColors: rightColors,
      showLegend: false,
			barDir: "bar",
      barGrouping: "clustered",
      showValAxisTitle: true,
      valAxisTitle: options[PPTIds.RightValAxisTitle],
      valGridLine: { style: "none" },
      catAxisLabelPos: 'low',
      valAxisTitleFontSize: 8,
      valAxisLabelFontSize: 8,
      catAxisTitleFontSize: 8,
      catAxisLabelFontSize: 8,
      // valAxisLabelFormatCode: '[>999999] #,,"M"; [>999] #,"K"; #',
		};

    let rightChartTypes = [
			{
				type: pptx.charts.BAR,
				data: [
					{
						name: options[PPTIds.RightValAxisTitle],
						labels: rightLabels,
						values: rightFactData,
            fill: rightColors
					},
				],
			},
		];
    slide.addChart(rightChartTypes, {...this.rightChartPPTOpts, ...this.rightChartPPTOpts, ...rightOpts});
  }


  /**
   *
   * @param pptx
   * @param slide
   * @param fileName          used for header | will be inserted into the header of the slide
   * @param categoryValue     used for header | will be used to replace the placeholder in headerTemplate
   * @param accounts          used for header | will be used to replace the placeholder in headerTemplate
   * @param products          used for header | will be used to replace the placeholder in headerTemplate
   * @param headerTemplate    used for header | a dictionary, which contains all the header information for this slide
   *
   * @param options           used for chart options | a dictionary, which contains all the header information for this slide
   *
   * @param singleTreeName          used for header & chart | either "Account" or "Product". If "Account", then all the slides (if there are multiple slides) will be filtered by a particular account. Similar for "Product"
   * @param dataseriesFields  used for chart | a list of strings. each string is a field-name, which exists in each item/row of the data
   * @param data              used for chart | a list of dictionary. it is the data for the chart of the slide
   */
  private ppt_create_multi_column_chart(pptx:any, slide: any, fileName: string, categoryValue: string, accounts: string[], products: string[], headerTemplate: any, options: any, singleTreeName: string, catAxisField: string, dataseriesFields: string[]=[], data: any[]) {
    // create data for chart
    let chartData = []
    for(let ds of dataseriesFields) {
      let labels: string[] = data.map(i => i[catAxisField])
      let factData: number[] = data.map(i => i[ds])
      chartData.push({
        name: ds,
        labels: labels,
        values: factData,
      })
    }
    // console.log("chartData: ", chartData)

    // update header
    let header = this.updateHeader(headerTemplate, singleTreeName, categoryValue, accounts, products)

    // update options
    let chartColors = this.configService.CHART_COLORS.slice(0, dataseriesFields.length) // get first N colors from the default color list. the N equals to the length of series
    let opts = {
      chartColors: chartColors,
			barDir: "col",
      barGrouping: "clustered",
      valAxisMinVal: 0,
      valGridLine: { color: this.configService.STROKE_COLOR_VALUE.replace("#", ""), style: "solid", size: 0.5 },
      legendPos: "b",
		};

    this.add_text_in_slide(slide, fileName, header)
    slide.addChart(pptx.charts.BAR, chartData, {...this.commonPPTOpts, ...opts, ...this.getOptsForMaxValueIsHundred(options[PPTIds.MaxValueIsHundred])});
  }
  // ------------------------- export ppt Normal End ------------------------------ //

  // ------------------------- export ppt Utilities Start ------------------------------ //
  private reverseArray(arr: any[]) {
    let result: any[] = []
    for(let i = arr.length - 1; i >= 0 ; i--) {
      result.push(arr[i])
    }
    return result
  }

  private updateHeader(headerTemplate: any, singleTreeName: string, categoryValue: string, accounts: string[], products: string[]) {
    let header = Object.assign({}, headerTemplate);
    let keys = Object.keys(header)
    for(let i = 0; i < keys.length; i++) {
      let key = keys[i]
      if(header[key] === Ids.Accounts_Placeholder) {
        header[key] = singleTreeName === Ids.Account ? categoryValue : accounts.join(", ")
      } else if(header[key] === Ids.Products_Placeholder) {
        header[key] = singleTreeName === Ids.Account ? products.join(", ") : categoryValue
      }
    }
    return header
  }

  private getOptsForMaxValueIsHundred(maxValueIsHundred: boolean) {
    if(maxValueIsHundred) {
      return {
        valAxisMaxVal: 100,
        valAxisMajorUnit: 10,
      }
    } else {
      return {}
    }
  }

  public add_text_in_slide(slide: any, fileName: string, header: any) {
    let keys = Object.keys(header)

    slide.addText(fileName,                     { x: "10%", y: "6%",  w: "90%", h: "5%", align: "left", color: this.configService.PPT_FONTCOLOR, fontSize: this.configService.PPT_FONTSIZE_TITLE, bold: true});
    let yValue = 0
    for(let i = 0; i< keys.length; i++) {
      let key = keys[i]
      yValue = 10 + i*3
      slide.addText("" + key + ": ",            { x: "10%", y: yValue.toString() + "%", w: "15%", h: "3%", align: "left", color: this.configService.PPT_FONTCOLOR, fontSize: this.configService.PPT_FONTSIZE_TEXT,});
      slide.addText("" + header[key],           { x: "25%", y: yValue.toString() + "%", w: "75%", h: "3%", align: "left", color: this.configService.PPT_FONTCOLOR, fontSize: this.configService.PPT_FONTSIZE_TEXT,});
    }
  }

  public add_text_in_slide_wider(slide: any, fileName: string, header: any) {
    let keys = Object.keys(header)

    slide.addText(fileName,                     { x: "10%", y: "6%",  w: "90%", h: "5%", align: "left", color: this.configService.PPT_FONTCOLOR, fontSize: this.configService.PPT_FONTSIZE_TITLE, bold: true});
    let yValue = 0
    for(let i = 0; i< keys.length; i++) {
      let key = keys[i]
      yValue = 10 + i*3
      slide.addText("" + key + ": ",            { x: "10%", y: yValue.toString() + "%", w: "25%", h: "3%", align: "left", color: this.configService.PPT_FONTCOLOR, fontSize: this.configService.PPT_FONTSIZE_TEXT,});
      slide.addText("" + header[key],           { x: "35%", y: yValue.toString() + "%", w: "65%", h: "3%", align: "left", color: this.configService.PPT_FONTCOLOR, fontSize: this.configService.PPT_FONTSIZE_TEXT,});
    }
  }
  // ------------------------- export ppt Utilities End ------------------------------ //


  // ------------------------- export Excel Start ------------------------------ //
  /**
   *
   * @param excelFileName
   * @param selectedAccounts
   * @param selectedProducts
   * @param selectedPeriods
   * @param selectedShopperGroup
   * @param selectedFacts
   * @param gridDataView
   *
   * Export the data of the PPT Chart
   */
  public exportExcel(Dashboard: string, hasPenetrationWarning: boolean = false, hidePenetrationWarning: boolean = false, type:string, excelFileName: string, data: any[] , header: any, columnValueMap: any, data2: any[] = [], columnValueMap2: any= {}, tablePeriodColumns: any[]= [], dashboard: string ="") {
    const wb = new Workbook(WorkbookFormat.Excel2007);
    const ws = wb.worksheets().add(this.cs.TABLE);

    //nsole.log("DashboardX", Dashboard)
    //console.log(type)

    switch (type) {
      case Ids.table: {
        // the normal one
        this.createExcelTable(Dashboard, ws, data, header, columnValueMap, data2, columnValueMap2, hasPenetrationWarning, hidePenetrationWarning, tablePeriodColumns)
        break
      }

      case Ids.twoTables: {
        // used in distribution-top-x
        this.createExcelTwoTables(Dashboard, ws, header, data, columnValueMap, data2, columnValueMap2, hasPenetrationWarning, hidePenetrationWarning )
        break
      }
    }

    ws.columns(0).autoFitWidth()
    let canSave = wb != null;
    return this.workbookSave(canSave, wb, excelFileName)
  }

   /** Use in Purchase Behavoir */
   public exportPivot(hidePenetrationWarning: boolean, excelFileName: string, header: any, row0:any[], row1: any[], dataRows: any[][], PenetrationRows: any[]) {
    const wb = new Workbook(WorkbookFormat.Excel2007);
    const ws = wb.worksheets().add(this.cs.TABLE);
    this.addHeaderInExcel(ws, header)

    let penetration_warning_color = new Color();
    penetration_warning_color.colorString = "#FF6E08";
    
    //console.log("ExportPivot")
    // write row0 to row3
    for(let c = 0; c < row0.length; c ++) {
      ws.rows(6).setCellValue(c, row0[c])
      ws.rows(6).cells(c).cellFormat.font.bold = true
    }

    for(let c = 0; c < row1.length; c ++) {
      ws.rows(7).setCellValue(c, row1[c])
      ws.rows(7).cells(c).cellFormat.font.bold = true
    }

    // write data
    for(let r = 0; r < dataRows.length; r ++) {
      let dataRow = dataRows[r]
      let row = ws.rows(8 + r)
      for(let c = 0; c < dataRow.length; c ++) {
        row.setCellValue(c, dataRow[c])
       // console.log(dataRow[c])
      // console.log(r+1)
        if(c === 0) {
          row.cells(c).cellFormat.font.bold = true
        }
        else{
          if(PenetrationRows.indexOf(r+1) !== -1 && hidePenetrationWarning === false){
            row.cells(c).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
          }          
        }
      }
    }

    ws.columns(0).autoFitWidth()

    ws.columns(1).cellFormat.formatString = "#.##0,00";
    ws.columns(2).cellFormat.formatString = "#.##0,00";    

    let canSave = wb != null;
    return this.workbookSave(canSave, wb, excelFileName)
  }

  /** Use in table of facts, the pivot 1 */
  public exportPivot1(hidePenetrationWarning: boolean = false, Penetration:any[], excelFileName: string, header: any, row0:any[], row1: any[], row2: any[], row3: any[], dataRows: any[][]) {
    const wb = new Workbook(WorkbookFormat.Excel2007);
    const ws = wb.worksheets().add(this.cs.TABLE);
    this.addHeaderInExcel(ws, header)

    let penetration_warning_color = new Color();
    penetration_warning_color.colorString = "#FF6E08";

    for(let i = 0; i < Penetration.length; i++) {
      if(hidePenetrationWarning === false){      
        ws.rows(Penetration[i]["row"] + 9).cells(Penetration[i]["col"] + 0).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)          
      }
    }

    // write row0 to row3
    for(let c = 0; c < row0.length; c ++) {
      ws.rows(6).setCellValue(c, row0[c])
      ws.rows(6).cells(c).cellFormat.font.bold = true
    }

    for(let c = 0; c < row1.length; c ++) {
      ws.rows(7).setCellValue(c, row1[c])
      ws.rows(7).cells(c).cellFormat.font.bold = true
    }

    for(let c = 0; c < row2.length; c ++) {
      ws.rows(8).setCellValue(c, row2[c])
      ws.rows(8).cells(c).cellFormat.font.bold = true
    }

    for(let c = 0; c < row3.length; c ++) {
      ws.rows(9).setCellValue(c, row3[c])
      ws.rows(9).cells(c).cellFormat.font.bold = true
    }

    // write data
    for(let r = 0; r < dataRows.length; r ++) {
      let dataRow = dataRows[r]
      let row = ws.rows(10 + r)
      for(let c = 0; c < dataRow.length; c ++) {
        row.setCellValue(c, dataRow[c])
        if(c === 0) {
          row.cells(c).cellFormat.font.bold = true
        }
      }
    }

    for(let j = 0; j < 500; j++) {
      ws.columns(j).cellFormat.formatString = "#.##0,00";
      ws.columns(j).autoFitWidth()
    }      

    let canSave = wb != null;
    return this.workbookSave(canSave, wb, excelFileName)
  }

  /** Use in table of facts, the pivot 1 */
  public exportPivot2(hidePenetrationWarning: boolean = false, Penetration:any[],excelFileName: string, header: any, row0:any[], row1: any[], row2: any[], dataRows: any[][]) {
    const wb = new Workbook(WorkbookFormat.Excel2007);
    const ws = wb.worksheets().add(this.cs.TABLE);
    this.addHeaderInExcel(ws, header)
    //console.log("ExportPivot2")

    let penetration_warning_color = new Color();
    penetration_warning_color.colorString = "#FF6E08";

    for(let i = 0; i < Penetration.length; i++) {
      if(hidePenetrationWarning === false){      
        ws.rows(Penetration[i]["row"] + 8).cells(Penetration[i]["col"] + 1).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)          
      }
    }


    // write row0 to row2
    for(let c = 0; c < row0.length; c ++) {
      ws.rows(6).setCellValue(c, row0[c])
      ws.rows(6).cells(c).cellFormat.font.bold = true
    }

    for(let c = 0; c < row1.length; c ++) {
      ws.rows(7).setCellValue(c, row1[c])
      ws.rows(7).cells(c).cellFormat.font.bold = true
    }

    for(let c = 0; c < row2.length; c ++) {
      ws.rows(8).setCellValue(c, row2[c])
      ws.rows(8).cells(c).cellFormat.font.bold = true
    }

    //console.log("dataRows", dataRows)
    // write data
    for(let r = 0; r < dataRows.length; r ++) {
      let dataRow = dataRows[r]
      let row = ws.rows(9 + r)
      for(let c = 0; c < dataRow.length; c ++) {
        row.setCellValue(c, dataRow[c])
        if(c <= 1) {
          row.cells(c).cellFormat.font.bold = true
        }
      }
    }

    //console.log("dataRows[0].length", dataRows[0].length)

    for(let a = 0; a < dataRows[0].length; a++){
      ws.columns(a).cellFormat.formatString = "#.##0,00";
      ws.columns(a).autoFitWidth()
    }

    ws.columns(0).autoFitWidth()
    let canSave = wb != null;
    return this.workbookSave(canSave, wb, excelFileName)
  }

  private createExcelTable(dashboard: string = "", ws: any, data: any[], header: any, columnValueMap: any, data2: any[] = [], columnValueMap2: any = {}, hasPenetrationWarning: boolean, hidePenetrationWarning: boolean, tablePeriodColumns: any[]   ) {
    let headerLength = Object.keys(header).length
    this.addHeaderInExcel(ws, header)

    let penetration_warning_color = new Color();
    penetration_warning_color.colorString = "#FF6E08";

    const colRow = ws.rows(headerLength + 1)
    let columns = Object.keys(columnValueMap)


   //console.log("Dashboard", dashboard)

    for(let i = 0; i < columns.length; i++) {
      colRow.setCellValue(i, columns[i])
      ws.rows(headerLength + 1).cells(i).cellFormat.font.bold = true      
    }
    
    for(let y = 0; y < tablePeriodColumns.length; y++) {
      if(tablePeriodColumns[y]["period"] !== 'undefined') {
        if(tablePeriodColumns[y].hasOwnProperty("period")){
          let per = tablePeriodColumns[y]["period"].slice(0,4)
          for(let x = 0; x < data.length; x++) {
            if(per === 'PER1' && data[x]["Penetration_PER1"] === true && hidePenetrationWarning === false){
              ws.rows(5+x+1).cells(y+1).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)          
            }
            if(per === 'PER2' && data[x]["Penetration_PER2"] === true && hidePenetrationWarning === false){
              ws.rows(5+x+1).cells(y+1).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)          
            }            
            if(per === 'PER3' && data[x]["Penetration_PER3"] === true && hidePenetrationWarning === false){
              ws.rows(5+x+1).cells(y+1).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)          
            }
            if(per === 'PER4' && data[x]["Penetration_PER4"] === true && hidePenetrationWarning === false){
              ws.rows(5+x+1).cells(y+1).cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)          
            }                                    
          }
        }
      } 
    }      

    for(let j = 0; j < data.length; j++) {
      let row = ws.rows(headerLength + 2 + j)
      let keyCount = 0
      let col = 2
      for(let key in columnValueMap) {        
        let keyInData = columnValueMap[key]
        row.setCellValue(keyCount, data[j][keyInData])      
        if(data[j]["Penetration_SingleSeries"] === true && hidePenetrationWarning === false){
          row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
        }
        keyCount = keyCount + 1
        col = col + 1
      }
    }


    if(dashboard === 'Definition_Key_Facts' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 16; j++) {
        let row = ws.rows(7 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
      }
    }


    
    if(dashboard === 'Structure_Account_Clients' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 16; j++) {
        let row = ws.rows(6 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
      }
    }

    if(dashboard === 'MarketShare_Components' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 16; j++) {
        let row = ws.rows(7 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
      }
    }


    if(dashboard === 'Decomposition_Tree' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 16; j++) {
        let row = ws.rows(6 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
      }
    }

    if(dashboard === 'Account_Balance' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 16; j++) {
        let row = ws.rows(8 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
      }
    }


    if(dashboard === 'KPI_Comparison' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 4; j++) {
        let row = ws.rows(0 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
        
      }
    }

    if(dashboard === 'Definition_Key_Facts'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
    }    

    if(dashboard === 'Analysis_Strength_Weaknesses'){
      ws.columns(2).cellFormat.formatString = "#.##0,00";
      ws.columns(3).cellFormat.formatString = "#.##0,00";
      ws.columns(4).cellFormat.formatString = "#.##0,00";
    }

    if(dashboard === 'Structure_Account_Clients'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }

    if(dashboard === 'MarketShare_Development'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }

    if(dashboard === 'Loyalty_Simulation'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }    

    if(dashboard === 'Decomposition_Tree'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
      ws.columns(3).cellFormat.formatString = "#.##0,00";
    }   

    if(dashboard === 'Account_Balance'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
      ws.columns(3).cellFormat.formatString = "#.##0,00";
    }   
    
    if(dashboard === 'Price_Performance'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }        

    if(dashboard === 'Promotion_Performance'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }    

    if(dashboard === 'Assortment_Performance'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }        
    
    if(dashboard === 'Demographics_Chart'){
      for(let j = 0; j < 100; j++) {
        ws.columns(j).cellFormat.formatString = "#.##0,00";
      }      
    }        
    
    if(dashboard === 'Table_Demographics'){
      for(let j = 0; j < 100; j++) {
        ws.columns(j).cellFormat.formatString = "#.##0,00";
      }      
    }        

    if(dashboard === 'Basket_Analysis'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";      
    }        

    if(dashboard === 'MarketShare_Components'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }

    if(dashboard === 'Propensity_Ranking'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }   

    if(dashboard === 'Price_Performance'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
    }

    if(dashboard === 'Table_Of_Facts'){
      for(let j = 0; j < 500; j++) {
        ws.columns(j).cellFormat.formatString = "#.##0,00";
        ws.columns(j).autoFitWidth()
      }      
    }       


    if(dashboard === 'KPI_Comparison'){
      for(let j = 0; j < 500; j++) {
        ws.columns(j).cellFormat.formatString = "#.##0,00";
        ws.columns(j).autoFitWidth()
      }      
    }        


    

    let columns2 = Object.keys(columnValueMap2)
    
    for(let i = 0; i < columns2.length; i++) {
      colRow.setCellValue(columns.length + i, columns2[i])
   
      ws.rows(headerLength + 1).cells(columns.length + i).cellFormat.font.bold = true
    }

    // add the data of data2
    for(let j = 0; j < data2.length; j++) {
      let row = ws.rows(headerLength + 2 + j)
      let keyCount2 = 0 + columns.length
      for(let key in columnValueMap2) {
        let keyInData2 = columnValueMap2[key]
        row.setCellValue(keyCount2, data2[j][keyInData2])
        keyCount2 = keyCount2 + 1
      }
    }
  }


  private createExcelTwoTables(dashboard: string, ws: any, header: any, data1: any[], columnValueMap1: any, data2: any[] = [], columnValueMap2: any = {}, hasPenetrationWarning: boolean, hidePenetrationWarning: boolean) {
    // add header
    this.addHeaderInExcel(ws, header)
    let headerLength = Object.keys(header).length

    let penetration_warning_color = new Color();
    penetration_warning_color.colorString = "#FF6E08";

    // add table 1
    let colRow1 = ws.rows(headerLength + 1)
    let columns1 = Object.keys(columnValueMap1)

    for(let i = 0; i < columns1.length; i++) {
      colRow1.setCellValue(i, columns1[i])
      ws.rows(headerLength + 1).cells(i).cellFormat.font.bold = true      
    }

    //console.log(data1)

    for(let j = 0; j < data1.length; j++) {
      let row1 = ws.rows(headerLength + 2 + j)
      let keyCount1 = 0
      for(let key in columnValueMap1) {
        let keyInData = columnValueMap1[key]
        row1.setCellValue(keyCount1, data1[j][keyInData])
        keyCount1 = keyCount1 + 1
      }
    }

    // add table 2
    // console.log("data2: ", data2, columnValueMap2)
    let colRow2 = ws.rows(headerLength + 1 + 3)
    let columns2 = Object.keys(columnValueMap2)

    for(let i = 0; i < columns2.length; i++) {
      colRow2.setCellValue(i, columns2[i])
      ws.rows(headerLength + 1 + 3).cells(i).cellFormat.font.bold = true
    }
    // console.log("colRow2: ", headerLength + 1 + 3, colRow2)

    for(let j = 0; j < data2.length; j++) {
      let row2 = ws.rows(headerLength + 1 + 3 + 1 + j)
      let keyCount2 = 0
      for(let key in columnValueMap2) {
        let keyInData = columnValueMap2[key]
        row2.setCellValue(keyCount2, data2[j][keyInData])
        keyCount2 = keyCount2 + 1
      }
    }

    if(dashboard === 'Distribution_TopX' && hidePenetrationWarning === false && hasPenetrationWarning === true){
      for(let j = 0; j < 16; j++) {
        let row = ws.rows(8 + j)
        row.cellFormat.font.colorInfo = new WorkbookColorInfo(penetration_warning_color)
      }
    }

    if(dashboard === 'Distribution_TopX'){
      ws.columns(1).cellFormat.formatString = "#.##0,00";
      ws.columns(2).cellFormat.formatString = "#.##0,00";
      ws.columns(3).cellFormat.formatString = "#.##0,00";
      ws.columns(4).cellFormat.formatString = "#.##0,00";
    }        



  }
  // ------------------------- export Excel End ------------------------------ //


  // ------------------------- export Excel Utilities Start ------------------------------ //
  private workbookSave(canSave: boolean, wb: Workbook, excelFileName: string): Promise<string> {
    if (canSave) {
        return ExcelUtility.save(wb, excelFileName);
    } else {
        return new Promise((resolve, reject) => {
        resolve("Promise Resolved");
    })
    }
  }

  private addHeaderInExcel(ws: any, header: any) {
    let rowCount = 0
    for (const[key, value] of Object.entries(header)) {
      let row = ws.rows(rowCount)
      row.setCellValue(0, key);
      row.setCellValue(1, value);
      ws.rows(rowCount).cells(0).cellFormat.font.bold = true
      rowCount = rowCount + 1
    }
  }
  // ------------------------- export Excel Utilities End ------------------------------ //


  /**
   *
   * @param csvFileName the name of the csv file
   * @param data the data to export, such as
   * [
        { Name: 'Eric Ridley', Age: '26' },
        { Name: 'Alanis Brook', Age: '22' },
        { Name: 'Jonathan Morris', Age: '23' }
     ]
   */
  public exportCSV(csvFileName: string, data: any[]): void {
    const opt: IgxCsvExporterOptions = new IgxCsvExporterOptions(csvFileName, CsvFileTypes.CSV);
    this.csvExportService.exportData(data, opt);
  }
}

