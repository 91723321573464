import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IgxCheckboxComponent, IgxTreeComponent, IgxTreeNode, IgxTreeSearchResolver, ITreeNodeSelectionEvent } from '@infragistics/igniteui-angular';

import { TreeNode } from '../../../../model/interfaces';
import { ConstantService } from '../../../../services/constant.service';

@Component({
  selector: 'app-tree-with-checkbox-cascading',
  templateUrl: './tree-with-checkbox-cascading.component.html',
  styleUrls: ['./tree-with-checkbox-cascading.component.scss']
})
export class TreeWithCheckboxCascadingComponent {
  @Input() public data: TreeNode[] = [];
  @Input() public name!: string;
  @Input() public topLevelItemsAreSelected: boolean = false;
  @Input() public topLevelIsExpanded: boolean = true;

  @Output() getSelectedNodesEvent = new EventEmitter<any>();

  public selectAllTitle = this.cs.SELECT_ALL

  @ViewChild('tree')
  public tree!: IgxTreeComponent;

  @ViewChild('checkboxSelectAll')
  checkboxSelectAll!: IgxCheckboxComponent

  constructor(public cs: ConstantService) { }

  public handleNodeSelection(event: ITreeNodeSelectionEvent) {
    const name = this.name

    // console.log("event: ", event)
    let added: IgxTreeNode<any>[] = event.added.filter(i=>i.data.Id !== "");
    let removed: IgxTreeNode<any>[] = event.removed.filter(i=>i.data.Id !== "");
    let newSelection: IgxTreeNode<any>[] = event.newSelection.filter(i=>i.data.Id !== "");

    let selectedNodes: any[] = []   // the output

    // get newSelection
    let newSelectionNodes = []
    for(let i of newSelection ) {
      i.data.Selected = true
      let node:any = {}
      node['Id'] = i.data.Id
      node['Name'] = i.data.Name
      node['Level'] = i.level
      node['Parent'] = i.parentNode ? i.parentNode.data.Name : "null"
      newSelectionNodes.push(node)
    }

    // handle added
    if(added.length > 0 ) {
      let addedIds = added.map(i => i.data.Id)

      const comparerAdded: IgxTreeSearchResolver = (data: any, node: IgxTreeNode<any>) => {
        return addedIds.some(i=> i === node.data.Id)
      };

      const nodesAdded: any = this.tree.findNodes(null, comparerAdded);

      let addedNodes = []
      for(let i of nodesAdded ) {
        i.data.Selected = true
        let node:any = {}
        node['Id'] = i.data.Id
        node['Name'] = i.data.Name
        node['Level'] = i.level
        node['Parent'] = i.parentNode ? i.parentNode.data.Name : "null"
        addedNodes.push(node)
      }

      let concatedNodes = newSelectionNodes.concat(addedNodes)
      selectedNodes = [... new Map(concatedNodes.map(i => [i["Id"] + + i["Name"] + i["Level"] + i["Parent"], i])).values()]
    }

    // handle removed
    else if(removed.length > 0 ) {
      let removedIds = removed.map(i => i.data.Id)

      const comparerRemoved: IgxTreeSearchResolver = (data: any, node: IgxTreeNode<any>) => {
        return removedIds.some(i=> i === node.data.Id)
      };

      const nodesRemoved: any = this.tree.findNodes(null, comparerRemoved);

      let removedNodes = []
      let removedNodeIds = []
      for(let i of nodesRemoved ) {
        i.data.Selected = false
        let node:any = {}
        node['Id'] = i.data.Id
        node['Name'] = i.data.Name
        node['Level'] = i.level
        node['Parent'] = i.parentNode ? i.parentNode.data.Name : "null"
        removedNodes.push(node)
        removedNodeIds.push(node['Id'] + node['Name'] + node['Level'] + node['Parent'])
      }

      for(let ns of newSelectionNodes) {
        let nsId = ns['Id'] + ns['Name'] + ns['Level'] + ns['Parent']
        if(!removedNodeIds.includes(nsId)) {
          selectedNodes.push(ns)
        }
      }
    }

    // console.log("selectedNodes: ", selectedNodes)

    this.getSelectedNodesEvent.emit({name, selectedNodes});
   }

  // used to load the user config
  select(nodes2Select: any[]) {
    // console.log("tree with checkbox, nodes2Select: ", nodes2Select)
    if(nodes2Select) {  // make sure nodes2Select is not null / undefined / empty
      this.tree.deselectAll()

      const comparer: IgxTreeSearchResolver = (data: any, node: IgxTreeNode<any>) => {
        if(node.parentNode) {
            return nodes2Select.some(r => r.Level === node.level && r.Name === node.data.Name && r.Parent === node.parentNode!.data.Name);
        } else {
            return nodes2Select.some(r => r.Level === node.level && r.Name === node.data.Name);
        }
      };

      const foundNodes: any = this.tree.findNodes(null, comparer);

      for(let i of foundNodes ) {
          i.selected = true
      }
    }
  }

  selectAll() {
    const name = this.name
    if(this.checkboxSelectAll.checked) {
        const comparer: IgxTreeSearchResolver = (
            data: any,
            node: IgxTreeNode<any>
          ) => {
          return true;
        };

        const nodes: any =  this.tree.findNodes(null, comparer)

        let selectedNodes = []
        for(let i of nodes ) {
            i.selected = true
            let node:any = {}
            node['Id'] = i.data.Id
            node['Name'] = i.data.Name
            node['Level'] = i.level
            node['Parent'] = i.parentNode ? i.parentNode.data.Name : "null"     // Parent is not used, can be delete later on. so set it just to "nv"
            selectedNodes.push(node)
        }
        this.getSelectedNodesEvent.emit({name, selectedNodes});
    } else {
        this.tree.deselectAll()
        let selectedNodes:any = []
        this.getSelectedNodesEvent.emit({name, selectedNodes});
    }
  }
}
