import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../../layout/layout.component';
import { PurchaseBehaviorComponent } from './purchase-behavior/purchase-behavior.component';
import { LoyaltyPropensityRankingComponent } from './loyalty-propensity-ranking/loyalty-propensity-ranking.component';
import { AnalysisOfStrengthsAndWeaknessesComponent } from './analysis-of-strengths-and-weaknesses/analysis-of-strengths-and-weaknesses.component';
import { DefinitionOfKeyFactsComponent } from './definition-of-key-facts/definition-of-key-facts.component';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { StructureOfAccountClientsComponent } from './structure-of-account-clients/structure-of-account-clients.component';
import { DashboardId } from '../../shared/model/constants';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.definition_of_key_facts, pathMatch: 'full' },
      { path: DashboardId.definition_of_key_facts, component: DefinitionOfKeyFactsComponent },
      { path: DashboardId.analysis_of_strengths_and_weaknesses, component: AnalysisOfStrengthsAndWeaknessesComponent },
      { path: DashboardId.purchase_behavior, component: PurchaseBehaviorComponent },
      { path: DashboardId.loyalty_propensity_ranking, component: LoyaltyPropensityRankingComponent },
      { path: DashboardId.structure_of_account_clients, component: StructureOfAccountClientsComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropensityRoutingModule { }
