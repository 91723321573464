import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first} from 'rxjs';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { BasisId, Columns, DashboardId, Ids, ModeIds, PPTIds, UserSelectionIds } from '../../../shared/model/constants';
import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';

import { ChartMasterService } from '../../../shared/services/chart-master.service';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-price-performance',
  templateUrl: './price-performance.component.html',
  styleUrls: ['./price-performance.component.scss']
})
export class PricePerformanceComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.TACTICAL
  dashboardId = DashboardId.price_performance
  dashboards = this.cs.TACTICAL_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // If it has 2 period filters
  hasPeriod2Filter = true

  // static filter if existing
  public selectBasisTitle: string = this.cs.BASIS
  public selectBasisData: any[] = this.cs.SELECT_BASIS_DATA
  public selectedBasisItem: SelectItem = {Id: this.selectBasisData[0].Id, Name: this.selectBasisData[0].Name}

  // Facts used in dashboard
  axisYFactId = "TF1"     //default in BasisId.expenditures
  axisY2FactId = "TF12"   //default in BasisId.expenditures
  allowedChartFactIds = ["TF1", "TF2", "TF12", this.penetrationFactId]

  /** -------------------- Inputs for this dashboard -------------------- */
  @ViewChild("columnSeries")
  public columnseries!: IgxColumnSeriesComponent;

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  setToDefaultIndividual() {
    // console.log("this.showDataLabel: ", this.showDataLabel)
    this.columnseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning);
  }

  public getSelectedBasis = (event: any) => {
    this.selectedBasisItem = event.selectedItem
    // console.log("price-performance | selectedBasisItem: ", this.selectedBasisItem)
    switch (event.selectedItem.Id) {
      case BasisId.expenditures:
        this.axisYFactId = "TF1"
        this.axisY2FactId = "TF12"
        this.axisYFactName = this.axisYFactId ? this.filterChartFactData.filter(i => i.Id === this.axisYFactId)[0].Name : ''
        this.axisY2FactName = this.axisY2FactId ? this.filterChartFactData.filter(i => i.Id === this.axisY2FactId)[0].Name : ''
        this.feedChartWithData()
        break;
      case BasisId.buyers:
        this.axisYFactId = "TF2"
        this.axisY2FactId = "TF12"
        this.axisYFactName = this.axisYFactId ? this.filterChartFactData.filter(i => i.Id === this.axisYFactId)[0].Name : ''
        this.axisY2FactName = this.axisY2FactId ? this.filterChartFactData.filter(i => i.Id === this.axisY2FactId)[0].Name : ''
        this.feedChartWithData()
        break;
    }
    // console.log("price-performance | axisYFactId: ", this.axisYFactId)
  }

  feedChartWithData(): void {  
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), [this.axisYFactId, this.axisY2FactId, this.penetrationFactId], this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( data => {
            this.hasPenetrationWarning = false
         //    console.log("price-performance | chart | data: ", data)
            this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
        //     console.log("price-performance | chart | preprocessed data: ", data)
            this.chartData = this.utilityService.replaceIdWithName3(this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue), Columns.Account, Columns.Product, Columns.Period,
            this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, [this.axisYFactId, this.axisY2FactId], this.filterChartFactData)
            this.addCategoryAndDataLabelAndPenetration(this.chartData)
         //    console.log("price-performance | chart | chart data: ", this.chartData)
          }
        )
      })
  }

  private addCategoryAndDataLabelAndPenetration(chartData: any[]) {
    let inx = 0
    for(let i of chartData) {
      if(this.treeAccountIsMulti) {
        i[Ids.Category] = i[Columns.Account] + "-" + i[Columns.Period]
      } else {
        i[Ids.Category] = i[Columns.Product] + "-" + i[Columns.Period]
      }

      if(this.selectedBasisItem.Id === BasisId.expenditures) {
        //i[Ids.Label1] = this.utilityService.myFormatNumber(i["TF1"], 1)        
        i[Ids.Label1] = this.utilityService.myFormatNumber(i["TF1"], 2, 2)

        i[Ids.value1] = i["TF1"]
      } else {
        //i[Ids.Label1] = this.utilityService.myFormatNumber(i["TF2"], 1)
        i[Ids.Label1] = this.utilityService.myFormatNumber(i["TF2"], 2, 2)
        i[Ids.value1] = i["TF2"]
      }

      i[Ids.Label2] = this.utilityService.myFormatNumber(i["TF12"], 2, 2)
      i[Ids.value2] = i["TF12"]

      i[Ids.Penetration_SingleSeries] = this.utilityService.havePenetrationWarning(i[this.penetrationFactId])
      if(!this.hasPenetrationWarning && i[Ids.Penetration_SingleSeries]) {
        this.hasPenetrationWarning = true
      }

      i[Ids.index] = inx
      inx++
    }
  }

  handlePenetrationWarning() {
    this.columnseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning)
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = this.treeAccountIsMulti ? Ids.Product : Ids.Account

    let axisYFactName = this.filterChartFactData.filter(i=>i.Id === this.axisYFactId)[0].Name
    let axisY2FactName = this.filterChartFactData.filter(i=>i.Id === this.axisY2FactId)[0].Name
    let header = this.getPPTHeaderTemplate()
    let catAxisField = Ids.Category
    let dataseriesFields = [axisYFactName, axisY2FactName]
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = true,
    options[PPTIds.ValAxisTitle] = axisYFactName,
    options[PPTIds.ValAxisTitle2] = axisY2FactName,
    options[PPTIds.DataLabelFormatCode2] = "#,##0.00"

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.ColumnAndDot, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
    this.chartMasterService.next()
  }


  handleExportPPT(singleTreeName: string): void {
    // console.log("export single ppt: this.chartData", this.chartData)

    let axisYFactName = this.filterChartFactData.filter(i=>i.Id === this.axisYFactId)[0].Name
    let axisY2FactName = this.filterChartFactData.filter(i=>i.Id === this.axisY2FactId)[0].Name
    let header = this.getPPTHeaderTemplate()
    let catAxisField = Ids.Category
    let dataseriesFields = [axisYFactName, axisY2FactName]
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = true,
    options[PPTIds.ValAxisTitle] = axisYFactName,
    options[PPTIds.ValAxisTitle2] = axisY2FactName,
    options[PPTIds.DataLabelFormatCode2] = "#,##0.00"

    this.exportService.exportPPT(PPTIds.ColumnAndDot, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
                      .then(fileName => {
                        console.log(`Created Single PPT file: ${fileName}`);
                        this.isCreatingExport = false
                    });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCT] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.BASIS] = this.selectedBasisItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    this.restService.filterData (
      this.sourceTable,
      accounts.map(i => i.Id),
      products.map(i => i.Id),
      this.getPeriodIds(),
      [this.axisYFactId, this.axisY2FactId]
    )
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      // console.log("price-performance: multi ppt | data: ", data)

      this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.axisYFactId, this.axisY2FactId], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})

      // console.log("price-performance | multi ppt | preprocessed data: ", data)

      let multiPptData = this.utilityService.replaceIdWithName3(this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue), Columns.Account, Columns.Product, Columns.Period,
                                                              this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, [this.axisYFactId, this.axisY2FactId], this.filterChartFactData)

      this.addCategoryAndDataLabelAndPenetration(multiPptData)
      // console.log("price-performance | multi ppt | chart data: ", multiPptData)

      let axisYFactName = this.filterChartFactData.filter(i=>i.Id === this.axisYFactId)[0].Name
      let axisY2FactName = this.filterChartFactData.filter(i=>i.Id === this.axisY2FactId)[0].Name
      let header = this.getPPTHeaderTemplate()
      let catAxisField = Ids.Category
      let dataseriesFields = [axisYFactName, axisY2FactName]
      let options: any = {}
      options[PPTIds.master] = this.userDefinedPptMaster
      options[PPTIds.MaxValueIsHundred] = true,
      options[PPTIds.ValAxisTitle] = axisYFactName,
      options[PPTIds.ValAxisTitle2] = axisY2FactName,
      options[PPTIds.DataLabelFormatCode2] = "#,##0.00"

      this.exportService.exportPPT(PPTIds.ColumnAndDot, this.dashboardTitle, accounts.map(i=>i.Name), products.map(i=>i.Name), header, options, singleTreeName, catAxisField, dataseriesFields, multiPptData)
                        .then(fileName => {
                          console.log(`Created Multi PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
    })
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNT] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    header[this.cs.BASIS] = this.selectedBasisItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    if(this.treeAccountIsMulti) {
      columnValueMap[this.cs.ACCOUNT] = Ids.Category
    } else {
      columnValueMap[this.cs.PRODUCT] = Ids.Category
    }

    if(this.selectedBasisItem.Id === BasisId.expenditures) {
      columnValueMap[this.cs.FACT_OBJECT["TF1"]] = "TF1"
      columnValueMap[this.cs.FACT_OBJECT["TF12"]] = "TF12"
    } else {
      columnValueMap[this.cs.FACT_OBJECT["TF2"]] = "TF2"
      columnValueMap[this.cs.FACT_OBJECT["TF12"]] = "TF12"
    }

    this.exportService.exportExcel("Price_Performance", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.chartData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show
    this.columnseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning)
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Mode] = this.selectedModeItem
    selection[UserSelectionIds.Basis] = this.selectedBasisItem
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      return
    }

    // Switch (Mode)
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.treeProductIsMulti = true
        this.callBackendToGetProductAndAllAccounts = false

        this.treeAccountIsMulti = false
        this.callBackendToGetAccountAndAllProducts = true
        break

      case ModeIds.OneProductMultiAccounts:
        this.treeProductIsMulti = false
        this.callBackendToGetProductAndAllAccounts = true

        this.treeAccountIsMulti = true
        this.callBackendToGetAccountAndAllProducts = false
        break
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // Period 1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Period 2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)
    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]

    // Basis
    this.selectedBasisItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Basis, {
      Id: this.selectBasisData[0].Id,
      Name: this.selectBasisData[0].Name
    })
    // have to update it according to the User Language
    this.selectedBasisItem = {...this.selectedBasisItem, Name: this.cs.BASIS_OBJECT[this.selectedBasisItem.Id]}
    // console.log("this.selectedBasisItem: ", this.selectedBasisItem)
    switch (this.selectedBasisItem.Id) {
      case BasisId.expenditures:
        this.axisYFactId = "TF1"
        this.axisY2FactId = "TF12"
        this.axisYFactName = this.axisYFactId ? this.filterChartFactData.filter(i => i.Id === this.axisYFactId)[0].Name : ''
        this.axisY2FactName = this.axisY2FactId ? this.filterChartFactData.filter(i => i.Id === this.axisY2FactId)[0].Name : ''
        break;
      case BasisId.buyers:
        this.axisYFactId = "TF2"
        this.axisY2FactId = "TF12"
        this.axisYFactName = this.axisYFactId ? this.filterChartFactData.filter(i => i.Id === this.axisYFactId)[0].Name : ''
        this.axisY2FactName = this.axisY2FactId ? this.filterChartFactData.filter(i => i.Id === this.axisY2FactId)[0].Name : ''
        break;
    }

    // Shopper Group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // Chart Setting
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)
  }
}
