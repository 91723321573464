<section class="container_content">
  <div class="setting-content">
    <igx-tabs tabAlignment="justify">
      <igx-tab-item>
          <igx-tab-header>
              <span igxTabHeaderLabel>{{headerDatabaseLanguage}}</span>
          </igx-tab-header>
          <igx-tab-content>
            <div class="setting-tab-content">
                  <div class="flex flex-row mb-5">
                    <igx-select [(ngModel)]="selectedDBId" (ngModelChange)="onChangDatabase($event)">
                        <label igxLabel>{{databaseTitle}}</label>
                        <igx-select-item-group *ngFor="let ds of databaseScopes" [label]="ds.databases_clients">
                            <igx-select-item *ngFor="let item of ds.databases" [value]="item['id']" [text]="item['db_name']">
                                {{item['db_name']}}
                            </igx-select-item>
                        </igx-select-item-group>
                    </igx-select>
                  </div>

                  <div class="flex flex-row mb-5">
                    <igx-select [(ngModel)]="selectedLanguageId" (ngModelChange)="onChangLanguage($event)">
                      <label igxLabel>{{languageTitle}}</label>
                      <igx-select-item *ngFor="let item of filterLanguageData" [value]="item.Id" [text]="item.Name">
                          {{item.Name}}
                      </igx-select-item>
                    </igx-select>
                  </div>

                  <button igxButton="raised" class="button-green" [disabled]="saveDBLanguageButtonDisabled" (click)="saveLanguageAndDatabase()">{{saveAndLogout}}</button>
            </div>
          </igx-tab-content>
      </igx-tab-item>

    </igx-tabs>
  </div>

  <igx-toast #toast_account></igx-toast>
  <div class="loading" *ngIf="databaseAndLanguageDataIsLoading === true || (isPersonalDataSaving$ | async) === true"></div>
</section>
