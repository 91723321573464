import { Injectable } from '@angular/core';
import { DataTemplateMeasureInfo, DataTemplateRenderInfo } from 'igniteui-angular-core';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() { }

  /**
   *
   * @param source, data source to be converted.
   * @param keys, the items of the source, which are combined to be the identifier. The first item of the keys will be used in chart
   * @returns the converted data format for the chart.
   *
   *
   * for example, source is, with two series, namely PER1 and PER2
   * [{"BGNum": "BG1", "Fact": "F1", "PerNum": "PER1", "MBDNum": "M1", "ProdNum": "P0", "Value": 3},
   *  {"BGNum": "BG1", "Fact": "F1", "PerNum": "PER2", "MBDNum": "M1", "ProdNum": "P0", "Value": 4},
   *  {"BGNum": "BG1", "Fact": "F1", "PerNum": "PER1", "MBDNum": "M1", "ProdNum": "P1", "Value": 10},
   *  {"BGNum": "BG1", "Fact": "F1", "PerNum": "PER2", "MBDNum": "M1", "ProdNum": "P1", "Value": 23}];
   *
   * keys are: ["ProdNum", "BGNum", "Fact", "MBDNum"], so they are all fields without period and value
   *
   *xo will be: {
   * "P0|BG1|F1|M1": [{"PER1": 3},{"PER2": 4}],
   * "P1|BG1|F1|M1": [{"PER1": 10},{"PER2": 23}]
   * }
   * result will be: [
   * {"ProdNum": "P0", "BGNum": "BG1", "Fact": "F1", "MBDNum": "M1", "PER1": 3, "PER2": 4},
   * {"ProdNum": "P1", "BGNum": "BG1", "Fact": "F1", "MBDNum": "M1", "PER1": 10,"PER2": 23}
   * ]
   *
   *
   * It also works with one Series, for example, only with PER1
   */
  public covertToChartData1(source: any[], keys: string[]): any {
    const xo = source.reduce( (a: any,b: any) => {
        let values = keys.map(i => b[i]) // the values of the keys in the source
        let identifier: string = values.join('|')
        a[identifier] = a[identifier] || []
        a[identifier].push({[b.PerNum]: b.Value})
        return a;
    }, {})

    var result = []

    for (let identifier in xo) {
      let value = xo[identifier];
      let i: any = {}
      let ks: string[] = identifier.split('|')

      for (let n = 0; n < ks.length; n++) {
        i[keys[n]] = ks[n]
      }

      for (let d of value) {
        i[(Object.keys(d)[0])] = d[(Object.keys(d)[0])]
      }
      result.push(i)
    }

    return result
  }


  /**
   *
   * @param source, data source to be converted.
   * @param keys, the items of the source, which are combined to be the identifier. The first item of the keys will be used in chart
   * @returns the converted data format for the chart.
   *
   *
   * for example, source is, with two series, namely PER1 and PER2
   * [
   *  {"BGNum": "BG1", "Fact": "F1", "PerNum": "PER1", "MBDNum": "M1", "ProdNum": "P0", "Value": 3},
   *  {"BGNum": "BG1", "Fact": "F2", "PerNum": "PER1", "MBDNum": "M1", "ProdNum": "P0", "Value": 4},
   *  {"BGNum": "BG1", "Fact": "F3", "PerNum": "PER1", "MBDNum": "M1", "ProdNum": "P0", "Value": 5},
   *  {"BGNum": "BG1", "Fact": "F1", "PerNum": "PER2", "MBDNum": "M1", "ProdNum": "P0", "Value": 13},
   *  {"BGNum": "BG1", "Fact": "F2", "PerNum": "PER2", "MBDNum": "M1", "ProdNum": "P0", "Value": 14},
   *  {"BGNum": "BG1", "Fact": "F3", "PerNum": "PER2", "MBDNum": "M1", "ProdNum": "P0", "Value": 15},
   * ];
   *
   * keys are: ["ProdNum", "BGNum", "PerNum", "MBDNum"], so they are all fields without period and value
   *
   *
   * result will be: [
   * {"ProdNum": "P0", "BGNum": "BG1", "PerNum": "PER1", "MBDNum": "M1", "F1": 3,  "F2": 4,  "F3": 5},
   * {"ProdNum": "P0", "BGNum": "BG1", "PerNum": "PER2", "MBDNum": "M1", "F1": 13, "F2": 14, "F3": 15}
   * ]
   *
   *
   */
    public covertToChartData2(source: any[], keys: string[]): any {
      const xo = source.reduce( (a: any,b: any) => {
        let values = keys.map(i => b[i]) // the values of the keys in the source
        let identifier: string = values.join('|')
        a[identifier] = a[identifier] || []
        a[identifier].push({[b.Fact]: b.Value})
        return a;
      }, {})

      var result = []

      for (let identifier in xo) {
        let value = xo[identifier];
        let i: any = {}
        let ks: string[] = identifier.split('|')

        for (let n = 0; n < ks.length; n++) {
          i[keys[n]] = ks[n]
        }

        for (let d of value) {
          i[(Object.keys(d)[0])] = d[(Object.keys(d)[0])]
        }
        result.push(i)
      }

       //console.log("result: ", result)
      return result
    }

}
