<div class="select-wrapper">
  <ng-container *ngIf="hasSeparateLabel; else hasIntegratedLabel">
    <div class="tab-heading">{{name}}</div>
    <igx-select #select [(ngModel)]="selected" (ngModelChange)="onChang($event)" [disabled]="disabled" [hidden]="hidden" [overlaySettings]="{outlet: elem}">
      <igx-select-item *ngFor="let item of items" [value]="item.Id" [text]="item.Name" >
          {{item.Name}}
      </igx-select-item>
    </igx-select>
  </ng-container>
  <ng-template #hasIntegratedLabel>
    <igx-select #select [(ngModel)]="selected" (ngModelChange)="onChang($event)" [disabled]="disabled" [hidden]="hidden" [overlaySettings]="{outlet: elem}">
      <label igxLabel>{{name}}</label>
      <igx-select-item *ngFor="let item of items" [value]="item.Id" [text]="item.Name" >
          {{item.Name}}
      </igx-select-item>
    </igx-select>
  </ng-template>
</div>
