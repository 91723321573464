<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [showDemographicsChartSwitchFilter]="true" [filterDemographicsChartSwitchTitle]="filterDemographicsChartSwitchTitle" [filterDemographicsChartSwitchData]="filterDemographicsChartSwitchData" [selectedDemographicsChartSwitchItem]="selectedDemographicsChartSwitchItem" [getSelectedDemographicsChartSwitchItem]="getSelectedDemographicsChartSwitchItem"
    [showDemographicsChartBasisFilter]="true" [filterDemographicsChartBasisTitle]="filterDemographicsChartBasisTitle" [filterDemographicsChartBasisData]="filterDemographicsChartBasisData" [selectedDemographicsChartBasisItem]="selectedDemographicsChartBasisItem" [getSelectedDemographicsChartBasisItem]="getSelectedDemographicsChartBasisItem"
    [showSingleDemographicFilter]="true" [filterSingleDemographicTitle]="filterSingleDemographicTitle" [filterSingleDemographicData]="filterSingleDemographicData" [selectedSingleDemographicItem]="selectedSingleDemographicGroup" [getSelectedSingleDemographicItem]="getSelectedSingleDemographicGroup"
    [showFilterMode]="showFilterMode" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
    [showDemographicsChartBarsFilter]="showDemographicsChartBarsFilter" [filterDemographicsChartBarsTitle]="filterDemographicsChartBarsTitle" [filterDemographicsChartBarsData]="filterDemographicsChartBarsData" [selectedDemographicsChartBarsItem]="selectedDemographicsChartBarsItem" [getSelectedDemographicsChartBarsItem]="getSelectedDemographicsChartBarsItem"

    [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"                             [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"                             [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"                             [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"                             [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"                   [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipChartTitle]="filterDemographicsChartSwitchTitle"               [selectedChart]="selectedDemographicsChartSwitchItem"
      [chipBasisTitle]="filterDemographicsChartBasisTitle"                [selectedBasis]="selectedDemographicsChartBasisItem"
      [chipFilterDemographicGroupTitle]="filterSingleDemographicTitle"    [selectedFilterDemographicGroup]="selectedSingleDemographicGroup"
      [chipFilterHouseholdsTitle]="householdPeriod1"                      [selectedFilterHouseholds]="hhOfSelectedPeriod"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      [showInfoChart]="true" [showInfoBasis]="true" [showFilterDemographicGroup]="true" [showFilterHouseholds]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <div class="container fill">
        <igx-data-chart
        #chart
        width="1200px"
        height="600px"
        [legend]="legend"
        isHorizontalZoomEnabled="false"
        isVerticalZoomEnabled="false"
        [dataSource]="chartData">
        </igx-data-chart>

        <div class="legend">
          <igx-legend
          orientation="Horizontal"
          name="Legend"
          #legend>
          </igx-legend>
        </div>
      </div>

    </div>
  </div>

  <div class="loading" *ngIf="(isFilterDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>



