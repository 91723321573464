import { Component, Input } from '@angular/core';
import { SelectedTreeNode, SelectItem } from '../../../model/interfaces';

@Component({
  selector: 'app-chip-bar-table',
  templateUrl: './chip-bar-table.component.html',
  styleUrls: ['./chip-bar-table.component.scss']
})
export class ChipBarTableComponent {

  @Input() public chipAccountTitle!: string;
  @Input() public selectedAccountNodes: SelectedTreeNode[] = [];

  @Input() public chipProductTitle!: string;
  @Input() public selectedProductNodes: SelectedTreeNode[] = [];

  @Input() public chipPeriod1Title!: string;
  @Input() public selectedPeriod1ItemNames: String[] = []

  @Input() public chipPeriod2Title!: string;
  @Input() public selectedPeriod2ItemNames: String[] = []

  @Input() public chipShopperGroupTitle!: string;
  @Input() public selectedShopperGroupItem: SelectItem      = {Id: '', Name: ''}

  @Input() public chipBenchmarkTitle!: string;
  @Input() public selectedBenchmark: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterBubbleSizeTitle!: string;
  @Input() public selectedFilterBubbleSize: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFactTitle!: string;
  @Input() public selectedFact: SelectItem      = {Id: '', Name: ''}

  @Input() public chipChartTitle!: string;
  @Input() public selectedChart: SelectItem      = {Id: '', Name: ''}

  @Input() public chipBasisTitle!: string;
  @Input() public selectedBasis: SelectItem      = {Id: '', Name: ''}

  @Input() public chipBenchmarkAccountTitle!: string;
  @Input() public selectedBenchmarkAccount: SelectItem      = {Id: '', Name: ''}

  @Input() public chipBenchmarkProductTitle!: string;
  @Input() public selectedBenchmarkProduct: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterValueAccountTitle!: string;
  @Input() public selectedFilterValueAccount: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterValueProductTitle!: string;
  @Input() public selectedFilterValueProduct: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterValueFactTitle!: string;
  @Input() public selectedFilterValueFact: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterValueShopperGroupTitle!: string;
  @Input() public selectedFilterValueShopperGroup: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterDemographicGroupTitle!: string;
  @Input() public selectedFilterDemographicGroup: SelectItem      = {Id: '', Name: ''}

  @Input() public chipFilterHouseholdsTitle!: string;
  @Input() public selectedFilterHouseholds: string = ""

  /** Show or Not */
  @Input() public showFilterPeriod1: boolean = true;
  @Input() public showFilterPeriod2: boolean = false;
  @Input() public showInfoAccount: boolean = false;
  @Input() public showInfoProduct: boolean = false;
  @Input() public showInfoBenchmark: boolean = false;
  @Input() public showInfoFact: boolean = false;
  @Input() public showInfoChart: boolean = false;
  @Input() public showInfoBasis: boolean = false;
  @Input() public showInfoShopperGroup: boolean = true;
  @Input() public showInfoBenchmarkAccount: boolean = false;
  @Input() public showInfoBenchmarkProduct: boolean = false;
  @Input() public showFilterValueAccount: boolean = false;
  @Input() public showFilterValueProduct: boolean = false;
  @Input() public showFilterValueFact: boolean = false;
  @Input() public showFilterValueShopperGroup: boolean = false;
  @Input() public showFilterBubbleSize: boolean = false;
  @Input() public showFilterDemographicGroup: boolean = false;
  @Input() public showFilterHouseholds: boolean = false;


  constructor() { }

}
