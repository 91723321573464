import { ChangeDetectorRef, Component, ViewChild, AfterViewInit } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { ClientBuyerIds, Columns, DashboardId, Ids, PPTIds, UserSelectionIds } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent extends BaseComponent implements AfterViewInit{
  /** -------------------- Inputs for Base -------------------- */
  // set source table
  sourceTable = "NTP_Data-rest-requirement"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.REST_REQUIREMENT
  dashboardId = DashboardId.account_balance
  dashboards = this.cs.REST_REQUIREMENT_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // If it has 2 period filters
  hasPeriod2Filter = false     // used in base component, have to keep it
  filterPeriod1Title = this.cs.PERIOD

  // Facts used in dashboard
  allowedChartFactIds = []  //which is actually not needed, since in the backend api, it returns the needed facts

  /** -------------------- Inputs for this dashboard -------------------- */
  public selectFactRestRequirementData = this.cs.SELECT_FACT_RR_DATA
  public selectedFactRestRequirementItem = {Id: '', Name: ''}

  /** Select Single Chart Fact | get selected item | select one fact */
  public getSelectedFactRestRequirement = (event: any) => {
    this.selectedFactRestRequirementItem = event.selectedItem

    // console.log("this.selectedFactRestRequirementItem: ", this.selectedFactRestRequirementItem)
    this.feedChartWithData()
  }

  // set default to get all accounts and a/selected products when calling the backend
  public callBackendToGetAccountAndAllProducts: boolean = false
  public callBackendToGetProductAndAllAccounts: boolean = true

  /** new things */
  treeProductIsMulti = false

  public CHART_ACCOUNTBALANCE:any[] = [];

  public axis_minimumValue: number = 0;
  public axis_maximumValue: number = 0;
  public axis_net_minimumValue: number = 0;
  public axis_net_maximumValue: number = 0;

  @ViewChild("CHART_ACCOUNTBALANCE_TO_barSeries1")
  public CHART_ACCOUNTBALANCE_TO_barSeries1!: IgxColumnSeriesComponent;

  @ViewChild("CHART_ACCOUNTBALANCE_FROM_BarSeries1")
  public CHART_ACCOUNTBALANCE_FROM_BarSeries1!: IgxColumnSeriesComponent;

  @ViewChild("CHART_ACCOUNTBALANCE_NET_barSeries1")
  public CHART_ACCOUNTBALANCE_NET_barSeries1!: IgxColumnSeriesComponent;

  public from: string = this.cs.FROM
  public to: string = this.cs.TO
  public net_balance: string = this.cs.NET_BALANCE

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  public ngAfterViewInit(): void {
    this.CHART_ACCOUNTBALANCE_TO_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_To, !this.hidePenetrationWarning);
    this.CHART_ACCOUNTBALANCE_FROM_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_From, !this.hidePenetrationWarning);
    this.CHART_ACCOUNTBALANCE_NET_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_Net, !this.hidePenetrationWarning);
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show

    if(this.CHART_ACCOUNTBALANCE_TO_barSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_TO_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_To, !this.hidePenetrationWarning); }
    if(this.CHART_ACCOUNTBALANCE_FROM_BarSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_FROM_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_From, !this.hidePenetrationWarning); }
    if(this.CHART_ACCOUNTBALANCE_NET_barSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_NET_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_Net, !this.hidePenetrationWarning); }
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFiltersWithoutFacts([... new Set(this.selectedAccountNodesCompetitor.map(i=>i.Id).concat(this.selectedAccountNodesFocus.map(i => i.Id), this.selectedAccountNodesCompetitor.map(i => i.Id)))], this.selectedProductNodes.map(i => i.Id), this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log("account-balance | data: ", data)
          // get data of focused account
          let data_focused = data.filter(d=> d[Columns.Account] === this.selectedAccountNodesFocus[0].Id)
          // console.log("account-balance | data_focused: ", data_focused)

          // get switch value, either buyers or clients. when buyers, isLB equals true. when clients, isLB equals false
          let isLB: boolean = this.selectedFactRestRequirementItem.Id === ClientBuyerIds.buyers

          let theChartData: any[] = []
          let valuesLeft: number[] = []
          let valuesMiddle: number[] = []
          let valuesRight: number[] = []
          let inx = 0
          for(let competitorId of this.selectedAccountNodesCompetitor.map(i=>i.Id)) {
            let item: any = {}
            let competitorLELBFact = this.getCompetitorFact(competitorId, isLB)
            item[Ids.Account] = this.selectedAccountNodesCompetitor.filter(n => n.Id === competitorId)[0].Name
            item[Columns.Product] = this.selectedProductNodes[0].Name
            item[Ids.Value_Account_To] = data_focused[0][competitorLELBFact] * -1
            item[Ids.Penetration_To_Value] = data_focused[0][this.penetrationFactId]
            item[Ids.Penetration_To] = this.utilityService.havePenetrationWarning(item[Ids.Penetration_To_Value])

            let focusLELBFact = this.getCompetitorFact(this.selectedAccountNodesFocus[0].Id, isLB)
            let data_competitor = data.filter(d => d[Columns.Account] === competitorId)[0]
            item[Ids.Value_Account_From] = data_competitor[focusLELBFact]
            item[Ids.Penetration_From_Value] = data_competitor[this.penetrationFactId]
            item[Ids.Penetration_From] = this.utilityService.havePenetrationWarning(item[Ids.Penetration_From_Value])

            item[Ids.Value_Account_Net] = item[Ids.Value_Account_To] + item[Ids.Value_Account_From]
            item[Ids.Penetration_Net] = item[Ids.Penetration_To] || item[Ids.Penetration_From]

            if(!this.hasPenetrationWarning && (item[Ids.Penetration_To] || item[Ids.Penetration_From])) {
              this.hasPenetrationWarning = true
            }

            item[Ids.index] = inx
            item[Ids.Series] = this.selectedPeriod1Item.Name
            item[Ids.Label_Account_To] = this.utilityService.myFormatNumber(item[Ids.Value_Account_To], 0)
            item[Ids.Label_Account_From] = this.utilityService.myFormatNumber(item[Ids.Value_Account_From], 0)
            item[Ids.Label_Account_Net] = this.utilityService.myFormatNumber(item[Ids.Value_Account_Net], 0)
            theChartData.push(item)
            valuesLeft.push(item[Ids.Value_Account_To])
            valuesMiddle.push(item[Ids.Value_Account_From])
            valuesRight.push(item[Ids.Value_Account_Net])

            inx++
          }

          this.axis_minimumValue = Math.min(...valuesLeft) * 2
          this.axis_maximumValue = Math.max(...valuesMiddle) * 2
          let absMinRight = Math.abs(Math.min(...valuesRight))
          let absMaxRight = Math.abs(Math.max(...valuesRight))

          if(absMinRight < absMaxRight) {
            this.axis_net_minimumValue = absMaxRight * -3
            this.axis_net_maximumValue = absMaxRight * 3
          } else {
            this.axis_net_minimumValue = absMinRight * -3
            this.axis_net_maximumValue = absMinRight * 3
          }

          if(Math.min(...valuesRight) > 0) {
            this.axis_net_minimumValue = 0
          }
          if(Math.max(...valuesRight) < 0) {
            this.axis_net_maximumValue = 0
          }

          // console.log("axis: ", this.axis_minimumValue, this.axis_maximumValue, this.axis_net_minimumValue, this.axis_net_maximumValue)

          this.CHART_ACCOUNTBALANCE = theChartData
          // console.log("account-balance | CHART_ACCOUNTBALANCE : ", this.CHART_ACCOUNTBALANCE)
        }
      )
    })
  }

  private getCompetitorFact(competitorAccountId: string, isLB: boolean): string {
    let LBLE = ""
    if(isLB) {
      LBLE = "LB"
    } else {
      LBLE = "LE"
    }

    let idNum = competitorAccountId.replace("BG", "")
    return LBLE + idNum
  }

  handlePenetrationWarning() {
    if(this.CHART_ACCOUNTBALANCE_TO_barSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_TO_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_To, !this.hidePenetrationWarning); }
    if(this.CHART_ACCOUNTBALANCE_FROM_BarSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_FROM_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_From, !this.hidePenetrationWarning); }
    if(this.CHART_ACCOUNTBALANCE_NET_barSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_NET_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_Net, !this.hidePenetrationWarning); }
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = Ids.Product

    let header = this.getPPTHeaderTemplate()
    let catAxisField = Ids.Account
    let dataseriesFields: string[] = []
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.Dataseries] = this.selectedPeriod1Item.Name,
    options[PPTIds.Account] = this.selectedAccountNodesFocus[0].Name,

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.ThreeBarCharts, this.dashboardTitle,this.selectedAccountNodesFocus.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.CHART_ACCOUNTBALANCE)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    singleTreeName = Ids.Product  // singleTreeName is always Product

    // console.log("single ppt data: ", this.chartData)
    let header = this.getPPTHeaderTemplate()
    let catAxisField = Ids.Account
    let dataseriesFields: string[] = []
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.Dataseries] = this.selectedPeriod1Item.Name,
    options[PPTIds.Account] = this.selectedAccountNodesFocus[0].Name,

    this.exportService.exportPPT(PPTIds.ThreeBarCharts, this.dashboardTitle,this.selectedAccountNodesFocus.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.CHART_ACCOUNTBALANCE)
                      .then(fileName => {
                        console.log(`Created Single PPT file: ${fileName}`);
                        this.isCreatingExport = false
                    });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT_FOCUS] = this.selectedAccountNodesFocus.map(n => n.Name).join(", ")
    pptHeaderTemplate[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    pptHeaderTemplate[this.cs.PERIOD] = this.selectedPeriod1Item.Name
    pptHeaderTemplate[this.cs.ACCOUNT_CLIENT_ACCOUNT_BUYER] = this.selectedFactRestRequirementItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  // not used
  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // alert("Export will be implemented soon")
    this.isCreatingExport = false
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNT_FOCUS] = this.selectedAccountNodesFocus.map(n => n.Name).join(", ")
    header[this.cs.ACCOUNT_COMPETITOR] = this.selectedAccountNodesCompetitor.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD] = this.selectedPeriod1Item.Name
    header[this.cs.ACCOUNT_CLIENT_ACCOUNT_BUYER] = this.selectedFactRestRequirementItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    columnValueMap[this.cs.ACCOUNT] = Ids.Account
    columnValueMap[this.cs.FROM + " " + this.selectedAccountNodesFocus[0].Name + " " + this.cs.TO + " ..."] = Ids.Value_Account_To
    columnValueMap[this.cs.FROM + " ... " + this.cs.TO + " " + this.selectedAccountNodesFocus[0].Name] = Ids.Value_Account_From
    columnValueMap[this.cs.NET_BALANCE + " " + this.selectedAccountNodesFocus[0].Name] = Ids.Value_Account_Net

    let dummy: any = []

    this.exportService.exportExcel("Account_Balance", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.CHART_ACCOUNTBALANCE, header, columnValueMap, dummy, dummy, dummy)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.AccountFocus] = this.selectedAccountNodesFocus,
    selection[UserSelectionIds.AccountCompetitor] = this.selectedAccountNodesCompetitor,
    selection[UserSelectionIds.Product] = this.selectedProductNodes,
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Fact] = this.selectedFactRestRequirementItem
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[]): void {
    // Account Focus Tree
    this.selectedAccountNodesFocus = this.utilityService.getSelectionItem(selection, UserSelectionIds.AccountFocus, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    this.selectedFocusAccountName = this.selectedAccountNodesFocus[0].Name

    // Account Competitor Tree
    this.selectedAccountNodesCompetitor = this.utilityService.getSelectionItem(selection, UserSelectionIds.AccountCompetitor, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    this.selectedAccountNodeLevelsCompetitor = [... new Set(this.selectedAccountNodesCompetitor.map(n => n.Level))]

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // shopper group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // period (period1)
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // fact chart (Fact)
    this.selectedFactRestRequirementItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, this.selectFactRestRequirementData[0])
    // have to update it according to the User Language
    this.selectedFactRestRequirementItem = {...this.selectedFactRestRequirementItem, Name: this.cs.FACT_RR_OBJECT[this.selectedFactRestRequirementItem.Id]}
    // console.log("this.selectedFactRestRequirementItem: ", this.selectedFactRestRequirementItem)

    // chart setting
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)

    // display datalabels
    if(this.CHART_ACCOUNTBALANCE_TO_barSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_TO_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_To, !this.hidePenetrationWarning); }
    if(this.CHART_ACCOUNTBALANCE_FROM_BarSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_FROM_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_From, !this.hidePenetrationWarning); }
    if(this.CHART_ACCOUNTBALANCE_NET_barSeries1 !== undefined) { this.CHART_ACCOUNTBALANCE_NET_barSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_Net, !this.hidePenetrationWarning); }
  }
}
