<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [showFilterPeriod2]="true" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard" id="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
        [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
        [hideSwitch]="true"
      ></app-penetration-warning>

      <ng-container *ngIf="showChart">
        <div class="decompostion-tree-summary">
          <div class="decompostion-tree-heading">{{valueChange}}</div>
          <div class="stat-card-wrapper-decomposition-tree">
            <div class="stat-card">{{VALUE_CHANGE}}</div>
          </div>
        </div>

        <div class="decompostion-tree-contribution">
          <div class="decompostion-tree-heading">{{valueChangeAmoung}}</div>
          <div class="decompostion-tree-constribution-chart">
            <igx-data-chart
            isHorizontalZoomEnabled="false"
            isVerticalZoomEnabled="false"
            width="800px"
            height="175px"
            [dataSource]="ContributionValueChangeY"
            name="chart"
            #chart>
                <igx-category-x-axis
                interval="1"
                label="Category"
                labelLocation="OutsideBottom"
                labelTextStyle="6pt Montserrat"
                name="xAxis"
                #xAxis>
                </igx-category-x-axis>

                <igx-numeric-y-axis
                titleAngle="90"
                titleLeftMargin="10"
                [majorStroke]="configService.STROKE_COLOR_VALUE"
                name="yAxis"
                #yAxis>
                </igx-numeric-y-axis>

                <igx-waterfall-series
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                valueMemberPath="Value"
                isTransitionInEnabled="true"
                showDefaultTooltip="true"
                title="Value"
                name="WaterfallSeries"
                #waterfallSeries>
                </igx-waterfall-series>

                <igx-callout-layer
                [dataSource]="ContributionValueChangeY" isAutoCalloutBehaviorEnabled="false" [targetSeries]="waterfallSeries"
                [labelMemberPath]="ids.Label" [xMemberPath]="ids.index" [yMemberPath]="ids.Value"
                calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
                </igx-callout-layer>
            </igx-data-chart>
          </div>
        </div>



        <div class="decompostion-tree-orgchart">
          <p-organizationChart [value]="data" selectionMode="single" styleClass="company">
            <ng-template let-node pTemplate="person">
                <div class="card-content">
                  <div class="card-section-a">{{node.label}}</div>
                  <div class="card-section-b">
                    <div class="card-section-b-MAT-1">{{node.data.val}} ({{node.data.change}})</div>
                    <div class="card-section-b-MAT-2">{{node.data.impact}}</div>
                  </div>
                </div>
            </ng-template>
          </p-organizationChart>
        </div>
      </ng-container>
    </div>
  </div>

  <app-export-multi-ppt-dialog
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
