import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableDemographicsComponent } from './table-demographics/table-demographics.component';
import { DemographicRoutingModule } from './demographic.routing.module';
import { DemographicsChartComponent } from './demographics-chart/demographics-chart.component';
import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';


@NgModule({
  declarations: [
    TableDemographicsComponent,
    DemographicsChartComponent,

  ],
  imports: [
    CommonModule,
    DemographicRoutingModule,
    SharedDashboardComponentModule,
  ]
})
export class DemographicModule { }
