const { version } = require('../../package.json');

export const environment = {
  dataApi: 'https://x4v1-1rtl-dp2s.f2.xano.io/api:GYxwNOFP',
  authApi: 'https://x4v1-1rtl-dp2s.f2.xano.io/api:x4ocgg3f',

  production: true,
  appVersion: version,

};
