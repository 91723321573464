import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { DataTemplateMeasureInfo, DataTemplateRenderInfo } from 'igniteui-angular-core';
import { Columns, Ids } from '../model/constants';
import { PreprocessDataParameters, SelectedTreeNode } from '../model/interfaces';
import { ConstantService } from './constant.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(public cs: ConstantService) { }

  /** Id -> Name
   *
   * suppose input data is:
   * [{"BGNum": "BG1", "Fact":"F1", PerNum: "PER1", "ProdNum": "P0",  "Value": 1}]
   *
   * then output is:
   * [{"BGNum": "BG1 (Name)", "Fact":"F1 (Name)", PerNum: "PER1 (Name)", "ProdNum": "P0 (Name)",  "Value": 1}]
   *
   * Or
   *
   * suppose input data is:
   * [{"BGNum": "BG1", "Fact":"F1", PerNum: "PER1", "ProdNum": "P0",  "Value": 1,  "changeInPercentage": 2,  "changeInValue": 3}]
   *
   * then output is:
   * [{"BGNum": "BG1 (Name)", "Fact":"F1 (Name)", PerNum: "PER1 (Name)", "ProdNum": "P0 (Name)",  "Value": 1,  "changeInPercentage": 2,  "changeInValue": 3}]
  */
  replaceIdWithName(
    items: any[],
    fieldNameValue: string, fieldNameAccount: string, fieldNameProduct: string, fieldNamePeriod: string, fieldNameFact: string, fieldNameShopperGroup: string,
    dictAccountDataWithIdName: any[], dictProductDataWithIdName: any[], filterPeriod1Data: any[], filterChartFactData: any[], filterShopperGroupData: any[],
    otherFields?: any[], formatValue: boolean = false, keepAccountId: boolean = false
  ): any[] {
    let results: any[] = []

    for(let i = 0; i < items.length; i++) {
      // let result: any = {}
      let result: any = Object.assign({}, items[i]);  // so that the position of accout field and product field keeps

      let accountName  = dictAccountDataWithIdName.filter(j => j.Id === items[i][fieldNameAccount])[0].Name
      let productName = dictProductDataWithIdName.filter(j => j.Id === items[i][fieldNameProduct])[0].Name
      let periodName  = filterPeriod1Data.filter(j => j.Id === items[i][fieldNamePeriod])[0].Name
      let factName    = filterChartFactData.filter(j => j.Id === items[i][fieldNameFact])[0].Name
      let shopperGroupName    = filterShopperGroupData.filter(j => j.Id === items[i][fieldNameShopperGroup])[0].Name

      result[fieldNameAccount]  = accountName
      result[fieldNameProduct] = productName
      result[fieldNamePeriod]  = periodName
      result[fieldNameFact]    = factName
      result[fieldNameShopperGroup] = shopperGroupName

      if(keepAccountId) {
        result[fieldNameAccount+"_Id"]  = items[i][fieldNameAccount]
      }

      if(Ids.Penetration_Row in items[i]) {  // used in the grid of purchase behavior
        result[fieldNameValue]   = items[i][Ids.Penetration_Row] ? " " + this.myFormatNumber(items[i][fieldNameValue], 2) : this.myFormatNumber(items[i][fieldNameValue], 2)
      } else {
        result[fieldNameValue]   = formatValue? this.myFormatNumber(items[i][fieldNameValue], 2) : items[i][fieldNameValue]
      }

      if(otherFields) {
        for(let field of otherFields) {
          result[field] = items[i][field]
        }
      }

      results.push(result)
    }
    return results
  }


  /** Id -> Name
   *
   * suppose input data is:
   * [{"ProdNum": "P0", "BGNum": "BG1", "Fact": "F1", "PER1": 3, "PER2": 4}]
   *
   * then output is:
   * [{"ProdNum": "P0 (Name)", "BGNum": "BG1 (Name)", "Fact": "F1 (Name)", "PER1 (Name)": 3, "PER2 (Name)": 4}]
  */
  replaceIdWithName2(
    items: any[],
    fieldNameAccount: string, fieldNameProduct: string, fieldNameFact: string,
    dictAccountDataWithIdName: any[], dictProductDataWithIdName: any[], filterChartFactData: any[],
    selectedPeriodIds: string[], filterPeriod1Data: any[], otherFields?: any[]
  ): any[] {
    let results: any[] = []
    for(let i = 0; i < items.length; i++) {
      let result: any = Object.assign({}, items[i]);  // so that the position of accout field and product field keeps      
      let accountName  = dictAccountDataWithIdName.filter(j => j.Id === items[i][fieldNameAccount])[0].Name
      let productName = dictProductDataWithIdName.filter(j => j.Id === items[i][fieldNameProduct])[0].Name
      let factName    = filterChartFactData.filter(j => j.Id === items[i][fieldNameFact])[0].Name
      result[fieldNameAccount]  = accountName
      result[fieldNameProduct] = productName
      result[fieldNameFact]    = factName

      for(let perId of selectedPeriodIds) {
        let perName  = filterPeriod1Data.filter(j => j.Id === perId)[0].Name
        result[perName] = items[i][perId]
        delete result[perId]  // delete the period keys, such as "PER1", "PER2"
      }

      if(otherFields) {
        for(let field of otherFields) {
          result[field] = items[i][field]
        }
      }

      results.push(result)
    }    
    return results
  }


  /** Id -> Name
   *
   * suppose input data is:
   * [{"ProdNum": "P0", "BGNum": "BG1", "PerNum": "PER1", "F1": 3,  "F2": 4,  "F3": 5}]
   *
   * then output is:
   * [{"ProdNum": "P0 (Name)", "BGNum": "BG1 (Name)", "PerNum": "PER1 (Name)", "F1 (Name)": 3,  "F2 (Name)": 4,  "F3 (Name)": 5}]
  */
  replaceIdWithName3(
    items: any[],
    fieldNameAccount: string, fieldNameProduct: string, fieldNamePeriod: string,
    dictAccountDataWithIdName: any[], dictProductDataWithIdName: any[], filterPeriod1Data: any[],
    selectedFactIds: string[], filterChartFactData: any[], otherFields?: any[], keepAccountId: boolean = false, keepProductId: boolean = false
  ): any[] {
    let results: any[] = []

    //console.log("items", items)
    for(let i = 0; i < items.length; i++) {
      // let result: any = {}
      let result: any = Object.assign({}, items[i]);  // so that the position of accout field and product field keeps
      
      let accountName  = dictAccountDataWithIdName.filter(j => j.Id === items[i][fieldNameAccount])[0].Name
      let productName = dictProductDataWithIdName.filter(j => j.Id === items[i][fieldNameProduct])[0].Name
      let periodName    = filterPeriod1Data.filter(j => j.Id === items[i][fieldNamePeriod])[0].Name

      result[fieldNameAccount]  = accountName
      result[fieldNameProduct]  = productName
      result[fieldNamePeriod]   = periodName

      if(keepAccountId) {
        result[fieldNameAccount+"_Id"]  = items[i][fieldNameAccount]
      }

      if(keepProductId) {
        result[fieldNameProduct+"_Id"]  = items[i][fieldNameProduct]
      }

      for(let factId of selectedFactIds) {
        let factName  = filterChartFactData.filter(j => j.Id === factId)[0].Name
        result[factName] = items[i][factId]
      }

      if(otherFields) {
        for(let field of otherFields) {
          result[field] = items[i][field]
        }
      }
      results.push(result)
    }
    return results
  }


  convertConfigValue2SelectedTreeNodes(configValue: any[], dimensionData: any[]) {
    let selectedNodes = []
    for(let item of configValue) { // configValue is a list of configs, which has no Id in each item. we need to get the id from the dimension data
      let tmp: SelectedTreeNode = {
        Id: dimensionData.filter(d => d.Name === item.Name && d.Level === item.Level && d.Parent === item.Parent)[0].Id,
        Name: item.Name,
        Level: item.Level,
        Parent: item.Parent
      }
      selectedNodes.push(tmp)
    }
    return selectedNodes
  }

  public abbreviateNumber(num: any, digits: number, minDigits: number = 0) {
    if(typeof num === "number") {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        // { value: 1e9, symbol: "G" },
        // { value: 1e12, symbol: "T" },
        // { value: 1e15, symbol: "P" },
        // { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });

      let result = item ? this.myFormatNumber( num/item.value, digits, minDigits) + item.symbol :  num.toFixed(digits).replace(".", ",")
      return result
    } else {
      return ""
    }
  }

  public myRoundNumber(n:any, digits:number):number {
    if(typeof n === "number") {
      return Number(n.toFixed(digits))
    } else {
      return n
    }
  }

  public myFormatNumber(n: any, digits: number, minDigits: number = 0): string {
    if(typeof n === "number") {
      let digitsInfo = "1." + String(minDigits) + "-" + String(digits)
      return formatNumber(n, 'de-DE', digitsInfo)
    } else {
      return n
    }
  }

  public formatNumberAsPercent(num: any, digits: number, minDigits: number = 0) {
    if(typeof num === "number") {
      return this.myFormatNumber(num, digits, minDigits) + '%'
    } else {
      return ""
    }
  }

  getFactDim(dim: any[], elements: any[]) {
    let result: any[] = []
    elements.forEach((id) => {
      result.push(dim.filter(dim => dim.Id === id)[0])
    })
    return result
  }

  getValue(data: any[], factId: string, periodId: string, accountId: string, productId: string, shopperGroupId: string, digits = -1, defaultValue: string = "" ) {
    let filteredData = data.filter(
      i => i[Columns.Fact] === factId
        && i[Columns.Period] === periodId
        && i[Columns.Account] === accountId
        && i[Columns.Product] === productId
        && i[Columns.ShopperGroup] === shopperGroupId
     )

    if(filteredData.length > 0) {
      if(digits > 0) {
        return this.myRoundNumber(filteredData[0][Columns.Value], digits)
      } else {
        return filteredData[0][Columns.Value]
      }
    } else {
      return defaultValue
    }
  }

  // not to use it for now. but there is a saying, that toFixed() is not good ;-(, so maybe we should use Math.round() to round a number later
  // myRound(value: number, digits: number) {
  //   var multiplier = Math.pow(10, digits || 0);
  //   return Math.round(value * multiplier) / multiplier;
  // }

  getDiffAbsolute(actualValue: number, perviousValue: number) {
    return (actualValue - perviousValue)
  }

  //it multiplies 100, to represent the number without % symbol
  getDiffInPercent(actualValue: number, perviousValue: number) {
    return this.getDiffAbsolute(actualValue, perviousValue) / perviousValue * 100
  }

  //add calculated facts, for table-of-facts & kpi-comparison

  public extendDataWithCalculatedFacts(data: any[], hhData: any, totalProductId: string, totalAccountId: string, selectedProductBenchmarkItemId: string, penetrationFactId: string,
    factsBasicCalculated: string[], factsBuyerCalculated: string[], factsNumberOfBuyersCalculated: string[], factsNumberOfBuyersMCalculated: string[],
    factsExpendituresCalculated: string[], factsExpendituresMCalculated: string[], factsPropensityCalculated: string[], factsGapCalculated: string[], factsPromotionCalculated: string[],
    factsDecompostionCalculated: string[]) {
    let results = []
    let hasPenetrationWarning: boolean = false
    let retVal:any = {}
    for(let d of data) {
      let result = Object.assign({}, d);

      result[Ids.Penetration_Row] = this.havePenetrationWarning(d[penetrationFactId])
      if(!hasPenetrationWarning && result[Ids.Penetration_Row]) {
        hasPenetrationWarning = true
      }

      for(let f of factsBasicCalculated) {
        switch (f) {
          case "c_ShopperConversion":
            result[f] = (result["F2"] / result["F1"]) * 100
            break
          case "c_NumberOfLostHouseholds":
            result[f] = ((result["F1"] - result["F2"]) * this.getHH(result, hhData)) / 100
            break
          case "c_NumberOfAccountClients":
            result[f] = Math.round(this.getHH(result, hhData) * result["F1"] / 100)
            break
          case "c_NumberOfAccountBuyers":
            result[f] = Math.round(this.getHH(result, hhData) * result["F2"] / 100)
            break
          case "c_PenetrationOfShop":
            result[f] = this.getFactOfTotalProduct(data, result, "F1", totalProductId)
            break
          case "c_Occasions000":
            result[f] = ((Math.round(this.getHH(result, hhData) * result["F2"] / 100)) * result["F5"]) / 1000
            break
        }
      }

      for(let f of factsBuyerCalculated) {
        switch (f) {
          case "c_PenetrationInTotalMarket":
            result[f] = this.getFactOfTotalMarket(data, result, "F1", totalAccountId)
            break
          case "c_BuyerCoverageIndex":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = result["F3"] / this.getFactOfBenchmarkProduct(data, result, "F3", selectedProductBenchmarkItemId) * 100
            }
            break
          case "c_ShopperConversionIndex":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = (result["F2"] / result["F1"]) / (this.getFactOfBenchmarkProduct(data, result, "F2", selectedProductBenchmarkItemId) / this.getFactOfBenchmarkProduct(data, result, "F1", selectedProductBenchmarkItemId)) * 100
            }
            break
          case "c_ComparativeCoverageOfAccountBuyers":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = ((result["F2"] / this.getFactOfBenchmarkProduct(data, result, "F2", selectedProductBenchmarkItemId))) * 100
            }
            break
          case "c_ComparativeCoverageInTotalMarket":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = (result["c_PenetrationInTotalMarket"] / data.filter(n => n[Columns.Account] === totalAccountId && n[Columns.Product] === selectedProductBenchmarkItemId)[0]["F1"]) * 100
            }
            break
          case "c_ComparativeCoverageIndex":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = (result["c_ComparativeCoverageOfAccountBuyers"] / result["c_ComparativeCoverageInTotalMarket"]) * 100
            }
            break
        }
      }

      for(let f of factsNumberOfBuyersCalculated) {
        switch (f) {
          case "c_NumberOfBuyersInTotalMarket":
            result[f] = Math.round(this.getHH(result, hhData) * this.getFactOfTotalMarket(data, result, "F2", totalAccountId) / 100)
            break
          case "c_NumberOfBuyerOfShop":
            result[f] = Math.round((this.getHH(result, hhData) * result["c_PenetrationOfShop"]) / 100)
            break
        }
      }

      for(let f of factsNumberOfBuyersMCalculated) {
        switch (f) {
          case "c_NumberOfBuyersInTotalMarketM":
            result[f] = result["c_NumberOfBuyersInTotalMarket"] / 1000000
            break
          case "c_NumberOfBuyerOfShopM":
            result[f] = result["c_NumberOfBuyerOfShop"] / 1000000
            break
          case "c_NumberOfAccountClientsM":
            result[f] = result["c_NumberOfAccountClients"] / 1000000
            break
          case "c_NumberOfAccountBuyersM":
            result[f] = result["c_NumberOfAccountBuyers"] / 1000000
            break
          case "c_NumberOfLostHouseholdsM":
            result[f] = result["c_NumberOfLostHouseholds"] / 1000000
            break
        }
      }

      for(let f of factsExpendituresCalculated) {
        switch (f) {
          case "c_TotalExpenditures000":
            result[f] = this.getFactOfTotalProduct(data, result, "F7", totalProductId)
            break
          case "c_ExpendituresInTotalMarket000":
            result[f] = this.getFactOfTotalMarket(data, result, "F6", totalAccountId)
            break
          case "c_ExpendituresOfAccountClients000":
            result[f] = result["F6"]
            break
          case "c_ExpendituresOfAccountBuyers000":
            result[f] = result["F7"]
            break
          case "c_LostExpenditures000":
            result[f] = result["F8"]
            break
          case "c_ExpendituresOfAccountBuyersInAllShops000":
            result[f] = result["F7"] / (result["F14"] / 100)
            break
          case "c_ExpendituresPerAccountBuyersInAllShops":
            result[f] = (result["F5"] * result["F13"] * ((this.getHH(result, hhData) * result["F2"]) / 100) / result["F14"] * 100) / ((this.getHH(result, hhData) * result["F2"]) / 100)
            break
          case "c_ExpenditurePerAccountClientsInAllShops":
            result[f] = (result["F6"] * 1000) / (this.getHH(result, hhData) * result["F1"] / 100)
            break
          case "c_ExpendituresPerBuyerInTotalMarket":
            result[f] = this.getFactOfTotalMarket(data, result, "F7", totalAccountId) / ((this.getHH(result, hhData) * result["c_PenetrationInTotalMarket"]) / 100) * 1000
            break
        }
      }

      for(let f of factsExpendituresMCalculated) {
        switch (f) {
          case "c_TotalExpendituresM":
            result[f] = Math.round(result["c_TotalExpenditures000"] / 1000)
            break
          case "c_ExpendituresInTotalMarketM":
            result[f] = Math.round(result["c_ExpendituresInTotalMarket000"] / 1000)
            break
          case "c_ExpendituresOfAccountClientsM":
            result[f] = result["c_ExpendituresOfAccountClients000"] / 1000
            break
          case "c_ExpendituresOfAccountBuyersM":
            result[f] = Math.round(result["c_ExpendituresOfAccountBuyers000"] / 1000)
            break
          case "c_LostExpendituresM":
            result[f] = Math.round(result["c_LostExpenditures000"] / 1000)
            break
          case "c_ExpendituresOfAccountBuyersInAllShopsM":
            result[f] = result["c_ExpendituresOfAccountBuyersInAllShops000"] / 1000
            break
        }
      }

      for(let f of factsPropensityCalculated) {
        switch (f) {
          case "c_UnitsMarketShareInPercent":
            result[f] = result["F22"] / this.getFactOfTotalMarket(data, result, "F22", totalAccountId) * 100
            break
          case "c_Intensness":
            result[f] = ((result["F5"] * result["F13"] * ((this.getHH(result, hhData) * result["F2"]) / 100) / result["F14"] * 100) / ((this.getHH(result, hhData) * result["F2"]) / 100)) / ((result["F6"] * 1000) / (this.getHH(result, hhData) * result["F1"] / 100))
            //Expenditure per account buyer in all shops / Expenditure per account-client in all shops
            break
          case "c_LoyaltyIndex":
            result[f] = result["F10"] / this.getFactOfBenchmarkProduct(data, result, "F10", selectedProductBenchmarkItemId) * 100
            break
          case "c_PropensityIndex":
            result[f] = result["F11"] / this.getFactOfBenchmarkProduct(data, result, "F11", selectedProductBenchmarkItemId) * 100
            break
          case "c_ShareOnTotalOccasions":
            result[f] = (((this.getHH(result, hhData) * result["F2"] / 100) * result["F5"]) / ((this.getHH(result, hhData) * this.getFactOfTotalProduct(data, result, "F2", totalProductId) / 100) * this.getFactOfTotalProduct(data, result, "F5", totalProductId))) * 100
            break
        }
      }

      for(let f of factsGapCalculated) {
        switch (f) {
          case "c_LoyaltyGapInPercent":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = result["F10"] - this.getFactOfBenchmarkProduct(data, result, "F10", selectedProductBenchmarkItemId)
            }
            break
          case "c_LoyaltyGapIn000":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = (result["F6"] * result["c_LoyaltyGapInPercent"]) / 100
            }
            break
          case "c_MarketShareGapInPercent":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = result["F9"] - this.getFactOfBenchmarkProduct(data, result, "F9", selectedProductBenchmarkItemId)
            }
            break
          case "c_MarketShareGapIn000":
            if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
              result[f] = ""
            } else {
              result[f] = Math.round((this.getFactOfTotalMarket(data, result, "F7", totalAccountId) * result["c_MarketShareGapInPercent"]) / 100)
            }
            break
        }
      }

      for(let f of factsPromotionCalculated) {
        switch (f) {
          case "c_PromotionPenetrationInTotalMarket":
            result[f] = this.getFactOfTotalMarket(data, result, "TF7", totalAccountId)
            break
          case "c_PercentPromotionBuyersInTotalMarket":
            result[f] = this.getFactOfTotalMarket(data, result, "TF9", totalAccountId)
            break
          case "c_PromotionExpendituresInTotalMarket000":
            result[f] = this.getFactOfTotalMarket(data, result, "TF4", totalAccountId)
            break
          case "c_PercentPromotionExpendituresInTotalMarket":
            result[f] = this.getFactOfTotalMarket(data, result, "TF5", totalAccountId)
            break
          case "c_PromotionExpendituresInTotalMarketM":
            result[f] = this.getFactOfTotalMarket(data, result, "TF4", totalAccountId) / 1000
            break
          case "c_PromotionExpendituresOfAccountBuyersM":
            result[f] = result["TF4"] / 1000
            break
          case "c_BasisExpendituresM":
            result[f] = result["TF10"] / 1000
            break
          case "c_NumberOfAccountBuyersPromotion":
            result[f] = this.getHH(result, hhData) * result["TF7"] / 100
            break
          case "c_NumberOfAccountBuyersNonPromotion":
            result[f] = this.getHH(result, hhData) * result["TF13"] / 100
            break
          case "c_NumberOfAccountBuyersPromotionOnly":
            result[f] = (this.getHH(result, hhData) * result["TF7"] / 100) - (((this.getHH(result, hhData) * result["TF7"] / 100) + (this.getHH(result, hhData) * result["TF13"] / 100)) - (this.getHH(result, hhData) * result["F2"] / 100))
            break
          case "c_NumberOfAccountBuyersNonPromotionOnly":
            result[f] = (this.getHH(result, hhData) * result["TF13"] / 100) - (((this.getHH(result, hhData) * result["TF7"] / 100) + (this.getHH(result, hhData) * result["TF13"] / 100)) - (this.getHH(result, hhData) * result["F2"] / 100))
            break
          case "c_NumberOfAccountBuyerPromotionAndNonPromotion":
            result[f] = ((this.getHH(result, hhData) * result["TF7"] / 100) + (this.getHH(result, hhData) * result["TF13"] / 100)) - (this.getHH(result, hhData) * result["F2"] / 100)
            break
          case "c_PercentPromotionAccountBuyers":
            result[f] = (this.getHH(result, hhData) * result["TF7"]) / (this.getHH(result, hhData) * result["F2"] / 100)
            break
          case "c_PercentNonPromotionAccountBuyers":
            result[f] = (this.getHH(result, hhData) * result["TF13"]) / (this.getHH(result, hhData) * result["F2"] / 100)
            break
          case "c_PercentExclusivelyPromotionAccountBuyers":
            result[f] = ((this.getHH(result, hhData) * result["TF7"] / 100) - (((this.getHH(result, hhData) * result["TF7"] / 100) + (this.getHH(result, hhData) * result["TF13"] / 100)) - (this.getHH(result, hhData) * result["F2"] / 100))) / ((this.getHH(result, hhData) * result["F2"] / 100)) * 100
            break
          case "c_PercentExclusivelyNonPromotionAccountBuyers":
            result[f] = ((this.getHH(result, hhData) * result["TF13"] / 100) - (((this.getHH(result, hhData) * result["TF7"] / 100) + (this.getHH(result, hhData) * result["TF13"] / 100)) - (this.getHH(result, hhData) * result["F2"] / 100))) / ((this.getHH(result, hhData) * result["F2"] / 100)) * 100
            break
          case "c_PercentPromotionAndNonPromotionAccountBuyers":
            result[f] = (((this.getHH(result, hhData) * result["TF7"] / 100) + (this.getHH(result, hhData) * result["TF13"] / 100)) - (this.getHH(result, hhData) * result["F2"] / 100)) / (this.getHH(result, hhData) * result["F2"] / 100) * 100
            break
          }
      }

      for(let f of factsDecompostionCalculated) {
        switch (f) {
          case "c_UnitsM":
            result[f] = result["F22"] / 1000
            break
          case "c_AveragePricePerUnit":
            result[f] = result["F7"] / result["F22"]
            break
          case "c_UnitsPerAccountBuyer":
            result[f] = (result["F22"] * 1000) / (this.getHH(result, hhData) * result["F2"] / 100)
            break
          case "c_UnitsPerOccasion":
            result[f] = (result["F22"] * 1000) / ((this.getHH(result, hhData) * result["F2"] / 100) * result["F5"])
            break
        }
      }

      results.push(result)
    }
    retVal[Ids.hasPenetrationWarning] = hasPenetrationWarning
    retVal[Ids.results] = results
    return retVal
  }

  private getHH(row: any, hhData: any) {
    return hhData[row[Columns.Period]]
  }

  public extendTableDemographicsData(type: string, dataAccount: any[], dataProduct: any[], hhData: any, calculatedFacts: string[], benchmarkAccountId: string, benchmarkProductId: string) {
    let results = []

    if(type === Ids.Account) {
      for(let d of dataAccount) {
        let result = Object.assign({}, d);

        for(let f of calculatedFacts) {
          switch (f) {
            case "c_BuyerStructureM":
              result[f] = this.getHH(result, hhData) * result["SF2"] / 100 / 1000000
              break
            case "c_BuyerIndexVSProductAccount":
              if(benchmarkProductId === this.cs.NO_BENCHMARK_SELECTED.Id) {
                result[f] = ''
              } else {
                result[f] = result["SF9"] / dataProduct.filter(d=> d[Columns.Product] === benchmarkProductId && d[Columns.Soz] === result[Columns.Soz])[0]["SF9"] * 100
              }
              break
            case "c_ExpenditureIndexVSProductAccount":
              if(benchmarkProductId === this.cs.NO_BENCHMARK_SELECTED.Id) {
                result[f] = ''
              } else {
                result[f] = result["SF8"] / dataProduct.filter(d=> d[Columns.Product] === benchmarkProductId && d[Columns.Soz] === result[Columns.Soz])[0]["SF8"] * 100
              }
              break
          }
        }
        results.push(result)
      }
    } else if (type === Ids.Product) {
      for(let d of dataProduct) {
        let result = Object.assign({}, d);

        for(let f of calculatedFacts) {
          switch (f) {
            case "c_BuyerStructureM":
              result[f] = this.getHH(result, hhData) * result["SF2"] / 100 / 1000000
              break
            case "c_BuyerIndexVSProductAccount":
              if(benchmarkAccountId === this.cs.NO_BENCHMARK_SELECTED.Id) {
                result[f] = ''
              } else {
                result[f] = result["SF9"] / dataAccount.filter(d=> d[Columns.Account] === benchmarkAccountId && d[Columns.Soz] === result[Columns.Soz])[0]["SF9"] * 100
              }
              break
            case "c_ExpenditureIndexVSProductAccount":
              if(benchmarkAccountId === this.cs.NO_BENCHMARK_SELECTED.Id) {
                result[f] = ''
              } else {
                result[f] = result["SF8"] / dataAccount.filter(d=> d[Columns.Account] === benchmarkAccountId && d[Columns.Soz] === result[Columns.Soz])[0]["SF8"] * 100
              }
              break
          }
        }
        results.push(result)
      }
    }

    return results
  }

  private getFactOfTotalProduct(data: any[], row: any, fact: string, totalProductId: string) {
    return data.filter(n => n[Columns.Product] === totalProductId && n[Columns.Account] === row[Columns.Account] && n[Columns.Period] === row[Columns.Period])[0][fact]
  }

  private getFactOfTotalMarket(data: any[], row: any, fact: string, totalAccountId: string) {
    return data.filter(n => n[Columns.Account] === totalAccountId && n[Columns.Product] === row[Columns.Product] && n[Columns.Period] === row[Columns.Period])[0][fact]
  }

  private getFactOfBenchmarkProduct(data: any[], row: any, fact: string, selectedProductBenchmarkItemId: string) {
    if(selectedProductBenchmarkItemId === this.cs.NO_BENCHMARK_SELECTED.Id) {
      return ''
    } else {
      return data.filter(n => n[Columns.Account] === row[Columns.Account] && n[Columns.Product] === selectedProductBenchmarkItemId)[0][fact]
    }
  }

  private getNameOfId(id: string, dictIdName: any[]) {
    return dictIdName.filter(i=>i.Id === id)[0]["Name"];
  }

  public normalizeAndRenameData(data: any[], factIds: string[], dictAccoutIdName: any[], dictFactIdName: any[], dictShopperGroupIdName: any[], dictPeriodIdName: any[], dictProductIdName: any[]) {
    let results = []
    for(let f of factIds) {
      for(let d of data) {
        let result:any = {}
        result[Columns.Account] = this.getNameOfId(d[Columns.Account], dictAccoutIdName)
        result[Columns.Fact] = this.getNameOfId(f, dictFactIdName)
        result[Columns.ShopperGroup] = this.getNameOfId(d[Columns.ShopperGroup], dictShopperGroupIdName)
        result[Columns.Period] = this.getNameOfId(d[Columns.Period], dictPeriodIdName)
        result[Columns.Product] = this.getNameOfId(d[Columns.Product], dictProductIdName)

        if(d[Ids.Penetration_Row]) {  // add empty string at the beginning to indicate, that it has penetration warning
          result[Columns.Value] = " " + this.myFormatNumber(d[f],2)   //format the number in german format, so that the pivot tables shows the numbers in german format
        } else {
          result[Columns.Value] = this.myFormatNumber(d[f],2)   //format the number in german format, so that the pivot tables shows the numbers in german format
        }

        results.push(result)
      }
    }

    return results
  }

  public normalizeData(data: any[], factIds: string[]) {
    let results = []
    for(let f of factIds) {
      for(let d of data) {
        let result:any = {}
        result[Columns.Account] = d[Columns.Account]
        result[Columns.Fact] = f
        result[Columns.ShopperGroup] = d[Columns.ShopperGroup]
        result[Columns.Period] = d[Columns.Period]
        result[Columns.Product] = d[Columns.Product]
        result[Columns.Value] = d[f]
        result[Ids.Penetration_Prefix + d[Columns.Period]] = d[Ids.Penetration_Row]
        results.push(result)
      }
    }

    return results
  }

  private addMissingDataAsZero(data: any[], accounts: string[], products: string[], facts: string[], periods: string[], shopperGroups: string[]) {
    if(data.length < accounts.length * products.length * facts.length * periods.length) {
      if(data.length >= 1) {
          for(let account of accounts) {
            for(let product of products) {
              for(let fact of facts) {
                for(let period of periods) {
                  for(let shopperGroup of shopperGroups) {
                    if(data.filter(i => i[Columns.Account] === account && i[Columns.Product] === product && i[Columns.Fact] === fact && i[Columns.Period] === period && i[Columns.ShopperGroup] === shopperGroup).length === 0) {
                      let template: any = {}
                      Object.assign(template, data[0]) // take data[0] as the template
                      template[Columns.Account] = account
                      template[Columns.Product] = product
                      template[Columns.Fact] = fact
                      template[Columns.Period] = period
                      template[Columns.ShopperGroup] = shopperGroup
                      template[Columns.Value] = 0
                      data.push(template)
                    }
                  }
                }
              }
            }
          }
      } else {
        // when this case do exist, then we will handle it
      }
    } else if(data.length === accounts.length * products.length * facts.length * periods.length) {
      // console.log("Data is complete")
    }
  }

  public preprocessData({addMissingData, sortDataByValue, sortDataBySelectionOrder, treeProductIsMulti, data, selectedAccounts, selectedProducts, selectedFacts, selectedPeriods, selectedShopperGroups}: PreprocessDataParameters) {
    // activate it until it is necessary.
    if(addMissingData) {
      this.addMissingDataAsZero(data, selectedAccounts, selectedProducts, selectedFacts, selectedPeriods, selectedShopperGroups)
    }

    // sort the data according to the value
    if(sortDataByValue) {
      data.sort((a, b) => (a[Columns.Value] > b[Columns.Value] ? -1 : 1));
    }


    // sort the data according to the selection order
    if(sortDataBySelectionOrder) {
      if(treeProductIsMulti) {
        data.sort((a,b) =>(selectedProducts.indexOf(a[Columns.Product]) > selectedProducts.indexOf(b[Columns.Product]) ? 1 : -1))
      } else {
        data.sort((a,b) =>(selectedAccounts.indexOf(a[Columns.Account]) > selectedAccounts.indexOf(b[Columns.Account]) ? 1 : -1))
      }
    }
  }

  public getSelectBenchmarkData(nodes: any[]) {
    let result : any[] = []
    result.push(this.cs.NO_BENCHMARK_SELECTED)
    return result.concat(nodes)
  }

  public getSelectionItem(selection: any[], key:string,  defaultValue: any) {
    if(selection.length > 0) {
      if(key in selection[0]["selections"]) {
        // have to validate the saved selection, if there is any error, then it should return the defaultValue
        let value = selection[0]["selections"][key]
        let type = typeof value

        if(type === 'string') { // there should be no string
          return defaultValue
        }

        if(type === 'boolean') {  // if boolean, then the value should be ok
          return value
        }

        if(type === 'object') {
          if(Array.isArray(value)) {
            if(key === "FactIds" || key === "FactNames") { // special cases for keys, which are array of strings
              if(key.length === 0) {
                return defaultValue
              } else {
                return value
              }
            } else { // in case of an array of objects [{}, {}]
              if(value.length === 0) {
                return defaultValue         // if no element, then return the default value
              } else {
                for(let i of value) {
                  if(!this.objectIsOk(i)) { // check each object of the array
                    return defaultValue
                  }
                }
                return value
              }
            }
          } else {  // in case of an object {}
            if(key === "Periods") {
              return value
            } else {
              if(this.objectIsOk(value)) {
                return value
              } else {
                return defaultValue
              }
            }
          }
        }
      } else {
        return defaultValue
      }
    } else {
      return defaultValue
    }
  }

  private objectIsOk(value: any) {
    let result = true
    if("Id" in value) {
      if(value["Id"] === '') { // if Id is empty string, do not check Name, since it may not always be there, there could be Group. We hope it won't happen, that the Id has value, but the Name or Group is empty string
        result = false
      }
    } else {
      result = false
    }
    return result
  }

  // penetrationSingleSeriesName has "Penetration_SingleSeries" for most of the cases when they have only 1 series in chart
  // if the chat has multi series, then penetrationSingleSeriesName is Ids.Penetration_2Series
  public getMarker(configService: ConfigService, display: boolean, penetrationSingleSeriesName: string = "tbd", showPenetrationWarning: boolean = false): any {
    if(!display) {
      return
    }

    return {
        measure: function (measureInfo: DataTemplateMeasureInfo) {
            const context = measureInfo.context;
            const height = context.measureText("M").width;
            const width = context.measureText("0.00").width;
            measureInfo.width = width;
            measureInfo.height = height + 12;
        },
        render: function (renderInfo: DataTemplateRenderInfo) {
            let ctx = renderInfo.context;
            let x = renderInfo.xPosition;
            let y = renderInfo.yPosition;

            if (renderInfo.isHitTestRender) {
                ctx.fillStyle = renderInfo.data.actualItemBrush.fill;
                let width = renderInfo.availableWidth;
                let height = renderInfo.availableHeight;
                ctx.fillRect(x - (width / 2), y - (height), renderInfo.availableWidth, renderInfo.availableHeight);
                return;
            }

            let markerColor = configService.DEFAULT_MARKER_COLOR
            if(showPenetrationWarning) {
              let penetrationColor = configService.PENETRATION_COLOR
              const dataItem = renderInfo.data.item;
              if (dataItem === null) return;
              // console.log("dataItem: ", dataItem)
              if (penetrationSingleSeriesName === "tbd") {
                // do nothing since it is the default value
              } else if(penetrationSingleSeriesName === Ids.Penetration_2Series) { // there are at least 2 series in chart, the penetration name is with the series name as suffix
                const series = renderInfo.data.series;
                const dataPath = series.valueColumn.propertyName;
                // console.log("dataPath: ", dataPath)
                if (dataItem[Ids.Penetration_Prefix + dataPath]) {
                  markerColor = penetrationColor
                }
              } else { // single series in chart -> Penetration_SingleSeries
                if (dataItem[penetrationSingleSeriesName]) {
                  markerColor = penetrationColor
                }
              }
            }

            ctx.strokeStyle = "black";
            ctx.fillStyle = markerColor;
            ctx.beginPath();
            ctx.arc(x, y, 6, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
        }
    }
  }

    /**
   *
   * @param displayDataLabel
   * @param digitFormat
   * @param showMarker
   * @param type it could be "h" (horizontal, columns) or "v" (vertical, bars). the default is "v"
   * @returns
   */
    public getMarkerComplex(display: boolean, digitFormat: string = '1.1-1', showMarker: boolean = true, type: string = "v", showDatalabel: boolean = false, showPenetrationWarning: boolean = false): any {
      if(!display) {
        return
      }

      return {
          measure: function (measureInfo: DataTemplateMeasureInfo) {
              const context = measureInfo.context;
              const height = context.measureText("M").width;
              const width = context.measureText("0.00").width;
              measureInfo.width = width;
              measureInfo.height = height + 12;
          },
          render: function (renderInfo: DataTemplateRenderInfo) {
              let ctx = renderInfo.context;
              let x = renderInfo.xPosition;
              let y = renderInfo.yPosition;

              if (renderInfo.isHitTestRender) {
                  ctx.fillStyle = renderInfo.data.actualItemBrush.fill;
                  let width = renderInfo.availableWidth;
                  let height = renderInfo.availableHeight;
                  ctx.fillRect(x - (width / 2), y - (height), renderInfo.availableWidth, renderInfo.availableHeight);
                  return;
              }

              if(showDatalabel) {
                const dataItem = renderInfo.data.item;
                if (dataItem === null) return;
                const series = renderInfo.data.series;
                const dataPath = series.valueColumn.propertyName;
                let dataValue = 0;
                dataValue = dataItem[dataPath];
                ctx.font = '12px Montserrat';
                ctx.textBaseline = 'top';
                ctx.fillStyle = "black";
                const dataFormat = formatNumber(Number(dataValue), 'de-DE', digitFormat)    // not able to use this.myFormatNumber()

                if(type === "v") {
                  let xOffset = 20;
                  let yOffset = 10;
                  ctx.fillText(dataFormat + "", x - (xOffset / 2), y - (yOffset * 2));
                } else {
                  let xOffset = 20;
                  let yOffset = 10;
                  ctx.fillText(dataFormat + "", x + (xOffset / 2), y - (yOffset / 2));
                }
              }

              if(showMarker) {
                ctx.strokeStyle = "black";
                ctx.fillStyle = "#BFBFBF";
                ctx.beginPath();
                ctx.arc(x, y, 6, 0, 2 * Math.PI);
                ctx.stroke();
                ctx.fill();
              }
          }
      }
    }

    public havePenetrationWarning(value: number) {
      if(value < 0.5) {
        return true
      } else {
        return false
      }
    }


    /**
     * get the colors of accout/product id
     * @param treeData example like
     *
     * {
        "Id": "BG1",
        "Name": "Gesamtmarkt",
        "Color": "#d6b71e",
        "Children": [
          {
            "Id": "BG3",
            "Name": "Nielsen Gebiet 1",
            "Color": "#61d86e"
          },
          {
            "Id": "BG4",
            "Name": "Nielsen Gebiet 2",
            "Color": "#44105b"
          }]
        }
     * @param result get something like {"BG1": "#d6b71e", "BG3": "#61d86e", "BG4": "#44105b"}
     */
    public getIdAndColor(treeData: any[], result: any) {
      let len = treeData.length
      for(let i = 0;  i < len; i++) {
        let row = treeData[i]
        let keysOfRow = Object.keys(row)

        let color = row["Color"]
        if(color) { // if not null, not empty, not undefined
          result[row["Id"]] = color
        }

        if(keysOfRow.includes("Children")) {
          let children = treeData[i]["Children"]
          this.getIdAndColor(children, result)
        }
      }
    }

    /**
     * add the user defined colors for account/product into the account/product dimension
     *
     * @param treeData example
     * {
        "Id": "BG1",
        "Name": "Gesamtmarkt",
        "Color": "",
        "Children": [
          {
            "Id": "BG3",
            "Name": "Nielsen Gebiet 1",
            "Color": ""
          },
          {
            "Id": "BG4",
            "Name": "Nielsen Gebiet 2",
            "Color": ""
          }]
        }
     * @param idColor example
     * {
     *  "BG1": "#000000",
     *  "BG3": "#FFFFFF"
     * }
     * @returns example
     * {
        "Id": "BG1",
        "Name": "Gesamtmarkt",
        "Color": "#000000",
        "Children": [
          {
            "Id": "BG3",
            "Name": "Nielsen Gebiet 1",
            "Color": "#FFFFFF"
          },
          {
            "Id": "BG4",
            "Name": "Nielsen Gebiet 2",
            "Color": ""
          }]
        }
     */
    public updateIdAndColor(treeData: any[], idColor: any) {
      let idsToFind = Object.keys(idColor)

      if(idsToFind.length === 0) {
        return
      }

      let len = treeData.length
      for(let i = 0;  i < len; i++) {
        let row = treeData[i]
        let keysOfRow = Object.keys(row)
        let idOfRow = row["Id"]
        if(idsToFind.includes(idOfRow)) {
          row["Color"] = idColor[idOfRow]

          delete idColor[idOfRow]
        } else {
          row["Color"] = ""
        }

        if(keysOfRow.includes("Children")) {
          let children = treeData[i]["Children"]
          this.updateIdAndColor(children, idColor)
        }
      }
    }
}
