import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthActions } from './auth.actions';
import { fromAuth } from './auth.selectors';
import { AuthAppState } from './auth.reducer';


@Injectable()
export class AuthFacade {
  user$ = this.store.select(fromAuth.selectAuthUser);

  isLoadingLogin$ = this.store.select(fromAuth.selectIsLoadingLogin);
  hasLoginError$ = this.store.select(fromAuth.selectLoginError);
  isLoggedIn$ = this.store.select(fromAuth.selectIsLoggedIn);

  isForgettingPassword$ = this.store.select(fromAuth.selectIsForgettingPassword);
  hasForgetPasswordError$ = this.store.select(fromAuth.selectForgetPasswordError);
  isForgetPasswordEmailSent$ = this.store.select(fromAuth.selectIsForgetPasswordEmailSent);

  isLoadingMagicLogin$ = this.store.select(fromAuth.selectIsLoadingMagicLogin);
  hasMagicLoginError$ = this.store.select(fromAuth.selectMagicLoginError);
  isMagicLoggedIn$ = this.store.select(fromAuth.selectIsMagicLoggedIn);

  isSettingPassword$ = this.store.select(fromAuth.selectIsSettingPassword);
  hasSetPasswordError$ = this.store.select(fromAuth.selectSetPasswordError);
  isPasswordSet$ = this.store.select(fromAuth.selectIsPasswordSet);

  constructor(private store: Store<AuthAppState>) {}

  login(email: string, password: string) {
    this.store.dispatch(AuthActions.loginRequest({ email, password }));
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  getAuthUser() {
    this.store.dispatch(AuthActions.getAuthUserRequest());
  }

  forgetPassword(email: string) {
    this.store.dispatch(AuthActions.forgetPasswordRequest({ email }));
  }

  magicLogin(token: string) {
    this.store.dispatch(AuthActions.magicLoginRequest({token}));
  }

  resetPassword(password: string, passwordConfirm: string) {
    this.store.dispatch(AuthActions.resetPasswordRequest({password, passwordConfirm}))
  }
}
