import { ElementRef, EventEmitter, OnInit, Output, Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { TreeWithCheckboxWithTenLevelsComponent } from '../tree/tree-with-checkbox-with-ten-levels/tree-with-checkbox-with-ten-levels.component';
import { TreeWithoutCheckboxWithTenLevelsComponent } from '../tree/tree-without-checkbox-with-ten-levels/tree-without-checkbox-with-ten-levels.component';
import { SelectItem, SelectedTreeNode } from '../../../model/interfaces';
import { ConstantService } from '../../../services/constant.service';


@Component({
  selector: 'app-filter-tabs-rest-requirement',
  templateUrl: './filter-tabs-rest-requirement.component.html',
  styleUrls: ['./filter-tabs-rest-requirement.component.scss']
})
export class FilterTabsRestRequirementComponent implements OnInit {
  // variables & constent
  public exportItems: any = []


  // tab focus
  @Input() public tabFocusTitle!: string;

  @Input() public filterAccountTitleFocus!: string;
  @Input() public filterAccountDataFocus!: any[];
  @Input() public selectedAccountNodesFocus!: any[];
  @Input() getSelectedNodesFocus!: ($event: any) => void


  // tab competitor
  @Input() public tabCompetitorTitle!: string;

  @Input() public filterAccountTitleCompetitor!: string;
  @Input() public filterAccountDataCompetitor!: any[];
  @Input() public selectedAccountNodesCompetitor!: any[];
  @Input() getSelectedNodesCompetitor!: ($event: any) => void


  // tab product
  @Input() public tabProductTitle!: string;

  @Input() public filterProductTitle!: string;
  @Input() public filterProductData!: any[];
  @Input() public selectedProductNodes!: any[];
  @Input() getSelectedNodesProduct!: ($event: any) => void


  // tab selection
  @Input() public tabSelectionTitle!: string;

  @Input() public filterShopperGroupTitle!: string;
  @Input() public filterShopperGroupData!: any[];
  @Input() public selectedShopperGroupItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedShopperGroupItem!: ($event: any) => void
  @Input() public showFilterShopperGroup: boolean = false;

  @Input() public filterPeriod1Title!: string;
  @Input() public filterPeriod1Data!: any[];
  @Input() public selectedPeriod1Item: SelectItem = {Id: '', Name: ''};
  @Input() public filterPeriod2Title!: string;
  @Input() public filterPeriod2Data!: any[];
  @Input() public selectedPeriod2Item: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedPeriodItem!: ($event: any) => void
  @Input() public showOneSinglePeriodFilter: boolean = false;
  @Input() public showTwoSinglePeriodFilters: boolean = false;

  @Input() public selectSingleFactTitle!: string;
  @Input() public selectSingleFactData!: any[];
  @Input() public selectedSingleFactItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedSingleFact!: ($event: any) => void

  @Input() public selectStaticFilterTitle!: string; // static filter is a filter with select-items which are static
  @Input() public selectStaticFilterData!: any[];
  @Input() public selectedStaticFilterItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedStaticFilterItem!: ($event: any) => void
  @Input() public showStaticFilter: boolean = false;

  @Input() showRestRequirementIndex: boolean = false;
  @Input() showIndexChart: boolean = true
  @Output() showRestRequirementIndexEvent = new EventEmitter<any>();

  @Input() showCheckboxLegend: boolean = false;
  @Input() showCheckboxDataLabel: boolean = false;
  @Input() showLegend: boolean = true;
  @Input() showDataLabel: boolean = true;
  @Output() showLegendEvent = new EventEmitter<any>();
  @Output() showDataLabelEvent = new EventEmitter<any>();

  @Input() public showExportExcel: boolean = false;
  @Input() public showExportPPT: boolean = false;
  @Input() public showExportMultiPPT: boolean = false;
  @Output() getSelectedExportEvent = new EventEmitter<any>();

  // others inputs
  @Input() selectedAccountNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes
  @Input() selectedProductNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes

  // maybe used later
  @Input() isDataLoading$!: Observable<boolean>;


  // view child
  @ViewChild('accountTreeWithCheckbox')
  private accountTreeWithCheckbox!: TreeWithCheckboxWithTenLevelsComponent;
  @ViewChild('accountTreeWithoutCheckbox')
  private accountTreeWithoutCheckbox!: TreeWithoutCheckboxWithTenLevelsComponent;
  @ViewChild('productTreeWithCheckbox')
  private productTreeWithCheckbox!: TreeWithCheckboxWithTenLevelsComponent;
  @ViewChild('productTreeWithoutCheckbox')
  private productTreeWithoutCheckbox!: TreeWithoutCheckboxWithTenLevelsComponent;


  public chartSettingTitle: string = this.cs.CHART_SETTING
  public chartSettingLegendTitle: string = this.cs.LEGEND
  public chartSettingDatalabelTitle: string = this.cs.DATA_LABEL
  public exportTitle: string = this.cs.EXPORT
  public showRRIndexTitle: string = this.cs.SHOW_RR_INDEX

  constructor(public elem: ElementRef, public cs: ConstantService) {}

  ngOnInit() {
    this.updateExportItems(this.showExportExcel, this.showExportPPT, this.showExportMultiPPT)
  }

  /** tree select */
  public accountTreeWithCheckboxSelect(selectedAccountNodes: SelectedTreeNode[]): void {
    this.accountTreeWithCheckbox.select(selectedAccountNodes)
  }

  public accountTreeWithoutCheckboxSelect(selectedAccountNodes: SelectedTreeNode[]): void {
    this.accountTreeWithoutCheckbox.select(selectedAccountNodes)
  }

  public productTreeWithCheckboxSelect(selectedProductNodes: SelectedTreeNode[]): void {
    this.productTreeWithCheckbox.select(selectedProductNodes)
  }

  public productTreeWithoutCheckboxSelect(selectedProductNodes: SelectedTreeNode[]): void {
    this.productTreeWithoutCheckbox.select(selectedProductNodes)
  }

  /** export items */
  private updateExportItems(showExportExcel: boolean, showExportPPT: boolean, showExportMultiPPT: boolean) {
    this.exportItems = []
    if(showExportExcel) {
      this.exportItems.push({Value:"excel", Name:this.cs.EXCEL})  // if there is grid, then export the excel of the grid data. when there is no grid, just export the data of the chart
    }

    if(showExportPPT) {
      this.exportItems.push({Value:"ppt", Name:this.cs.SINGLE_PPT})
    }

    if(showExportMultiPPT) {
      this.exportItems.push({Value:"multippt", Name:this.cs.MULTI_PPT})
    }
  }

  /** export with button group */
  export(id: string){
    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = id

    this.getSelectedExportEvent.emit({selectedItem});
  }

  toggleLegend(event: any) {
    let legend: any = {show: false}
    if(event.checked) {
      legend['show'] = true
    } else {
      legend['show'] = false
    }
    this.showLegendEvent.emit({legend})
  }

  toggleDataLabel(event: any) {
    let dataLabel: any = {show: false}
    if(event.checked) {
      dataLabel['show'] = true
    } else {
      dataLabel['show'] = false
    }
    this.showDataLabelEvent.emit({dataLabel})
  }

  toggleRestRequirementIndex(event: any) {
    let index: any = {show: false}
    if(event.checked) {
      index['show'] = true
    } else {
      index['show'] = false
    }
    this.showRestRequirementIndexEvent.emit({index})
  }
}
