import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../../layout/layout.component';
import { TwoDimensionalComponent } from './two-dimensional/two-dimensional.component';
import { TableOfFactsComponent } from './table-of-facts/table-of-facts.component';
import { ThreeDimensionalComponent } from './three-dimensional/three-dimensional.component';
import { KpiComparisonComponent } from './kpi-comparison/kpi-comparison.component';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { DashboardId } from '../../shared/model/constants';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.table_of_facts, pathMatch: 'full' },
      // { path: DashboardId.two_dimentional, component: TwoDimensionalComponent },
      { path: DashboardId.table_of_facts, component: TableOfFactsComponent },
      // { path: DashboardId.3_dimentional, component: ThreeDimensionalComponent },
      { path: DashboardId.kpi_comparison, component: KpiComparisonComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
