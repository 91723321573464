// ***************************************************************
// here are only ids, so they are not relevant to the user language
// ***************************************************************
export enum DashboardId {
  definition_of_key_facts = "definition-of-key-facts",
  analysis_of_strengths_and_weaknesses = "analysis-of-strengths-and-weaknesses",
  purchase_behavior = "purchase-behavior",
  loyalty_propensity_ranking = "loyalty-propensity-ranking",
  structure_of_account_clients = "structure-of-account-clients",
  market_shares = "market-shares",              // implemented but not used
  market_share_development = "market-share-development",
  market_share_components = "market-share-components",
  loyalty_simulation = "loyalty-simulation",
  decomposition_tree = "decomposition-tree",
  distribution_top_x = "distribution-top-x",
  distribution_table = "distribution-table",    // not implemented yet
  actual_vs_expected = "actual-vs-expected",    // not implemented yet
  account_balance = "account-balance",
  price_performance = "price-performance",
  promotion_performance = "promotion-performance",
  assortment_performance = "assortment-performance",
  demographics_chart = "demographics-chart",
  table_demographics = "table-demographics",
  basket_analysis = "basket-analysis",
  two_dimentional = "2-dimentional",            // not implemented yet
  three_dimentional = "3-dimentional",          // not implemented yet
  table_of_facts = "table-of-facts",
  kpi_comparison = "kpi-comparison",
  standard_selection = "standard-selection",
}

export const AllDashboardsInStandardSelection = [
  DashboardId.definition_of_key_facts,
  DashboardId.analysis_of_strengths_and_weaknesses,
  DashboardId.purchase_behavior,
  DashboardId.loyalty_propensity_ranking,
  DashboardId.structure_of_account_clients,
  DashboardId.market_share_development,
  DashboardId.market_share_components,
  DashboardId.loyalty_simulation,
  DashboardId.decomposition_tree,
  DashboardId.distribution_top_x,
  DashboardId.account_balance,
  DashboardId.price_performance,
  DashboardId.promotion_performance,
  DashboardId.assortment_performance,
  DashboardId.demographics_chart,
  DashboardId.table_demographics,
  DashboardId.basket_analysis,
  DashboardId.table_of_facts,
  DashboardId.kpi_comparison,
  DashboardId.standard_selection,
]

export enum MatModeId {
  MAT_both = "MAT-both",
  MAT_current_percent = "MAT-current-percent",
  MAT_current_abs = "MAT-current-abs"
}

export enum FactTypeId {
  percent = "percent",
  index = "index"
}

export enum SwitchId {
  Buyers = "Buyers",
  Expenditures = "Expenditures",
  Expenditures_incl_RR = "Expenditures_incl_RR"
}

export enum TableTypeId {
  Table = "Table",
  Pivot1 = "Pivot1",
  Pivot2 = "Pivot2",
}

export enum ChangeId {
  show = "show",
  hide = "hide"
}

export enum MetricId {
  percentage = "percentage",
  value = "value"
}

export enum BarStyleId {
  horizontal = "horizontal",
  vertical = "vertical"
}

export enum DemographicSwitchId {
  IndexTotalExpenditureMarket = "IndexTotalExpenditureMarket",
  PercentDistribution = "PercentDistribution",
  IndexProductAccount = "IndexProductAccount"
}

export enum BasisId {
  expenditures = "expenditures",
  buyers = "buyers"
}

export enum MarketShareExpenditureId {
  marketshare = "market-share",
  expenditure = "expenditure"
}

export enum ClientBuyerIds {
  clients = "clients",
  buyers = 'buyers'
}

export enum ChangeRateIds {
  changeInValue = "changeInValue",
  changeInPercentage = "changeInPercentage"
}

export enum ModeIds {
  OneAccountMultiProducts = "OneAccountMultiProducts",
  OneProductMultiAccounts = "OneProductMultiAccounts"
}

export enum SortingIds {
  ranking = "ranking",
  clickOrder = "clickOrder"
}

// the column names in source data
export enum Columns {
  Period = "PerNum",
  ShopperGroup = "MBDNum",
  Account = "BGNum",
  Product = "ProdNum",
  Soz = "SozNum",
  Fact = "Fact",
  Value = "Value",
}

// the dimensions in backend dimensions table
export enum Dimensions {
  Period = "Period",
  ShopperGroup = "ShopperGroup",
  Account = "Account",
  Product = "Product",
  SOC = "SOC",
  Fact = "Fact",
  AccountTree = "AccountTree",
  ProductTree = "ProductTree",
  Tree = "Tree",
}

export enum PPTIds {
  //options
  MaxValueIsHundred = "MaxValueIsHundred",
  LeftValAxisTitle = "LeftValAxisTitle",
  RightValAxisTitle = "RightValAxisTitle",
  ValAxisTitle = "ValAxisTitle",
  ValAxisTitle2 = "ValAxisTitle2",
  DataLabelFormatCode2 = "DataLabelFormatCode2",
  Dataseries = "Dataseries",
  Account = "Account",
  period1 = "period1",
  period1Value = "period1Value",
  period2 = "period2",
  period2Value = "period2Value",
  master = "master",

  // types
  MultiColumnsAndBarChart = "MultiColumnsAndBarChart",
  MultiColumns = "MultiColumns",
  ColumnAndDot = "ColumnAndDot",
  ColumnAndLine = "ColumnAndLine",
  ThreeBarCharts = "ThreeBarCharts",
  TwoBarCharts = "TwoBarCharts",
  TwoPieTwoBarCharts = "TwoPieTwoBarCharts",
  StackedColumns = "StackedColumns",
  MultiBars = "MultiBars",
}

// general Ids
export enum Ids {
  Label = "Label",   //used for bubble chart, bar chart and pie chart, for example, to display the product name and the bubble size value. it is calculated in frontend
  Label1 = "Label1",
  Label2 = "Label2",
  Label3 = "Label3",
  Label4 = "Label4",
  NoLabel = "NoLabel",     //used in pie chart, so that its data labe can be hidden.
  Benchmark = "Benchmark",
  Description = "Description",
  HH = "HH",

  Penetration_Prefix = "Penetration_",
  Penetration_SingleSeries = "Penetration_SingleSeries",
  Penetration_2Series = "Penetration_2Series",
  Penetration_To = "Penetration_To",
  Penetration_From = "Penetration_From",
  Penetration_Net = "Penetration_Net",
  Penetration_Row_Value = "Penetration_Row_Value",
  Penetration_Row = "Penetration_Row",
  Penetration_To_Value = "Penetration_To_Value",
  Penetration_From_Value = "Penetration_From_Value",
  Penetration_current = "Penetration_current",
  Penetration_previous = "Penetration_previous",
  hasPenetrationWarning = "hasPenetrationWarning",
  results = "results",

  content = "content",

  Series = "Series",
  Series1 = "Series1",
  Series2 = "Series2",
  Series3 = "Series3",
  Series4 = "Series4",
  Series_current = "Series_current",
  Series_previous = "Series_previous",
  value = "value",
  value1 = "value1",
  value2 = "value2",
  value3 = "value3",
  value4 = "value4",
  value_current = "value_current",
  value_previous = "value_previous",
  value_diff_absolute = "value_diff_absolute",
  value_diff_in_percent = "value_diff_in_percent",
  index = "index",
  index_yaxis = "index_yaxis",
  Category = "Category",
  Value_Account_To = "Value_Account_To",
  Value_Account_From = "Value_Account_From",
  Value_Account_Net = "Value_Account_Net",
  Label_Account_To = "Label_Account_To",
  Label_Account_From = "Label_Account_From",
  Label_Account_Net = "Label_Account_Net",
  Label_current = "Label_current",
  Label_previous = "Label_previous",

  Account = "Account",
  Account_Competitor = "Account_Competitor",
  Product = "Product",
  MAccount = "MAccount",
  MPRODUCT = "MPRODUCT",
  Accounts_Placeholder = "Accounts_Placeholder",
  Products_Placeholder = "Products_Placeholder",

  table = "table",
  twoTables = "twoTables",

  percent = "percent",
  number = "number",

  Type = "Type",
  Value = "Value",
  Summary = "Summary",
  F7_Period1 = "F7_Period1",
  F7_Period2 = "F7_Period2",
  F8_Period1 = "F8_Period1",
  F8_Period2 = "F8_Period2",
  Lost_Expenditure_Percent_Period1 = "Lost_Expenditure_Percent_Period1",
  Lost_Expenditure_Percent_Period2 = "Lost_Expenditure_Percent_Period2",
  Lost_Expenditure_Index_Period1 = "Lost_Expenditure_Index_Period1",
  Lost_Expenditure_Index_Period2 = "Lost_Expenditure_Index_Period2",

  Produkt_Account = "Produkt_Account",

  Id = "Id",
  Name = "Name",
  Item = "Item",
  GroupId = "GroupId",
  isSection = "isSection",
  Group = "Group",
  isVisible = "isVisible",
  state = "state",
  type = "type",

  FactNum = "FactNum",
  FactName = "FactName",
  ProdNum = "ProdNum",
  ProdName = "ProdName",
  BGNum = "BGNum",
  BGName = "BGName",

  Radius = "Radius",

  Changes = "Changes",
  Contribution = "Contribution",
  Change = "Change",

  Color = "Color",
  BGNum_Id = "BGNum_Id"
}

export enum CallAPIModes {
  GetAPI = "Call_get_api", // The selected accounts with all products, or the selected products with all accounts
  GetAPIWithTotal = "Call_get_api_with_total",  // The selected accounts and total account with all products, or the selected products and total product with all accounts
  FilterAPI = "Call_filter_api",  // The selected accounts with the selected products
  FilterAPIWithTotal = "Call_filter_api_with_total",  // The selected accounts and total account with the selected products and total product
}

export enum UserSelectionIds {
  Account = "Account",
  AccountFocus = "AccountFocus",
  AccountCompetitor = "AccountCompetitor",
  Product = "Products",
  Period1 = "Period1",
  Period2 = "Period2",
  Periods = "Periods",
  ShopperGroup = "ShopperGroup",
  Switch = "Switch",
  Basis = "Basis",
  Fact = "Fact",
  Mode = "Mode",
  Sorting = "Sorting",
  Legend = "Legend",
  DataLabel = "DataLabel",
  Benchmark = "Benchmark",
  ChangeRate = "ChangeRate",
  Selection = "Selection",
  ShowChart = "ShowChart",
  FactIds = "FactIds",
  FactNames  ="FactNames",
  DemographicGroups = "DemographicGroups",
  BenchmarkAccount = "BenchmarkAccount",
  BenchmarkProduct = "BenchmarkProduct",
  Row =  "Row",
  Column = "Column",
  MatMode = "MatMode",
  ColumnNumber = "ColumnNumber",
  FilterAccount = "FilterAccount",
  FilterProduct = "FilterProduct",
  FilterFact = "FilterFact",
  FilterShopperGroup = "FilterShopperGroup",
  Style = "Style",
  ChartType = "ChartType",
  BubbleSize = "BubbleSize",
  FactType = "FactType",
}

export const SelectFactTypeData = [
  {Id: 'percent', Name: 'Kennziffern in %'},
  {Id: 'index', Name: 'Kennziffern als Index'},
]

export const NoFilterSelected: any = {Id: "None", Name: "-"}

export const NoBubbleSizeFact: any = {Id: "None", Name: "gleiche Bubblegröße/kein Fact"}

export const SelectNumberOfColumnsData = [
  {Id: 1, Name: "1"},
  {Id: 2, Name: "2"},
  {Id: 3, Name: "3"},
  {Id: 4, Name: "4"},
  {Id: 5, Name: "5"},
  {Id: 6, Name: "6"},
  {Id: 7, Name: "7"},
  {Id: 8, Name: "8"},
  {Id: 9, Name: "9"},
  {Id: 10, Name: "10"}
]

/** Used in dashboard "market share", which (dashboard) is not needed anymore */
export enum ChartType {
  Bar = "bar",
  Column = "column",
  Pie = "pie"
}

export const SelectChartTypeData = [
  {Id: ChartType.Bar, Name: "Balkendiagramm"},
  {Id: ChartType.Column, Name: "Säulendiagramm"},
  {Id: ChartType.Pie, Name: "Kuchendiagramm"}
]
