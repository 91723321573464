import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';

import { concatMap, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";

import { Store } from "@ngrx/store";
import { RESTService } from "../services/rest.service";
import { DashboardAppState } from './dashboard.reducer';
import { fromDashboard } from "./dashboard.selector";
import { DashboardActions } from "./dashboard.actions";


@Injectable()
export class DashboardEffects {
  constructor(private actions$: Actions, private restService: RESTService, private store: Store<DashboardAppState>) {}

  dataGet$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.dataGet),
      concatLatestFrom(()=>this.store.select(fromDashboard.selectDataLoadStatus)),
      // concatMap((action) =>
      //   of(action).pipe(
      //     withLatestFrom(this.store.select(fromDashboard.selectDataLoadStatus))
      // )),
      filter(([, dataLoadStatus]) => dataLoadStatus === 'NOT_LOADED'),
      map((action) => DashboardActions.dataLoad(action[0]))
    )
  );

  dataLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.dataLoad),
      mergeMap((action) =>
        this.restService.getData(action.sourceTable, action.selectedAccounts, action.selectedProducts, action.facts).pipe(
          map((data) =>
              DashboardActions.dataLoaded({
              data,
            })
          ),
          // catchError((error) => of(SalesActions.loadSalesFailure({ error })))
        )
      )
    )
  );

  dimGet$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.dimGet),
      concatLatestFrom(()=>this.store.select(fromDashboard.selectDimLoadStatus)),
      // concatMap((action) =>
      //   of(action).pipe(
      //     withLatestFrom(this.store.select(fromDashboard.selectDimLoadStatus))
      // )),
      filter(([, dimLoadStatus]) => dimLoadStatus === 'NOT_LOADED'),
      map((action) => DashboardActions.dimLoad())
    )
  );

  dimLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.dimLoad),
      mergeMap((action) =>
        this.restService.getDimensions().pipe(
          map((dim) =>
              DashboardActions.dimLoaded({
              dim
            })
          ),
          // catchError((error) => of(SalesActions.loadSalesFailure({ error })))
        )
      )
    )
  );

  filterDataGet$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.filterDataGet),
      concatLatestFrom(()=>this.store.select(fromDashboard.selectFilterDataLoadStatus)),
      // concatMap((action) =>
      //   of(action).pipe(
      //     withLatestFrom(this.store.select(fromDashboard.selectFilterDataLoadStatus))
      // )),
      filter(([, dataLoadStatus]) => dataLoadStatus === 'NOT_LOADED'),
      map((action) => DashboardActions.filterDataLoad(action[0]))
    )
  );

  filterDataLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.filterDataLoad),
      mergeMap((action) =>
        this.restService.filterData(action.sourceTable, action.accounts, action.products, action.periods, action.facts).pipe(
          map((filterData) =>
              DashboardActions.filterDataLoaded({
              filterData
            })
          ),
          // catchError((error) => of(SalesActions.loadSalesFailure({ error })))
        )
      )
    )
  );

  hhGet$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.hhGet),
      concatLatestFrom(()=>this.store.select(fromDashboard.selectHHLoadStatus)),
      // concatMap((action) =>
      //   of(action).pipe(
      //     // withLatestFrom(this.store.select(fromDashboard.selectHHLoadStatus))
      // )),
      filter(([, hhLoadStatus]) => hhLoadStatus === 'NOT_LOADED'),
      map((action) => DashboardActions.hhLoad())
    )
  );

  hhLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.hhLoad),
      mergeMap((action) =>
        this.restService.getHH().pipe(
          map((hh) =>
              DashboardActions.hhLoaded({
              hh
            })
          ),
          // catchError((error) => of(SalesActions.loadSalesFailure({ error })))
        )
      )
    )
  );

  selectionGet$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.selectionGet),
      concatLatestFrom(()=>this.store.select(fromDashboard.selectSelectionLoadStatus)),
      filter(([, selectionloadStatus]) => selectionloadStatus === 'NOT_LOADED'),
      map((action) => DashboardActions.selectionLoad(action[0]))
    )
  );

  selectionLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.selectionLoad),
      mergeMap((action) =>
        this.restService.getSelection(action.dashboard).pipe(
          map((selection) =>
          DashboardActions.selectionLoaded({
            selection,
            })
          ),
        )
      )
    )
  );

  // Selection Template
  selectionTemplateLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.selectionTemplatesLoad),
      mergeMap((action) =>
        this.restService.getSelectionTemplate(action.dashboard).pipe(
          map((selection) =>
          DashboardActions.selectionLoaded({
            selection,
            })
          ),
        )
      )
    )
  );  

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.selectionSave),
      mergeMap((action) =>
        this.restService.addOrUpdateSelection(action.dashboards, action.selection).pipe(
          map((res) =>
            DashboardActions.selectionSaved()
          ),
        )
      )
    )
  );
}
