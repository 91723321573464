<igx-tabs tabAlignment="start" [disableAnimation]="true" >
  <igx-tab-item [selected]="true" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>store_mall_directory</igx-icon>
      <span igxTabHeaderLabel >{{tabAccountTitle}}</span>
    </igx-tab-header>
    <igx-tab-content >
      <ng-container *ngIf="treeAccountIsMulti; else oneAccount">
        <app-tree-with-checkbox-with-ten-levels #accountTreeWithCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedAccountNodeLevels" [selectedNodes]="selectedAccountNodes"></app-tree-with-checkbox-with-ten-levels>
      </ng-container>
      <ng-template #oneAccount>
        <app-tree-without-checkbox-with-ten-levels #accountTreeWithoutCheckbox [data]="filterAccountData" [name]="filterAccountTitle" (getActivatedNodeEvent)="getSelectedNodes($event)" [selectedNodes]="selectedAccountNodes"></app-tree-without-checkbox-with-ten-levels>
      </ng-template>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>shopping_cart</igx-icon>
      <span igxTabHeaderLabel>{{tabProductTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div *ngIf="treeProductIsMulti; else oneProduct">
        <app-tree-with-checkbox-with-ten-levels #productTreeWithCheckbox [data]="filterProductData" [name]="filterProductTitle" (getSelectedNodesEvent)="getSelectedNodes($event)" [selectedNodeLevels]="selectedProductNodeLevels" [selectedNodes]="selectedProductNodes"></app-tree-with-checkbox-with-ten-levels>
      </div>
      <ng-template #oneProduct>
        <app-tree-without-checkbox-with-ten-levels #productTreeWithoutCheckbox [data]="filterProductData" [name]="filterProductTitle" (getActivatedNodeEvent)="getSelectedNodes($event)" [selectedNodes]="selectedProductNodes"></app-tree-without-checkbox-with-ten-levels>
      </ng-template>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false">
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>euro_symbol</igx-icon>
      <span igxTabHeaderLabel>{{tabPeriodFactTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <ng-container *ngIf="showOneSinglePeriodFilter">
          <app-single-select-radio [name]="filterPeriod1Title" [items]="filterPeriod1Data" [selected]="selectedPeriod1Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)"></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showTwoSinglePeriodFilters">
            <app-single-select-radio [name]="filterPeriod1Title" [items]="filterPeriod1Data" [selected]="selectedPeriod1Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)" ></app-single-select-radio>
            <app-single-select-radio [name]="filterPeriod2Title" [items]="filterPeriod2Data" [selected]="selectedPeriod2Item.Id" (getSelectedItemEvent)="getSelectedPeriodItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showSingleFactFilter">
          <app-single-select-radio [name]="selectSingleFactTitle" [items]="selectSingleFactData" [selected]="selectedSingleFactItem.Id" (getSelectedItemEvent)="getSelectedSingleFact($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showMultiFactsFilter">
          <app-multi-select-combo [name]="selectMultiFactsTitle" [items]="selectMultiFactsData" [selected]="selectedMultiFactItemIds" (getSelectedItemsEvent)="getSelectedMultiFacts($event)" ></app-multi-select-combo>
        </ng-container>

        <ng-container *ngIf="showMultiFactsCheckboxFilter">
            <app-multi-select-checkbox [name]="selectMultiFactsTitle" [items]="selectMultiFactsData" (getCheckedItemsEvent)="getSelectedMultiFacts($event)"></app-multi-select-checkbox>
        </ng-container>
      </div>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [selected]="false" [disabled]="false" >
    <igx-tab-header>
      <igx-icon igxTabHeaderIcon>insert_chart_outlined</igx-icon>
      <span igxTabHeaderLabel>{{tabSelectionTitle}}</span>
    </igx-tab-header>
    <igx-tab-content>
      <div class="tab-padding">
        <ng-container *ngIf="showDemographicsChartSwitchFilter">
          <app-single-select-radio [name]="filterDemographicsChartSwitchTitle" [items]="filterDemographicsChartSwitchData" [selected]="selectedDemographicsChartSwitchItem.Id" (getSelectedItemEvent)="getSelectedDemographicsChartSwitchItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showDemographicsChartBasisFilter">
          <app-single-select-radio [name]="filterDemographicsChartBasisTitle" [items]="filterDemographicsChartBasisData" [selected]="selectedDemographicsChartBasisItem.Id" (getSelectedItemEvent)="getSelectedDemographicsChartBasisItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showSingleDemographicFilter">
          <app-single-select-radio [name]="filterSingleDemographicTitle" [items]="filterSingleDemographicData" [selected]="selectedSingleDemographicItem.Id" (getSelectedItemEvent)="getSelectedSingleDemographicItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showFilterMode">
          <app-single-select-radio [name]="filterModeTitle" [items]="filterModeData" [selected]="selectedModeItem.Id" (getSelectedItemEvent)="getSelectedFilterModeItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showDemographicsChartBarsFilter">
          <app-single-select-radio [name]="filterDemographicsChartBarsTitle" [items]="filterDemographicsChartBarsData" [selected]="selectedDemographicsChartBarsItem.Id" (getSelectedItemEvent)="getSelectedDemographicsChartBarsItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showFilterBenchmark">
          <div class="flex flex-col">
            <app-single-select [name]="filterBenchmarkTitle" [items]="filterBenchmarkData" [selected]="selectedBenchmarkItem.Id" (getSelectedItemEvent)="getSelectedBenchmarkItem($event)" [hasSeparateLabel]="true" ></app-single-select>
            <ng-container *ngIf="showNoBenchmarkSelectedError">
              <span class="text-red-500 text-sm">{{benchmarkWarning}}</span>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="showFilterBenchmark2">
          <div class="flex flex-col">
            <app-single-select [name]="filterBenchmark2Title" [items]="filterBenchmark2Data" [selected]="selectedBenchmark2Item.Id" (getSelectedItemEvent)="getSelectedBenchmark2Item($event)" [hasSeparateLabel]="true" ></app-single-select>
          </div>
        </ng-container>

        <ng-container *ngIf="showFilterShopperGroup">
            <app-single-select-radio [name]="filterShopperGroupTitle" [items]="filterShopperGroupData" [selected]="selectedShopperGroupItem.Id" (getSelectedItemEvent)="getSelectedShopperGroupItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showStaticFilter">
          <app-single-select-radio [name]="selectStaticFilterTitle" [items]="selectStaticFilterData" [selected]="selectedStaticFilterItem.Id" (getSelectedItemEvent)="getSelectedStaticFilterItem($event)" ></app-single-select-radio>
        </ng-container>

        <ng-container *ngIf="showMultiDemographicFilter">
          <div class="flex flex-col">
            <div class="tab-heading">{{filterDemographicGroupTitle}}</div>
            <igx-checkbox *ngFor="let item of DemographicGroups" [checked]="item.state" [value]="item.Group" (change)="onChangeDemographicGroup($event, item.Group)">
                  {{ item.Group }}
            </igx-checkbox>
          </div>
        </ng-container>

        <div class="flex flex-col">
          <ng-container *ngIf="showCheckboxLegend || showCheckboxDataLabel">
            <div class="tab-heading">{{chartSettingTitle}}</div>
          </ng-container>

          <ng-container *ngIf="showCheckboxLegend">
            <igx-switch labelPosition="after" [checked]="showLegend" (change)="toggleLegend($event)">{{chartSettingLegendTitle}}</igx-switch>
          </ng-container>

          <ng-container *ngIf="showCheckboxDataLabel">
            <igx-switch labelPosition="after" [checked]="showDataLabel" (change)="toggleDataLabel($event)">{{chartSettingDatalabelTitle}}</igx-switch>
          </ng-container>
        </div>

        <ng-container *ngIf="showExportExcel || showExportPPT">
          <div class="tab-heading">{{exportTitle}}</div>
          <igx-buttongroup>
            <button igxButton="outlined" *ngFor="let item of exportItems" (buttonClick)="export(item.Value)">{{item.Name}}</button>
          </igx-buttongroup>
        </ng-container>
      </div>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>


