<div class="penetration-warning-wrapper" id="p-w-div">
  <div class="penetration-warning" [igxTooltipTarget]="tooltipRef">
    {{penetrationWarning}}
  </div>

  <div #tooltipRef="tooltip" igxTooltip>
    {{penetrationWarningTooltip}}

  </div>

  <div class="penetration-warning-switch" [hidden]="hideSwitch">
    <igx-switch
    [checked]="isChecked"
    (change)="onChange($event)"
    >
      {{hidePenetrationWarning}}
    </igx-switch>
  </div>
</div>
