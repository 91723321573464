import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthFacade } from '../../state/auth.facade';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {
  isForgettingPassword$ = this.authFacade.isForgettingPassword$;
  hasForgetPasswordError$ = this.authFacade.hasForgetPasswordError$;
  isForgetPasswordEmailSent$ = this.authFacade.isForgetPasswordEmailSent$;

  readonly forgetPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', {
      validators: [
        Validators.required,
        Validators.pattern("[^ @]*@[^ @]*")
      ],
    }),
  });

  constructor(private authFacade: AuthFacade) { }

  submit() {
    const { email } = this.forgetPasswordForm.value;
    this.authFacade.forgetPassword(email);
  }


}
