<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"
    [showMultiFactsCheckboxFilter]="true" [selectMultiFactsTitle]="factTitle" [selectMultiFactsData]="tableDemograhicsFactData" [getSelectedMultiFacts]="getSelectedTableDemograhicsFacts"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"

    [showFilterBenchmark]="true" [filterBenchmarkTitle]="benchmarkAccountTitle" [filterBenchmarkData]="benchmarkAccountData" [selectedBenchmarkItem]="selectedBenchmarkAccountItem" [getSelectedBenchmarkItem]="getSelectedBenchmarkAccountItem"
    [showFilterBenchmark2]="true" [filterBenchmark2Title]="benchmarkProductTitle" [filterBenchmark2Data]="benchmarkProductData" [selectedBenchmark2Item]="selectedBenchmarkProductItem" [getSelectedBenchmark2Item]="getSelectedBenchmarkProductItem"

    [showMultiDemographicFilter]="true" [filterDemographicGroupTitle]="filterDemographicGroupTitle" [DemographicGroups]="DemographicGroups" (getChangeDemographicGroupEvent)="getChangeDemographicGroupEvent($event)"

    [showExportExcel]="showExportExcel" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"              [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"              [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"              [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"              [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"    [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipBenchmarkAccountTitle]="benchmarkAccountTitle"  [selectedBenchmarkAccount]="selectedBenchmarkAccountItem"
      [chipBenchmarkProductTitle]="benchmarkProductTitle"  [selectedBenchmarkProduct]="selectedBenchmarkProductItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti" [showInfoBenchmarkAccount]="true" [showInfoBenchmarkProduct] = "true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
        <table class="demo-table" style="padding: 100px;" *ngIf="showTable">
          <tr>
              <td class="Demographics_Index_Corner"></td>
              <td [attr.colspan]="facts_items" *ngFor="let product of DATA_PRODUCT" class="Demographics_Index_Product"><strong>{{product.ProdName}}</strong> in Total Market</td>
              <td [attr.colspan]="facts_items" *ngFor="let account of DATA_ACCOUNT" class="Demographics_Index_Account">{{account.BGName}}</td>
          </tr>
          <tr>
              <td></td>
              <ng-container *ngFor="let product of DATA_PRODUCT">
                <ng-container *ngFor="let fact of DATA_FACTS">
                    <td class="demo-table-heading-column">{{fact.FactName}}</td>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let account of DATA_ACCOUNT">
                <ng-container *ngFor="let fact of DATA_FACTS">
                    <td class="demo-table-heading-column">{{fact.FactName}}</td>
                </ng-container>
              </ng-container>
          </tr>
          <ng-container *ngFor="let soz of DATA_TABLE_SOZ">
            <tr *ngIf="soz.isVisible" [ngClass]="{'isSection': soz.isSection}">
                <td>{{soz.Name}}</td>
                <ng-container *ngFor="let product of DATA_PRODUCT">
                  <ng-container *ngFor="let fact of DATA_FACTS">
                      <td class="demo-table-content-column">{{getValueOfProductWithTotalAccount(gridDataProduct, product.ProdNum, this.selectedPeriod1Item.Id, soz.Id, this.selectedShopperGroupItem.Id, fact.FactNum)}}</td>
                  </ng-container>
                </ng-container>
                <ng-container *ngFor="let account of DATA_ACCOUNT">
                  <ng-container *ngFor="let fact of DATA_FACTS">
                      <td class="demo-table-content-column">{{getValueOfAccountWithTotalProduct(gridDataAccount, account.BGNum, this.selectedPeriod1Item.Id, soz.Id, this.selectedShopperGroupItem.Id, fact.FactNum)}}</td>
                  </ng-container>
                </ng-container>
            </tr>
          </ng-container>
        </table>
    </div>
  </div>

  <div class="loading" *ngIf="(isFilterDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>



