<div class="container flex justify-center">
  <div class="form-wrapper">
    <h1 class="mb-8">
      Forget Password
    </h1>

    <form [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPasswordForm.valid && submit()">
      <div *ngIf="hasForgetPasswordError$ | async" class="mt-4 text-red-600">
        Error! Email is not found.
      </div>

      <div *ngIf="isForgetPasswordEmailSent$ | async" class="mt-4 text-green-600">
        Please check your email.
      </div>

      <div *ngIf="isForgettingPassword$ | async" class="mt-4 text-gray-600">
        <img src="../../../../assets/spinner.gif" alt="loading" width="40px" />
      </div>

      <div class="my-14">
        <igx-input-group type="border">
          <input igxInput name="email" type="text" formControlName="email" title="email"/>
          <label igxLabel for="email">Email</label>
        </igx-input-group>
      </div>

      <div class="flex items-center">
        <button
          type="submit"
          [disabled]="(!forgetPasswordForm.valid || (isForgettingPassword$ | async))"
          class="authButton"
        >
          Send Email
        </button>

        <button
          igxButton="raised"
          igxRipple
          class="authButton ml-8"
          routerLink="../login"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
