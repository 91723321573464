import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { IgxButtonModule, IgxCheckboxModule, IgxComboModule, IgxDialogModule, IgxDropDownModule, IgxIconModule, IgxNavbarModule, IgxPivotGridModule, IgxRadioModule, IgxRippleModule, IgxSelectModule, IgxSwitchModule, IgxTabsModule, IgxToastModule, IgxToggleModule, IgxTooltipModule, IgxTreeGridModule, IgxTreeModule } from '@infragistics/igniteui-angular';
import { IgxCalloutLayerModule, IgxCategoryChartModule, IgxDataChartCoreModule, IgxDataChartInteractivityModule, IgxDataChartScatterCoreModule, IgxDataChartScatterModule, IgxDataChartStackedModule, IgxDataChartVerticalCategoryModule, IgxItemLegendModule, IgxLegendModule, IgxPieChartModule, IgxValueOverlayModule } from 'igniteui-angular-charts';

import { SingleSelectComponent } from './components/single-select/single-select.component';
import { MultiSelectComboComponent } from './components/multi-select-combo/multi-select-combo.component';
import { FilterTabsComponent } from './components/filter-tabs/filter-tabs.component';
import { ExportMultiPptDialogComponent } from './components/export-multi-ppt-dialog/export-multi-ppt-dialog.component';
import { TreeWithoutCheckboxWithTenLevelsComponent } from './components/tree/tree-without-checkbox-with-ten-levels/tree-without-checkbox-with-ten-levels.component';
import { TreeWithCheckboxWithTenLevelsComponent } from './components/tree/tree-with-checkbox-with-ten-levels/tree-with-checkbox-with-ten-levels.component';
import { FilterTabsTableOfFactsComponent } from './components/filter-tabs-table-of-facts/filter-tabs-table-of-facts.component';
import { FilterTabsKpiComparisionComponent } from './components/filter-tabs-kpi-comparision/filter-tabs-kpi-comparision.component';
import { TreeWithCheckboxCascadingComponent } from './components/tree/tree-with-checkbox-cascading/tree-with-checkbox-cascading.component';
import { SingleSelectRadioComponent } from './components/single-select-radio/single-select-radio.component';
import { MultiSelectCheckboxComponent } from './components/multi-select-checkbox/multi-select-checkbox.component';
import { ChipBarTableComponent } from './components/chip-bar-table/chip-bar-table.component';
import { PenetrationWarningComponent } from './components/penetration-warning/penetration-warning.component';
import { FilterTabsRestRequirementComponent } from './components/filter-tabs-rest-requirement/filter-tabs-rest-requirement.component';
import { IgxExcelModule } from 'igniteui-angular-excel';
import { ChartMasterNavigationComponent } from './components/chart-master-navigation/chart-master-navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { BoldSpanPipe } from '../pipes/bold-span.pipe';

@NgModule({
  declarations: [
    //Shared Components
    SingleSelectComponent,
    MultiSelectComboComponent,
    FilterTabsComponent,
    FilterTabsTableOfFactsComponent,
    ExportMultiPptDialogComponent,
    TreeWithoutCheckboxWithTenLevelsComponent,
    TreeWithCheckboxWithTenLevelsComponent,
    FilterTabsKpiComparisionComponent,
    TreeWithCheckboxCascadingComponent,
    SingleSelectRadioComponent,
    MultiSelectCheckboxComponent,
    ChipBarTableComponent,
    PenetrationWarningComponent,
    FilterTabsRestRequirementComponent,
    ChartMasterNavigationComponent,
    BoldSpanPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,

    IgxButtonModule,
    IgxRippleModule,
    IgxIconModule,
    IgxToggleModule,
    IgxDropDownModule,
    IgxRadioModule,
    IgxTabsModule,
    IgxCategoryChartModule,
    IgxLegendModule,
    IgxTooltipModule,
    IgxComboModule,
    IgxPivotGridModule,
    IgxDialogModule,
    IgxTreeModule,
    IgxSelectModule,
    IgxNavbarModule,
    IgxCheckboxModule,
    IgxSwitchModule,
    IgxToastModule,
    IgxTreeGridModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,

    BoldSpanPipe,

    //UI
    TreeWithoutCheckboxWithTenLevelsComponent,
    TreeWithCheckboxWithTenLevelsComponent,
    SingleSelectComponent,
    MultiSelectComboComponent,
    FilterTabsComponent,
    FilterTabsTableOfFactsComponent,
    FilterTabsKpiComparisionComponent,
    FilterTabsRestRequirementComponent,
    ExportMultiPptDialogComponent,
    TreeWithCheckboxCascadingComponent,
    SingleSelectRadioComponent,
    MultiSelectCheckboxComponent,
    ChipBarTableComponent,
    PenetrationWarningComponent,
    ChartMasterNavigationComponent,

    //Ignite libs
    IgxCheckboxModule,
    IgxCategoryChartModule,
    IgxLegendModule,
    IgxPivotGridModule,
    IgxDataChartCoreModule,
    IgxDataChartScatterModule,
    IgxDataChartScatterCoreModule,
    IgxDataChartInteractivityModule,
    IgxValueOverlayModule,
    IgxCalloutLayerModule,
    IgxDataChartVerticalCategoryModule,
    IgxPieChartModule,
	  IgxItemLegendModule,
    IgxTabsModule,
    IgxExcelModule,
    IgxDataChartStackedModule,
    IgxToastModule,
    IgxTreeGridModule,
    IgxDialogModule,
  ]
})
export class SharedDashboardComponentModule { }
