import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../../layout/layout.component';
import { MarketShareComponentsComponent } from './market-share-components/market-share-components.component';
import { MarketShareDevelopmentComponent } from './market-share-development/market-share-development.component';
import { MarketSharesComponent } from './market-shares/market-shares.component';
import { LoyaltySimulationComponent } from './loyalty-simulation/loyalty-simulation.component';
import { DecompositionTreeComponent } from './decomposition-tree/decomposition-tree.component';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { DashboardId } from '../../shared/model/constants';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.market_share_development, pathMatch: 'full' },
      // { path: DashboardId.market_shares, component: MarketSharesComponent }, //there should be no market-shares
      { path: DashboardId.market_share_development, component: MarketShareDevelopmentComponent },
      { path: DashboardId.market_share_components, component: MarketShareComponentsComponent },
      { path: DashboardId.loyalty_simulation, component: LoyaltySimulationComponent },
      { path: DashboardId.decomposition_tree, component: DecompositionTreeComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketShareRoutingModule { }
