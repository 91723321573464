import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { IgxTreeComponent, IgxTreeNode, IgxTreeSearchResolver } from '@infragistics/igniteui-angular';

import { TreeNode } from '../../../../model/interfaces';

@Component({
  selector: 'app-tree-without-checkbox-with-ten-levels',
  templateUrl: './tree-without-checkbox-with-ten-levels.component.html',
  styleUrls: ['./tree-without-checkbox-with-ten-levels.component.scss']
})
export class TreeWithoutCheckboxWithTenLevelsComponent implements OnChanges {
  @Input() public data!: TreeNode[];
  @Input() public name!: string;
  @Input() public topLevelIsExpanded: boolean = true;
  @Input() public selectedNodes!: any[]

  @Output() getActivatedNodeEvent = new EventEmitter<any>();

  @ViewChild('tree')
  public tree!: IgxTreeComponent;

  private init = true

  constructor() { }

  // once the data for the tree is given, wait for 1 second to init the tree view. After that add an event listener to each node
  ngOnChanges(changes: SimpleChanges): void {
    if(this.data.length > 0) {
        setTimeout(() => {
          if(this.init) { // since the selectedNodes becomes input, the ngOnChanges will be triggered every time when the selectedNodes changes. So we have to ensure the following code only run one time
            this.tree.nodes.forEach((node) => {
              node.nativeElement.addEventListener('click', (evt) =>
                this.onClick(node, evt)
              );
            });
            this.init = false
          }

          if(this.selectedNodes) {
            this.select(this.selectedNodes)
          }
        }, 1000);
    }
  }

  public onClick(node: any, event: any) {
    // console.log("node, event:", node, event, node.level)
    if (
      event.target.ariaLabel !== 'Expand' &&
      event.target.ariaLabel !== 'Collapse'
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.selectNode(node);

      let selectedNodesName = [node.data.Name]
      let selectedNodesId = [node.data.Id]
      let selectedNodesLevel = [node.level]
      let selectedNodesParent: any[] = []
      if(node.parentNode) {
        selectedNodesParent = [node.parentNode.data.Name]
      } else {
        selectedNodesParent = ["null"]
      }

      let selectedNodes = []
      for (let i = 0; i < selectedNodesName.length; i ++) {
        let node:any = {}
        node['Id'] = selectedNodesId[i]
        node['Name'] = selectedNodesName[i]
        node['Level'] = selectedNodesLevel[i]
        node['Parent'] = selectedNodesParent[i]
        selectedNodes.push(node)
      }

      const name = this.name
      this.getActivatedNodeEvent.emit({name, selectedNodes});
    }
  }

  public selectNode(clickedNode: any) {
    this.tree.nodes.forEach((node) => {
      node.selected = false;
    });
    clickedNode.selected = true;
  }

  public activeNodeChanged(event: IgxTreeNode<any>) {
    // when loading the page initially, event.data is undefined
    if (typeof event.data !== "undefined") {
      const selectedNodesName = [event.data.Name]
      const selectedNodesId = [event.data.Id]
      const selectedNodesLevel = [event.level]

      let selectedNodesParent: any[] = []
      if(event.parentNode) {
        selectedNodesParent = [event.parentNode.data.Name]
      } else {
        selectedNodesParent = ["null"]
      }

      const selectedNodes = []
      for (let i = 0; i < selectedNodesName.length; i ++) {
        let node:any = {}
        node['Id'] = selectedNodesId[i]
        node['Name'] = selectedNodesName[i]
        node['Level'] = selectedNodesLevel[i]
        node['Parent'] = selectedNodesParent[i]
        selectedNodes.push(node)
      }

      const name = this.name
      this.getActivatedNodeEvent.emit({name, selectedNodes});
    }
  }

  // used to load the selection when initializing the ui component
  select(nodes2Select: any[]) {
    // console.log("tree without checkbox: nodes2Select: ", nodes2Select)
    if(nodes2Select) {  // make sure nodes2Select is not null / undefined / empty string
      if(nodes2Select.length > 0) { // make sure nodes2Select is not empty list
        let nodes2SelectWithFirstElement = [nodes2Select[0]]  // get only the first item since its a tree without checkbox
        const comparer: IgxTreeSearchResolver = (data: any, node: IgxTreeNode<any>) => {
          if(node.parentNode) {
              return nodes2SelectWithFirstElement.some(r => r.Level === node.level && r.Name === node.data.Name && r.Parent === node.parentNode!.data.Name);
          } else {
              return nodes2SelectWithFirstElement.some(r => r.Level === node.level && r.Name === node.data.Name);
          }
        };

        const foundNodes: any = this.tree.findNodes(null, comparer);
        // console.log("this.tree: ", this.tree)
        // console.log("foundNodes: ", foundNodes)
        if(foundNodes) {
          for(let i of foundNodes ) {
            i.active = true
          }
        }
      }
    }
  }
}
