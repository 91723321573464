<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs-table-of-facts
    [getSelectedNodes]="getSelectedNodes"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [getSelectedPeriods]="getSelectedPeriods"
    [treeFactName]="treeFactName" [treeFactData]="treeFactData" [getSelectedFactNodes]="getSelectedFactNodes"

    [tabSelectionTitle]="tabSelectionTitle"
    [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [filterBenchmarkTitle]="filterBenchmarkTitle" [filterBenchmarkData]="filterProductBenchmarkData" [selectedBenchmarkItem]="selectedProductBenchmarkItem" [getSelectedBenchmarkItem]="getSelectedProductBenchmarkItem"
    [selectMetricsTitle]="selectMetricsTitle" [selectMetricsData]="selectMetricsData" [selectedMetricsItem]="selectedMetricsItem" [getSelectedMetricsItem]="getSelectedMetricsItem"
    [selectChangeTitle]="selectChangeTitle" [selectChangeData]="selectChangeData" [selectedChangeItem]="selectedChangeItem" [getSelectedChangeItem]="getSelectedChangeItem"

    [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
    (getSelectedTableTypeEvent)="getSelectedTableChartType($event)"
    (getSelectedExportEvent)="getSelectedExportEvent($event)"
    (getSelectedColumnsPivot1Event)="getSelectedColumnsPivot1Event($event)"
    (getSelectedColumnsPivot2Event)="getSelectedColumnsPivot2Event($event)"

    [select_col_items]="select_col_items"
    [select_col1_selected]="select_col1_selected"
    [select_col2_selected]="select_col2_selected"
    [select_col3_selected]="select_col3_selected"
    [select_col4_selected]="select_col4_selected"
    [select_col5_selected]="select_col5_selected"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs-table-of-facts>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">

      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [showFilterPeriod1]="false" [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <div [ngSwitch]="selectedTableChartTpye">
        <div *ngSwitchCase="tableType.Table">
          <table style="padding: 100px;" class="main_table">
            <thead>
              <tr>
                <th [attr.rowspan]="2">{{firstColumnName}}</th>
                <th *ngFor="let colFact of tableFactColumns" [attr.colspan]="columnSpan">{{colFact.name}}</th>
              </tr>

              <tr>
                <th *ngFor="let colPeriod of tablePeriodColumns" >{{colPeriod.name}}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let data of tableData">
                <td>{{data[firstColumnId]}}</td>
                <td *ngFor="let col of tablePeriodColumns" [style.color]="getColor(col, data)">
                  {{utilityService.myFormatNumber(data[col.field],2,2)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngSwitchCase="tableType.Pivot1">
          <div  class="pivot-container">
            <div>
              <div id="table_of_facts_pivot" #pivot1></div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="tableType.Pivot2">
          <div  class="pivot-container">
            <div>
              <div id="table_of_facts_pivot" #pivot2></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || (isFilterDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
