import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';

import { SharedUIModule } from '../shared/ui/shared-ui.module';
import { LayoutComponent } from './layout/layout.component';
import { DashboardEffects } from './shared/state/dashboard.effects';
import { reducer, DASHBOARD_FEATURE_KEY } from './shared/state/dashboard.reducer';
import { DashboardRoutingModule } from './dashboard.routing.module';
import { PropensityModule } from './tabs/propensity/propensity.module';
import { MarketShareModule } from './tabs/market-share/market-share.module';
import { DemographicModule } from './tabs/demographic/demographic.module';
import { RestRequirementModule } from './tabs/rest-requirement/rest-requirement.module';
import { TacticalModule } from './tabs/tactical/tactical.module';
import { ReportsModule } from './tabs/reports/reports.module';
import { BaseComponent } from './base/base.component';
import { BasketModule } from './tabs/basket/basket.module';
import { SettingsModule } from './settings/settings.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    LayoutComponent,
    BaseComponent,
   ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    DashboardRoutingModule,

    SharedUIModule,

    PropensityModule,
    MarketShareModule,
    RestRequirementModule,
    TacticalModule,
    DemographicModule,
    BasketModule,
    ReportsModule,

    SettingsModule,

    TranslateModule,



    StoreModule.forFeature(DASHBOARD_FEATURE_KEY, reducer),
    EffectsModule.forFeature([DashboardEffects])
  ],
})
export class DashboardModule { }
