<div class="container flex justify-center app-login">
  <div class="form-wrapper">


    <form [formGroup]="loginForm" (ngSubmit)="loginForm.valid && submit()">
      <div *ngIf="showLoginError$ | async" class="mt-4 text-red-600 app-login-error">
        Error! Either the email or the password is incorrect.
      </div>
      <h2>NielsenIQ TradePlanner</h2>
      <h3>Sign in to your account</h3>
      <div class="my-3">
        <igx-input-group type="border">
          <input igxInput name="email" type="text" formControlName="email" title="email" />
          <label igxLabel for="email">Email</label>
        </igx-input-group>
      </div>
      <div class="my-3">
        <igx-input-group type="border">
          <input igxInput name="password" type="password" title="password" formControlName="password" />
          <label igxLabel for="password" >Password</label>
        </igx-input-group>
      </div>

      <div class="flex items-center">
        <button
          igxButton="outlined"
          igxRipple
          size="m"
          type="submit"
          [disabled]="(!loginForm.valid)"
          class="authButton"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</div>
