import { ElementRef, EventEmitter, OnInit, Output, Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectItem } from '../../../model/interfaces';
import { TableTypeId } from '../../../model/constants';
import { ConstantService } from '../../../services/constant.service';

@Component({
  selector: 'app-filter-tabs-table-of-facts',
  templateUrl: './filter-tabs-table-of-facts.component.html',
  styleUrls: ['./filter-tabs-table-of-facts.component.scss']
})
export class FilterTabsTableOfFactsComponent implements OnInit {
  public exportItems: any = []
  public tableType = TableTypeId


  // tab account
  @Input() public tabAccountTitle!: string;

  @Input() public filterAccountTitle!: string;
  @Input() public treeAccountIsMulti!: boolean;
  @Input() public filterAccountData!: any[];
  @Input() public selectedAccountNodes!: any[];
  @Input() getSelectedNodes!: ($event: any) => void


  // tab product
  @Input() public tabProductTitle!: string;

  @Input() public filterProductTitle!: string;
  @Input() public treeProductIsMulti!: boolean;
  @Input() public filterProductData!: any[];
  @Input() public selectedProductNodes!: any[];


  // tab period & fact
  @Input() public tabPeriodFactTitle!: string;

  @Input() public filterPeriod1Title!: string;
  @Input() public filterPeriod1Data!: any[];
  @Input() getSelectedPeriods!: ($event: any) => void

  @Input() public treeFactName!: string;
  @Input() public treeFactData!: any[];
  @Input() getSelectedFactNodes!: ($event: any) => void


  // tab settings
  @Input() public tabSelectionTitle!: string;

  @Input() public filterModeTitle!: string; // static filter is a filter with select-items which are static
  @Input() public filterModeData!: any[];
  @Input() public selectedModeItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedFilterModeItem!: ($event: any) => void

  @Input() public selectedTableType: string = TableTypeId.Table;   // default selected type is Table
  @Output() getSelectedTableTypeEvent = new EventEmitter<any>();

  @Input() public filterShopperGroupTitle!: string;
  @Input() public filterShopperGroupData!: any[];
  @Input() public selectedShopperGroupItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedShopperGroupItem!: ($event: any) => void

  @Input() public filterBenchmarkTitle!: string;
  @Input() public filterBenchmarkData!: any[];
  @Input() public selectedBenchmarkItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedBenchmarkItem!: ($event: any) => void

  @Input() public selectMetricsTitle!: string;
  @Input() public selectMetricsData!: any[];
  @Input() public selectedMetricsItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedMetricsItem!: ($event: any) => void

  @Input() public selectChangeTitle!: string;
  @Input() public selectChangeData!: any[];
  @Input() public selectedChangeItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedChangeItem!: ($event: any) => void

  @Output() getSelectedExportEvent = new EventEmitter<any>();

  /** since input variables shared in 2 different pivots, so the output events are not shared */
  public updatePivotButtonDisabled: boolean = false
  @Input() public select_col_items: any[] = [];
  @Input() public select_col1_selected = ""
  @Input() public select_col2_selected = ""
  @Input() public select_col3_selected = ""
  @Input() public select_col4_selected = ""
  @Input() public select_col5_selected = ""
  @Output() getSelectedColumnsPivot1Event = new EventEmitter<any>();
  @Output() getSelectedColumnsPivot2Event = new EventEmitter<any>();


  // others inputs
  @Input() selectedAccountNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes
  @Input() selectedProductNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes


  // maybe used later
  @Input() isDataLoading$!: Observable<boolean>;

  public tableTypeTitle = this.cs.TABLE_TYPE
  public tableTitle = this.cs.TABLE
  public pivot1Title = this.cs.PIVOT1
  public pivot2Title = this.cs.PIVOT2
  public pivotSettingTitle = this.cs.PIVOT_SETTING
  public updatePivotTitle = this.cs.UPDATE_PIVOT
  public exportTitle = this.cs.EXPORT

  constructor(public elem: ElementRef, public cs: ConstantService) {}

  ngOnInit(): void {
    this.updateExportItems()
  }

  private init = true

  private updateExportItems() {
    this.exportItems = []
    this.exportItems.push({Value:"excel", Name:"Excel"})  // if there is grid, then export the excel of the grid data. when there is no grid, just export the data of the chart
  }


  selectRadioTable() {
    // the first call will be done when inializing the page, but this call should not take place. so here is a solution to ignore the first call.
    if(this.init) {
      this.init = false
      return
    }

    this.treeAccountIsMulti = false     // set false as default
    this.treeProductIsMulti = true      // set true as default

    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = TableTypeId.Table

    // console.log("set radio table is called")
    this.getSelectedTableTypeEvent.emit({selectedItem});
  }

  selectRadioPivot1() {
    // the first call will be done when inializing the page, but this call should not take place. so here is a solution to ignore the first call.
    if(this.init) {
      this.init = false
      return
    }

    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = TableTypeId.Pivot1

    this.getSelectedTableTypeEvent.emit({selectedItem});
  }

  selectRadioPivot2() {
    // the first call will be done when inializing the page, but this call should not take place. so here is a solution to ignore the first call.
    if(this.init) {
      this.init = false
      return
    }

    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = TableTypeId.Pivot2

    this.getSelectedTableTypeEvent.emit({selectedItem});
  }

  export(id: string){
    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = id

    this.getSelectedExportEvent.emit({selectedItem});
  }

  checkUpdatePivotButton() {
    let selected_cols = new Set<String>();

    selected_cols.add(this.select_col1_selected).add(this.select_col2_selected).add(this.select_col3_selected).add(this.select_col4_selected).add(this.select_col5_selected)

    if(selected_cols.size === 5) {
      this.updatePivotButtonDisabled = false
    } else {
      this.updatePivotButtonDisabled = true
    }
  }

  updatePivot1() {
    let selectedColumns = this.getSelectedPivotColumns()
    this.getSelectedColumnsPivot1Event.emit({selectedColumns});
  }

  updatePivot2(){
    let selectedColumns = this.getSelectedPivotColumns()
    this.getSelectedColumnsPivot2Event.emit({selectedColumns});
  }

  private getSelectedPivotColumns() {
    return [
      this.select_col1_selected,
      this.select_col2_selected,
      this.select_col3_selected,
      this.select_col4_selected,
      this.select_col5_selected
    ]
  }
}
