import { Injectable } from '@angular/core';
import { PENETRATION_COLOR, PENETRATION_HIDDEN_COLOR } from '../model/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /** Language */
  public LANGUAGES = [
    {"Id": "en", "Name": "English"},
    {"Id": "de", "Name": "Deutsch"},
  ]

  /** colors */
  public DEFAULT_CHART_COLOR_FIRST = "#00A346"
  public DEFAULT_CHART_COLOR_SECOND = "#BDFFBB"
  public CHART_COLORS_WITHOUT_BASIC: string[] = ['#727272','#BFBFBF','#BC8F8F','#4169E1','#00FF00','#2E8B57','#9932CC','#A52A2A','#8FBC8F','#FF00FF','#E9967A','#FFC0CB','#008B8B','#8A2BE2','#FFFAF0','#32CD32','#C71585','#0000FF','#B0C4DE','#8B008B','#6A5ACD','#556B2F','#FFF5EE','#FAEBD7','#FFDAB9','#2F4F4F','#FF1493','#D8BFD8','#B22222','#FFFF00','#D2691E','#F0F8FF','#FFD700','#E6E6FA','#DB7093','#FFEBCD','#B0E0E6','#FFDEAD','#FFF8DC','#A0522D','#0000CD','#9370DB','#F5FFFA','#00CED1','#FFF0F5','#FA8072','#87CEFA','#191970','#DC143C','#008000','#FFA500','#FAFAD2','#FF69B4','#EEE8AA','#6495ED','#1E90FF','#FFFFF0','#FFE4C4','#F8F8FF','#DDA0DD','#FF6347','#006400','#66CDAA','#F0FFF0','#00FA9A','#7CFC00','#F0E68C','#778899','#00FFFF','#4682B4','#7B68EE','#00008B','#008080','#F5DEB3','#8B4513','#800000','#FF8C00','#483D8B','#000080','#8B0000','#FF7F50','#FFB6C1','#AFEEEE','#800080','#FFA07A','#FFFACD','#90EE90','#D2B48C','#FF4500','#F5F5DC','#BA55D3','#40E0D0','#ADD8E6','#B8860B','#F08080','#CD853F','#F0FFFF','#DAA520','#FFEFD5','#5F9EA0','#F4A460','#EE82EE','#FFFAFA','#87CEEB','#FDF5E6','#4B0082','#00FF7F','#E0FFFF','#FF0000','#DEB887','#708090','#FFE4B5','#FFFFE0','#7FFFD4','#3CB371','#FFE4E1','#00FFFF','#808000','#CD5C5C','#98FB98','#20B2AA','#6B8E23','#7FFF00','#9ACD32','#DA70D6','#ADFF2F','#FAF0E6','#00BFFF','#228B22','#BDB76B','#9400D3','#48D1CC']
  public CHART_COLORS: string[] = []

  public BENCHMARK_COLOR = "#e1a734"

  public CALLOUT_TEXT_COLOR = "Black"
  public CALLOUT_BACKGROUND: string = "Transparent"
  public CALLOUT_LEDER_BRUSH: string = "Transparent"

  public LEGEND_TEXT_COLOR = "Black"
  public PENETRATION_COLOR = PENETRATION_COLOR
  public PENETRATION_HIDDEN_COLOR: string = PENETRATION_HIDDEN_COLOR

  public STROKE_COLOR_CATEGORY = "#727272"
  public STROKE_COLOR_VALUE = "#D3D3D3"

  public DEFAULT_MARKER_COLOR = "#BFBFBF"

  public COLOR_RED = "#ff0000"
  public COLOR_GREEN = "#00A346"
  public COLOR_LIGHT_GREEN = "#00F000"
  public COLOR_GREY = "#BFBFBF"
  public COLOR_BLACK = "#000000"
  public COLOR_WHITE = "#ffffff"

  /** PPT */
  public PPT_AUTHOR: string = "NielsenIQ"
  public PPT_COMPANY: string = "NielsenIQ"
  public PPT_FONTCOLOR: string = "0088CC"
  public PPT_FONTSIZE_TITLE: number = 16
  public PPT_FONTSIZE_TEXT: number = 10

  // used in decomposition tree
  public PPT_Config =
  {
    author: 'NielsenIQ',
    company: 'NielsenIQ',
    color: '000000',
    fontSize: 10,
    first_y: 0.1,
    y: 0.1,
    h: 0.1,
    header: {
      fontSize: 12,
      bold: true
    },
    info_header: {
      left_column: {
        x: 0.2
      },
      right_column: {
        x: 1.5
      },
    }
  }


  constructor() { }

  public setBasicColors(firstColor: string, secondColor: string) {
    this.CHART_COLORS = [firstColor, secondColor].concat(this.CHART_COLORS_WITHOUT_BASIC)
  }
}
