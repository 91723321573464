import { ChangeDetectorRef, Component, ViewChild, OnInit, ElementRef, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { IgxBubbleSeriesComponent, IgxDataChartComponent, IgxLegendComponent, IgxNumericXAxisComponent, IgxNumericYAxisComponent, IgxSizeScaleComponent, MarkerType } from 'igniteui-angular-charts';

import pptxgen from 'pptxgenjs';
import domtoimage from 'dom-to-image';
declare var $:any;

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { Columns, DashboardId, FactTypeId, Ids, ModeIds, UserSelectionIds } from '../../../shared/model/constants';
import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-analysis-of-strengths-and-weaknesses',
  templateUrl: './analysis-of-strengths-and-weaknesses.component.html',
  styleUrls: ['./analysis-of-strengths-and-weaknesses.component.scss']
})
export class AnalysisOfStrengthsAndWeaknessesComponent extends BaseComponent implements OnInit {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.PROPENSITY
  dashboardId = DashboardId.analysis_of_strengths_and_weaknesses
  dashboards = this.cs.PROPENSITY_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // If it has 2 period filters
  hasPeriod2Filter = true     // used in base component, have to keep it

  // bubble size filter
  public selectBubbleSizeFactTitle: string = this.cs.BUBBLE_SIZES

  // static filter if existing
  hasFactTypeFilter = true    //static filter, also used in base component, so have to keep it
  public selectFactTypeTitle: string = this.cs.PROPENSITY_LOYALTY
  public selectFactTypeData: any[] = this.cs.SELECT_FACT_TYPE_DATA
  selectedFactTypeItem: SelectItem = {Id: "",  Name: ""}

  getUserDefinedAccountProductColor = true

  // Facts used in dashboard
  axisXFactId: string = 'F11'
  axisYFactId: string = 'F10'
  selectBubbleSizeFactIds: string[] = ["F6", "F7", "F8", "F9"]
  allowedChartFactIds = this.selectBubbleSizeFactIds.concat([this.axisYFactId, this.axisXFactId, this.penetrationFactId])

  /** -------------------- Inputs for this dashboard -------------------- */
  bubbleChartPeriod1Data: any[] = []
  bubbleChartPeriod2Data: any[] = []
  axisXFactName: string = ''
  factIdsUsedInBubbleChart: string[] = [] // the 3 facts, used in bubble chart, namely the xAxis, yAxis and bubble size facts

  @ViewChild('chart')
  public chart!: IgxDataChartComponent;
  @ViewChild('legend')
  public legend!: IgxLegendComponent;
  @ViewChild('xAxis_Per1')
  public xAxis_Per1!: IgxNumericXAxisComponent;
  @ViewChild('yAxis_Per1')
  public yAxis_Per1!: IgxNumericYAxisComponent;

  @ViewChild('xAxis_Per2')
  public xAxis_Per2!: IgxNumericXAxisComponent;
  @ViewChild('yAxis_Per2')
  public yAxis_Per2!: IgxNumericYAxisComponent;

  @ViewChild('valueTooltip')
  public valueTooltip!: any;


  // @Output('ngModelChange') update = new EventEmitter();

  public NoBenchmarkSelectedId: string = this.cs.NO_BENCHMARK_SELECTED.Id
  public maxXAxisValue!: number
  public minXAxisValue!: number
  public maxYAxisValue!: number
  public minYAxisValue!: number

  private el: ElementRef;

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el
  }

  setToDefaultIndividualWhenReadingDim(dim: any[]) {
    this.filterBubbleSizeFactData = this.filterChartFactData.filter(i => this.selectBubbleSizeFactIds.includes(i.Id)).concat(this.cs.NO_BUBBLE_SIZE_FACT)
  }

  setToDefaultIndividual() {
    this.axisXFactName = this.axisXFactId ? this.filterChartFactData.filter(i => i.Id === this.axisXFactId)[0].Name : ''
    this.axisYFactName = this.axisYFactId ? this.filterChartFactData.filter(i => i.Id === this.axisYFactId)[0].Name : ''
    // console.log("this.axisXFactName: ", this.axisXFactName, this.axisY2FactName, this.axisYFactName)
  }



  getSelectedBenchmarkItemIndividual() {
    if(this.hasFactTypeFilter && this.selectedFactTypeItem.Id === FactTypeId.index && this.selectedBenchmarkItem.Id === this.cs.NO_BENCHMARK_SELECTED.Id) {
      this.hasNoBenchmarkSelectedError = true
    } else {
      this.hasNoBenchmarkSelectedError = false
    }
  }

  /** Select Bubble size fact */
  public getSelectedBubbleSizeFact = (event: any) => {
    this.selectedBubbleSizeFactItem = event.selectedItem
    // console.log("selectedBubbleSizeFactItem: ", this.selectedBubbleSizeFactItem)
    this.factIdsUsedInBubbleChart = this.selectedBubbleSizeFactItem.Id !== this.cs.NO_BUBBLE_SIZE_FACT.Id ? [this.axisXFactId, this.axisYFactId, this.selectedBubbleSizeFactItem.Id, this.penetrationFactId] : [this.axisXFactId, this.axisYFactId, this.penetrationFactId]
    this.feedChartWithData()
  }

  /** Select Fact Type (Fact as % or as index) | get selected item */
  public getSelectedFactType = (event: any) => {
    this.selectedFactTypeItem = event.selectedItem

    if(this.hasFactTypeFilter && this.selectedFactTypeItem.Id === FactTypeId.index && this.selectedBenchmarkItem.Id === this.cs.NO_BENCHMARK_SELECTED.Id) {
      this.hasNoBenchmarkSelectedError = true
    } else {
      this.hasNoBenchmarkSelectedError = false
      this.feedChartWithData()
    }

    // console.log("analysis-of-strengths-and-weaknesses | this.selectedFactTypeItem.Id: ", this.selectedFactTypeItem.Id)
    // console.log("analysis-of-strengths-and-weaknesses | hasNoBenchmarkSelectedError: ", this.hasNoBenchmarkSelectedError)
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.factIdsUsedInBubbleChart, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log("analysis-of-strengths-and-weaknesses | data: ", data)

          this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:false, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
          // console.log("analysis-of-strengths-and-weaknesses | preprocessed data: ", data)

          // add bubbleSizeRadius in data
          let bubbleSizeFactData: any[] = data.filter(i => i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id)
          let bubbleSizeFactValues: number[] = bubbleSizeFactData.map(i => i[Columns.Value])
          let maxBubbleSizeFactValue = bubbleSizeFactValues.reduce((a, b) => Math.max(a, b), -Infinity);
          // console.log("bubbleSizeFactData: ", bubbleSizeFactData, bubbleSizeFactValues, maxBubbleSizeFactValue)

          let bubbleSizeFactDataWithRadius: any[] = []
          for (let d of bubbleSizeFactData) {
            if(d[Columns.Fact] === this.selectedBubbleSizeFactItem.Id) {
              let dWithRadius: any = {...d}
              dWithRadius[Ids.Radius] = d[Columns.Value] / maxBubbleSizeFactValue * 100
              bubbleSizeFactDataWithRadius.push(dWithRadius)
            } else {
              bubbleSizeFactDataWithRadius.push(d)
            }
          }
          // console.log("bubbleSizeFactDataWithRadius: ", bubbleSizeFactDataWithRadius)

          this.feedBenchmarkWithData(data)

          this.bubbleChartPeriod1Data = this.chartService.covertToChartData2(data.filter(i => i[Columns.Period] === this.selectedPeriod1Item.Id), this.fieldsWithoutFactAndValue)
          // console.log("analysis-of-strengths-and-weaknesses | this.bubbleChartPeriod1Data converted: ", this.bubbleChartPeriod1Data)
          for(let d1 of this.bubbleChartPeriod1Data) {
            if(bubbleSizeFactDataWithRadius.filter(i => i[Columns.Period] === d1[Columns.Period] && i[Columns.Account] === d1[Columns.Account] && i[Columns.Product] === d1[Columns.Product] && i[Columns.ShopperGroup] === d1[Columns.ShopperGroup]).length > 0) {
              d1[Ids.Radius] = bubbleSizeFactDataWithRadius.filter(i => i[Columns.Period] === d1[Columns.Period] && i[Columns.Account] === d1[Columns.Account] && i[Columns.Product] === d1[Columns.Product] && i[Columns.ShopperGroup] === d1[Columns.ShopperGroup])[0][Ids.Radius]
            } else {
              d1[Ids.Radius] = 50
            }
          }
          this.bubbleChartPeriod1Data = this.utilityService.replaceIdWithName3(this.bubbleChartPeriod1Data, Columns.Account, Columns.Product, Columns.Period, this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.factIdsUsedInBubbleChart, this.filterChartFactData, [], true, true)
          // console.log("analysis-of-strengths-and-weaknesses | this.bubbleChartPeriod1Data with names: ", this.bubbleChartPeriod1Data)

          this.bubbleChartPeriod2Data = this.chartService.covertToChartData2(data.filter(i => i[Columns.Period] === this.selectedPeriod2Item.Id), this.fieldsWithoutFactAndValue)
          for(let d2 of this.bubbleChartPeriod2Data) {
            if(bubbleSizeFactDataWithRadius.filter(i => i[Columns.Period] === d2[Columns.Period] && i[Columns.Account] === d2[Columns.Account] && i[Columns.Product] === d2[Columns.Product] && i[Columns.ShopperGroup] === d2[Columns.ShopperGroup]).length > 0) {
              d2[Ids.Radius]= bubbleSizeFactDataWithRadius.filter(i => i[Columns.Period] === d2[Columns.Period] && i[Columns.Account] === d2[Columns.Account] && i[Columns.Product] === d2[Columns.Product] && i[Columns.ShopperGroup] === d2[Columns.ShopperGroup])[0][Ids.Radius]
            } else {
              d2[Ids.Radius]= 50
            }
          }
          this.bubbleChartPeriod2Data = this.utilityService.replaceIdWithName3(this.bubbleChartPeriod2Data, Columns.Account, Columns.Product, Columns.Period, this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.factIdsUsedInBubbleChart, this.filterChartFactData, [], true, true)
          // console.log("analysis-of-strengths-and-weaknesses | this.bubbleChartPeriod2Data with names: ", this.bubbleChartPeriod2Data)

          this.setAxisMaxMinToDefault() // set the max/min values to default, so that it can be recaculated in the function "addLabelAndCalculateIndexInBubbleChartPeriodData"

          this.bubbleChartPeriod1Data = this.addLabelAndCalculateIndexInBubbleChartPeriodData(this.bubbleChartPeriod1Data, 0)
          this.bubbleChartPeriod2Data = this.addLabelAndCalculateIndexInBubbleChartPeriodData(this.bubbleChartPeriod2Data, 1)

          // then change the benchmark xAxis yAxis Value to 100 for both period 1 and period 2, so that the benchmark lines will be shown correct.
          if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id && this.selectedFactTypeItem.Id === FactTypeId.index) {
            this.selectedBenchmarkItem.XAxisValues = [100, 100]
            this.selectedBenchmarkItem.YAxisValues = [100, 100]
          }

          this.maxXAxisValue = Math.max(this.maxXAxisValue + 20, this.maxXAxisValue * 1.1)
          this.maxYAxisValue = Math.max(this.maxYAxisValue + 20, this.maxYAxisValue * 1.1)
          this.minXAxisValue = Math.min(this.minXAxisValue - 20, this.minXAxisValue * 0.9)
          this.minYAxisValue = Math.min(this.minYAxisValue - 20, this.minYAxisValue * 0.9)

          // console.log("analysis-of-strengths-and-weaknesses | this.bubbleChartPeriod1Data with labels: ", this.bubbleChartPeriod1Data)
          // console.log("analysis-of-strengths-and-weaknesses | this.bubbleChartPeriod2Data with labels: ", this.bubbleChartPeriod2Data)
          // console.log("analysis-of-strengths-and-weaknesses | min, max ", this.maxXAxisValue, this.maxYAxisValue, this.minXAxisValue, this.minYAxisValue)

          this.createChart()
        }
      )
    })
  }

  feedBenchmarkWithData(data: any[]): void {
    if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeAccountIsMulti) {
        this.selectedBenchmarkItem.Values = []
        this.selectedBenchmarkItem.Values.push(data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod1Item.Id)[0]
                                                ? data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod1Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.Values[0]) // have to check if the item with the fact exists
        this.selectedBenchmarkItem.Values.push(data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod2Item.Id)[0]
                                                ? data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod2Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.Values[0]) // add the value of both period1 and period2

        this.selectedBenchmarkItem.XAxisValues = []
        this.selectedBenchmarkItem.XAxisValues.push(data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0]
                                                ? data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.XAxisValues[0])
        this.selectedBenchmarkItem.XAxisValues.push(data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0]
                                                ? data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.XAxisValues[0])

        this.selectedBenchmarkItem.YAxisValues = []
        this.selectedBenchmarkItem.YAxisValues.push(data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0]
                                                ? data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.YAxisValues[0])
        this.selectedBenchmarkItem.YAxisValues.push(data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0]
                                                ? data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.YAxisValues[0])

      } else {
        this.selectedBenchmarkItem.Values = []
        this.selectedBenchmarkItem.Values.push(data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod1Item.Id)[0]
                                                ? data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod1Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.Values[0]) // have to check if the item with the fact exists
        this.selectedBenchmarkItem.Values.push(data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod2Item.Id)[0]
                                                ? data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedBubbleSizeFactItem.Id && i[Columns.Period] === this.selectedPeriod2Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.Values[0]) // add the value of both period1 and period2

        this.selectedBenchmarkItem.XAxisValues = []
        this.selectedBenchmarkItem.XAxisValues.push(data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0]
                                                ? data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.XAxisValues[0])
        this.selectedBenchmarkItem.XAxisValues.push(data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0]
                                                ? data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisXFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.XAxisValues[0])

        this.selectedBenchmarkItem.YAxisValues = []
        this.selectedBenchmarkItem.YAxisValues.push(data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0]
                                                ? data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod1Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.YAxisValues[0])
        this.selectedBenchmarkItem.YAxisValues.push(data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0]
                                                ? data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.axisYFactId && i[Columns.Period] === this.selectedPeriod2Item.Id)[0].Value
                                                : this.cs.NO_BENCHMARK_SELECTED.YAxisValues[0])
      }

      // console.log("analysis-of-strengths-and-weaknesses | component selectedBenchmarkItem: ", this.selectedBenchmarkItem, this.selectedBenchmarkItem.YAxisValues[0], this.selectedBenchmarkItem.XAxisValues[0])
    }
  }

  private addLabelAndCalculateIndexInBubbleChartPeriodData(data: any[], id: number) {
    for(let i of data) {
      if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id && this.selectedFactTypeItem.Id === FactTypeId.index) {  // if fact type as index, then calculate the index
        i[this.axisXFactName] = i[this.axisXFactName] / this.selectedBenchmarkItem.XAxisValues[id] * 100
        i[this.axisYFactName] = i[this.axisYFactName] / this.selectedBenchmarkItem.YAxisValues[id] * 100
      }

      if (this.selectedBubbleSizeFactItem.Id !== this.cs.NO_BUBBLE_SIZE_FACT.Id) { // only if the benchmark is selected, the label will include the bubble size value
        // console.log("bubble size has fact", this.selectedBubbleSizeFactItem.Name, i[this.selectedBubbleSizeFactItem.Name])
        i[Ids.Label] = i[this.selectedBubbleSizeFactItem.Name] ? i[this.modeCategory] + ": (" + this.utilityService.myFormatNumber(i[this.axisXFactName], 1) + " , " + this.utilityService.myFormatNumber(i[this.axisYFactName], 1) + ") " + this.utilityService.myFormatNumber(i[this.selectedBubbleSizeFactItem.Name], 1) : i[this.modeCategory] + ": (" + this.utilityService.myFormatNumber(i[this.axisXFactName], 1) + " , " + this.utilityService.myFormatNumber(i[this.axisYFactName], 1) + ")"   // only if there is data. Without this check, it could happen that the Label is ' , undefined'
      } else {  //
        // console.log("bubble size has NOT fact")
        i[Ids.Label] = i[this.selectedBubbleSizeFactItem.Name] ? i[this.modeCategory] + ": (" + this.utilityService.myFormatNumber(i[this.axisXFactName], 1) + " , " + this.utilityService.myFormatNumber(i[this.axisYFactName], 1) + ") " + this.utilityService.myFormatNumber(i[this.selectedBubbleSizeFactItem.Name], 1) : i[this.modeCategory] + ": (" + this.utilityService.myFormatNumber(i[this.axisXFactName], 1) + " , " + this.utilityService.myFormatNumber(i[this.axisYFactName], 1) + ")"   // only if there is data. Without this check, it could happen that the Label is ' , undefined'
      }

      i[Ids.Category] = i[this.modeCategory]

      i[Ids.Penetration_SingleSeries] = this.utilityService.havePenetrationWarning(i[this.penetrationFactId])
      // console.log("ip: ", i[Ids.Penetration_SingleSeries], i)
      if(!this.hasPenetrationWarning && i[Ids.Penetration_SingleSeries]) {
        this.hasPenetrationWarning = true
      }

      // calculate the min, max value for axis, so that the chart can be displayed in a better way
      if(i[this.axisXFactName] > this.maxXAxisValue) {
        this.maxXAxisValue = i[this.axisXFactName]
      }
      if(i[this.axisXFactName] < this.minXAxisValue) {
        this.minXAxisValue = i[this.axisXFactName]
      }

      if(i[this.axisYFactName] > this.maxYAxisValue) {
        this.maxYAxisValue = i[this.axisYFactName]
      }
      if(i[this.axisYFactName] < this.minYAxisValue) {
        this.minYAxisValue = i[this.axisYFactName]
      }
    }
    return data
  }

  public createChart() {
    this.chart.series.clear();

    let categories: String[] = []
    let colors: any = {}
    let categoryField: string = ""
    if(this.treeAccountIsMulti) {
      categories = this.selectedAccountNodes.map(i=>i.Name)
      categoryField = Columns.Account + "_Id"
      colors = this.accountColorMapping
    } else {
      categories = this.selectedProductNodes.map(i=>i.Name)
      categoryField = Columns.Product + "_Id"
      colors = this.productColorMapping
    }

    // console.log("createchart | categories: ", categoryField, categories, colors)

    for (let i =0; i< categories.length; i++) {
      let tdata1 = this.bubbleChartPeriod1Data.filter(d=>d[Ids.Category] === categories[i])
      let tdata2 = this.bubbleChartPeriod2Data.filter(d=>d[Ids.Category] === categories[i])
      // console.log("tdata1: ", tdata1)
      // console.log("tdata2: ", tdata2)

      if(tdata1.length > 0) {
        let tdata1Radius = tdata1[0][Ids.Radius]
        let tdata1Color = colors[tdata1[0][categoryField]]
        // console.log("tdata1Color: ", tdata1Color)
        this.chart.series.add(
          this.createBubbleSeries(
            this.xAxis_Per1,
            this.yAxis_Per1,
            tdata1,
            tdata1Color,
            0.8,
            0,
            true,
            tdata1Radius
          )
        );
      }

      if(tdata2.length > 0) {
        let tdata2Radius = tdata2[0][Ids.Radius]
        let tdata2Color = colors[tdata2[0][categoryField]]
        // console.log("tdata2Color: ", tdata2Color)
        this.chart.series.add(
          this.createBubbleSeries(
            this.xAxis_Per2,
            this.yAxis_Per2,
            tdata2,
            tdata2Color,
            0.2,
            1,
            false,
            tdata2Radius
          )
        );
      }
    }

    // console.log("number of series: ", this.chart.series.count)
  }

  public createBubbleSeries(xAxis: IgxNumericXAxisComponent, yAxis: IgxNumericYAxisComponent, DataObj: any, MarkerColor: string, markerFillOpacity: number, legendItemVisibility: number, isUsableInLegend: boolean, radius: number) {
    var series = new IgxBubbleSeriesComponent();

    series.xAxis = xAxis;
    series.yAxis = yAxis;
    series.dataSource = DataObj;
    series.xMemberPath = this.axisXFactName;
    series.yMemberPath = this.axisYFactName;
    series.radiusMemberPath = this.selectedBubbleSizeFactItem.Id;

    series.name = DataObj[0].Category;
    series.title = DataObj[0].Category;
    series.labelMemberPath = DataObj[0].Label;

    series.showDefaultTooltip = false
    series.tooltipTemplate = this.valueTooltip

    series.markerBrush = MarkerColor
    if(this.hidePenetrationWarning) {
      series.markerOutline = MarkerColor
    } else {
      series.markerOutline = DataObj[0][Ids.Penetration_SingleSeries] ? "#FF6E08" : MarkerColor
    }

    series.markerFillOpacity = markerFillOpacity;
    series.legendItemVisibility = legendItemVisibility;

    series.thickness = 2;
    series.markerType = MarkerType.Circle;

    const sizeScale = new IgxSizeScaleComponent();
    sizeScale.minimumValue = radius;
    sizeScale.maximumValue = radius;
    series.radiusScale = sizeScale;
    // series.radiusScaleUseGlobalValues = true   //it seems to be the solution, but it doesn't work

    // console.log("series: ", series)
    return series;
  }

  private setAxisMaxMinToDefault() {
    this.maxXAxisValue = -200000  //just make sure, the smallest max value is bigger than this one
    this.minXAxisValue = 200000   //just make sure, the maximum min value is samller than this one
    this.maxYAxisValue = -200000
    this.minYAxisValue = 200000
  }

  handlePenetrationWarning() {
    this.feedChartWithData()
  }

  chartMasterNextPage = (event:any) => {
    let pptMaster: string = this.userDefinedPptMaster
    let slide: any
    if(pptMaster) {
      slide = this.chartMasterPPT.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = this.chartMasterPPT.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#bubblechart"

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
      // console.log("dataUrl: ", dataUrl)
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      // console.log("image: ", image)
      slide.addImage({
        data: image,
        x: 1.4,
        y: 2.4,
        w: 9.5,
        h: 5
      })

      this.chartMasterService.next()
    })
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(', ')
    pptHeaderTemplate[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name
    pptHeaderTemplate[this.cs.BUBBLE_SIZES] = this.selectedBubbleSizeFactItem.Name
    pptHeaderTemplate[this.cs.FACT] = this.selectedFactTypeItem.Name
    pptHeaderTemplate[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Name

    return pptHeaderTemplate
  }

  handleExportPPT(singleTreeName: string): void {
    if (!this.el ||
      !this.el.nativeElement ||
      !this.el.nativeElement.children){
          console.log('cant build without element');
        return;
    }

    let pptMaster: string = this.userDefinedPptMaster

    var pptx = new pptxgen();
    this.exportService.createPPTBasics(pptx)

    // create the ppt master
    this.exportService.createPPTMaster(pptx, pptMaster)
    let slide: any
    if(pptMaster) {
      slide = pptx.addSlide({ masterName: "MASTER_SLIDE" })
    } else {
      slide = pptx.addSlide()
    }

    var image: any;
    var fileName = this.dashboardTitle
    var div_id = "#bubblechart"

    let header = this.getPPTHeaderTemplate()
    this.exportService.add_text_in_slide(slide, fileName, header)

    domtoimage.toPng($(div_id)[0])
    .then(function (dataUrl: any) {
      var img = new Image();
      img.src = dataUrl;
      image = dataUrl;
      // console.log("dataUrl: ", dataUrl)
    })
    .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
    })
    .finally(() => {
      // console.log("image: ", image)
      slide.addImage({
      data: image,
      x: 1.4,
      y: 2.4,
      w: 9.5,
      h: 5
      });
      pptx.writeFile({fileName: fileName})
        .then(fileName => {
          console.log(`Created Single PPT file: ${fileName}`);
          this.isCreatingExport = false
      });
    })
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // has no export multi ppt
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNTS] =  this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS]= this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    header[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    if(this.treeAccountIsMulti) {
      columnValueMap[this.cs.ACCOUNT] = Columns.Account
    } else {
      columnValueMap[this.cs.PRODUCT] = Columns.Product
    }
    columnValueMap[this.cs.PERIOD] = Columns.Period
    if (this.selectedFactTypeItem.Id === FactTypeId.index) {
      columnValueMap[this.axisXFactName + " (index)"] = this.axisXFactName
      columnValueMap[this.axisYFactName + " (index)"] = this.axisYFactName
    } else {
      columnValueMap[this.axisXFactName + " (%)"] = this.axisXFactName
      columnValueMap[this.axisYFactName + " (%)"] = this.axisYFactName
    }
    columnValueMap[this.selectedBubbleSizeFactItem.Name] = this.selectedBubbleSizeFactItem.Name

    let excelData: any[] = this.bubbleChartPeriod1Data.concat(this.bubbleChartPeriod2Data)

    // console.log("export Excel: ", excelData, columnValueMap)
    this.exportService.exportExcel("Analysis_Strength_Weaknesses", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, excelData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  public onPanUpClick() {
    this.chart.actualWindowPositionVertical -= 0.05;
  }

  public onPanDownClick() {
    this.chart.actualWindowPositionVertical += 0.05;
  }

  public onPanRightClick() {
    this.chart.actualWindowPositionHorizontal += 0.05;
  }

  public onPanLeftClick() {
    this.chart.actualWindowPositionHorizontal -= 0.05;
  }

  public ZoomInClick() {
    this.chart.actualWindowScaleHorizontal -= 0.05;
    this.chart.actualWindowScaleVertical -= 0.05;
  }

  public ZoomOutClick() {
    this.chart.actualWindowScaleHorizontal += 0.05;
    this.chart.actualWindowScaleVertical += 0.05;
  }

  public resetZoom() {
    this.chart.resetZoom();
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.BubbleSize] = this.selectedBubbleSizeFactItem
    selection[UserSelectionIds.Mode] = this.selectedModeItem
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem

    // do not save the Benchmark and FactType, because it could be unable to show the chart when the Benchmark is selected and the FactType is percent
    // selection[UserSelectionIds.Benchmark] = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [-22]} // do not save the values
    // selection[UserSelectionIds.FactType] = this.selectedFactTypeItem

    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
      return
    }

    // Switch (Mode)
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.treeProductIsMulti = true
        this.callBackendToGetProductAndAllAccounts = false

        this.treeAccountIsMulti = false
        this.callBackendToGetAccountAndAllProducts = true
        break

      case ModeIds.OneProductMultiAccounts:
        this.treeProductIsMulti = false
        this.callBackendToGetProductAndAllAccounts = true

        this.treeAccountIsMulti = true
        this.callBackendToGetAccountAndAllProducts = false
        break
    }
    // console.log("this.selectedModeItem: ", this.selectedModeItem)

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))]
    this.getAccountColorMapping()

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))]
    this.getProductColorMapping()

    // Period1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Period2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)
    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]

    // Bubble size
    this.selectedBubbleSizeFactItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.BubbleSize, {
      Id: this.filterBubbleSizeFactData.length === 0 ? '' : this.filterBubbleSizeFactData[0].Id,
      Name: this.filterBubbleSizeFactData.length === 0 ? '' : this.filterBubbleSizeFactData[0].Name
    })
    // have to update it according to the User Language
    this.selectedBubbleSizeFactItem = {...this.selectedBubbleSizeFactItem, Name: this.filterBubbleSizeFactData.filter(i=>i.Id === this.selectedBubbleSizeFactItem.Id)[0].Name}
    // console.log("this.selectedBubbleSizeFactItem: ", this.selectedBubbleSizeFactItem)
    this.factIdsUsedInBubbleChart = this.selectedBubbleSizeFactItem.Id !== this.cs.NO_BUBBLE_SIZE_FACT.Id ? [this.axisXFactId, this.axisYFactId, this.selectedBubbleSizeFactItem.Id, this.penetrationFactId] : [this.axisXFactId, this.axisYFactId, this.penetrationFactId]


    // Benchmark     // do not load Benchmark, just hard code it. otherwise, it could not load the chart.
    this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
    // console.log("this.selectedBenchmarkItem: ", this.selectedBenchmarkItem)

    // do not load Fact Type, just hard code it. otherwise, it could not load the chart.
    // Fact Type
    this.selectedFactTypeItem = {
      Id: this.selectFactTypeData[0].Id,
      Name: this.selectFactTypeData[0].Name
    }

    // ShopperGroup
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // chart setting (has to be before individualLogicForPeriod2)
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)
  }
}
