<section class="container_content">
  <div class="setting-content">
      <igx-tabs tabAlignment="justify">
        <igx-tab-item>
            <igx-tab-header>
                <span igxTabHeaderLabel>{{headerBasic}}</span>
            </igx-tab-header>
            <igx-tab-content>
              <div class="setting-tab-content">
              <div class="flex flex-row">
                <div class="basis-1/4">
                  <div>{{firstColorTitle}}</div>
                  <input
                          class="ColorPickerInput"
                          title="first color"
                          [style.background]="chartColorFirst"
                          [(colorPicker)]="chartColorFirst"
                          [cpCmykEnabled]="false"
                          (colorPickerClose)="getChartColorFirst('colorPickerClose', $event)"
                          />
                </div>
                <div class="basis-1/4">
                  <div>{{secondColorTitle}}</div>
                  <input
                        class="ColorPickerInput"
                        title="second color"
                        [style.background]="chartColorSecond"
                        [(colorPicker)]="chartColorSecond"
                        [cpCmykEnabled]="false"
                        (colorPickerClose)="getChartColorSecond('colorPickerClose', $event)"
                  />
                </div>
                <div class="basis-1/4">
                  <div>&nbsp;</div>
                  <button igxButton="raised" class="button-green" [disabled]="latestChartColorFirst === chartColorFirst && latestChartColorSecond === chartColorSecond" (click)="SaveBasicColor()">{{saveTitle}}</button>
                </div>
              </div>
              </div>
            </igx-tab-content>
        </igx-tab-item>

        <igx-tab-item>
            <igx-tab-header>
                <span igxTabHeaderLabel>{{headerAccounts}}</span>
            </igx-tab-header>
            <igx-tab-content>
              <igx-tree-grid #treeGrid [data]="AccountsTree" childDataKey="Children" [autoGenerate]="false" [width]="'750px'">
                <igx-column field="Name" dataType="string" [width]="'500px'"></igx-column>
                <igx-column field="Color" dataType="string" [editable]="true" [width]="'150px'"></igx-column>
              </igx-tree-grid>

              <button igxButton="raised" class="button-green" [disabled]="json.stringify(AccountsTree) === latestAccountsTreeString" (click)="saveAccountsColor()">{{saveTitle}}</button>
            </igx-tab-content>
        </igx-tab-item>

        <igx-tab-item>
            <igx-tab-header>
                <span igxTabHeaderLabel>{{headerProducts}}</span>
            </igx-tab-header>
            <igx-tab-content>
              <div class="setting-tab-content">
                <igx-tree-grid #treeGrid [data]="ProductsTree" childDataKey="Children" [autoGenerate]="false" [width]="'750px'">
                  <igx-column field="Name" dataType="string" [width]="'500px'"></igx-column>
                  <igx-column field="Color" dataType="string" [editable]="true" [width]="'150px'"></igx-column>
                </igx-tree-grid>

                <button igxButton="raised" class="button-green" [disabled]="json.stringify(ProductsTree) === latestProductsTreeString" (click)="saveProductsColor()">{{saveTitle}}</button>
              </div>

            </igx-tab-content>
        </igx-tab-item>
      </igx-tabs>
  </div>

  <igx-toast #toast_color></igx-toast>
  <div class="loading" *ngIf="basicColorIsLoading === true || (isBasicColorSaving$ | async) === true || (isDimLoading$ | async) === true || (isAccountProductColorSaving$ | async) === true"></div>
</section>
