import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import 'pivottable/dist/pivot.min.js';
declare var jQuery:any;
declare var $:any;

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { fromAuth } from 'src/app/auth/state/auth.selectors';
import { Columns, TableTypeId, CallAPIModes, UserSelectionIds, ModeIds, Ids, DashboardId } from '../../../shared/model/constants';
import { SelectedTreeNode, SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { PENETRATION_COLOR, PENETRATION_HIDDEN_COLOR, PIVOT_AGGREGATOR_NAME } from 'src/app/dashboard/shared/model/config';
import { AuthService } from 'src/app/auth/services/auth.service';
//import { ConsoleReporter } from 'jasmine';

// <button (click)="GetSelection()">Get Selection</button> 
// <button (click)="LoadSelection()">Load Selection</button> 


// <igx-select [(ngModel)]="Selections_Items">
// <label igxLabel>Simple Select</label>
// <igx-select-item *ngFor="let item of Selections_Items" [value]="item">
    //{{item}}
//</igx-select-item>
//</igx-select>


@UntilDestroy()
@Component({
  selector: 'app-table-of-facts',
  templateUrl: './table-of-facts.component.html',
  styleUrls: ['./table-of-facts.component.scss']
})
export class TableOfFactsComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data"    // default is the NTP_Data, because default it Table. For the pivot 1 and pivot 2, we need NTP_Data_Normalized
  callAPIMode = CallAPIModes.GetAPIWithTotal    // default is the get api with total, because default is Table. For the pivot 1 and pivot 2, we need the FilterAPI and call a different api

  // Dashboard Title
  dashboardCategoryTitle = this.cs.REPORTS
  dashboardId = DashboardId.table_of_facts
  dashboards = this.cs.REPORTS_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name


  public isPowerpointMasterLoaded$     = this.store.select(fromAuth.isPowerpointMasterLoaded)

  // call backend
  getHHDataFromBackend = true

  // Facts used in dashboard
  factsBasic = ["F11", "F10", "F6", "F7", "F8", "F9", "F3", "F1", "F2", "F16", "F5", "F13", "F12"]
  factsBasicCalculated = ["c_ShopperConversion", "c_NumberOfLostHouseholds", "c_NumberOfAccountClients", "c_NumberOfAccountBuyers", "c_PenetrationOfShop", "c_Occasions000"]

  factsBuyer = ["F15"]
  factsBuyerCalculated = ["c_PenetrationInTotalMarket", "c_BuyerCoverageIndex", "c_ShopperConversionIndex", "c_ComparativeCoverageOfAccountBuyers", "c_ComparativeCoverageInTotalMarket", "c_ComparativeCoverageIndex"]

  factsNumberOfBuyers = []
  factsNumberOfBuyersCalculated = ["c_NumberOfBuyersInTotalMarket", "c_NumberOfBuyerOfShop"]    //c_NumberOfAccountClients, c_NumberOfAccountBuyers, c_NumberOfLostHouseholds already exist

  factsNumberOfBuyersM = []
  factsNumberOfBuyersMCalculated = ["c_NumberOfBuyersInTotalMarketM", "c_NumberOfBuyerOfShopM", "c_NumberOfAccountClientsM", "c_NumberOfAccountBuyersM", "c_NumberOfLostHouseholdsM"]

  factsExpenditures = ["F14"]
  factsExpendituresCalculated = ["c_TotalExpenditures000", "c_ExpendituresInTotalMarket000", "c_ExpendituresOfAccountClients000", "c_ExpendituresOfAccountBuyers000", "c_LostExpenditures000", "c_ExpendituresOfAccountBuyersInAllShops000", "c_ExpendituresPerAccountBuyersInAllShops", "c_ExpenditurePerAccountClientsInAllShops", "c_ExpendituresPerBuyerInTotalMarket"]

  factsExpendituresM = []
  factsExpendituresMCalculated = ["c_TotalExpendituresM", "c_ExpendituresInTotalMarketM", "c_ExpendituresOfAccountClientsM", "c_ExpendituresOfAccountBuyersM", "c_LostExpendituresM", "c_ExpendituresOfAccountBuyersInAllShopsM"]

  factsShopping = []
  factsShoppingCalculated = []    //c_Occasions000 already exists

  factsPropensity = []
  factsPropensityCalculated = ["c_UnitsMarketShareInPercent", "c_Intensness", "c_LoyaltyIndex", "c_PropensityIndex", "c_ShareOnTotalOccasions"]

  factsGap = []
  factsGapCalculated = ["c_LoyaltyGapInPercent", "c_LoyaltyGapIn000", "c_MarketShareGapInPercent", "c_MarketShareGapIn000"]

  factsPromotion = ["TF7", "TF8", "TF4", "TF9", "TF5", "TF6", "TF13", "TF10", "TF12", "TF3"]
  factsPromotionCalculated = ["c_PromotionPenetrationInTotalMarket", "c_PercentPromotionBuyersInTotalMarket", "c_PromotionExpendituresInTotalMarket000",
  "c_PercentPromotionExpendituresInTotalMarket", "c_PromotionExpendituresInTotalMarketM", "c_PromotionExpendituresOfAccountBuyersM", "c_BasisExpendituresM",
  "c_NumberOfAccountBuyersPromotion", "c_NumberOfAccountBuyersNonPromotion", "c_NumberOfAccountBuyersPromotionOnly", "c_NumberOfAccountBuyersNonPromotionOnly", "c_NumberOfAccountBuyerPromotionAndNonPromotion", "c_PercentPromotionAccountBuyers",
  "c_PercentNonPromotionAccountBuyers", "c_PercentExclusivelyPromotionAccountBuyers", "c_PercentExclusivelyNonPromotionAccountBuyers", "c_PercentPromotionAndNonPromotionAccountBuyers"
  ]

  factsAssortment = ["TF14"]
  factsAssortmentCalculated = []

  factsBasket = ["F19", "F17", "F18", "F20"]
  factsBasketCalculated = []

  factsDecompostion = ["F22", "F26"]
  factsDecompostionCalculated = ["c_UnitsM", "c_AveragePricePerUnit", "c_UnitsPerAccountBuyer", "c_UnitsPerOccasion"]

  allowedGridFactIds = [...new Set(
                          this.factsBasic.concat(this.factsBuyer).concat(this.factsNumberOfBuyers).concat(this.factsNumberOfBuyersM).concat(this.factsExpenditures)
                          .concat(this.factsExpendituresM).concat(this.factsShopping).concat(this.factsGap).concat(this.factsPromotion).concat(this.factsAssortment)
                          .concat(this.factsBasket).concat(this.factsDecompostion).concat([this.penetrationFactId])
                       )]

  /** -------------------- Inputs for this dashboard -------------------- */

  public Selections_Items: string[] = ['Rewe', 'Edeka'];


  public current_database_id: number = 0

  // Table
  public tableData: any[] = []
  public tableFactColumns: any[] = []
  public tablePeriodColumns: any[] = []
  public columnSpan: number = 0
  tableOtherColumns: any[] = []
  public firstColumnName: string = ''   // either Product or Account
  public firstColumnId: string = ''     // either ProdNum or BGNum

  //Metrics
  public selectMetricsTitle: string = this.cs.METRICS
  public selectMetricsData: any[] = this.cs.SELECT_METRICS_DATA
  public selectedMetricsItem: SelectItem =  this.cs.SELECT_METRICS_DATA[0]

  /** Select Metrics */
  public getSelectedMetricsItem = (event: any) => {
    this.selectedMetricsItem = event.selectedItem
    // console.log("this.selectedMetricsItem: ", this.selectedMetricsItem)

    this.updateTableColumns()
    this.feedGridWithData()
  }

  //Change
  public selectChangeTitle: string = this.cs.CHANGE
  public selectChangeData: any[] = this.cs.SELECT_CHANGE_DATA
  public selectedChangeItem: SelectItem =  this.cs.SELECT_CHANGE_DATA[0]

  /** Select Change */
  public getSelectedChangeItem = (event: any) => {
    this.selectedChangeItem = event.selectedItem
    // console.log("this.selectedMetricsItem: ", this.selectedMetricsItem)

    this.updateTableColumns()
    this.feedGridWithData()
  }

  // Pivot 1
  private el: ElementRef;
  public pivotData: any[] = []
  public select_col_items: any[] = [
    {Id:"BGNum", Name: this.cs.ACCOUNT},
    {Id:"ProdNum", Name: this.cs.PRODUCT},
    {Id:"PerNum", Name: this.cs.PERIOD},
    {Id:"Fact", Name: this.cs.FACT},
    {Id:"MBDNum", Name: this.cs.SHOPPER_GROUP},
  ];
  public select_col1_selected = "BGNum"
  public select_col2_selected = "ProdNum"
  public select_col3_selected = "MBDNum"
  public select_col4_selected = "PerNum"
  public select_col5_selected = "Fact"

  // Pivot 2

  /** Filter Table Chart Type | Table, Pivot1, Pivot2*/
  public tableType = TableTypeId
  public selectedTableChartTpye: string = TableTypeId.Table
  public getSelectedTableChartType = (event:any) => {
    // console.log(event)
    // console.log(event.selectedItem.Id)

    switch (event.selectedItem.Id) {
      case TableTypeId.Table:
        this.selectedTableChartTpye = TableTypeId.Table
        this.sourceTable = "NTP_Data"
        this.callAPIMode = CallAPIModes.GetAPIWithTotal

        this.treeAccountIsMulti = false     // not relevant for the UI, but relevant for the logic in base component
        this.callBackendToGetAccountAndAllProducts = true // get account from the store

        this.treeProductIsMulti = true      // not relevant for the UI, but relevant for the logic in base component
        this.callBackendToGetProductAndAllAccounts = false  // still get all the accounts when selecting a product

        this.updateTableColumns()

        break

      case TableTypeId.Pivot1:
        this.selectedTableChartTpye = TableTypeId.Pivot1
        this.sourceTable = "NTP_Data"
        this.callAPIMode = CallAPIModes.FilterAPIWithTotal

        this.treeAccountIsMulti = true     // not relevant for the UI, but relevant for the logic in base component
        this.callBackendToGetAccountAndAllProducts = true // get account from the store

        this.treeProductIsMulti = true     // not relevant for the UI, but relevant for the logic in base component
        this.callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

        break

      case TableTypeId.Pivot2:
        this.selectedTableChartTpye = TableTypeId.Pivot2
        this.sourceTable = "NTP_Data"
        this.callAPIMode = CallAPIModes.FilterAPIWithTotal

        this.treeAccountIsMulti = true     // not relevant for the UI, but relevant for the logic in base component
        this.callBackendToGetAccountAndAllProducts = true // get account from the store

        this.treeProductIsMulti = true     // not relevant for the UI, but relevant for the logic in base component
        this.callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

        break
    }

    this.resetData()
    // console.log("this.selectedTableChartTpye: ", this.selectedTableChartTpye)
    // console.log(" this.selectedFactNodes: ",  this.selectedFactNodes)
    this.setToDefault(false, false, false, true)
  }


  /** Filter Multi Select Periods */
  public filterPeriod1Title = this.cs.PERIOD
  public getSelectedPeriods= (event:any) => {

    // Sort only if Eckes
    if(this.current_database_id === 10 || this.current_database_id === 11 || this.current_database_id === 12 || this.current_database_id === 14 || this.current_database_id === 16 || this.current_database_id === 17){
      event.itemIds.sort()
      event.itemNames.sort()       
    }
    this.selectedPeriod1ItemNames = event.itemNames
    this.selectedPeriod1ItemIds = event.itemIds


    // console.log("table of facts | event.selectedNodes | periods: ", this.filterPeriod1Data, this.selectedPeriod1ItemNames, this.selectedPeriod1ItemIds)
    if(this.selectedTableChartTpye === TableTypeId.Table) {
      this.updateTableColumns()
    }
    this.feedGridWithData()
  }

  /** Filter Tree Facts */
  public treeFactName = this.cs.FACT
  public treeFactData: any[] = []
  public selectedFactNodes: SelectedTreeNode[] = []
  public getSelectedFactNodes = (event:any) => {
    let nodes:any[] = event.selectedNodes
    let nodesWithoutTopLevel: any[] = nodes.filter(n=> n.Level !== 0)
    this.selectedFactNodes = [... new Map(nodesWithoutTopLevel.map(i => [i["Id"], i])).values()]  // unique the nodes based on its Id
    // console.log("table of facts | event.selectedNodes: ", this.selectedFactNodes)
    if(this.selectedTableChartTpye === TableTypeId.Table) {
      this.updateTableColumns()
    }
    this.feedGridWithData()
  }

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
    this.el = el;

    this.authService.getAuthUser()
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      this.current_database_id = data.databases_id
    })       
  }

  feedGridWithData(): void {
    switch(this.selectedTableChartTpye) {
      case TableTypeId.Table:
        this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
          this.store
          .select<any[]>(fromDashboard.selectDataWithFiltersWithoutFacts([... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(i => i.Id)))], [... new Set(this.totalProduct.concat(this.selectedProductNodes.map(i => i.Id)))], this.selectedPeriod1ItemIds, [this.selectedShopperGroupItem.Id]))
          .pipe(first(), untilDestroyed(this))
          .subscribe( data => {
              this.hasPenetrationWarning = false

              // console.log("table of facts | data: ", data)

              let extendedResult = this.utilityService.extendDataWithCalculatedFacts(data, this.HH, this.totalProduct[0], this.totalAccount[0], this.selectedProductBenchmarkItem.Id, this.penetrationFactId,
                                  this.factsBasicCalculated, this.factsBuyerCalculated, this.factsNumberOfBuyersCalculated, this.factsNumberOfBuyersMCalculated, this.factsExpendituresCalculated, this.factsExpendituresMCalculated,
                                  this.factsPropensityCalculated, this.factsGapCalculated, this.factsPromotionCalculated, this.factsDecompostionCalculated)
              let dataExtended = extendedResult[Ids.results]
              this.hasPenetrationWarning = extendedResult[Ids.hasPenetrationWarning]
              // console.log("table of facts | dataExtended: ", dataExtended)

              this.getTableData(dataExtended)
            }
          )
        })
        break

      default : // Pivot1 and Pivot2
        if(this.selectedAccountNodes.length === 0 || this.selectedProductNodes.length === 0 || this.selectedPeriod1ItemIds.length === 0 || this.selectedFactNodes.length === 0) {
          this.loadPivotTable([], [], []) // do not show the pivot table
        } else {
            this.isFilterDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
              this.store
              .select<any[]>(fromDashboard.selectFilterDataWithFiltersWithoutFacts([... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(i => i.Id)))], [... new Set(this.totalProduct.concat(this.selectedProductNodes.map(i => i.Id)))], this.selectedPeriod1ItemIds, [this.selectedShopperGroupItem.Id]))
              .pipe(first(), untilDestroyed(this))
              .subscribe( data => {
                  this.hasPenetrationWarning = false

                  // console.log("pivot | data: ", data)

                  let extendedResult = this.utilityService.extendDataWithCalculatedFacts(data, this.HH, this.totalProduct[0], this.totalAccount[0], this.selectedProductBenchmarkItem.Id, this.penetrationFactId,
                    this.factsBasicCalculated, this.factsBuyerCalculated, this.factsNumberOfBuyersCalculated, this.factsNumberOfBuyersMCalculated, this.factsExpendituresCalculated, this.factsExpendituresMCalculated,
                    this.factsPropensityCalculated, this.factsGapCalculated, this.factsPromotionCalculated, this.factsDecompostionCalculated)
                  let dataExtended = extendedResult[Ids.results]
                  this.hasPenetrationWarning = extendedResult[Ids.hasPenetrationWarning]
                  // console.log("pivot | dataExtended: ", dataExtended)

                  let dataOfSelectedAccountsProducts = []
                  for(let d of dataExtended) {
                    if(this.selectedAccountIds.includes(d[Columns.Account]) && this.selectedProductIds.includes(d[Columns.Product])) {
                      dataOfSelectedAccountsProducts.push(d)
                    }
                  }
                  // console.log("pivot | dataOfSelectedAccountsProducts: ", dataOfSelectedAccountsProducts)

                  //convert dataExtended to normalized format
                  this.pivotData = this.utilityService.normalizeAndRenameData(dataOfSelectedAccountsProducts, this.selectedFactNodes.map(i=> i.Id), this.dictAccountDataWithIdName, this.cs.DATA_TABLE_FACTS_LIST, this.filterShopperGroupData, this.filterPeriod1Data, this.dictProductDataWithIdName)
                  // console.log("pivot | this.pivotData: ",  this.pivotData)

                  if(this.selectedTableChartTpye === TableTypeId.Pivot1) {
                    this.loadPivotTable(
                      this.pivotData,
                      [this.select_col1_selected],
                      [this.select_col2_selected,this.select_col3_selected,this.select_col4_selected,this.select_col5_selected]
                    );
                  } else {
                    this.loadPivotTable(
                      this.pivotData,
                      [this.select_col1_selected, this.select_col2_selected],
                      [this.select_col3_selected,this.select_col4_selected,this.select_col5_selected]
                    );
                  }
                }
              )
            })
        }
        break
    }
  }

  handleExportExcel() {
    if(this.selectedAccountNodes.length === 0 || this.selectedProductNodes.length === 0 || this.selectedPeriod1ItemIds.length === 0 || this.selectedFactNodes.length === 0) {
      alert(this.cs.EXPORT_EXCEL_WARNING)
      return
    }

    switch(this.selectedTableChartTpye) {
      case TableTypeId.Table:
        this.exportTableToExcel()
        break
      case TableTypeId.Pivot1:
        this.exportPivot1ToExcel()
        break
      case TableTypeId.Pivot2:
        this.exportPivot2ToExcel()
        break
    }
  }

  /** Table methods */

  private updateTableColumns() {
    this.tableFactColumns = []
    this.tablePeriodColumns = []
    this.tableData = []

    // update column definitions
    for(let n of this.selectedFactNodes) {
      let factId = n.Id
      let factName = n.Name
      this.tableFactColumns.push({"field": factId, "name": factName})

     // console.log("selectedPeriod1ItemIds", this.selectedPeriod1ItemIds)
    //  console.log("selectedPeriod1ItemNames", this.selectedPeriod1ItemNames)
      

      for(let i = 0; i < this.selectedPeriod1ItemIds.length; i++) {
        let periodId = this.selectedPeriod1ItemIds[i]
        let periodName = this.selectedPeriod1ItemNames[i]
        this.tablePeriodColumns.push({"field": factId + "-" + periodId, "name": periodName, "fact": factId, "period": periodId, "excelColumnName": factName + "-" + periodName})
      }

      if(this.selectedChangeItem.Id === "show" && this.selectedPeriod1ItemIds.length >= 2) {
        this.columnSpan = this.selectedPeriod1ItemIds.length + 1
        let changeName = ""
        if(this.selectedMetricsItem.Id === "percentage") {
          changeName = "Change (Percentage)"
        } else {
          changeName = "Change (Absolute)"
        }
        this.tablePeriodColumns.push({field: "change_" + factId + "_number", name: changeName, "excelColumnName": changeName + "-" + factName})
      } else {
        this.columnSpan = this.selectedPeriod1ItemIds.length
      }
    }

   //  console.log("updateTableColumns | tableFactColumns: ", this.tableFactColumns, )
   //  console.log("updateTableColumns | tablePeriodColumns: ", this.tablePeriodColumns,)
    // console.log("updateTableColumns | columnSpan: ", this.columnSpan)
  }

  handlePenetrationWarning() {
    if(this.selectedTableChartTpye === TableTypeId.Pivot1) {
      this.loadPivotTable(
        this.pivotData,
        [this.select_col1_selected],
        [this.select_col2_selected,this.select_col3_selected,this.select_col4_selected,this.select_col5_selected]
      );
    } else {
      this.loadPivotTable(
        this.pivotData,
        [this.select_col1_selected, this.select_col2_selected],
        [this.select_col3_selected,this.select_col4_selected,this.select_col5_selected]
      );
    }
  }

  public getColor(col: any, data: any) {
    let colorWithPenetrationWarning = "#FF6E08"
    let colorWithoutPenetrationWarning = "#000000"
    if(this.hidePenetrationWarning) {
      return colorWithoutPenetrationWarning
    }

    if("period" in col) {
      return data['Penetration_' + col.period]? colorWithPenetrationWarning : colorWithoutPenetrationWarning
    } else {
      // it is a "change_x_x" column, there is no "period" info for this column.
      // TODO: if there are 3 periods, then the change value is calculated by 2 of the periods. so need to exactly check the penetration of these 2 periods
      for(let perId of this.selectedPeriod1ItemIds) {
        if(data['Penetration_' + perId]) {
          return colorWithPenetrationWarning
        }
      }
      return colorWithoutPenetrationWarning
    }
  }

  private getTableData(data: any[]) {
    this.tableData = []

    if(this.treeAccountIsMulti) {
      this.firstColumnName = "Account"
      this.firstColumnId = Columns.Account

        // get the tableData
      for(let pNode of this.selectedAccountNodes) {
        let tableDataRow: any= {}
        tableDataRow["BGNum"] = pNode.Name
        tableDataRow["ProdNum"] = this.selectedProductNodes[0].Name

        for(let fn of this.selectedFactNodes) {
          let values: number[] = []
          let tablePeriodColumnsWithFilteredFacts = this.tablePeriodColumns.filter(i => i.fact === fn.Id)
          for(let pc of tablePeriodColumnsWithFilteredFacts){
            if(data.filter(d=> d[Columns.Account] === pNode.Id && d[Columns.Product] === this.selectedProductNodes[0].Id && d[Columns.Period] === pc.period && d[Columns.ShopperGroup] === this.selectedShopperGroupItem.Id).length >0) {
              tableDataRow[pc["field"]] = this.utilityService.myRoundNumber(data.filter(d=> d[Columns.Account] === pNode.Id && d[Columns.Product] === this.selectedProductNodes[0].Id && d[Columns.Period] === pc.period && d[Columns.ShopperGroup] === this.selectedShopperGroupItem.Id)[0][pc.fact] , 2)
              // console.log("tableDataRow[pc[field]]: ", tableDataRow[pc["field"]])
              values.push(tableDataRow[pc["field"]])

              tableDataRow[Ids.Penetration_Prefix + pc.period] = data.filter(d=> d[Columns.Account] === pNode.Id && d[Columns.Product] === this.selectedProductNodes[0].Id && d[Columns.Period] === pc.period && d[Columns.ShopperGroup] === this.selectedShopperGroupItem.Id)[0][Ids.Penetration_Row]
            }
          }

          if(this.selectedChangeItem.Id === "show" && this.selectedPeriod1ItemIds.length >= 2) {
            tableDataRow["change_" + fn.Id] = this.calculateDifference(values, this.selectedMetricsItem.Id)
            tableDataRow["change_" + fn.Id + "_number"] = this.calculateDifference(values, this.selectedMetricsItem.Id, "number")
          }
        }
        this.tableData.push(tableDataRow)
      }
    } else {
      this.firstColumnName = "Produkt"
      this.firstColumnId = Columns.Product

        // get the tableData
      for(let pNode of this.selectedProductNodes) {
        let tableDataRow: any= {}
        tableDataRow["ProdNum"] = pNode.Name
        tableDataRow["BGNum"] = this.selectedAccountNodes[0].Name

        for(let fn of this.selectedFactNodes) {
          let values: number[] = []
          let tablePeriodColumnsWithFilteredFacts = this.tablePeriodColumns.filter(i => i.fact === fn.Id)
          for(let pc of tablePeriodColumnsWithFilteredFacts){
            if(data.filter(d=> d[Columns.Product] === pNode.Id && d[Columns.Account] === this.selectedAccountNodes[0].Id && d[Columns.Period] === pc.period && d[Columns.ShopperGroup] === this.selectedShopperGroupItem.Id).length > 0) {
              tableDataRow[pc["field"]] = this.utilityService.myRoundNumber(data.filter(d=> d[Columns.Product] === pNode.Id && d[Columns.Account] === this.selectedAccountNodes[0].Id && d[Columns.Period] === pc.period && d[Columns.ShopperGroup] === this.selectedShopperGroupItem.Id)[0][pc.fact] , 2)
             //  console.log("tableDataRow[pc[field]]: ", tableDataRow[pc["field"]])
              values.push(tableDataRow[pc["field"]])

              tableDataRow[Ids.Penetration_Prefix + pc.period] = data.filter(d=> d[Columns.Account] === this.selectedAccountNodes[0].Id && d[Columns.Product] === pNode.Id && d[Columns.Period] === pc.period && d[Columns.ShopperGroup] === this.selectedShopperGroupItem.Id)[0][Ids.Penetration_Row]
            }
          }

          if(this.selectedChangeItem.Id === "show" && this.selectedPeriod1ItemIds.length >= 2) {
            tableDataRow["change_" + fn.Id] = this.calculateDifference(values, this.selectedMetricsItem.Id)
            tableDataRow["change_" + fn.Id + "_number"] = this.calculateDifference(values, this.selectedMetricsItem.Id, "number")
          }
        }

        // console.log("getTableData | tableDataRow: ", tableDataRow)
        this.tableData.push(tableDataRow)
      }
    }

    // console.log("getTableData | this.tableData: ", this.tableData)
  }

  private exportTableToExcel() {
      // header
      let header: any = {}
      let dummy: any = {}

      header["Accounts"] = this.selectedAccountNodes.map(n => n.Name).join(", ")
      header["Produkte"] = this.selectedProductNodes.map(n => n.Name).join(", ")
      header["Perioden"] = this.selectedPeriod1ItemNames.join(", ")
      header["Shopper Group"] = this.selectedShopperGroupItem.Name

      // columnValueMap
      let columnValueMap: any = {}
      if(this.treeAccountIsMulti) {
        columnValueMap["Account"] = "BGNum"
      } else {
        columnValueMap["Produkt"] = "ProdNum"
      }

      for(let pc of this.tablePeriodColumns) {
        columnValueMap[pc["excelColumnName"]] = pc["field"]
      }

      this.exportService.exportExcel("Table_Of_Facts", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.tableData, header, columnValueMap, dummy, dummy, this.tablePeriodColumns)
                        .then(fileName => {
                          console.log(`Created Excel file: ${fileName}`);
                          this.isCreatingExport = false
                        });
  }

  private calculateDifference(values: number[], metric: string, type: string = "string") {
    if(metric === "percentage") {
        // console.log("calculateDiff: ", values)
        if(values.length <= 1) {
          return 0
        }

        if(values.length === 2) {
          if(type === "string") {
            return this.utilityService.myFormatNumber(((values[1] - values[0]) / values[0] * 100) || 0, 2)
          } else {
            return this.utilityService.myRoundNumber(((values[1] - values[0]) / values[0] * 100) || 0, 2)
          }
        }

        if(values.length === 3) {
          if(type === "string") {
            return this.utilityService.myFormatNumber(((values[2] - values[1]) / values[1] * 100) || 0, 2)
          } else {
            return this.utilityService.myRoundNumber(((values[2] - values[1]) / values[1] * 100) || 0, 2)
          }
        }

        return ""
    } else {
        // console.log("calculateDiff: ", values)
        if(values.length <= 1) {
          return 0
        }

        if(values.length === 2) {
          if(type === "string") {
            return this.utilityService.myFormatNumber(values[1] - values[0], 2)
          } else {
            return this.utilityService.myRoundNumber(values[1] - values[0], 2)
          }
        }

        if(values.length === 3) {
          if(type === "string") {
            return this.utilityService.myFormatNumber(values[2] - values[1], 2)
          } else {
            return this.utilityService.myRoundNumber(values[2] - values[1], 2)
          }
        }
        return ""
    }
  }


  /** Pivot methods */

  // --------------------- pivot ---------------------------
  public getSelectedColumnsPivot1Event = (event:any) => {
    // console.log("event.selectedColumns: ", event.selectedColumns)
    this.select_col1_selected = event.selectedColumns[0]
    this.select_col2_selected = event.selectedColumns[1]
    this.select_col3_selected = event.selectedColumns[2]
    this.select_col4_selected = event.selectedColumns[3]
    this.select_col5_selected = event.selectedColumns[4]

    this.loadPivotTable(
      this.pivotData,
      [this.select_col1_selected],
      [this.select_col2_selected,this.select_col3_selected,this.select_col4_selected,this.select_col5_selected]
    );
  }

  public getSelectedColumnsPivot2Event = (event:any) => {
    // console.log("event.selectedColumns: ", event.selectedColumns)
    this.select_col1_selected = event.selectedColumns[0]
    this.select_col2_selected = event.selectedColumns[1]
    this.select_col3_selected = event.selectedColumns[2]
    this.select_col4_selected = event.selectedColumns[3]
    this.select_col5_selected = event.selectedColumns[4]

    this.loadPivotTable(
      this.pivotData,
      [this.select_col1_selected,this.select_col2_selected],
      [this.select_col3_selected,this.select_col4_selected,this.select_col5_selected]
    );
  }

  private loadPivotTable(data:any[],pivot_rows:any,pivot_columns:any){
    if (!this.el ||
        !this.el.nativeElement ||
        !this.el.nativeElement.children){
            console.log('cant build without element');
            return;
     }

    var container = this.el.nativeElement;
    var inst = jQuery(container);
    var targetElement = inst.find('#table_of_facts_pivot');
    if (!targetElement){
      console.log('cant find the pivot element');
      return;
    }

    // clear the table
    targetElement.empty()

    //if there is no data, just return, without creating a pivot
    if(data.length === 0) {
      return
    }

    // console.log("create pivot: ", data, pivot_rows, pivot_columns)
    let selectedAccounts = this.selectedAccountNodes.map(n => n.Name)
    let selectedProducts = this.selectedProductNodes.map(n => n.Name)
    let selectedFacts = this.selectedFactNodes.map(i=> i.Name)
    let selectedPerids = this.selectedPeriod1ItemNames

    let mySorters: any = {}
    mySorters[Columns.Account] = $.pivotUtilities.sortAs(selectedAccounts)
    mySorters[Columns.Product] = $.pivotUtilities.sortAs(selectedProducts)
    mySorters[Columns.Fact] = $.pivotUtilities.sortAs(selectedFacts)
    mySorters[Columns.Period] = $.pivotUtilities.sortAs(selectedPerids)

    $( document ).ready(function() {
      targetElement.pivotUI(
        data,
        {
          rows: pivot_rows,
          cols: pivot_columns,
          vals: [Columns.Value],
          aggregatorName: PIVOT_AGGREGATOR_NAME,
          sorters: mySorters,
          aggregators: $.pivotUtilities.aggregators,

          onRefresh: function() {
            let pwIsHidden = $('#p-w-div').attr('pwIsHidden') === 'true'
            var $values = $('.pvtVal')
            for (var i=0; i<$values.length; i++) {
              if ($values[i].innerHTML.startsWith(" ")) { // " " at beginning indicates of having penetration warning
                if(pwIsHidden) {
                  $values.eq(i).css('color', PENETRATION_HIDDEN_COLOR)
                } else {
                  $values.eq(i).css('color', PENETRATION_COLOR)
                }
              } else {
                $values.eq(i).css('color', PENETRATION_HIDDEN_COLOR)
              }
            }
          },
          showUI: false
        }, true); // true -> allow to override the parameters
    });
  }

  @ViewChild('pivot1')
  pivot1!: ElementRef;

  @ViewChild('pivot2')
  pivot2!: ElementRef;


  private exportPivot1ToExcel(){
    /** header */
    let header: any = {}
    header["Accounts"] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header["Produkte"] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header["Perioden"] = this.selectedPeriod1ItemNames.join(", ")
    header["Shopper Group"] = this.selectedShopperGroupItem.Name

    let div = this.pivot1.nativeElement
    let pivotUITable = div.children[0]  // get HTML Table from div
    let htmlTable = pivotUITable.rows[2].cells[1].children[0]

    let Penetration: any = []

    for (var x:any = 0; x < htmlTable.children[1].rows.length - 1 ; x++) {
      let rowX = htmlTable.children[1].rows[x]      
      for (var y:any = 1; y < rowX.cells.length - 1; y++) {
        let rowX_Cell = rowX.cells[y]
        if(rowX_Cell.style.color === 'rgb(255, 110, 8)'){
          Penetration.push({
            row: x + 1,
            col: y
          });          
        }
      }     
    }  

    for (var i = 0; i < htmlTable.rows.length ; i++) {
      let row = htmlTable.rows[i]
      // console.log("row: ", row)
      /** each rdata should not contain the last column, since it is Totals */
      for (var j = 0; j < row.cells.length - 1; j++) {
        let col = row.cells[j]
        // console.log("col: ", col)
      }
    }

    let row0: any[] = ['']
    let row1: any[] = ['']
    let row2: any[] = ['']
    let row3: any[] = ['']
    let dataRows: any[][] = []

    /** row0 doesn't have the first and the last element. (which is '' and 'Totals')*/
    let tableRow0 = htmlTable.rows[0]
    // console.log("tableRow0: ", tableRow0)
    for(let j = 2; j < tableRow0.cells.length - 1; j++) {
      let cell = tableRow0.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row0.push(cell.innerText)
      }
    }

    /** row1 - row3 contains all elements */
    let tableRow1 = htmlTable.rows[1]
    // console.log("tableRow1: ", tableRow1)
    for(let j = 1; j < tableRow1.cells.length; j++) {
      let cell = tableRow1.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row1.push(cell.innerText)
      }
    }

    let tableRow2 = htmlTable.rows[2]
    // console.log("tableRow2: ", tableRow2)
    for(let j = 1; j < tableRow2.cells.length; j++) {
      let cell = tableRow2.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row2.push(cell.innerText)
      }
    }

    let tableRow3 = htmlTable.rows[3]
    // console.log("tableRow3: ", tableRow3)
    for(let j = 1; j < tableRow3.cells.length; j++) {
      let cell = tableRow3.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row3.push(cell.innerText)
      }
    }

    // console.log("rowX: ", row0, row1, row2, row3)

    /** dataRows should not contain the last row, which is Totals */
    for (var i = 5; i < htmlTable.rows.length - 1 ; i++) {
      let row = htmlTable.rows[i]
      let rdata: any[] = []
      /** each rdata should not contain the last column, since it is Totals */
      for (var j = 0; j < row.cells.length - 1; j++) {
        let cell = row.cells[j]
        if(j === 0) {
          rdata.push(cell.innerText)
        } else {
          rdata.push(Number(cell.innerText.replaceAll(".", "").replace(",", ".")))  // change the german number format (string) back to the english format
        }
      }
      dataRows.push(rdata)
    }

    // console.log("dataRows: ", dataRows)

    this.exportService.exportPivot1(this.hidePenetrationWarning, Penetration, this.dashboardTitle, header, row0, row1, row2, row3, dataRows)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  private exportPivot2ToExcel(){
    /** header */
    let header: any = {}
    header["Accounts"] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header["Produkte"] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header["Perioden"] = this.selectedPeriod1ItemNames.join(", ")
    header["Shopper Group"] = this.selectedShopperGroupItem.Name

    let div = this.pivot2.nativeElement
    let pivotUITable = div.children[0]  // get HTML Table from div
    let htmlTable = pivotUITable.rows[2].cells[1].children[0]

    let Penetration: any = []
 
    for (var x:any = 0; x < htmlTable.children[1].rows.length - 1 ; x++) {
      let rowX = htmlTable.children[1].rows[x]      
       for (var y:any = 1; y < rowX.cells.length - 1; y++) {
        let rowX_Cell = rowX.cells[y]
        if(rowX_Cell.style.color === 'rgb(255, 110, 8)'){      
          Penetration.push({
            row: x + 1,
            col: y
          });          
        }
      }     
    }  

    let row0: any[] = ['', '']
    let row1: any[] = ['', '']
    let row2: any[] = ['', '']
    let dataRows: any[][] = []
    let dataColumns: any[][] = []

    /** row0 doesn't have the first two and the last one element. (which is '', axislabel and 'Totals')*/
    let tableRow0 = htmlTable.rows[0]
    for(let j = 2; j < tableRow0.cells.length - 1; j++) {
      let cell = tableRow0.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row0.push(cell.innerText)
      }
    }

    /** row1 - row2 contains all elements */
    let tableRow1 = htmlTable.rows[1]
    for(let j = 1; j < tableRow1.cells.length; j++) {
      let cell = tableRow1.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row1.push(cell.innerText)
      }
    }

    let tableRow2 = htmlTable.rows[2]
    for(let j = 1; j < tableRow2.cells.length; j++) {
      let cell = tableRow2.cells[j]
      for(let c = 0; c < cell.colSpan; c++) {
        row2.push(cell.innerText)
      }
    }

    
    
    let firstCol = ""
    // Each Row
    for (var i = 4; i < htmlTable.rows.length - 1 ; i++) {
      let row = htmlTable.rows[i]
      let rdata: any[] = []            
      if(row.cells[1].className === 'pvtRowLabel'){
        rdata.push(row.cells[0].innerText)  
        rdata.push(row.cells[1].innerText)  
        for (var j = 2; j < row.cells.length - 1; j++) {
          let cell = row.cells[j]
          rdata.push(Number(cell.innerText.replaceAll(".", "").replace(",", ".")))
        }         
        firstCol = row.cells[0].innerText
        dataRows.push(rdata)                
      }
      else {
        rdata.push(firstCol)  
        rdata.push(row.cells[0].innerText)  
        for (var j = 1; j < row.cells.length - 1; j++) {
          let cell = row.cells[j]
          rdata.push(Number(cell.innerText.replaceAll(".", "").replace(",", ".")))
        }         
        dataRows.push(rdata)         
      }
    }    
    // loop dataRows to add the missing first column
    let rightColAmount = dataRows[0].length
    let firstColValue = ''
    for(let i = 0; i < dataRows.length; i++) {
      if(dataRows[i].length === rightColAmount) {
        firstColValue = dataRows[i][0]
      } else {
        dataRows[i].unshift(firstColValue)
      }
    }
 

    

    this.exportService.exportPivot2(this.hidePenetrationWarning, Penetration, this.dashboardTitle, header, row0, row1, row2, dataRows)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes

    selection[UserSelectionIds.Periods] = {Ids: this.selectedPeriod1ItemIds, Names: this.selectedPeriod1ItemNames}

    let selectedFacts:any[] = this.treeFactData.flatMap(i=>i.Children).filter(i=>i.Selected === true).map(i=> { return { Id: i.Id, Name: i.Name }})
    let existing = Object.create(null)
    let uniqueSelectedFacts = selectedFacts.filter(o => {
      var key = ["Id", "Name"].map(k=> o[k]).join("|")
      if(!existing[key]) {
        existing[key] = true
        return true
      } else {
        return false
      }
    })
    // console.log("uniqueSelectedFacts: ", selectedFacts, uniqueSelectedFacts)
    selection[UserSelectionIds.Fact] = uniqueSelectedFacts // just save the unique facts

    selection[UserSelectionIds.ShopperGroup] =  this.selectedShopperGroupItem
    selection[UserSelectionIds.Benchmark] = this.selectedProductBenchmarkItem
    selection[UserSelectionIds.Mode] = this.selectedModeItem

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      // here should have no "this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED", since there is no swith mode, but there is a switch table type. but when changing the table type, the selectedBenchmark should be not changed
      return
    }

    // Switch (Mode)
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.treeProductIsMulti = true
        this.callBackendToGetProductAndAllAccounts = false

        this.treeAccountIsMulti = false
        this.callBackendToGetAccountAndAllProducts = true
        break

      case ModeIds.OneProductMultiAccounts:
        this.treeProductIsMulti = false
        this.callBackendToGetProductAndAllAccounts = true

        this.treeAccountIsMulti = true
        this.callBackendToGetAccountAndAllProducts = false
        break
    }
     //console.log("this.selectedModeItem: ", this.selectedModeItem)

    // Account Tree
    this.selectedAccountNodes =  this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];
    // console.log("this.selectedAccountIds: ", this.selectedAccountIds, this.selectedAccountNodeLevels)


    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
    this.filterProductBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
    // console.log("this.selectedProductIds: ", this.selectedProductIds, this.selectedProductNodeLevels, this.filterProductBenchmarkData)

    // Period (Period1)
    let periods = this.utilityService.getSelectionItem(selection, UserSelectionIds.Periods, {
      Ids: [],
      Names: []
    })
    this.selectedPeriod1ItemIds = periods.Ids
    this.selectedPeriod1ItemNames = periods.Names
    for(let pid of this.selectedPeriod1ItemIds) {
      let index = this.filterPeriod1Data.findIndex((i)=> i.Id === pid)
      let item = this.filterPeriod1Data[index]
      let itemChanged = {Id: item.Id, Name: item.Name, Checked: true}
      let copy = []
      for(let j = 0; j < this.filterPeriod1Data.length; j++) {
        if(j === index) {
          copy.push(itemChanged)
        } else {
          copy.push(this.filterPeriod1Data[j])
        }
      }
      this.filterPeriod1Data = copy
    }

    // console.log("periods: ", periods, this.selectedPeriod1ItemIds, this.selectedPeriod1ItemNames, this.filterPeriod1Data)

    //Fact
    this.selectedFactNodes = []
    let tmpFactNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, [])
    for(let sf of tmpFactNodes) {
      sf = {...sf, Name: this.cs.DATA_TABLE_FACTS_LIST.filter(i=>i.Id === sf.Id)[0].Name}
      this.selectedFactNodes.push(sf)
    }
    // console.log("this.selectedFactNodes: ", this.selectedFactNodes)
    this.treeFactData = this.cs.DATA_TABLE_FACTS
    for(let item of this.treeFactData){
      for(let ele of item.Children) {
          if(this.selectedFactNodes.map(i=>i.Id).includes(ele.Id)) {
              ele.Selected = true
          }
      }
    }
    // console.log("this.selectedFactNodes: ", this.selectedFactNodes, this.treeFactData)

    // Shopper Group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })
    this.filterValuesShopperGroupData = [this.selectedShopperGroupItem]
    // console.log("this.selectedShopperGroupItem: ", this.selectedShopperGroupItem, this.filterValuesShopperGroupData)


    // Benchmark
    this.selectedProductBenchmarkItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Benchmark, this.cs.NO_BENCHMARK_SELECTED)
    // if it is not this.cs.NO_BENCHMARK_SELECTED, then we have to check if it is selected in the tree when initializing
    if(this.selectedProductBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeProductIsMulti) {
        if(!this.selectedProductIds.includes(this.selectedProductBenchmarkItem.Id)) {
          this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        if(!this.selectedAccountIds.includes(this.selectedProductBenchmarkItem.Id)) {
          this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      }
    } else {
      this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
    }
    // console.log("this.selectedProductBenchmarkItem: ", this.selectedProductBenchmarkItem, this.cs.NO_BENCHMARK_SELECTED)

    // show data
    if(this.selectedTableChartTpye === TableTypeId.Table) {
      this.updateTableColumns()
    }
    // console.log("this.selectedTableChartTpye: ", this.selectedTableChartTpye)

    // this.feedGridWithData()
  }

  public GetSelection(){
    let Product =  [{Id: "P300002",
                     Name: "SIRUP",
                     Level: 1,                     
                     Parent: "Total expenditures"
  
      } ]

  console.log("Product", Product)

     this.UpdateSelectionProduct(Product)
    //Product.push(
      //"Id": "P300002"
    //) 
    /*
    Id
: 
"P300002"
Level
: 
1
Name
: 
"SIRUP"
Parent
: 
"Total expenditures"
*/
  //  console.log("GetSelection")
//    console.log("this.selectedAccountNodes", this.selectedAccountNodes)
    //console.log("this.selectedProductNodes", this.selectedProductNodes)
    
  }

  LoadSelection(){
    console.log("Start LoadSelection")
   // this.store.dispatch(DashboardActions.selectionTemplatesGet({dashboard: "table-of-facts"}))
    /*
    this.isPowerpointMasterLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(isLoaded => {
      this.store.select<any>(fromAuth.selectPowerpointMaster)      // select the dim from state
      .pipe(first(), untilDestroyed(this))
      .subscribe(data  => {
             console.log("powerpointMaster: ", data)
//            console.log(data)

      })
    })
    */
    this.authService.getSelectionTemplate("table-of-facts")
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      console.log("data", data)

    })
    console.log("End LoadSelection")
  }

  UpdateSelectionProduct(SelectionProducts:any){
    //console.log()
    console.log("UpdateSelectionProduct")
    console.log("this.selectedProductIds AAA", this.selectedProductIds)
    console.log("this.selectedProductNodes AAA", this.selectedProductNodes)
    console.log("this.selectedProductNodeLevels AAA", this.selectedProductNodeLevels)
    console.log("this.filterProductData AAA", this.filterProductData)
    
    //this.selectedProductIds = SelectionProducts
    //this.selectedProductIds = ["P300002"]
    //this.selectedProductNodes = SelectionProducts
    console.log("this.selectedProductIds BBB", this.selectedProductIds)
    console.log("this.selectedProductNodes BBB", this.selectedProductNodes)
    //this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    //this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];


  }

}


