import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { Columns, DashboardId, Ids, ModeIds, PPTIds, SortingIds, UserSelectionIds } from '../../../shared/model/constants';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../../shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-loyalty-propensity-ranking',
  templateUrl: './loyalty-propensity-ranking.component.html',
  styleUrls: ['./loyalty-propensity-ranking.component.scss']
})
export class LoyaltyPropensityRankingComponent extends BaseComponent {
  /** -------------------- Inputs for Base -------------------- */
  // set source table
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.PROPENSITY
  dashboardId = DashboardId.loyalty_propensity_ranking
  dashboards = this.cs.PROPENSITY_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // set period title
  filterPeriod1Title = this.cs.PERIOD

  // Facts used in dashboard
  allowedChartFactIds = ["F10", "F11", this.penetrationFactId]

  /** -------------------- Inputs for this dashboard -------------------- */
  public NoBenchmarkSelectedId: string = this.cs.NO_BENCHMARK_SELECTED.Id
  public benchmarkTitle: string = ''

  public selectChartSortingTitle = this.cs.CHART_SORTING
  public selectChartSortingData = this.cs.SELECT_CHART_SORTING_DATA
  public selectedChartSortingItem = {Id: "", Name: ""}

  @ViewChild("columnseries")
  public columnseries!: IgxColumnSeriesComponent;

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  setToDefaultIndividual() {
    // console.log("this.showDataLabel: ", this.showDataLabel)
    this.columnseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning);
  }

  public getSelectedChartSorting = (event: any) => {
    this.selectedChartSortingItem = event.selectedItem
    // console.log("this.selectedChartSortingItem: ", this.selectedChartSortingItem)
    this.feedChartWithData()
  }

  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountIds, this.selectedProductIds, [this.selectedChartFactItem.Id, this.penetrationFactId], this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log("loyalty-propensity-ranking | data: ", data)

          if(this.selectedChartSortingItem.Id === SortingIds.ranking) {
            this.utilityService.preprocessData({addMissingData: false, sortDataByValue: true, sortDataBySelectionOrder:false, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
          } else {
            this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
          }
          // console.log("loyalty-propensity-ranking | preprocessed data: ", data)

          // no need to convert, because chartData has the same format as the data
          this.chartData = this.utilityService.replaceIdWithName(data,
            Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
            this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData
          )

          this.addPenetration(this.chartData)

          this.feedBenchmarkWithData(data, this.chartData)

          this.addCategoryAndDataLabel(this.chartData)

          // console.log("loyalty-propensity-ranking | chart data: ", this.chartData)
        }
      )
    })
  }

  private addPenetration(chartData: any[]) {
    let convertedDataOfChartFact = chartData.filter(d => d[Columns.Fact] === this.selectedChartFactItem.Name)
    let convertedDataOfPenetration = chartData.filter(d => d[Columns.Fact] === this.penetrationFactName)
    for(let i of convertedDataOfChartFact) {
      let iPenetration: any = convertedDataOfPenetration.filter(d => d[Columns.Product] === i[Columns.Product] && d[Columns.Account] === i[Columns.Account] && d[Columns.Period] === i[Columns.Period] && d[Columns.ShopperGroup] === i[Columns.ShopperGroup])[0]
      i[Ids.Penetration_SingleSeries] = this.utilityService.havePenetrationWarning(iPenetration[Columns.Value])
      if(!this.hasPenetrationWarning && i[Ids.Penetration_SingleSeries]) {
        this.hasPenetrationWarning = true
      }
    }
    this.chartData = convertedDataOfChartFact
  }

  private addCategoryAndDataLabel(data: any[]) {
    let inx = 0
    for(let i of data) {
      if(this.treeAccountIsMulti) {
        i[Ids.Series] = i[Columns.Account]
      } else {
        i[Ids.Series] = i[Columns.Product]
      }

      i[Ids.Label] = this.utilityService.myFormatNumber(i[Columns.Value], 1)
      i[Ids.value] = i[Columns.Value]
      i[Ids.index] = inx

      inx++
    }
    return data
  }

  feedBenchmarkWithData(data: any[], chartData: any[]): void {
    if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {    // make sure there is one element selected in benchmark select
      if(this.treeAccountIsMulti) {
        let item = data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedChartFactItem.Id)[0]
        // console.log("treeAccountIsMulti item: ", item)
        if(item) {  // make sure the benchmark value exists
          this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
          for(let i of chartData) {
            i[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Values[0]    // add "Benchmark" in ChartData, so that they can be used to display the benchmark line in chart, as well as using them in exporting ppt
          }
        } else {
          // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
          this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
        }
      } else {
        let item = data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.selectedChartFactItem.Id)[0]
        // console.log("item: ", item)
        if(item) {
          this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
          for(let i of chartData) {
            i[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Values[0]
          }
        } else {
          // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
          this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
        }
      }
    }

    this.benchmarkTitle = this.cs.BENCHMARK
    // console.log("loyalty-propensity-ranking | selectedBenchmarkItem: ", this.selectedBenchmarkItem)
    // console.log("loyalty-propensity-ranking | feedBenchmarkWithData | chartData: ", chartData)
  }

  handlePenetrationWarning() {
    this.columnseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning)
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = this.treeAccountIsMulti ? Ids.Product : Ids.Account

    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields: any[] = []
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = true,
    options[PPTIds.ValAxisTitle] = this.selectedChartFactItem.Name

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.ColumnAndLine, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    let header = this.getPPTHeaderTemplate()
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    let dataseriesFields: any[] = []
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = true,
    options[PPTIds.ValAxisTitle] = this.selectedChartFactItem.Name

    this.exportService.exportPPT(PPTIds.ColumnAndLine, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
                      .then(fileName => {
                        console.log(`Created Single PPT file: ${fileName}`);
                        this.isCreatingExport = false
                    });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNTS] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCTS] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.FACT] = this.selectedChartFactItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name
    pptHeaderTemplate[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Name

    return pptHeaderTemplate
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    this.restService.filterData (
      this.sourceTable,
      accounts.map(i => i.Id),
      products.map(i => i.Id),
      this.getPeriodIds(),
      [this.selectedChartFactItem.Id]
    )
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      // console.log("loyalty-propensity-ranking | multippt | data: ", data)

      if(this.selectedChartSortingItem.Id === SortingIds.ranking) {
        this.utilityService.preprocessData({addMissingData: false, sortDataByValue: true, sortDataBySelectionOrder:false, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: accounts.map(i => i.Id), selectedProducts: products.map(i=> i.Id), selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
      } else {
        this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: accounts.map(i => i.Id), selectedProducts: products.map(i=> i.Id), selectedFacts: [this.selectedChartFactItem.Id], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
      }
      // console.log("loyalty-propensity-ranking | multippt | preprocessed data: ", data)

      let dataWithBenchmark = this.addBenchmarkValueInMultiPPTData(singleTreeName, data, accounts.map(i => i.Id), products.map(i=> i.Id))
      // console.log("loyalty-propensity-ranking | multippt | dataWithBenchmark: ", dataWithBenchmark)

      let multiPptData = this.utilityService.replaceIdWithName(dataWithBenchmark,
        Columns.Value, Columns.Account, Columns.Product, Columns.Period, Columns.Fact, Columns.ShopperGroup,
        this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data, this.filterChartFactData, this.filterShopperGroupData
      )
      // console.log("loyalty-propensity-ranking | multippt | multiPptData: ", multiPptData)

      let header = this.getPPTHeaderTemplate()
      let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
      let dataseriesFields: any[] = []
      let options: any = {}
      options[PPTIds.master] = this.userDefinedPptMaster
      options[PPTIds.MaxValueIsHundred] = true,
      options[PPTIds.ValAxisTitle] = this.selectedChartFactItem.Name

      this.exportService.exportPPT(PPTIds.ColumnAndLine, this.dashboardTitle, accounts.map(i=>i.Name), products.map(i=>i.Name), header, options, singleTreeName, catAxisField, dataseriesFields, multiPptData)
                        .then(fileName => {
                          console.log(`Created Multi PPT file: ${fileName}`);
                          this.isCreatingExport = false
                        });
    })
  }

  addBenchmarkValueInMultiPPTData(category:string, data: any[], accountIds: string[], productIds: string[]) {
    if(this.selectedBenchmarkItem.Id === this.cs.NO_BENCHMARK_SELECTED.Id) {
      return data
    }

    let categoryValues: string[] = []
    let categoryKey: string = ''
    let benchmarkKey: string = ''
    if(category === Ids.Account) {
      categoryValues = accountIds
      categoryKey = Columns.Account
      benchmarkKey = Columns.Product
    }
    else if(category === Ids.Product) {
      categoryValues = productIds
      categoryKey = Columns.Product
      benchmarkKey = Columns.Account
    }

    let benchmarkValue: number = 0
    for(let cv of categoryValues) {
      let slideData = data.filter(d => d[categoryKey] === cv)
      benchmarkValue = slideData.filter(d => d[benchmarkKey] === this.selectedBenchmarkItem.Id)[0][Columns.Value]
      for(let row of slideData) {
        row[this.cs.BENCHMARK] = benchmarkValue
      }
    }

    return data
  }

  handleExportExcel() {
    let header: any = {}
    header[this.cs.ACCOUNTS] =  this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS]= this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    header[this.cs.FACT] = this.selectedChartFactItem.Name
    header[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    if(this.treeAccountIsMulti) {
      columnValueMap[this.cs.ACCOUNT] = Columns.Account
      columnValueMap[this.cs.LOYALTY] = Columns.Value
      columnValueMap[this.cs.BENCHMARK] = this.cs.BENCHMARK
    } else {
        // key is the name of the column, value is the key in the data, whose value should be written in the column
      columnValueMap[this.cs.PRODUCT] = Columns.Product
      columnValueMap[this.cs.LOYALTY] = Columns.Value
      columnValueMap[this.cs.BENCHMARK] = this.cs.BENCHMARK
    }

    // console.log("export Excel: ", this.chartData, columnValueMap)
    this.exportService.exportExcel("Propensity_Ranking", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.chartData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show
    this.columnseries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning)
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Fact] = this.selectedChartFactItem
    selection[UserSelectionIds.Mode] = this.selectedModeItem
    selection[UserSelectionIds.Sorting] = this.selectedChartSortingItem
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel
    selection[UserSelectionIds.Benchmark] = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [-22]} // do not save the values

    // console.log("selection: ", selection)
    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
      return
    }

    // Switch (Mode)
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.treeProductIsMulti = true
        this.callBackendToGetProductAndAllAccounts = false

        this.treeAccountIsMulti = false
        this.callBackendToGetAccountAndAllProducts = true
        break

      case ModeIds.OneProductMultiAccounts:
        this.treeProductIsMulti = false
        this.callBackendToGetProductAndAllAccounts = true

        this.treeAccountIsMulti = true
        this.callBackendToGetAccountAndAllProducts = false
        break
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // Period (Period 1)
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Fact
    this.selectedChartFactItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, {
      Id: this.filterChartFactData.length === 0 ? '' : this.filterChartFactData[0].Id,
      Name: this.filterChartFactData.length === 0 ? '' : this.filterChartFactData[0].Name
    })
    // have to update it according to the User Language
    this.selectedChartFactItem = {...this.selectedChartFactItem, Name: this.cs.FACT_OBJECT[this.selectedChartFactItem.Id]}
    // console.log("this.selectedChartFactItem: ", this.selectedChartFactItem)


    // Benchmark
    this.selectedBenchmarkItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Benchmark, this.cs.NO_BENCHMARK_SELECTED)
    // if it is not this.cs.NO_BENCHMARK_SELECTED, then we have to check if it is selected in the tree when initializing
    if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeProductIsMulti) {
        if(!this.selectedProductIds.includes(this.selectedBenchmarkItem.Id)) {
          this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        if(!this.selectedAccountIds.includes(this.selectedBenchmarkItem.Id)) {
          this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      }
    } else {
      this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
    }
    // console.log("this.selectedBenchmarkItem: ", this.selectedBenchmarkItem, this.cs.NO_BENCHMARK_SELECTED)


    // ShopperGroup
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // sorting
    this.selectedChartSortingItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Sorting, this.cs.SELECT_CHART_SORTING_DATA[0])
    // have to update it according to the User Language
    this.selectedChartSortingItem = {...this.selectedChartSortingItem, Name: this.cs.SORTING_OBJECT[this.selectedChartSortingItem.Id]}
    // console.log("this.selectedChartSortingItem: ", this.selectedChartSortingItem)

    // chart setting
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)
  }
}
