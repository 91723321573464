import { ChangeDetectorRef, Component, ViewChild, AfterViewInit } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';
import { DataTemplateMeasureInfo, DataTemplateRenderInfo } from 'igniteui-angular-core';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { Columns, UserSelectionIds, ClientBuyerIds, PPTIds, Ids, DashboardId } from '../../../shared/model/constants';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { AuthService } from 'src/app/auth/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-distribution-top-x',
  templateUrl: './distribution-top-x.component.html',
  styleUrls: ['./distribution-top-x.component.scss']
})
export class DistributionTopXComponent extends BaseComponent implements AfterViewInit {
  /** -------------------- Inputs for Base -------------------- */
  // set source table
  sourceTable = "NTP_Data-rest-requirement"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.REST_REQUIREMENT
  dashboardId = DashboardId.distribution_top_x
  dashboards = this.cs.REST_REQUIREMENT_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // If it has 2 period filters
  hasPeriod2Filter = true     // used in base component, have to keep it

  // Facts used in dashboard
  allowedChartFactIds = ["F6", "F7", "F8", this.penetrationFactId]  //which is actually not needed, since in the backend api, it returns the needed facts

  /** -------------------- Inputs for this dashboard -------------------- */
  treeProductIsMulti = false

  public selectFactRestRequirementData = this.cs.SELECT_FACT_RR_DATA
  public selectedFactRestRequirementItem = {Id: '', Name: ''}

  /** Select Single Chart Fact | get selected item | select one fact */
  public getSelectedFactRestRequirement = (event: any) => {
    this.selectedFactRestRequirementItem = event.selectedItem

    // console.log("this.selectedFactRestRequirementItem: ", this.selectedFactRestRequirementItem)
    this.feedChartWithData()
  }

  // set default to get all accounts and a/selected products when calling the backend
  public callBackendToGetAccountAndAllProducts: boolean = false
  public callBackendToGetProductAndAllAccounts: boolean = true

  public showRestRequirementIndexChart = true

  /** new things */
  private excelTableData1 : any[] = []
  private excelTableData2 : any[] = []


  private F7_Value_P1_Percent = 0
  private F7_Value_P2_Percent = 0
  private F8_Value_P1_Percent = 0
  private F8_Value_P2_Percent = 0

  public CHART_DISTRIBUTION_PIE_MAT1:any[] = []
  public F6_Value_P1_string: string = ''
  public CHART_DISTRIBUTION_PIE_MAT2:any[] = []
  public F6_Value_P2_string: string = ''

  public Value_P1_A_number: number = 0
  public Value_P1_B_number: number = 0
  public Value_P2_A_number: number = 0
  public Value_P2_B_number: number = 0

  public Value_P1_A_string: string = ""
  public Value_P1_B_string: string = ""
  public Value_P2_A_string: string = ""
  public Value_P2_B_string: string = ""


  
  public VerloreneAusgaben = this.cs.VerloreneAusgaben
  public VerloreneAusgabenTotal = this.cs.LOST_EXPENSES_TOTAL

  public expenditureFor = this.cs.EXPENDITURE_FOR
  public CHART_HEADING_1 = this.cs.LOST_EXPENDITURE_IN_PERCENT
  public CHART_DISTRIBUTION_BAR_1: any[] = []
  public maxXValueBar1 = 0
  public CHART_HEADING_2 = this.cs.LOST_EXPENDITURE_INDEX
  public CHART_DISTRIBUTION_BAR_2: any[] = []
  public maxXValueBar2 = 0

  @ViewChild("CHART_DISTRIBUTION_BAR_1_BarSeries1")
  public CHART_DISTRIBUTION_BAR_1_BarSeries1!: IgxColumnSeriesComponent;
  @ViewChild("CHART_DISTRIBUTION_BAR_1_BarSeries2")
  public CHART_DISTRIBUTION_BAR_1_BarSeries2!: IgxColumnSeriesComponent;
  @ViewChild("CHART_DISTRIBUTION_BAR_2_BarSeries1", {static: false})
  public CHART_DISTRIBUTION_BAR_2_BarSeries1!: IgxColumnSeriesComponent;
  @ViewChild("CHART_DISTRIBUTION_BAR_2_BarSeries2", {static: false})
  public CHART_DISTRIBUTION_BAR_2_BarSeries2!: IgxColumnSeriesComponent;

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  public ngAfterViewInit(): void {
    this.CHART_DISTRIBUTION_BAR_1_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    this.CHART_DISTRIBUTION_BAR_1_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
    this.CHART_DISTRIBUTION_BAR_2_BarSeries1.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
    this.CHART_DISTRIBUTION_BAR_2_BarSeries2.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
    //console.log('XXX');
    //console.log(this.chartColors);
    //console.log(this.CHART_DISTRIBUTION_BAR_1);
    //console.log(this.selectedPeriod2Item);

    
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show
    if(this.CHART_DISTRIBUTION_BAR_1_BarSeries1 !== undefined) { this.CHART_DISTRIBUTION_BAR_1_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning); }
    if(this.CHART_DISTRIBUTION_BAR_1_BarSeries2 !== undefined) { this.CHART_DISTRIBUTION_BAR_1_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning); }
    if(this.CHART_DISTRIBUTION_BAR_2_BarSeries1 !== undefined) { this.CHART_DISTRIBUTION_BAR_2_BarSeries1.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel); }
    if(this.CHART_DISTRIBUTION_BAR_2_BarSeries2 !== undefined) { this.CHART_DISTRIBUTION_BAR_2_BarSeries2.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel); }
  }

  public getMarkerBar2(configService: ConfigService, display: boolean): any {
    if(!display) {
      return
    }

    return {
        measure: function (measureInfo: DataTemplateMeasureInfo) {
            const context = measureInfo.context;
            const height = context.measureText("M").width;
            const width = context.measureText("0.00").width;
            measureInfo.width = width;
            measureInfo.height = height + 12;
        },
        render: function (renderInfo: DataTemplateRenderInfo) {
            let ctx = renderInfo.context;
            let x = renderInfo.xPosition;
            let y = renderInfo.yPosition;

            if (renderInfo.isHitTestRender) {
                ctx.fillStyle = renderInfo.data.actualItemBrush.fill;
                let width = renderInfo.availableWidth;
                let height = renderInfo.availableHeight;
                ctx.fillRect(x - (width / 2), y - (height), renderInfo.availableWidth, renderInfo.availableHeight);
                return;
            }
            const dataItem = renderInfo.data.item;
            if (dataItem === null) return;
            const series = renderInfo.data.series;
            const dataPath = series.valueColumn.propertyName;
            let dataValue = 0;
            dataValue = dataItem[dataPath];

            if (dataValue < 90) {
              ctx.fillStyle = configService.COLOR_RED
              ctx.strokeStyle = configService.COLOR_BLACK
            }
            else if (dataValue >= 90 && dataValue <= 110) {
              ctx.fillStyle = configService.COLOR_WHITE
              ctx.strokeStyle = configService.COLOR_BLACK
            }
            else {
              ctx.fillStyle = configService.COLOR_LIGHT_GREEN
              ctx.strokeStyle = configService.COLOR_BLACK
            }
            ctx.beginPath();
            ctx.arc(x, y, 6, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
        }
    }
  }

   /** show RestRequirementIndexChart - the chart at the right */
   public displayRestRequirementIndex = (event:any) => {
    this.showRestRequirementIndexChart = event.index.show
    if(this.showRestRequirementIndexChart) {
      // kick off the Angular change detection manually, then set the marker Template
      this.changeDetector.detectChanges();
      this.CHART_DISTRIBUTION_BAR_2_BarSeries1.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
      this.CHART_DISTRIBUTION_BAR_2_BarSeries2.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
    }
  }

  individualLogicForPeriod2() {
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.period2IsSelected = false

      this.changeDetector.detectChanges();
      this.CHART_DISTRIBUTION_BAR_1_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
      this.CHART_DISTRIBUTION_BAR_2_BarSeries1.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
    } else {
      this.period2IsSelected = true

      this.changeDetector.detectChanges();
      this.CHART_DISTRIBUTION_BAR_1_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);
      this.CHART_DISTRIBUTION_BAR_1_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning);

      if(this.showRestRequirementIndexChart) {
        this.CHART_DISTRIBUTION_BAR_2_BarSeries1.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
        this.CHART_DISTRIBUTION_BAR_2_BarSeries2.markerTemplate = this.getMarkerBar2(this.configService, this.showDataLabel);
      }
    }
  }


  feedChartWithData(): void {
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFiltersWithoutFacts([... new Set(this.totalAccount.concat(this.selectedAccountNodesFocus.map(i => i.Id), this.selectedAccountNodesCompetitor.map(i => i.Id)))], this.selectedProductNodes.map(i => i.Id), this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log(this.selectedFactRestRequirementItem)

          // get switch value, either buyers or clients. when buyers, isLB equals true. when clients, isLB equals false
          let isLB: boolean = this.selectedFactRestRequirementItem.Id === ClientBuyerIds.buyers

          // console.log("distribution top ten | data: ", data)
          // get data of focused account
          let data_focused = data.filter(d=> d[Columns.Account] === this.selectedAccountNodesFocus[0].Id)
          let data_focused_p1 = data_focused.filter(d=>d[Columns.Period] === this.selectedPeriod1Item.Id)[0]
          //  console.log("distribution top ten | data_focused: ", data)
          //  console.log("distribution top ten | data_focused: ", data_focused)
          //  console.log("distribution top ten | data_focused_p1: ", data_focused_p1)

          // get total market exclusive focus market value
          let totalmarketExclusiveFocusmarket_p1 = this.getAccountValue(data, this.totalAccount[0], this.selectedPeriod1Item.Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, isLB) - this.getAccountValue(data, this.selectedAccountNodesFocus[0].Id, this.selectedPeriod1Item.Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, isLB)
          // console.log("totalmarketExclusiveFocusmarket_p1: ", totalmarketExclusiveFocusmarket_p1)

          let data_focused_p2: any = []
          let totalmarketExclusiveFocusmarket_p2 : any = []
          if(this.period2IsSelected) {
            data_focused_p2 = data_focused.filter(d=>d[Columns.Period] === this.selectedPeriod2Item.Id)[0]
            // console.log("distribution top ten | data_focused_p2: ", data_focused_p2)

            totalmarketExclusiveFocusmarket_p2 = this.getAccountValue(data, this.totalAccount[0], this.selectedPeriod2Item.Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, isLB) - this.getAccountValue(data, this.selectedAccountNodesFocus[0].Id, this.selectedPeriod2Item.Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, isLB)
            // console.log("totalmarketExclusiveFocusmarket_p2: ", totalmarketExclusiveFocusmarket_p2)
          }


          // calculate barchart-middle data and barchart-right data
          let data_barchart_middle: any[] = []
          let data_barchart_right: any[] = []
          let all_values_barchart_middle: number[] = []
          let all_values_barchart_right: number[] = []
          let inx = 0
          for(let cid of this.selectedAccountNodesCompetitor.map(i => i.Id)) {
            let item_middle: any = {}
            item_middle[Ids.Account_Competitor] = this.selectedAccountNodesCompetitor.filter(n=>n.Id === cid)[0].Name
            item_middle[Columns.Product] = this.selectedProductNodes[0].Name
            let LBLEId: string = this.getCompetitorFact(cid, isLB)
            let LBLEId_total: string = this.getCompetitorFact(this.totalAccount[0], isLB)
            item_middle[this.selectedPeriod1Item.Name] = data_focused_p1[LBLEId] / data_focused_p1[LBLEId_total] * 100
            if(this.period2IsSelected) {
              item_middle[this.selectedPeriod2Item.Name] = data_focused_p2[LBLEId] / data_focused_p2[LBLEId_total] * 100
            }
            // console.log("item_middle: ", item_middle)
            if(!isNaN(item_middle[this.selectedPeriod1Item.Name])) {
              all_values_barchart_middle.push(item_middle[this.selectedPeriod1Item.Name])
            }

            if(!isNaN(item_middle[this.selectedPeriod2Item.Name])) {
              all_values_barchart_middle.push(item_middle[this.selectedPeriod2Item.Name])
            }
            item_middle[Ids.index] = inx
            item_middle[Ids.value1] = item_middle[this.selectedPeriod1Item.Name]
            item_middle[Ids.Label1] = this.utilityService.myFormatNumber(item_middle[this.selectedPeriod1Item.Name], 1, 1)
            item_middle[Ids.Series1] = this.selectedPeriod1Item.Name
            item_middle[Ids.Penetration_Prefix + this.selectedPeriod1Item.Name] = this.utilityService.havePenetrationWarning(data_focused_p1[this.penetrationFactId])

            item_middle[Ids.value2] = item_middle[this.selectedPeriod2Item.Name]
            item_middle[Ids.Label2] = this.utilityService.myFormatNumber(item_middle[this.selectedPeriod2Item.Name], 1, 1)
            item_middle[Ids.Series2] = this.selectedPeriod2Item.Name
            item_middle[Ids.Penetration_Prefix + this.selectedPeriod2Item.Name] = this.utilityService.havePenetrationWarning(data_focused_p2[this.penetrationFactId])

            if(!this.hasPenetrationWarning && (item_middle[Ids.Penetration_Prefix + this.selectedPeriod1Item.Name] || item_middle[Ids.Penetration_Prefix + this.selectedPeriod2Item.Name])) {
              this.hasPenetrationWarning = true
            }

            data_barchart_middle.push(item_middle)

            let item_right: any = {}
            item_right[Ids.Account_Competitor] = this.selectedAccountNodesCompetitor.filter(n=>n.Id === cid)[0].Name
            item_right[Columns.Product] = this.selectedProductNodes[0].Name
            item_right[this.selectedPeriod1Item.Name] = (data_focused_p1[LBLEId] / data_focused_p1[LBLEId_total]) / (this.getAccountValue(data, cid, this.selectedPeriod1Item.Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, isLB)/totalmarketExclusiveFocusmarket_p1) * 100
            if(this.period2IsSelected) {
              item_right[this.selectedPeriod2Item.Name] = (data_focused_p2[LBLEId] / data_focused_p2[LBLEId_total]) / (this.getAccountValue(data, cid, this.selectedPeriod2Item.Id, this.selectedProductNodes[0].Id, this.selectedShopperGroupItem.Id, isLB)/totalmarketExclusiveFocusmarket_p2) * 100
            }
            // console.log("item_right: ", item_right)
            if(!isNaN(item_right[this.selectedPeriod1Item.Name])) {
              all_values_barchart_right.push(item_right[this.selectedPeriod1Item.Name])
            }

            if(!isNaN(item_right[this.selectedPeriod2Item.Name])) {
              all_values_barchart_right.push(item_right[this.selectedPeriod2Item.Name])
            }

            item_right[Ids.index] = inx
            item_right[Ids.value1] = item_right[this.selectedPeriod1Item.Name]
            item_right[Ids.Label1] = this.utilityService.myFormatNumber(item_right[this.selectedPeriod1Item.Name], 0)
            item_right[Ids.Series1] = this.selectedPeriod1Item.Name
            item_right[Ids.value2] = item_right[this.selectedPeriod2Item.Name]
            item_right[Ids.Label2] = this.utilityService.myFormatNumber(item_right[this.selectedPeriod2Item.Name], 0)
            item_right[Ids.Series2] = this.selectedPeriod2Item.Name
            data_barchart_right.push(item_right)

            inx++
          }

          this.maxXValueBar1 = Math.max(...all_values_barchart_middle) * 2
          this.CHART_DISTRIBUTION_BAR_1 = data_barchart_middle

          this.maxXValueBar2 = Math.max(...all_values_barchart_right) * 2
          this.CHART_DISTRIBUTION_BAR_2 = data_barchart_right

          let F6_Value_P1
          // Account Clients / Kunden
          if(this.selectedFactRestRequirementItem.Id === ClientBuyerIds.clients){
            this.Value_P1_A_number = this.utilityService.myRoundNumber(data_focused_p1["F8"],0)
            this.Value_P1_B_number = this.utilityService.myRoundNumber(data_focused_p1["F6"],0)
            this.Value_P2_A_number = this.utilityService.myRoundNumber(data_focused_p2["F8"],0)
            this.Value_P2_B_number = this.utilityService.myRoundNumber(data_focused_p2["F6"],0)
          }
          // Account Buyers / Käufer
          else{
            this.Value_P1_A_number = this.utilityService.myRoundNumber((data_focused_p1["F7"] / data_focused_p1["F14"] * 100) - data_focused_p1["F7"],0)
            this.Value_P1_B_number = this.utilityService.myRoundNumber((data_focused_p1["F7"] / data_focused_p1["F14"] * 100),0)
            this.Value_P2_A_number = this.utilityService.myRoundNumber((data_focused_p2["F7"] / data_focused_p2["F14"] * 100) - data_focused_p2["F7"],0)
            this.Value_P2_B_number = this.utilityService.myRoundNumber((data_focused_p2["F7"] / data_focused_p2["F14"] * 100),0)
          }

          this.Value_P1_A_string =  this.utilityService.myFormatNumber(this.Value_P1_A_number,0)
          this.Value_P1_B_string =  this.utilityService.myFormatNumber(this.Value_P1_B_number,0)
          this.Value_P2_A_string =  this.utilityService.myFormatNumber(this.Value_P2_A_number,0)
          this.Value_P2_B_string =  this.utilityService.myFormatNumber(this.Value_P2_B_number,0)

          this.F7_Value_P1_Percent = this.Value_P1_A_number / this.Value_P1_B_number * 100
          this.F8_Value_P1_Percent = 100 - (this.Value_P1_A_number / this.Value_P1_B_number * 100)

          this.F7_Value_P2_Percent = this.Value_P2_A_number / this.Value_P2_B_number * 100
          this.F8_Value_P2_Percent = 100 - (this.Value_P2_A_number / this.Value_P2_B_number * 100)


          this.CHART_DISTRIBUTION_PIE_MAT1 = []

          // Account Clients / Kunden
          if(this.selectedFactRestRequirementItem.Id === ClientBuyerIds.clients){
            let row1: any = {}
            row1[Ids.Type] = this.cs.VerloreneAusgaben
            row1[Ids.Value] = this.F7_Value_P1_Percent
            row1[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F7_Value_P1_Percent, 1)
            row1[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT1.push(row1)
            let row2: any = {}
            row2[Ids.Type] = this.cs.Umsatzpotenzialausschöpfung
            row2[Ids.Value] = this.F8_Value_P1_Percent
            row2[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F8_Value_P1_Percent, 1)
            row2[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT1.push(row2)

            this.CHART_DISTRIBUTION_PIE_MAT2 = []
            let rowA: any = {}
            rowA[Ids.Type] = this.cs.VerloreneAusgaben
            rowA[Ids.Value] = this.F7_Value_P2_Percent
            rowA[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F7_Value_P2_Percent, 1)
            rowA[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT2.push(rowA)
            let rowB: any = {}
            rowB[Ids.Type] = this.cs.Umsatzpotenzialausschöpfung
            rowB[Ids.Value] = this.F8_Value_P2_Percent
            rowB[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F8_Value_P2_Percent, 1)
            rowB[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT2.push(rowB)

          }
          // Account Buyers / Käufer
          else{
            let row1: any = {}
            row1[Ids.Type] = this.cs.VerloreneAusgaben
            //row1[Ids.Type] = "Bedarfsdeckung"
            row1[Ids.Value] = this.F7_Value_P1_Percent
            row1[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F7_Value_P1_Percent, 1)
            row1[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT1.push(row1)
            let row2: any = {}
            row2[Ids.Type] = this.cs.DEMAND_COVERAGE
            row2[Ids.Value] = this.F8_Value_P1_Percent
            row2[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F8_Value_P1_Percent, 1)
            row2[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT1.push(row2)

            this.CHART_DISTRIBUTION_PIE_MAT2 = []
            let rowA: any = {}
            rowA[Ids.Type] = this.cs.VerloreneAusgaben
            rowA[Ids.Value] = this.F7_Value_P2_Percent
            rowA[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F7_Value_P2_Percent, 1)
            rowA[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT2.push(rowA)
            let rowB: any = {}
            rowB[Ids.Type] = this.cs.DEMAND_COVERAGE
            rowB[Ids.Value] = this.F8_Value_P2_Percent
            rowB[Ids.Summary] = this.utilityService.formatNumberAsPercent(this.F8_Value_P2_Percent, 1)
            rowB[Columns.Product] = this.selectedProductNodes[0].Name
            this.CHART_DISTRIBUTION_PIE_MAT2.push(rowB)
            }
        }
      )
    })
  }

  private getAccountValue(data: any[], accountId: string, periodId: string, productId: string, shopperGroupId: string, isLB: boolean) {
    let factId = ""
    if(isLB) {
      factId = "F7"
    } else {
      factId = "F7"
    }

    // console.log("data: ", this.selectedAccountNodesCompetitor, data, accountId, periodId)
    let items = data.filter(d=> d[Columns.Account] === accountId && d[Columns.Period] === periodId && d[Columns.Product] === productId && d[Columns.ShopperGroup] === shopperGroupId)
    let value = 0
    if (items.length > 0) {
      value = data.filter(d=> d[Columns.Account] === accountId && d[Columns.Period] === periodId && d[Columns.Product] === productId && d[Columns.ShopperGroup] === shopperGroupId)[0][factId]
    }
    return value
  }

  private getCompetitorFact(competitorAccountId: string, isLB: boolean): string {
    let LBLE = ""
    if(isLB) {
      LBLE = "LB"
    } else {
      LBLE = "LE"
    }

    let idNum = competitorAccountId.replace("BG", "")
    return LBLE + idNum
  }

  handlePenetrationWarning() {
    if(this.CHART_DISTRIBUTION_BAR_1_BarSeries1 !== undefined) { this.CHART_DISTRIBUTION_BAR_1_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning); }
    if(this.CHART_DISTRIBUTION_BAR_1_BarSeries2 !== undefined) { this.CHART_DISTRIBUTION_BAR_1_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_2Series, !this.hidePenetrationWarning); }
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName: string = Ids.Product

    let header = this.getPPTHeaderTemplate()
    let dataseriesFields = this.getPeriodNames()
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.period1] = this.selectedPeriod1Item.Name
    options[PPTIds.period1Value] = this.Value_P1_A_string + " (" + this.VerloreneAusgabenTotal + " " + this.Value_P1_B_string + ")"
    options[PPTIds.period2] = this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id? "none" : this.selectedPeriod2Item.Name
    options[PPTIds.period2Value] = this.Value_P2_A_string + " (" + this.VerloreneAusgabenTotal + " " + this.Value_P2_B_string + ")"

    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    this.exportService.createPPT(this.chartMasterPPT, PPTIds.TwoPieTwoBarCharts, this.dashboardTitle, this.selectedAccountNodesFocus.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.CHART_DISTRIBUTION_PIE_MAT1, this.CHART_DISTRIBUTION_PIE_MAT2, this.CHART_DISTRIBUTION_BAR_1, this.CHART_DISTRIBUTION_BAR_2)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    let header = this.getPPTHeaderTemplate()
    let dataseriesFields = this.getPeriodNames()
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.period1] = this.selectedPeriod1Item.Name
    options[PPTIds.period1Value] = this.Value_P1_A_string + " (" + this.VerloreneAusgabenTotal + " " + this.Value_P1_B_string + ")"
    options[PPTIds.period2] = this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id? "none" : this.selectedPeriod2Item.Name
    options[PPTIds.period2Value] = this.Value_P2_A_string + " (" + this.VerloreneAusgabenTotal + " " + this.Value_P2_B_string + ")"

    // console.log("handleExportPPT | options ", options)
    singleTreeName = Ids.Product  // singleTreeName is always Product
    let catAxisField = singleTreeName === Ids.Account? Columns.Product : Columns.Account
    this.exportService.exportPPT(PPTIds.TwoPieTwoBarCharts, this.dashboardTitle, this.selectedAccountNodesFocus.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.CHART_DISTRIBUTION_PIE_MAT1, this.CHART_DISTRIBUTION_PIE_MAT2, this.CHART_DISTRIBUTION_BAR_1, this.CHART_DISTRIBUTION_BAR_2)
                      .then(fileName => {
                          console.log(`Created Single PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNT_FOCUS] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCT] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIODS] = this.getPeriodNames().join(", ")
    pptHeaderTemplate[this.cs.ACCOUNT_CLIENT_ACCOUNT_BUYER] = this.selectedFactRestRequirementItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  // not used
  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // alert("Export will be implemented soon")
    this.isCreatingExport = false
  }

  handleExportExcel() {
    // data for excel
    this.excelTableData1 = [] // clean
    let tableData1Item: any = {}
    tableData1Item[this.cs.ACCOUNT_FOCUS] = this.selectedAccountNodesFocus[0].Name
    tableData1Item[Ids.F7_Period1] = this.F7_Value_P1_Percent
    tableData1Item[Ids.F8_Period1] = this.F8_Value_P1_Percent
    tableData1Item[Ids.F7_Period2] = this.F7_Value_P2_Percent
    tableData1Item[Ids.F8_Period2] = this.F8_Value_P2_Percent
    this.excelTableData1.push(tableData1Item)

    // console.log("this.excelTableData1: ", this.excelTableData1)

    this.excelTableData2 = [] // clean
    let tableData2Item: any = {}
    for(let i of this.CHART_DISTRIBUTION_BAR_1) {
      tableData2Item[this.cs.ACCOUNT_COMPETITOR] = i[Ids.Account_Competitor]
      tableData2Item[Ids.Lost_Expenditure_Percent_Period1] = i[this.selectedPeriod1Item.Name]
      if(this.selectedPeriod2Item.Id !== this.cs.PERIOD2_NOT_SELECTED.Id) {
        tableData2Item[Ids.Lost_Expenditure_Percent_Period2] = i[this.selectedPeriod2Item.Name]
      }

      this.excelTableData2.push(tableData2Item)
      tableData2Item = {}
    }

    for(let j of this.CHART_DISTRIBUTION_BAR_2) {
      let item = this.excelTableData2.filter(n=>n[this.cs.ACCOUNT_COMPETITOR]===j[Ids.Account_Competitor])[0]
      // console.log("item: ", item)
      item[Ids.Lost_Expenditure_Index_Period1] = j[this.selectedPeriod1Item.Name]
      item[Ids.Lost_Expenditure_Index_Period2] = j[this.selectedPeriod2Item.Name]
    }

    // console.log("this.excelTableData2: ", this.excelTableData2)

    let header:any = {}
    header[this.cs.ACCOUNT_FOCUS] = this.selectedAccountNodesFocus.map(n => n.Name).join(", ")
    header[this.cs.ACCOUNT_COMPETITOR] =  this.selectedAccountNodesCompetitor.map(n => n.Name).join(", ")
    header[this.cs.PRODUCT] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD1] = this.selectedPeriod1Item.Name
    header[this.cs.PERIOD2] = this.selectedPeriod2Item.Name
    header[this.cs.ACCOUNT_CLIENT_ACCOUNT_BUYER] = this.selectedFactRestRequirementItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap1: any = {}
    columnValueMap1[this.cs.ACCOUNT_FOCUS] = this.cs.ACCOUNT_FOCUS
    columnValueMap1[this.cs.Umsatzpotenzialausschöpfung + " - " + this.cs.PERIOD1] = Ids.F7_Period1
    columnValueMap1[this.cs.Nebenausgaben + " - " + this.cs.PERIOD1] = Ids.F8_Period1
    if(this.selectedPeriod2Item.Id !== this.cs.PERIOD2_NOT_SELECTED.Id) {
      columnValueMap1[this.cs.Umsatzpotenzialausschöpfung + " - " + this.cs.PERIOD2] = Ids.F7_Period2
    }
    if(this.selectedPeriod2Item.Id !== this.cs.PERIOD2_NOT_SELECTED.Id) {
      columnValueMap1[this.cs.Nebenausgaben + " - " + this.cs.PERIOD2] = Ids.F8_Period2
    }
    // console.log("columnValueMap1: ", columnValueMap1)

    let columnValueMap2: any = {}
    columnValueMap2[this.cs.ACCOUNT_COMPETITOR] = this.cs.ACCOUNT_COMPETITOR
    columnValueMap2[this.cs.LOST_EXPENDITURE_IN_PERCENT + " - " + this.cs.PERIOD1] = Ids.Lost_Expenditure_Percent_Period1
    if(this.selectedPeriod2Item.Id !== this.cs.PERIOD2_NOT_SELECTED.Id) {
      columnValueMap2[this.cs.LOST_EXPENDITURE_IN_PERCENT + " - " + this.cs.PERIOD2] = Ids.Lost_Expenditure_Percent_Period2
    }
    columnValueMap2[this.cs.LOST_EXPENDITURE_INDEX + " - " + this.cs.PERIOD1] = Ids.Lost_Expenditure_Index_Period1
    if(this.selectedPeriod2Item.Id !== this.cs.PERIOD2_NOT_SELECTED.Id) {
      columnValueMap2[this.cs.LOST_EXPENDITURE_INDEX + " - " + this.cs.PERIOD2] = Ids.Lost_Expenditure_Index_Period2
    }

    let dummy: any = []
    console.log("BBBBB")

    this.exportService.exportExcel("Distribution_TopX", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.twoTables, this.dashboardTitle, this.excelTableData1, header, columnValueMap1, this.excelTableData2, columnValueMap2, dummy)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.AccountFocus] = this.selectedAccountNodesFocus
    selection[UserSelectionIds.AccountCompetitor] = this.selectedAccountNodesCompetitor
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item
    selection[UserSelectionIds.Fact] = this.selectedFactRestRequirementItem
    selection[UserSelectionIds.ShowChart] = this.showRestRequirementIndexChart
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[]): void {
    // Account Focus Tree
    this.selectedAccountNodesFocus = this.utilityService.getSelectionItem(selection, UserSelectionIds.AccountFocus, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    this.selectedFocusAccountName = this.selectedAccountNodesFocus[0].Name

    // Account Competitor Tree
    this.selectedAccountNodesCompetitor = this.utilityService.getSelectionItem(selection, UserSelectionIds.AccountCompetitor, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    this.selectedAccountNodeLevelsCompetitor = [... new Set(this.selectedAccountNodesCompetitor.map(n => n.Level))]

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // shopper group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // period 1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // period 2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)
    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]

    // chart setting (has to be before individualLogicForPeriod2)
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)

    // run individualLogicForPeriod2 (since period 2 could be NoSelected)
    this.individualLogicForPeriod2()

    // fact chart (Fact)
    this.selectedFactRestRequirementItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, this.selectFactRestRequirementData[0])
    // have to update it according to the User Language
    this.selectedFactRestRequirementItem = {...this.selectedFactRestRequirementItem, Name: this.cs.FACT_RR_OBJECT[this.selectedFactRestRequirementItem.Id]}
    // console.log("this.selectedFactRestRequirementItem: ", this.selectedFactRestRequirementItem)

    // show share of rest requirement index
    this.showRestRequirementIndexChart = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShowChart, true)
  }
}
