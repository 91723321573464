import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
  private authTokenKey: string;

  constructor(
    private localStorageService: LocalStorageService
  ) {
    this.authTokenKey = 'authToken';
  }

  getAuthToken(): string {
    return this.localStorageService.getItem(this.authTokenKey) as string;
  }

  saveAuthToken(token: string) {
    this.localStorageService.setItem(this.authTokenKey, token);
  }


  saveTokens(authToken: string) {
    this.saveAuthToken(authToken);
  }

  removeTokens() {
    this.localStorageService.removeItem(this.authTokenKey);
  }
}
