import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthActions } from './auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../dashboard/shared/services/constant.service';
import { Store } from '@ngrx/store';
import { AuthState } from './auth.reducer';
import { fromAuth } from './auth.selectors';


@Injectable()
export class AuthEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private translate: TranslateService,
    private cs: ConstantService,
    private store: Store<AuthState>
  ) {}

  // login
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginRequest),
      exhaustMap(credentials =>
        this.authService.login(credentials.email, credentials.password).pipe(
          tap(data => {
            let languageToSet = "de" // the default language
            if(data.language) {
              languageToSet = data.language
            }

            this.translate.use(languageToSet).subscribe({
              next: () => {
                            // console.log(`auth-effect | loginRequest | successfully initialized '${languageToSet}' language.`)
                            this.cs.updateTranslationg()
                          },
              error: (e) => {
                             console.error(`auth-effect | loginRequest | problem with '${languageToSet}' language initialization.`)
                           }
            });
          }),
          delay(500), // wait for 0.5 second to make sure the tranlationg are loaded.
          map(data => {
            // save tokens
            this.tokenStorageService.saveTokens(data.authToken);

            // trigger login success action
            const authUser = this.authService.formatUser(data.email, data.databases_id, data.language, data.firstname, data.lastname, data.chart_color_first, data.chart_color_second);
            return AuthActions.loginSuccess({user: authUser, redirect:true});
          }),
          catchError(error => of(AuthActions.loginFailure({ error })))
        )
      )
    );
  });

  // forget password
  forgetPassword$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(AuthActions.forgetPasswordRequest),
        exhaustMap(payload =>
          this.authService.forgetPassword(payload.email).pipe(
            map(data => {
                return AuthActions.forgetPasswordSuccess();
            }),
            catchError(error => of(AuthActions.forgetPasswordFailure({ error })))
          )
        )
      )
    }
  );

  // get user
  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getAuthUserRequest),
      exhaustMap(() =>
        this.authService.getAuthUser().pipe(
          tap(data => {
            let languageToSet = "de" // the default language
            if(data.language) {
              languageToSet = data.language
            }
            this.translate.use(languageToSet).subscribe({
              next: () => {
                            // console.log(`auth-effect | getAuthUserRequest | successfully initialized '${languageToSet}' language.`)
                            this.cs.updateTranslationg()
                          },
              error: (e) => {
                             console.error(`auth-effect | getAuthUserRequest | problem with '${languageToSet}' language initialization.`)
                           }
            });
          }),
          delay(500), // wait for 0.5 second to make sure the tranlationg are loaded.
          map(data => {
            // trigger login success action
            const authUser = this.authService.formatUser(data.email, data.databases_id, data.language, data.firstname, data.lastname, data.chart_color_first, data.chart_color_second);
            return AuthActions.getAuthUserSuccess({ user: authUser, redirect: true });
          }),
          catchError(() => of(AuthActions.getAuthUserFailure()))
        )
      )
    );
  });

  // redirect
  redirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[AuthActions.loginSuccess, AuthActions.getAuthUserSuccess, AuthActions.resetPasswordSuccess]),
        tap((action) => {
          if (action.redirect) {
            this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.returnUrl || '/')
          }
        })
      );
    },
    { dispatch: false }
  );

  // logout
  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          // redirect to return url or home
          this.router.navigateByUrl(
            this.activatedRoute.snapshot.queryParams.returnUrl || '/'
          );

          setTimeout(() => {
            this.tokenStorageService.removeTokens();
          }, 500);

        })
      );
    },
    { dispatch: false }
  );

  // magic login
  magicLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.magicLoginRequest),
        exhaustMap(payload =>
          this.authService.magicLogin(payload.token).pipe(
            map(data => {
                // save tokens
                this.tokenStorageService.saveTokens(data.authToken);
                return AuthActions.magicLoginSuccess();
            }),
            catchError(error => of(AuthActions.magicLoginFailure({ error })))
          )
        )
      )
    }

  );

  magicLoginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[AuthActions.magicLoginFailure]),
        tap((action) => {
          if (action.error) {
            // console.log(JSON.parse(JSON.stringify(action.error)).error.message)
            // this.router.navigateByUrl(
            //   '/'
            // );
          }
        })
      );
    },
    { dispatch: false }
  );

  // reset password
  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPasswordRequest),
      exhaustMap(payload =>
        this.authService.resetPassword(payload.password, payload.passwordConfirm).pipe(
          map(data => {
            return AuthActions.resetPasswordSuccess({redirect:false});
          }),
          catchError(error => of(AuthActions.resetPasswordFailure({ error })))
        )
      )
    );
  });

  // update database id
  updateDBID$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateDBIDRequest),
        exhaustMap(payload =>
          this.authService.updateDBID(payload.dbid).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              return AuthActions.updateDBIDSuccess()
            }),
            catchError(error => of(AuthActions.updateDBIDFailure({ error })))
          )
        ),
      );
    }
  );

  updateDBIDSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateDBIDSuccess),
        map(() => {
          return AuthActions.logout()
        })
      );
    }
  )

  updateDBIDFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateDBIDFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save database id")
          }
        })
      );
    },
    { dispatch: false }
  )

  // update language
  updateLanguage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateLanguageRequest),
        exhaustMap(payload =>
          this.authService.updateLanguage(payload.language).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              return AuthActions.updateLanguageSuccess()
            }),
            catchError(error => of(AuthActions.updateLanguageFailure({ error })))
          )
        ),
      );
    }
  );

  updateLanguageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateLanguageSuccess),
        map(() => {
          return AuthActions.logout()
        })
      );
    }
  )

  updateLanguageFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateLanguageFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save language")
          }
        })
      );
    },
    { dispatch: false }
  )

  // update database id and language
  updateDBIDLanguage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateDBIDLanguageRequest),
        exhaustMap(payload =>
          this.authService.updateDBIDLanguage(payload.dbid, payload.language).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              return AuthActions.updateDBIDLanguageSuccess()
            }),
            catchError(error => of(AuthActions.updateLanguageFailure({ error })))
          )
        ),
      );
    }
  );

  updateDBIDLanguageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateDBIDLanguageSuccess),
        map(() => {
          return AuthActions.logout()
        })
      );
    }
  )

  updateDBIDLanguageFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateDBIDLanguageFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save database and language")
          }
        })
      );
    },
    { dispatch: false }
  )

  // update personal data
  updatePersonalData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updatePersonalDataRequest),
        exhaustMap(payload =>
          this.authService.updatePersonalData(payload.firstname, payload.lastname).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              const authUser = this.authService.formatUser(data.email, data.databases_id, data.language, data.firstname, data.lastname, data.chart_color_first, data.chart_color_second);
              return AuthActions.updatePersonalDataSuccess({ user: authUser});
            }),
            catchError(error => of(AuthActions.updatePersonalDataFailure({ error })))
          )
        ),
      );
    }
  );

  updatePersonalDataFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updatePersonalDataFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save personal data")
          }
        })
      );
    },
    { dispatch: false }
  )

  // update basic colors
  updateBasicColors$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateBasicColorsRequest),
        exhaustMap(payload =>
          this.authService.updateBasicColors(payload.firstColor, payload.secondColor).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              const authUser = this.authService.formatUser(data.email, data.databases_id, data.language, data.firstname, data.lastname, data.chart_color_first, data.chart_color_second);
              return AuthActions.updateBasicColorsSuccess({ user: authUser});
            }),
            catchError(error => of(AuthActions.updateLanguageFailure({ error })))
          )
        ),
      );
    }
  );

  updateBasicColorsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateBasicColorsFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save basic colors")
          }
        })
      );
    },
    { dispatch: false }
  )

  // update chart master
  updateChartMaster$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateChartMasterRequest),
        exhaustMap(payload =>
          this.authService.updateChartMaster(payload.chartMaster).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              return AuthActions.updateChartMasterSuccess();
            }),
            catchError(error => of(AuthActions.updateChartMasterFailure({ error })))
          )
        ),
      );
    }
  );

  updateChartMasterFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateChartMasterFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save chart master")
          }
        })
      );
    },
    { dispatch: false }
  )


  // update account product color
  updateAccountProductColor$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateAccountProductColorRequest),
        exhaustMap(payload =>
          this.authService.updateAccountProductColor(payload.accountProduct, payload.accountProductColor).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              console.log(
                "updateAccountProductColor$: ", data
              )
              return AuthActions.updateAccountProductColorSuccess();
            }),
            catchError(error => of(AuthActions.updateAccountProductColorFailure({ error })))
          )
        ),
      );
    }
  );

  updateAccountProductColorFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updateAccountProductColorFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save account product color")
          }
        })
      );
    },
    { dispatch: false }
  )

  // update powerpoint setting
  updatePowerpointMaster$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updatePowerpointMasterRequest),
        exhaustMap(payload =>
          this.authService.updatePowerpointMaster(payload.powerpointMaster).pipe(
            map(data => {
              // if there is response, then the update should be done successfully
              // console.log("updatePowerpointMaster$: ", data)

              // update powerpointMaster in the store
              let powerpointMaster = payload.powerpointMaster
              return AuthActions.updatePowerpointMasterSuccess({ powerpointMaster});
            }),
            catchError(error => of(AuthActions.updatePowerpointMasterFailure({ error })))
          )
        ),
      );
    }
  );

  updatePowerpointMasterFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.updatePowerpointMasterFailure),
        tap((action) => {
          if (action.error) {
            console.error("Unable to save powerpoint master")
          }
        })
      );
    },
    { dispatch: false }
  )

  // get powerpoint master
  powerpointMasterGet$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.powerpointMasterGet),
      concatLatestFrom(()=>this.store.select(fromAuth.selectPowerpointMasterLoadStatus)),
      filter(([, powerpointMasterLoadStatus]) => powerpointMasterLoadStatus === 'NOT_LOADED'),
      map((action) => AuthActions.powerpointMasterLoad())
    )
  );

  powerpointMasterLoad$ =
  createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.powerpointMasterLoad),
      mergeMap((action) =>
        this.authService.getPptMaster().pipe(
          map((data) => {
              let powerpointMaster
              if(data.length > 0 && "ppt_master" in data[0]) {
                powerpointMaster = data[0]["ppt_master"]
              } else {
                powerpointMaster = ""
              }

              // console.log("powerpointMasterLoad data: ", data, powerpointMaster)
              return   AuthActions.powerpointMasterLoaded({ powerpointMaster})
            }
          )
        )
      )
    )
  );
}