import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IgxAvatarModule, IgxButtonModule, IgxComboModule, IgxDropDownModule, IgxIconModule, IgxNavbarModule, IgxSelectModule, IgxToggleModule, IgxTreeModule } from '@infragistics/igniteui-angular';

import { HeaderComponent } from './layouts/header/header.component';
import { ReportBarComponent } from './layouts/report-bar/report-bar.component';
import { IgxDataChartStackedModule } from 'igniteui-angular-charts';
import { HeaderLoginComponent } from './layouts/header-login/header-login.component';



@NgModule({
  declarations: [
    //UI
    HeaderComponent,
    ReportBarComponent,
    HeaderLoginComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    IgxSelectModule,
    IgxComboModule,
    IgxButtonModule,
    IgxIconModule,
    IgxDropDownModule,
    IgxToggleModule,
    IgxAvatarModule,
    IgxNavbarModule,
    IgxDataChartStackedModule,

  ],

  exports: [
    CommonModule,
    FormsModule,

    //UI
    HeaderComponent,
    ReportBarComponent,
    HeaderLoginComponent,
  ]
})
export class SharedUIModule { }
