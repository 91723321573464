import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

type AppEnv = typeof environment;

@Injectable()
export class EnvService {
  private env: any = {};

  load() {
    this.env = Object.assign({}, environment);
  }

  get(key: string) {
    return this.env[key];
  }

  /**
   * Returns environment env of application
   */
   getEnvironment(): AppEnv {
    return environment;
  }

  /**
   * Indicates whether the apps is running in production mode
   *
   * @return {*}  {boolean}
   */
  isProd(): boolean {
    return environment.production;
  }

  /**
   * Returns app's version
   */
  getVersion(): string {
    return environment.appVersion;
  }

  /**
   * Returns the server's host url
   */
  getDataAPIUrl(): string {
    return environment?.dataApi ?? '';
  }

  getAuthApiUrl(): string {
    return environment?.authApi ?? '';
  }

}

export function envServiceFactory(envService: EnvService) {
  return () => envService.load();
}

