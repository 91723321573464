import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth/guards';
import { PageNotFoundComponent } from '../error-routing/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'propensity', pathMatch: 'full' },
  {
    path: 'propensity',
    loadChildren: () => import('./tabs/propensity/propensity.module').then(m=> m.PropensityModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'market-share',
    loadChildren: () => import('./tabs/market-share/market-share.module').then(m=> m.MarketShareModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'rest-requirement',
    loadChildren: () => import('./tabs/rest-requirement/rest-requirement.module').then(m=> m.RestRequirementModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'tactical',
    loadChildren: () => import('./tabs/tactical/tactical.module').then(m=> m.TacticalModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'demographic',
    loadChildren: () => import('./tabs/demographic/demographic.module').then(m=> m.DemographicModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'basket',
    loadChildren: () => import('./tabs/basket/basket.module').then(m=> m.BasketModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports',
    loadChildren: () => import('./tabs/reports/reports.module').then(m=> m.ReportsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m=> m.SettingsModule),
    canActivate: [AuthGuardService],
  },
  { path: '**', component: PageNotFoundComponent } // must always be last
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
