import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFacade } from '../../state/auth.facade';
import { ConfirmedValidator } from '../../utils/confirmed.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoadingMagicLogin$ = this.authFacade.isLoadingMagicLogin$;
  hasMagicLoginError$ = this.authFacade.hasMagicLoginError$;
  isMagicLoggedIn$ = this.authFacade.isMagicLoggedIn$;

  isSettingPassword$ = this.authFacade.isSettingPassword$;
  hasSetPasswordError$ = this.authFacade.hasSetPasswordError$;
  isPasswordSet$ = this.authFacade.isPasswordSet$;

  resetPasswordForm: UntypedFormGroup = new UntypedFormGroup({});

  private magicToken: string = '';

  constructor(private activatedRoute: ActivatedRoute, private authFacade: AuthFacade, private fb: UntypedFormBuilder, private router: Router) {
    this.resetPasswordForm = fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validator: ConfirmedValidator('password', 'passwordConfirm')
    } as AbstractControlOptions)
  }

  ngOnInit(): void {
    this.magicToken = this.activatedRoute.snapshot.queryParams.token;
    // console.log("this.magicToken: ", this.magicToken)
    this.authFacade.magicLogin(this.magicToken);
  }

  submit() {
    const { password, passwordConfirm } = this.resetPasswordForm.value;
    this.authFacade.resetPassword(password, passwordConfirm)
  }

}
