import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { LayoutComponent } from '../../layout/layout.component';
import { DistributionTopXComponent } from './distribution-top-x/distribution-top-x.component';
import { ActualVsExpectedComponent } from './actual-vs-expected/actual-vs-expected.component';
import { DistributionTableComponent } from './distribution-table/distribution-table.component';
import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { DashboardId } from '../../shared/model/constants';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.distribution_top_x, pathMatch: 'full' },
      { path: DashboardId.distribution_top_x, component: DistributionTopXComponent },
      // { path: DashboardId.distribution_table, component: DistributionTableComponent },
      // { path: DashboardId.actual_vs_expected, component: ActualVsExpectedComponent },
      { path: DashboardId.account_balance, component: AccountBalanceComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RestRequirementRoutingModule { }
