<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
      [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

      [tabAccountTitle]="tabAccountTitle"
      [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

      [tabProductTitle]="tabProductTitle"
      [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

      [tabPeriodFactTitle]="tabPeriodFactTitle"
      [showTwoSinglePeriodFilters]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item" [filterPeriod2Title]="filterPeriod2Title" [filterPeriod2Data]="filterPeriod2Data" [selectedPeriod2Item]="selectedPeriod2Item"
      [showSingleFactFilter]="true" [selectSingleFactTitle]="filterChartFactTitle" [selectSingleFactData]="factSelectData" [selectedSingleFactItem]="selectedChartFactItem" [getSelectedSingleFact]="getSelectedChartFact"

      [tabSelectionTitle]="tabSelectionTitle"
      [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
      [showStaticFilter]="true"  [selectStaticFilterTitle]="selectChangeRateTitle" [selectStaticFilterData]="selectChangeRateData" [selectedStaticFilterItem]="selectedChangeRateItem" [getSelectedStaticFilterItem]="getSelectedChangeRate"
      [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"
      [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

      [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [chipFactTitle]="factTitle"                        [selectedFact]="selectedChartFactItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti" [showInfoFact]="true"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item[modeCategory]}}</div>
        <div><font [color]="'#000000'">{{selectedChartFactItem.Name}}:</font> {{item.Label}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item[modeCategory]}}</div>
        <div><font [color]="'#000000'">{{selectedChangeRateItem.Name}}:</font> {{item.Label}}</div>
      </ng-template>

      <div class="flex flex-row">
        <div class="flex flex-row w-1/2">
          <div class="flex flex-col w-full">
            <igx-data-chart #barChart isHorizontalZoomEnabled="false" isVerticalZoomEnabled="false" width="600px"
              height="550px" [dataSource]="chartData">
              <igx-numeric-x-axis [title]="selectedChartFactItem.Name" #xAxis1 minimumValue="0" majorStrokeThickness="0" abbreviateLargeNumbers="true"></igx-numeric-x-axis>
              <igx-category-y-axis [label]="modeCategory" [dataSource]="chartData" #yAxis1 isInverted="true" gap="0.5" overlap="-0.1"></igx-category-y-axis>

              <igx-bar-series #barFactSeries [brush]="chartColors[0]" outline="Transparent" [dataSource]="chartData" valueMemberPath="Value" [xAxis]="xAxis1" [yAxis]="yAxis1" [title]="selectedPeriod1Item.Name"
                showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
                isTransitionedInEnabled="false" isHighlightingEnabled="false"
                isCustomCategoryStyleAllowed="true" (assigningCategoryStyle)="assignAccountColors($event)"
                >
              </igx-bar-series>
            </igx-data-chart>

          </div>
        </div>

        <div class="flex flex-row w-1/2">
          <div *ngIf="showChangeChart">
            <igx-data-chart #barChangeChart isHorizontalZoomEnabled="false" isVerticalZoomEnabled="false" width="600px"
                height="550px" [dataSource]="changeChartData">
                <igx-numeric-x-axis [title]="selectedChangeRateItem.Name" #xAxis2
                majorStrokeThickness="0" referenceValue="0" abbreviateLargeNumbers="true"
                [minimumValue]="minChangeValue"
                [maximumValue]="maxChangeValue"
                [interval]="interval"
                ></igx-numeric-x-axis>
                <igx-category-y-axis [label]="modeCategory" [dataSource]="changeChartData" #yAxis2 isInverted="true" gap="0.5" overlap="-0.1"></igx-category-y-axis>

                <igx-bar-series #barChangeSeries [dataSource]="changeChartData" [valueMemberPath]="selectedChangeRateItem.Id" [xAxis]="xAxis2" [yAxis]="yAxis2" [title]="selectedPeriod2Item.Name"
                  showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
                  isTransitionedInEnabled="true" isHighlightingEnabled="true"
                  isCustomCategoryStyleAllowed="true" (assigningCategoryStyle)="assigningCategoryStyle($event)"
                  >
                </igx-bar-series>
            </igx-data-chart>
          </div>
        </div>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [multiSelectedNodeLevels]="multiSelectedNodeLevels"
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>

</section>


