// ***************************************************************
// have to export them directly because if put them in the ConfigService,
// it doesn't work at the place where they are needed
// ***************************************************************


export const PENETRATION_COLOR: string = "#FF6E08"
export const PENETRATION_HIDDEN_COLOR: string = "#000000"
export const PIVOT_AGGREGATOR_NAME: string = "List Unique Values"

