import { ChangeDetectorRef, Component, ViewChild, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { IgxColumnSeriesComponent } from 'igniteui-angular-charts';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { Columns, DashboardId, Ids, MarketShareExpenditureId, ModeIds, PPTIds, UserSelectionIds } from '../../../shared/model/constants';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-loyalty-simulation',
  templateUrl: './loyalty-simulation.component.html',
  styleUrls: ['./loyalty-simulation.component.scss']
})
export class LoyaltySimulationComponent extends BaseComponent implements OnInit{
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data_Normalized"

  // Dashboard Title
  dashboardCategoryTitle = this.cs.MARKET_SHARE
  dashboardId = DashboardId.loyalty_simulation
  dashboards = this.cs.MARKET_SHARE_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // static filter if existing
  public selectMarketShareExpenditureTitle: string = this.cs.SELECT
  public selectMarketShareExpenditureData: any[] = this.cs.SELECT_MARKETSHARE_EXPENDITURE_DATA
  public selectedMarketShareExpenditureItem: SelectItem = {Id: "", Name: ""}

  // Facts used in dashboard
  loyalityFactId = "F10"
  totalExFactId = "F6"
  resExpFactId = "F7"
  loyalityFactName = ""
  allowedChartFactIds = [this.loyalityFactId, this.totalExFactId, this.resExpFactId, this.penetrationFactId]

  /** -------------------- Inputs for this dashboard -------------------- */
  filterPeriod1Title = this.cs.PERIOD

  public NoBenchmarkSelectedId: string = this.cs.NO_BENCHMARK_SELECTED.Id
  public benchmarkTitle: string = this.cs.BENCHMARK
  public dataWithAllUsedFacts: any[] = []
  public dataWithAllUsedFactsTotalCountry: any[] = []
  public dataWithAllUsedFactsMultiProducts: any[] = []

  

  @ViewChild("factSeries")
  public factSeries!: IgxColumnSeriesComponent;

  @ViewChild("benchmarkSeries")
  public benchmarkSeries!: IgxColumnSeriesComponent;

  public minChangeValue!: number
  public maxChangeValue!: number
  public interval!: number

  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)
  }

  setToDefaultIndividual() {
    this.changeDetector.detectChanges();
    if(this.factSeries !== undefined) { this.factSeries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.benchmarkSeries !== undefined) { this.benchmarkSeries.markerTemplate = this.utilityService.getMarker(this.configService, false); }
  }

  getSelectedBenchmarkItemIndividual(){
    this.changeDetector.detectChanges();
    if(this.factSeries !== undefined) { this.factSeries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.benchmarkSeries !== undefined) { this.benchmarkSeries.markerTemplate = this.utilityService.getMarker(this.configService, false); }
  }

  /** Select Market Share or Expenditure */
  public getSelectedMarketShareExpenditure = (event: any) => {
    this.selectedMarketShareExpenditureItem = event.selectedItem
  //   console.log("this.selectedMarketShareExpenditureItem", this.selectedMarketShareExpenditureItem)    
    this.feedChartWithData()
  }

  feedChartWithData(): void {
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( data => {
            this.hasPenetrationWarning = false

             //console.log("Loyalty-simulation | data: ", data)
        //  console.log("this.selectedAccountNodes.map(n => n.Id)", this.selectedAccountNodes.map(n => n.Id))
          
          //console.log("ZZZ", fromDashboard.selectDataWithFilters(['BG0'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
            this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.loyalityFactId], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
            this.dataWithAllUsedFacts = this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue)
            this.feedBenchmarkWithData(data, this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id))
             //console.log("this.dataWithAllUsedFacts with expenditure", this.dataWithAllUsedFacts)


            //console.log("1",this.chartData)
            //console.log("MarketShareExpenditureId.marketshare", MarketShareExpenditureId.marketshare)
            this.chartData = this.utilityService.replaceIdWithName3(this.dataWithAllUsedFacts,
              Columns.Account, Columns.Product, Columns.Period,
              this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data,
              [this.loyalityFactId], this.filterChartFactData, [this.benchmarkTitle, MarketShareExpenditureId.expenditure, MarketShareExpenditureId.marketshare])


              //console.log("2",this.chartData) 
            // add data labels in change chart
            let changeValues: number[] = []
            let idx = 0;
            for (const item of this.chartData) {
              if(this.treeAccountIsMulti) {
                item[Ids.Category] = item[Columns.Account]
              } else {
                item[Ids.Category] = item[Columns.Product]
              }

              item[Ids.index] = idx;
            //  item[Ids.value] = this.selectedMarketShareExpenditureItem.Id === MarketShareExpenditureId.expenditure ? item[MarketShareExpenditureId.expenditure] * 10 : item[MarketShareExpenditureId.marketshare]
              item[Ids.value1] = item[this.loyalityFactId]
              item[Ids.value2] = item[this.benchmarkTitle]
              item[Ids.Label] = this.selectedMarketShareExpenditureItem.Id === MarketShareExpenditureId.expenditure ? (item[MarketShareExpenditureId.expenditure]  ? this.utilityService.myFormatNumber(item[MarketShareExpenditureId.expenditure] , 1) : '0') : (item[MarketShareExpenditureId.marketshare] ? this.utilityService.myFormatNumber(item[MarketShareExpenditureId.marketshare], 1) : '0');
              item[Ids.Label1] = this.utilityService.myFormatNumber(item[this.loyalityFactId], 1, 1)
              item[Ids.Label2] = this.utilityService.myFormatNumber(item[this.benchmarkTitle], 1, 1)
              item[Ids.Color] = item[Ids.value] >= 0 ? this.configService.COLOR_GREEN.replace("#", "") : this.configService.COLOR_RED.replace("#", "")
              
              if(this.selectedMarketShareExpenditureItem["Id"] === 'expenditure'){
                changeValues.push(item[MarketShareExpenditureId.expenditure])                
              }
              else {
                changeValues.push(item[MarketShareExpenditureId.marketshare])
              }
              
              //console.log("changeValues", changeValues)
              // Add Penetration Value in chartData
              item[Ids.Penetration_SingleSeries] = this.utilityService.havePenetrationWarning(item[this.penetrationFactId])
              if(!this.hasPenetrationWarning && item[Ids.Penetration_SingleSeries]) {
                this.hasPenetrationWarning = true
              }

              idx++;
            }
             //console.log("Loyalty-simulation | chart data: ", this.chartData)
            // console.log("Loyalty-simulation | this.hasPenetrationWarning: ", this.hasPenetrationWarning)

            // get the min and min value for the xAxis of the change chart
            let minValue = Math.min.apply(Math, changeValues)
            let maxValue = Math.max.apply(Math, changeValues)

        //    console.log("this.chartData", this.chartData)
        //    console.log("minValue", minValue)
            //console.log("maxValue", maxValue)
            //console.log("maxValue", maxValue)
            if(maxValue <= 0) {
              this.maxChangeValue = 0
              this.minChangeValue = Math.round(minValue - 1)
            } else if(minValue >= 0) {
              this.minChangeValue = 0
              this.maxChangeValue = Math.round(maxValue + 1)
            } else if(maxValue > 0 && minValue < 0) {
              this.minChangeValue = Math.round(minValue - 1)
              this.maxChangeValue = Math.round(maxValue + 1)
            }
            //console.log("minChangeValue", this.minChangeValue)
            //console.log("maxChangeValue", this.maxChangeValue)

            if(Math.abs(this.maxChangeValue) >= Math.abs(this.minChangeValue)) {
              this.interval = Math.abs(this.maxChangeValue)
            } else {
              this.interval = Math.abs(this.minChangeValue)
            }            
          }
        )
      })

  }

  feedBenchmarkWithData(data: any[], accountIds: string[]=[], productIds: string[]=[]): void {
  //  console.log("feedBenchmarkWithData")
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(['BG1'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      //.select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( dataMultiAccount => {
          //console.log("dataX", dataMultiAccount)
          this.dataWithAllUsedFactsTotalCountry = this.chartService.covertToChartData2(dataMultiAccount, this.fieldsWithoutFactAndValue)
          //console.log("dataWithAllUsedFactsTotalCountry", this.dataWithAllUsedFactsTotalCountry)

        //console.log("this.dataWithAllUsedFactsTotalCountry[0][F6]", this.dataWithAllUsedFactsTotalCountry[0]["F6"])

        if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {    // make sure there is one element selected in benchmark select
          if(this.treeAccountIsMulti) {
           // console.log("MULTI ACCOUNT")
            for(let product of productIds) {
              let item = data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.loyalityFactId && i[Columns.Product] === product)[0]     // you don't need to filter on period and shoppergroup, since they are filtered at the first place when calling feedChartWithData at selecting data from the store.
              if(item) {  // make sure the benchmark value exists
                this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
                for(let i of this.dataWithAllUsedFacts) {
                  if(i[Columns.Product] === product) {
                    i[this.benchmarkTitle] = this.selectedBenchmarkItem.Values[0]    // add "Benchmark" in ChartData, so that they can be used to display the benchmark line in chart, as well as using them in exporting ppt
                  //  i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/100         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                  i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/10         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                    //i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / i[this.resExpFactId]) - (i[this.totalExFactId] * i[this.loyalityFactId] / i[this.resExpFactId]))*100)/100
                    i[MarketShareExpenditureId.marketshare] = Math.round((((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0]) / this.dataWithAllUsedFactsTotalCountry[0]["F6"]) - (i[this.totalExFactId] * i[this.loyalityFactId] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]))*100)/100
                  }
                }
              } else {
                // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
                this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
              }
            }
          } else {
       //     console.log("MULTI PRODUCT")
            for(let account of accountIds) {
              let item = data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.loyalityFactId && i[Columns.Account] === account)[0]
              if(item) {
                this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
                for(let i of this.dataWithAllUsedFacts) {
                  if(i[Columns.Account] === account) {
                    i[this.benchmarkTitle] = this.selectedBenchmarkItem.Values[0]
                    //i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/100         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                    i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/10         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                    //i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / i[this.resExpFactId]) - (i[this.totalExFactId] * i[this.loyalityFactId] / i[this.resExpFactId]))*100)/100
                    i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]) - (i[this.totalExFactId] * i[this.loyalityFactId] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]))*100)/100
                  }
                }
              } else {
                // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
                this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
              }
            }
          }
        }  

       
        }
      )
    })      


    /*
    if(this.treeAccountIsMulti) {
      console.log("MULTI ACCOUNT")
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(['BG1'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        //.select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( dataMultiAccount => {
            console.log("dataX", dataMultiAccount)
            this.dataWithAllUsedFactsTotalCountry = this.chartService.covertToChartData2(dataMultiAccount, this.fieldsWithoutFactAndValue)
            console.log("dataWithAllUsedFactsTotalCountry", this.dataWithAllUsedFactsTotalCountry)
  
          console.log("this.dataWithAllUsedFactsTotalCountry[0][F6]", this.dataWithAllUsedFactsTotalCountry[0]["F6"])
  
         
          }
        )
      })      
    }
    else{
      console.log("MULTI PRODUCT")
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(['BG1'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      // .select<any[]>(fromDashboard.selectDataWithFilters(this.selectedAccountNodes.map(n => n.Id), this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( dataMultiAccount => {
            console.log("dataX", dataMultiAccount)
            this.dataWithAllUsedFactsTotalCountry = this.chartService.covertToChartData2(dataMultiAccount, this.fieldsWithoutFactAndValue)
            console.log("dataWithAllUsedFactsTotalCountry", this.dataWithAllUsedFactsTotalCountry)
  
          console.log("this.dataWithAllUsedFactsTotalCountry[0][F6]", this.dataWithAllUsedFactsTotalCountry[0]["F6"])
  
         
          }
        )
      })      
    } 
    */
/*
    console.log("data", data)

    console.log("this.selectedAccountNodes.map(n => n.Id)", this.selectedAccountNodes.map(n => n.Id))
    console.log("this.selectedProductNodes.map(n => n.Id)", this.selectedProductNodes.map(n => n.Id))
    console.log("this.allowedChartFactIds", this.allowedChartFactIds)
    console.log("this.getPeriodIds()", this.getPeriodIds())
    console.log("[this.selectedShopperGroupItem.Id]", [this.selectedShopperGroupItem.Id])
    console.log("this.store",  this.store)
    */


    /*
    if(this.treeAccountIsMulti) {
      console.log("MULTI ACCOUNT")

      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(['BG1'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( dataMultiAccount => {
            console.log("dataX", dataMultiAccount)
            this.dataWithAllUsedFactsTotalCountry = this.chartService.covertToChartData2(dataMultiAccount, this.fieldsWithoutFactAndValue)
            console.log("dataWithAllUsedFactsTotalCountry", this.dataWithAllUsedFactsTotalCountry)
  
          console.log("this.dataWithAllUsedFactsTotalCountry[0][F6]", this.dataWithAllUsedFactsTotalCountry[0]["F6"])
  
            if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {    // make sure there is one element selected in benchmark select
              for(let product of productIds) {
                let item = data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.loyalityFactId && i[Columns.Product] === product)[0]     // you don't need to filter on period and shoppergroup, since they are filtered at the first place when calling feedChartWithData at selecting data from the store.
                if(item) {  // make sure the benchmark value exists
                  this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
                  for(let i of this.dataWithAllUsedFacts) {
                    if(i[Columns.Product] === product) {
                      i[this.benchmarkTitle] = this.selectedBenchmarkItem.Values[0]    // add "Benchmark" in ChartData, so that they can be used to display the benchmark line in chart, as well as using them in exporting ppt
                      i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/100         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                      //i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / i[this.resExpFactId]) - (i[this.totalExFactId] * i[this.loyalityFactId] / i[this.resExpFactId]))*100)/100
                      i[MarketShareExpenditureId.marketshare] = Math.round((((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0]) / this.dataWithAllUsedFactsTotalCountry[0]["F6"]) - (i[this.totalExFactId] * i[this.loyalityFactId] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]))*100)/100
                    }
                  }
                } else {
                  // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
                  this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
                }
              }
            }          
          }
        )
      })
  
    }
    else{
      console.log("MULTI PRODUCT")      
      
      this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
        this.store
        .select<any[]>(fromDashboard.selectDataWithFilters(['BG1'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
        .pipe(first(), untilDestroyed(this))
        .subscribe( dataMultiProduct => {
            console.log("dataY", dataMultiProduct)
            this.dataWithAllUsedFactsMultiProducts = this.chartService.covertToChartData2(dataMultiProduct, this.fieldsWithoutFactAndValue)
            console.log("dataWithAllUsedFactsMultiProducts", this.dataWithAllUsedFactsMultiProducts)
  
         // console.log("this.dataWithAllUsedFactsTotalCountry[0][F6]", this.dataWithAllUsedFactsTotalCountry[0]["F6"])
  
            if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {    // make sure there is one element selected in benchmark select

            }          
          }
        )
      })      
    }
*/







/*
    this.isDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectDataWithFilters(['BG1'], this.selectedProductNodes.map(n => n.Id), this.allowedChartFactIds, this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( dataX => {
          console.log("dataX", dataX)
          this.dataWithAllUsedFactsTotalCountry = this.chartService.covertToChartData2(dataX, this.fieldsWithoutFactAndValue)
          console.log("dataWithAllUsedFactsTotalCountry", this.dataWithAllUsedFactsTotalCountry)

        console.log("this.dataWithAllUsedFactsTotalCountry[0][F6]", this.dataWithAllUsedFactsTotalCountry[0]["F6"])

          if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {    // make sure there is one element selected in benchmark select
            if(this.treeAccountIsMulti) {
              for(let product of productIds) {
                let item = data.filter(i => i[Columns.Account] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.loyalityFactId && i[Columns.Product] === product)[0]     // you don't need to filter on period and shoppergroup, since they are filtered at the first place when calling feedChartWithData at selecting data from the store.
                if(item) {  // make sure the benchmark value exists
                  this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
                  for(let i of this.dataWithAllUsedFacts) {
                    if(i[Columns.Product] === product) {
                      i[this.benchmarkTitle] = this.selectedBenchmarkItem.Values[0]    // add "Benchmark" in ChartData, so that they can be used to display the benchmark line in chart, as well as using them in exporting ppt
                      i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/100         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                      //i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / i[this.resExpFactId]) - (i[this.totalExFactId] * i[this.loyalityFactId] / i[this.resExpFactId]))*100)/100
                      i[MarketShareExpenditureId.marketshare] = Math.round((((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0]) / this.dataWithAllUsedFactsTotalCountry[0]["F6"]) - (i[this.totalExFactId] * i[this.loyalityFactId] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]))*100)/100
                    }
                  }
                } else {
                  // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
                  this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
                }
              }
            } else {
              for(let account of accountIds) {
                let item = data.filter(i => i[Columns.Product] === this.selectedBenchmarkItem.Id && i[Columns.Fact] === this.loyalityFactId && i[Columns.Account] === account)[0]
                if(item) {
                  this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [item.Value], "XAxisValues": [-11], "YAxisValues": [-11]}
                  for(let i of this.dataWithAllUsedFacts) {
                    if(i[Columns.Account] === account) {
                      i[this.benchmarkTitle] = this.selectedBenchmarkItem.Values[0]
                      i[MarketShareExpenditureId.expenditure] = Math.round((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] - i[this.totalExFactId] * i[this.loyalityFactId])/1000000 * 100)/100         // this.selectedBenchmarkItem.Values[0] is the value of loyalty Fact
                      //i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / i[this.resExpFactId]) - (i[this.totalExFactId] * i[this.loyalityFactId] / i[this.resExpFactId]))*100)/100
                      i[MarketShareExpenditureId.marketshare] = Math.round(((i[this.totalExFactId] * this.selectedBenchmarkItem.Values[0] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]) - (i[this.totalExFactId] * i[this.loyalityFactId] / this.dataWithAllUsedFactsTotalCountry[0]["F6"]))*100)/100
                    }
                  }
                } else {
                  // if benchmark value not exists, then it is not needed to add "Benchmark" in chart data. so that the benchmark line will not be displayed
                  this.selectedBenchmarkItem = {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": this.cs.NO_BENCHMARK_SELECTED.Values, "XAxisValues": [-11], "YAxisValues": [-11]}
                }
              }
            }
          }          
        }
      )
    })

*/


     //console.log("111 Loyalty-simulation | feedBenchmarkWithData | chartData: ", this.chartData)
  }

  chartMasterNextPage = (event:any) => {
    let singleTreeName = this.treeAccountIsMulti ? Ids.Product : Ids.Account

    let header = this.getPPTHeaderTemplate()
    let catAxisField = Ids.Category
    let dataseriesFields = [this.loyalityFactName, this.benchmarkTitle]
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = true,
    options[PPTIds.LeftValAxisTitle] = this.loyalityFactName,
    options[PPTIds.RightValAxisTitle] = this.selectedMarketShareExpenditureItem.Name,

    this.exportService.createPPT(this.chartMasterPPT, PPTIds.MultiColumnsAndBarChart, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    let header = this.getPPTHeaderTemplate()
    let catAxisField = Ids.Category
    let dataseriesFields = [this.loyalityFactName, this.benchmarkTitle]
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster
    options[PPTIds.MaxValueIsHundred] = true,
    options[PPTIds.LeftValAxisTitle] = this.loyalityFactName,
    options[PPTIds.RightValAxisTitle] = this.selectedMarketShareExpenditureItem.Name,

    // console.log("ppt: ", header, catAxisField, dataseriesFields, options, this.filterChartFactData, this.chartData)

    this.exportService.exportPPT(PPTIds.MultiColumnsAndBarChart, this.dashboardTitle,this.selectedAccountNodes.map(n => n.Name), this.selectedProductNodes.map(n => n.Name), header, options, singleTreeName, catAxisField, dataseriesFields, this.chartData)
                      .then(fileName => {
                          console.log(`Created Single PPT file: ${fileName}`);
                          this.isCreatingExport = false
                      });
  }

  private getPPTHeaderTemplate() {
    let pptHeaderTemplate: any = {}
    pptHeaderTemplate[this.cs.ACCOUNTS] = Ids.Accounts_Placeholder
    pptHeaderTemplate[this.cs.PRODUCTS] = Ids.Products_Placeholder
    pptHeaderTemplate[this.cs.PERIOD] = this.selectedPeriod1Item.Name
    pptHeaderTemplate[this.cs.BENCHMARK] = this.selectedBenchmarkItem.Name
    pptHeaderTemplate[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    return pptHeaderTemplate
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    this.restService.filterData (
      this.sourceTable,
      accounts.map(i => i.Id),
      products.map(i => i.Id),
      this.getPeriodIds(),
      this.allowedChartFactIds
    )
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
      // console.log("Loyalty-simulation | multi ppt | data: ", data)

      this.utilityService.preprocessData({addMissingData: false, sortDataByValue: false, sortDataBySelectionOrder:true, treeProductIsMulti: this.treeProductIsMulti, data: data, selectedAccounts: this.selectedAccountIds, selectedProducts: this.selectedProductIds, selectedFacts: [this.loyalityFactId], selectedPeriods: this.getPeriodIds(), selectedShopperGroups: [this.selectedShopperGroupItem.Id]})
      // console.log("Loyalty-simulation | multi ppt | preprocessed data: ", data)


      this.dataWithAllUsedFacts = this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue)
      // console.log("Loyalty-simulation | multi ppt | this.dataWithAllUsedFacts ", this.chartService.covertToChartData2(data, this.fieldsWithoutFactAndValue))

      // add benchmark value in dataWithAllUsedFacts
      this.feedBenchmarkWithData(data, accounts.map(i => i.Id), products.map(i => i.Id),)
      // console.log("this.dataWithAllUsedFacts with expenditure", this.dataWithAllUsedFacts)

      let multiPptData = this.utilityService.replaceIdWithName3(this.dataWithAllUsedFacts,
      Columns.Account, Columns.Product, Columns.Period,
      this.dictAccountDataWithIdName, this.dictProductDataWithIdName, this.filterPeriod1Data,
      [this.loyalityFactId], this.filterChartFactData, [this.benchmarkTitle, MarketShareExpenditureId.expenditure, MarketShareExpenditureId.marketshare])

      // console.log("Loyalty-simulation | multi ppt | chart data: ", multiPptData)

      // add data labels in change chart
      for (const item of multiPptData) {
        if(this.treeAccountIsMulti) {
          item[Ids.Category] = item[Columns.Account]
        } else {
          item[Ids.Category] = item[Columns.Product]
        }

        // used for the right chart
        item[Ids.value] = this.selectedMarketShareExpenditureItem.Id === MarketShareExpenditureId.expenditure ? item[MarketShareExpenditureId.expenditure] : item[MarketShareExpenditureId.marketshare]
        item[Ids.Color] = item[Ids.value] >= 0 ? this.configService.COLOR_GREEN.replace("#", "") : this.configService.COLOR_RED.replace("#", "")
      }

      let header = this.getPPTHeaderTemplate()
      let catAxisField = Ids.Category
      let dataseriesFields = [this.loyalityFactName, this.benchmarkTitle]
      let options: any = {}
      options[PPTIds.master] = this.userDefinedPptMaster
      options[PPTIds.MaxValueIsHundred] = true,
      options[PPTIds.LeftValAxisTitle] = this.loyalityFactName,
      options[PPTIds.RightValAxisTitle] = this.selectedMarketShareExpenditureItem.Name,

      // console.log("ppt: ", header, catAxisField, dataseriesFields, options, this.filterChartFactData)

      this.exportService.exportPPT(PPTIds.MultiColumnsAndBarChart, this.dashboardTitle, accounts.map(i=>i.Name), products.map(i=>i.Name), header, options, singleTreeName, catAxisField, dataseriesFields, multiPptData)
                        .then(fileName => {
                            console.log(`Created Single PPT file: ${fileName}`);
                            this.isCreatingExport = false
                        });
    })
  }

  handleExportExcel() {
    let header:any = {}
    header[this.cs.ACCOUNTS] = this.selectedAccountNodes.map(n => n.Name).join(", ")
    header[this.cs.PRODUCTS] = this.selectedProductNodes.map(n => n.Name).join(", ")
    header[this.cs.PERIOD] = this.getPeriodNames().join(", ")
    header[this.benchmarkTitle] = this.selectedBenchmarkItem.Name
    header[this.cs.SHOPPER_GROUP] = this.selectedShopperGroupItem.Name

    let columnValueMap: any = {}
    if(this.treeAccountIsMulti) {
      columnValueMap[this.cs.ACCOUNT] = Columns.Account
      columnValueMap[this.cs.FACT_OBJECT["F10"]] = this.loyalityFactName
      columnValueMap[this.cs.BENCHMARK] = this.benchmarkTitle
      columnValueMap[this.cs.MARKETSHARE_EXPENDITURE_OBJECT[MarketShareExpenditureId.expenditure]] = MarketShareExpenditureId.expenditure
      columnValueMap[this.cs.MARKETSHARE_EXPENDITURE_OBJECT[MarketShareExpenditureId.marketshare]] = MarketShareExpenditureId.marketshare
    } else {
      // key is the name of the column, value is the key in the data, whose value should be written in the column
      columnValueMap[this.cs.PRODUCT] = Columns.Product
      columnValueMap[this.cs.FACT_OBJECT["F10"]] = this.loyalityFactName
      columnValueMap[this.cs.BENCHMARK] = this.benchmarkTitle
      columnValueMap[this.cs.MARKETSHARE_EXPENDITURE_OBJECT[MarketShareExpenditureId.expenditure]] = MarketShareExpenditureId.expenditure
      columnValueMap[this.cs.MARKETSHARE_EXPENDITURE_OBJECT[MarketShareExpenditureId.marketshare]] = MarketShareExpenditureId.marketshare
    }

    this.exportService.exportExcel("Loyalty_Simulation", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.chartData, header, columnValueMap)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  public assigningCategoryStyle(e: any){
    let args = e.args;
    let item = args.getItems(args.startIndex, args.endIndex)[0];

    if(item[this.selectedMarketShareExpenditureItem.Id] < 0){
      args.fill = this.configService.COLOR_RED;
      args.stroke = this.configService.COLOR_RED;
    }
    else if(item[this.selectedMarketShareExpenditureItem.Id] == 0){
      args.fill = this.configService.COLOR_GREY;
      args.stroke = this.configService.COLOR_GREY;
    }
    else{
      args.fill = this.configService.COLOR_GREEN;
      args.stroke = this.configService.COLOR_GREEN;
    }
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show

    // the datalabels of the left chart
    if(this.factSeries !== undefined) { this.factSeries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.benchmarkSeries !== undefined) { this.benchmarkSeries.markerTemplate = this.utilityService.getMarker(this.configService, false); }
  }

  handlePenetrationWarning() {
    if(this.factSeries !== undefined) { this.factSeries.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_SingleSeries, !this.hidePenetrationWarning); }
    if(this.benchmarkSeries !== undefined) { this.benchmarkSeries.markerTemplate = this.utilityService.getMarker(this.configService, false); }
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes
    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Mode] = this.selectedModeItem
    selection[UserSelectionIds.Benchmark] =  {"Id": this.selectedBenchmarkItem.Id, "Name": this.selectedBenchmarkItem.Name, "Values": [-22]} // do not save the values
    selection[UserSelectionIds.ShopperGroup] = this.selectedShopperGroupItem
    selection[UserSelectionIds.Selection] = this.selectedMarketShareExpenditureItem
    selection[UserSelectionIds.Legend] = this.showLegend
    selection[UserSelectionIds.DataLabel] = this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }


  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
      return
    }

    this.loyalityFactName = this.filterChartFactData.filter(i => i.Id === this.loyalityFactId)[0].Name

    // Switch (Mode)
    this.selectedModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Mode, this.filterModeData[0])
    switch (this.selectedModeItem.Id) {
      case ModeIds.OneAccountMultiProducts:
        this.treeProductIsMulti = true
        this.callBackendToGetProductAndAllAccounts = false

        this.treeAccountIsMulti = false
        this.callBackendToGetAccountAndAllProducts = true
        break

      case ModeIds.OneProductMultiAccounts:
        this.treeProductIsMulti = false
        this.callBackendToGetProductAndAllAccounts = true

        this.treeAccountIsMulti = true
        this.callBackendToGetAccountAndAllProducts = false
        break
    }

    // Account Tree
    this.selectedAccountNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];

    // Period (Period 1)
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Fact --- selectedChartFactItem is used in html, so have to set its value
    this.selectedChartFactItem = {
        Id: this.filterChartFactData[0].Id,
        Name: this.filterChartFactData[0].Name
    }

    // Benchmark
    this.selectedBenchmarkItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Benchmark, this.cs.NO_BENCHMARK_SELECTED)
    // if it is not this.cs.NO_BENCHMARK_SELECTED, then we have to check if it is selected in the tree when initializing
    if(this.selectedBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeProductIsMulti) {
        if(!this.selectedProductIds.includes(this.selectedBenchmarkItem.Id)) {
          this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        if(!this.selectedAccountIds.includes(this.selectedBenchmarkItem.Id)) {
          this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      }
    } else {
      this.selectedBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
    }
    // console.log("this.selectedBenchmarkItem: ", this.selectedBenchmarkItem, this.cs.NO_BENCHMARK_SELECTED)

    // ShopperGroup
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })

    // Selection
    this.selectedMarketShareExpenditureItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Selection, this.selectMarketShareExpenditureData[0])
    // have to update it according to the User Language
    this.selectedMarketShareExpenditureItem = {...this.selectedMarketShareExpenditureItem, Name: this.cs.MARKETSHARE_EXPENDITURE_OBJECT[this.selectedMarketShareExpenditureItem.Id]}
    // console.log("this.selectedMarketShareExpenditureItem: ", this.selectedMarketShareExpenditureItem)

    // Chart Setting
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)
  }
}
