import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardSelectionComponent } from './standard-selection/standard-selection.component';
import { SettingsRoutingModule } from './settings.routing.module';
import { ColorsComponent } from './colors/colors.component';
import { PresentationComponent } from './presentation/presentation.component';
import { ProjectComponent } from './project/project.component';
import { SharedDashboardComponentModule } from '../shared/ui/shared-dashboard-component.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccountComponent } from './account/account.component';
import { ColorPickerModule } from 'ngx-color-picker';


@NgModule({
  declarations: [
    StandardSelectionComponent,
    ColorsComponent,
    PresentationComponent,
    ProjectComponent,
    AccountComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedDashboardComponentModule,
    DragDropModule,
    ColorPickerModule,
  ]
})
export class SettingsModule { }
