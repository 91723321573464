<div class="outer">
  <div class="inner_fixed">
    <app-report-bar></app-report-bar>
  </div>

  <div class="inner_remaining">
    <router-outlet></router-outlet>
  </div>
</div>


