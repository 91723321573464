import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketAnalysisComponent } from './basket-analysis/basket-analysis.component';
import { BasketRoutingModule } from './basket.routing.module';
import { IgxExcelExporterService } from '@infragistics/igniteui-angular';
import { SharedDashboardComponentModule } from '../../shared/ui/shared-dashboard-component.module';

@NgModule({
  declarations: [
    BasketAnalysisComponent
  ],
  providers: [IgxExcelExporterService],
  imports: [
    CommonModule,
    BasketRoutingModule,
    SharedDashboardComponentModule,
  ]
})
export class BasketModule { }
