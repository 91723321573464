<section class="container_content">
  <div class="leftmenu">
    <app-filter-tabs
    [getSelectedNodes]="getSelectedNodes" [getSelectedPeriodItem]="getSelectedPeriodItem"

    [tabAccountTitle]="tabAccountTitle"
    [filterAccountTitle]="filterAccountTitle" [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [selectedAccountNodeLevels]="selectedAccountNodeLevels" [selectedAccountNodes] = "selectedAccountNodes"

    [tabProductTitle]="tabProductTitle"
    [filterProductTitle]="filterProductTitle" [treeProductIsMulti]="treeProductIsMulti" [filterProductData]="filterProductData" [selectedProductNodeLevels]="selectedProductNodeLevels" [selectedProductNodes] = "selectedProductNodes"

    [tabPeriodFactTitle]="tabPeriodFactTitle"
    [showOneSinglePeriodFilter]="true" [filterPeriod1Title]="filterPeriod1Title" [filterPeriod1Data]="filterPeriod1Data" [selectedPeriod1Item]="selectedPeriod1Item"
    [showMultiFactsCheckboxFilter]="true" [selectMultiFactsTitle]="filterChartFactsTitle" [selectMultiFactsData]="filterChartFactsData" [selectedMultiFactItemIds]="selectedChartFactItemIds" [getSelectedMultiFacts]="getSelectedChartFacts"

    [tabSelectionTitle]="tabSelectionTitle"
    [showFilterShopperGroup]="true" [filterShopperGroupTitle]="filterShopperGroupTitle" [filterShopperGroupData]="filterShopperGroupData" [selectedShopperGroupItem]="selectedShopperGroupItem" [getSelectedShopperGroupItem]="getSelectedShopperGroupItem"
    [showFilterMode]="true" [filterModeTitle]="filterModeTitle" [filterModeData]="filterModeData" [selectedModeItem]="selectedModeItem" [getSelectedFilterModeItem]="getSelectedMode"
    [showCheckboxLegend]="true" [showLegend] = "showLegend" (showLegendEvent)="displayLegend($event)"
    [showCheckboxDataLabel]="true" [showDataLabel] = "showDataLabel" (showDataLabelEvent)="displayDataLabel($event)"
    [showExportExcel]="showExportExcel" [showExportPPT]="showExportPPT" [showExportMultiPPT]="showExportPPT" (getSelectedExportEvent)="getSelectedExportEvent($event)"

    [isDataLoading$]="isDataLoading$"
    ></app-filter-tabs>
  </div>

  <div class="main">
    <app-chart-master-navigation
      [chartMasterActive]="chartMasterActive" [chartMasterCurrentElement] = "chartMasterCurrentElement" [chartMasterTotalElements] = "chartMasterTotalElements"
      (chartMasterNextPageEvent)="chartMasterNextPage($event)" (cancelChartMasterEvent)="cancelChartMaster($event)"
    ></app-chart-master-navigation>

    <div class="info-bar">
      <app-chip-bar-table
      [chipAccountTitle]="filterAccountTitle"            [selectedAccountNodes]="selectedAccountNodes"
      [chipProductTitle]="filterProductTitle"            [selectedProductNodes]="selectedProductNodes"
      [chipPeriod1Title]="filterPeriod1Title"            [selectedPeriod1ItemNames]="selectedPeriod1ItemNames"
      [chipPeriod2Title]="filterPeriod2Title"            [selectedPeriod2ItemNames]="selectedPeriod2ItemNames"
      [chipShopperGroupTitle]="filterShopperGroupTitle"  [selectedShopperGroupItem]="selectedShopperGroupItem"
      [showFilterPeriod2]="hasPeriod2Filter" [showInfoAccount]="!treeAccountIsMulti" [showInfoProduct]="!treeProductIsMulti"
      ></app-chip-bar-table>
    </div>

    <div class="main-dashboard">
      <app-penetration-warning [hidden]="!hasPenetrationWarning"
      [isChecked]="hidePenetrationWarning" (getSwitchPenetrationWarningEvent)="getSwitchPenetrationWarningEvent($event)"
      ></app-penetration-warning>

      <ng-template let-series="series" let-item="item" #valueTooltip1>
        <div>{{item[ids.Category]}}</div>
        <div><font [color]="chartColors[0]">{{item[ids.Series1]}}:</font> {{item[ids.Label1]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip2>
        <div>{{item[ids.Category]}}</div>
        <div><font [color]="chartColors[1]">{{item[ids.Series2]}}:</font> {{item[ids.Label2]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip3>
        <div>{{item[ids.Category]}}</div>
        <div><font [color]="chartColors[2]">{{item[ids.Series3]}}:</font> {{item[ids.Label3]}}</div>
      </ng-template>

      <ng-template let-series="series" let-item="item" #valueTooltip4>
        <div>{{item[ids.Category]}}</div>
        <div><font [color]="chartColors[3]">{{item[ids.Series4]}}:</font> {{item[ids.Label4]}}</div>
      </ng-template>

      <igx-data-chart #chart
        width="900px"
        height="600px"
        [legend]="legend"
        isHorizontalZoomEnabled="false"
        isVerticalZoomEnabled="false"
        isSeriesHighlightingEnabled="true"
        highlightingMode="FadeOthersSpecific"
        highlightingBehavior="NearestItemsAndSeries"
        crosshairsDisplayMode="None"
        [dataSource]="chartData">

        <igx-category-x-axis
          #xAxis
          [majorStroke]="configService.STROKE_COLOR_CATEGORY"
          majorStrokeThickness="1"
          [label]="modeCategory">
        </igx-category-x-axis>

        <igx-numeric-y-axis
          #yAxis
          minimumValue = "0"
          majorStrokeThickness="1"
          [majorStroke]="configService.STROKE_COLOR_VALUE"
          abbreviateLargeNumbers="true"
          [title]="''"
          titleLeftMargin="5"
          titleRightMargin="0">
        </igx-numeric-y-axis>

        <!-- the first column series should be always there, so that the marker can be set by initializing-->
        <igx-column-series #fact1Series [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
        [valueMemberPath]="selectedChartFactItemNames[0]"
        showDefaultTooltip="false" [tooltipTemplate]="valueTooltip1"
        isTransitionedInEnabled="true" isHighlightingEnabled="true"
        [title]="selectedChartFactItemNames[0]" [brush]="chartColors[0]" outline="Transparent" [markerBrush]="chartColors[0]">
        </igx-column-series>

        <ng-container *ngIf="selectedChartFactItemNames[1]">
          <igx-column-series #fact2Series [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
          [valueMemberPath]="selectedChartFactItemNames[1]"
          showDefaultTooltip="false" [tooltipTemplate]="valueTooltip2"
          isTransitionedInEnabled="true" isHighlightingEnabled="true"
          [title]="selectedChartFactItemNames[1]" [brush]="chartColors[1]" outline="Transparent" [markerBrush]="chartColors[1]">
          </igx-column-series>
        </ng-container>

        <ng-container *ngIf="selectedChartFactItemNames[2]">
          <igx-column-series #fact3Series [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
          [valueMemberPath]="selectedChartFactItemNames[2]"
          showDefaultTooltip="false" [tooltipTemplate]="valueTooltip3"
          isTransitionedInEnabled="true" isHighlightingEnabled="true"
          [title]="selectedChartFactItemNames[2]" [brush]="chartColors[2]" outline="Transparent" [markerBrush]="chartColors[2]">
          </igx-column-series>
        </ng-container>

        <ng-container *ngIf="selectedChartFactItemNames[3]">
          <igx-column-series #fact4Series [xAxis]="xAxis" [yAxis]="yAxis" [dataSource]="chartData"
          [valueMemberPath]="selectedChartFactItemNames[3]"
          showDefaultTooltip="false" [tooltipTemplate]="valueTooltip4"
          isTransitionedInEnabled="true" isHighlightingEnabled="true"
          [title]="selectedChartFactItemNames[3]" [brush]="chartColors[3]" outline="Transparent" [markerBrush]="chartColors[3]">
          </igx-column-series>
        </ng-container>

        <ng-container *ngIf="showDataLabel">
          <igx-callout-layer
          [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="fact1Series"
          [labelMemberPath]="ids.Label1" [xMemberPath]="ids.index" [yMemberPath]="ids.value1"
          calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
          </igx-callout-layer>

          <igx-callout-layer
          [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="fact2Series"
          [labelMemberPath]="ids.Label2" [xMemberPath]="ids.index" [yMemberPath]="ids.value2"
          calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
          </igx-callout-layer>

          <igx-callout-layer
          [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="fact3Series"
          [labelMemberPath]="ids.Label3" [xMemberPath]="ids.index" [yMemberPath]="ids.value3"
          calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
          </igx-callout-layer>

          <igx-callout-layer
          [dataSource]="chartData" isAutoCalloutBehaviorEnabled="false" [targetSeries]="fact4Series"
          [labelMemberPath]="ids.Label4" [xMemberPath]="ids.index" [yMemberPath]="ids.value4"
          calloutLeaderBrush="Transparent" [calloutTextColor]="configService.CALLOUT_TEXT_COLOR" calloutBackground = "Transparent">
          </igx-callout-layer>
        </ng-container>
      </igx-data-chart>

      <div class="legend" [hidden]="!showLegend">
        <igx-legend #legend orientation="Horizontal" [textColor]="configService.LEGEND_TEXT_COLOR"></igx-legend>
      </div>

    </div>
  </div>

  <app-export-multi-ppt-dialog
    [treeAccountIsMulti]="treeAccountIsMulti" [filterAccountData]="filterAccountData" [treeMultiAccountName]="treeMultiAccountName"
    [filterProductData]="filterProductData" [treeMultiProductName]="treeMultiProductName"
    [getMultiSelectedNodes]="getMultiSelectedNodes" [exportMultiPPT]="exportMultiPPT"
  ></app-export-multi-ppt-dialog>

  <div class="loading" *ngIf="(isDataLoading$ | async) === true || isCreatingExport === true"></div>
</section>
