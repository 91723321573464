import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PricePerformanceComponent } from './price-performance/price-performance.component';
import { PromotionPerformanceComponent } from './promotion-performance/promotion-performance.component';
import { AssortmentPerformanceComponent } from './assortment-performance/assortment-performance.component';
import { PageNotFoundComponent } from 'src/app/error-routing/not-found/not-found.component';
import { LayoutComponent } from '../../layout/layout.component';
import { DashboardId } from '../../shared/model/constants';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: DashboardId.price_performance, pathMatch: 'full' },
      { path: DashboardId.price_performance, component: PricePerformanceComponent },
      { path: DashboardId.promotion_performance, component: PromotionPerformanceComponent },
      { path: DashboardId.assortment_performance, component: AssortmentPerformanceComponent },
      { path: '**', component: PageNotFoundComponent } // must always be last
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TacticalRoutingModule { }
