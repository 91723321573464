<div class="flex flex-row justify-center items-center flex-wrap reportbar">

  <ng-container *ngFor="let db of dashboards">
    <ng-container *ngIf="chartMasterActive; else link">
      <a
      igxButton="raised"
      igxRipple
      [class]="isSelected(db.Id) ? 'dbSelected' : 'dbUnselected'"
      >
        <span>{{db.Name}}</span>
      </a>
    </ng-container>

    <ng-template #link>
      <a
      igxButton="raised"
      igxRipple
      [routerLink]="'../'+ module + '/' + db.Id"
      routerLinkActive="active"
      #rla="routerLinkActive"
      [class]="rla.isActive ? 'dbSelected' : 'dbUnselected'"
      >
        <span>{{db.Name}}</span>
      </a>
    </ng-template>

  </ng-container>
</div>
