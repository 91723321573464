import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { text: 'login' } },
  { path: 'forget-password', component: ForgetPasswordComponent, data: { text: 'forget-password' } },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { text: 'reset-password' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
