export class User {
  public get firstname(): string {
    return this._firstname;
  }

  public get lastname(): string {
    return this._lastname;
  }

  public get email(): string {
    return this._email;
  }

  public get role(): string {
    return this._role;
  }

  public get databases_id(): number {
    return this._databases_id;
  }

  public get language(): string {
    return this._language
  }

  public get chart_color_first(): string {
    return this._chart_color_first
  }

  public get chart_color_second(): string {
    return this._chart_color_second
  }

  constructor(
    private _email: string,
    private _firstname: string,
    private _lastname: string,
    private _role: string,
    private _databases_id: number,
    private _language: string,
    private _chart_color_first: string,
    private _chart_color_second: string,
  ) {}
}

export interface AuthResponseData {
  role: string,
  email: string,
  firstname: string,
  lastname: string,
  authToken : string,
  databases_id: number,
  language: string,
  chart_color_first: string,
  chart_color_second: string,
}

export interface ForgetPasswordResponse {
  code: string,
  message: string,
  payload: string,
}

export interface MagicLoginResponse {
  authToken: string,
  message: string,
}

export interface ResetPasswordResponse {
  message: string,
}


