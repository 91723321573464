import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModeIds, SortingIds, SwitchId, ChangeRateIds, MarketShareExpenditureId, ClientBuyerIds, BasisId, DemographicSwitchId, BarStyleId, MetricId, ChangeId, FactTypeId, MatModeId, DashboardId } from '../model/constants';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  public HEADER_ITEMS: any[] = [];
  public PROPENSITY_DASHBOARDS: any[]  = []
  public MARKET_SHARE_DASHBOARDS: any[]  = []
  public REST_REQUIREMENT_DASHBOARDS: any[] = []
  public TACTICAL_DASHBOARDS: any[] = []
  public DEMOGRAPHIC_DASHBOARDS: any[] = []
  public BASKET_DASHBOARDS: any[] = []
  public REPORTS_DASHBOARDS: any[]  = []
  public SETTING_PAGES: any[] = []
  public CHART_MASTER_DASHBOARDS: any[] = [];

  constructor(public translate:TranslateService) {
    // you don't have to run the updateTranslation here
    this.updateTranslationg()
  }

  public PROPENSITY = ""
  public MARKET_SHARE = ""
  public REST_REQUIREMENT = ""
  public TACTICAL = ""
  public DEMOGRAPHIC = ""
  public BASKET = ""
  public REPORTS = ""

  public ACCOUNT = ""
  public ACCOUNTS = ""
  public ACCOUNT_FOCUS = ""
  public ACCOUNT_COMPETITOR = ""
  public ACCOUNT_CLIENT_ACCOUNT_BUYER = ""

  public PRODUCT = ""
  public PRODUCTS = ""

  public SELECT_ALL = ""
  public SELECT_ALL_IN_HIERARCHY = ""
  public SELECT_ALL_IN_HIERARCHY_WARNING = ""

  public PERIOD_AND_FACT = ""
  public PERIOD = ""
  public PERIODS = ""
  public PERIOD1 = ""
  public PERIOD2 = ""
  public CURRENT_PERIOD = ""
  public PERVIOUS_PERIOD = ""

  public FACT = ""
  public FACTS = ""
  public FACT_CHART = ""
  public FACT_TABLE = ""

  public SELECTION = ""

  public BENCHMARK=  ""
  public BENCHMARK_FOR_PRODUCT =  ""
  public BENCHMARK_FOR_ACCOUNT =  ""
  public NO_BENCHMARK_SELECTED: any =  {}

  public SHOPPER_GROUP = ""

  public AVAILABLE_DASHBOARDS = ""
  public SELECTED_DASHBOARDS = ""

  public CREATE_PPT = ""
  public SAVE_AND_LOGOUT = ""
  public SAVE = ""

  public LANGUAGE = ""
  public DATABASE = ""

  public FOCUS = ""
  public COMPETITOR = ""

  public EXPORT = ""
  public EXCEL = ""
  public SINGLE_PPT = ""
  public MULTI_PPT = ""
  public EXPORT_MULTI_PPT = ""
  public EXPORT_EXCEL_WARNING = ""

  public CHART_SORTING = ""
  public SORTING_OBJECT: any = {}
  public SELECT_CHART_SORTING_DATA: any[] = []

  public LOYALTY = ""

  // Facts
  public FACT_OBJECT: any = {}
  public FACT_DIM: any[] = []

  public MODE = ""
  public SELECT_FILTER_MODE_DATA: any[] = []

  public CHART_SETTING = ""
  public CHART_SETTING_SAVE_VALUES = ""
  public LEGEND = ""
  public DATA_LABEL = ""

  public SLIDE = ""
  public NEXT = ""
  public CANCEL = ""

  public PENETRATION_WARNING = ""
  public PENETRATION_WARNING_TOOLTIP = ""
  public HIDE_PENETRATION_WARNING = ""

  public SWITCH = ""    // same content with MODE at the moment
  public BASIS = ""

  public SWITCH_OBJECT: any = {}
  public SELECT_SWITCH_DATA: any[] = []

  public BUYERS_TEXT_0 = ""
  public BUYERS_EXCEL_1 = ""
  public BUYERS_EXCEL_2 = ""
  public BUYERS_EXCEL_3 = ""
  public BUYERS_HTML_1 = ""
  public BUYERS_HTML_2 = ""
  public BUYERS_HTML_3 = ""
  public BUYERS_TEXT_4 = ""
  public BUYERS_TEXT_5 = ""
  public BUYERS_TEXT_6 = ""
  public BUYERS_TEXT_7 = ""

  public EXPENDITURES_EXCEL_1 = ""
  public EXPENDITURES_EXCEL_2 = ""
  public EXPENDITURES_EXCEL_3 = ""
  public EXPENDITURES_HTML_1 = ""
  public EXPENDITURES_HTML_2 = ""
  public EXPENDITURES_HTML_3 = ""
  public EXPENDITURES_TEXT_4 = ""
  public EXPENDITURES_TEXT_5 = ""
  public EXPENDITURES_TEXT_6 = ""
  public EXPENDITURES_TEXT_7 = ""

  public EXPENDITURES_INCL_RR_EXCEL_1 = ""
  public EXPENDITURES_INCL_RR_EXCEL_2 = ""
  public EXPENDITURES_INCL_RR_EXCEL_3 = ""
  public EXPENDITURES_INCL_RR_EXCEL_4 = ""
  public EXPENDITURES_INCL_RR_HTML_1 = ""
  public EXPENDITURES_INCL_RR_HTML_2 = ""
  public EXPENDITURES_INCL_RR_HTML_3 = ""
  public EXPENDITURES_INCL_RR_HTML_4 = ""
  public EXPENDITURES_INCL_RR_TEXT_5 = ""
  public EXPENDITURES_INCL_RR_TEXT_6 = ""
  public EXPENDITURES_INCL_RR_TEXT_7 = ""
  public EXPENDITURES_INCL_RR_TEXT_8 = ""
  public EXPENDITURES_INCL_RR_TEXT_9 = ""

  public PERIOD2_NOT_SELECTED: any = {}

  public DESCRIPTION = ""
  public VALUE = ""

  public ALL_HOUSEHOLDS = ""
  public SHOPPER_VS_NON_SHOPPER = ""
  public SHOPPER = ""
  public NON_SHOPPER = ""
  public PRODUCT_BUYER = ""
  public NON_PRODUCT_BUYER = ""
  public SHOPPER_PRODUCT_BUYER = ""
  public IN_SHOP = ""
  public ELSEWHERE = ""
  public BUYER_GROUP = ""
  public BUYING_HOUSEHOLDS_M = ""
  public SHAER_IN_GROUP = ""

  public MARKET_SHARE_FACT = ""
  public CHANGE_RATE = ""
  public CHANGE_RATE_OBJECT: any = {}
  public SELECT_CHANGE_RATE_DATE: any[] = []

  public SELECT = ""
  public MARKETSHARE_EXPENDITURE_OBJECT: any = {}
  public SELECT_MARKETSHARE_EXPENDITURE_DATA: any[] = []

  public DECOMPOSITION_TREE = ""
  public VALUE_CHANGE = ""
  public VALUE_CHANGE_AMOUNT = ""

  public LOST_EXPENDITURE_IN_PERCENT = ""
  public LOST_EXPENDITURE_INDEX = ""
  public EXPENDITURE_FOR = ""
  public FACT_RR_OBJECT: any = {}
  public SELECT_FACT_RR_DATA: any[] = []
  public SHOW_RR_INDEX = ""

  public DEMAND_COVERAGE = ""
  public LOST_EXPENSES = ""
  public LOST_EXPENSES_TOTAL = ""

  public Umsatzpotenzialausschöpfung = ""
  public VerloreneAusgaben = ""
  public Nebenausgaben = ""

  public TABLE = ""

  public FROM = ""
  public TO = ""
  public NET_BALANCE = ""

  public BASIS_OBJECT: any =  {}
  public SELECT_BASIS_DATA: any[] =  []

  public DEMOGRAPHIC_CHART_SWITCH_OBJECT: any = {}
  public SELECT_DEMOGRAPHIC_CHART_SWITCH_DATA: any[] = []
  public DEMOGRAPHIC_GROUP = ""
  public HOUSEHOLD_PERIOD1 = ""
  public STYLE = ""
  public BAR_STYLE_OBJECT: any = {}
  public SELECT_BAR_STYLE_DATA: any[] = []
  public CHART = ""
  public PRODUCT_ACCOUNT = ""

  public TABLE_DEMOGRAPHICS_FACTS: any[] = []

  public METRICS = ""
  public METRICS_OBJECT: any = {}
  public SELECT_METRICS_DATA: any[] = []
  public CHANGE = ""
  public CHANGE_OBJECT: any = {}
  public SELECT_CHANGE_DATA: any[] = []
  public TABLE_TYPE = ""
  public PIVOT1 = ""
  public PIVOT2 = ""
  public PIVOT_SETTING = ""
  public UPDATE_PIVOT = ""
  public BUBBLE_SIZES = ""
  public PROPENSITY_LOYALTY = ""

  public FACT_TYPE_OBJECT: any = {}
  public SELECT_FACT_TYPE_DATA: any[] = []
  public BENCHMARK_WARNING = ""

  public DATA_TABLE_FACTS: any[] = []
  public DATA_KPI_COMPARISON: any[] = []
  public DATA_TABLE_FACTS_LIST: any[] = []

  public ROW = ""
  public COLUMN = ""
  public MAT_MODE = ""
  public MAT_MODE_ITEMS: any[] = []
  public COLUMN_NUMBER = ""
  public VALUE_CURRENT = ""
  public VALUE_PREVIOUS = ""
  public VALUE_DIFF_ABSOLUTE = ""
  public VALUE_DIFF_PERCENT = ""

  public NO_BUBBLE_SIZE_FACT: any = {}
  public CONTRIBUTION_TO_CHANGE = ""

  public EXPENDITURES_ACCOUNT_BUYERS_M = ""
  public UNITS_M = ""
  public AVERAGE_PRICE_UNIT = ""
  public UNITS_ACCOUNT_BUYER = ""
  public NUMBER_ACCOUNT_BUYERS_M = ""
  public PURCHASE_FREQUENCY = ""
  public UNITS_OCCASION = ""
  public PENETRATION_ACCOUNT_BUYER = ""
  public NUMBER_HOUSEHOLDS_TOTAL_MARKET_M = ""
  public NUMBER_HOUSEHOLDS = ""

  public DATABASE_LANGUANGE = ""
  public PERSONAL_DATA = ""
  public PROJECT = ""

  public FIRSTNAME = ""
  public LASTNAME = ""

  public CHANGE_SAVED = ""
  public CHANGE_NOT_SAVED = ""

  public BASIC = ""
  public FIRST_DEFAULT_COLOR = ""
  public SECOND_DEFAULT_COLOR = ""

  public CHART_MASTER = ""
  public POWERPOINT = ""
  public SELECT_CHART_MASTER = ""
  public CREATE_CHART_MASTER = ""
  public CHART_MASTER_ADDED = ""
  public CHART_MASTER_EXIST = ""
  public CHART_MASTER_SAVED = ""
  public CHART_MASTER_ADD_TIP = ""
  public DELETE_CHART_MASTER = ""
  public DELETE_CHART_MASTER_CONFIRMATION = ""

  public POWERPOINT_SETTING = ""
  public CREATE_PPT_TEMPLATE = ""

  public updateTranslationg(): void {
    this.HEADER_ITEMS = [
      { link: '/dashboard/propensity/', label: this.translate.instant('HEADER.PROPENSITY') },
      { link: '/dashboard/market-share/', label: this.translate.instant('HEADER.MARKET_SHARE') },
      { link: '/dashboard/rest-requirement/', label: this.translate.instant('HEADER.REST_REQUIREMENT') },
      { link: '/dashboard/tactical/', label: this.translate.instant('HEADER.TACTICAL') },
      { link: '/dashboard/demographic/', label: this.translate.instant('HEADER.DEMOGRAPHIC') },
      { link: '/dashboard/basket/', label: this.translate.instant('HEADER.BASKET') },
      { link: '/dashboard/reports/', label: this.translate.instant('HEADER.REPORTS') },
    ];

    this.PROPENSITY_DASHBOARDS  = [
      { Id: DashboardId.definition_of_key_facts, Name: this.translate.instant('DASHBOARD.DEFINITION_OF_KEY_FACTS') },
      { Id: DashboardId.analysis_of_strengths_and_weaknesses, Name: this.translate.instant('DASHBOARD.ANALYSIS_OF_STRENGTHS_AND_WEAKNESSES') },
      { Id: DashboardId.purchase_behavior, Name: this.translate.instant('DASHBOARD.PURCHASE_BEHAVIOR') },
      { Id: DashboardId.loyalty_propensity_ranking, Name: this.translate.instant('DASHBOARD.LOYALTY_PROPENSITY_RANKING') },
      { Id: DashboardId.structure_of_account_clients, Name: this.translate.instant('DASHBOARD.STRUCTURE_OF_ACCOUNT_CLIENTS') },
    ]

    this.MARKET_SHARE_DASHBOARDS  = [
      // { Id: DashboardId.market_shares, Name: "Market Shares" },  //there should be no market-shares
      { Id: DashboardId.market_share_development, Name: this.translate.instant('DASHBOARD.MARKET_SHARE_DEVELOPMENT') },
      { Id: DashboardId.market_share_components, Name: this.translate.instant('DASHBOARD.MARKET_SHARE_COMPONENTS') },
      { Id: DashboardId.loyalty_simulation, Name: this.translate.instant('DASHBOARD.LOYALTY_SIMULATION') },
      { Id: DashboardId.decomposition_tree, Name: this.translate.instant('DASHBOARD.DECOMPOSITION_TREE') },
    ]

    this.REST_REQUIREMENT_DASHBOARDS  = [
      { Id: DashboardId.distribution_top_x, Name: this.translate.instant('DASHBOARD.DISTRIBUTION_TOP_X') },
      // { Id: DashboardId.distribution_table, Name: "% Distribution Table" },
      // { Id: DashboardId.actual_vs_expected, Name: "Actual vs Expected" },
      { Id: DashboardId.account_balance, Name: this.translate.instant('DASHBOARD.ACCOUNT_BALANCE') },
    ]

    this.TACTICAL_DASHBOARDS  = [
      { Id: DashboardId.price_performance, Name: this.translate.instant('DASHBOARD.PRICE_PERFORMANCE') },
      { Id: DashboardId.promotion_performance, Name: this.translate.instant('DASHBOARD.PROMOTION_PERFORMANCE') },
      { Id: DashboardId.assortment_performance, Name: this.translate.instant('DASHBOARD.ASSORTMENT_PERFORMANCE') },
    ]

    this.DEMOGRAPHIC_DASHBOARDS = [
      { Id: DashboardId.demographics_chart, Name: this.translate.instant('DASHBOARD.DEMOGRAPHICS_CHART') },
      { Id: DashboardId.table_demographics, Name: this.translate.instant('DASHBOARD.TABLE_DEMOGRAPHICS') },
    ]

    this.BASKET_DASHBOARDS  = [
      { Id: DashboardId.basket_analysis, Name: this.translate.instant('DASHBOARD.BASKET_ANALYSIS') },
    ]

    this.REPORTS_DASHBOARDS  = [
      // { Id: DashboardId.two-dimentional, Name: "2 Dimentional" },
      // { Id: DashboardId.three-dimentional, Name: "3 Dimentional" },
      { Id: DashboardId.table_of_facts, Name: this.translate.instant('DASHBOARD.TABLE_OF_FACTS') },
      { Id: DashboardId.kpi_comparison, Name: this.translate.instant('DASHBOARD.KPI_COMPARISON') },
    ]

    this.SETTING_PAGES = [
      { Id: "account", Name: this.translate.instant('SETTING.ACCOUNT') },
      { Id: "colors", Name: this.translate.instant('SETTING.COLORS') },
      { Id: "standard-selection", Name: this.translate.instant('SETTING.STANDARD_SELECTION') },
      { Id: "presentation", Name: this.translate.instant('SETTING.PRESENTATION') },
      //{ Id: "project", Name: this.translate.instant('SETTING.PROJECT') },
    ]

    this.CHART_MASTER_DASHBOARDS = [
      {
        "group": this.translate.instant('HEADER.PROPENSITY'),
        "id": DashboardId.definition_of_key_facts,
        "name": this.translate.instant('DASHBOARD.DEFINITION_OF_KEY_FACTS'),
        "path": "dashboard/propensity/definition-of-key-facts"
      },
      {
        "group": this.translate.instant('HEADER.PROPENSITY'),
        "id": DashboardId.analysis_of_strengths_and_weaknesses,
        "name": this.translate.instant('DASHBOARD.ANALYSIS_OF_STRENGTHS_AND_WEAKNESSES'),
        "path": "dashboard/propensity/analysis-of-strengths-and-weaknesses"
      },
      {
        "group": this.translate.instant('HEADER.PROPENSITY'),
        "id": DashboardId.purchase_behavior,
        "name": this.translate.instant('DASHBOARD.PURCHASE_BEHAVIOR'),
        "path": "dashboard/propensity/purchase-behavior"
      },
      {
        "group": this.translate.instant('HEADER.PROPENSITY'),
        "id": DashboardId.loyalty_propensity_ranking,
        "name": this.translate.instant('DASHBOARD.LOYALTY_PROPENSITY_RANKING'),
        "path": "dashboard/propensity/loyalty-propensity-ranking"
      },
      {
        "group": this.translate.instant('HEADER.PROPENSITY'),
        "id": DashboardId.structure_of_account_clients,
        "name": this.translate.instant('DASHBOARD.STRUCTURE_OF_ACCOUNT_CLIENTS'),
        "path": "dashboard/propensity/structure-of-account-clients"
      },
      //there should be no market-shares
      // {
      //   "group": "Market Share",
      //    "id": DashboardId.market_shares,
      //   "name": "Market Shares",
      //   "path": "dashboard/market-share/market-shares"
      // },
      {
        "group": this.translate.instant('HEADER.MARKET_SHARE'),
        "id": DashboardId.market_share_development,
        "name": this.translate.instant('DASHBOARD.MARKET_SHARE_DEVELOPMENT'),
        "path": "dashboard/market-share/market-share-development"
      },
      {
        "group": this.translate.instant('HEADER.MARKET_SHARE'),
        "id": DashboardId.market_share_components,
        "name": this.translate.instant('DASHBOARD.MARKET_SHARE_COMPONENTS'),
        "path": "dashboard/market-share/market-share-components"
      },
      {
        "group": this.translate.instant('HEADER.MARKET_SHARE'),
        "id": DashboardId.loyalty_simulation,
        "name": this.translate.instant('DASHBOARD.LOYALTY_SIMULATION'),
        "path": "dashboard/market-share/loyalty-simulation"
      },
      {
        "group": this.translate.instant('HEADER.MARKET_SHARE'),
        "id": DashboardId.decomposition_tree,
        "name": this.translate.instant('DASHBOARD.DECOMPOSITION_TREE'),
        "path": "dashboard/market-share/decomposition-tree"
      },
      {
        "group": this.translate.instant('HEADER.REST_REQUIREMENT'),
        "id": DashboardId.distribution_top_x,
        "name": this.translate.instant('DASHBOARD.DISTRIBUTION_TOP_X'),
        "path": "dashboard/rest-requirement/distribution-top-x"
      },
      {
        "group": this.translate.instant('HEADER.REST_REQUIREMENT'),
        "id": DashboardId.account_balance,
        "name": this.translate.instant('DASHBOARD.ACCOUNT_BALANCE'),
        "path": "dashboard/rest-requirement/account-balance"
      },
      {
        "group": this.translate.instant('HEADER.TACTICAL'),
        "id": DashboardId.price_performance,
        "name": this.translate.instant('DASHBOARD.PRICE_PERFORMANCE'),
        "path": "dashboard/tactical/price-performance"
      },
      {
        "group": this.translate.instant('HEADER.TACTICAL'),
        "id": DashboardId.promotion_performance,
        "name": this.translate.instant('DASHBOARD.PROMOTION_PERFORMANCE'),
        "path": "dashboard/tactical/promotion-performance"
      },
      {
        "group": this.translate.instant('HEADER.TACTICAL'),
        "id": DashboardId.assortment_performance,
        "name": this.translate.instant('DASHBOARD.ASSORTMENT_PERFORMANCE'),
        "path": "dashboard/tactical/assortment-performance"
      },
      {
        "group": this.translate.instant('HEADER.DEMOGRAPHIC'),
        "id": DashboardId.demographics_chart,
        "name": this.translate.instant('DASHBOARD.DEMOGRAPHICS_CHART'),
        "path": "dashboard/demographic/demographics-chart"
      },
      // no ppt for table demographics
      // {
      //   "group": "Demographic",
      //   "id": DashboardId.table_demographics,
      //   "name": "Table Demographics",
      //   "path": "dashboard/demographic/table-demographics"
      // },
      {
        "group": this.translate.instant('HEADER.BASKET'),
        "id": DashboardId.basket_analysis,
        "name": this.translate.instant('DASHBOARD.BASKET_ANALYSIS'),
        "path": "dashboard/basket/basket-analysis"
      },
      // no ppt for table of facts
      // {
      //   "group": "Report",
      //   "id": DashboardId.table_of_facts,
      //   "name": "Table of Facts",
      //   "path": "dashboard/reports/table-of-facts"
      // },
      {
        "group": this.translate.instant('HEADER.REPORTS'),
        "id": DashboardId.kpi_comparison,
        "name": this.translate.instant('DASHBOARD.KPI_COMPARISON'),
        "path": "dashboard/reports/kpi-comparison"
      },
    ];

    this.PROPENSITY = this.translate.instant("HEADER.PROPENSITY")
    this.MARKET_SHARE = this.translate.instant("HEADER.MARKET_SHARE")
    this.REST_REQUIREMENT = this.translate.instant("HEADER.REST_REQUIREMENT")
    this.TACTICAL = this.translate.instant("HEADER.TACTICAL")
    this.DEMOGRAPHIC = this.translate.instant("HEADER.DEMOGRAPHIC")
    this.BASKET = this.translate.instant("HEADER.BASKET")
    this.REPORTS = this.translate.instant("HEADER.REPORTS")

    this.ACCOUNT = this.translate.instant('COMMON.ACCOUNT')
    this.ACCOUNTS = this.translate.instant('COMMON.ACCOUNTS')
    this.ACCOUNT_FOCUS = this.translate.instant('COMMON.ACCOUNT_FOCUS')
    this.ACCOUNT_COMPETITOR = this.translate.instant('COMMON.ACCOUNT_COMPETITOR')
    this.ACCOUNT_CLIENT_ACCOUNT_BUYER = this.translate.instant('COMMON.ACCOUNT_CLIENT_ACCOUNT_BUYER')

    this.PRODUCT = this.translate.instant('COMMON.PRODUCT')
    this.PRODUCTS = this.translate.instant('COMMON.PRODUCTS')

    this.SELECT_ALL = this.translate.instant('COMMON.SELECT_ALL')
    this.SELECT_ALL_IN_HIERARCHY = this.translate.instant('COMMON.SELECT_ALL_IN_HIERARCHY')
    this.SELECT_ALL_IN_HIERARCHY_WARNING = this.translate.instant('COMMON.SELECT_ALL_IN_HIERARCHY_WARNING')

    this.PERIOD_AND_FACT = this.translate.instant('COMMON.PERIOD_AND_FACT')
    this.PERIOD = this.translate.instant('COMMON.PERIOD')
    this.PERIODS = this.translate.instant('COMMON.PERIODS')
    this.PERIOD1 = this.translate.instant('COMMON.PERIOD1')
    this.PERIOD2 = this.translate.instant('COMMON.PERIOD2')
    this.CURRENT_PERIOD = this.translate.instant('COMMON.CURRENT_PERIOD')
    this.PERVIOUS_PERIOD = this.translate.instant('COMMON.PERVIOUS_PERIOD')

    this.FACT = this.translate.instant('COMMON.FACT')
    this.FACTS = this.translate.instant('COMMON.FACTS')
    this.FACT_CHART = this.translate.instant('COMMON.FACT_CHART')
    this.FACT_TABLE = this.translate.instant('COMMON.FACT_TABLE')

    this.SELECTION = this.translate.instant('COMMON.SELECTION')

    this.BENCHMARK=  this.translate.instant('COMMON.BENCHMARK')
    this.BENCHMARK_FOR_PRODUCT =  this.translate.instant('COMMON.BENCHMARK_FOR_PRODUCT')
    this.BENCHMARK_FOR_ACCOUNT =  this.translate.instant('COMMON.BENCHMARK_FOR_ACCOUNT')
    this.NO_BENCHMARK_SELECTED =  {Id: 'None', Name: this.translate.instant('COMMON.NO_BENCHMARK_SELECTED'), Values: [-11], XAxisValues: [-11], YAxisValues: [-11]}  // default value is -11, so that the benchmark line won't be shown in chart. The chart minXAxis and minYAxis are both -10

    this.SHOPPER_GROUP = this.translate.instant('COMMON.SHOPPER_GROUP')

    this.AVAILABLE_DASHBOARDS = this.translate.instant('COMMON.AVAILABLE_DASHBOARDS')
    this.SELECTED_DASHBOARDS = this.translate.instant('COMMON.SELECTED_DASHBOARDS')

    this.CREATE_PPT = this.translate.instant('COMMON.CREATE_PPT')
    this.SAVE_AND_LOGOUT = this.translate.instant('COMMON.SAVE_AND_LOGOUT')
    this.SAVE = this.translate.instant('COMMON.SAVE')

    this.LANGUAGE = this.translate.instant('COMMON.LANGUAGE')
    this.DATABASE = this.translate.instant('COMMON.DATABASE')

    this.FOCUS = this.translate.instant('COMMON.FOCUS')
    this.COMPETITOR = this.translate.instant('COMMON.COMPETITOR')

    this.EXPORT = this.translate.instant('COMMON.EXPORT')
    this.EXCEL = this.translate.instant('COMMON.EXCEL')
    this.SINGLE_PPT = this.translate.instant('COMMON.SINGLE_PPT')
    this.MULTI_PPT = this.translate.instant('COMMON.MULTI_PPT')
    this.EXPORT_MULTI_PPT = this.translate.instant('COMMON.EXPORT_MULTI_PPT')
    this.EXPORT_EXCEL_WARNING = this.translate.instant('COMMON.EXPORT_EXCEL_WARNING')

    this.CHART_SORTING = this.translate.instant('COMMON.CHART_SORTING')
    this.SORTING_OBJECT = {}
    this.SORTING_OBJECT[SortingIds.ranking] = this.translate.instant('COMMON.SORT_BY_RANKING')
    this.SORTING_OBJECT[SortingIds.clickOrder] = this.translate.instant('COMMON.SORT_BY_CLICK_ORDER')
    this.SELECT_CHART_SORTING_DATA = [
      {Id: SortingIds.ranking, Name: this.translate.instant('COMMON.SORT_BY_RANKING')},
      {Id: SortingIds.clickOrder, Name: this.translate.instant('COMMON.SORT_BY_CLICK_ORDER')}
    ]

    this.LOYALTY = this.translate.instant('COMMON.LOYALTY')

    this.FACT_OBJECT = {
      "F1": this.translate.instant("FACT.F1"),
      "F2": this.translate.instant("FACT.F2"),
      "F3": this.translate.instant("FACT.F3"),
      "F4": this.translate.instant("FACT.F4"),
      "F5": this.translate.instant("FACT.F5"),
      "F6": this.translate.instant("FACT.F6"),
      "F7": this.translate.instant("FACT.F7"),
      "F8": this.translate.instant("FACT.F8"),
      "F9": this.translate.instant("FACT.F9"),
      "F10": this.translate.instant("FACT.F10"),
      "F11": this.translate.instant("FACT.F11"),
      "F12": this.translate.instant("FACT.F12"),
      "F13": this.translate.instant("FACT.F13"),
      "F14": this.translate.instant("FACT.F14"),
      "F15": this.translate.instant("FACT.F15"),
      "F16": this.translate.instant("FACT.F16"),
      "F17": this.translate.instant("FACT.F17"),
      "F18": this.translate.instant("FACT.F18"),
      "F19": this.translate.instant("FACT.F19"),
      "F20": this.translate.instant("FACT.F20"),
      "F21": this.translate.instant("FACT.F21"),
      "F22": this.translate.instant("FACT.F22"),
      "F23": this.translate.instant("FACT.F23"),
      "F24": this.translate.instant("FACT.F24"),
      "F25": this.translate.instant("FACT.F25"),
      "F26": this.translate.instant("FACT.F26"),
      "SF1": this.translate.instant("FACT.SF1"),
      "SF2": this.translate.instant("FACT.SF2"),
      "SF3": this.translate.instant("FACT.SF3"),
      "SF4": this.translate.instant("FACT.SF4"),
      "SF5": this.translate.instant("FACT.SF5"),
      "SF6": this.translate.instant("FACT.SF6"),
      "SF7": this.translate.instant("FACT.SF7"),
      "SF8": this.translate.instant("FACT.SF8"),
      "SF9": this.translate.instant("FACT.SF9"),
      "SF10": this.translate.instant("FACT.SF10"),
      "TF1": this.translate.instant("FACT.TF1"),
      "TF2": this.translate.instant("FACT.TF2"),
      "TF3": this.translate.instant("FACT.TF3"),
      "TF4": this.translate.instant("FACT.TF4"),
      "TF5": this.translate.instant("FACT.TF5"),
      "TF6": this.translate.instant("FACT.TF6"),
      "TF7": this.translate.instant("FACT.TF7"),
      "TF8": this.translate.instant("FACT.TF8"),
      "TF9": this.translate.instant("FACT.TF9"),
      "TF10": this.translate.instant("FACT.TF10"),
      "TF11": this.translate.instant("FACT.TF11"),
      "TF12": this.translate.instant("FACT.TF12"),
      "TF13": this.translate.instant("FACT.TF13"),
      "TF14": this.translate.instant("FACT.TF14"),
      "F_MSC_FACT1": this.translate.instant("FACT.F_MSC_FACT1"),
      "F_MSC_FACT2": this.translate.instant("FACT.F_MSC_FACT2"),
      "F_MSC_FACT3": this.translate.instant("FACT.F_MSC_FACT3"),
      "F_MSC_FACT4": this.translate.instant("FACT.F_MSC_FACT4"),
      "F_MSC_FACT5": this.translate.instant("FACT.F_MSC_FACT5"),
      "F_MSC_FACT6": this.translate.instant("FACT.F_MSC_FACT6"),
      "F_MSC_FACT7": this.translate.instant("FACT.F_MSC_FACT7"),
      "F_MSC_FACT8": this.translate.instant("FACT.F_MSC_FACT8"),
      "F_MSC_FACT9": this.translate.instant("FACT.F_MSC_FACT9"),
      "F_MSC_FACT10": this.translate.instant("FACT.F_MSC_FACT10"),
      "F_MSC_FACT11": this.translate.instant("FACT.F_MSC_FACT11"),
      "F_MSC_FACT12": this.translate.instant("FACT.F_MSC_FACT12"),
      "F_MSC_FACT13": this.translate.instant("FACT.F_MSC_FACT13"),
      "F_MSC_FACT14": this.translate.instant("FACT.F_MSC_FACT14"),
      "F_MSC_FACT15": this.translate.instant("FACT.F_MSC_FACT15"),

    }

    this.FACT_DIM = [
      {"Id":"F1", "Name":this.FACT_OBJECT["F1"]},
      {"Id":"F2", "Name":this.FACT_OBJECT["F2"]},
      {"Id":"F3", "Name":this.FACT_OBJECT["F3"]},
      {"Id":"F4", "Name":this.FACT_OBJECT["F4"]},
      {"Id":"F5", "Name":this.FACT_OBJECT["F5"]},
      {"Id":"F6", "Name":this.FACT_OBJECT["F6"]},
      {"Id":"F7", "Name":this.FACT_OBJECT["F7"]},
      {"Id":"F8", "Name":this.FACT_OBJECT["F8"]},
      {"Id":"F9", "Name":this.FACT_OBJECT["F9"]},
      {"Id":"F10", "Name":this.FACT_OBJECT["F10"]},
      {"Id":"F11", "Name":this.FACT_OBJECT["F11"]},
      {"Id":"F12", "Name":this.FACT_OBJECT["F12"]},
      {"Id":"F13", "Name":this.FACT_OBJECT["F13"]},
      {"Id":"F14", "Name":this.FACT_OBJECT["F14"]},
      {"Id":"F15", "Name":this.FACT_OBJECT["F15"]},
      {"Id":"F16", "Name":this.FACT_OBJECT["F16"]},
      {"Id":"F17", "Name":this.FACT_OBJECT["F17"]},
      {"Id":"F18", "Name":this.FACT_OBJECT["F18"]},
      {"Id":"F19", "Name":this.FACT_OBJECT["F19"]},
      {"Id":"F20", "Name":this.FACT_OBJECT["F20"]},
      {"Id":"F21", "Name":this.FACT_OBJECT["F21"]},
      {"Id":"F22", "Name":this.FACT_OBJECT["F22"]},
      {"Id":"F23", "Name":this.FACT_OBJECT["F23"]},
      {"Id":"F24", "Name":this.FACT_OBJECT["F24"]},
      {"Id":"F25", "Name":this.FACT_OBJECT["F25"]},
      {"Id":"F26", "Name":this.FACT_OBJECT["F26"]},
      {"Id":"SF1", "Name":this.FACT_OBJECT["SF1"]},
      {"Id":"SF2", "Name":this.FACT_OBJECT["SF2"]},
      {"Id":"SF3", "Name":this.FACT_OBJECT["SF3"]},
      {"Id":"SF4", "Name":this.FACT_OBJECT["SF4"]},
      {"Id":"SF5", "Name":this.FACT_OBJECT["SF5"]},
      {"Id":"SF6", "Name":this.FACT_OBJECT["SF6"]},
      {"Id":"SF7", "Name":this.FACT_OBJECT["SF7"]},
      {"Id":"SF8", "Name":this.FACT_OBJECT["SF8"]},
      {"Id":"SF9", "Name":this.FACT_OBJECT["SF9"]},
      {"Id":"SF10", "Name":this.FACT_OBJECT["SF10"]},
      {"Id":"TF1", "Name":this.FACT_OBJECT["TF1"]},
      {"Id":"TF2", "Name":this.FACT_OBJECT["TF2"]},
      {"Id":"TF3", "Name":this.FACT_OBJECT["TF3"]},
      {"Id":"TF4", "Name":this.FACT_OBJECT["TF4"]},
      {"Id":"TF5", "Name":this.FACT_OBJECT["TF5"]},
      {"Id":"TF6", "Name":this.FACT_OBJECT["TF6"]},
      {"Id":"TF7", "Name":this.FACT_OBJECT["TF7"]},
      {"Id":"TF8", "Name":this.FACT_OBJECT["TF8"]},
      {"Id":"TF9", "Name":this.FACT_OBJECT["TF9"]},
      {"Id":"TF10", "Name":this.FACT_OBJECT["TF10"]},
      {"Id":"TF11", "Name":this.FACT_OBJECT["TF11"]},
      {"Id":"TF12", "Name":this.FACT_OBJECT["TF12"]},
      {"Id":"TF13", "Name":this.FACT_OBJECT["TF13"]},
      {"Id":"TF14", "Name":this.FACT_OBJECT["TF14"]}

    ]

    this.MODE = this.translate.instant('COMMON.SWITCH')
    this.SELECT_FILTER_MODE_DATA =  [
      {Id: ModeIds.OneAccountMultiProducts, Name: this.translate.instant('COMMON.ONE_ACCOUNT_MULTI_PRODUCTS')},
      {Id: ModeIds.OneProductMultiAccounts, Name: this.translate.instant('COMMON.ONE_PRODUCT_MULTI_ACCOUNTS')}
    ]

    this.CHART_SETTING = this.translate.instant('COMMON.CHART_SETTING')
    this.CHART_SETTING_SAVE_VALUES = this.translate.instant('COMMON.CHART_SETTING_SAVE_VALUES')
    this.LEGEND = this.translate.instant('COMMON.LEGEND')
    this.DATA_LABEL = this.translate.instant('COMMON.DATA_LABEL')

    this.SLIDE = this.translate.instant("COMMON.SLIDE")
    this.NEXT = this.translate.instant("COMMON.NEXT")
    this.CANCEL = this.translate.instant("COMMON.CANCEL")

    this.PENETRATION_WARNING = this.translate.instant("COMMON.PENETRATION_WARNING")
    this.PENETRATION_WARNING_TOOLTIP = this.translate.instant("COMMON.PENETRATION_WARNING_TOOLTIP")
    this.HIDE_PENETRATION_WARNING = this.translate.instant("COMMON.HIDE_PENETRATION_WARNING")

    this.SWITCH = this.translate.instant("COMMON.SWITCH")
    this.BASIS = this.translate.instant("COMMON.BASIS")

    this.BASIS = this.translate.instant("COMMON.BASIS")
    this.BASIS = this.translate.instant("COMMON.BASIS")
    this.BASIS = this.translate.instant("COMMON.BASIS")

    this.SWITCH_OBJECT[SwitchId.Buyers] = this.translate.instant("COMMON.BUYERS")
    this.SWITCH_OBJECT[SwitchId.Expenditures] = this.translate.instant("COMMON.EXPENDITURES")
    this.SWITCH_OBJECT[SwitchId.Expenditures_incl_RR] = this.translate.instant("COMMON.EXPENDITURES_INCL_RR")

    this.SELECT_SWITCH_DATA = [
      {Id: SwitchId.Buyers, Name: this.translate.instant("COMMON.BUYERS")},
      {Id: SwitchId.Expenditures, Name: this.translate.instant("COMMON.EXPENDITURES")},
      {Id: SwitchId.Expenditures_incl_RR, Name: this.translate.instant("COMMON.EXPENDITURES_INCL_RR")}
    ]

    this.BUYERS_TEXT_0 = this.translate.instant("COMMON.BUYERS_TEXT_0")
    this.BUYERS_EXCEL_1 = this.translate.instant("COMMON.BUYERS_EXCEL_1")
    this.BUYERS_EXCEL_2 = this.translate.instant("COMMON.BUYERS_EXCEL_2")
    this.BUYERS_EXCEL_3 = this.translate.instant("COMMON.BUYERS_EXCEL_3")
    this.BUYERS_HTML_1 = this.translate.instant("COMMON.BUYERS_HTML_1")
    this.BUYERS_HTML_2 = this.translate.instant("COMMON.BUYERS_HTML_2")
    this.BUYERS_HTML_3 = this.translate.instant("COMMON.BUYERS_HTML_3")
    this.BUYERS_TEXT_4 = this.translate.instant("COMMON.BUYERS_TEXT_4")
    this.BUYERS_TEXT_5 = this.translate.instant("COMMON.BUYERS_TEXT_5")
    this.BUYERS_TEXT_6 = this.translate.instant("COMMON.BUYERS_TEXT_6")
    this.BUYERS_TEXT_7 = this.translate.instant("COMMON.BUYERS_TEXT_7")

    this.EXPENDITURES_EXCEL_1 = this.translate.instant("COMMON.EXPENDITURES_EXCEL_1")
    this.EXPENDITURES_EXCEL_2 = this.translate.instant("COMMON.EXPENDITURES_EXCEL_2")
    this.EXPENDITURES_EXCEL_3 = this.translate.instant("COMMON.EXPENDITURES_EXCEL_3")
    this.EXPENDITURES_HTML_1 = this.translate.instant("COMMON.EXPENDITURES_HTML_1")
    this.EXPENDITURES_HTML_2 = this.translate.instant("COMMON.EXPENDITURES_HTML_2")
    this.EXPENDITURES_HTML_3 = this.translate.instant("COMMON.EXPENDITURES_HTML_3")
    this.EXPENDITURES_TEXT_4 = this.translate.instant("COMMON.EXPENDITURES_TEXT_4")
    this.EXPENDITURES_TEXT_5 = this.translate.instant("COMMON.EXPENDITURES_TEXT_5")
    this.EXPENDITURES_TEXT_6 = this.translate.instant("COMMON.EXPENDITURES_TEXT_6")
    this.EXPENDITURES_TEXT_7 = this.translate.instant("COMMON.EXPENDITURES_TEXT_7")

    this.EXPENDITURES_INCL_RR_EXCEL_1 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_EXCEL_1")
    this.EXPENDITURES_INCL_RR_EXCEL_2 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_EXCEL_2")
    this.EXPENDITURES_INCL_RR_EXCEL_3 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_EXCEL_3")
    this.EXPENDITURES_INCL_RR_EXCEL_4 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_EXCEL_4")
    this.EXPENDITURES_INCL_RR_HTML_1 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_HTML_1")
    this.EXPENDITURES_INCL_RR_HTML_2 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_HTML_2")
    this.EXPENDITURES_INCL_RR_HTML_3 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_HTML_3")
    this.EXPENDITURES_INCL_RR_HTML_4 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_HTML_4")
    this.EXPENDITURES_INCL_RR_TEXT_5 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_TEXT_5")
    this.EXPENDITURES_INCL_RR_TEXT_6 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_TEXT_6")
    this.EXPENDITURES_INCL_RR_TEXT_7 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_TEXT_7")
    this.EXPENDITURES_INCL_RR_TEXT_8 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_TEXT_8")
    this.EXPENDITURES_INCL_RR_TEXT_9 = this.translate.instant("COMMON.EXPENDITURES_INCL_RR_TEXT_9")

    this.PERIOD2_NOT_SELECTED = {Id: "None", Name: this.translate.instant("COMMON.NOT_SELECTED")}

    this.DESCRIPTION = this.translate.instant("COMMON.DESCRIPTION")
    this.VALUE = this.translate.instant("COMMON.VALUE")

    this.ALL_HOUSEHOLDS = this.translate.instant("COMMON.ALL_HOUSEHOLDS")
    this.SHOPPER_VS_NON_SHOPPER = this.translate.instant("COMMON.SHOPPER_VS_NON_SHOPPER")
    this.SHOPPER = this.translate.instant("COMMON.SHOPPER")
    this.NON_SHOPPER = this.translate.instant("COMMON.NON_SHOPPER")
    this.PRODUCT_BUYER = this.translate.instant("COMMON.PRODUCT_BUYER")
    this.NON_PRODUCT_BUYER = this.translate.instant("COMMON.NON_PRODUCT_BUYER")
    this.SHOPPER_PRODUCT_BUYER = this.translate.instant("COMMON.SHOPPER_PRODUCT_BUYER")
    this.IN_SHOP = this.translate.instant("COMMON.IN_SHOP")
    this.ELSEWHERE = this.translate.instant("COMMON.ELSEWHERE")
    this.BUYER_GROUP = this.translate.instant("COMMON.BUYER_GROUP")
    this.BUYING_HOUSEHOLDS_M = this.translate.instant("COMMON.BUYING_HOUSEHOLDS_M")
    this.SHAER_IN_GROUP = this.translate.instant("COMMON.SHAER_IN_GROUP")

    this.MARKET_SHARE_FACT = this.translate.instant("COMMON.MARKET_SHARE_FACT")
    this.CHANGE_RATE = this.translate.instant("COMMON.CHANGE_RATE")
    this.CHANGE_RATE_OBJECT = {}
    this.CHANGE_RATE_OBJECT[ChangeRateIds.changeInValue] = this.translate.instant("COMMON.CHANGE_ABSOLUTE")
    this.CHANGE_RATE_OBJECT[ChangeRateIds.changeInPercentage] = this.translate.instant("COMMON.CHANGE_IN_PERCENT")
    this.SELECT_CHANGE_RATE_DATE = [
      {Id: ChangeRateIds.changeInValue, Name: this.translate.instant("COMMON.CHANGE_ABSOLUTE")},
      {Id: ChangeRateIds.changeInPercentage, Name: this.translate.instant("COMMON.CHANGE_IN_PERCENT")}
    ]

    this.SELECT = this.translate.instant("COMMON.SELECT")
    this.MARKETSHARE_EXPENDITURE_OBJECT = {}
    this.MARKETSHARE_EXPENDITURE_OBJECT[MarketShareExpenditureId.marketshare] = this.translate.instant("COMMON.CHANGE_MARKETSHARE")
    this.MARKETSHARE_EXPENDITURE_OBJECT[MarketShareExpenditureId.expenditure] = this.translate.instant("COMMON.CHANGE_EXPENDITURE")
    this.SELECT_MARKETSHARE_EXPENDITURE_DATA = [
      {Id: MarketShareExpenditureId.marketshare, Name: this.translate.instant("COMMON.CHANGE_MARKETSHARE")},
      {Id: MarketShareExpenditureId.expenditure, Name: this.translate.instant("COMMON.CHANGE_EXPENDITURE")}
    ]

    this.DECOMPOSITION_TREE = this.translate.instant('DASHBOARD.DECOMPOSITION_TREE')
    this.VALUE_CHANGE = this.translate.instant('COMMON.VALUE_CHANGE')
    this.VALUE_CHANGE_AMOUNT = this.translate.instant('COMMON.VALUE_CHANGE_AMOUNT')

    this.LOST_EXPENDITURE_IN_PERCENT = this.translate.instant('COMMON.LOST_EXPENDITURE_IN_PERCENT')
    this.LOST_EXPENDITURE_INDEX = this.translate.instant('COMMON.LOST_EXPENDITURE_INDEX')
    this.EXPENDITURE_FOR = this.translate.instant('COMMON.EXPENDITURE_FOR')
    this.FACT_RR_OBJECT = {}
    this.FACT_RR_OBJECT[ClientBuyerIds.clients] = this.translate.instant('COMMON.ACCOUNT_CLIENT')
    this.FACT_RR_OBJECT[ClientBuyerIds.buyers] = this.translate.instant('COMMON.ACCOUNT_BUYER')
    this.SELECT_FACT_RR_DATA = [
      {Id: ClientBuyerIds.clients, Name: this.translate.instant('COMMON.ACCOUNT_CLIENT')},
      {Id: ClientBuyerIds.buyers, Name: this.translate.instant('COMMON.ACCOUNT_BUYER')}
    ]
    this.SHOW_RR_INDEX = this.translate.instant('COMMON.SHOW_RR_INDEX')

    this.DEMAND_COVERAGE = this.translate.instant('COMMON.DEMAND_COVERAGE')
    this.LOST_EXPENSES = this.translate.instant('COMMON.LOST_EXPENSES')
    this.LOST_EXPENSES_TOTAL = this.translate.instant('COMMON.LOST_EXPENSES_TOTAL')
    this.Umsatzpotenzialausschöpfung = this.translate.instant('COMMON.Umsatzpotenzialausschöpfung')
    this.VerloreneAusgaben = this.translate.instant('COMMON.VerloreneAusgaben')
    this.Nebenausgaben = this.translate.instant('COMMON.Nebenausgaben')

    this.TABLE = this.translate.instant('COMMON.TABLE')

    this.FROM = this.translate.instant('COMMON.FROM')
    this.TO = this.translate.instant('COMMON.TO')
    this.NET_BALANCE = this.translate.instant('COMMON.NET_BALANCE')

    this.BASIS_OBJECT =  {}
    this.BASIS_OBJECT[BasisId.expenditures] = this.translate.instant('COMMON.EXPENDITURES')
    this.BASIS_OBJECT[BasisId.buyers] = this.translate.instant('COMMON.BUYERS')

    this.SELECT_BASIS_DATA = [
      {Id: BasisId.expenditures, Name: this.translate.instant('COMMON.EXPENDITURES') },
      {Id: BasisId.buyers, Name: this.translate.instant('COMMON.BUYERS') }
    ]

    this.DEMOGRAPHIC_CHART_SWITCH_OBJECT = {}
    this.DEMOGRAPHIC_CHART_SWITCH_OBJECT[DemographicSwitchId.IndexTotalExpenditureMarket] = this.translate.instant('COMMON.INDEX_TOTAL_EXPENDITURE_MARKET')
    this.DEMOGRAPHIC_CHART_SWITCH_OBJECT[DemographicSwitchId.PercentDistribution] = this.translate.instant('COMMON.PERCENT_DISTRIBUTION')
    this.DEMOGRAPHIC_CHART_SWITCH_OBJECT[DemographicSwitchId.IndexProductAccount] = this.translate.instant('COMMON.INDEX_PRODUCT_ACCOUNT')
    this.SELECT_DEMOGRAPHIC_CHART_SWITCH_DATA = [
      {Id: DemographicSwitchId.IndexTotalExpenditureMarket, Name: this.translate.instant('COMMON.INDEX_TOTAL_EXPENDITURE_MARKET')},
      {Id: DemographicSwitchId.PercentDistribution, Name: this.translate.instant('COMMON.PERCENT_DISTRIBUTION')},
      {Id: DemographicSwitchId.IndexProductAccount, Name: this.translate.instant('COMMON.INDEX_PRODUCT_ACCOUNT')}
    ]
    this.DEMOGRAPHIC_GROUP = this.translate.instant('COMMON.DEMOGRAPHIC_GROUP')
    this.HOUSEHOLD_PERIOD1 = this.translate.instant('COMMON.HOUSEHOLD_PERIOD1')
    this.STYLE = this.translate.instant('COMMON.STYLE')

    this.BAR_STYLE_OBJECT = {}
    this.BAR_STYLE_OBJECT[BarStyleId.horizontal] = this.translate.instant('COMMON.HORIZONTAL')
    this.BAR_STYLE_OBJECT[BarStyleId.vertical] = this.translate.instant('COMMON.VERTICAL')

    this.SELECT_BAR_STYLE_DATA = [
      {Id: BarStyleId.horizontal, Name: this.translate.instant('COMMON.HORIZONTAL')},
      {Id: BarStyleId.vertical, Name: this.translate.instant('COMMON.VERTICAL')},
    ]
    this.CHART = this.translate.instant('COMMON.CHART')
    this.PRODUCT_ACCOUNT = this.translate.instant('COMMON.PRODUCT_ACCOUNT')

    this.TABLE_DEMOGRAPHICS_FACTS = [
      {"Id": 'SF2', "Name": this.translate.instant("FACT.SF2"), "Checked": false},
      {"Id": 'c_BuyerStructureM', "Name": this.translate.instant("COMMON.BUYER_STRUCTURE_M"), "Checked": false},
      {"Id": 'SF3', "Name": this.translate.instant("FACT.SF3"), "Checked": false},
      {"Id": 'SF9', "Name": this.translate.instant("FACT.SF9"), "Checked": false},
      {"Id": 'SF5', "Name": this.translate.instant("FACT.SF5"), "Checked": false},
      {"Id": 'SF6', "Name": this.translate.instant("FACT.SF6"), "Checked": false},
      {"Id": 'SF8', "Name": this.translate.instant("FACT.SF8"), "Checked": false},
      {"Id": 'c_BuyerIndexVSProductAccount', "Name": this.translate.instant("COMMON.BUYER_INDEX_VS_PRODUCT_ACCOUNT"), "Checked": false},
      {"Id": 'c_ExpenditureIndexVSProductAccount', "Name": this.translate.instant("COMMON.EXPENDITURE_INDEX_VS_PRODUCT_ACCOUNT"), "Checked": false},
    ]

    this.METRICS = this.translate.instant('COMMON.METRICS')
    this.METRICS_OBJECT = {}
    this.METRICS_OBJECT[MetricId.percentage] = ""
    this.METRICS_OBJECT[MetricId.value] = ""
    this.SELECT_METRICS_DATA = [
      {Id: MetricId.percentage, Name: this.translate.instant('COMMON.PERCENT')},
      {Id: MetricId.value, Name: this.translate.instant('COMMON.VALUE')}
    ]
    this.CHANGE = this.translate.instant('COMMON.CHANGE')
    this.CHANGE_OBJECT = {}
    this.CHANGE_OBJECT[ChangeId.show] = this.translate.instant('COMMON.SHOW')
    this.CHANGE_OBJECT[ChangeId.hide] = this.translate.instant('COMMON.HIDE')
    this.SELECT_CHANGE_DATA = [
      {Id: ChangeId.show, Name: this.translate.instant('COMMON.SHOW')},
      {Id: ChangeId.hide, Name: this.translate.instant('COMMON.HIDE')}
    ]
    this.TABLE_TYPE = this.translate.instant('COMMON.TABLE_TYPE')
    this.PIVOT1 = this.translate.instant('COMMON.PIVOT1')
    this.PIVOT2 = this.translate.instant('COMMON.PIVOT2')
    this.PIVOT_SETTING = this.translate.instant('COMMON.PIVOT_SETTING')
    this.UPDATE_PIVOT = this.translate.instant('COMMON.UPDATE_PIVOT')

    this.BUBBLE_SIZES = this.translate.instant('COMMON.BUBBLE_SIZES')
    this.PROPENSITY_LOYALTY = this.translate.instant('COMMON.PROPENSITY_LOYALTY')

    this.FACT_TYPE_OBJECT = {}
    this.FACT_TYPE_OBJECT[FactTypeId.percent] = this.translate.instant('COMMON.FACT_TYPE_PERCENT')
    this.FACT_TYPE_OBJECT[FactTypeId.index] = this.translate.instant('COMMON.FACT_TYPE_INDEX')
    this.SELECT_FACT_TYPE_DATA = [
      {Id: FactTypeId.percent, Name: this.translate.instant('COMMON.FACT_TYPE_PERCENT')},
      {Id: FactTypeId.index, Name: this.translate.instant('COMMON.FACT_TYPE_INDEX')},
    ]
    this.BENCHMARK_WARNING = this.translate.instant('COMMON.BENCHMARK_WARNING')

    this.DATA_TABLE_FACTS = [
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_BASIC'), "Selected": false,
          Children: [
              { "Id": 'F11', "Name": this.translate.instant('FACT.F11'), "Selected": false },
              { "Id": 'F10', "Name": this.translate.instant('FACT.F10'), "Selected": false },
              { "Id": 'F6', "Name": this.translate.instant('FACT.F6'), "Selected": false },
              { "Id": 'F7', "Name": this.translate.instant('FACT.F7'), "Selected": false },
              { "Id": 'F8', "Name": this.translate.instant('FACT.F8'), "Selected": false },
              { "Id": 'F9', "Name": this.translate.instant('FACT.F9'), "Selected": false },
              { "Id": 'F3', "Name": this.translate.instant('FACT.F3'), "Selected": false },
              { "Id": 'c_ShopperConversion', "Name": this.translate.instant('FACT.c_ShopperConversion'), "Selected": false },
              { "Id": 'c_NumberOfLostHouseholds', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholds'), "Selected": false },
              { "Id": 'c_NumberOfAccountClients', "Name": this.translate.instant('FACT.c_NumberOfAccountClients'), "Selected": false },
              { "Id": 'c_NumberOfAccountBuyers', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyers'), "Selected": false },
              { "Id": 'c_PenetrationOfShop', "Name": this.translate.instant('FACT.c_PenetrationOfShop'), "Selected": false },
              { "Id": 'F16', "Name": this.translate.instant('FACT.F16'), "Selected": false },
              { "Id": 'c_Occasions000', "Name": this.translate.instant('FACT.c_Occasions000'), "Selected": false },
              { "Id": 'F13', "Name": this.translate.instant('FACT.F13'), "Selected": false },
              { "Id": 'F12', "Name": this.translate.instant('FACT.F12'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_BUYERS_PERCENT'), "Selected": false ,
          Children: [
              { "Id": 'c_PenetrationInTotalMarket', "Name": this.translate.instant('FACT.c_PenetrationInTotalMarket'), "Selected": false },
              { "Id": 'c_PenetrationOfShop', "Name": this.translate.instant('FACT.c_PenetrationOfShop'), "Selected": false },
              { "Id": 'F1', "Name": this.translate.instant('FACT.F1'), "Selected": false },
              { "Id": 'F2', "Name": this.translate.instant('FACT.F2'), "Selected": false },
              { "Id": 'F3', "Name": this.translate.instant('FACT.F3'), "Selected": false },
              { "Id": 'c_ShopperConversion', "Name": this.translate.instant('FACT.c_ShopperConversion'), "Selected": false },
              { "Id": 'c_BuyerCoverageIndex', "Name": this.translate.instant('FACT.c_BuyerCoverageIndex'), "Selected": false },
              { "Id": 'c_ShopperConversionIndex', "Name": this.translate.instant('FACT.c_ShopperConversionIndex'), "Selected": false },
              { "Id": 'c_ComparativeCoverageIndex', "Name": this.translate.instant('FACT.c_ComparativeCoverageIndex'), "Selected": false },
              { "Id": 'F15', "Name": this.translate.instant('FACT.F15'), "Selected": false },
              { "Id": 'c_ComparativeCoverageInTotalMarket', "Name": this.translate.instant('FACT.c_ComparativeCoverageInTotalMarket'), "Selected": false },
              { "Id": 'c_ComparativeCoverageOfAccountBuyers', "Name": this.translate.instant('FACT.c_ComparativeCoverageOfAccountBuyers'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_NUMBER_BUYERS_M'), "Selected": false ,
          Children: [
            { "Id": 'c_NumberOfBuyersInTotalMarketM', "Name": this.translate.instant('FACT.c_NumberOfBuyersInTotalMarketM'), "Selected": false },
            { "Id": 'c_NumberOfBuyerOfShopM', "Name": this.translate.instant('FACT.c_NumberOfBuyerOfShopM'), "Selected": false },
            { "Id": 'c_NumberOfAccountClientsM', "Name": this.translate.instant('FACT.c_NumberOfAccountClientsM'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersM', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersM'), "Selected": false },
            { "Id": 'c_NumberOfLostHouseholdsM', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholdsM'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_NUMBER_BUYERS'), "Selected": false,
          Children: [
            { "Id": 'c_NumberOfBuyersInTotalMarket', "Name": this.translate.instant('FACT.c_NumberOfBuyersInTotalMarket'), "Selected": false },
            { "Id": 'c_NumberOfBuyerOfShop', "Name": this.translate.instant('FACT.c_NumberOfBuyerOfShop'), "Selected": false },
            { "Id": 'c_NumberOfAccountClients', "Name": this.translate.instant('FACT.c_NumberOfAccountClients'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyers', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyers'), "Selected": false },
            { "Id": 'c_NumberOfLostHouseholds', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholds'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_EXPENDITURES_M'), "Selected": false ,
          Children: [
            { "Id": 'c_TotalExpendituresM', "Name": this.translate.instant('FACT.c_TotalExpendituresM'), "Selected": false },
            { "Id": 'c_ExpendituresInTotalMarketM', "Name": this.translate.instant('FACT.c_ExpendituresInTotalMarketM'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountClientsM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountClientsM'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyersM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersM'), "Selected": false },
            { "Id": 'c_LostExpendituresM', "Name": this.translate.instant('FACT.c_LostExpendituresM'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyersInAllShopsM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersInAllShopsM'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_EXPENDITURES'), "Selected": false ,
          Children: [
            { "Id": 'c_TotalExpenditures000', "Name": this.translate.instant('FACT.c_TotalExpenditures000'), "Selected": false },
            { "Id": 'c_ExpendituresInTotalMarket000', "Name": this.translate.instant('FACT.c_ExpendituresInTotalMarket000'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountClients000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountClients000'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyers000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyers000'), "Selected": false },
            { "Id": 'c_LostExpenditures000', "Name": this.translate.instant('FACT.c_LostExpenditures000'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyersInAllShops000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersInAllShops000'), "Selected": false },
            { "Id": 'c_ExpendituresPerAccountBuyersInAllShops', "Name": this.translate.instant('FACT.c_ExpendituresPerAccountBuyersInAllShops'), "Selected": false },
            { "Id": 'c_ExpenditurePerAccountClientsInAllShops', "Name": this.translate.instant('FACT.c_ExpenditurePerAccountClientsInAllShops'), "Selected": false },
            { "Id": 'c_ExpendituresPerBuyerInTotalMarket', "Name": this.translate.instant('FACT.c_ExpendituresPerBuyerInTotalMarket'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_SHOPPING'), "Selected": false ,
          Children: [
            { "Id": 'F5', "Name": this.translate.instant('FACT.F5'), "Selected": false },
            { "Id": 'F13', "Name": this.translate.instant('FACT.F13'), "Selected": false },
            { "Id": 'F12', "Name": this.translate.instant('FACT.F12'), "Selected": false },
            { "Id": 'c_Occasions000', "Name": this.translate.instant('FACT.c_Occasions000'), "Selected": false },
          ]
      },
      {
        "Id": '', "Name": this.translate.instant('FACT.F_R_PROPENSITY'), "Selected": false ,
        Children: [
          { "Id": 'F10', "Name": this.translate.instant('FACT.F10'), "Selected": false },
          { "Id": 'F11', "Name": this.translate.instant('FACT.F11'), "Selected": false },
          { "Id": 'F14', "Name": this.translate.instant('FACT.F14'), "Selected": false },
          { "Id": 'F9', "Name": this.translate.instant('FACT.F9'), "Selected": false },
          { "Id": 'c_UnitsMarketShareInPercent', "Name": this.translate.instant('FACT.c_UnitsMarketShareInPercent'), "Selected": false },
          { "Id": 'F16', "Name": this.translate.instant('FACT.F16'), "Selected": false },
          { "Id": 'c_Intensness', "Name": this.translate.instant('FACT.c_Intensness'), "Selected": false },
          { "Id": 'c_LoyaltyIndex', "Name": this.translate.instant('FACT.c_LoyaltyIndex'), "Selected": false },
          { "Id": 'c_PropensityIndex', "Name": this.translate.instant('FACT.c_PropensityIndex'), "Selected": false },
          { "Id": 'c_ShareOnTotalOccasions', "Name": this.translate.instant('FACT.c_ShareOnTotalOccasions'), "Selected": false },
        ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_GAP'), "Selected": false ,
          Children: [
            { "Id": 'c_LoyaltyGapInPercent', "Name": this.translate.instant('FACT.c_LoyaltyGapInPercent'), "Selected": false },
            { "Id": 'c_LoyaltyGapIn000', "Name": this.translate.instant('FACT.c_LoyaltyGapIn000'), "Selected": false },
            { "Id": 'c_MarketShareGapInPercent', "Name": this.translate.instant('FACT.c_MarketShareGapInPercent'), "Selected": false },
            { "Id": 'c_MarketShareGapIn000', "Name": this.translate.instant('FACT.c_MarketShareGapIn000'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_PROMOTION'), "Selected": false ,
          Children: [
            { "Id": 'c_PromotionPenetrationInTotalMarket', "Name": this.translate.instant('FACT.c_PromotionPenetrationInTotalMarket'), "Selected": false },
            { "Id": 'TF7', "Name": this.translate.instant('FACT.TF7'), "Selected": false },
            { "Id": 'TF8', "Name": this.translate.instant('FACT.TF8'), "Selected": false },
            { "Id": 'c_PercentPromotionBuyersInTotalMarket', "Name": this.translate.instant('FACT.c_PercentPromotionBuyersInTotalMarket'), "Selected": false },
            { "Id": 'TF9', "Name": this.translate.instant('FACT.TF9'), "Selected": false },
            { "Id": 'c_PromotionExpendituresInTotalMarket000', "Name": this.translate.instant('FACT.c_PromotionExpendituresInTotalMarket000'), "Selected": false },
            { "Id": 'TF4', "Name": this.translate.instant('FACT.TF4'), "Selected": false },
            { "Id": 'c_PercentPromotionExpendituresInTotalMarket', "Name": this.translate.instant('FACT.c_PercentPromotionExpendituresInTotalMarket'), "Selected": false },
            { "Id": 'TF5', "Name": this.translate.instant('FACT.TF5'), "Selected": false },
            { "Id": 'TF6', "Name": this.translate.instant('FACT.TF6'), "Selected": false },
            { "Id": 'c_PromotionExpendituresInTotalMarketM', "Name": this.translate.instant('FACT.c_PromotionExpendituresInTotalMarketM'), "Selected": false },
            { "Id": 'TF13', "Name": this.translate.instant('FACT.TF13'), "Selected": false },
            { "Id": 'c_PromotionExpendituresOfAccountBuyersM', "Name": this.translate.instant('FACT.c_PromotionExpendituresOfAccountBuyersM'), "Selected": false },
            { "Id": 'TF10', "Name": this.translate.instant('FACT.TF10'), "Selected": false },
            { "Id": 'c_BasisExpendituresM', "Name": this.translate.instant('FACT.c_BasisExpendituresM'), "Selected": false },
            { "Id": 'TF12', "Name": this.translate.instant('FACT.TF12'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersPromotion'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersNonPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersNonPromotion'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersPromotionOnly', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersPromotionOnly'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersNonPromotionOnly', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersNonPromotionOnly'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyerPromotionAndNonPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyerPromotionAndNonPromotion'), "Selected": false },
            { "Id": 'c_PercentPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentNonPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentExclusivelyPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentExclusivelyPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentExclusivelyNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentExclusivelyNonPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentPromotionAndNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentPromotionAndNonPromotionAccountBuyers'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_ASSORTMENT'), "Selected": false ,
          Children: [
              { "Id": 'TF14', "Name": this.translate.instant('FACT.TF14'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_BASKET'), "Selected": false ,
          Children: [
              { "Id": 'F19', "Name": this.translate.instant('FACT.F19'), "Selected": false },
              { "Id": 'F17', "Name": this.translate.instant('FACT.F17'), "Selected": false },
              { "Id": 'F18', "Name": this.translate.instant('FACT.F18'), "Selected": false },
              { "Id": 'F20', "Name": this.translate.instant('FACT.F20'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_DECOMPOSITION'), "Selected": false ,
          Children: [
            { "Id": 'F22', "Name": this.translate.instant('FACT.F22'), "Selected": false },
            { "Id": 'c_UnitsM', "Name": this.translate.instant('FACT.c_UnitsM'), "Selected": false },
            { "Id": 'c_AveragePricePerUnit', "Name": this.translate.instant('FACT.c_AveragePricePerUnit'), "Selected": false },
            { "Id": 'c_UnitsPerAccountBuyer', "Name": this.translate.instant('FACT.c_UnitsPerAccountBuyer'), "Selected": false },
            { "Id": 'c_UnitsPerOccasion', "Name": this.translate.instant('FACT.c_UnitsPerOccasion'), "Selected": false },
            { "Id": 'F26', "Name": this.translate.instant('FACT.F26'), "Selected": false },
          ]
      }
    ];

    this.DATA_KPI_COMPARISON = [
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_BASIC'), "Selected": false,
          Children: [
              { "Id": 'F11', "Name": this.translate.instant('FACT.F11'), "Selected": false },
              { "Id": 'F10', "Name": this.translate.instant('FACT.F10'), "Selected": false },
              { "Id": 'F6', "Name": this.translate.instant('FACT.F6'), "Selected": false },
              { "Id": 'F7', "Name": this.translate.instant('FACT.F7'), "Selected": false },
              { "Id": 'F8', "Name": this.translate.instant('FACT.F8'), "Selected": false },
              { "Id": 'F9', "Name": this.translate.instant('FACT.F9'), "Selected": false },
              { "Id": 'F3', "Name": this.translate.instant('FACT.F3'), "Selected": false },
              { "Id": 'c_ShopperConversion', "Name": this.translate.instant('FACT.c_ShopperConversion'), "Selected": false },
              { "Id": 'c_NumberOfLostHouseholds', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholds'), "Selected": false },
              { "Id": 'c_NumberOfAccountClients', "Name": this.translate.instant('FACT.c_NumberOfAccountClients'), "Selected": false },
              { "Id": 'c_NumberOfAccountBuyers', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyers'), "Selected": false },
              { "Id": 'c_PenetrationOfShop', "Name": this.translate.instant('FACT.c_PenetrationOfShop'), "Selected": false },
              { "Id": 'F16', "Name": this.translate.instant('FACT.F16'), "Selected": false },
              { "Id": 'c_Occasions000', "Name": this.translate.instant('FACT.c_Occasions000'), "Selected": false },
              { "Id": 'F13', "Name": this.translate.instant('FACT.F13'), "Selected": false },
              { "Id": 'F12', "Name": this.translate.instant('FACT.F12'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_BUYERS_PERCENT'), "Selected": false ,
          Children: [
              { "Id": 'c_PenetrationInTotalMarket', "Name": this.translate.instant('FACT.c_PenetrationInTotalMarket'), "Selected": false },
              { "Id": 'c_PenetrationOfShop', "Name": this.translate.instant('FACT.c_PenetrationOfShop'), "Selected": false },
              { "Id": 'F1', "Name": this.translate.instant('FACT.F1'), "Selected": false },
              { "Id": 'F2', "Name": this.translate.instant('FACT.F2'), "Selected": false },
              { "Id": 'F3', "Name": this.translate.instant('FACT.F3'), "Selected": false },
              { "Id": 'c_ShopperConversion', "Name": this.translate.instant('FACT.c_ShopperConversion'), "Selected": false },
              { "Id": 'c_BuyerCoverageIndex', "Name": this.translate.instant('FACT.c_BuyerCoverageIndex'), "Selected": false },
              { "Id": 'c_ShopperConversionIndex', "Name": this.translate.instant('FACT.c_ShopperConversionIndex'), "Selected": false },
              { "Id": 'c_ComparativeCoverageIndex', "Name": this.translate.instant('FACT.c_ComparativeCoverageIndex'), "Selected": false },
              { "Id": 'F15', "Name": this.translate.instant('FACT.F15'), "Selected": false },
              { "Id": 'c_ComparativeCoverageInTotalMarket', "Name": this.translate.instant('FACT.c_ComparativeCoverageInTotalMarket'), "Selected": false },
              { "Id": 'c_ComparativeCoverageOfAccountBuyers', "Name": this.translate.instant('FACT.c_ComparativeCoverageOfAccountBuyers'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_NUMBER_BUYERS_M'), "Selected": false ,
          Children: [
            { "Id": 'c_NumberOfBuyersInTotalMarketM', "Name": this.translate.instant('FACT.c_NumberOfBuyersInTotalMarketM'), "Selected": false },
            { "Id": 'c_NumberOfBuyerOfShopM', "Name": this.translate.instant('FACT.c_NumberOfBuyerOfShopM'), "Selected": false },
            { "Id": 'c_NumberOfAccountClientsM', "Name": this.translate.instant('FACT.c_NumberOfAccountClientsM'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersM', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersM'), "Selected": false },
            { "Id": 'c_NumberOfLostHouseholdsM', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholdsM'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_NUMBER_BUYERS'), "Selected": false,
          Children: [
            { "Id": 'c_NumberOfBuyersInTotalMarket', "Name": this.translate.instant('FACT.c_NumberOfBuyersInTotalMarket'), "Selected": false },
            { "Id": 'c_NumberOfBuyerOfShop', "Name": this.translate.instant('FACT.c_NumberOfBuyerOfShop'), "Selected": false },
            { "Id": 'c_NumberOfAccountClients', "Name": this.translate.instant('FACT.c_NumberOfAccountClients'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyers', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyers'), "Selected": false },
            { "Id": 'c_NumberOfLostHouseholds', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholds'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_EXPENDITURES_M'), "Selected": false ,
          Children: [
            { "Id": 'c_TotalExpendituresM', "Name": this.translate.instant('FACT.c_TotalExpendituresM'), "Selected": false },
            { "Id": 'c_ExpendituresInTotalMarketM', "Name": this.translate.instant('FACT.c_ExpendituresInTotalMarketM'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountClientsM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountClientsM'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyersM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersM'), "Selected": false },
            { "Id": 'c_LostExpendituresM', "Name": this.translate.instant('FACT.c_LostExpendituresM'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyersInAllShopsM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersInAllShopsM'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_EXPENDITURES'), "Selected": false ,
          Children: [
            { "Id": 'c_TotalExpenditures000', "Name": this.translate.instant('FACT.c_TotalExpenditures000'), "Selected": false },
            { "Id": 'c_ExpendituresInTotalMarket000', "Name": this.translate.instant('FACT.c_ExpendituresInTotalMarket000'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountClients000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountClients000'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyers000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyers000'), "Selected": false },
            { "Id": 'c_LostExpenditures000', "Name": this.translate.instant('FACT.c_LostExpenditures000'), "Selected": false },
            { "Id": 'c_ExpendituresOfAccountBuyersInAllShops000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersInAllShops000'), "Selected": false },
            { "Id": 'c_ExpendituresPerAccountBuyersInAllShops', "Name": this.translate.instant('FACT.c_ExpendituresPerAccountBuyersInAllShops'), "Selected": false },
            { "Id": 'c_ExpenditurePerAccountClientsInAllShops', "Name": this.translate.instant('FACT.c_ExpenditurePerAccountClientsInAllShops'), "Selected": false },
            { "Id": 'c_ExpendituresPerBuyerInTotalMarket', "Name": this.translate.instant('FACT.c_ExpendituresPerBuyerInTotalMarket'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_SHOPPING'), "Selected": false ,
          Children: [
            { "Id": 'F5', "Name": this.translate.instant('FACT.F5'), "Selected": false },
            { "Id": 'F13', "Name": this.translate.instant('FACT.F13'), "Selected": false },
            { "Id": 'F12', "Name": this.translate.instant('FACT.F12'), "Selected": false },
            { "Id": 'c_Occasions000', "Name": this.translate.instant('FACT.c_Occasions000'), "Selected": false },
          ]
      },
      {
        "Id": '', "Name": this.translate.instant('FACT.F_R_PROPENSITY'), "Selected": false ,
        Children: [
          { "Id": 'F10', "Name": this.translate.instant('FACT.F10'), "Selected": false },
          { "Id": 'F11', "Name": this.translate.instant('FACT.F11'), "Selected": false },
          { "Id": 'F14', "Name": this.translate.instant('FACT.F14'), "Selected": false },
          { "Id": 'F9', "Name": this.translate.instant('FACT.F9'), "Selected": false },
          { "Id": 'c_UnitsMarketShareInPercent', "Name": this.translate.instant('FACT.c_UnitsMarketShareInPercent'), "Selected": false },
          { "Id": 'F16', "Name": this.translate.instant('FACT.F16'), "Selected": false },
          { "Id": 'c_Intensness', "Name": this.translate.instant('FACT.c_Intensness'), "Selected": false },
          { "Id": 'c_LoyaltyIndex', "Name": this.translate.instant('FACT.c_LoyaltyIndex'), "Selected": false },
          { "Id": 'c_PropensityIndex', "Name": this.translate.instant('FACT.c_PropensityIndex'), "Selected": false },
          { "Id": 'c_ShareOnTotalOccasions', "Name": this.translate.instant('FACT.c_ShareOnTotalOccasions'), "Selected": false },
        ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_GAP'), "Selected": false ,
          Children: [
            { "Id": 'c_LoyaltyGapInPercent', "Name": this.translate.instant('FACT.c_LoyaltyGapInPercent'), "Selected": false },
            { "Id": 'c_LoyaltyGapIn000', "Name": this.translate.instant('FACT.c_LoyaltyGapIn000'), "Selected": false },
            { "Id": 'c_MarketShareGapInPercent', "Name": this.translate.instant('FACT.c_MarketShareGapInPercent'), "Selected": false },
            { "Id": 'c_MarketShareGapIn000', "Name": this.translate.instant('FACT.c_MarketShareGapIn000'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_PROMOTION'), "Selected": false ,
          Children: [
            { "Id": 'c_PromotionPenetrationInTotalMarket', "Name": this.translate.instant('FACT.c_PromotionPenetrationInTotalMarket'), "Selected": false },
            { "Id": 'TF7', "Name": this.translate.instant('FACT.TF7'), "Selected": false },
            { "Id": 'TF8', "Name": this.translate.instant('FACT.TF8'), "Selected": false },
            { "Id": 'c_PercentPromotionBuyersInTotalMarket', "Name": this.translate.instant('FACT.c_PercentPromotionBuyersInTotalMarket'), "Selected": false },
            { "Id": 'TF9', "Name": this.translate.instant('FACT.TF9'), "Selected": false },
            { "Id": 'c_PromotionExpendituresInTotalMarket000', "Name": this.translate.instant('FACT.c_PromotionExpendituresInTotalMarket000'), "Selected": false },
            { "Id": 'TF4', "Name": this.translate.instant('FACT.TF4'), "Selected": false },
            { "Id": 'c_PercentPromotionExpendituresInTotalMarket', "Name": this.translate.instant('FACT.c_PercentPromotionExpendituresInTotalMarket'), "Selected": false },
            { "Id": 'TF5', "Name": this.translate.instant('FACT.TF5'), "Selected": false },
            { "Id": 'TF6', "Name": this.translate.instant('FACT.TF6'), "Selected": false },
            { "Id": 'c_PromotionExpendituresInTotalMarketM', "Name": this.translate.instant('FACT.c_PromotionExpendituresInTotalMarketM'), "Selected": false },
            { "Id": 'TF13', "Name": this.translate.instant('FACT.TF13'), "Selected": false },
            { "Id": 'c_PromotionExpendituresOfAccountBuyersM', "Name": this.translate.instant('FACT.c_PromotionExpendituresOfAccountBuyersM'), "Selected": false },
            { "Id": 'TF10', "Name": this.translate.instant('FACT.TF10'), "Selected": false },
            { "Id": 'c_BasisExpendituresM', "Name": this.translate.instant('FACT.c_BasisExpendituresM'), "Selected": false },
            { "Id": 'TF12', "Name": this.translate.instant('FACT.TF12'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersPromotion'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersNonPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersNonPromotion'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersPromotionOnly', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersPromotionOnly'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyersNonPromotionOnly', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersNonPromotionOnly'), "Selected": false },
            { "Id": 'c_NumberOfAccountBuyerPromotionAndNonPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyerPromotionAndNonPromotion'), "Selected": false },
            { "Id": 'c_PercentPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentNonPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentExclusivelyPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentExclusivelyPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentExclusivelyNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentExclusivelyNonPromotionAccountBuyers'), "Selected": false },
            { "Id": 'c_PercentPromotionAndNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentPromotionAndNonPromotionAccountBuyers'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_ASSORTMENT'), "Selected": false ,
          Children: [
              { "Id": 'TF14', "Name": this.translate.instant('FACT.TF14'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_BASKET'), "Selected": false ,
          Children: [
              { "Id": 'F19', "Name": this.translate.instant('FACT.F19'), "Selected": false },
              { "Id": 'F17', "Name": this.translate.instant('FACT.F17'), "Selected": false },
              { "Id": 'F18', "Name": this.translate.instant('FACT.F18'), "Selected": false },
              { "Id": 'F20', "Name": this.translate.instant('FACT.F20'), "Selected": false },
          ]
      },
      {
          "Id": '', "Name": this.translate.instant('FACT.F_R_DECOMPOSITION'), "Selected": false ,
          Children: [
            { "Id": 'F22', "Name": this.translate.instant('FACT.F22'), "Selected": false },
            { "Id": 'c_UnitsM', "Name": this.translate.instant('FACT.c_UnitsM'), "Selected": false },
            { "Id": 'c_AveragePricePerUnit', "Name": this.translate.instant('FACT.c_AveragePricePerUnit'), "Selected": false },
            { "Id": 'c_UnitsPerAccountBuyer', "Name": this.translate.instant('FACT.c_UnitsPerAccountBuyer'), "Selected": false },
            { "Id": 'c_UnitsPerOccasion', "Name": this.translate.instant('FACT.c_UnitsPerOccasion'), "Selected": false },
            { "Id": 'F26', "Name": this.translate.instant('FACT.F26'), "Selected": false },
          ]
      }
    ];

    this.DATA_TABLE_FACTS_LIST = [
      { "Id": 'F6', "Name": this.translate.instant('FACT.F6'), "Selected": false },
      { "Id": 'F7', "Name": this.translate.instant('FACT.F7'), "Selected": false },
      { "Id": 'F8', "Name": this.translate.instant('FACT.F8'), "Selected": false },
      { "Id": 'c_ShopperConversion', "Name": this.translate.instant('FACT.c_ShopperConversion'), "Selected": false },
      { "Id": 'c_PenetrationOfShop', "Name": this.translate.instant('FACT.c_PenetrationOfShop'), "Selected": false },
      { "Id": 'c_PenetrationInTotalMarket', "Name": this.translate.instant('FACT.c_PenetrationInTotalMarket'), "Selected": false },
      { "Id": 'F1', "Name": this.translate.instant('FACT.F1'), "Selected": false },
      { "Id": 'F2', "Name": this.translate.instant('FACT.F2'), "Selected": false },
      { "Id": 'F3', "Name": this.translate.instant('FACT.F3'), "Selected": false },
      { "Id": 'c_BuyerCoverageIndex', "Name": this.translate.instant('FACT.c_BuyerCoverageIndex'), "Selected": false },
      { "Id": 'c_ShopperConversionIndex', "Name": this.translate.instant('FACT.c_ShopperConversionIndex'), "Selected": false },
      { "Id": 'c_ComparativeCoverageIndex', "Name": this.translate.instant('FACT.c_ComparativeCoverageIndex'), "Selected": false },
      { "Id": 'F15', "Name": this.translate.instant('FACT.F15'), "Selected": false },
      { "Id": 'c_ComparativeCoverageInTotalMarket', "Name": this.translate.instant('FACT.c_ComparativeCoverageInTotalMarket'), "Selected": false },
      { "Id": 'c_ComparativeCoverageOfAccountBuyers', "Name": this.translate.instant('FACT.c_ComparativeCoverageOfAccountBuyers'), "Selected": false },
      { "Id": 'c_NumberOfBuyersInTotalMarketM', "Name": this.translate.instant('FACT.c_NumberOfBuyersInTotalMarketM'), "Selected": false },
      { "Id": 'c_NumberOfBuyerOfShopM', "Name": this.translate.instant('FACT.c_NumberOfBuyerOfShopM'), "Selected": false },
      { "Id": 'c_NumberOfAccountClientsM', "Name": this.translate.instant('FACT.c_NumberOfAccountClientsM'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyersM', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersM'), "Selected": false },
      { "Id": 'c_NumberOfLostHouseholdsM', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholdsM'), "Selected": false },
      { "Id": 'c_NumberOfBuyersInTotalMarket', "Name": this.translate.instant('FACT.c_NumberOfBuyersInTotalMarket'), "Selected": false },
      { "Id": 'c_NumberOfBuyerOfShop', "Name": this.translate.instant('FACT.c_NumberOfBuyerOfShop'), "Selected": false },
      { "Id": 'c_NumberOfAccountClients', "Name": this.translate.instant('FACT.c_NumberOfAccountClients'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyers', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyers'), "Selected": false },
      { "Id": 'c_NumberOfLostHouseholds', "Name": this.translate.instant('FACT.c_NumberOfLostHouseholds'), "Selected": false },
      { "Id": 'c_TotalExpendituresM', "Name": this.translate.instant('FACT.c_TotalExpendituresM'), "Selected": false },
      { "Id": 'c_ExpendituresInTotalMarketM', "Name": this.translate.instant('FACT.c_ExpendituresInTotalMarketM'), "Selected": false },
      { "Id": 'c_ExpendituresOfAccountClientsM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountClientsM'), "Selected": false },
      { "Id": 'c_ExpendituresOfAccountBuyersM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersM'), "Selected": false },
      { "Id": 'c_LostExpendituresM', "Name": this.translate.instant('FACT.c_LostExpendituresM'), "Selected": false },
      { "Id": 'c_ExpendituresOfAccountBuyersInAllShopsM', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersInAllShopsM'), "Selected": false },
      { "Id": 'c_TotalExpenditures000', "Name": this.translate.instant('FACT.c_TotalExpenditures000'), "Selected": false },
      { "Id": 'c_ExpendituresInTotalMarket000', "Name": this.translate.instant('FACT.c_ExpendituresInTotalMarket000'), "Selected": false },
      { "Id": 'c_ExpendituresOfAccountClients000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountClients000'), "Selected": false },
      { "Id": 'c_ExpendituresOfAccountBuyers000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyers000'), "Selected": false },
      { "Id": 'c_LostExpenditures000', "Name": this.translate.instant('FACT.c_LostExpenditures000'), "Selected": false },
      { "Id": 'c_ExpendituresOfAccountBuyersInAllShops000', "Name": this.translate.instant('FACT.c_ExpendituresOfAccountBuyersInAllShops000'), "Selected": false },
      { "Id": 'c_ExpendituresPerAccountBuyersInAllShops', "Name": this.translate.instant('FACT.c_ExpendituresPerAccountBuyersInAllShops'), "Selected": false },
      { "Id": 'c_ExpenditurePerAccountClientsInAllShops', "Name": this.translate.instant('FACT.c_ExpenditurePerAccountClientsInAllShops'), "Selected": false },
      { "Id": 'c_ExpendituresPerBuyerInTotalMarket', "Name": this.translate.instant('FACT.c_ExpendituresPerBuyerInTotalMarket'), "Selected": false },
      { "Id": 'F5', "Name": this.translate.instant('FACT.F5'), "Selected": false },
      { "Id": 'F13', "Name": this.translate.instant('FACT.F13'), "Selected": false },
      { "Id": 'F12', "Name": this.translate.instant('FACT.F12'), "Selected": false },
      { "Id": 'c_Occasions000', "Name": this.translate.instant('FACT.c_Occasions000'), "Selected": false },
      { "Id": 'F10', "Name": this.translate.instant('FACT.F10'), "Selected": false },
      { "Id": 'F11', "Name": this.translate.instant('FACT.F11'), "Selected": false },
      { "Id": 'F14', "Name": this.translate.instant('FACT.F14'), "Selected": false },
      { "Id": 'F9', "Name": this.translate.instant('FACT.F9'), "Selected": false },
      { "Id": 'c_UnitsMarketShareInPercent', "Name": this.translate.instant('FACT.c_UnitsMarketShareInPercent'), "Selected": false },
      { "Id": 'F16', "Name": this.translate.instant('FACT.F16'), "Selected": false },
      { "Id": 'c_Intensness', "Name": this.translate.instant('FACT.c_Intensness'), "Selected": false },
      { "Id": 'c_LoyaltyIndex', "Name": this.translate.instant('FACT.c_LoyaltyIndex'), "Selected": false },
      { "Id": 'c_PropensityIndex', "Name": this.translate.instant('FACT.c_PropensityIndex'), "Selected": false },
      { "Id": 'c_ShareOnTotalOccasions', "Name": this.translate.instant('FACT.c_ShareOnTotalOccasions'), "Selected": false },
      { "Id": 'c_LoyaltyGapInPercent', "Name": this.translate.instant('FACT.c_LoyaltyGapInPercent'), "Selected": false },
      { "Id": 'c_LoyaltyGapIn000', "Name": this.translate.instant('FACT.c_LoyaltyGapIn000'), "Selected": false },
      { "Id": 'c_MarketShareGapInPercent', "Name": this.translate.instant('FACT.c_MarketShareGapInPercent'), "Selected": false },
      { "Id": 'c_MarketShareGapIn000', "Name": this.translate.instant('FACT.c_MarketShareGapIn000'), "Selected": false },
      { "Id": 'c_PromotionPenetrationInTotalMarket', "Name": this.translate.instant('FACT.c_PromotionPenetrationInTotalMarket'), "Selected": false },
      { "Id": 'TF7', "Name": this.translate.instant('FACT.TF7'), "Selected": false },
      { "Id": 'TF8', "Name": this.translate.instant('FACT.TF8'), "Selected": false },
      { "Id": 'c_PercentPromotionBuyersInTotalMarket', "Name": this.translate.instant('FACT.c_PercentPromotionBuyersInTotalMarket'), "Selected": false },
      { "Id": 'TF9', "Name": this.translate.instant('FACT.TF9'), "Selected": false },
      { "Id": 'c_PromotionExpendituresInTotalMarket000', "Name": this.translate.instant('FACT.c_PromotionExpendituresInTotalMarket000'), "Selected": false },
      { "Id": 'TF4', "Name": this.translate.instant('FACT.TF4'), "Selected": false },
      { "Id": 'c_PercentPromotionExpendituresInTotalMarket', "Name": this.translate.instant('FACT.c_PercentPromotionExpendituresInTotalMarket'), "Selected": false },
      { "Id": 'TF5', "Name": this.translate.instant('FACT.TF5'), "Selected": false },
      { "Id": 'TF6', "Name": this.translate.instant('FACT.TF6'), "Selected": false },
      { "Id": 'c_PromotionExpendituresInTotalMarketM', "Name": this.translate.instant('FACT.c_PromotionExpendituresInTotalMarketM'), "Selected": false },
      { "Id": 'TF13', "Name": this.translate.instant('FACT.TF13'), "Selected": false },
      { "Id": 'c_PromotionExpendituresOfAccountBuyersM', "Name": this.translate.instant('FACT.c_PromotionExpendituresOfAccountBuyersM'), "Selected": false },
      { "Id": 'TF10', "Name": this.translate.instant('FACT.TF10'), "Selected": false },
      { "Id": 'c_BasisExpendituresM', "Name": this.translate.instant('FACT.c_BasisExpendituresM'), "Selected": false },
      { "Id": 'TF12', "Name": this.translate.instant('FACT.TF12'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyersPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersPromotion'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyersNonPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersNonPromotion'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyersPromotionOnly', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersPromotionOnly'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyersNonPromotionOnly', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyersNonPromotionOnly'), "Selected": false },
      { "Id": 'c_NumberOfAccountBuyerPromotionAndNonPromotion', "Name": this.translate.instant('FACT.c_NumberOfAccountBuyerPromotionAndNonPromotion'), "Selected": false },
      { "Id": 'c_PercentPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentPromotionAccountBuyers'), "Selected": false },
      { "Id": 'c_PercentNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentNonPromotionAccountBuyers'), "Selected": false },
      { "Id": 'c_PercentExclusivelyPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentExclusivelyPromotionAccountBuyers'), "Selected": false },
      { "Id": 'c_PercentExclusivelyNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentExclusivelyNonPromotionAccountBuyers'), "Selected": false },
      { "Id": 'c_PercentPromotionAndNonPromotionAccountBuyers', "Name": this.translate.instant('FACT.c_PercentPromotionAndNonPromotionAccountBuyers'), "Selected": false },
      { "Id": 'TF14', "Name": this.translate.instant('FACT.TF14'), "Selected": false },
      { "Id": 'F19', "Name": this.translate.instant('FACT.F19'), "Selected": false },
      { "Id": 'F17', "Name": this.translate.instant('FACT.F17'), "Selected": false },
      { "Id": 'F18', "Name": this.translate.instant('FACT.F18'), "Selected": false },
      { "Id": 'F20', "Name": this.translate.instant('FACT.F20'), "Selected": false },
      { "Id": 'F22', "Name": this.translate.instant('FACT.F22'), "Selected": false },
      { "Id": 'c_UnitsM', "Name": this.translate.instant('FACT.c_UnitsM'), "Selected": false },
      { "Id": 'c_AveragePricePerUnit', "Name": this.translate.instant('FACT.c_AveragePricePerUnit'), "Selected": false },
      { "Id": 'c_UnitsPerAccountBuyer', "Name": this.translate.instant('FACT.c_UnitsPerAccountBuyer'), "Selected": false },
      { "Id": 'c_UnitsPerOccasion', "Name": this.translate.instant('FACT.c_UnitsPerOccasion'), "Selected": false },
      { "Id": 'F26', "Name": this.translate.instant('FACT.F26'), "Selected": false },
    ];

    this.ROW = this.translate.instant('COMMON.ROW')
    this.COLUMN = this.translate.instant('COMMON.COLUMN')
    this.MAT_MODE = this.translate.instant('COMMON.MAT_MODE')
    this.MAT_MODE_ITEMS = [
      {Id:MatModeId.MAT_both, Name:this.translate.instant('COMMON.MAT_BOTH')},
      {Id:MatModeId.MAT_current_percent, Name:this.translate.instant('COMMON.MAT_CURRENT_PERCENT')},
      {Id:MatModeId.MAT_current_abs, Name:this.translate.instant('COMMON.MAT_CURRENT_ABSOLUTE')},
    ]
    this.COLUMN_NUMBER = this.translate.instant('COMMON.COLUMN_NUMBER')

    this.VALUE_CURRENT = this.translate.instant('COMMON.VALUE_CURRENT')
    this.VALUE_PREVIOUS = this.translate.instant('COMMON.VALUE_PREVIOUS')
    this.VALUE_DIFF_ABSOLUTE = this.translate.instant('COMMON.VALUE_DIFF_ABSOLUTE')
    this.VALUE_DIFF_PERCENT = this.translate.instant('COMMON.VALUE_DIFF_PERCENT')

    this.NO_BUBBLE_SIZE_FACT = {Id: "None", Name: this.translate.instant('COMMON.NO_BUBBLE_SIZE_FACT')}

    this.CONTRIBUTION_TO_CHANGE = this.translate.instant('COMMON.CONTRIBUTION_TO_CHANGE')

    this.EXPENDITURES_ACCOUNT_BUYERS_M = this.translate.instant('COMMON.EXPENDITURES_ACCOUNT_BUYERS_M')
    this.UNITS_M = this.translate.instant('COMMON.UNITS_M')
    this.AVERAGE_PRICE_UNIT = this.translate.instant('COMMON.AVERAGE_PRICE_UNIT')
    this.UNITS_ACCOUNT_BUYER = this.translate.instant('COMMON.UNITS_ACCOUNT_BUYER')
    this.NUMBER_ACCOUNT_BUYERS_M = this.translate.instant('COMMON.NUMBER_ACCOUNT_BUYERS_M')
    this.PURCHASE_FREQUENCY = this.translate.instant('COMMON.PURCHASE_FREQUENCY')
    this.UNITS_OCCASION = this.translate.instant('COMMON.UNITS_OCCASION')
    this.PENETRATION_ACCOUNT_BUYER = this.translate.instant('COMMON.PENETRATION_ACCOUNT_BUYER')
    this.NUMBER_HOUSEHOLDS_TOTAL_MARKET_M = this.translate.instant('COMMON.NUMBER_HOUSEHOLDS_TOTAL_MARKET_M')
    this.NUMBER_HOUSEHOLDS = this.translate.instant('COMMON.NUMBER_HOUSEHOLDS')

    this.DATABASE_LANGUANGE = this.translate.instant('COMMON.DATABASE_LANGUANGE')
    this.PERSONAL_DATA = this.translate.instant('COMMON.PERSONAL_DATA')
    this.PROJECT = this.translate.instant('COMMON.PROJECT')

    this.FIRSTNAME = this.translate.instant('COMMON.FIRSTNAME')
    this.LASTNAME = this.translate.instant('COMMON.LASTNAME')

    this.CHANGE_SAVED = this.translate.instant('COMMON.CHANGE_SAVED')
    this.CHANGE_NOT_SAVED = this.translate.instant('COMMON.CHANGE_NOT_SAVED')

    this.BASIC = this.translate.instant('COMMON.BASIC')
    this.FIRST_DEFAULT_COLOR = this.translate.instant('COMMON.FIRST_DEFAULT_COLOR')
    this.SECOND_DEFAULT_COLOR = this.translate.instant('COMMON.SECOND_DEFAULT_COLOR')

    this.CHART_MASTER = this.translate.instant('COMMON.CHART_MASTER')
    this.POWERPOINT = this.translate.instant('COMMON.POWERPOINT')
    this.SELECT_CHART_MASTER = this.translate.instant('COMMON.SELECT_CHART_MASTER')
    this.CREATE_CHART_MASTER = this.translate.instant('COMMON.CREATE_CHART_MASTER')

    this.CHART_MASTER_ADDED = this.translate.instant('COMMON.CHART_MASTER_ADDED')
    this.CHART_MASTER_EXIST = this.translate.instant('COMMON.CHART_MASTER_EXIST')
    this.CHART_MASTER_SAVED = this.translate.instant('COMMON.CHART_MASTER_SAVED')
    this.CHART_MASTER_ADD_TIP = this.translate.instant('COMMON.CHART_MASTER_ADD_TIP')
    this.DELETE_CHART_MASTER = this.translate.instant('COMMON.DELETE_CHART_MASTER')
    this.DELETE_CHART_MASTER_CONFIRMATION = this.translate.instant('COMMON.DELETE_CHART_MASTER_CONFIRMATION')

    this.POWERPOINT_SETTING = this.translate.instant('COMMON.POWERPOINT_SETTING')
    this.CREATE_PPT_TEMPLATE = this.translate.instant('COMMON.CREATE_PPT_TEMPLATE')

  }
}

