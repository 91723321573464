import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { IgxBarSeriesComponent, IgxCategoryYAxisComponent, IgxNumericXAxisComponent } from 'igniteui-angular-charts';

import { DashboardAppState } from '../../../shared/state/dashboard.reducer';
import { ChartService } from 'src/app/dashboard/shared/services/chart.service';
import { ConfigService } from 'src/app/dashboard/shared/services/config.service';
import { RESTService } from '../../../shared/services/rest.service';
import { ExportService } from '../../../shared/services/export.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { BaseComponent } from '../../../base/base.component';
import { fromDashboard } from '../../../shared/state/dashboard.selector';
import { Columns, CallAPIModes, NoFilterSelected, SelectNumberOfColumnsData, UserSelectionIds, Ids, MatModeId, DashboardId, PPTIds } from '../../../shared/model/constants';
import { SelectedTreeNode, SelectItem } from 'src/app/dashboard/shared/model/interfaces';
import { FilterTabsKpiComparisionComponent } from 'src/app/dashboard/shared/ui/components/filter-tabs-kpi-comparision/filter-tabs-kpi-comparision.component';
import { DashboardActions } from 'src/app/dashboard/shared/state/dashboard.actions';
import { ChartMasterService } from 'src/app/dashboard/shared/services/chart-master.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from 'src/app/dashboard/shared/services/constant.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-kpi-comparison',
  templateUrl: './kpi-comparison.component.html',
  styleUrls: ['./kpi-comparison.component.scss']
})
export class KpiComparisonComponent extends BaseComponent implements AfterViewInit{
  /** -------------------- Inputs for Base -------------------- */
  sourceTable = "NTP_Data"

  callAPIMode = CallAPIModes.FilterAPIWithTotal    // default is the get api, because default is Table. For the pivot 1 and pivot 2, we need the FilterAPI and call a different api
  treeAccountIsMulti = true     // not relevant for the UI, but relevant for the logic in base component
  callBackendToGetAccountAndAllProducts = true // get account from the store
  treeProductIsMulti = true     // not relevant for the UI, but relevant for the logic in base component
  callBackendToGetProductAndAllAccounts = true  // still get all the accounts when selecting a product

  // Dashboard Title
  dashboardCategoryTitle = this.cs.REPORTS
  dashboardId = DashboardId.kpi_comparison
  dashboards = this.cs.REPORTS_DASHBOARDS
  dashboardTitle = this.dashboardCategoryTitle +  " - " + this.dashboards.filter(db => db.Id === this.dashboardId)[0].Name

  // call backend
  getHHDataFromBackend = true

  // Facts used in dashboard
  factsBasic = ["F11", "F10", "F6", "F7", "F8", "F9", "F3", "F1", "F2", "F16", "F5", "F13", "F12"]
  factsBasicCalculated = ["c_ShopperConversion", "c_NumberOfLostHouseholds", "c_NumberOfAccountClients", "c_NumberOfAccountBuyers", "c_PenetrationOfShop", "c_Occasions000"]

  factsBuyer = ["F15"]
  factsBuyerCalculated = ["c_PenetrationInTotalMarket", "c_BuyerCoverageIndex", "c_ShopperConversionIndex", "c_ComparativeCoverageOfAccountBuyers", "c_ComparativeCoverageInTotalMarket", "c_ComparativeCoverageIndex"]

  factsNumberOfBuyers = []
  factsNumberOfBuyersCalculated = ["c_NumberOfBuyersInTotalMarket", "c_NumberOfBuyerOfShop"]    //c_NumberOfAccountClients, c_NumberOfAccountBuyers, c_NumberOfLostHouseholds already exist

  factsNumberOfBuyersM = []
  factsNumberOfBuyersMCalculated = ["c_NumberOfBuyersInTotalMarketM", "c_NumberOfBuyerOfShopM", "c_NumberOfAccountClientsM", "c_NumberOfAccountBuyersM", "c_NumberOfLostHouseholdsM"]

  factsExpenditures = ["F14"]
  factsExpendituresCalculated = ["c_TotalExpenditures000", "c_ExpendituresInTotalMarket000", "c_ExpendituresOfAccountClients000", "c_ExpendituresOfAccountBuyers000", "c_LostExpenditures000", "c_ExpendituresOfAccountBuyersInAllShops000", "c_ExpendituresPerAccountBuyersInAllShops", "c_ExpenditurePerAccountClientsInAllShops", "c_ExpendituresPerBuyerInTotalMarket"]

  factsExpendituresM = []
  factsExpendituresMCalculated = ["c_TotalExpendituresM", "c_ExpendituresInTotalMarketM", "c_ExpendituresOfAccountClientsM", "c_ExpendituresOfAccountBuyersM", "c_LostExpendituresM", "c_ExpendituresOfAccountBuyersInAllShopsM"]

  factsShopping = []
  factsShoppingCalculated = []    //c_Occasions000 already exists

  factsPropensity = []
  factsPropensityCalculated = ["c_UnitsMarketShareInPercent", "c_Intensness", "c_LoyaltyIndex", "c_PropensityIndex", "c_ShareOnTotalOccasions"]

  factsGap = []
  factsGapCalculated = ["c_LoyaltyGapInPercent", "c_LoyaltyGapIn000", "c_MarketShareGapInPercent", "c_MarketShareGapIn000"]

  factsPromotion = ["TF7", "TF8", "TF4", "TF9", "TF5", "TF6", "TF13", "TF10", "TF12", "TF3"]
  factsPromotionCalculated = ["c_PromotionPenetrationInTotalMarket", "c_PercentPromotionBuyersInTotalMarket", "c_PromotionExpendituresInTotalMarket000",
  "c_PercentPromotionExpendituresInTotalMarket", "c_PromotionExpendituresInTotalMarketM", "c_PromotionExpendituresOfAccountBuyersM", "c_BasisExpendituresM",
  "c_NumberOfAccountBuyersPromotion", "c_NumberOfAccountBuyersNonPromotion", "c_NumberOfAccountBuyersPromotionOnly", "c_NumberOfAccountBuyersNonPromotionOnly", "c_NumberOfAccountBuyerPromotionAndNonPromotion", "c_PercentPromotionAccountBuyers",
  "c_PercentNonPromotionAccountBuyers", "c_PercentExclusivelyPromotionAccountBuyers", "c_PercentExclusivelyNonPromotionAccountBuyers", "c_PercentPromotionAndNonPromotionAccountBuyers"
  ]

  factsAssortment = ["TF14"]
  factsAssortmentCalculated = []

  factsBasket = ["F19", "F17", "F18", "F20"]
  factsBasketCalculated = []

  factsDecompostion = ["F22", "F26"]
  factsDecompostionCalculated = ["c_UnitsM", "c_AveragePricePerUnit", "c_UnitsPerAccountBuyer", "c_UnitsPerOccasion"]

  allowedChartFactIds = [...new Set(
                          this.factsBasic.concat(this.factsBuyer).concat(this.factsNumberOfBuyers).concat(this.factsNumberOfBuyersM).concat(this.factsExpenditures)
                          .concat(this.factsExpendituresM).concat(this.factsShopping).concat(this.factsGap).concat(this.factsPromotion).concat(this.factsAssortment)
                          .concat(this.factsBasket).concat(this.factsDecompostion).concat([this.penetrationFactId])
                       )]

  hasPeriod2Filter = true
  filterPeriod1Title = this.cs.CURRENT_PERIOD
  filterPeriod2Title = this.cs.PERVIOUS_PERIOD

  /** -------------------- Inputs for this dashboard -------------------- */
  public chartDataRaw: any[] = []
  public chartHeadings:string[] = []
  private exportData: any[] = []

  private filterValueIds: string[] = []
  private selectedColumnNames: string[] = []


  /** Filter Tree Facts */
  public treeFactName = this.cs.FACT
  public treeFactData: any[] = []
  public selectedFactNodes: SelectedTreeNode[] = []
  public getSelectedFactNodes = (event:any) => {
    let nodes:any[] = event.selectedNodes
    let nodesWithoutTopLevel: any[] = nodes.filter(n=> n.Level !== 0)
    this.selectedFactNodes = [... new Map(nodesWithoutTopLevel.map(i => [i["Id"], i])).values()]  // unique the nodes based on its Id
    // console.log("table of facts | event.selectedNodes: ", this.selectedFactNodes)
    this.filterValuesFactData = this.selectedFactNodes
    // console.log("this.filterValuesFactData: ", this.filterValuesFactData)

    if(this.filterValuesFactData.length === 0) {
      this.filterValuesFactData = [NoFilterSelected]
      this.selectedFilterFactItem = NoFilterSelected
    } else {
      if(!this.filterValuesFactData.map(i=>i.Id).includes(this.selectedFilterFactItem.Id)) {
        this.selectedFilterFactItem = this.filterValuesFactData[0]
      }
    }

    this.feedChartWithData()
  }

  /** chip table bar */
  public showFilterValueAccount: boolean = false
  public showFilterValueProduct: boolean = false
  public showFilterValueFact: boolean = false
  public showFilterValueShopperGroup: boolean = false

  /** Select Row, Column */
  public selectRowTitle = this.cs.ROW
  public selectColumnTitle = this.cs.COLUMN
  public selectMatModeTitle = this.cs.MAT_MODE
  public select_items: any[] = [
    {Id:Columns.Account, Name:this.cs.ACCOUNT},
    {Id:Columns.Product, Name:this.cs.PRODUCT},
    {Id:Columns.Fact, Name:this.cs.FACT},
    {Id:Columns.ShopperGroup, Name:this.cs.SHOPPER_GROUP},
  ];
  public selectedRowItem:SelectItem = {Id:Columns.Account, Name:this.cs.ACCOUNT}  // have to init it with some value
  public selectedColumnItem:SelectItem = {Id:Columns.Product, Name:this.cs.PRODUCT} // have to init it with some value

  public getSelectedRowItem = (event: any) => {
    this.selectedRowItem = event.selectedItem
    // console.log("kpi-comparison | getSelectedRowItem: ", this.selectedRowItem)
    if(this.selectedRowItem.Id === this.selectedColumnItem.Id) {
      this.selectedColumnItem = this.select_items.filter(i => i.Id !== this.selectedColumnItem.Id)[0]
    }
    this.disableFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)
    this.showChipBarFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)
    this.updateCharts()
  }

  public getSelectedColumnItem = (event: any) => {
    this.selectedColumnItem = event.selectedItem
    // console.log("kpi-comparison | getSelectedColumnItem: ", this.selectedColumnItem)
    if(this.selectedColumnItem.Id === this.selectedRowItem.Id) {
      this.selectedRowItem = this.select_items.filter(i => i.Id !== this.selectedRowItem.Id)[0]
    }
    this.disableFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)
    this.showChipBarFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)
    this.updateCharts()
  }

  private disableFilterValues(rowId: string, columnId: string) {
    // console.log("disableFilterValues: ", rowId, columnId)
    this.filterTabsKpiComparisionComponent.disableFilterValueSelect(rowId, true)
    this.filterTabsKpiComparisionComponent.disableFilterValueSelect(columnId, true)
    this.filterValueIds = [Columns.Account, Columns.Product, Columns.Fact, Columns.ShopperGroup].filter(i => ![rowId, columnId].includes(i))
    for(let fid of this.filterValueIds) {
      this.filterTabsKpiComparisionComponent.disableFilterValueSelect(fid, false)
    }
  }

  private showChipBarFilterValues(rowId: string, columnId: string) {
    // console.log("showChipBarFilterValues: ", rowId, columnId)
    let usedIdArray = [rowId, columnId]

    if(usedIdArray.includes(Columns.Account)) {
      this.showFilterValueAccount = false
    } else {
      this.showFilterValueAccount = true
    }

    if(usedIdArray.includes(Columns.Product)) {
      this.showFilterValueProduct = false
    } else {
      this.showFilterValueProduct = true
    }

    if(usedIdArray.includes(Columns.Fact)) {
      this.showFilterValueFact = false
    } else {
      this.showFilterValueFact = true
    }

    if(usedIdArray.includes(Columns.ShopperGroup)) {
      this.showFilterValueShopperGroup = false
    } else {
      this.showFilterValueShopperGroup = true
    }

    // console.log("this.showFilterValueAccount: ", this.showFilterValueAccount)
  }

  /** Select MatMode Item */
  public MatMode_items: any[] = this.cs.MAT_MODE_ITEMS
  public selectedMatModeItem:SelectItem = {Id:"", Name:""}

  public getSelectedMatModeItem = (event: any) => {
    this.selectedMatModeItem = event.selectedItem
    // console.log("kpi-comparison | selectedMatModeItem: ", this.selectedMatModeItem)

    this.updateCharts()
    this.GenerateMarker()
  }


  /** Number of Columns */
  public selectFilterNumberOfColumnsTitle = this.cs.COLUMN_NUMBER
  public filterValuesNumberOfColumnsData: any[] = SelectNumberOfColumnsData
  public selectedFilterNumberOfColumnsItem: any = SelectNumberOfColumnsData[4]  // init the value with 5
  public getSelectedFilterNumberOfColumnsItem = (event: any) => {
    // selection Benchmark is changed
    this.selectedFilterNumberOfColumnsItem = event.selectedItem

    // console.log("this.selectedFilterNumberOfColumnsItem: ", this.selectedFilterNumberOfColumnsItem)

    this.changeDetector.detectChanges();

    if(this.CHART_KPI_1_BarSeries1 !== undefined) this.CHART_KPI_1_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_2_BarSeries1 !== undefined) this.CHART_KPI_2_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_3_BarSeries1 !== undefined) this.CHART_KPI_3_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_4_BarSeries1 !== undefined) this.CHART_KPI_4_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_5_BarSeries1 !== undefined) this.CHART_KPI_5_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_6_BarSeries1 !== undefined) this.CHART_KPI_6_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_7_BarSeries1 !== undefined) this.CHART_KPI_7_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_8_BarSeries1 !== undefined) this.CHART_KPI_8_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_9_BarSeries1 !== undefined) this.CHART_KPI_9_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)
    if(this.CHART_KPI_10_BarSeries1 !== undefined) this.CHART_KPI_10_BarSeries1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning)

    if(this.CHART_KPI_1_BarSeries2 !== undefined) this.CHART_KPI_1_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_2_BarSeries2 !== undefined) this.CHART_KPI_2_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_3_BarSeries2 !== undefined) this.CHART_KPI_3_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_4_BarSeries2 !== undefined) this.CHART_KPI_4_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_5_BarSeries2 !== undefined) this.CHART_KPI_5_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_6_BarSeries2 !== undefined) this.CHART_KPI_6_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_7_BarSeries2 !== undefined) this.CHART_KPI_7_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_8_BarSeries2 !== undefined) this.CHART_KPI_8_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_9_BarSeries2 !== undefined) this.CHART_KPI_9_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
    if(this.CHART_KPI_10_BarSeries2 !== undefined) this.CHART_KPI_10_BarSeries2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)


    this.updateCharts()
  }


  /** Filter Values */
  @ViewChild(FilterTabsKpiComparisionComponent)
  filterTabsKpiComparisionComponent!: FilterTabsKpiComparisionComponent

  public getSelectedFilterAccountItem = (event: any) => {
    // selection Benchmark is changed
    this.selectedFilterAccountItem = event.selectedItem

    // console.log("this.selectedFilterAccountItem: ", this.selectedFilterAccountItem)
    this.updateCharts()
  }

  public getSelectedFilterProductItem = (event: any) => {
    this.selectedFilterProductItem = event.selectedItem

    // console.log("this.selectedFilterProductItem: ", this.selectedFilterProductItem)
    this.updateCharts()
  }

  public getSelectedFilterFactItem = (event: any) => {
    this.selectedFilterFactItem = event.selectedItem

    // console.log("this.selectedFilterFactItem: ", this.selectedFilterFactItem)
    this.updateCharts()
  }

  public getSelectedFilterShopperGroupItem = (event: any) => {
    this.selectedFilterShopperGroupItem = event.selectedItem

    // console.log("this.selectedFilterShopperGroupItem: ", this.selectedFilterShopperGroupItem)
    this.updateCharts()
  }

  /** markers */


  @ViewChild('CHART_KPI_1_BarSeries1')
  public CHART_KPI_1_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_1_BarSeries2')
  public CHART_KPI_1_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_1_yAxis')
  public CHART_KPI_1_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_1_xAxis')
  public CHART_KPI_1_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_2_BarSeries1')
  public CHART_KPI_2_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_2_BarSeries2')
  public CHART_KPI_2_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_2_yAxis')
  public CHART_KPI_2_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_2_xAxis')
  public CHART_KPI_2_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_3_BarSeries1')
  public CHART_KPI_3_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_3_BarSeries2')
  public CHART_KPI_3_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_3_yAxis')
  public CHART_KPI_3_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_3_xAxis')
  public CHART_KPI_3_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_4_BarSeries1')
  public CHART_KPI_4_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_4_BarSeries2')
  public CHART_KPI_4_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_4_yAxis')
  public CHART_KPI_4_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_4_xAxis')
  public CHART_KPI_4_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_5_BarSeries1')
  public CHART_KPI_5_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_5_BarSeries2')
  public CHART_KPI_5_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_5_yAxis')
  public CHART_KPI_5_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_5_xAxis')
  public CHART_KPI_5_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_6_BarSeries1')
  public CHART_KPI_6_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_6_BarSeries2')
  public CHART_KPI_6_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_6_yAxis')
  public CHART_KPI_6_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_6_xAxis')
  public CHART_KPI_6_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_7_BarSeries1')
  public CHART_KPI_7_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_7_BarSeries2')
  public CHART_KPI_7_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_7_yAxis')
  public CHART_KPI_7_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_7_xAxis')
  public CHART_KPI_7_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_8_BarSeries1')
  public CHART_KPI_8_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_8_BarSeries2')
  public CHART_KPI_8_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_8_yAxis')
  public CHART_KPI_8_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_8_xAxis')
  public CHART_KPI_8_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_9_BarSeries1')
  public CHART_KPI_9_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_9_BarSeries2')
  public CHART_KPI_9_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_9_yAxis')
  public CHART_KPI_9_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_9_xAxis')
  public CHART_KPI_9_xAxis!: IgxNumericXAxisComponent;

  @ViewChild('CHART_KPI_10_BarSeries1')
  public CHART_KPI_10_BarSeries1!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_10_BarSeries2')
  public CHART_KPI_10_BarSeries2!: IgxBarSeriesComponent;
  @ViewChild('CHART_KPI_10_yAxis')
  public CHART_KPI_10_yAxis!: IgxCategoryYAxisComponent;
  @ViewChild('CHART_KPI_10_xAxis')
  public CHART_KPI_10_xAxis!: IgxNumericXAxisComponent;

  private listBarSeries1: IgxBarSeriesComponent[]  = []
  private listBarSeries2: IgxBarSeriesComponent[]  = []
  private listXAxis: IgxNumericXAxisComponent[] = []
  private listYAxis: IgxCategoryYAxisComponent[] = []



  constructor(
    public store: Store<DashboardAppState>,
    public chartService: ChartService,
    public configService: ConfigService,
    public exportService: ExportService,
    public restService: RESTService,
    public utilityService: UtilityService,
    public changeDetector: ChangeDetectorRef,
    public chartMasterService:ChartMasterService,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    @Inject(ElementRef)el: ElementRef,
  ) {
    super(store, chartService, configService, exportService, restService, utilityService, changeDetector, chartMasterService, translate, cs, authService)

    for(let i = 1; i <= this.selectedFilterNumberOfColumnsItem.Id; i++) {
      this.chartHeadings[i] = "";
    }
  }

  setToDefaultIndividual() {
    this.showChipBarFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)
  }

  ngAfterViewInit(): void {
    this.disableFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)
    // this.showChipBarFilterValues(this.selectedRowItem.Id, this.selectedColumnItem.Id)

    this.listBarSeries1 = [this.CHART_KPI_1_BarSeries1, this.CHART_KPI_2_BarSeries1, this.CHART_KPI_3_BarSeries1, this.CHART_KPI_4_BarSeries1, this.CHART_KPI_5_BarSeries1, this.CHART_KPI_6_BarSeries1, this.CHART_KPI_7_BarSeries1, this.CHART_KPI_8_BarSeries1, this.CHART_KPI_9_BarSeries1, this.CHART_KPI_10_BarSeries1]
    this.listBarSeries2 = [this.CHART_KPI_1_BarSeries2, this.CHART_KPI_2_BarSeries2, this.CHART_KPI_3_BarSeries2, this.CHART_KPI_4_BarSeries2, this.CHART_KPI_5_BarSeries2, this.CHART_KPI_6_BarSeries2, this.CHART_KPI_7_BarSeries2, this.CHART_KPI_8_BarSeries2, this.CHART_KPI_9_BarSeries2, this.CHART_KPI_10_BarSeries2]
    this.listXAxis = [this.CHART_KPI_1_xAxis, this.CHART_KPI_2_xAxis, this.CHART_KPI_3_xAxis, this.CHART_KPI_4_xAxis, this.CHART_KPI_5_xAxis, this.CHART_KPI_6_xAxis, this.CHART_KPI_7_xAxis, this.CHART_KPI_8_xAxis, this.CHART_KPI_9_xAxis, this.CHART_KPI_10_xAxis]
    this.listYAxis = [this.CHART_KPI_1_yAxis, this.CHART_KPI_2_yAxis, this.CHART_KPI_3_yAxis, this.CHART_KPI_4_yAxis, this.CHART_KPI_5_yAxis, this.CHART_KPI_6_yAxis, this.CHART_KPI_7_yAxis, this.CHART_KPI_8_yAxis, this.CHART_KPI_9_yAxis, this.CHART_KPI_10_yAxis]
  }

  public displayDataLabel = (event:any) => {
    this.showDataLabel = event.dataLabel.show

    for(let s1 of this.listBarSeries1) {
      if(s1 !== undefined) {s1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning);}
    }

    for(let s2 of this.listBarSeries2) {
      if(s2 !== undefined) {s2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning);}
    }

  }

  individualLogicForPeriod2() {
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.period2IsSelected = false
      this.selectedMatModeItem = this.cs.MAT_MODE_ITEMS[0] // once period2 is not selected, set the matmode to Mat-both, otherwise, it shows nan value for period2 in ()
    } else {
      this.period2IsSelected = true
    }

    this.GenerateMarker()
  }

  feedChartWithData(): void {
    this.isFilterDataLoaded$.pipe(first(isLoaded => isLoaded === true), untilDestroyed(this)).subscribe(r => {
      this.store
      .select<any[]>(fromDashboard.selectFilterDataWithFiltersWithoutFacts([... new Set(this.totalAccount.concat(this.selectedAccountNodes.map(i => i.Id)))], [... new Set(this.totalProduct.concat(this.selectedProductNodes.map(i => i.Id)))], this.getPeriodIds(), [this.selectedShopperGroupItem.Id]))
      .pipe(first(), untilDestroyed(this))
      .subscribe( data => {
          this.hasPenetrationWarning = false

          // console.log("chart | data: ", data)

          let extendedResult = this.utilityService.extendDataWithCalculatedFacts(data, this.HH, this.totalProduct[0], this.totalAccount[0], this.selectedProductBenchmarkItem.Id, this.penetrationFactId,
            this.factsBasicCalculated, this.factsBuyerCalculated, this.factsNumberOfBuyersCalculated, this.factsNumberOfBuyersMCalculated, this.factsExpendituresCalculated, this.factsExpendituresMCalculated,
            this.factsPropensityCalculated, this.factsGapCalculated, this.factsPromotionCalculated, this.factsDecompostionCalculated)
          let dataExtended = extendedResult[Ids.results]
          this.hasPenetrationWarning = extendedResult[Ids.hasPenetrationWarning]
          // console.log("pivot | dataExtended: ", dataExtended)

          //convert dataExtended to normalized format
          let dataExtendedNormalized = this.utilityService.normalizeData(dataExtended, this.selectedFactNodes.map(i=> i.Id))
          // console.log("pivot | dataExtendedNormalized: ", dataExtendedNormalized)

          this.chartDataRaw = dataExtendedNormalized

          this.updateCharts()
        }
      )
    })
  }

  handleExportExcel() {
    // if(this.selectedAccountNodes.length === 0 || this.selectedProductNodes.length === 0 || this.selectedPeriod1ItemIds.length === 0 || this.selectedFactNodes.length === 0) {
    //   alert("Missing...")
    //   return
    // }

    // header
    let header: any = {}
    header[this.cs.CURRENT_PERIOD] = this.selectedPeriod1Item.Name
    header[this.cs.PERVIOUS_PERIOD] = this.selectedPeriod2Item.Name

    for(let fid of this.filterValueIds) {
      switch(fid) {
        case Columns.Account: {
            header[this.cs.ACCOUNT] = this.selectedFilterAccountItem.Name
            break;
        }
        case Columns.ShopperGroup: {
            header[this.cs.SHOPPER_GROUP] = this.selectedFilterShopperGroupItem.Name
            break;
        }
        case Columns.Product: {
          header[this.cs.PRODUCT] = this.selectedFilterProductItem.Name
          break;
        }
        case Columns.Fact: {
          header[this.cs.FACT] = this.selectedFilterFactItem.Name
          break;
        }
      }
    }


    // table
    let columnValueMap: any = {}
    let rowColumnIds = [this.selectedRowItem.Id, this.selectedColumnItem.Id]

    if(rowColumnIds.includes(Columns.ShopperGroup)) {
      columnValueMap[this.cs.SHOPPER_GROUP] = Columns.ShopperGroup
    }


    if(rowColumnIds.includes(Columns.Account)) {
      columnValueMap[this.cs.ACCOUNT] = Columns.Account
    }

    if(rowColumnIds.includes(Columns.Product)) {
      columnValueMap[this.cs.PRODUCT] = Columns.Product
    }


    if(rowColumnIds.includes(Columns.Fact)) {
      columnValueMap[this.cs.FACT] = Columns.Fact
    }

    columnValueMap[this.cs.VALUE_CURRENT] = Ids.value_current
    columnValueMap[this.cs.VALUE_PREVIOUS] = Ids.value_previous
    columnValueMap[this.cs.VALUE_DIFF_ABSOLUTE] = Ids.value_diff_absolute
    columnValueMap[this.cs.VALUE_DIFF_PERCENT] = Ids.value_diff_in_percent

    this.calculateExportData()

    let dummy: any = {}
    
    this.exportService.exportExcel("KPI_Comparison", this.hasPenetrationWarning, this.hidePenetrationWarning, Ids.table, this.dashboardTitle, this.exportData, header, columnValueMap, dummy, dummy, dummy)
                      .then(fileName => {
                        console.log(`Created Excel file: ${fileName}`);
                        this.isCreatingExport = false
                      });
  }

  handlePenetrationWarning() {
    this.changeDetector.detectChanges();

    for(let s1 of this.listBarSeries1) {
      if(s1 !== undefined) {s1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning);}
    }

    for(let s2 of this.listBarSeries2) {
      if(s2 !== undefined) {s2.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning);}
    }
  }

  chartMasterNextPage = (event:any) => {
    let filter: any[] = []
    for(let fid of this.filterValueIds) {
      let filterItem: any = {}
      switch(fid) {
        case Columns.Account: {
            filterItem[this.cs.ACCOUNT] = this.selectedFilterAccountItem.Name
            break;
        }
        case Columns.ShopperGroup: {
            filterItem[this.cs.SHOPPER_GROUP] = this.selectedFilterShopperGroupItem.Name
            break;
        }
        case Columns.Product: {
          filterItem[this.cs.PRODUCT] = this.selectedFilterProductItem.Name
          break;
        }
        case Columns.Fact: {
          filterItem[this.cs.FACT] = this.selectedFilterFactItem.Name
          break;
        }
      }
      filter.push(filterItem)
    }

    let header: any = {}
    header[this.cs.CURRENT_PERIOD] = this.selectedPeriod1Item.Name
    header[this.cs.PERVIOUS_PERIOD] = this.selectedPeriod2Item.Name
    header[Object.keys(filter[0])[0]] = Object.values(filter[0])[0]
    header[Object.keys(filter[1])[0]] = Object.values(filter[1])[0]

    this.calculateExportData()

    // console.log("header: ", header, this.exportData, this.selectedColumnItem.Id, this.selectedColumnNames, this.selectedRowItem.Id)
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster

    this.exportService.createPPTKPIComparison(this.chartMasterPPT, this.dashboardTitle, this.selectedColumnItem.Id, this.selectedColumnNames, this.selectedRowItem.Id, this.exportData, header, options)
    this.chartMasterService.next();
  }

  handleExportPPT(singleTreeName: string): void {
    let filter: any[] = [
    ]
    for(let fid of this.filterValueIds) {
      let filterItem: any = {}
      switch(fid) {
        case Columns.Account: {
            filterItem[this.cs.ACCOUNT] = this.selectedFilterAccountItem.Name
            break;
        }
        case Columns.ShopperGroup: {
            filterItem[this.cs.SHOPPER_GROUP] = this.selectedFilterShopperGroupItem.Name
            break;
        }
        case Columns.Product: {
          filterItem[this.cs.PRODUCT] = this.selectedFilterProductItem.Name
          break;
        }
        case Columns.Fact: {
          filterItem[this.cs.FACT] = this.selectedFilterFactItem.Name
          break;
        }
      }
      filter.push(filterItem)
    }

    let header: any = {}
    header[this.cs.CURRENT_PERIOD] = this.selectedPeriod1Item.Name
    header[this.cs.PERVIOUS_PERIOD] = this.selectedPeriod2Item.Name
    header[Object.keys(filter[0])[0]] = Object.values(filter[0])[0]
    header[Object.keys(filter[1])[0]] = Object.values(filter[1])[0]

    this.calculateExportData()

    // console.log("header: ", header, this.exportData, this.selectedColumnItem.Id, this.selectedColumnNames, this.selectedRowItem.Id)
    let options: any = {}
    options[PPTIds.master] = this.userDefinedPptMaster

    this.exportService.exportPPTKPIComparison(this.dashboardTitle, this.selectedColumnItem.Id, this.selectedColumnNames, this.selectedRowItem.Id, this.exportData, header, options)
                      .then(fileName => {
                        console.log(`Created Single PPT file: ${fileName}`);
                        this.isCreatingExport = false
                    });
  }

  handleExportMultiPPT(singleTreeName: string, accounts: any[], products: any[]) {
    // not existing
    this.isCreatingExport = false
  }

  private updateCharts() {
    for(let i=1; i<=this.selectedFilterNumberOfColumnsItem.Id; i++) {
      this.chartData[i] = [];
      this.chartHeadings[i] = "";
    }

    switch(this.selectedColumnItem.Id) {
      case Columns.Account: {
          this.getDataBasedOnAccounts();
          break;
      }
      case Columns.ShopperGroup: {
          this.getDataBasedOnShopperGroup();
          break;
      }
      case Columns.Product: {
        this.getDataBasedOnProducts();
        break;
      }
      case Columns.Fact: {
        this.getDataBasedOnFacts();
        break;
      }
    }
    // console.log("this.chartHeadings: ", this.chartHeadings);
    // console.log("this.chartData: ", this.chartData);
  }

  private calculateExportData() {
    this.exportData = []
    this.selectedColumnNames = []
    for(let i = 0; i < this.chartHeadings.length; i++) {
      if(i <= this.selectedFilterNumberOfColumnsItem.Id && this.chartHeadings[i]) { // not empty or ""
        let rowData = this.chartData[i]

        // console.log("rowData: ", i, rowData, rowData.length)
        for(let j = 0; j < rowData.length; j++) {
          let item: any = {}
          item[this.selectedColumnItem.Id] = this.chartHeadings[i]
          item[this.selectedRowItem.Id] = rowData[j][Ids.content]
          item[Ids.value_current] = rowData[j][Ids.value_current]
          item[Ids.value_previous] = rowData[j][Ids.value_previous]
          item[Ids.value_diff_absolute] = Number((item[Ids.value_current] - item[Ids.value_previous]).toFixed(1))
          item[Ids.value_diff_in_percent] = Number(((item[Ids.value_current] - item[Ids.value_previous]) / item[Ids.value_previous] * 100).toFixed(1))

          this.exportData.push(item)
          this.selectedColumnNames.push(this.chartHeadings[i])
        }
      }
    }
  }

  private getDataBasedOnFacts() {
    let column = 1;
    let selectedFactNodeIds = this.selectedFactNodes.map(i=>i.Id)
    let selectedFactNodeNames = this.selectedFactNodes.map(i=>i.Name)

    switch(this.selectedRowItem.Id) {
      case Columns.Account: {
        selectedFactNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, this.selectedAccountNodes.map(i=>i.Id), [this.selectedFilterShopperGroupItem.Id], [this.selectedFilterProductItem.Id], [selectedFactNodeIds[column-1]]);
          this.chartHeadings[column] = selectedFactNodeNames[column-1]
          column++;
        });
        break;
      }
      case Columns.ShopperGroup: {
        selectedFactNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, [this.selectedFilterAccountItem.Id], [this.selectedShopperGroupItem.Id], [this.selectedFilterProductItem.Id], [selectedFactNodeIds[column-1]]);
          this.chartHeadings[column] = selectedFactNodeNames[column-1]
          column++;
        });
        break;
      }
      case Columns.Product: {
        selectedFactNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, [this.selectedFilterAccountItem.Id], [this.selectedFilterShopperGroupItem.Id], this.selectedProductNodes.map(i=>i.Id), [selectedFactNodeIds[column-1]]);
          this.chartHeadings[column] = selectedFactNodeNames[column-1]
          column++;
        });
        break;
     }
     case Columns.Fact: {
        // can not happen
        break;
     }
   }
  }

  private getDataBasedOnAccounts() {
    let column = 1;
    let selectedAccountNodeIds = this.selectedAccountNodes.map(i=>i.Id)
    let selectedAccountNodeNames = this.selectedAccountNodes.map(i=>i.Name)

    switch(this.selectedRowItem.Id) {
      case Columns.Account: {
        // can not happen
        break;
      }
      case Columns.ShopperGroup: {
        selectedAccountNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, [selectedAccountNodeIds[column-1]], [this.selectedShopperGroupItem.Id], [this.selectedFilterProductItem.Id], [this.selectedFilterFactItem.Id]);
          this.chartHeadings[column] = selectedAccountNodeNames[column-1]
          column++;
        });
        break;
      }
      case Columns.Product: {
        selectedAccountNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, [selectedAccountNodeIds[column-1]], [this.selectedFilterShopperGroupItem.Id], this.selectedProductNodes.map(i=>i.Id), [this.selectedFilterFactItem.Id]);
          this.chartHeadings[column] = selectedAccountNodeNames[column-1]
          column++;
        });
        break;
     }
     case Columns.Fact: {
      selectedAccountNodeIds.forEach((currentValue, index) => {
        this.getDataX(column, [selectedAccountNodeIds[column-1]], [this.selectedFilterShopperGroupItem.Id], [this.selectedFilterProductItem.Id], this.selectedFactNodes.map(i=>i.Id));
        this.chartHeadings[column] = selectedAccountNodeNames[column-1]
        column++;
      });
      break;
     }
   }
  }

  private getDataBasedOnProducts() {
    let column = 1;
    let selectedProductNodeIds = this.selectedProductNodes.map(i=>i.Id)
    let selectedProductNodeNames = this.selectedProductNodes.map(i=>i.Name)

    switch(this.selectedRowItem.Id) {
      case Columns.Account: {
        selectedProductNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, this.selectedAccountNodes.map(i=>i.Id), [this.selectedFilterShopperGroupItem.Id], [selectedProductNodeIds[column - 1]], [this.selectedFilterFactItem.Id])
          this.chartHeadings[column] = selectedProductNodeNames[column - 1]
          column++;
        });
        break;
      }

      case Columns.ShopperGroup: {
        selectedProductNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, [this.selectedFilterAccountItem.Id], [this.selectedShopperGroupItem.Id], [selectedProductNodeIds[column - 1]], [this.selectedFilterFactItem.Id]);
          this.chartHeadings[column] = selectedProductNodeNames[column - 1]
          column++;
        });
        break;
      }

      case Columns.Product: {
        // cannot happen
        break;
     }

     case Columns.Fact: {
      selectedProductNodeIds.forEach((currentValue, index) => {
        this.getDataX(column, [this.selectedFilterAccountItem.Id],  [this.selectedFilterShopperGroupItem.Id], [selectedProductNodeIds[column - 1]], this.selectedFactNodes.map(i=>i.Id));
        this.chartHeadings[column] = selectedProductNodeNames[column-1];
        column++;
      });
      break;
     }
   }
  }

  private getDataBasedOnShopperGroup() {
    let column = 1;
    let selectedShopperGroupNodeIds = [this.selectedShopperGroupItem.Id]
    let selectedShopperGroupNodeNames = [this.selectedShopperGroupItem.Name]
    switch(this.selectedRowItem.Id) {
      case Columns.Account: {
        selectedShopperGroupNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, this.selectedAccountNodes.map(i=>i.Id), [selectedShopperGroupNodeIds[column-1]], [this.selectedFilterProductItem.Id], [this.selectedFilterFactItem.Id]);
          this.chartHeadings[column] = selectedShopperGroupNodeNames[column-1]
          column++;
        });
        break;
      }
      case Columns.ShopperGroup: {
        // can not happen
        break;
      }
      case Columns.Product: {
        selectedShopperGroupNodeIds.forEach((currentValue, index) => {
          this.getDataX(column, [this.selectedFilterAccountItem.Id], [selectedShopperGroupNodeIds[column-1]], this.selectedProductNodes.map(i=>i.Id), [this.selectedFilterFactItem.Id]);
          this.chartHeadings[column] = selectedShopperGroupNodeNames[column-1]
          column++;
        });
        break;
     }
     case Columns.Fact: {
        selectedShopperGroupNodeIds.forEach((currentValue, index) => {
        this.getDataX(column, [this.selectedFilterAccountItem.Id], [selectedShopperGroupNodeIds[column-1]], [this.selectedFilterProductItem.Id], this.selectedFactNodes.map(i=>i.Id));
        this.chartHeadings[column] = selectedShopperGroupNodeNames[column-1]
        column++;
      });
      break;
     }
   }
  }

  private getDataX(column: number, account: any[], shopper: any[], product: any[], fact: any[] ) {
      if(column > this.selectedFilterNumberOfColumnsItem.Id) {
        return
      }

      // console.log("shopper ", shopper);
      // console.log("account ", account);
      // console.log("product ", product);
      // console.log("fact ", fact);

      let per_act = this.selectedPeriod1Item.Id;
      let res_current = this.chartDataRaw.filter(function (obj: any) {
        return (obj[Columns.Period] === per_act) &&
                shopper.includes(obj[Columns.ShopperGroup])  &&
                account.includes(obj[Columns.Account]) &&
                product.includes(obj[Columns.Product]) &&
                fact.includes(obj[Columns.Fact]) ;
      });
      // console.log("per_act ", per_act)
      // console.log("res_current: ", res_current)

      let res_previous: any = []
      if(this.period2IsSelected) {
        let per_pre = this.selectedPeriod2Item.Id;
        res_previous = this.chartDataRaw.filter(function (obj: any) {
          return (obj[Columns.Period] === per_pre) &&
                  shopper.includes(obj[Columns.ShopperGroup]) &&
                  account.includes(obj[Columns.Account])&&
                  product.includes(obj[Columns.Product])&&
                  fact.includes(obj[Columns.Fact])
        });
        // console.log("per_pre ", per_pre)
        // console.log("res_previous: ", res_previous)
      }

      let index_yaxis = 0;
      res_current.forEach((currentValue: any, index: any) => {
        let res_previous_x: any = []
        if(this.period2IsSelected) {
          res_previous_x = res_previous.filter(function (objx: any) {
            return (
                    objx[Columns.ShopperGroup] === currentValue[Columns.ShopperGroup] &&
                    objx[Columns.Account] === currentValue[Columns.Account] &&
                    objx[Columns.Product] === currentValue[Columns.Product] &&
                    objx[Columns.Fact] === currentValue[Columns.Fact]
                    );
          });
        }

        let item:any = {}
        item[Ids.index_yaxis] = index_yaxis
        item[Ids.content]     = this.replaceIdWithName(currentValue[this.selectedRowItem.Id], this.selectedRowItem.Id),
        item[Ids.value_current] = currentValue[Columns.Value],
        item[Ids.Label_current] = '',
        item[Ids.Series_current] = this.selectedPeriod1Item.Name,
        item[Ids.value_previous] = this.period2IsSelected ? res_previous_x[0][Columns.Value] : '',
        item[Ids.Label_previous] = '',
        item[Ids.Series_previous] = this.selectedPeriod2Item.Name,
        item[Ids.Penetration_current] = currentValue[Ids.Penetration_Prefix + this.selectedPeriod1Item.Id],
        item[Ids.Penetration_previous] = res_previous_x[0][Ids.Penetration_Prefix + this.selectedPeriod2Item.Id]

        switch(this.selectedMatModeItem.Id) {
          case MatModeId.MAT_both: {
            item[Ids.Label_current] = this.utilityService.myFormatNumber(currentValue[Columns.Value], 1)
            item[Ids.Label_previous] = this.period2IsSelected ? this.utilityService.myFormatNumber(res_previous_x[0][Columns.Value], 1) : ''
            break
          }

          case MatModeId.MAT_current_percent: {
            let diff = (item[Ids.value_current] / item[Ids.value_previous] - 1) * 100;
            item[Ids.Label_current] = this.utilityService.myFormatNumber(currentValue[Columns.Value], 1) + '(' + this.utilityService.myFormatNumber(diff, 1) + ')'
            break
          }

          case MatModeId.MAT_current_abs: {
            let diff = item[Ids.value_current] - item[Ids.value_previous]
            item[Ids.Label_current] = this.utilityService.myFormatNumber(currentValue[Columns.Value], 1) + '(' + this.utilityService.myFormatNumber(diff, 1) + ')'
            break
          }
        }

        this.chartData[column].push(item)

        index_yaxis = index_yaxis + 1;
    });

    // console.log(" this.chartData: ",  this.chartData)
  }

  private replaceIdWithName(id: string, dimensionId: any) {
    switch(dimensionId) {
      case Columns.Account:
        return this.dictAccountDataWithIdName.filter(i=>i.Id === id)[0].Name
        break

      case Columns.Product:
        return this.dictProductDataWithIdName.filter(i=>i.Id === id)[0].Name
        break

      case Columns.Fact:
        return this.cs.DATA_TABLE_FACTS_LIST.filter(i=>i.Id === id)[0].Name
        break

      case Columns.ShopperGroup:
        return this.filterShopperGroupData.filter(i=>i.Id === id)[0].Name
        break
    }
  }

  public getAxisMaxValue(num : number) {
    var factor = 8.0;
    if(this.chartData[num]){
      const value_current = this.chartData[num].map(function (i: any) {
          return i.value_current;
        });
      const value_previous = this.chartData[num].map(function (i: any) {
        return i.value_previous;
      });
      const value_complete = value_current.concat(value_previous);
      const max_value = Math.max(...value_complete);
      return max_value * factor;
    } else {
      return 0;
    }
  }

  private GenerateMarker() {
    if (
      this.selectedMatModeItem.Id == MatModeId.MAT_current_percent ||
      this.selectedMatModeItem.Id == MatModeId.MAT_current_abs ||
      this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id
    ) {
      for(let s2 of this.listBarSeries2) {
        if(s2!== undefined) { s2.removeAxes() }
      }
    } else {
      // console.log(" this.listXAxis: ",  this.listXAxis, this.listYAxis, this.listBarSeries2, this.listBarSeries1)
      for(let i = 0; i < this.listXAxis.length; i++) {
        if(this.listXAxis[i] !== undefined) {
          if(this.listBarSeries2[i] !== undefined) {
            this.listBarSeries2[i].xAxis = this.listXAxis[i]
            this.listBarSeries2[i].yAxis = this.listYAxis[i]
            this.listBarSeries2[i].markerTemplate =  this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_previous, !this.hidePenetrationWarning)
          }
        }
      }
    }

    for(let s1 of this.listBarSeries1) {
      if(s1 !== undefined) { s1.markerTemplate = this.utilityService.getMarker(this.configService, this.showDataLabel, Ids.Penetration_current, !this.hidePenetrationWarning) }
    }
  }

  saveSelectionToBackend = () => {
    let selection: any = {}
    selection[UserSelectionIds.Account] = this.selectedAccountNodes
    selection[UserSelectionIds.Product] = this.selectedProductNodes

    selection[UserSelectionIds.Period1] = this.selectedPeriod1Item
    selection[UserSelectionIds.Period2] = this.selectedPeriod2Item

    let selectedFacts:any[] = this.treeFactData.flatMap(i=>i.Children).filter(i=>i.Selected === true).map(i=> { return { Id: i.Id, Name: i.Name }})
    let existing = Object.create(null)
    let uniqueSelectedFacts = selectedFacts.filter(o => {
      var key = [Ids.Id, Ids.Name].map(k=> o[k]).join("|")
      if(!existing[key]) {
        existing[key] = true
        return true
      } else {
        return false
      }
    })
    // console.log("uniqueSelectedFacts: ", selectedFacts, uniqueSelectedFacts)
    selection[UserSelectionIds.Fact] = uniqueSelectedFacts // just save the unique facts

    selection[UserSelectionIds.ShopperGroup] =  this.selectedShopperGroupItem
    selection[UserSelectionIds.Benchmark] = this.selectedProductBenchmarkItem
    selection[UserSelectionIds.Row] =  this.selectedRowItem
    selection[UserSelectionIds.Column] =  this.selectedColumnItem
    selection[UserSelectionIds.MatMode] = this.selectedMatModeItem
    selection[UserSelectionIds.ColumnNumber] = this.selectedFilterNumberOfColumnsItem

    selection[UserSelectionIds.FilterAccount] = this.selectedFilterAccountItem
    selection[UserSelectionIds.FilterProduct] = this.selectedFilterProductItem
    selection[UserSelectionIds.FilterFact] = this.selectedFilterFactItem
    selection[UserSelectionIds.FilterShopperGroup] = this.selectedFilterShopperGroupItem

    selection[UserSelectionIds.Legend] =  this.showLegend
    selection[UserSelectionIds.DataLabel] =  this.showDataLabel

    this.store.dispatch(DashboardActions.selectionSave({dashboards: [this.dashboardId], selection: selection}))
  }

  loadSelection(selection: any[], loadSelectionFromBackend: boolean): void {
    if(!loadSelectionFromBackend) {
      // it will be reached here when change the mode (oneProductMultiAccount or oneAccountMultiProduct), but it will never happen here since there is no mode switch
      this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
      return
    }

    // Account Tree
    this.selectedAccountNodes =  this.utilityService.getSelectionItem(selection, UserSelectionIds.Account, [
      {
        Id: this.filterAccountData[0].Id,
        Name: this.filterAccountData[0].Name,
        Level: 0, // first level is 0
        Parent: "null"
      }
    ])
    if(!this.treeAccountIsMulti) {
      this.selectedAccountNodes = [this.selectedAccountNodes[0]]
    }
    this.selectedAccountIds = this.selectedAccountNodes.map(i=>i.Id)
    this.selectedAccountNodeLevels = [... new Set(this.selectedAccountNodes.map(n => n.Level))];
    if(this.selectedAccountNodes.length === 0) {
      this.filterValuesAccountData = [NoFilterSelected]
    } else {
      this.filterValuesAccountData = this.selectedAccountNodes
    }

    // Product Tree
    this.selectedProductNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Product, [
      {
        Id: this.filterProductData[0].Id,
        Name: this.filterProductData[0].Name,
        Level: 0,
        Parent: "null"
      }
    ])
    if(!this.treeProductIsMulti) {
      this.selectedProductNodes = [this.selectedProductNodes[0]]
    }
    this.selectedProductIds = this.selectedProductNodes.map(i=>i.Id)
    this.selectedProductNodeLevels = [... new Set(this.selectedProductNodes.map(n => n.Level))];
    this.filterProductBenchmarkData = this.utilityService.getSelectBenchmarkData(this.selectedProductNodes)
    if(this.selectedProductNodes.length === 0) {
      this.filterValuesProductData = [NoFilterSelected]
    } else {
      this.filterValuesProductData = this.selectedProductNodes
    }

    // Period1
    this.selectedPeriod1Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period1, {
      Id: this.filterPeriod1Data[0].Id,
      Name: this.filterPeriod1Data[0].Name
    })
    this.selectedPeriod1ItemNames = [this.selectedPeriod1Item.Name]

    // Period 2
    this.selectedPeriod2Item = this.utilityService.getSelectionItem(selection, UserSelectionIds.Period2, {
      Id: this.filterPeriod2Data[2].Id,
      Name: this.filterPeriod2Data[2].Name
    })
    // have to update it according to the User Language
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.selectedPeriod2Item = this.cs.PERIOD2_NOT_SELECTED
    }
    // console.log("this.selectedPeriod2Item: ", this.selectedPeriod2Item)

    this.selectedPeriod2ItemNames = [this.selectedPeriod2Item.Name]
    if(this.selectedPeriod2Item.Id === this.cs.PERIOD2_NOT_SELECTED.Id) {
      this.period2IsSelected = false
    } else {
      this.period2IsSelected = true
    }

    //Fact
    this.selectedFactNodes = []
    let tmpFactNodes = this.utilityService.getSelectionItem(selection, UserSelectionIds.Fact, [])
    for(let sf of tmpFactNodes) {
      sf = {...sf, Name: this.cs.DATA_TABLE_FACTS_LIST.filter(i=>i.Id === sf.Id)[0].Name}
      this.selectedFactNodes.push(sf)
    }
    // console.log("this.selectedFactNodes: ", this.selectedFactNodes)
    this.treeFactData = this.cs.DATA_KPI_COMPARISON
    for(let item of this.treeFactData){
      for(let ele of item.Children) {
          if(this.selectedFactNodes.map(i=>i.Id).includes(ele.Id)) {
              ele.Selected = true
          }
      }
    }
    if(this.selectedFactNodes.length === 0) {
      this.filterValuesFactData = [NoFilterSelected]
      this.selectedFilterFactItem = NoFilterSelected
    } else {
      this.filterValuesFactData = this.selectedFactNodes
      this.selectedFilterFactItem = this.filterValuesFactData[0]
    }

    // Shopper Group
    this.selectedShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ShopperGroup, {
      Id: this.filterShopperGroupData[0].Id,
      Name: this.filterShopperGroupData[0].Name
    })
    this.filterValuesShopperGroupData = [this.selectedShopperGroupItem]


    // Benchmark
    this.selectedProductBenchmarkItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Benchmark, this.cs.NO_BENCHMARK_SELECTED)
    // if it is not this.cs.NO_BENCHMARK_SELECTED, then we have to check if it is selected in the tree when initializing
    if(this.selectedProductBenchmarkItem.Id !== this.cs.NO_BENCHMARK_SELECTED.Id) {
      if(this.treeProductIsMulti) {
        if(!this.selectedProductIds.includes(this.selectedProductBenchmarkItem.Id)) {
          this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      } else {
        if(!this.selectedAccountIds.includes(this.selectedProductBenchmarkItem.Id)) {
          this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
        }
      }
    } else {
      this.selectedProductBenchmarkItem = this.cs.NO_BENCHMARK_SELECTED
    }
    // console.log("this.selectedProductBenchmarkItem: ", this.selectedProductBenchmarkItem, this.cs.NO_BENCHMARK_SELECTED)

    // Row
    this.selectedRowItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Row, {Id:Columns.Account, Name:"Account"})

    // Column
    this.selectedColumnItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.Column, {Id:Columns.Product, Name:"Product"})

    // Mat Mode
    this.selectedMatModeItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.MatMode, this.cs.MAT_MODE_ITEMS[0])
    this.GenerateMarker()

    // Number of Columns
    this.selectedFilterNumberOfColumnsItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.ColumnNumber, SelectNumberOfColumnsData[4])

    // Filter Values - Account
    let defaultSelectedFilterAccountItem = {}
    if(this.filterValuesAccountData.length === 0) {
      defaultSelectedFilterAccountItem = NoFilterSelected
    } else {
      defaultSelectedFilterAccountItem = this.filterValuesAccountData[0]
    }
    this.selectedFilterAccountItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.FilterAccount, defaultSelectedFilterAccountItem)
    // console.log("this.selectedFilterAccountItem: ", this.selectedFilterAccountItem )

    // Filter Values - Product
    let defaultSelectedFilterProductItem = {}
    if(this.filterValuesProductData.length === 0) {
      defaultSelectedFilterProductItem = NoFilterSelected
    } else {
      defaultSelectedFilterProductItem = this.filterValuesAccountData[0]
    }
    this.selectedFilterProductItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.FilterProduct, defaultSelectedFilterProductItem)
    // console.log("selection this.selectedFilterProductItem: ", this.selectedFilterProductItem)

    // Filter Values - Fact
    let defaultSelectedFilterFactItem = {}
    if(this.filterValuesFactData.length === 0) {
      defaultSelectedFilterFactItem = NoFilterSelected
    } else {
      defaultSelectedFilterFactItem = this.filterValuesFactData[0]
    }
    this.selectedFilterFactItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.FilterFact, defaultSelectedFilterFactItem)

    // Filter Values - Shoppergroup
    let defaultSelectedFilterShopperGroupItem = {}
    if(this.filterValuesShopperGroupData.length === 0) {
      defaultSelectedFilterShopperGroupItem = NoFilterSelected
    } else {
      defaultSelectedFilterShopperGroupItem = this.filterValuesShopperGroupData[0]
    }
    this.selectedFilterShopperGroupItem = this.utilityService.getSelectionItem(selection, UserSelectionIds.FilterShopperGroup, defaultSelectedFilterShopperGroupItem)

    // chart setting
    this.showLegend = this.utilityService.getSelectionItem(selection, UserSelectionIds.Legend, true)
    this.showDataLabel = this.utilityService.getSelectionItem(selection, UserSelectionIds.DataLabel, true)

  }
}
