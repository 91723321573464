import { ElementRef, EventEmitter, OnInit, Output, Component, Input, ViewChild, DoCheck, AfterViewChecked } from '@angular/core';

import { Observable } from 'rxjs';

import { SelectItem } from '../../../model/interfaces';
import { Columns } from '../../../model/constants';
import { ConstantService } from '../../../services/constant.service';


@Component({
  selector: 'app-filter-tabs-kpi-comparision',
  templateUrl: './filter-tabs-kpi-comparision.component.html',
  styleUrls: ['./filter-tabs-kpi-comparision.component.scss']
})
export class FilterTabsKpiComparisionComponent implements OnInit, AfterViewChecked {
  public exportItems: any = []
  public accountHidden = true
  public productHidden = true
  public factHidden = false
  public shopperGroupHidden = false


  // tab account
  @Input() public tabAccountTitle!: string;

  @Input() public filterAccountTitle!: string;
  @Input() public treeAccountIsMulti!: boolean;
  @Input() public filterAccountData!: any[];
  @Input() public selectedAccountNodes!: any[];
  @Input() getSelectedNodes!: ($event: any) => void

  // tab product
  @Input() public tabProductTitle!: string;

  @Input() public filterProductTitle!: string;
  @Input() public treeProductIsMulti!: boolean;
  @Input() public filterProductData!: any[];
  @Input() public selectedProductNodes!: any[];

  // tab period & fact
  @Input() public tabPeriodFactTitle!: string;

  @Input() public filterPeriod1Title!: string;
  @Input() public filterPeriod1Data!: any[];
  @Input() public selectedPeriod1Item: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedPeriodItem!: ($event: any) => void

  @Input() public filterPeriod2Title!: string;
  @Input() public filterPeriod2Data!: any[];
  @Input() public selectedPeriod2Item: SelectItem = {Id: '', Name: ''};

  @Input() public treeFactName!: string;
  @Input() public treeFactData!: any[];
  @Input() getSelectedFactNodes!: ($event: any) => void

  // tab settings
  @Input() public tabSelectionTitle!: string;

  @Input() public filterShopperGroupTitle!: string;
  @Input() public filterShopperGroupData!: any[];
  @Input() public selectedShopperGroupItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedShopperGroupItem!: ($event: any) => void

  @Input() public filterBenchmarkTitle!: string;
  @Input() public filterBenchmarkData!: any[];
  @Input() public selectedBenchmarkItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedBenchmarkItem!: ($event: any) => void

  @Input() public selectRowTitle!: string;
  @Input() public selectRowData!: any[];
  @Input() public selectedRowItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedRowItem!: ($event: any) => void

  @Input() public selectColumnTitle!: string;
  @Input() public selectColumnData!: any[];
  @Input() public selectedColumnItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedColumnItem!: ($event: any) => void

  @Input() public selectMatModeTitle!: string;
  @Input() public selectMatModeData!: any[];
  @Input() public selectedMatModeItem: SelectItem = {Id: '', Name: ''};
  @Input() public matModeIsDisabled!: boolean;

  @Input() getSelectedMatModeItem!: ($event: any) => void

  @Output() getSelectedExportEvent = new EventEmitter<any>();

  @Input() public selectFilterNumberOfColumnsTitle!: string;
  @Input() public filterValuesNumberOfColumnsData!: any[];
  @Input() public selectedFilterNumberOfColumnsItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedFilterNumberOfColumnsItem!: ($event: any) => void

  @Input() public selectFilterAccountTitle!: string;
  @Input() public filterValuesAccountData!: any[];
  @Input() public selectedFilterAccountItem: SelectItem = {Id: '', Name: ''};
  public selectedFilterAccountItemId: string = ''
  @Input() getSelectedFilterAccountItem!: ($event: any) => void

  @Input() public selectFilterProductTitle!: string;
  @Input() public filterValuesProductData!: any[];
  @Input() public selectedFilterProductItem: SelectItem = {Id: '', Name: ''};
  public selectedFilterProductItemId: string = ''
  @Input() getSelectedFilterProductItem!: ($event: any) => void

  @Input() public selectFilterFactTitle!: string;
  @Input() public filterValuesFactData!: any[];
  @Input() public selectedFilterFactItem: SelectItem = {Id: '', Name: ''};
  public selectedFilterFactItemId: string = ''
  @Input() getSelectedFilterFactItem!: ($event: any) => void

  @Input() public selectFilterShopperGroupTitle!: string;
  @Input() public filterValuesShopperGroupData!: any[];
  @Input() public selectedFilterShopperGroupItem: SelectItem = {Id: '', Name: ''};
  @Input() getSelectedFilterShopperGroupItem!: ($event: any) => void

  @Input() showLegend: boolean = true;
  @Input() showDataLabel: boolean = true;
  @Output() showLegendEvent = new EventEmitter<any>();
  @Output() showDataLabelEvent = new EventEmitter<any>();

  // other inputs
  @Input() selectedAccountNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes
  @Input() selectedProductNodeLevels!: number[];   // used for selectAllInHierarchy in tree with checkboxes

  // may be used later
  @Input() isDataLoading$!: Observable<boolean>;

  public chartSettingTitle: string = this.cs.CHART_SETTING
  public chartSettingSaveValues: string = this.cs.CHART_SETTING_SAVE_VALUES
  public chartSettingLegendTitle: string = this.cs.LEGEND
  public chartSettingDatalabelTitle: string = this.cs.DATA_LABEL
  public exportTitle: string = this.cs.EXPORT

  constructor(public elem: ElementRef, public cs: ConstantService) {}

  ngAfterViewChecked(): void {
    this.selectedFilterAccountItemId = this.selectedFilterAccountItem ? this.selectedFilterAccountItem.Id : ''
    this.selectedFilterProductItemId = this.selectedFilterProductItem ? this.selectedFilterProductItem.Id : ''
    this.selectedFilterFactItemId = this.selectedFilterFactItem ? this.selectedFilterFactItem.Id : ''

    // console.log("init selectedFilterProductItem: ", this.selectedFilterProductItem, )
    // console.log("init selectedFilterProductItemId: ", this.selectedFilterProductItemId)
    // console.log("init selectedFilterAccountItem: ", this.selectedFilterAccountItem, )
    // console.log("init selectedFilterAccountItemId: ", this.selectedFilterAccountItemId)
  }

  ngOnInit(): void {
    this.updateExportItems()
  }

  public disableFilterValueSelect(filterValueId: string, disable: boolean) {
    if(filterValueId === Columns.Account) {
      if(disable) {
        this.accountHidden = true
      } else {
        this.accountHidden = false
      }
    } else if(filterValueId === Columns.Product) {
      if(disable) {
        this.productHidden = true
      } else {
        this.productHidden = false
      }
    } else if(filterValueId === Columns.Fact) {
      if(disable) {
        this.factHidden = true
      } else {
        this.factHidden = false
      }
    } else { //ShopperGroup
      if(disable) {
        this.shopperGroupHidden = true
      } else {
        this.shopperGroupHidden = false
      }
    }
  }

  private updateExportItems() {
    this.exportItems = []
    this.exportItems.push({Value:"excel", Name:this.cs.EXCEL})
    this.exportItems.push({Value:"ppt", Name:this.cs.SINGLE_PPT})
  }

  export(id: string){
    let selectedItem: any = {
      Id: ''
    }
    selectedItem['Id'] = id

    this.getSelectedExportEvent.emit({selectedItem});
  }

  toggleLegend(event: any) {
    let legend: any = {show: false}
    if(event.checked) {
      legend['show'] = true
    } else {
      legend['show'] = false
    }
    this.showLegendEvent.emit({legend})
  }

  toggleDataLabel(event: any) {
    let dataLabel: any = {show: false}
    if(event.checked) {
      dataLabel['show'] = true
    } else {
      dataLabel['show'] = false
    }
    this.showDataLabelEvent.emit({dataLabel})
  }
}
