import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IgxToastComponent } from '@infragistics/igniteui-angular';
import { first } from 'rxjs';
import { AuthActions } from 'src/app/auth/state/auth.actions';
import { AuthAppState } from 'src/app/auth/state/auth.reducer';
import { fromAuth } from 'src/app/auth/state/auth.selectors';
import { ConstantService } from '../../shared/services/constant.service';
import { AuthService } from '../../../auth/services/auth.service';
import { ConfigService } from '../../shared/services/config.service';

@UntilDestroy()
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  // titles
  public headerDatabaseLanguage = this.cs.DATABASE_LANGUANGE
  public headerPersonalData = this.cs.PERSONAL_DATA
  public headerProject = this.cs.PROJECT

  public databaseTitle = this.cs.DATABASE
  public languageTitle = this.cs.LANGUAGE

  public firstNameTitle = this.cs.FIRSTNAME
  public lastNameTitle = this.cs.LASTNAME
  public saveTitle = this.cs.SAVE

  public saveAndLogout: string = this.cs.SAVE_AND_LOGOUT
  public language: string = this.cs.LANGUAGE

  // database & language
  public filterLanguageData: any[] = this.configService.LANGUAGES
  public databaseScopes: any[] = [];
  public databaseItems: any[] = []

  private initLanguageId: string = ""
  private initDBId: number = -1

  public selectedDBId: number = -1
  public selectedLanguageId = ""

  private languageHasChanged: boolean = false
  private databaseHasChanged: boolean = false
  public databaseAndLanguageDataIsLoading: boolean = false

  public saveDBLanguageButtonDisabled: boolean = true

  // personal data
  public firstname = ""
  public lastname = ""

  public latestFirstname = ""
  public latestLastname = ""

  public isPersonalDataSaving$     = this.store.select(fromAuth.isPersonalDataSaving)
  public isPersonalDataSaved$      = this.store.select(fromAuth.isPersonalDataSaved)


  // others
  @ViewChild('toast_account', { read: IgxToastComponent })
  public toast_account!: IgxToastComponent;

  constructor(
    public store: Store<AuthAppState>,
    public translate: TranslateService,
    public cs: ConstantService,
    public authService: AuthService,
    public configService: ConfigService,
  ) { }

  ngOnInit(): void {
    this.databaseAndLanguageDataIsLoading = true
    this.authService.getDatabaseScope()
    .pipe(first(), untilDestroyed(this))
    .subscribe(data => {
          this.databaseScopes = data["databases_scope"]
          this.databaseItems = this.databaseScopes.map(i=>i.databases).flat()
          // console.log("database scope: ", this.databaseScopes, this.databaseItems)
          this.databaseAndLanguageDataIsLoading = false

          this.store
          .select<any | null>(fromAuth.selectAuthUser)
          .pipe(first(), untilDestroyed(this))
          .subscribe( user => {
              this.selectedLanguageId = user.language
              this.initLanguageId = this.selectedLanguageId

              this.selectedDBId = user.databases_id
              this.initDBId = this.selectedDBId

              this.firstname = user.firstname
              this.lastname = user.lastname

              this.latestFirstname = this.firstname
              this.latestLastname = this.lastname

              // console.log("this.initLanguageId: ", this.initLanguageId, this.initDBId)
              // console.log("names: ", this.firstname, this.lastname)
            }
          )
    })
  }

  // DB & Language functions
  public saveLanguageAndDatabase() {
    let language: string = this.selectedLanguageId
    let dbid: number = this.selectedDBId
    this.store.dispatch(AuthActions.updateDBIDLanguageRequest({dbid, language}))
  }

  public onChangDatabase(event: any): void {
    this.selectedDBId = event
    if(this.initDBId === this.selectedDBId) {
      this.databaseHasChanged = false
    } else {
      this.databaseHasChanged = true
    }
    this.saveDBLanguageButtonDisabled = !(this.languageHasChanged || this.databaseHasChanged)
    // console.log("change database: ", this.selectedDBId, this.databaseHasChanged, this.saveDBLanguageButtonDisabled)
  }

  public onChangLanguage(event: any): void {
    this.selectedLanguageId = event
    if(this.initLanguageId === this.selectedLanguageId) {
      this.languageHasChanged = false
    } else {
      this.languageHasChanged = true
    }
    this.saveDBLanguageButtonDisabled = !(this.languageHasChanged || this.databaseHasChanged)
    // console.log("change languange: ", this.selectedLanguageId, this.languageHasChanged, this.saveDBLanguageButtonDisabled)
  }

  // Personal data functions
  public saveAccountUserInformation(){
    if(this.firstname.length >= 2 && this.lastname.length >= 2) {
      // console.log(this.firstname, this.lastname)
      let firstname = this.firstname
      let lastname = this.lastname

      this.store.dispatch(AuthActions.updatePersonalDataRequest({firstname, lastname}))

      this.isPersonalDataSaved$.subscribe((saved) => {
        if(saved) {
          this.latestFirstname = this.firstname
          this.latestLastname = this.lastname
          this.toast_account.open(this.cs.CHANGE_SAVED);
        }
      })
    }
    else{
      this.toast_account.open(this.cs.CHANGE_NOT_SAVED);
    }
  }
}
