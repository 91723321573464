import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthService } from './services/auth.service';
import { AuthEffects } from './state/auth.effects';
import { AuthFacade } from './state/auth.facade';
import { AUTH_FEATURE_KEY, reducer } from './state/auth.reducer';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { IgxIconModule, IgxInputGroupModule } from '@infragistics/igniteui-angular';
import { AuthRoutingModule } from './auth.routing.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    StoreModule.forFeature(AUTH_FEATURE_KEY, reducer),
    EffectsModule.forFeature([AuthEffects]),
    IgxIconModule,
    IgxInputGroupModule,
    TranslateModule,
  ],
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent
  ],
  providers: [AuthFacade, AuthService],
})
export class AuthModule {}
