import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { IgxSelectComponent } from '@infragistics/igniteui-angular';

import { SelectItem } from 'src/app/dashboard/shared/model/interfaces';


@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class SingleSelectComponent {
  @Input() public name!: string;
  @Input() public items: SelectItem[] = [];
  @Input() public selected!: string;
  @Input() public disabled!: boolean;

  @Input() public hidden!: boolean
  @Input() public hasSeparateLabel: boolean = false


  @Output() getSelectedItemEvent = new EventEmitter<any>();

  @ViewChild('select')
  public selectComponent!: IgxSelectComponent;

  constructor(public elem: ElementRef) { }

  onChang(event: any): void {
    const selectedItem: SelectItem = {
      Id: '',
      Name: ''
    }
    selectedItem['Id'] = event
    selectedItem['Name'] = this.selectComponent?.selectedItem.text

    const name = this.name
    this.getSelectedItemEvent.emit({name, selectedItem});
  }

  public clearSelection() {
    this.selectComponent.clearSelection()
  }

}
